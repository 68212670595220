import React  from 'react';


import { Button } from '@carbon/react';


const HouseTotalResult = () => {



  return <>

      <Button 
          kind='secondary'
          className='car-calculate-footprint-button'
          > 
      </Button>
  
  </>;
};

export default HouseTotalResult;