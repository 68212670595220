import React from 'react';

const SecondaryNumberOfPeople = () => {

  return <>
  
        <div className='global-header-container' >
        <p>Inputs </p>
        </div>
      
  </>;
};

export default SecondaryNumberOfPeople;
