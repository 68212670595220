import React from 'react';
import { Button } from '@carbon/react';

const SecondaryFootprint = () => {
  return <>  
    <Button 
        className='house-calculate-footprint-button'
        > Save
    </Button>
  </>
}
;

export default SecondaryFootprint;