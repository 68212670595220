import React from 'react';

const CarHeaderResult = () => {

  return <>
       
      <div className='global-header-container' >
        <p>Results</p>
      </div>
      
  </>;
};

export default CarHeaderResult;
