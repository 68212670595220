import React from 'react';

const HouseHeaderVisualization = () => {

  return <>
       
      <div className='global-header-container' >
        <p>Visualization</p>
      </div>
      
  </>;
};

export default HouseHeaderVisualization;
