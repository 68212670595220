import React from 'react';

const FlightHeaderVisualization = () => {

  return <>
       
      <div className='global-header-container' >
        <p>Visualization</p>
      </div>
      
  </>;
};

export default FlightHeaderVisualization;
