import React from 'react';


const HouseNumberOfPeople = () => {
  return <>
        <section >
       
          </section>

          <section className='global-header-container'>
           <div className='secondary-header-container-left' >
           <p>First, please tell us where you live: </p>
           
           </div>
           <div className='secondary-header-container-right' >
            
           </div>
          </section>
      
  </>;
};

export default HouseNumberOfPeople;
