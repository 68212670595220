import React from 'react';

const FlightHeader = () => {

  return <>
        <div className='global-header-container' >
          <p>You can enter details for up to 5 flight itinerary</p>
        </div>
  </>
};

export default FlightHeader;
