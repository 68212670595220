import React from 'react';

const BusRailInputsAndResultsHeaders = () => {

  return <>
           <div className='global-header-container' >
            Inputs
           </div>
      
  </>;
};

export default  BusRailInputsAndResultsHeaders;
