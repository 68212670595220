import React from 'react';

const HouseHeaderResults = () => {

  return <>
      <div className='global-header-container' >
        <p>Results</p>
      </div>
  </>;
};

export default HouseHeaderResults;
