import React from 'react';


const FlightResults = () => {



  return <>
       
        <div className='global-header-container' >
        <p>Results</p>
      
        </div>
      
  </>;
};

export default FlightResults;
