import React from 'react';

const CarHeaderInputs = () => {
  return <>
          <div className='global-header-container' >
            <p>You can enter details for up to 1 car </p>
          </div>
  </>;
};

export default CarHeaderInputs;
