const airportsIATA = [
  {
    "code": "AAA",
    "lat": "-17.3595",
    "lon": "-145.494",
    "name": "Anaa Airport",
    "city": "Anaa",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "12512819",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "7",
    "icao": "NTGA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AAE",
    "lat": "36.8236",
    "lon": "7.8103",
    "name": "El Mellah Airport",
    "city": "El Tarf",
    "state": "Annaba",
    "country": "Algeria",
    "woeid": "12510325",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "16",
    "icao": "DABB",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "AAL",
    "lat": "57.0952",
    "lon": "9.85606",
    "name": "Aalborg Airport",
    "city": "Norresundby",
    "state": "Nordjylland",
    "country": "Denmark",
    "woeid": "12512587",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aal.dk\/",
    "runway_length": "8700",
    "elev": "10",
    "icao": "EKYT",
    "direct_flights": "7",
    "carriers": "10"
  },
  {
    "code": "AAM",
    "lat": "-24.8",
    "lon": "31.5333",
    "name": "Mala Mala",
    "city": "Mala Mala",
    "state": "",
    "country": "South Africa",
    "woeid": "55921381",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4420",
    "elev": "1200",
    "icao": "FAMD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AAN",
    "lat": "24.25",
    "lon": "55.75",
    "name": "Al Ain Airport",
    "city": "Ayn al Faydah",
    "state": "Abu Dhabi",
    "country": "United Arab Emirates",
    "woeid": "12523371",
    "tz": "Asia\/Dubai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "OMAL",
    "direct_flights": "12",
    "carriers": "9"
  },
  {
    "code": "AAQ",
    "lat": "44.9",
    "lon": "37.3167",
    "name": "Olkhovka Airport",
    "city": "Novorossiysk",
    "state": "Krasnodarskiy Kray",
    "country": "Russia",
    "woeid": "12516605",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "URKA",
    "direct_flights": "24",
    "carriers": "15"
  },
  {
    "code": "AAR",
    "lat": "56.3088",
    "lon": "10.6154",
    "name": "Tirstrup Airport",
    "city": "Kolind",
    "state": "Midtjylland",
    "country": "Denmark",
    "woeid": "12512604",
    "tz": "Europe\/Copenhagen",
    "phone": "+45 8775 7000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aar.dk",
    "runway_length": "9127",
    "elev": "81",
    "icao": "EKAH",
    "direct_flights": "9",
    "carriers": "8"
  },
  {
    "code": "AAT",
    "lat": "47.7406",
    "lon": "88.0845",
    "name": "Altay Airport",
    "city": "Altay",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "12511977",
    "tz": "Asia\/Urumqi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZWAT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AAX",
    "lat": "-19.5603",
    "lon": "-46.9653",
    "name": "Romeu Zuma Airport",
    "city": "Arax\u00e1",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511034",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6230",
    "elev": "3274",
    "icao": "SBAX",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AAY",
    "lat": "16.1947",
    "lon": "52.1694",
    "name": "Al Gaidah Airport",
    "city": "Al Ghaydah",
    "state": "Hadramawt",
    "country": "Yemen",
    "woeid": "12523000",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "98",
    "icao": "OYGD",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ABA",
    "lat": "53.7167",
    "lon": "91.4333",
    "name": "Abakan",
    "city": "Abakan",
    "state": "Khakasiya",
    "country": "Russian Federation",
    "woeid": "2119917",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UNAA",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "ABC",
    "lat": "38.9833",
    "lon": "-1.85",
    "name": "Los Llanos",
    "city": "Albacete",
    "state": "Castilla-la Mancha",
    "country": "Spain",
    "woeid": "20081243",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LEAB",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ABD",
    "lat": "30.3374",
    "lon": "48.3032",
    "name": "",
    "city": "Abadan",
    "state": "Khuzestan",
    "country": "Iran",
    "woeid": "2254271",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10170",
    "elev": "10",
    "icao": "OIAA",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "ABE",
    "lat": "40.6514",
    "lon": "-75.4342",
    "name": "Lehigh Valley International Airport",
    "city": "Allentown",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12518581",
    "tz": "America\/New_York",
    "phone": "610-266-6000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lvia.org\/",
    "runway_length": null,
    "elev": null,
    "icao": "KABE",
    "direct_flights": "14",
    "carriers": "11"
  },
  {
    "code": "ABI",
    "lat": "32.4164",
    "lon": "-99.6803",
    "name": "Abilene Regional Airport",
    "city": "Abilene",
    "state": "Texas",
    "country": "United States",
    "woeid": "12518518",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7199",
    "elev": "1790",
    "icao": "KABI",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "ABJ",
    "lat": "5.2556",
    "lon": "-3.9292",
    "name": "Abidjan Port Bouet Airport",
    "city": "Abidjan",
    "state": "Abidjan",
    "country": "Ivory Coast",
    "woeid": "12513870",
    "tz": "Africa\/Abidjan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "20",
    "icao": "DIAP",
    "direct_flights": "22",
    "carriers": "29"
  },
  {
    "code": "ABK",
    "lat": "6.73719",
    "lon": "44.2797",
    "name": "Kabri Dar",
    "city": "Kabri Dar",
    "state": "Ogaden",
    "country": "Ethiopia",
    "woeid": "1317686",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10935",
    "elev": "1800",
    "icao": "HAKD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ABL",
    "lat": "67.1058",
    "lon": "-157.854",
    "name": "Ambler Airport",
    "city": "Kiana",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12518600",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "289",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "ABM",
    "lat": "-10.9438",
    "lon": "142.453",
    "name": "Bamaga Airport",
    "city": "Seisia",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510598",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6700",
    "elev": "49",
    "icao": "YBAM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ABQ",
    "lat": "35.0494",
    "lon": "-106.625",
    "name": "Albuquerque International Airport",
    "city": "Albuquerque",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12518564",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13375",
    "elev": "5352",
    "icao": "KABQ",
    "direct_flights": "41",
    "carriers": "25"
  },
  {
    "code": "ABR",
    "lat": "45.4536",
    "lon": "-98.4189",
    "name": "Aberdeen Regional Airport",
    "city": "Aberdeen",
    "state": "South Dakota",
    "country": "United States",
    "woeid": "12518514",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6904",
    "elev": "1301",
    "icao": "KABR",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "ABS",
    "lat": "22.3722",
    "lon": "31.6125",
    "name": "Abu Simbel Airport",
    "city": "Abu Simbel",
    "state": "Aswan",
    "country": "Egypt",
    "woeid": "12512656",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "615",
    "icao": "HEBL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ABT",
    "lat": "20.2961",
    "lon": "41.6342",
    "name": "Al Baha Airport",
    "city": "Al Aqiq",
    "state": "Al Baha",
    "country": "Saudi Arabia",
    "woeid": "12517321",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10991",
    "elev": "5486",
    "icao": "OEBA",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ABV",
    "lat": "9.0056",
    "lon": "7.2661",
    "name": "Abuja International Airport",
    "city": "Gwagwa",
    "state": "Abuja Capital Territory",
    "country": "Nigeria",
    "woeid": "12515056",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11808",
    "elev": "1122",
    "icao": "DNAA",
    "direct_flights": "9",
    "carriers": "12"
  },
  {
    "code": "ABX",
    "lat": "-36.0678",
    "lon": "146.956",
    "name": "Albury Airport",
    "city": "Albury",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510583",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "539",
    "icao": "YMAY",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "ABY",
    "lat": "31.5328",
    "lon": "-84.1867",
    "name": "Southwest Georgia Regional Airport",
    "city": "Albany",
    "state": "Georgia",
    "country": "United States",
    "woeid": "12521922",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6601",
    "elev": "197",
    "icao": "KABY",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "ABZ",
    "lat": "57.2004",
    "lon": "-2.20373",
    "name": "Aberdeen Dyce International Airport",
    "city": "Aberdeen",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22450858",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aberdeenairport.com\/",
    "runway_length": "6002",
    "elev": "215",
    "icao": "EGPD",
    "direct_flights": "37",
    "carriers": "35"
  },
  {
    "code": "ACA",
    "lat": "16.7561",
    "lon": "-99.7536",
    "name": "General Juan N Alvarez International Airport",
    "city": "Acapulco",
    "state": "Guerrero",
    "country": "Mexico",
    "woeid": "12514882",
    "tz": "America\/Mexico_City",
    "phone": "52-748-66-93-23",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MMAA",
    "direct_flights": "12",
    "carriers": "17"
  },
  {
    "code": "ACC",
    "lat": "5.5964",
    "lon": "-0.17",
    "name": "Kotoka International Airport",
    "city": "Accra",
    "state": "Greater Accra",
    "country": "Ghana",
    "woeid": "12513022",
    "tz": "Africa\/Accra",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.gcaa.com.gh\/index.html",
    "runway_length": "9800",
    "elev": "205",
    "icao": "DGAA",
    "direct_flights": "25",
    "carriers": "30"
  },
  {
    "code": "ACE",
    "lat": "28.9521",
    "lon": "-13.6085",
    "name": "Arrecife Airport",
    "city": "San Bartolom\u00e9",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "12523048",
    "tz": "Atlantic\/Canary",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "46",
    "icao": "GCRR",
    "direct_flights": "49",
    "carriers": "28"
  },
  {
    "code": "ACH",
    "lat": "47.4887",
    "lon": "9.55376",
    "name": "Altenrhein Airport",
    "city": "Altenrhein",
    "state": "Canton of St. Gallen",
    "country": "Switzerland",
    "woeid": "23344872",
    "tz": "Europe\/Zurich",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport-stgallen.com\/",
    "runway_length": "4350",
    "elev": "1306",
    "icao": "LSZR",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ACI",
    "lat": "49.7134",
    "lon": "-2.22089",
    "name": "The Blaye Airport",
    "city": "St. Peter Port",
    "state": "Channel Islands",
    "country": "United Kingdom",
    "woeid": "12523974",
    "tz": "Europe\/Jersey",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2887",
    "elev": "291",
    "icao": "EGJA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ACK",
    "lat": "41.2568",
    "lon": "-70.0649",
    "name": "Nantucket Memorial Airport",
    "city": "Nantucket",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12521076",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6303",
    "elev": "48",
    "icao": "KACK",
    "direct_flights": "9",
    "carriers": "6"
  },
  {
    "code": "ACP",
    "lat": "68.1609",
    "lon": "-151.696",
    "name": "Sahand Airport",
    "city": "Sahand",
    "state": "",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KACP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ACS",
    "lat": "47.7006",
    "lon": "18.0064",
    "name": "",
    "city": "Achinsk",
    "state": "Krasnoyarskiy Kray",
    "country": "Russia",
    "woeid": "1979926",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ACT",
    "lat": "31.609",
    "lon": "-97.2234",
    "name": "Waco Regional Airport",
    "city": "Waco",
    "state": "Texas",
    "country": "United States",
    "woeid": "12522299",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6597",
    "elev": "516",
    "icao": "KACT",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ACV",
    "lat": "40.9698",
    "lon": "-124.108",
    "name": "Arcata Airport",
    "city": "Mckinleyville",
    "state": "California",
    "country": "United States",
    "woeid": "12518649",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5998",
    "elev": "218",
    "icao": "KACV",
    "direct_flights": "6",
    "carriers": "7"
  },
  {
    "code": "ACX",
    "lat": "25.0887",
    "lon": "104.89",
    "name": "Xingyi",
    "city": "Xingyi",
    "state": "Guizhou",
    "country": "China",
    "woeid": "2146722",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ACY",
    "lat": "39.4512",
    "lon": "-74.5716",
    "name": "Atlantic City International Airport",
    "city": "Egg Harbor City",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "12518689",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "76",
    "icao": "KACY",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "ACZ",
    "lat": "31.0385",
    "lon": "61.4962",
    "name": "Zabol A\/P",
    "city": "Zabol",
    "state": "Sistan va Baluchestan",
    "country": "Iran",
    "woeid": "2255305",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KACZ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ADA",
    "lat": "36.9811",
    "lon": "35.2803",
    "name": "Sakirpasa Airport",
    "city": "Adana",
    "state": "Adana",
    "country": "Turkey",
    "woeid": "12517911",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9022",
    "elev": "64",
    "icao": "LTAF",
    "direct_flights": "14",
    "carriers": "4"
  },
  {
    "code": "ADB",
    "lat": "38.32",
    "lon": "27.1603",
    "name": "Gaziemir Airport",
    "city": "Azmir",
    "state": "\u0130zmir",
    "country": "Turkey",
    "woeid": "12517893",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7700",
    "elev": "412",
    "icao": "LTBJ",
    "direct_flights": "37",
    "carriers": "10"
  },
  {
    "code": "ADD",
    "lat": "8.9783",
    "lon": "38.8011",
    "name": "Bole International Airport",
    "city": "Addis Ababa",
    "state": "Debub Shewa",
    "country": "Ethiopia",
    "woeid": "12512758",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12139",
    "elev": "7625",
    "icao": "HAAB",
    "direct_flights": "43",
    "carriers": "25"
  },
  {
    "code": "ADE",
    "lat": "12.8278",
    "lon": "45.0306",
    "name": "Aden International Airport",
    "city": "Ash Shaykh Uthman",
    "state": "`Adan",
    "country": "Yemen",
    "woeid": "12522998",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10270",
    "elev": "12",
    "icao": "OYAA",
    "direct_flights": "15",
    "carriers": "8"
  },
  {
    "code": "ADF",
    "lat": "37.8099",
    "lon": "38.3357",
    "name": "Adiyaman Airport",
    "city": "Adiyaman",
    "state": "Ad\u0131yaman",
    "country": "Turkey",
    "woeid": "2347259",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LTAG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ADJ",
    "lat": "31.973",
    "lon": "35.9822",
    "name": "Al Matar Airport",
    "city": "Amman",
    "state": "'Amman",
    "country": "Jordan",
    "woeid": "23388321",
    "tz": "Asia\/Amman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "OJAM",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ADK",
    "lat": "51.88",
    "lon": "-176.639",
    "name": "Adak Airport",
    "city": "Adak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12518524",
    "tz": "America\/Adak",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7794",
    "elev": "19",
    "icao": "PADK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ADL",
    "lat": "-34.9464",
    "lon": "138.529",
    "name": "Adelaide International Airport",
    "city": "Adelaide",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "1107231",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aal.com.au",
    "runway_length": "10171",
    "elev": "20",
    "icao": "YPAD",
    "direct_flights": "24",
    "carriers": "20"
  },
  {
    "code": "ADQ",
    "lat": "57.7545",
    "lon": "-152.512",
    "name": "Kodiak Airport",
    "city": "Kodiak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520492",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7548",
    "elev": "73",
    "icao": "PADQ",
    "direct_flights": "12",
    "carriers": "7"
  },
  {
    "code": "ADU",
    "lat": "38.2465",
    "lon": "48.2951",
    "name": "Ardabil Airport",
    "city": "Ardabil",
    "state": "Ardabil",
    "country": "Iran",
    "woeid": "2254335",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "OITL",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "ADX",
    "lat": "56.377",
    "lon": "-2.86117",
    "name": "Leuchars Airport",
    "city": "St. Andrews",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "12518118",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8707",
    "elev": "38",
    "icao": "EGQL",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ADZ",
    "lat": "12.5811",
    "lon": "-81.7092",
    "name": "Sesquicentenario Airport",
    "city": "San Andr\u00e9s",
    "state": "San Andres y Providencia",
    "country": "Colombia",
    "woeid": "12512417",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7808",
    "elev": "19",
    "icao": "SKSP",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "AEH",
    "lat": "13.8486",
    "lon": "20.8472",
    "name": "Abeche Airport",
    "city": "Ab\u00e9ch\u00e9",
    "state": "Ouaddai",
    "country": "Chad",
    "woeid": "12511930",
    "tz": "Africa\/Ndjamena",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1788",
    "icao": "FTTC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AEP",
    "lat": "-34.5617",
    "lon": "-58.4113",
    "name": "Aeroparque Jorge Newbery",
    "city": "Buenos Aires",
    "state": "Ciudad de Buenos Aires",
    "country": "Argentina",
    "woeid": "23388199",
    "tz": "America\/Buenos_Aires",
    "phone": "(54 11) 5480 6111",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aa2000.com.ar",
    "runway_length": "6890",
    "elev": "20",
    "icao": "SABE",
    "direct_flights": "31",
    "carriers": "4"
  },
  {
    "code": "AER",
    "lat": "43.447",
    "lon": "39.9552",
    "name": "Adler Airport",
    "city": "Sochi",
    "state": "Krasnodarskiy Kray",
    "country": "Russia",
    "woeid": "12515677",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "URSS",
    "direct_flights": "45",
    "carriers": "31"
  },
  {
    "code": "AES",
    "lat": "62.5607",
    "lon": "6.11129",
    "name": "Vigra Airport",
    "city": "Vigra",
    "state": "More og Romsdal",
    "country": "Norway",
    "woeid": "12515129",
    "tz": "Europe\/Oslo",
    "phone": "+47 70 11 48 00",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "71",
    "icao": "ENAL",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "AET",
    "lat": "66.55",
    "lon": "-152.65",
    "name": "Allakaket Airport",
    "city": "Allakaket",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524551",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "350",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "AEX",
    "lat": "31.323",
    "lon": "-92.5417",
    "name": "Alexandria International Airport",
    "city": "Alexandria",
    "state": "Louisiana",
    "country": "United States",
    "woeid": "12519648",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KAEX",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "AEY",
    "lat": "65.6561",
    "lon": "-18.0923",
    "name": "Akureyri Airport",
    "city": "Akureyri",
    "state": "Akureyri",
    "country": "Iceland",
    "woeid": "12513442",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6365",
    "elev": "6",
    "icao": "BIAR",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "AFA",
    "lat": "-34.5886",
    "lon": "-68.4028",
    "name": "San Rafael Airport",
    "city": "San Rafael",
    "state": "Mendoza",
    "country": "Argentina",
    "woeid": "12510556",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7513",
    "elev": "2444",
    "icao": "SAMR",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AFL",
    "lat": "-9.8661",
    "lon": "-56.1044",
    "name": "Alta Floresta Airport",
    "city": "Alta Floresta",
    "state": "Mato Grosso",
    "country": "Brazil",
    "woeid": "12511017",
    "tz": "America\/Campo_Grande",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "288",
    "icao": "SBAT",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "AFS",
    "lat": "41.6136",
    "lon": "64.2331",
    "name": "Zarafshan Airport",
    "city": "Zarafshan",
    "state": "Nawoiy",
    "country": "Uzbekistan",
    "woeid": "2272667",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AFT",
    "lat": "-9.18333",
    "lon": "160.95",
    "name": "Afutara Aerodrome",
    "city": "Afutara",
    "state": "Malaita",
    "country": "Solomon Islands",
    "woeid": "1020505",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AFZ",
    "lat": "36.1903",
    "lon": "57.7094",
    "name": "Sabzevar Airport",
    "city": "Sabzevar",
    "state": "Khorasan",
    "country": "Iran",
    "woeid": "12513753",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10428",
    "elev": "3010",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AGA",
    "lat": "30.3833",
    "lon": "-9.55",
    "name": "Almassira Airport",
    "city": "Agadir",
    "state": "Agadir",
    "country": "Morocco",
    "woeid": "12523043",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9547",
    "elev": "88",
    "icao": "GMAD",
    "direct_flights": "15",
    "carriers": "16"
  },
  {
    "code": "AGF",
    "lat": "44.1736",
    "lon": "0.59223",
    "name": "La Garenne Airport",
    "city": "Laplume",
    "state": "Aquitaine",
    "country": "France",
    "woeid": "12512903",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7103",
    "elev": "203",
    "icao": "LFBA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AGH",
    "lat": "56.2873",
    "lon": "12.8675",
    "name": "Angelholm Airport",
    "city": "Angelholm",
    "state": "Skane",
    "country": "Sweden",
    "woeid": "23319131",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lfv.se\/templates\/LFV_AirportStartPage____4777.aspx",
    "runway_length": "6562",
    "elev": "65",
    "icao": "ESTA",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "AGL",
    "lat": "-9.33333",
    "lon": "149.15",
    "name": "Wanigela",
    "city": "Wanigela",
    "state": "Northern",
    "country": "Papua New Guinea",
    "woeid": "1061994",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "50",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AGM",
    "lat": "65.6667",
    "lon": "-37.6667",
    "name": "Angmagssalik Airport",
    "city": "Angmassalik",
    "state": "Ostgronland",
    "country": "Greenland",
    "woeid": "12523853",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "BGAM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AGN",
    "lat": "57.4996",
    "lon": "-134.577",
    "name": "Angoon Airport",
    "city": "Angoon",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524552",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "PAGN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AGP",
    "lat": "36.6749",
    "lon": "-4.49298",
    "name": "Malaga Airport",
    "city": "Malaga",
    "state": "Andalucia",
    "country": "Spain",
    "woeid": "23232917",
    "tz": "Europe\/Madrid",
    "phone": "+34 952 048 838",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aena.es\/csee\/Satellite?cid=1048858947193&pagename=Est",
    "runway_length": "10500",
    "elev": "52",
    "icao": "LEMG",
    "direct_flights": "106",
    "carriers": "59"
  },
  {
    "code": "AGR",
    "lat": "27.1632",
    "lon": "77.9788",
    "name": "Agra Airport",
    "city": "Agra",
    "state": "Uttar Pradesh",
    "country": "India",
    "woeid": "12513528",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "551",
    "icao": "VIAG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AGS",
    "lat": "33.3735",
    "lon": "-81.9732",
    "name": "Bush Field Airport",
    "city": "Augusta",
    "state": "Georgia",
    "country": "United States",
    "woeid": "12518987",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8001",
    "elev": "145",
    "icao": "KAGS",
    "direct_flights": "2",
    "carriers": "8"
  },
  {
    "code": "AGT",
    "lat": "-25.5266",
    "lon": "-54.6226",
    "name": "Alejo Garcia Airport",
    "city": "Presidente Franco",
    "state": "Alto Paran\u00e1",
    "country": "Paraguay",
    "woeid": "12523540",
    "tz": "America\/Asuncion",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "SGES",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "AGU",
    "lat": "21.7047",
    "lon": "-102.317",
    "name": "Aguascalientes Airport",
    "city": "Aguascalientes",
    "state": "Aguascalientes",
    "country": "Mexico",
    "woeid": "12514821",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "6112",
    "icao": "MMAS",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "AGV",
    "lat": "9.55194",
    "lon": "-69.2367",
    "name": "Acarigua",
    "city": "Acarigua",
    "state": "Portuguesa",
    "country": "Venezuela",
    "woeid": "12522827",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "640",
    "icao": "SVAC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AGX",
    "lat": "10.8415",
    "lon": "72.175",
    "name": "Agatti Island Airport",
    "city": "Agatti Island",
    "state": "Lakshadweep",
    "country": "India",
    "woeid": "12467998",
    "tz": "Asia\/Kolkata",
    "phone": "+91 4894-42217",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1204",
    "elev": null,
    "icao": "VOAT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AHB",
    "lat": "18.24",
    "lon": "42.6556",
    "name": "Abha Airport",
    "city": "Hajlah",
    "state": "Asir",
    "country": "Saudi Arabia",
    "woeid": "12517317",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10991",
    "elev": "6857",
    "icao": "OEAB",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "AHC",
    "lat": "40.2664",
    "lon": "-120.151",
    "name": "Amedee Army Air Field",
    "city": "Herlong",
    "state": "California",
    "country": "United States",
    "woeid": "12518602",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KAHC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AHE",
    "lat": "-14.5",
    "lon": "-1.3",
    "name": "Ahe Airport",
    "city": "Ahe",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "24549702",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "AHO",
    "lat": "40.6319",
    "lon": "8.29279",
    "name": "Alghero Airport",
    "city": "Alghero",
    "state": "Sardinia",
    "country": "Italy",
    "woeid": "12513798",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "88",
    "icao": "LIEA",
    "direct_flights": "23",
    "carriers": "11"
  },
  {
    "code": "AHS",
    "lat": "15.4534",
    "lon": "-84.4041",
    "name": "Ahuas Airport",
    "city": "Auas",
    "state": "Gracias a Dios",
    "country": "Honduras",
    "woeid": "12524351",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2460",
    "elev": "98",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AHU",
    "lat": "35.1781",
    "lon": "-3.8383",
    "name": "Cote du Rif Airport",
    "city": "Al Hoceima",
    "state": "Al Hoceima",
    "country": "Morocco",
    "woeid": "12514784",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7087",
    "elev": "89",
    "icao": "GMTA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AIA",
    "lat": "42.0622",
    "lon": "-102.81",
    "name": "Alliance Municipal Airport",
    "city": "Alliance",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12518583",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9202",
    "elev": "3929",
    "icao": "KAIA",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "AIN",
    "lat": "70.6139",
    "lon": "-159.857",
    "name": "Wainwright Airport",
    "city": "Wainwright",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12522307",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "25",
    "icao": "PAWT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AIT",
    "lat": "-18.8299",
    "lon": "-159.765",
    "name": "Aitutaki Airport",
    "city": "Aitutaki",
    "state": "Aitutaki",
    "country": "Cook Islands",
    "woeid": "24549676",
    "tz": "Pacific\/Rarotonga",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5981",
    "elev": "14",
    "icao": "NCAI",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "AIU",
    "lat": "-20.0333",
    "lon": "-158.1",
    "name": "Atiu Island",
    "city": "Atiu Island",
    "state": "Atiu",
    "country": "Cook Islands",
    "woeid": "12523056",
    "tz": "Pacific\/Rarotonga",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2648",
    "elev": "200",
    "icao": "NCAT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AJA",
    "lat": "41.9204",
    "lon": "8.79778",
    "name": "Campo Dell Oro Airport",
    "city": "Ajaccio",
    "state": "Corsica",
    "country": "France",
    "woeid": "12512869",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6972",
    "elev": "16",
    "icao": "LFKJ",
    "direct_flights": "18",
    "carriers": "6"
  },
  {
    "code": "AJF",
    "lat": "29.7897",
    "lon": "40.1039",
    "name": "Al Jouf Airport",
    "city": "Sakakah",
    "state": "Al Jawf",
    "country": "Saudi Arabia",
    "woeid": "12517324",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12008",
    "elev": "2261",
    "icao": "OESK",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "AJI",
    "lat": "39.7239",
    "lon": "43.0618",
    "name": "Agri Airport",
    "city": "Agr\u0131",
    "state": "A\u011fr\u0131",
    "country": "Turkey",
    "woeid": "12523340",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LTCO",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AJL",
    "lat": "23.7361",
    "lon": "92.8083",
    "name": "Aizwal Airport",
    "city": "Aizawl",
    "state": "Mizoram",
    "country": "India",
    "woeid": "12513530",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3131",
    "elev": "1000",
    "icao": "VEAZ",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "AJN",
    "lat": "-12.2234",
    "lon": "44.3709",
    "name": "Comoros",
    "city": "Anjouan",
    "state": "Anjouan",
    "country": "Comoros",
    "woeid": "23424786",
    "tz": "Indian\/Comoro",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4429",
    "elev": "62",
    "icao": "FMCV",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "AJR",
    "lat": "65.592",
    "lon": "19.2647",
    "name": "Arvidsjaur Airport",
    "city": "Arvidsjaur",
    "state": "Norrbotten",
    "country": "Sweden",
    "woeid": "22656498",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ESNX",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "AJU",
    "lat": "-10.985",
    "lon": "-37.0733",
    "name": "Santa Maria Airport",
    "city": "Aracaju",
    "state": "Sergipe",
    "country": "Brazil",
    "woeid": "12511305",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5372",
    "elev": "26",
    "icao": "SBAR",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "AKA",
    "lat": "32.7026",
    "lon": "108.912",
    "name": "Ankang Airport",
    "city": "Ankang",
    "state": "Shaanxi",
    "country": "China",
    "woeid": "12511979",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZLAK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AKB",
    "lat": "52.2217",
    "lon": "-174.204",
    "name": "Atka Airport",
    "city": "Atka",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12518685",
    "tz": "America\/Adak",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3100",
    "elev": "33",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "AKF",
    "lat": "24.1819",
    "lon": "23.3189",
    "name": "Kufra Airport",
    "city": "Kufrah",
    "state": "Al Kufrah",
    "country": "Libya",
    "woeid": "12514656",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12007",
    "elev": "1367",
    "icao": "HLKF",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "AKI",
    "lat": "60.8866",
    "lon": "-161.218",
    "name": "Akiak Airport",
    "city": "Bethel",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524554",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "22",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "AKJ",
    "lat": "43.665",
    "lon": "142.453",
    "name": "Asahikawa Airport",
    "city": "Asahikawa-shi",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "28360516",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "709",
    "icao": "RJEC",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "AKK",
    "lat": "56.95",
    "lon": "-154.167",
    "name": "Akhiok Airport",
    "city": "Kodiak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524555",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2170",
    "elev": "50",
    "icao": "PAKH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AKL",
    "lat": "-37.0085",
    "lon": "174.782",
    "name": "Auckland International Airport",
    "city": "Manukau City",
    "state": "Auckland",
    "country": "New Zealand",
    "woeid": "12515151",
    "tz": "Pacific\/Auckland",
    "phone": "+64 9 256 8899",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.auckland-airport.co.nz\/",
    "runway_length": "10800",
    "elev": "23",
    "icao": "NZAA",
    "direct_flights": "52",
    "carriers": "35"
  },
  {
    "code": "AKN",
    "lat": "58.6775",
    "lon": "-156.655",
    "name": "King Salmon Airport",
    "city": "King Salmon",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520466",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8500",
    "elev": "57",
    "icao": "PAKN",
    "direct_flights": "14",
    "carriers": "7"
  },
  {
    "code": "AKP",
    "lat": "68.1916",
    "lon": "-151.79",
    "name": "Anaktuvuk Pass Airport",
    "city": "Anaktuvuk Pass",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524556",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "2100",
    "icao": "PAKP",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "AKS",
    "lat": "-27.6667",
    "lon": "27.3167",
    "name": "Kroonstad Airport",
    "city": "Kroonstad",
    "state": "Free State",
    "country": "South Africa",
    "woeid": "12517431",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "FAKS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AKU",
    "lat": "41.171",
    "lon": "80.1982",
    "name": "Aksu Airport",
    "city": "Aksu",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "12511976",
    "tz": "Asia\/Kashgar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZWAK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AKV",
    "lat": "60.8142",
    "lon": "-78.1509",
    "name": "Akulivik Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524039",
    "tz": "America\/Iqaluit",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYKO",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AKX",
    "lat": "50.2362",
    "lon": "57.2115",
    "name": "Aktyubinsk Airport",
    "city": "Aqtobe",
    "state": "Aqtobe",
    "country": "Kazakhstan",
    "woeid": "12514256",
    "tz": "Asia\/Aqtobe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10170",
    "elev": "722",
    "icao": "UATT",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "AKY",
    "lat": "20.1303",
    "lon": "92.8803",
    "name": "Sittwe Airport",
    "city": "Sittwe",
    "state": "Rakhine State",
    "country": "Myanmar",
    "woeid": "12510936",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "28",
    "icao": "VYSW",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "ALA",
    "lat": "43.3608",
    "lon": "77.0319",
    "name": "Alma Ata Airport",
    "city": "Almaty",
    "state": "Almaty",
    "country": "Kazakhstan",
    "woeid": "12514259",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "14427",
    "elev": "2234",
    "icao": "UAAA",
    "direct_flights": "44",
    "carriers": "36"
  },
  {
    "code": "ALB",
    "lat": "42.7426",
    "lon": "-73.809",
    "name": "Albany International Airport",
    "city": "Latham",
    "state": "New York",
    "country": "United States",
    "woeid": "12518554",
    "tz": "America\/New_York",
    "phone": "518-242-2222",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.albanyairport.com",
    "runway_length": "7200",
    "elev": "285",
    "icao": "KALB",
    "direct_flights": "27",
    "carriers": "27"
  },
  {
    "code": "ALC",
    "lat": "38.2844",
    "lon": "-0.5576",
    "name": "Alicante Airport",
    "city": "Elx",
    "state": "Valencia",
    "country": "Spain",
    "woeid": "22412043",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "141",
    "icao": "LEAL",
    "direct_flights": "91",
    "carriers": "37"
  },
  {
    "code": "ALF",
    "lat": "69.9793",
    "lon": "23.3571",
    "name": "Alta Airport",
    "city": "Alta",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12515099",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "9",
    "icao": "ENAT",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "ALG",
    "lat": "36.6993",
    "lon": "3.21935",
    "name": "Houari Boumediene Airport",
    "city": "Algiers",
    "state": "Alger",
    "country": "Algeria",
    "woeid": "12510331",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.egsa.dz",
    "runway_length": "11483",
    "elev": "82",
    "icao": "DAAG",
    "direct_flights": "62",
    "carriers": "20"
  },
  {
    "code": "ALH",
    "lat": "-34.9447",
    "lon": "117.808",
    "name": "Albany Airport",
    "city": "Albany",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510582",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5250",
    "elev": "232",
    "icao": "YABA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ALM",
    "lat": "32.8407",
    "lon": "-105.994",
    "name": "Alamogordo White Sands Regional Airport",
    "city": "Alamogordo",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12518553",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7005",
    "elev": "4197",
    "icao": "KALM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ALO",
    "lat": "42.5509",
    "lon": "-92.3951",
    "name": "Waterloo Municipal Airport",
    "city": "Waterloo",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12522347",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8401",
    "elev": "873",
    "icao": "KALO",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "ALP",
    "lat": "36.1808",
    "lon": "37.2267",
    "name": "Aleppo International Airport",
    "city": "Djibrine",
    "state": "H'alab",
    "country": "Syria",
    "woeid": "12517695",
    "tz": "Asia\/Damascus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9908",
    "elev": "1276",
    "icao": "OSAP",
    "direct_flights": "19",
    "carriers": "13"
  },
  {
    "code": "ALS",
    "lat": "37.4444",
    "lon": "-105.865",
    "name": "San Luis Valley Regional Airport",
    "city": "Alamosa",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12521726",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8499",
    "elev": "7535",
    "icao": "KALS",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "ALW",
    "lat": "46.0879",
    "lon": "-118.283",
    "name": "Walla Walla Regional Airport",
    "city": "Walla Walla",
    "state": "Washington",
    "country": "United States",
    "woeid": "12522317",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7186",
    "elev": "1205",
    "icao": "KALW",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "ALY",
    "lat": "31.1845",
    "lon": "29.9491",
    "name": "An-Nuzhah Airport",
    "city": "Alexandria",
    "state": "Al Iskandariyah",
    "country": "Egypt",
    "woeid": "23388264",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7021",
    "elev": null,
    "icao": "HEAX",
    "direct_flights": "19",
    "carriers": "15"
  },
  {
    "code": "ALZ",
    "lat": "56.8988",
    "lon": "-154.246",
    "name": "Alitak Seaplane Base",
    "city": "Alitak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524557",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AMA",
    "lat": "35.2177",
    "lon": "-101.706",
    "name": "Amarillo International Airport",
    "city": "Amarillo",
    "state": "Texas",
    "country": "United States",
    "woeid": "12518598",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13502",
    "elev": "3606",
    "icao": "KAMA",
    "direct_flights": "7",
    "carriers": "10"
  },
  {
    "code": "AMD",
    "lat": "23.0728",
    "lon": "72.6328",
    "name": "Sardar Vallabhbhai Patel International Airport",
    "city": "Ahmedabad",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513529",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "184",
    "icao": "VAAH",
    "direct_flights": "16",
    "carriers": "15"
  },
  {
    "code": "AMH",
    "lat": "6.01327",
    "lon": "37.5405",
    "name": "Ethiopia",
    "city": "Arba Mintch",
    "state": "Gamo Gofa",
    "country": "Ethiopia",
    "woeid": "23424808",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3999",
    "elev": "4000",
    "icao": "HAAM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AMI",
    "lat": "-8.562",
    "lon": "116.098",
    "name": "Selaparang",
    "city": "Mataram",
    "state": "Nusa Tenggara Barat",
    "country": "Indonesia",
    "woeid": "12513504",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "48",
    "icao": "WRRA",
    "direct_flights": "6",
    "carriers": "7"
  },
  {
    "code": "AMM",
    "lat": "31.7231",
    "lon": "35.9936",
    "name": "Queen Alia International Airport",
    "city": "Amman",
    "state": "'Amman",
    "country": "Jordan",
    "woeid": "12514059",
    "tz": "Asia\/Amman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12008",
    "elev": "2395",
    "icao": "OJAI",
    "direct_flights": "62",
    "carriers": "41"
  },
  {
    "code": "AMQ",
    "lat": "-3.7067",
    "lon": "128.078",
    "name": "Pattimura Airport",
    "city": "Ambon",
    "state": "Maluku",
    "country": "Indonesia",
    "woeid": "12513492",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "33",
    "icao": "WAPP",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "AMS",
    "lat": "52.3122",
    "lon": "4.77511",
    "name": "Schiphol Airport",
    "city": "Schiphol Zuid",
    "state": "North Holland",
    "country": "Netherlands",
    "woeid": "22386730",
    "tz": "Europe\/Amsterdam",
    "phone": "+31-207940800",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.schiphol.com\/",
    "runway_length": "12467",
    "elev": "-11",
    "icao": "EHAM",
    "direct_flights": "284",
    "carriers": "118"
  },
  {
    "code": "AMV",
    "lat": "69.7638",
    "lon": "61.5586",
    "name": "Amderma Airport",
    "city": "Nar'yan-Mar",
    "state": "Nenetskiy Avtonomnyy Okrug",
    "country": "Russia",
    "woeid": "12515701",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ULDD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AMY",
    "lat": "38.4819",
    "lon": "-100.608",
    "name": "Ambatomainty",
    "city": "Ambatomainty",
    "state": "Fianarantsoa",
    "country": "Madagascar",
    "woeid": "1489538",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3010",
    "elev": "1050",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ANC",
    "lat": "61.1767",
    "lon": "-149.961",
    "name": "Anchorage International Airport",
    "city": "Anchorage",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12518613",
    "tz": "America\/Anchorage",
    "phone": "(907) 266-2526",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dot.state.ak.us\/anc\/index.shtml",
    "runway_length": "10897",
    "elev": "144",
    "icao": "PANC",
    "direct_flights": "62",
    "carriers": "47"
  },
  {
    "code": "ANE",
    "lat": "47.5603",
    "lon": "-0.312222",
    "name": "A\u00e9roport d'Angers-Marc\u00e9",
    "city": "Seiches-sur-le-Loir",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "22868196",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4052",
    "elev": "184",
    "icao": "LFRA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ANF",
    "lat": "-23.44",
    "lon": "-70.4419",
    "name": "Cerro Moreno International Airport",
    "city": "Antofagasta",
    "state": "Antofagasta",
    "country": "Chile",
    "woeid": "12512312",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8527",
    "elev": "460",
    "icao": "SCFA",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "ANG",
    "lat": "45.7274",
    "lon": "0.21835",
    "name": "Brie Champniers Airport",
    "city": "Champniers",
    "state": "Poitou-Charentes",
    "country": "France",
    "woeid": "12512864",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2723",
    "elev": "289",
    "icao": "LFBU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ANI",
    "lat": "61.5742",
    "lon": "-159.535",
    "name": "Aniak Airport",
    "city": "Bethel",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524558",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "88",
    "icao": "PANI",
    "direct_flights": "6",
    "carriers": "8"
  },
  {
    "code": "ANM",
    "lat": "-18.7772",
    "lon": "46.8543",
    "name": "Madagascar",
    "city": "Antalaha",
    "state": null,
    "country": "Madagascar",
    "woeid": "23424883",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3914",
    "elev": "20",
    "icao": "FMNH",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ANR",
    "lat": "51.1896",
    "lon": "4.45236",
    "name": "Deurne Airport",
    "city": "Antwerp",
    "state": "Antwerp",
    "country": "Belgium",
    "woeid": "22028955",
    "tz": "Europe\/Brussels",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4839",
    "elev": "39",
    "icao": "EBAW",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ANU",
    "lat": "17.1375",
    "lon": "-61.79",
    "name": "V C Bird International Airport",
    "city": "Carlisle",
    "state": "Saint George",
    "country": "Antigua and Barbuda",
    "woeid": "12510300",
    "tz": "America\/Antigua",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "62",
    "icao": "TAPA",
    "direct_flights": "28",
    "carriers": "14"
  },
  {
    "code": "ANV",
    "lat": "62.65",
    "lon": "-160.217",
    "name": "Anvik Airport",
    "city": "Anvik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524559",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2880",
    "elev": "325",
    "icao": "PANV",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "ANX",
    "lat": "69.3088",
    "lon": "16.1236",
    "name": "Andoya Airport",
    "city": "Andenes",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12515100",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8005",
    "elev": "46",
    "icao": "ENAN",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "AOC",
    "lat": "50.9794",
    "lon": "12.5111",
    "name": "Altenburg Nobitz",
    "city": "Altenburg",
    "state": "Thuringia",
    "country": "Germany",
    "woeid": "12597007",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flughafen-altenburg.de",
    "runway_length": "2235",
    "elev": "641",
    "icao": "EDAC",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AOE",
    "lat": "39.7905",
    "lon": "30.5172",
    "name": "Anadolu University Airport",
    "city": "Eski\u015fehir",
    "state": "Eski\u015fehir",
    "country": "Turkey",
    "woeid": "12523341",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "AOI",
    "lat": "43.6158",
    "lon": "13.3634",
    "name": "Falconara Airport",
    "city": "Falconara Marittima",
    "state": "Marche",
    "country": "Italy",
    "woeid": "12513819",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ancona-airport.com",
    "runway_length": "9810",
    "elev": "50",
    "icao": "LIPY",
    "direct_flights": "12",
    "carriers": "16"
  },
  {
    "code": "AOJ",
    "lat": "40.7357",
    "lon": "140.69",
    "name": "Aomori Airport",
    "city": "Aomori-shi",
    "state": "Aomori Prefecture",
    "country": "Japan",
    "woeid": "12523065",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4590",
    "elev": "662",
    "icao": "RJSA",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "AOK",
    "lat": "35.4239",
    "lon": "27.1472",
    "name": "Karpathos Airport",
    "city": "Karpathos",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513293",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "23",
    "icao": "LGKP",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "AOO",
    "lat": "40.2952",
    "lon": "-78.324",
    "name": "Altoona-Blair County Airport",
    "city": "Martinsburg",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12518593",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5466",
    "elev": "1504",
    "icao": "KAOO",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AOR",
    "lat": "6.1947",
    "lon": "100.404",
    "name": "Sultan Abdul Halim Airport",
    "city": "Kepala Batas",
    "state": "Kedah",
    "country": "Malaysia",
    "woeid": "12515009",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6600",
    "elev": "15",
    "icao": "WMKA",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "AOS",
    "lat": "57.4672",
    "lon": "-153.839",
    "name": "Amook Bay Seaplane Base",
    "city": "Amook",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "APA",
    "lat": "39.5752",
    "lon": "-104.845",
    "name": "Centennial Airport",
    "city": "Englewood",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12519112",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "10002",
    "elev": "5883",
    "icao": "KAPA",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "APF",
    "lat": "26.1483",
    "lon": "-81.7745",
    "name": "Naples Municipal Airport",
    "city": "Naples",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521078",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "9",
    "icao": "KAPF",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "APK",
    "lat": "-8.64687",
    "lon": "-131.787",
    "name": "French Polynesia",
    "city": "Apataki",
    "state": "",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2756",
    "elev": "3",
    "icao": "NTGD",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "APL",
    "lat": "-15.1",
    "lon": "39.2814",
    "name": "Nampula Airport",
    "city": "Nampula",
    "state": "Nampula",
    "country": "Mozambique",
    "woeid": "12515027",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "1457",
    "icao": "FQNP",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "APN",
    "lat": "45.07",
    "lon": "-83.5683",
    "name": "Alpena County Regional Airport",
    "city": "Alpena",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12518585",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9001",
    "elev": "689",
    "icao": "KAPN",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "APO",
    "lat": "7.88361",
    "lon": "-76.6321",
    "name": "Apartado Airport",
    "city": "Apartad\u00f3",
    "state": "Antioquia",
    "country": "Colombia",
    "woeid": "12523474",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "APW",
    "lat": "-13.8255",
    "lon": "-171.993",
    "name": "Faleolo Airport",
    "city": "Apia",
    "state": "A`ana",
    "country": "Samoa",
    "woeid": "12523066",
    "tz": "Pacific\/Apia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "58",
    "icao": "NSFA",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "AQG",
    "lat": "30.5167",
    "lon": "117.033",
    "name": "Anqing Airport",
    "city": "Anqing",
    "state": "Anhui",
    "country": "China",
    "woeid": "12523067",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZSAQ",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "AQI",
    "lat": "28.3317",
    "lon": "46.13",
    "name": "Hafr Al Batin Airport",
    "city": "Qaisumah",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "12517338",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "1175",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AQJ",
    "lat": "29.6119",
    "lon": "35.0178",
    "name": "Aqaba International Airport",
    "city": "Aqaba",
    "state": "Al `Aqabah",
    "country": "Jordan",
    "woeid": "12514051",
    "tz": "Asia\/Amman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "174",
    "icao": "OJAQ",
    "direct_flights": "10",
    "carriers": "5"
  },
  {
    "code": "AQP",
    "lat": "-16.3372",
    "lon": "-71.5692",
    "name": "Rodriguez Ballon Airport",
    "city": "Arequipa",
    "state": "Arequipa",
    "country": "Peru",
    "woeid": "12515218",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9777",
    "elev": "8365",
    "icao": "SPQU",
    "direct_flights": "3",
    "carriers": "6"
  },
  {
    "code": "ARC",
    "lat": "68.1167",
    "lon": "-145.583",
    "name": "Arctic Village Airport",
    "city": "Arctic Village",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524562",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4450",
    "elev": "2086",
    "icao": "PARC",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "ARD",
    "lat": "35.1437",
    "lon": "-93.1848",
    "name": "Alor Island",
    "city": "Alor Island",
    "state": "",
    "country": "Indonesia",
    "woeid": "23424846",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2952",
    "elev": "39",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ARH",
    "lat": "64.4465",
    "lon": "40.4242",
    "name": "Arkhangelsk Airport",
    "city": "Arkhangel'sk",
    "state": "Arkhangelrskaya Oblast",
    "country": "Russia",
    "woeid": "12515724",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ULAA",
    "direct_flights": "13",
    "carriers": "5"
  },
  {
    "code": "ARI",
    "lat": "-18.3458",
    "lon": "-70.3358",
    "name": "Chacalluta Airport",
    "city": "Arica",
    "state": "Tarapaca",
    "country": "Chile",
    "woeid": "12512313",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7119",
    "elev": "180",
    "icao": "SCAR",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "ARK",
    "lat": "-3.36515",
    "lon": "36.6738",
    "name": "Arusha Airport",
    "city": "Arusha",
    "state": "Arusha",
    "country": "Tanzania",
    "woeid": "24554855",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "HTAR",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "ARM",
    "lat": "-30.5308",
    "lon": "151.618",
    "name": "Armidale Airport",
    "city": "Armidale",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510590",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5702",
    "elev": "3556",
    "icao": "YARM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ARN",
    "lat": "59.6521",
    "lon": "17.9317",
    "name": "Arlanda Airport",
    "city": "M\u00e4rst",
    "state": "Stockholm",
    "country": "Sweden",
    "woeid": "12517610",
    "tz": "Europe\/Stockholm",
    "phone": "+46 8 797 6000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.arlanda.se",
    "runway_length": "10827",
    "elev": "123",
    "icao": "ESSA",
    "direct_flights": "126",
    "carriers": "76"
  },
  {
    "code": "ART",
    "lat": "43.9942",
    "lon": "-76.0232",
    "name": "Watertown International Airport",
    "city": "Dexter",
    "state": "New York",
    "country": "United States",
    "woeid": "12522348",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "325",
    "icao": "KART",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ARU",
    "lat": "-21.1436",
    "lon": "-50.4261",
    "name": "Aracatuba Airport",
    "city": "Ara\u00e7atuba",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511026",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6955",
    "elev": "1358",
    "icao": "SBAU",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ARV",
    "lat": "45.9296",
    "lon": "-89.7371",
    "name": "Lakelan-Noble F. Lee Memerial Field Airport",
    "city": "Woodruff",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12521141",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1628",
    "icao": "KARV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ARW",
    "lat": "46.1805",
    "lon": "21.2609",
    "name": "Ceala Airport",
    "city": "Arad",
    "state": "Arad",
    "country": "Romania",
    "woeid": "12515518",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroportularad.ro\/index_en.html",
    "runway_length": "6562",
    "elev": "351",
    "icao": "LRAR",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "ASA",
    "lat": "31.4124",
    "lon": "-97.0528",
    "name": "Assab",
    "city": "Assab",
    "state": "",
    "country": "Ethiopia",
    "woeid": "23424808",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "46",
    "icao": "HHSB",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ASB",
    "lat": "37.9842",
    "lon": "58.3294",
    "name": "Ashkhabad Northwest Airport",
    "city": "Ashgabat",
    "state": "Ashkhabad",
    "country": "Turkmenistan",
    "woeid": "12517960",
    "tz": "Asia\/Ashgabat",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "689",
    "icao": "UTAA",
    "direct_flights": "15",
    "carriers": "8"
  },
  {
    "code": "ASD",
    "lat": "24.6978",
    "lon": "-77.7961",
    "name": "Andros Town Airport",
    "city": "Fresh Creek",
    "state": "Central Andros",
    "country": "Bahamas",
    "woeid": "12510859",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "5",
    "icao": "MYAF",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ASE",
    "lat": "39.2197",
    "lon": "-106.864",
    "name": "Aspen Pitkin County Airport-Sardy Field",
    "city": "Aspen",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12518679",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7003",
    "elev": "7815",
    "icao": "KASE",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "ASF",
    "lat": "46.2971",
    "lon": "48.0515",
    "name": "Astrakhan Southeast Airport",
    "city": "Astrakhan'",
    "state": "Astrakhanskaya Oblast",
    "country": "Russia",
    "woeid": "12515745",
    "tz": "Europe\/Samara",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "URWA",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "ASI",
    "lat": "-7.93586",
    "lon": "-14.4081",
    "name": "Wideawake Fld",
    "city": "Georgetown, Ascension Island",
    "state": "Ascension",
    "country": "St. Helena",
    "woeid": "12466481",
    "tz": "Atlantic\/St_Helena",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "FHAW",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ASJ",
    "lat": "28.431",
    "lon": "129.712",
    "name": "Amami Airport",
    "city": "Amami O Shima",
    "state": "Mie Prefecture",
    "country": "Japan",
    "woeid": "12513942",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4070",
    "elev": "59",
    "icao": "RJKA",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ASM",
    "lat": "15.2906",
    "lon": "38.9103",
    "name": "Yohannes Iv International Airport",
    "city": "Asmara",
    "state": "Maekel",
    "country": "Eritrea",
    "woeid": "12512750",
    "tz": "Africa\/Asmara",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "7661",
    "icao": "HHAS",
    "direct_flights": "9",
    "carriers": "7"
  },
  {
    "code": "ASO",
    "lat": "10.0611",
    "lon": "34.5344",
    "name": "Ethiopia",
    "city": "Asosa",
    "state": "Welega",
    "country": "Ethiopia",
    "woeid": "23424808",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3346",
    "elev": "5260",
    "icao": "HASO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ASP",
    "lat": "-23.802",
    "lon": "133.892",
    "name": "Alice Springs Airport",
    "city": "Alice Springs",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "12510584",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "1789",
    "icao": "YBAS",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "ASR",
    "lat": "38.7708",
    "lon": "35.4939",
    "name": "Erkilet Airport",
    "city": "Kayseri",
    "state": "Kayseri",
    "country": "Turkey",
    "woeid": "12517886",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "3571",
    "icao": "LTAU",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "ASU",
    "lat": "-25.2397",
    "lon": "-57.5189",
    "name": "Silvio Pettirossi International Airport",
    "city": "Colonia Mariano Roque Alonso",
    "state": "Central",
    "country": "Paraguay",
    "woeid": "12515187",
    "tz": "America\/Asuncion",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11000",
    "elev": "292",
    "icao": "SGAS",
    "direct_flights": "9",
    "carriers": "8"
  },
  {
    "code": "ASV",
    "lat": "-2.63333",
    "lon": "37.25",
    "name": "Amboseli",
    "city": "Amboseli",
    "state": "Rift Valley",
    "country": "Kenya",
    "woeid": "1528663",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "3755",
    "icao": "HKAM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ASW",
    "lat": "23.965",
    "lon": "32.8217",
    "name": "Aswan Airport",
    "city": "Aswan",
    "state": "Aswan",
    "country": "Egypt",
    "woeid": "12512666",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11155",
    "elev": "656",
    "icao": "HESN",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "ATB",
    "lat": "17.7",
    "lon": "33.9833",
    "name": "Atbara",
    "city": "Atbara",
    "state": "Nahr an Nil",
    "country": "Sudan",
    "woeid": "1431005",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1181",
    "icao": "HSAT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ATC",
    "lat": "24.6287",
    "lon": "-75.6734",
    "name": "Arthur's Town Airport",
    "city": "Arthur Town",
    "state": "Cat Island",
    "country": "Bahamas",
    "woeid": "12524017",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MYCA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ATD",
    "lat": "-8.86667",
    "lon": "161.033",
    "name": "Atoifi",
    "city": "Atoifi",
    "state": "Malaita",
    "country": "Solomon Islands",
    "woeid": "1020493",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1968",
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ATH",
    "lat": "37.8937",
    "lon": "23.7235",
    "name": "Eleftherios Venizelos International Airport",
    "city": "Athens",
    "state": "Attiki",
    "country": "Greece",
    "woeid": "12513286",
    "tz": "Europe\/Athens",
    "phone": "0030 210 353 0001",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aia.gr",
    "runway_length": "13123",
    "elev": "308",
    "icao": "LGAV",
    "direct_flights": "117",
    "carriers": "81"
  },
  {
    "code": "ATK",
    "lat": "70.4639",
    "lon": "-157.331",
    "name": "Atqasuk Airport",
    "city": "Atqasuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524564",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1250",
    "elev": "65",
    "icao": "PATQ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ATL",
    "lat": "33.6558",
    "lon": "-84.4333",
    "name": "Hartsfield-Jackson Atlanta International Airport",
    "city": "Atlanta",
    "state": "Georgia",
    "country": "United States",
    "woeid": "12522118",
    "tz": "America\/New_York",
    "phone": "(654) 57 8833",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.atlanta-airport.com\/",
    "runway_length": "11889",
    "elev": "1026",
    "icao": "KATL",
    "direct_flights": "253",
    "carriers": "60"
  },
  {
    "code": "ATM",
    "lat": "-3.2506",
    "lon": "-52.2517",
    "name": "Altamira Airport",
    "city": "Altamira",
    "state": "Para",
    "country": "Brazil",
    "woeid": "12511018",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6572",
    "elev": "367",
    "icao": "SBHT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ATQ",
    "lat": "31.7131",
    "lon": "74.7955",
    "name": "Raja Sansi Airport",
    "city": "Raja Sansi",
    "state": "Punjab",
    "country": "India",
    "woeid": "12513536",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9250",
    "elev": "754",
    "icao": "VIAR",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "ATR",
    "lat": "20.5067",
    "lon": "-13.0436",
    "name": "Atar Airport",
    "city": "Atar",
    "state": "Adrar",
    "country": "Mauritania",
    "woeid": "12514800",
    "tz": "Africa\/Nouakchott",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "758",
    "icao": "GQPA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ATT",
    "lat": "60.865",
    "lon": "-162.276",
    "name": "Atmautluak Airport",
    "city": "Atmautluak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524566",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2250",
    "elev": "17",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "ATW",
    "lat": "44.2603",
    "lon": "-88.5111",
    "name": "Outagamie County Airport",
    "city": "Appleton",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12521262",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.atwairport.com\/",
    "runway_length": "7001",
    "elev": "918",
    "icao": "KATW",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "ATY",
    "lat": "44.9216",
    "lon": "-97.1609",
    "name": "Watertown Municipal Airport",
    "city": "Watertown",
    "state": "South Dakota",
    "country": "United States",
    "woeid": "12522349",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6895",
    "elev": "1748",
    "icao": "KATY",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "ATZ",
    "lat": "27.0411",
    "lon": "31.0114",
    "name": "Asyut Airport",
    "city": "Asyut",
    "state": "Asyut",
    "country": "Egypt",
    "woeid": "12512667",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "774",
    "icao": "HEAT",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "AUA",
    "lat": "12.5022",
    "lon": "-70.0139",
    "name": "Reina Beatrix International Airport",
    "city": "Aruba",
    "state": "",
    "country": "Aruba",
    "woeid": "12510298",
    "tz": "America\/Aruba",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportaruba.com",
    "runway_length": "9000",
    "elev": "59",
    "icao": "TNCA",
    "direct_flights": "25",
    "carriers": "24"
  },
  {
    "code": "AUC",
    "lat": "7.0703",
    "lon": "-70.7406",
    "name": "Santiago Perez Airport",
    "city": "Arauca",
    "state": "Caldas",
    "country": "Colombia",
    "woeid": "12512414",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6888",
    "elev": "400",
    "icao": "SKUC",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "AUG",
    "lat": "44.3181",
    "lon": "-69.7933",
    "name": "Augusta State Airport",
    "city": "Augusta",
    "state": "Maine",
    "country": "United States",
    "woeid": "12518700",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5140",
    "elev": "353",
    "icao": "KAUG",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "AUH",
    "lat": "24.4331",
    "lon": "54.6489",
    "name": "Abu Dhabi International Airport",
    "city": "Abu Dhabi",
    "state": "Abu Dhabi",
    "country": "United Arab Emirates",
    "woeid": "12517727",
    "tz": "Asia\/Dubai",
    "phone": "+971 2-5757500",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dcaauh.gov.ae\/english\/abudhabi\/introduction.htm#",
    "runway_length": "10499",
    "elev": "15",
    "icao": "OMAA",
    "direct_flights": "76",
    "carriers": "45"
  },
  {
    "code": "AUK",
    "lat": "62.6833",
    "lon": "-164.633",
    "name": "Alakanuk Airport",
    "city": "Alakanuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524567",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2400",
    "elev": "10",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "AUQ",
    "lat": "-9.7719",
    "lon": "-139.015",
    "name": "Atuona Airport",
    "city": "Atuona",
    "state": "Marquesas Islands",
    "country": "French Polynesia",
    "woeid": "12523068",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3691",
    "elev": "1473",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "AUR",
    "lat": "44.8984",
    "lon": "2.41982",
    "name": "Aurillac Airport",
    "city": "Aurillac",
    "state": "Auvergne",
    "country": "France",
    "woeid": "12512848",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "2096",
    "icao": "LFLW",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AUS",
    "lat": "30.2027",
    "lon": "-97.6653",
    "name": "Austin-Bergstrom International Airport",
    "city": "Austin",
    "state": "Texas",
    "country": "United States",
    "woeid": "23418445",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.abia.org\/",
    "runway_length": "12248",
    "elev": "542",
    "icao": "KAUS",
    "direct_flights": "50",
    "carriers": "37"
  },
  {
    "code": "AUX",
    "lat": "-7.2281",
    "lon": "-48.2403",
    "name": "Araguaina Airport",
    "city": "Araguaina",
    "state": "Norte",
    "country": "Brazil",
    "woeid": "12511029",
    "tz": "America\/Araguaina",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "771",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "AUY",
    "lat": "-20.1909",
    "lon": "169.825",
    "name": "Vanuatu",
    "city": "Aneityum",
    "state": "Tafea",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2001",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AVL",
    "lat": "35.4349",
    "lon": "-82.5373",
    "name": "Asheville Regional Airport",
    "city": "Fletcher",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12518672",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flyavl.com\/",
    "runway_length": "8001",
    "elev": "2165",
    "icao": "KAVL",
    "direct_flights": "7",
    "carriers": "10"
  },
  {
    "code": "AVN",
    "lat": "43.9056",
    "lon": "4.89951",
    "name": "Avignon-Caumont Airport",
    "city": "Avignon",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "12523803",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "118",
    "icao": "LFMV",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "AVP",
    "lat": "41.3354",
    "lon": "-75.7294",
    "name": "Wilkes Barre Scranton International Airport",
    "city": "Pittston",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12522441",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7501",
    "elev": "957",
    "icao": "KAVP",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "AVU",
    "lat": "-9.85",
    "lon": "160.417",
    "name": "Avu Avu",
    "city": "Avu Avu",
    "state": "Guadalcanal",
    "country": "Solomon Islands",
    "woeid": "1020506",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2210",
    "elev": "8",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AVV",
    "lat": "-38.0408",
    "lon": "144.468",
    "name": "Avalon Airport",
    "city": "Lara",
    "state": "Victoria",
    "country": "Australia",
    "woeid": "12510591",
    "tz": "Australia\/Melbourne",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "34",
    "icao": "YMAV",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "AWB",
    "lat": "-8.01667",
    "lon": "142.75",
    "name": "Awaba",
    "city": "Awaba",
    "state": "",
    "country": "Papua New Guinea",
    "woeid": "23424926",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2300",
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AWD",
    "lat": "-19.2501",
    "lon": "169.598",
    "name": "Aniwa Airport",
    "city": "Aniwa",
    "state": "Tafea",
    "country": "Vanuatu",
    "woeid": "12515050",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3085",
    "elev": "69",
    "icao": "NVVB",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AWZ",
    "lat": "31.3378",
    "lon": "48.7597",
    "name": "Ahvaz Airport",
    "city": "Ahvaz",
    "state": "Khuzestan",
    "country": "Iran",
    "woeid": "12513699",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11100",
    "elev": "60",
    "icao": "OIAW",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "AXA",
    "lat": "18.2103",
    "lon": "-63.0575",
    "name": "Wallblake Airport",
    "city": "Anguilla",
    "state": "The Valley",
    "country": "Anguilla",
    "woeid": "12510830",
    "tz": "America\/Anguilla",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3600",
    "elev": "108",
    "icao": "TQPF",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "AXD",
    "lat": "40.8567",
    "lon": "25.9567",
    "name": "Alexandroupolis Airport",
    "city": "Alexandroupolis",
    "state": "Anatoliki Makedonia",
    "country": "Greece",
    "woeid": "12513282",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "24",
    "icao": "LGAL",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "AXM",
    "lat": "4.4564",
    "lon": "-75.7681",
    "name": "El Eden Airport",
    "city": "La Tebaida",
    "state": "Quindio",
    "country": "Colombia",
    "woeid": "12512374",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7825",
    "elev": "3960",
    "icao": "SKAR",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "AXP",
    "lat": "22.4441",
    "lon": "-73.9775",
    "name": "Spring Point Airport",
    "city": "Spring Point",
    "state": "Acklins",
    "country": "Bahamas",
    "woeid": "12510881",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "11",
    "icao": "MYAP",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "AXR",
    "lat": "-15.5098",
    "lon": "-146.538",
    "name": "French Polynesia",
    "city": "Arutua",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2230",
    "elev": "7",
    "icao": "NTGU",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "AXT",
    "lat": "39.6153",
    "lon": "140.219",
    "name": "Akita Airport",
    "city": "Akita-shi",
    "state": "Akita Prefecture",
    "country": "Japan",
    "woeid": "12513940",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "313",
    "icao": "RJSK",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "AXU",
    "lat": "14.147",
    "lon": "38.7726",
    "name": "Axum",
    "city": "Axum",
    "state": "Tigray",
    "country": "Ethiopia",
    "woeid": "1313280",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5347",
    "elev": "7000",
    "icao": "HAAX",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "AYQ",
    "lat": "-25.1872",
    "lon": "130.977",
    "name": "Ayers Rock Airport",
    "city": "Ayers Rock",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "12510821",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "1626",
    "icao": "YAYE",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "AYT",
    "lat": "36.9022",
    "lon": "30.7917",
    "name": "Antalya Airport",
    "city": "Antalya",
    "state": "Antalya",
    "country": "Turkey",
    "woeid": "12517869",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11243",
    "elev": "167",
    "icao": "LTAI",
    "direct_flights": "51",
    "carriers": "24"
  },
  {
    "code": "AZA",
    "lat": "33.3078",
    "lon": "-111.656",
    "name": "Phoenix-Mesa Gateway Airport",
    "city": "Phoenix",
    "state": "Arizona",
    "country": "United States",
    "woeid": "2449808",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flywga.org\/",
    "runway_length": "10",
    "elev": "1",
    "icao": "KIWA",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "AZD",
    "lat": "31.905",
    "lon": "54.2786",
    "name": "Yazd Airport",
    "city": "Yezd",
    "state": "Yazd",
    "country": "Iran",
    "woeid": "12513771",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "4066",
    "icao": "OIYY",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "AZN",
    "lat": "40.7324",
    "lon": "72.2931",
    "name": "Andizhan Airport",
    "city": "Andijon",
    "state": "Andijon",
    "country": "Uzbekistan",
    "woeid": "12522576",
    "tz": "Asia\/Tashkent",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UTKA",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "AZO",
    "lat": "42.2398",
    "lon": "-85.5563",
    "name": "Kalamazoo-Battle Creek International Airport",
    "city": "Kalamazoo",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12520414",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6499",
    "elev": "874",
    "icao": "KAZO",
    "direct_flights": "5",
    "carriers": "12"
  },
  {
    "code": "AZR",
    "lat": "27.8378",
    "lon": "-0.1864",
    "name": "Touat Airport",
    "city": "Adrar",
    "state": "Adrar",
    "country": "Algeria",
    "woeid": "12510355",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "915",
    "icao": "DAUA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "AZS",
    "lat": "19.2099",
    "lon": "-69.3279",
    "name": "Samana El Catey International Airport",
    "city": "Samana",
    "state": "Samana",
    "country": "Dominican Republic",
    "woeid": "76414",
    "tz": "America\/Santo_Domingo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MDEP",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "BAG",
    "lat": "16.3764",
    "lon": "120.619",
    "name": "Baguio Airport",
    "city": "Baguio City",
    "state": "Cordillera Administrative Region",
    "country": "Philippines",
    "woeid": "12515596",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5512",
    "elev": "4239",
    "icao": "RPUB",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BAH",
    "lat": "26.2736",
    "lon": "50.6234",
    "name": "Bahrain International Airport",
    "city": "Al Muharraq",
    "state": "Al Manamah",
    "country": "Bahrain",
    "woeid": "23388208",
    "tz": "Asia\/Bahrain",
    "phone": "+973 1732 1997",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bahrainairport.com\/bia\/index_bia.htm",
    "runway_length": "13002",
    "elev": "6",
    "icao": "OBBI",
    "direct_flights": "56",
    "carriers": "49"
  },
  {
    "code": "BAL",
    "lat": "37.93",
    "lon": "41.1167",
    "name": "Batman Airport",
    "city": "Batman",
    "state": "Batman",
    "country": "Turkey",
    "woeid": "12517873",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10005",
    "elev": "1819",
    "icao": "LTCJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BAQ",
    "lat": "10.8961",
    "lon": "-74.7789",
    "name": "Ernesto Cortissoz Airport",
    "city": "Soledad",
    "state": "Atlantico",
    "country": "Colombia",
    "woeid": "12512376",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "94",
    "icao": "SKBQ",
    "direct_flights": "15",
    "carriers": "9"
  },
  {
    "code": "BAS",
    "lat": "-7.98333",
    "lon": "155.9",
    "name": "Balalae",
    "city": "Balalae",
    "state": "Western",
    "country": "Solomon Islands",
    "woeid": "1020484",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "5",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BAU",
    "lat": "-22.345",
    "lon": "-49.0531",
    "name": "Bauru Airport",
    "city": "Bauru",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511049",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "2018",
    "icao": "SBBU",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BAV",
    "lat": "40.5679",
    "lon": "109.998",
    "name": "Baotou Airport",
    "city": "Baotou",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "12511987",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "7"
  },
  {
    "code": "BAX",
    "lat": "53.3667",
    "lon": "83.75",
    "name": "Barnaui West Airport",
    "city": "Barnaul",
    "state": "Altayskiy Kray",
    "country": "Russia",
    "woeid": "12515778",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KBAX",
    "direct_flights": "11",
    "carriers": "7"
  },
  {
    "code": "BAY",
    "lat": "47.6589",
    "lon": "23.465",
    "name": "Baia Mare",
    "city": "Baia Mare",
    "state": "",
    "country": "Romania",
    "woeid": "866625",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "604",
    "icao": "LRBM",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "BBA",
    "lat": "-45.9167",
    "lon": "-71.6869",
    "name": "Balmaceda Airport",
    "city": "Balmaceda",
    "state": "Aisen del General Carlos Ibanez ",
    "country": "Chile",
    "woeid": "12512305",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6883",
    "elev": "1713",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "BBI",
    "lat": "20.2466",
    "lon": "85.8152",
    "name": "Bhubaneswar Airport",
    "city": "Bhubaneswar",
    "state": "Orissa",
    "country": "India",
    "woeid": "12513553",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5850",
    "elev": "146",
    "icao": "VEBS",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "BBK",
    "lat": "-17.8333",
    "lon": "25.1667",
    "name": "Kasane Airport",
    "city": "Kasane",
    "state": "Chobe",
    "country": "Botswana",
    "woeid": "12523069",
    "tz": "Africa\/Gaborone",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3150",
    "elev": "3060",
    "icao": "FBKE",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BBN",
    "lat": "3.73333",
    "lon": "115.467",
    "name": "Bario Airport",
    "city": "Nanga Medamit",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12523070",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2100",
    "elev": "3480",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BBO",
    "lat": "10.3917",
    "lon": "44.95",
    "name": "Berbera Airport",
    "city": "Berbera",
    "state": "Woqooyi Galbeed",
    "country": "Somalia",
    "woeid": "12517527",
    "tz": "Africa\/Mogadishu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13615",
    "elev": "30",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BBS",
    "lat": "51.3252",
    "lon": "-0.84173",
    "name": "Blackbushe Airport",
    "city": "Camberley",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518042",
    "tz": "Europe\/London",
    "phone": "+44 (0) 1252 879449",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.blackbusheairport.co.uk",
    "runway_length": "4436",
    "elev": "329",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BBU",
    "lat": "44.4966",
    "lon": "26.087",
    "name": "Aeroportul National Bucuresti-Baneasa",
    "city": "Bucharest",
    "state": "Bucuresti",
    "country": "Romania",
    "woeid": "23388350",
    "tz": "Europe\/Bucharest",
    "phone": "+40 21 232 0020",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "295",
    "icao": "LRBS",
    "direct_flights": "32",
    "carriers": "5"
  },
  {
    "code": "BCA",
    "lat": "20.3644",
    "lon": "-74.5064",
    "name": "Baracoa Airport",
    "city": "Baracoa",
    "state": "Guantanamo",
    "country": "Cuba",
    "woeid": "12512452",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "38",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BCD",
    "lat": "10.6444",
    "lon": "122.929",
    "name": "Bacolod Airport",
    "city": "Bacolod City",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "12515595",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5932",
    "elev": "20",
    "icao": "RPVB",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "BCI",
    "lat": "-23.5635",
    "lon": "145.305",
    "name": "Barcaldine Aerodrome",
    "city": "Barcaldine",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510599",
    "tz": "Australia\/Lindeman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4510",
    "elev": "878",
    "icao": "YBAR",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BCL",
    "lat": "10.7856",
    "lon": "-83.5966",
    "name": "Barra Colorado Airport",
    "city": "Colorado",
    "state": "Limon",
    "country": "Costa Rica",
    "woeid": "12524312",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2296",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BCM",
    "lat": "46.5283",
    "lon": "26.8997",
    "name": "Luizi Calugara Airport",
    "city": "Bac\u00e6u",
    "state": "Bacau",
    "country": "Romania",
    "woeid": "12515549",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LRBC",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "BCN",
    "lat": "41.3006",
    "lon": "2.07976",
    "name": "Barcelona International Airport",
    "city": "El Prat de Llobregat",
    "state": "Catalonia",
    "country": "Spain",
    "woeid": "22411879",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aena.es",
    "runway_length": "11001",
    "elev": "15",
    "icao": "LEBL",
    "direct_flights": "145",
    "carriers": "90"
  },
  {
    "code": "BDA",
    "lat": "32.3675",
    "lon": "-64.6903",
    "name": "Bermuda International Airport",
    "city": "St. George",
    "state": "Pembroke",
    "country": "Bermuda",
    "woeid": "12523071",
    "tz": "Atlantic\/Bermuda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9713",
    "elev": "12",
    "icao": "TXKF",
    "direct_flights": "13",
    "carriers": "12"
  },
  {
    "code": "BDB",
    "lat": "-24.9046",
    "lon": "152.322",
    "name": "Bundaberg Airport",
    "city": "Bundaberg",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510621",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5030",
    "elev": "107",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BDD",
    "lat": "-10.1214",
    "lon": "142.142",
    "name": "Badu Island Airport",
    "city": "Badu Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510593",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BDH",
    "lat": "26.5333",
    "lon": "54.8239",
    "name": "Bandar Lengeh Airport",
    "city": "Bandar Abbas",
    "state": "Hormozgan",
    "country": "Iran",
    "woeid": "12513704",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6650",
    "elev": "33",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BDJ",
    "lat": "-3.4425",
    "lon": "114.757",
    "name": "Syamsuddin Noor Airport",
    "city": "Banjarmasin",
    "state": "Kalimantan Selatan",
    "country": "Indonesia",
    "woeid": "12513514",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6135",
    "elev": "69",
    "icao": "WRBB",
    "direct_flights": "3",
    "carriers": "6"
  },
  {
    "code": "BDL",
    "lat": "41.9271",
    "lon": "-72.6816",
    "name": "Bradley International Airport",
    "city": "Windsor Locks",
    "state": "Connecticut",
    "country": "United States",
    "woeid": "12518915",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9502",
    "elev": "174",
    "icao": "KBDL",
    "direct_flights": "41",
    "carriers": "31"
  },
  {
    "code": "BDO",
    "lat": "-6.9017",
    "lon": "107.576",
    "name": "Husein Sastranegara Airport",
    "city": "Bandung",
    "state": "Jawa Barat",
    "country": "Indonesia",
    "woeid": "12513471",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6519",
    "elev": "2430",
    "icao": "WICC",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "BDP",
    "lat": "26.5667",
    "lon": "88.0833",
    "name": "Bhadrapur",
    "city": "Bhadrapur",
    "state": "West Bengal",
    "country": "Nepal",
    "woeid": "2269155",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5133",
    "elev": "300",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BDQ",
    "lat": "22.3367",
    "lon": "73.2264",
    "name": "Vadodara Airport",
    "city": "Vadodara",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513692",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8100",
    "elev": "123",
    "icao": "VABO",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "BDR",
    "lat": "41.1636",
    "lon": "-73.1283",
    "name": "Igor I Sikorsky Memorial Airport",
    "city": "Stratford",
    "state": "Connecticut",
    "country": "United States",
    "woeid": "12520279",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4761",
    "elev": "10",
    "icao": "KBDR",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "BDS",
    "lat": "40.6626",
    "lon": "17.9426",
    "name": "Casale Airport",
    "city": "Brindisi",
    "state": "Puglia",
    "country": "Italy",
    "woeid": "12513811",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8622",
    "elev": "48",
    "icao": "LIBR",
    "direct_flights": "17",
    "carriers": "19"
  },
  {
    "code": "BDU",
    "lat": "69.0589",
    "lon": "18.539",
    "name": "Bardufoss Airport",
    "city": "Bardufoss",
    "state": "Troms Fylke",
    "country": "Norway",
    "woeid": "12515103",
    "tz": "Europe\/Oslo",
    "phone": "+47 77 83 02 00",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2443",
    "elev": "252",
    "icao": "ENDU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BEB",
    "lat": "57.4756",
    "lon": "-7.37041",
    "name": "Benbecula Airport",
    "city": "Balivanich",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22453706",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6017",
    "elev": "19",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "BEG",
    "lat": "44.8192",
    "lon": "20.3122",
    "name": "Surcin Airport",
    "city": "Sur\u010din",
    "state": "Beograd",
    "country": "Serbia",
    "woeid": "12517583",
    "tz": "Europe\/Belgrade",
    "phone": "+381 11 209 4000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.beg.aero",
    "runway_length": "11155",
    "elev": "335",
    "icao": "LYBE",
    "direct_flights": "41",
    "carriers": "22"
  },
  {
    "code": "BEL",
    "lat": "-1.3844",
    "lon": "-48.4783",
    "name": "Val de Caes International Airport",
    "city": "Belem",
    "state": "Para",
    "country": "Brazil",
    "woeid": "12511369",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "52",
    "icao": "SBBE",
    "direct_flights": "12",
    "carriers": "7"
  },
  {
    "code": "BEN",
    "lat": "35.6425",
    "lon": "-87.1192",
    "name": "Benina Intl",
    "city": "Benghazi",
    "state": "Banghazi",
    "country": "Libya",
    "woeid": "1352831",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11800",
    "elev": "433",
    "icao": "HLLB",
    "direct_flights": "15",
    "carriers": "6"
  },
  {
    "code": "BES",
    "lat": "48.4458",
    "lon": "-4.41511",
    "name": "Guipavas Airport",
    "city": "Guipavas",
    "state": "Brittany",
    "country": "France",
    "woeid": "12523072",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "325",
    "icao": "LFRB",
    "direct_flights": "14",
    "carriers": "9"
  },
  {
    "code": "BET",
    "lat": "60.7788",
    "lon": "-161.847",
    "name": "Bethel Airport",
    "city": "Bethel",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12518815",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6399",
    "elev": "131",
    "icao": "PABE",
    "direct_flights": "29",
    "carriers": "11"
  },
  {
    "code": "BEU",
    "lat": "-24.3544",
    "lon": "139.463",
    "name": "Bedourie Aerodrome",
    "city": "Bedourie",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510603",
    "tz": "Australia\/Lindeman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3478",
    "elev": "300",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BEW",
    "lat": "-19.7958",
    "lon": "34.9089",
    "name": "Beira Airport",
    "city": "Beira",
    "state": "Sofala",
    "country": "Mozambique",
    "woeid": "12515014",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "33",
    "icao": "FQBR",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "BEY",
    "lat": "33.825",
    "lon": "35.4925",
    "name": "Beirut International Airport",
    "city": "Beirut",
    "state": "Jabal Lubnan",
    "country": "Lebanon",
    "woeid": "23388329",
    "tz": "Asia\/Beirut",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.beirutairport.gov.lb\/indexflash.html",
    "runway_length": "10663",
    "elev": "87",
    "icao": "OLBA",
    "direct_flights": "51",
    "carriers": "47"
  },
  {
    "code": "BFD",
    "lat": "41.7964",
    "lon": "-78.6407",
    "name": "Bradford Regional Airport",
    "city": "Lewis Run",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12518914",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6499",
    "elev": "2143",
    "icao": "KBFD",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BFE",
    "lat": "52",
    "lon": "8.5",
    "name": "Bielefeld",
    "city": "Bielefeld",
    "state": "North-Rhine-Westphalia",
    "country": "Germany",
    "woeid": "20066058",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KBFE",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "BFF",
    "lat": "41.8683",
    "lon": "-103.597",
    "name": "William B Heilig Field Airport",
    "city": "Scottsbluff",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12522448",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8280",
    "elev": "3965",
    "icao": "KBFF",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "BFI",
    "lat": "47.5369",
    "lon": "-122.304",
    "name": "King County International Airport-Boeing Field",
    "city": "Seattle",
    "state": "Washington",
    "country": "United States",
    "woeid": "12518877",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10001",
    "elev": "17",
    "icao": "KBFI",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "BFL",
    "lat": "35.4288",
    "lon": "-119.044",
    "name": "Kern County-Meadows Field Airport",
    "city": "Bakersfield",
    "state": "California",
    "country": "United States",
    "woeid": "12520881",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9100",
    "elev": "490",
    "icao": "KBFL",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "BFN",
    "lat": "-29.0942",
    "lon": "26.3027",
    "name": "J B M Hertzog Airport",
    "city": "Bloemfontein",
    "state": "Free State",
    "country": "South Africa",
    "woeid": "12517423",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "FABL",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "BFS",
    "lat": "54.6542",
    "lon": "-6.225",
    "name": "Aldergrove Airport",
    "city": "Crumlin",
    "state": "Northern Ireland",
    "country": "United Kingdom",
    "woeid": "12518031",
    "tz": "Europe\/London",
    "phone": "00 44 (0)28 9448 4848",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.belfastairport.com",
    "runway_length": "2780",
    "elev": "267",
    "icao": "EGAA",
    "direct_flights": "49",
    "carriers": "14"
  },
  {
    "code": "BFV",
    "lat": "15.2295",
    "lon": "103.253",
    "name": "Buri Ram",
    "city": "Buri Ram",
    "state": "Buriram",
    "country": "Thailand",
    "woeid": "28341176",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BGA",
    "lat": "7.1217",
    "lon": "-73.1883",
    "name": "Palonegro Airport",
    "city": "Bucaramanga",
    "state": "Santander",
    "country": "Colombia",
    "woeid": "12512401",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7415",
    "elev": "3896",
    "icao": "SKBG",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "BGF",
    "lat": "4.3961",
    "lon": "18.5203",
    "name": "Bangui M Poko Airport",
    "city": "Bangui",
    "state": "Bangui",
    "country": "Central African Republic",
    "woeid": "12512446",
    "tz": "Africa\/Bangui",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "1204",
    "icao": "FEFF",
    "direct_flights": "6",
    "carriers": "8"
  },
  {
    "code": "BGI",
    "lat": "13.0719",
    "lon": "-59.4939",
    "name": "Grantley Adams International Airport",
    "city": "Bridgetown",
    "state": "Christ Church",
    "country": "Barbados",
    "woeid": "12510833",
    "tz": "America\/Barbados",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "18000",
    "elev": "169",
    "icao": "TBPB",
    "direct_flights": "24",
    "carriers": "16"
  },
  {
    "code": "BGM",
    "lat": "42.2082",
    "lon": "-75.9825",
    "name": "Greater Binghamton Edwin A Link Field Airport",
    "city": "Johnson City",
    "state": "New York",
    "country": "United States",
    "woeid": "12519598",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6298",
    "elev": "1630",
    "icao": "KBGM",
    "direct_flights": "3",
    "carriers": "8"
  },
  {
    "code": "BGO",
    "lat": "60.2907",
    "lon": "5.22065",
    "name": "Bergen Flesland Airport",
    "city": "Blomsterdalen",
    "state": "Hordaland Fylke",
    "country": "Norway",
    "woeid": "12515104",
    "tz": "Europe\/Oslo",
    "phone": "+47 55 99 80 00",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.avinor.no\/English\/Airports\/Bergen_Airport,_Flesland\/",
    "runway_length": "8038",
    "elev": "165",
    "icao": "ENBR",
    "direct_flights": "37",
    "carriers": "21"
  },
  {
    "code": "BGR",
    "lat": "44.8086",
    "lon": "-68.8167",
    "name": "Bangor International Airport",
    "city": "Bangor",
    "state": "Maine",
    "country": "United States",
    "woeid": "12518734",
    "tz": "America\/New_York",
    "phone": "207-992-4600",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flybangor.com",
    "runway_length": "11439",
    "elev": "192",
    "icao": "KBGR",
    "direct_flights": "10",
    "carriers": "7"
  },
  {
    "code": "BGW",
    "lat": "33.3328",
    "lon": "44.3639",
    "name": "Al Muthana Airport",
    "city": "Baghdad",
    "state": "Baghdad",
    "country": "Iraq",
    "woeid": "12513890",
    "tz": "Asia\/Baghdad",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "112",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "BGY",
    "lat": "45.6664",
    "lon": "9.699",
    "name": "Orio Al Serio Airport",
    "city": "Grassobbio",
    "state": "Lombardy",
    "country": "Italy",
    "woeid": "22309691",
    "tz": "Europe\/Rome",
    "phone": "035311258",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sacbo.it\/",
    "runway_length": "9186",
    "elev": "779",
    "icao": "LIME",
    "direct_flights": "70",
    "carriers": "19"
  },
  {
    "code": "BHB",
    "lat": "44.4461",
    "lon": "-68.3615",
    "name": "Hancock County-Bar Harbor Airport",
    "city": "Ellsworth",
    "state": "Maine",
    "country": "United States",
    "woeid": "12520074",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5196",
    "elev": "84",
    "icao": "KBHB",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BHD",
    "lat": "54.6151",
    "lon": "-5.87096",
    "name": "George Best Belfast City Airport",
    "city": "Belfast",
    "state": "Northern Ireland",
    "country": "United Kingdom",
    "woeid": "23387943",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "15",
    "icao": "EGAC",
    "direct_flights": "24",
    "carriers": "24"
  },
  {
    "code": "BHE",
    "lat": "-41.52",
    "lon": "173.87",
    "name": "Woodbourne Airport",
    "city": "Blenheim",
    "state": "Marlborough",
    "country": "New Zealand",
    "woeid": "12515181",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4675",
    "elev": "118",
    "icao": "NZWB",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BHG",
    "lat": "15.7636",
    "lon": "-84.551",
    "name": "Brus Laguna Airport",
    "city": "Brus Laguna",
    "state": "Gracias a Dios",
    "country": "Honduras",
    "woeid": "12524352",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BHH",
    "lat": "19.9817",
    "lon": "42.6267",
    "name": "Bisha Airport",
    "city": "Bisha",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "12517332",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "3829",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BHI",
    "lat": "-38.7261",
    "lon": "-62.1539",
    "name": "Bahia Blanca Cte Espora Naval Air Base",
    "city": "Punta Alta",
    "state": "Buenos Aires",
    "country": "Argentina",
    "woeid": "12510461",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8365",
    "elev": "246",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BHJ",
    "lat": "23.2875",
    "lon": "69.6706",
    "name": "Bhuj Airport",
    "city": "Bhuj",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513554",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8317",
    "elev": "268",
    "icao": "VABJ",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BHK",
    "lat": "39.7948",
    "lon": "64.4895",
    "name": "Bukhara Airport",
    "city": "Bukhara",
    "state": "Bukhoro",
    "country": "Uzbekistan",
    "woeid": "12522588",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UTSB",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "BHM",
    "lat": "33.5604",
    "lon": "-86.7492",
    "name": "Birmingham International Airport",
    "city": "Birmingham",
    "state": "Alabama",
    "country": "United States",
    "woeid": "12518836",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "644",
    "icao": "KBHM",
    "direct_flights": "34",
    "carriers": "24"
  },
  {
    "code": "BHO",
    "lat": "23.2844",
    "lon": "77.3383",
    "name": "Bairagarh Airport",
    "city": "Bhopal",
    "state": "Madhya Pradesh",
    "country": "India",
    "woeid": "12513540",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6017",
    "elev": "1716",
    "icao": "VABP",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "BHQ",
    "lat": "-32.0031",
    "lon": "141.466",
    "name": "Broken Hill Airport",
    "city": "Broken Hill",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510619",
    "tz": "Australia\/Melbourne",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8251",
    "elev": "946",
    "icao": "YBHI",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BHR",
    "lat": "25.9173",
    "lon": "50.6071",
    "name": "Bharatpur",
    "city": "Bharatpur",
    "state": "Central",
    "country": "Nepal",
    "woeid": "2269055",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3800",
    "elev": "600",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BHS",
    "lat": "-33.4121",
    "lon": "149.657",
    "name": "Bathurst Airport",
    "city": "Bathurst",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510601",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5595",
    "elev": "2434",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BHU",
    "lat": "21.7536",
    "lon": "72.1867",
    "name": "Bhavnagar Airport",
    "city": "Bhavnagar",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513549",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6300",
    "elev": "34",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BHV",
    "lat": "29.3444",
    "lon": "71.7125",
    "name": "Bahawalpur Airport",
    "city": "Bahawalpur",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "12515225",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "100",
    "icao": "OPBW",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BHX",
    "lat": "52.4531",
    "lon": "-1.73847",
    "name": "Birmingham International Airport",
    "city": "Birmingham",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22454274",
    "tz": "Europe\/London",
    "phone": "+44 (0)8707 335511",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bhx.co.uk\/",
    "runway_length": "7398",
    "elev": "325",
    "icao": "EGBB",
    "direct_flights": "103",
    "carriers": "45"
  },
  {
    "code": "BHY",
    "lat": "21.4833",
    "lon": "109.083",
    "name": "Beihai",
    "city": "Beihai",
    "state": "Guangxi",
    "country": "China",
    "woeid": "2166477",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "BIA",
    "lat": "42.5497",
    "lon": "9.48341",
    "name": "Poretta Airport",
    "city": "Borgo",
    "state": "Corsica",
    "country": "France",
    "woeid": "12512955",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8235",
    "elev": "26",
    "icao": "LFKB",
    "direct_flights": "20",
    "carriers": "8"
  },
  {
    "code": "BID",
    "lat": "41.1696",
    "lon": "-71.58",
    "name": "Block Island State Airport",
    "city": "Block Island",
    "state": "Rhode Island",
    "country": "United States",
    "woeid": "12524577",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "105",
    "icao": "KBID",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BII",
    "lat": "11.5833",
    "lon": "165.383",
    "name": "Enyu Airfield",
    "city": "Bikini Atoll",
    "state": "",
    "country": "Marshall Islands",
    "woeid": "23424932",
    "tz": "Pacific\/Majuro",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BIK",
    "lat": "-1",
    "lon": "136.117",
    "name": "Frans Kaisiepo Airport",
    "city": "Biak",
    "state": "Irian Jaya",
    "country": "Indonesia",
    "woeid": "12513465",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11715",
    "elev": "46",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "BIL",
    "lat": "45.8034",
    "lon": "-108.537",
    "name": "Logan International Airport",
    "city": "Billings",
    "state": "Montana",
    "country": "United States",
    "woeid": "12520688",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10528",
    "elev": "3649",
    "icao": "KBIL",
    "direct_flights": "13",
    "carriers": "14"
  },
  {
    "code": "BIM",
    "lat": "25.6994",
    "lon": "-79.2636",
    "name": "South Bimini Airport",
    "city": "Bailey's Town",
    "state": "Bimini",
    "country": "Bahamas",
    "woeid": "12510880",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "10",
    "icao": "MYBS",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "BIO",
    "lat": "43.3025",
    "lon": "-2.91112",
    "name": "Bilbao Airport",
    "city": "Loiu",
    "state": "Basque Country",
    "country": "Spain",
    "woeid": "12517541",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "136",
    "icao": "LEBB",
    "direct_flights": "37",
    "carriers": "35"
  },
  {
    "code": "BIQ",
    "lat": "43.472",
    "lon": "-1.53137",
    "name": "Anglet Airport",
    "city": "Anglet",
    "state": "Aquitaine",
    "country": "France",
    "woeid": "12512842",
    "tz": "Europe\/Paris",
    "phone": "+33 5 59 43 83 83",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.biarritz.aeroport.fr\/indexen.html",
    "runway_length": "7382",
    "elev": "243",
    "icao": "LFBZ",
    "direct_flights": "14",
    "carriers": "8"
  },
  {
    "code": "BIR",
    "lat": "26.4814",
    "lon": "87.2647",
    "name": "Biratnagar Airport",
    "city": "Biratnagur",
    "state": "Central",
    "country": "Nepal",
    "woeid": "12515132",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "235",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BIS",
    "lat": "46.7745",
    "lon": "-100.757",
    "name": "Bismarck Municipal Airport",
    "city": "Bismarck",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12518843",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8788",
    "elev": "1677",
    "icao": "KBIS",
    "direct_flights": "5",
    "carriers": "8"
  },
  {
    "code": "BJA",
    "lat": "36.7133",
    "lon": "5.0697",
    "name": "Soummam Airport",
    "city": "Bejaia",
    "state": "Bejaia",
    "country": "Algeria",
    "woeid": "12510347",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "20",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "BJB",
    "lat": "37.4933",
    "lon": "57.3007",
    "name": "Bojnord",
    "city": "Bojnord",
    "state": "Khorasan",
    "country": "Iran",
    "woeid": "2220377",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BJF",
    "lat": "70.6031",
    "lon": "29.6948",
    "name": "Batsfjord Airport",
    "city": "Baasfjord",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523932",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2493",
    "elev": "133",
    "icao": "ENBS",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "BJI",
    "lat": "47.5065",
    "lon": "-94.9338",
    "name": "Bemidji-Beltrami County Airport",
    "city": "Bemidji",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12518794",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5699",
    "elev": "1390",
    "icao": "KBJI",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "BJL",
    "lat": "13.3372",
    "lon": "-16.6544",
    "name": "Yundum International Airport",
    "city": "Yundum",
    "state": "Western",
    "country": "Gambia",
    "woeid": "12512990",
    "tz": "Africa\/Banjul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11810",
    "elev": "95",
    "icao": "GBYD",
    "direct_flights": "6",
    "carriers": "9"
  },
  {
    "code": "BJM",
    "lat": "-3.3317",
    "lon": "29.3208",
    "name": "Bujumbura Airport",
    "city": "Bujumbura",
    "state": "Bujumbura",
    "country": "Burundi",
    "woeid": "12511564",
    "tz": "Africa\/Bujumbura",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11810",
    "elev": "2566",
    "icao": "HBBA",
    "direct_flights": "6",
    "carriers": "10"
  },
  {
    "code": "BJR",
    "lat": "11.5564",
    "lon": "37.3687",
    "name": "Ethiopia",
    "city": "Bahar Dar",
    "state": "Misrak Gojam",
    "country": "Ethiopia",
    "woeid": "23424808",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5217",
    "elev": "6020",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BJV",
    "lat": "37.0413",
    "lon": "27.4362",
    "name": "Milas Airport",
    "city": "Bodrum",
    "state": "Mu\u011fla",
    "country": "Turkey",
    "woeid": "12523053",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "7"
  },
  {
    "code": "BJW",
    "lat": "-8.78333",
    "lon": "120.983",
    "name": "Bajawa Airport",
    "city": "Ende",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12523075",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2920",
    "elev": "4326",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BJX",
    "lat": "20.9903",
    "lon": "-101.478",
    "name": "Silao Airport",
    "city": "Silao",
    "state": "Guanajuato",
    "country": "Mexico",
    "woeid": "12514965",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MMLO",
    "direct_flights": "12",
    "carriers": "13"
  },
  {
    "code": "BJZ",
    "lat": "38.8939",
    "lon": "-6.81921",
    "name": "Talavera la Real Airport",
    "city": "Badajoz",
    "state": "Extremadura",
    "country": "Spain",
    "woeid": "12517575",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9350",
    "elev": "607",
    "icao": "LEBZ",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "BKA",
    "lat": "55.5516",
    "lon": "37.9763",
    "name": "Bykovo Airport",
    "city": "Lyubertsy",
    "state": "Moskovskaya Oblast",
    "country": "Russia",
    "woeid": "12515878",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BKC",
    "lat": "65.9833",
    "lon": "-161.167",
    "name": "Buckland Airport",
    "city": "Buckland",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524578",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1870",
    "elev": "30",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "BKI",
    "lat": "5.9386",
    "lon": "116.049",
    "name": "Kota Kinabalu Airport",
    "city": "Kota Kinabalu",
    "state": "Sabah",
    "country": "Malaysia",
    "woeid": "12514995",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9800",
    "elev": "10",
    "icao": "WBKK",
    "direct_flights": "27",
    "carriers": "16"
  },
  {
    "code": "BKK",
    "lat": "13.9144",
    "lon": "100.608",
    "name": "Bangkok International Airport",
    "city": "Lak Si",
    "state": "Bangkok",
    "country": "Thailand",
    "woeid": "12517747",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportthai.co.th\/airportnew\/bia\/html\/",
    "runway_length": "12139",
    "elev": "12",
    "icao": "VTBS",
    "direct_flights": "132",
    "carriers": "113"
  },
  {
    "code": "BKL",
    "lat": "41.5115",
    "lon": "-81.6869",
    "name": "Burke Lakefront Airport",
    "city": "Cleveland",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12518976",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "6198",
    "elev": "584",
    "icao": "KBKL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BKM",
    "lat": "3.94515",
    "lon": "114.402",
    "name": "Malaysia",
    "city": "Bakalalan",
    "state": null,
    "country": "Malaysia",
    "woeid": "23424901",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1800",
    "elev": "2900",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BKO",
    "lat": "12.5361",
    "lon": "-7.9486",
    "name": "Bamako Senou Airport",
    "city": "Kalaban",
    "state": "Bamako",
    "country": "Mali",
    "woeid": "12514773",
    "tz": "Africa\/Bamako",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "1247",
    "icao": "GABS",
    "direct_flights": "17",
    "carriers": "19"
  },
  {
    "code": "BKQ",
    "lat": "-24.4292",
    "lon": "145.429",
    "name": "Blackall Aerodrome",
    "city": "Mount Enniskillen",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510609",
    "tz": "Australia\/Lindeman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5538",
    "elev": "928",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BKS",
    "lat": "-3.8633",
    "lon": "102.339",
    "name": "Padangkemiling Airport",
    "city": "Bengkulu",
    "state": "Bengkulu",
    "country": "Indonesia",
    "woeid": "12513489",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "50",
    "icao": "KBKS",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BKW",
    "lat": "37.7849",
    "lon": "-81.1174",
    "name": "Raleigh County Memorial Airport",
    "city": "Beaver",
    "state": "West Virginia",
    "country": "United States",
    "woeid": "12521519",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6750",
    "elev": "2504",
    "icao": "KBKW",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BKY",
    "lat": "-2.3014",
    "lon": "28.8139",
    "name": "Bukavu Kavumu Airport",
    "city": "Bukavu",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511951",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "5633",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BLA",
    "lat": "10.1103",
    "lon": "-64.6872",
    "name": "Jose Antonio Anzoategui Airport",
    "city": "Pariagu\u00e1n",
    "state": "Anzoategui",
    "country": "Venezuela",
    "woeid": "12522795",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "26",
    "icao": "SVBC",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "BLE",
    "lat": "60.4297",
    "lon": "15.5081",
    "name": "Dala Airport",
    "city": "Borlange",
    "state": "Dalarna",
    "country": "Sweden",
    "woeid": "12517614",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dalaflyget.se\/",
    "runway_length": "7585",
    "elev": "503",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "BLI",
    "lat": "48.7955",
    "lon": "-122.532",
    "name": "Bellingham International Airport",
    "city": "Bellingham",
    "state": "Washington",
    "country": "United States",
    "woeid": "12518790",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "158",
    "icao": "KBLI",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "BLJ",
    "lat": "35.5572",
    "lon": "6.17229",
    "name": "Algeria",
    "city": "Batna",
    "state": "Batna",
    "country": "Algeria",
    "woeid": "23424740",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "3445",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "BLK",
    "lat": "53.7756",
    "lon": "-3.04118",
    "name": "Blackpool Airport",
    "city": "Blackpool",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22454971",
    "tz": "Europe\/London",
    "phone": "08700 273 777",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.blackpoolinternational.com",
    "runway_length": "6001",
    "elev": "34",
    "icao": "EGNH",
    "direct_flights": "13",
    "carriers": "4"
  },
  {
    "code": "BLL",
    "lat": "55.742",
    "lon": "9.15354",
    "name": "Billund Airport",
    "city": "Billund",
    "state": "Syddanmark",
    "country": "Denmark",
    "woeid": "12512589",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.billund-airport.com\/",
    "runway_length": "10171",
    "elev": "247",
    "icao": "EKBI",
    "direct_flights": "38",
    "carriers": "23"
  },
  {
    "code": "BLQ",
    "lat": "44.5345",
    "lon": "11.2903",
    "name": "Bologna Airport",
    "city": "Bologna",
    "state": "Emilia Romagna",
    "country": "Italy",
    "woeid": "12513806",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8435",
    "elev": "125",
    "icao": "LIPE",
    "direct_flights": "52",
    "carriers": "39"
  },
  {
    "code": "BLR",
    "lat": "12.9526",
    "lon": "77.6656",
    "name": "HAL Bangalore International Airport",
    "city": "Bangalore",
    "state": "Karnataka",
    "country": "India",
    "woeid": "12513543",
    "tz": "Asia\/Kolkata",
    "phone": "+91 (0)80 25228460",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10850",
    "elev": "2910",
    "icao": "VOBG",
    "direct_flights": "37",
    "carriers": "33"
  },
  {
    "code": "BLT",
    "lat": "-23.6012",
    "lon": "148.805",
    "name": "Blackwater Aerodrome",
    "city": "Baralaba",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510610",
    "tz": "Australia\/Lindeman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "660",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BLV",
    "lat": "38.5412",
    "lon": "-89.8499",
    "name": "Belleville",
    "city": "Belleville",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12521774",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KBLV",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BLZ",
    "lat": "-15.6764",
    "lon": "34.9714",
    "name": "Chileka International Airport",
    "city": "Lunzu",
    "state": "Blantyre",
    "country": "Malawi",
    "woeid": "12514765",
    "tz": "Africa\/Blantyre",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7710",
    "elev": "2555",
    "icao": "FWCL",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "BMA",
    "lat": "59.3562",
    "lon": "17.9378",
    "name": "Bromma Airport",
    "city": "Bromma",
    "state": "Stockholm",
    "country": "Sweden",
    "woeid": "12517616",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bromma.lfv.se\/",
    "runway_length": "5863",
    "elev": "46",
    "icao": "ESSB",
    "direct_flights": "11",
    "carriers": "8"
  },
  {
    "code": "BME",
    "lat": "-17.9464",
    "lon": "122.233",
    "name": "Broome International Airport",
    "city": "Broome",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510620",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5010",
    "elev": "56",
    "icao": "",
    "direct_flights": "8",
    "carriers": "4"
  },
  {
    "code": "BMI",
    "lat": "40.4841",
    "lon": "-88.9134",
    "name": "Bloomington Normal Airport",
    "city": "Bloomington",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12518861",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "875",
    "icao": "KBMI",
    "direct_flights": "7",
    "carriers": "12"
  },
  {
    "code": "BMK",
    "lat": "53.5797",
    "lon": "6.71294",
    "name": "Borkum Airport",
    "city": "Borkum",
    "state": "Lower Saxony",
    "country": "Germany",
    "woeid": "22176048",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "3",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BMO",
    "lat": "24.2739",
    "lon": "97.2517",
    "name": "Bhamo Airport",
    "city": "Bhamo",
    "state": "Kachin State",
    "country": "Myanmar",
    "woeid": "12510912",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5501",
    "elev": "360",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BMU",
    "lat": "-8.5444",
    "lon": "118.682",
    "name": "Mohammad Salahuddin Airport",
    "city": "Raba",
    "state": "Nusa Tenggara Barat",
    "country": "Indonesia",
    "woeid": "12513483",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "39",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "BMV",
    "lat": "12.6667",
    "lon": "108.05",
    "name": "Ban Me Thaut",
    "city": "Banmethuot",
    "state": "Dak Lak",
    "country": "Vietnam",
    "woeid": "1233132",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1758",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BMW",
    "lat": "28.0269",
    "lon": "1.65284",
    "name": "Algeria",
    "city": "Bordj Badji Mokhtar",
    "state": null,
    "country": "Algeria",
    "woeid": "23424740",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7372",
    "elev": "1303",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BMY",
    "lat": "-19.75",
    "lon": "163.667",
    "name": "Belep Island",
    "city": "Belep Island",
    "state": "Nord",
    "country": "New Caledonia",
    "woeid": "1049677",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1640",
    "elev": "338",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BNA",
    "lat": "36.1342",
    "lon": "-86.6682",
    "name": "Nashville International Airport",
    "city": "Nashville",
    "state": "Tennessee",
    "country": "United States",
    "woeid": "12521083",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8500",
    "elev": "599",
    "icao": "KBNA",
    "direct_flights": "53",
    "carriers": "38"
  },
  {
    "code": "BND",
    "lat": "27.225",
    "lon": "56.3783",
    "name": "Bandar Abbass International Airport",
    "city": "Bandar Abbas",
    "state": "Hormozgan",
    "country": "Iran",
    "woeid": "12513703",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12020",
    "elev": "23",
    "icao": "",
    "direct_flights": "10",
    "carriers": "6"
  },
  {
    "code": "BNE",
    "lat": "-27.3589",
    "lon": "153.122",
    "name": "Brisbane International Airport",
    "city": "Brisbane",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "23388207",
    "tz": "Australia\/Brisbane",
    "phone": "+61 7 3406 3000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bne.com.au\/",
    "runway_length": "11483",
    "elev": "13",
    "icao": "YBBN",
    "direct_flights": "62",
    "carriers": "43"
  },
  {
    "code": "BNI",
    "lat": "6.3161",
    "lon": "5.6",
    "name": "Benin Airport",
    "city": "Benin City",
    "state": "Edo",
    "country": "Nigeria",
    "woeid": "12515060",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "258",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BNJ",
    "lat": "50.7667",
    "lon": "7.16449",
    "name": "Hangelar",
    "city": "Bonn",
    "state": "North-Rhine-Westphalia",
    "country": "Germany",
    "woeid": "695997",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BNK",
    "lat": "-28.8342",
    "lon": "153.555",
    "name": "Ballina Airport",
    "city": "Ballina",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510596",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "YBNA",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "BNN",
    "lat": "65.462",
    "lon": "12.2161",
    "name": "Bronnoy Airport",
    "city": "Bronnoysund",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523928",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2624",
    "elev": "28",
    "icao": "ENBN",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "BNS",
    "lat": "8.6211",
    "lon": "-70.2181",
    "name": "Barinas Airport",
    "city": "Barinas",
    "state": "Barinas",
    "country": "Venezuela",
    "woeid": "12522757",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "666",
    "icao": "SVBI",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BNX",
    "lat": "44.9403",
    "lon": "17.2992",
    "name": "Banja Luka Airport",
    "city": "Banja Luka",
    "state": "Republika Srpska",
    "country": "Bosnia and Herzegovina",
    "woeid": "12510897",
    "tz": "Europe\/Sarajevo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.banjaluka-airport.com\/",
    "runway_length": "8215",
    "elev": "390",
    "icao": "LQBK",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BNY",
    "lat": "-11.2961",
    "lon": "159.801",
    "name": "Bellona Airport",
    "city": "Bellona",
    "state": "Central",
    "country": "Solomon Islands",
    "woeid": "12511005",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2181",
    "elev": "56",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BOB",
    "lat": "-16.45",
    "lon": "-151.767",
    "name": "Motu-Mute Airport",
    "city": "Papeete",
    "state": "Leeward Islands",
    "country": "French Polynesia",
    "woeid": "12523077",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "10",
    "icao": "NTTB",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "BOC",
    "lat": "9.34",
    "lon": "-82.2514",
    "name": "Bocas del Toro Airport",
    "city": "Bocas del Toro",
    "state": "Bocas del Toro",
    "country": "Panama",
    "woeid": "12515413",
    "tz": "America\/Panama",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4932",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BOD",
    "lat": "44.8291",
    "lon": "-0.70278",
    "name": "Bordeaux Airport",
    "city": "Merignac",
    "state": "Aquitaine",
    "country": "France",
    "woeid": "12512934",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10170",
    "elev": "161",
    "icao": "LFBD",
    "direct_flights": "44",
    "carriers": "33"
  },
  {
    "code": "BOG",
    "lat": "4.69895",
    "lon": "-74.1435",
    "name": "El Dorado International Airport",
    "city": "Fontib\u00f3n",
    "state": "Distrito Especial",
    "country": "Colombia",
    "woeid": "12512375",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aerocivil.gov.co\/plan%20maestro\/index.htm",
    "runway_length": "12467",
    "elev": "8356",
    "icao": "SKBO",
    "direct_flights": "64",
    "carriers": "30"
  },
  {
    "code": "BOH",
    "lat": "50.7797",
    "lon": "-1.83424",
    "name": "Bournemouth International Airport",
    "city": "Christchurch",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22460043",
    "tz": "Europe\/London",
    "phone": "+44 1202 364 000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flybournemouth.com",
    "runway_length": "7450",
    "elev": "36",
    "icao": "EGHH",
    "direct_flights": "15",
    "carriers": "5"
  },
  {
    "code": "BOI",
    "lat": "43.5709",
    "lon": "-116.222",
    "name": "Boise Air Terminal",
    "city": "Boise",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12518880",
    "tz": "America\/Boise",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9763",
    "elev": "2858",
    "icao": "KBOI",
    "direct_flights": "22",
    "carriers": "21"
  },
  {
    "code": "BOJ",
    "lat": "42.5667",
    "lon": "27.5",
    "name": "Bourgas Airport",
    "city": "Burgas",
    "state": "Burgas",
    "country": "Bulgaria",
    "woeid": "12523782",
    "tz": "Europe\/Sofia",
    "phone": "+359 56 870248",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bourgas-airport.com\/",
    "runway_length": "8530",
    "elev": "121",
    "icao": "LBBG",
    "direct_flights": "22",
    "carriers": "14"
  },
  {
    "code": "BOM",
    "lat": "19.0932",
    "lon": "72.8654",
    "name": "Chhatrapati Shivaji International Airport",
    "city": "Mumbai",
    "state": "Maharashtra",
    "country": "India",
    "woeid": "12513559",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportsindia.org.in\/aai\/mumbai\/index.htm",
    "runway_length": "11446",
    "elev": "36",
    "icao": "VABB",
    "direct_flights": "86",
    "carriers": "60"
  },
  {
    "code": "BON",
    "lat": "12.1392",
    "lon": "-68.2697",
    "name": "Flamingo Airport",
    "city": "Kralendijk",
    "state": "Bonaire",
    "country": "Netherlands Antilles",
    "woeid": "12515137",
    "tz": "America\/Curacao",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9449",
    "elev": "20",
    "icao": "TNCB",
    "direct_flights": "9",
    "carriers": "11"
  },
  {
    "code": "BOO",
    "lat": "67.2709",
    "lon": "14.3653",
    "name": "Bodo Airport",
    "city": "Bodo",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12515105",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9163",
    "elev": "42",
    "icao": "ENBO",
    "direct_flights": "14",
    "carriers": "4"
  },
  {
    "code": "BOS",
    "lat": "42.3717",
    "lon": "-71.0281",
    "name": "Gen E L Logan International Airport",
    "city": "Boston",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12519874",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10081",
    "elev": "20",
    "icao": "KBOS",
    "direct_flights": "113",
    "carriers": "68"
  },
  {
    "code": "BOW",
    "lat": "27.9493",
    "lon": "-81.7818",
    "name": "Bartow Municipal Airport",
    "city": "Bartow",
    "state": "Florida",
    "country": "United States",
    "woeid": "12518749",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "128",
    "icao": "KBOW",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "BOY",
    "lat": "11.1636",
    "lon": "-4.3264",
    "name": "Bobo Dioulasso Airport",
    "city": "Bobo-Dioulasso",
    "state": "Houet",
    "country": "Burkina Faso",
    "woeid": "12522554",
    "tz": "Africa\/Ouagadougou",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10826",
    "elev": "1509",
    "icao": "DFOO",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BPN",
    "lat": "-1.2681",
    "lon": "116.897",
    "name": "Sepinggan Airport",
    "city": "Balikpapan",
    "state": "Kalimantan Timur",
    "country": "Indonesia",
    "woeid": "12513506",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "10",
    "icao": "WALL",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "BPS",
    "lat": "-16.4406",
    "lon": "-39.0833",
    "name": "Porto Seguro Airport",
    "city": "Porto Seguro",
    "state": "Bahia",
    "country": "Brazil",
    "woeid": "12511284",
    "tz": "America\/Bahia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5351",
    "elev": "161",
    "icao": "SBPS",
    "direct_flights": "3",
    "carriers": "6"
  },
  {
    "code": "BPT",
    "lat": "29.9551",
    "lon": "-94.0185",
    "name": "Jefferson County Airport",
    "city": "Beaumont",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520357",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6751",
    "elev": "16",
    "icao": "KBPT",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "BPX",
    "lat": "31.1442",
    "lon": "97.174",
    "name": "Bangda Airport",
    "city": "Qamdo",
    "state": "Tibet",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "18045",
    "elev": "14219",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BPY",
    "lat": "-16.7431",
    "lon": "44.4803",
    "name": "Besalampy Airport",
    "city": "Besalampy",
    "state": "Mahajanga",
    "country": "Madagascar",
    "woeid": "12514696",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "125",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BQK",
    "lat": "31.2525",
    "lon": "-81.4703",
    "name": "Glynco Jetport Airport",
    "city": "Brunswick",
    "state": "Georgia",
    "country": "United States",
    "woeid": "12519920",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8001",
    "elev": "26",
    "icao": "KBQK",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BQL",
    "lat": "-22.9122",
    "lon": "139.897",
    "name": "Boulia Aerodrome",
    "city": "Boulia",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510613",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4180",
    "elev": "540",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BQN",
    "lat": "18.496",
    "lon": "-67.1357",
    "name": "Rafael Hernandez Airport",
    "city": "Aguadilla",
    "state": "Puerto Rico",
    "country": "United States",
    "woeid": "12515664",
    "tz": "America\/Puerto_Rico",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11700",
    "elev": "237",
    "icao": "TJBQ",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "BQS",
    "lat": "50.4243",
    "lon": "127.409",
    "name": "Blagoveshchensk Northwest Airport",
    "city": "Blagoveshchensk",
    "state": "Amurskaya Oblast",
    "country": "Russia",
    "woeid": "12515824",
    "tz": "Asia\/Yakutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "7"
  },
  {
    "code": "BRA",
    "lat": "-12.0792",
    "lon": "-45.01",
    "name": "Barreiras Airport",
    "city": "Barreiras",
    "state": "Bahia",
    "country": "Brazil",
    "woeid": "12511045",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "2444",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BRC",
    "lat": "-41.1511",
    "lon": "-71.1581",
    "name": "San Carlos de Bariloche Airport",
    "city": "San Carlos DeBariloche",
    "state": "Santa Fe",
    "country": "Argentina",
    "woeid": "12510550",
    "tz": "America\/Argentina\/Cordoba",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7900",
    "elev": "2772",
    "icao": "SAZS",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "BRD",
    "lat": "46.3909",
    "lon": "-94.1413",
    "name": "Brainerd-Crow Wing County Regional Airport",
    "city": "Brainerd",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12518917",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "1226",
    "icao": "KBRD",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "BRE",
    "lat": "53.0523",
    "lon": "8.78533",
    "name": "Bremen Airport",
    "city": "Bremen",
    "state": "Bremen",
    "country": "Germany",
    "woeid": "22177393",
    "tz": "Europe\/Berlin",
    "phone": "+49 421 5595-0",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport-bremen.de",
    "runway_length": "6673",
    "elev": "13",
    "icao": "EDDW",
    "direct_flights": "38",
    "carriers": "27"
  },
  {
    "code": "BRF",
    "lat": "54.3139",
    "lon": "-2.23218",
    "name": "United Kingdom",
    "city": "Bradford",
    "state": null,
    "country": "United Kingdom",
    "woeid": "23424975",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BRI",
    "lat": "41.1376",
    "lon": "16.7674",
    "name": "Palese Macchie Airport",
    "city": "Bari",
    "state": "Puglia",
    "country": "Italy",
    "woeid": "12513843",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.seap-puglia.it\/",
    "runway_length": "7283",
    "elev": "167",
    "icao": "LIBD",
    "direct_flights": "31",
    "carriers": "28"
  },
  {
    "code": "BRK",
    "lat": "-30.0403",
    "lon": "145.953",
    "name": "Bourke Airport",
    "city": "Bourke",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510614",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "351",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BRL",
    "lat": "40.7816",
    "lon": "-91.1197",
    "name": "Burlington Municipal Airport",
    "city": "Burlington",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12518980",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6702",
    "elev": "698",
    "icao": "KBRL",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "BRM",
    "lat": "10.0453",
    "lon": "-69.3561",
    "name": "Barquisimeto Airport",
    "city": "Barquisimeto",
    "state": "Lara",
    "country": "Venezuela",
    "woeid": "12522758",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7808",
    "elev": "2042",
    "icao": "SVBM",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "BRN",
    "lat": "46.9124",
    "lon": "7.49929",
    "name": "Bern Belp Airport",
    "city": "Belp",
    "state": "Canton of Berne",
    "country": "Switzerland",
    "woeid": "23332448",
    "tz": "Europe\/Zurich",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4298",
    "elev": "1673",
    "icao": "LSZB",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "BRO",
    "lat": "25.9064",
    "lon": "-97.4321",
    "name": "Brownsville-South Padre Island International Air",
    "city": "Brownsville",
    "state": "Texas",
    "country": "United States",
    "woeid": "12518951",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7400",
    "elev": "22",
    "icao": "KBRO",
    "direct_flights": "5",
    "carriers": "9"
  },
  {
    "code": "BRQ",
    "lat": "49.1535",
    "lon": "16.6908",
    "name": "Turany Airport",
    "city": "Brno",
    "state": "Jihomoravsk\u00fd",
    "country": "Czech Republic",
    "woeid": "12512574",
    "tz": "Europe\/Prague",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport-brno.cz",
    "runway_length": "8694",
    "elev": "778",
    "icao": "LKTB",
    "direct_flights": "7",
    "carriers": "8"
  },
  {
    "code": "BRR",
    "lat": "57.0251",
    "lon": "-7.44967",
    "name": "North Bay Airport",
    "city": "Barra",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12523078",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2982",
    "elev": null,
    "icao": "EGPR",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "BRS",
    "lat": "51.3848",
    "lon": "-2.70526",
    "name": "Bristol International Airport",
    "city": "Bristol",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22456683",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bristolairport.co.uk",
    "runway_length": "6598",
    "elev": "620",
    "icao": "EGGD",
    "direct_flights": "73",
    "carriers": "22"
  },
  {
    "code": "BRU",
    "lat": "50.899",
    "lon": "4.4859",
    "name": "Brussels Airport",
    "city": "Bruxelles",
    "state": "Vlaams Brabant",
    "country": "Belgium",
    "woeid": "22103751",
    "tz": "Europe\/Brussels",
    "phone": "+32 (0) 900 7 0000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.brusselsairport.be",
    "runway_length": "9790",
    "elev": "184",
    "icao": "EBBR",
    "direct_flights": "186",
    "carriers": "97"
  },
  {
    "code": "BRV",
    "lat": "53.5537",
    "lon": "8.78359",
    "name": "Bremerhaven Airport",
    "city": "Bad Bederkesa",
    "state": "Lower Saxony",
    "country": "Germany",
    "woeid": "12523855",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2259",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BRW",
    "lat": "71.2892",
    "lon": "-156.772",
    "name": "Wiley Post Will Rogers Memorial Airport",
    "city": "Barrow",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12522440",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "44",
    "icao": "PABR",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "BSA",
    "lat": "11.2775",
    "lon": "49.1814",
    "name": "Somalia",
    "city": "Bossaso",
    "state": "Bari",
    "country": "Somalia",
    "woeid": "23424949",
    "tz": "Africa\/Mogadishu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3477",
    "elev": "6",
    "icao": "HCMF",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BSB",
    "lat": "-15.8622",
    "lon": "-47.9122",
    "name": "Juscelino Kubitschek International Airport",
    "city": "Lago Sul",
    "state": "Distrito Federal",
    "country": "Brazil",
    "woeid": "12511058",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.infraero.gov.br\/",
    "runway_length": "10499",
    "elev": "3473",
    "icao": "SBBR",
    "direct_flights": "32",
    "carriers": "10"
  },
  {
    "code": "BSC",
    "lat": "6.1786",
    "lon": "-77.3956",
    "name": "Jose Celestino Mutis Airport",
    "city": "Bah\u00eda Solano",
    "state": "Choco",
    "country": "Colombia",
    "woeid": "12512385",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3936",
    "elev": "80",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BSD",
    "lat": "25.0604",
    "lon": "99.1639",
    "name": "Baoshan Airport",
    "city": "Baoshan",
    "state": "Yunnan",
    "country": "China",
    "woeid": "12511986",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BSG",
    "lat": "1.9046",
    "lon": "9.8032",
    "name": "Bata Airport",
    "city": "Bata",
    "state": "Litoral",
    "country": "Equatorial Guinea",
    "woeid": "12512729",
    "tz": "Africa\/Malabo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "7",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BSH",
    "lat": "50.8333",
    "lon": "-0.13333",
    "name": "Brighton Airport",
    "city": "Brighton",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12523976",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BSK",
    "lat": "34.7933",
    "lon": "5.74",
    "name": "Biskra Airport",
    "city": "Biskra",
    "state": "Biskra",
    "country": "Algeria",
    "woeid": "12510315",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9514",
    "elev": "285",
    "icao": "DAUB",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BSL",
    "lat": "47.5998",
    "lon": "7.532",
    "name": "Euroairport Basel-Mulhouse-Freiburg",
    "city": "St-Ludwig",
    "state": "Alsace",
    "country": "France",
    "woeid": "12523049",
    "tz": "Europe\/Zurich",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12795",
    "elev": "883",
    "icao": "LFSB",
    "direct_flights": "67",
    "carriers": "32"
  },
  {
    "code": "BSO",
    "lat": "20.4531",
    "lon": "121.978",
    "name": "Basco Airport",
    "city": "Basco",
    "state": "Cagayan Valley",
    "country": "Philippines",
    "woeid": "12515599",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4101",
    "elev": "184",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BSR",
    "lat": "30.5481",
    "lon": "47.6658",
    "name": "Basrah International Airport",
    "city": "Gawad",
    "state": "Al Basrah",
    "country": "Iraq",
    "woeid": "12513895",
    "tz": "Asia\/Baghdad",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5971",
    "elev": "9",
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "BSX",
    "lat": "45.4264",
    "lon": "5.14384",
    "name": "Bassein",
    "city": "Bassein",
    "state": "Ayeyarwady",
    "country": "Myanmar",
    "woeid": "1017357",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4750",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BTH",
    "lat": "1.1306",
    "lon": "104.134",
    "name": "Hang Nadim Airport",
    "city": "Batam",
    "state": "Riau",
    "country": "Indonesia",
    "woeid": "12513469",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "68",
    "icao": "",
    "direct_flights": "8",
    "carriers": "7"
  },
  {
    "code": "BTI",
    "lat": "70.1478",
    "lon": "-143.579",
    "name": "Barter Island Airport",
    "city": "Kaktovik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524590",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "5",
    "icao": "PABA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BTJ",
    "lat": "5.5197",
    "lon": "95.4181",
    "name": "Blangbintang Airport",
    "city": "Banda Aceh",
    "state": "Aceh",
    "country": "Indonesia",
    "woeid": "12513456",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "62",
    "icao": "",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "BTK",
    "lat": "56.1667",
    "lon": "102.067",
    "name": "Bratsk",
    "city": "Bratsk",
    "state": "Irkutskaya Oblast",
    "country": "Russia",
    "woeid": "2120401",
    "tz": "Asia\/Irkutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "BTL",
    "lat": "42.3046",
    "lon": "-85.2448",
    "name": "W K Kellogg Airport",
    "city": "Battle Creek",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12522297",
    "tz": "America\/Detroit",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7011",
    "elev": "941",
    "icao": "KBTL",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "BTM",
    "lat": "45.9544",
    "lon": "-112.503",
    "name": "Bert Mooney Airport",
    "city": "Butte",
    "state": "Montana",
    "country": "United States",
    "woeid": "12518811",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "5553",
    "icao": "KBTM",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "BTR",
    "lat": "30.533",
    "lon": "-91.1567",
    "name": "Baton Rouge Metropolitan Airport",
    "city": "Baton Rouge",
    "state": "Louisiana",
    "country": "United States",
    "woeid": "12518754",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6900",
    "elev": "70",
    "icao": "KBTR",
    "direct_flights": "6",
    "carriers": "11"
  },
  {
    "code": "BTS",
    "lat": "48.2063",
    "lon": "17.2055",
    "name": "Bratislava Airport",
    "city": "Bratislava",
    "state": "Bratislavsky",
    "country": "Slovakia",
    "woeid": "12514608",
    "tz": "Europe\/Bratislava",
    "phone": "+42148571111",
    "type": "Airports",
    "email": "information@airportbratislava.sk",
    "url": "http:\/\/www.airportbratislava.sk",
    "runway_length": "9514",
    "elev": "433",
    "icao": "LZIB",
    "direct_flights": "35",
    "carriers": "16"
  },
  {
    "code": "BTT",
    "lat": "66.9134",
    "lon": "-151.551",
    "name": "Bettles Airport",
    "city": "Bettles",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524591",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5199",
    "elev": "643",
    "icao": "PABT",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "BTU",
    "lat": "3.1728",
    "lon": "113.043",
    "name": "Bintulu Airport",
    "city": "Bintulu",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12514990",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "6",
    "icao": "WBGB",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "BTV",
    "lat": "44.4688",
    "lon": "-73.1552",
    "name": "Burlington International Airport",
    "city": "South Burlington",
    "state": "Vermont",
    "country": "United States",
    "woeid": "12518979",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7807",
    "elev": "334",
    "icao": "KBTV",
    "direct_flights": "15",
    "carriers": "16"
  },
  {
    "code": "BTZ",
    "lat": "40.2344",
    "lon": "29.0053",
    "name": "Bursa Airport",
    "city": "Bursa",
    "state": "Bursa",
    "country": "Turkey",
    "woeid": "12517876",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "332",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BUA",
    "lat": "-5.42232",
    "lon": "154.673",
    "name": "Buka",
    "city": "Buka",
    "state": "Buka Island",
    "country": "Papua New Guinea",
    "woeid": "12498830",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5100",
    "elev": "11",
    "icao": "AYBK",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BUC",
    "lat": "-17.7484",
    "lon": "139.532",
    "name": "Burketown Aerodrome",
    "city": "Burketown",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510622",
    "tz": "Australia\/Lindeman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "50",
    "icao": "YBKT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BUD",
    "lat": "47.4453",
    "lon": "19.2195",
    "name": "Ferihegy Airport",
    "city": "Budapest",
    "state": "Budapest",
    "country": "Hungary",
    "woeid": "12513389",
    "tz": "Europe\/Budapest",
    "phone": "(36) 1 2969696",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bud.hu",
    "runway_length": "9875",
    "elev": "440",
    "icao": "LHBP",
    "direct_flights": "102",
    "carriers": "64"
  },
  {
    "code": "BUF",
    "lat": "42.9309",
    "lon": "-78.738",
    "name": "Greater Buffalo International Airport",
    "city": "Buffalo",
    "state": "New York",
    "country": "United States",
    "woeid": "12519977",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.nfta.com\/airport\/",
    "runway_length": "15",
    "elev": "724",
    "icao": "KBUF",
    "direct_flights": "29",
    "carriers": "30"
  },
  {
    "code": "BUL",
    "lat": "33.9116",
    "lon": "-102.638",
    "name": "Bulolo",
    "city": "Bulolo",
    "state": "Manus",
    "country": "Papua New Guinea",
    "woeid": "12498381",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4375",
    "elev": "2240",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BUN",
    "lat": "3.8183",
    "lon": "-76.9931",
    "name": "Buenaventura Airport",
    "city": "Buenaventura",
    "state": "Valle del Cauca",
    "country": "Colombia",
    "woeid": "12512367",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3510",
    "elev": "50",
    "icao": "SKBU",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BUO",
    "lat": "65.8514",
    "lon": "12.344",
    "name": "Burao",
    "city": "Burao",
    "state": "Togdheer",
    "country": "Somalia",
    "woeid": "1425676",
    "tz": "Africa\/Mogadishu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7875",
    "elev": "3400",
    "icao": "HCMV",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "BUQ",
    "lat": "-20.0169",
    "lon": "28.6181",
    "name": "Bulawayo Airport",
    "city": "Bulawayo",
    "state": "Matabeleland North",
    "country": "Zimbabwe",
    "woeid": "12523021",
    "tz": "Africa\/Harare",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8471",
    "elev": "4366",
    "icao": "FVBU",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BUR",
    "lat": "34.1963",
    "lon": "-118.352",
    "name": "Burbank Glendale Pasadena Airport",
    "city": "Burbank",
    "state": "California",
    "country": "United States",
    "woeid": "12518974",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bobhopeairport.com",
    "runway_length": "6902",
    "elev": "775",
    "icao": "KBUR",
    "direct_flights": "14",
    "carriers": "12"
  },
  {
    "code": "BUS",
    "lat": "50.0662",
    "lon": "2.96519",
    "name": "Batumi",
    "city": "Batumi",
    "state": "Ajaria",
    "country": "Georgia",
    "woeid": "1962543",
    "tz": "Asia\/Tbilisi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UGSB",
    "direct_flights": "10",
    "carriers": "6"
  },
  {
    "code": "BUX",
    "lat": "1.5717",
    "lon": "30.2128",
    "name": "Bunia Airport",
    "city": "Bunia",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511952",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "4045",
    "icao": "FZKA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BUZ",
    "lat": "28.9494",
    "lon": "50.8311",
    "name": "Bushehr Airport",
    "city": "Bandar-e Bushehr",
    "state": "Bushehr",
    "country": "Iran",
    "woeid": "12513710",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10860",
    "elev": "57",
    "icao": "OIBB",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "BVA",
    "lat": "49.4565",
    "lon": "2.11552",
    "name": "Beauvais-Tille Airport",
    "city": "Beauvais",
    "state": "Picardie",
    "country": "France",
    "woeid": "22904235",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "http:\/\/www.aeroportbeauvais.com\/",
    "runway_length": "7972",
    "elev": "358",
    "icao": "LFOB",
    "direct_flights": "19",
    "carriers": "3"
  },
  {
    "code": "BVB",
    "lat": "2.8417",
    "lon": "-60.6917",
    "name": "Boa Vista International Airport",
    "city": "Boa Vista",
    "state": "Nordeste",
    "country": "Brazil",
    "woeid": "12511054",
    "tz": "America\/Recife",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8071",
    "elev": "276",
    "icao": "SBBV",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "BVC",
    "lat": "16.0961",
    "lon": "-22.8172",
    "name": "Boa Vista Airport",
    "city": "Boa Vista",
    "state": "Boa Vista",
    "country": "Cape Verde",
    "woeid": "12512494",
    "tz": "Atlantic\/Cape_Verde",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "69",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BVE",
    "lat": "45.1487",
    "lon": "1.47441",
    "name": "La Roche Airport",
    "city": "Brive-la-Gaillarde",
    "state": "Limousin",
    "country": "France",
    "woeid": "12512905",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3609",
    "elev": "374",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BVG",
    "lat": "70.8702",
    "lon": "29.0294",
    "name": "Berlevag Airport",
    "city": "Berlevaag",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523927",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "43",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "BVH",
    "lat": "-12.7014",
    "lon": "-60.0931",
    "name": "Brigadeiro Camarao Airport",
    "city": "Vilhena",
    "state": "Rondonia",
    "country": "Brazil",
    "woeid": "12511373",
    "tz": "America\/Cuiaba",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "8524",
    "elev": "2008",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BVI",
    "lat": "-25.8971",
    "lon": "139.349",
    "name": "Birdsville Airport",
    "city": "Birdsville",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510608",
    "tz": "Australia\/Lindeman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4700",
    "elev": "157",
    "icao": "YBDV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BWA",
    "lat": "27.5042",
    "lon": "83.42",
    "name": "Bhairawa Airport",
    "city": "Bhairawa",
    "state": "West",
    "country": "Nepal",
    "woeid": "12515131",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4970",
    "elev": "358",
    "icao": "VNBW",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BWE",
    "lat": "52.3165",
    "lon": "10.5595",
    "name": "Braunschweig Airport",
    "city": "Braunschweig",
    "state": "Lower Saxony",
    "country": "Germany",
    "woeid": "22176819",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5315",
    "elev": "291",
    "icao": "EDVE",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BWF",
    "lat": "54.1284",
    "lon": "-3.26249",
    "name": "Walney Island Airport",
    "city": "Barrow in Furness",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518033",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3953",
    "elev": "47",
    "icao": "EGNL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BWI",
    "lat": "39.1841",
    "lon": "-76.6745",
    "name": "Baltimore-Washington International Thurgood Mars",
    "city": "Baltimore",
    "state": "Maryland",
    "country": "United States",
    "woeid": "12522341",
    "tz": "America\/New_York",
    "phone": "410-859-7111",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bwiairport.com",
    "runway_length": "9519",
    "elev": "146",
    "icao": "KBWI",
    "direct_flights": "79",
    "carriers": "39"
  },
  {
    "code": "BWK",
    "lat": "43.2619",
    "lon": "16.655",
    "name": "Bol",
    "city": "Bol",
    "state": "Splitsko-Dalmatinska",
    "country": "Croatia",
    "woeid": "15022368",
    "tz": "Europe\/Belgrade",
    "phone": "+385\/21\/559711",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BWN",
    "lat": "4.9458",
    "lon": "114.924",
    "name": "Brunei International Airport",
    "city": "Bandar Seri Begawan",
    "state": "Brunei and Muara",
    "country": "Brunei",
    "woeid": "12511563",
    "tz": "Asia\/Brunei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12000",
    "elev": "73",
    "icao": "WBSB",
    "direct_flights": "16",
    "carriers": "8"
  },
  {
    "code": "BWT",
    "lat": "-40.9935",
    "lon": "145.725",
    "name": "Burnie Wynyard Airport",
    "city": "Doctors Rocks",
    "state": "Tasmania",
    "country": "Australia",
    "woeid": "12510819",
    "tz": "Australia\/Hobart",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4965",
    "elev": "61",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BWW",
    "lat": "22.4922",
    "lon": "-79.9408",
    "name": "Santa Clara Airport",
    "city": "Santa Clara",
    "state": "",
    "country": "Cuba",
    "woeid": "12512488",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BXR",
    "lat": "29.4375",
    "lon": "-98.4618",
    "name": "",
    "city": "Bam",
    "state": "Kerman",
    "country": "Iran",
    "woeid": "2254392",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BXU",
    "lat": "8.9528",
    "lon": "125.476",
    "name": "Butuan Airport",
    "city": "Buenavista",
    "state": "Caraga",
    "country": "Philippines",
    "woeid": "12515601",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "141",
    "icao": "RPME",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "BYM",
    "lat": "20.3972",
    "lon": "-76.6192",
    "name": "Bayamo Airport",
    "city": "Bayamo",
    "state": "Granma",
    "country": "Cuba",
    "woeid": "12512453",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6625",
    "elev": "210",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "BYN",
    "lat": "62.0526",
    "lon": "17.1936",
    "name": "",
    "city": "Bayankhongor",
    "state": "Bayanhongor",
    "country": "Mongolia",
    "woeid": "2265314",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "BZE",
    "lat": "17.5386",
    "lon": "-88.3042",
    "name": "Philip S W Goldson International Airport",
    "city": "Hattieville",
    "state": "Belize",
    "country": "Belize",
    "woeid": "12510896",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6300",
    "elev": "15",
    "icao": "MZBZ",
    "direct_flights": "15",
    "carriers": "10"
  },
  {
    "code": "BZG",
    "lat": "53.0918",
    "lon": "17.9821",
    "name": "Szwederowo Airport",
    "city": "Bia\u0142e B\u0142ota",
    "state": "Kujawsko-Pomorskie",
    "country": "Poland",
    "woeid": "12523956",
    "tz": "Europe\/Warsaw",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.plb.pl",
    "runway_length": null,
    "elev": null,
    "icao": "EPBD",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "BZK",
    "lat": "53.2667",
    "lon": "34.3333",
    "name": "Briansk",
    "city": "Briansk",
    "state": "Tsentral'Niy",
    "country": "Russian Federation",
    "woeid": "2346892",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "BZL",
    "lat": "22.6667",
    "lon": "90.3417",
    "name": "",
    "city": "Barisal",
    "state": "Barisal",
    "country": "Bangladesh",
    "woeid": "1914997",
    "tz": "Asia\/Dhaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1810",
    "elev": "20",
    "icao": "VGBR",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "BZN",
    "lat": "45.7722",
    "lon": "-111.157",
    "name": "Gallatin Field Airport",
    "city": "Bozeman",
    "state": "Montana",
    "country": "United States",
    "woeid": "12519854",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9002",
    "elev": "4458",
    "icao": "KBZN",
    "direct_flights": "10",
    "carriers": "8"
  },
  {
    "code": "BZO",
    "lat": "46.4621",
    "lon": "11.3282",
    "name": "Bolzano Airport",
    "city": "Botzen",
    "state": "Trentino-Alto Adige",
    "country": "Italy",
    "woeid": "12513807",
    "tz": "Europe\/Rome",
    "phone": "+39-0471-255 255",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.abd-airport.it",
    "runway_length": "3450",
    "elev": "790",
    "icao": "LIPB",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "BZR",
    "lat": "43.3229",
    "lon": "3.35466",
    "name": "Vias Airport",
    "city": "Villeneuve",
    "state": "Languedoc-Roussillon",
    "country": "France",
    "woeid": "12512985",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5971",
    "elev": "56",
    "icao": "LFMU",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "BZV",
    "lat": "-4.2558",
    "lon": "15.2497",
    "name": "Brazzaville Maya Maya Airport",
    "city": "Brazzaville",
    "state": "Brazzaville",
    "country": "Congo",
    "woeid": "12511945",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10826",
    "elev": "1047",
    "icao": "FCBB",
    "direct_flights": "12",
    "carriers": "20"
  },
  {
    "code": "BZZ",
    "lat": "51.7614",
    "lon": "-1.57482",
    "name": "Brize Norton Airport",
    "city": "Carterton",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518048",
    "tz": "Europe\/London",
    "phone": "00 44 1993 842551",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "10006",
    "elev": "287",
    "icao": "EGVN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CAB",
    "lat": "-5.59",
    "lon": "12.1886",
    "name": "Cabinda Airport",
    "city": "Cabinda",
    "state": "Cabinda",
    "country": "Angola",
    "woeid": "12510430",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "66",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CAC",
    "lat": "-25.0017",
    "lon": "-53.5014",
    "name": "Cascavel Airport",
    "city": "Cascavel",
    "state": "Nordeste",
    "country": "Brazil",
    "woeid": "12511085",
    "tz": "America\/Fortaleza",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4633",
    "elev": "2473",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CAE",
    "lat": "33.9468",
    "lon": "-81.1241",
    "name": "Columbia Metropolitan Airport",
    "city": "West Columbia",
    "state": "South Carolina",
    "country": "United States",
    "woeid": "12519284",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8602",
    "elev": "236",
    "icao": "KCAE",
    "direct_flights": "20",
    "carriers": "26"
  },
  {
    "code": "CAG",
    "lat": "39.2527",
    "lon": "9.05813",
    "name": "Elmas Airport",
    "city": "Sardara",
    "state": "Sardinia",
    "country": "Italy",
    "woeid": "12513818",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "13",
    "icao": "KCAG",
    "direct_flights": "30",
    "carriers": "23"
  },
  {
    "code": "CAH",
    "lat": "9.17667",
    "lon": "105.151",
    "name": "Camo",
    "city": "Ca Mau",
    "state": "Ca Mau",
    "country": "Vietnam",
    "woeid": "1252573",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1804",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CAI",
    "lat": "30.1206",
    "lon": "31.4078",
    "name": "Cairo International Airport",
    "city": "Cairo",
    "state": "Al Qahirah",
    "country": "Egypt",
    "woeid": "12512678",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cairo-airport.com\/",
    "runway_length": "13123",
    "elev": "381",
    "icao": "HECA",
    "direct_flights": "92",
    "carriers": "52"
  },
  {
    "code": "CAK",
    "lat": "40.9149",
    "lon": "-81.4309",
    "name": "Akron Canton Regional Airport",
    "city": "Canton",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12518548",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6397",
    "elev": "1228",
    "icao": "KCAK",
    "direct_flights": "13",
    "carriers": "13"
  },
  {
    "code": "CAL",
    "lat": "55.4388",
    "lon": "-5.69997",
    "name": "Campbeltown Airport",
    "city": "Campbeltown",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22458271",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10003",
    "elev": "44",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CAN",
    "lat": "23.3925",
    "lon": "113.299",
    "name": "Baiyun Airport",
    "city": "Guangzhou",
    "state": "Guangdong",
    "country": "China",
    "woeid": "12511984",
    "tz": "Asia\/Chongqing",
    "phone": "0086-020-86120000",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11000",
    "elev": "45",
    "icao": "ZGGG",
    "direct_flights": "111",
    "carriers": "49"
  },
  {
    "code": "CAP",
    "lat": "19.7328",
    "lon": "-72.195",
    "name": "Cap Haitien Airport",
    "city": "Cap-Ha\u00eftien",
    "state": "Nord",
    "country": "Haiti",
    "woeid": "12513352",
    "tz": "America\/Port-au-Prince",
    "phone": "0115092628539",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1",
    "elev": "15",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CAX",
    "lat": "54.9375",
    "lon": "-2.8106",
    "name": "Carlisle Airport",
    "city": "Carlisle",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518055",
    "tz": "Europe\/London",
    "phone": "+44 (0)1228 573641",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.carlisleairport.co.uk",
    "runway_length": "6026",
    "elev": "190",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "CAY",
    "lat": "4.93333",
    "lon": "-52.3333",
    "name": "Rochambeau",
    "city": "Cayenne",
    "state": "Cayenne",
    "country": "French Guiana",
    "woeid": "379817",
    "tz": "America\/Cayenne",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10499",
    "elev": "26",
    "icao": "SOCA",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "CAZ",
    "lat": "-31.5334",
    "lon": "145.797",
    "name": "Cobar Airport",
    "city": "Canbelego",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510635",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "722",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "CBB",
    "lat": "-17.4161",
    "lon": "-66.1744",
    "name": "Jorge Wilsterman Airport",
    "city": "Cochabamba",
    "state": "Cochabamba",
    "country": "Bolivia",
    "woeid": "12510908",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8649",
    "elev": "8360",
    "icao": "SLCB",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "CBG",
    "lat": "52.2069",
    "lon": "0.17355",
    "name": "Cambridge Airport",
    "city": "Cambridge",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22458071",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6446",
    "elev": "50",
    "icao": "EGSC",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "CBH",
    "lat": "31.6583",
    "lon": "-2.2614",
    "name": "Bechar Airport",
    "city": "Bechar",
    "state": "Bechar",
    "country": "Algeria",
    "woeid": "12510314",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10064",
    "elev": "2661",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "CBO",
    "lat": "7.165",
    "lon": "124.208",
    "name": "Cotabato Airport",
    "city": "Sultan Kudarat",
    "state": "Autonomous Region in Muslim Mind",
    "country": "Philippines",
    "woeid": "12515608",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6233",
    "elev": "187",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "CBQ",
    "lat": "4.9756",
    "lon": "8.3481",
    "name": "Calabar Airport",
    "city": "Duke Town",
    "state": "Cross River",
    "country": "Nigeria",
    "woeid": "12515061",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8038",
    "elev": "210",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CBR",
    "lat": "-35.3069",
    "lon": "149.195",
    "name": "Canberra International Airport",
    "city": "Canberra",
    "state": "Australian Capital Territory",
    "country": "Australia",
    "woeid": "12510625",
    "tz": "Australia\/Sydney",
    "phone": "+61 2 6275 2222",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.canberraairport.com.au",
    "runway_length": "8800",
    "elev": "1888",
    "icao": "YSCB",
    "direct_flights": "8",
    "carriers": "9"
  },
  {
    "code": "CBT",
    "lat": "-12.4827",
    "lon": "13.4904",
    "name": "Angola",
    "city": "Catumbela",
    "state": null,
    "country": "Angola",
    "woeid": "23424745",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12135",
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "CCC",
    "lat": "22.4817",
    "lon": "-78.4813",
    "name": "Cuba",
    "city": "Cayo Coco",
    "state": "Ciego de Avila",
    "country": "Cuba",
    "woeid": "23424793",
    "tz": "America\/Havana",
    "phone": "0049 172 6221025",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "CCF",
    "lat": "43.2153",
    "lon": "2.30989",
    "name": "Salvaza Airport",
    "city": "Carcassonne",
    "state": "Languedoc-Roussillon",
    "country": "France",
    "woeid": "12512962",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "6397",
    "elev": "433",
    "icao": "LFMK",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "CCJ",
    "lat": "11.1329",
    "lon": "75.9536",
    "name": "Kozhikode Airport",
    "city": "Pallikkal",
    "state": "Kerala",
    "country": "India",
    "woeid": "12513562",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.calicutairport.com",
    "runway_length": "9383",
    "elev": "328",
    "icao": "VOCL",
    "direct_flights": "18",
    "carriers": "13"
  },
  {
    "code": "CCK",
    "lat": "-12.1778",
    "lon": "96.839",
    "name": "Cocos Airport",
    "city": "West Island",
    "state": "",
    "country": "Cocos (Keeling) Islands",
    "woeid": "12523082",
    "tz": "Indian\/Cocos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "7",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CCM",
    "lat": "-28.7239",
    "lon": "-49.4208",
    "name": "Criciuma Airport",
    "city": "Forquilhinha",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12511104",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4892",
    "elev": "93",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CCP",
    "lat": "-36.7736",
    "lon": "-73.06",
    "name": "Carriel Sur International Airport",
    "city": "Hualpencillo",
    "state": "Bio-Bio",
    "country": "Chile",
    "woeid": "12512311",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeropuertocarrielsur.co.cl",
    "runway_length": "7546",
    "elev": "41",
    "icao": "SCIE",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "CCS",
    "lat": "10.6053",
    "lon": "-66.9881",
    "name": "Simon Bolivar International Airport",
    "city": "Catia la Mar",
    "state": "Vargas",
    "country": "Venezuela",
    "woeid": "12522849",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "235",
    "icao": "SVMI",
    "direct_flights": "59",
    "carriers": "35"
  },
  {
    "code": "CCU",
    "lat": "22.6572",
    "lon": "88.4506",
    "name": "Netaji Subhash Chandra Bose International Airpor",
    "city": "Kolkata",
    "state": "West Bengal",
    "country": "India",
    "woeid": "12513561",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11900",
    "elev": "19",
    "icao": "VECC",
    "direct_flights": "42",
    "carriers": "24"
  },
  {
    "code": "CCV",
    "lat": "-16.6619",
    "lon": "168.38",
    "name": "Vanuatu",
    "city": "Craig Cove",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2952",
    "elev": "69",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "CCZ",
    "lat": "25.4098",
    "lon": "-77.8843",
    "name": "Chub Cay Airport",
    "city": "Chub Cay",
    "state": "Berry Islands",
    "country": "Bahamas",
    "woeid": "12510861",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "5",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "CDB",
    "lat": "55.2005",
    "lon": "-162.705",
    "name": "Cold Bay Airport",
    "city": "Cold Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519269",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10415",
    "elev": "98",
    "icao": "PACD",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "CDC",
    "lat": "37.6992",
    "lon": "-113.092",
    "name": "Cedar City Municipal Airport",
    "city": "Cedar City",
    "state": "Utah",
    "country": "United States",
    "woeid": "12519109",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7802",
    "elev": "5622",
    "icao": "KCDC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CDG",
    "lat": "49.0175",
    "lon": "2.5564",
    "name": "Charles de Gaulle International Airport",
    "city": "Le Mesnil-Amelot",
    "state": "Ile-de-France",
    "country": "France",
    "woeid": "22140453",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11811",
    "elev": "387",
    "icao": "LFPG",
    "direct_flights": "310",
    "carriers": "131"
  },
  {
    "code": "CDR",
    "lat": "42.8284",
    "lon": "-103.094",
    "name": "Chadron Municipal Airport",
    "city": "Chadron",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12519125",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6002",
    "elev": "3296",
    "icao": "KCDR",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "CDV",
    "lat": "60.4924",
    "lon": "-145.475",
    "name": "Merle K Mudhole Smith Airport",
    "city": "Cordova",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520907",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7499",
    "elev": "42",
    "icao": "PACV",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "CDW",
    "lat": "40.8759",
    "lon": "-74.2775",
    "name": "Essex County Airport",
    "city": "Fairfield",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "12519660",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4553",
    "elev": "173",
    "icao": "KCDW",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "CEB",
    "lat": "10.3596",
    "lon": "123.837",
    "name": "Lahug Airport",
    "city": "Cebu",
    "state": "Central Visayas",
    "country": "Philippines",
    "woeid": "12515625",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8500",
    "elev": "36",
    "icao": "RPVM",
    "direct_flights": "27",
    "carriers": "13"
  },
  {
    "code": "CEC",
    "lat": "41.7765",
    "lon": "-124.237",
    "name": "Jack Mcnamara Field Airport",
    "city": "Crescent City",
    "state": "California",
    "country": "United States",
    "woeid": "12520324",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5002",
    "elev": "57",
    "icao": "KCEC",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CED",
    "lat": "-32.1264",
    "lon": "133.701",
    "name": "Ceduna Airport",
    "city": "Ceduna",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "28677708",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5735",
    "elev": "77",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CEE",
    "lat": "59.083",
    "lon": "37.938",
    "name": "Cherepovets Airport",
    "city": "Cherepovets",
    "state": "Vologodskaya Oblast",
    "country": "Russia",
    "woeid": "12515900",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "CEG",
    "lat": "53.1707",
    "lon": "-2.98142",
    "name": "Hawarden Airport",
    "city": "Chester",
    "state": "Wales",
    "country": "United Kingdom",
    "woeid": "12518100",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4714",
    "elev": "35",
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "CEI",
    "lat": "19.8864",
    "lon": "99.8306",
    "name": "Chiang Rai Airport",
    "city": "Chiang Rai",
    "state": "Chiang Rai",
    "country": "Thailand",
    "woeid": "12517751",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4890",
    "elev": "1365",
    "icao": "VTCT",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "CEK",
    "lat": "55.3104",
    "lon": "61.4913",
    "name": "Chelyabinsk Balandino Airport",
    "city": "Zlatoust",
    "state": "Chelyabinskaya Oblast",
    "country": "Russia",
    "woeid": "12515894",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KCEK",
    "direct_flights": "14",
    "carriers": "11"
  },
  {
    "code": "CEM",
    "lat": "65.6121",
    "lon": "-144.674",
    "name": "Central Airport",
    "city": "Circle",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524607",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "932",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CEN",
    "lat": "27.3925",
    "lon": "-109.832",
    "name": "Ciudad Obregon Airport",
    "city": "Ciudad Obreg\u00f3n",
    "state": "Sonora",
    "country": "Mexico",
    "woeid": "12514849",
    "tz": "America\/Hermosillo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "243",
    "icao": "MMCN",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "CEZ",
    "lat": "37.3044",
    "lon": "-108.63",
    "name": "Cortez-Montezuma County Airport",
    "city": "Cortez",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12519328",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7205",
    "elev": "5914",
    "icao": "KCEZ",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "CFC",
    "lat": "-26.7775",
    "lon": "-51.0175",
    "name": "Cacador Airport",
    "city": "Ca\u00e7ador",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12523508",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CFE",
    "lat": "45.7896",
    "lon": "3.16013",
    "name": "Aulnat Airport",
    "city": "Aulnat",
    "state": "Auvergne",
    "country": "France",
    "woeid": "12512847",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9892",
    "elev": "1089",
    "icao": "LFLC",
    "direct_flights": "19",
    "carriers": "7"
  },
  {
    "code": "CFK",
    "lat": "36.2128",
    "lon": "1.33167",
    "name": "Abou Bakr Belkaid",
    "city": "Chlef",
    "state": "Chlef",
    "country": "Algeria",
    "woeid": "12510322",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1200",
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CFN",
    "lat": "55.0416",
    "lon": "-8.3436",
    "name": "Carrickfin Airport",
    "city": "Mullaghduff",
    "state": "",
    "country": "Ireland",
    "woeid": "12512717",
    "tz": "Europe\/Dublin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2001",
    "elev": "26",
    "icao": "EIDL",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CFR",
    "lat": "49.1825",
    "lon": "-0.45906",
    "name": "Carpiquet Airport",
    "city": "Carpiquet",
    "state": "Basse-Normandie",
    "country": "France",
    "woeid": "12523083",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "256",
    "icao": "LFRK",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CFS",
    "lat": "-30.3171",
    "lon": "153.119",
    "name": "Coffs Harbour Airport",
    "city": "Coffs Harbour",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510636",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6824",
    "elev": "18",
    "icao": "KCFS",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "CFU",
    "lat": "39.6013",
    "lon": "19.9136",
    "name": "Kerkira Airport",
    "city": "Corcyra",
    "state": "Nisia Ionioy",
    "country": "Greece",
    "woeid": "12513298",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hcaa.gr\/content\/index.asp",
    "runway_length": "7792",
    "elev": "13",
    "icao": "LGKR",
    "direct_flights": "33",
    "carriers": "14"
  },
  {
    "code": "CGA",
    "lat": "55.4592",
    "lon": "-133.106",
    "name": "Craig Seaplane Base",
    "city": "Craig",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524610",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CGB",
    "lat": "-15.6497",
    "lon": "-56.1169",
    "name": "Marechal Rondon International Airport",
    "city": "Cuiaba",
    "state": "Centro-Oeste",
    "country": "Brazil",
    "woeid": "12511228",
    "tz": "America\/Cuiaba",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "613",
    "icao": "SBCY",
    "direct_flights": "13",
    "carriers": "5"
  },
  {
    "code": "CGD",
    "lat": "28.9196",
    "lon": "111.63",
    "name": "Changde Airport",
    "city": "Changde",
    "state": "Hunan",
    "country": "China",
    "woeid": "12511995",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "CGH",
    "lat": "-23.6285",
    "lon": "-46.6589",
    "name": "Congonhas International Airport",
    "city": "Sao Paulo",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "23388212",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6362",
    "elev": "2631",
    "icao": "SBSP",
    "direct_flights": "26",
    "carriers": "6"
  },
  {
    "code": "CGI",
    "lat": "37.2272",
    "lon": "-89.5637",
    "name": "Cape Girardeau Municipal Airport",
    "city": "Scott City",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12519053",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6499",
    "elev": "342",
    "icao": "KCGI",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CGK",
    "lat": "-6.11964",
    "lon": "106.656",
    "name": "Jakarta International Airport",
    "city": "Tangerang",
    "state": "Banten",
    "country": "Indonesia",
    "woeid": "23388291",
    "tz": "Asia\/Jakarta",
    "phone": "+62 (0)21 550 5179",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.angkasapura2.co.id",
    "runway_length": "12008",
    "elev": "34",
    "icao": "WIII",
    "direct_flights": "51",
    "carriers": "45"
  },
  {
    "code": "CGM",
    "lat": "9.173",
    "lon": "124.725",
    "name": "Philippines",
    "city": "Camiguin",
    "state": "Northern Mindanao",
    "country": "Philippines",
    "woeid": "23424934",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "16",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CGN",
    "lat": "50.8784",
    "lon": "7.12293",
    "name": "Cologne Bonn Airport",
    "city": "Cologne",
    "state": "North Rhine-Westphalia",
    "country": "Germany",
    "woeid": "23030981",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.koeln-bonn-airport.de\/index.php",
    "runway_length": "12467",
    "elev": "300",
    "icao": "EDDK",
    "direct_flights": "129",
    "carriers": "45"
  },
  {
    "code": "CGO",
    "lat": "34.75",
    "lon": "113.65",
    "name": "Zhengzhou Airport",
    "city": "Zhengzhou",
    "state": "Henan",
    "country": "China",
    "woeid": "12523109",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZHCC",
    "direct_flights": "38",
    "carriers": "14"
  },
  {
    "code": "CGP",
    "lat": "22.2517",
    "lon": "91.815",
    "name": "Chittagong Airport",
    "city": "Chittagong",
    "state": "Chittagong",
    "country": "Bangladesh",
    "woeid": "12510886",
    "tz": "Asia\/Dhaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "12",
    "icao": "VGEG",
    "direct_flights": "10",
    "carriers": "14"
  },
  {
    "code": "CGQ",
    "lat": "43.9069",
    "lon": "125.207",
    "name": "Dafang Shen Airport",
    "city": "Changchun",
    "state": "Jilin",
    "country": "China",
    "woeid": "12512022",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZYCC",
    "direct_flights": "22",
    "carriers": "12"
  },
  {
    "code": "CGR",
    "lat": "-20.475",
    "lon": "-54.6736",
    "name": "Campo Grande International Airport",
    "city": "Campo Grande",
    "state": "Mato Grosso do Sul",
    "country": "Brazil",
    "woeid": "12511071",
    "tz": "America\/Campo_Grande",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "1833",
    "icao": "SBCG",
    "direct_flights": "9",
    "carriers": "4"
  },
  {
    "code": "CGY",
    "lat": "8.4139",
    "lon": "124.608",
    "name": "Cagayan de Oro Airport",
    "city": "Cagayan de Oro",
    "state": "Northern Mindanao",
    "country": "Philippines",
    "woeid": "12515602",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6857",
    "elev": "610",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CHA",
    "lat": "35.0373",
    "lon": "-85.1966",
    "name": "Chattanooga Metropolitan Airport",
    "city": "Chattanooga",
    "state": "Tennessee",
    "country": "United States",
    "woeid": "12520716",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7401",
    "elev": "682",
    "icao": "KCHA",
    "direct_flights": "13",
    "carriers": "15"
  },
  {
    "code": "CHC",
    "lat": "-43.4859",
    "lon": "172.534",
    "name": "Christchurch International Airport",
    "city": "Christchurch",
    "state": "Canterbury",
    "country": "New Zealand",
    "woeid": "12515152",
    "tz": "Pacific\/Auckland",
    "phone": "+64 3 358 5029",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.christchurch-airport.co.nz",
    "runway_length": "10785",
    "elev": "123",
    "icao": "NZCH",
    "direct_flights": "24",
    "carriers": "19"
  },
  {
    "code": "CHO",
    "lat": "38.14",
    "lon": "-78.4487",
    "name": "Charlottesville Albemarle Airport",
    "city": "Earlysville",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12519149",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6001",
    "elev": "640",
    "icao": "KCHO",
    "direct_flights": "7",
    "carriers": "9"
  },
  {
    "code": "CHQ",
    "lat": "35.5319",
    "lon": "24.1489",
    "name": "Souda Airport",
    "city": "Canea",
    "state": "Kriti",
    "country": "Greece",
    "woeid": "12513324",
    "tz": "Europe\/Athens",
    "phone": "+30 28 21083800",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11811",
    "elev": "492",
    "icao": "LGSA",
    "direct_flights": "24",
    "carriers": "12"
  },
  {
    "code": "CHS",
    "lat": "32.8838",
    "lon": "-80.0356",
    "name": "Charleston International Airport",
    "city": "North Charleston",
    "state": "South Carolina",
    "country": "United States",
    "woeid": "12519142",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "9001",
    "elev": "46",
    "icao": "KCHS",
    "direct_flights": "22",
    "carriers": "25"
  },
  {
    "code": "CHT",
    "lat": "-37.9723",
    "lon": "174.566",
    "name": "Karewa",
    "city": "Chatham Island",
    "state": "Canterbury",
    "country": "New Zealand",
    "woeid": "28645780",
    "tz": "Pacific\/Chatham",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4462",
    "elev": "43",
    "icao": "KCHT",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "CHU",
    "lat": "61.5769",
    "lon": "-159.244",
    "name": "Chuathbaluk",
    "city": "Chuathbaluk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2380180",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KCHU",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "CHY",
    "lat": "-6.7",
    "lon": "156.45",
    "name": "Choiseul Bay",
    "city": "Choiseul Bay",
    "state": "Western",
    "country": "Solomon Islands",
    "woeid": "1020483",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2395",
    "elev": "5",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CIA",
    "lat": "41.8004",
    "lon": "12.5908",
    "name": "Ciampino Airport",
    "city": "Rome",
    "state": "Lazio",
    "country": "Italy",
    "woeid": "22307480",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.adr.it\/",
    "runway_length": "7218",
    "elev": "426",
    "icao": "LIRA",
    "direct_flights": "34",
    "carriers": "4"
  },
  {
    "code": "CIC",
    "lat": "39.7987",
    "lon": "-121.854",
    "name": "Chico Municipal Airport",
    "city": "Chico",
    "state": "California",
    "country": "United States",
    "woeid": "12519180",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6724",
    "elev": "238",
    "icao": "KCIC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CID",
    "lat": "41.8893",
    "lon": "-91.7008",
    "name": "Cedar Rapids Municipal Airport",
    "city": "Cedar Rapids",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12519110",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "864",
    "icao": "KCID",
    "direct_flights": "15",
    "carriers": "20"
  },
  {
    "code": "CIF",
    "lat": "42.3333",
    "lon": "119.008",
    "name": "Chifeng",
    "city": "Chifeng",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "2149762",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "CIH",
    "lat": "36.1833",
    "lon": "113.133",
    "name": "Changzhi",
    "city": "Changzhi",
    "state": "Shanxi",
    "country": "China",
    "woeid": "2154549",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "CIJ",
    "lat": "-11.0178",
    "lon": "-68.759",
    "name": "E. Beltram Airport",
    "city": "Cobija",
    "state": "Pando",
    "country": "Bolivia",
    "woeid": "12523541",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3608",
    "elev": "771",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "CIK",
    "lat": "66.65",
    "lon": "-143.75",
    "name": "Chalkyitsik Airport",
    "city": "Chalkyitsik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524613",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "560",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CIP",
    "lat": "-11.1358",
    "lon": "-38.5205",
    "name": "",
    "city": "Chipata",
    "state": "Eastern",
    "country": "Zambia",
    "woeid": "1564855",
    "tz": "Africa\/Lusaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4822",
    "elev": "3359",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CIT",
    "lat": "39.89",
    "lon": "-78.0166",
    "name": "",
    "city": "Shimkent",
    "state": "",
    "country": "Kazakhstan",
    "woeid": "23424871",
    "tz": "Asia\/Qyzylorda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "CIU",
    "lat": "46.2581",
    "lon": "-84.4739",
    "name": "Chippewa County International Airport",
    "city": "Kincheloe",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12519187",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7201",
    "elev": "799",
    "icao": "KCIU",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "CIW",
    "lat": "12.7187",
    "lon": "-61.3306",
    "name": "Canouan Airport",
    "city": "Canouan Island",
    "state": "Grenadines",
    "country": "Saint Vincent and the Grenadines",
    "woeid": "12524425",
    "tz": "America\/St_Vincent",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2150",
    "elev": "18",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CIX",
    "lat": "-6.7897",
    "lon": "-79.8322",
    "name": "Cap J A Quinones Gonzales Airport",
    "city": "Chiclayo",
    "state": "Lambayeque",
    "country": "Peru",
    "woeid": "12515192",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8267",
    "elev": "95",
    "icao": "SPHI",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "CJA",
    "lat": "-7.1322",
    "lon": "-78.4889",
    "name": "Maj Gen Fap A R Iglesias Airport",
    "city": "Cajamarca",
    "state": "Cajamarca",
    "country": "Peru",
    "woeid": "12515209",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5971",
    "elev": "8590",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CJB",
    "lat": "11.0233",
    "lon": "77.0482",
    "name": "Peelamedu Airport",
    "city": "Coimbatore",
    "state": "Tamil Nadu",
    "country": "India",
    "woeid": "12513569",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "1319",
    "icao": "",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "CJC",
    "lat": "-22.4944",
    "lon": "-68.9017",
    "name": "El Loa Airport",
    "city": "Calama",
    "state": "Antofagasta",
    "country": "Chile",
    "woeid": "12512320",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9468",
    "elev": "7606",
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "CJJ",
    "lat": "36.7131",
    "lon": "127.509",
    "name": "Cheongju International Airport",
    "city": "Naesu-Eup",
    "state": "Chungcheongbuk-Do",
    "country": "South Korea",
    "woeid": "12514203",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KCJJ",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "CJL",
    "lat": "35.8861",
    "lon": "71.7944",
    "name": "Chitral Airport",
    "city": "Chitral",
    "state": "North-West Frontier",
    "country": "Pakistan",
    "woeid": "12515230",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5800",
    "elev": "4900",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CJS",
    "lat": "31.6361",
    "lon": "-106.428",
    "name": "Ciudad Juarez International Airport",
    "city": "Ciudad Juarez",
    "state": "Chihuahua",
    "country": "Mexico",
    "woeid": "12514848",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8859",
    "elev": "3842",
    "icao": "MMCS",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "CJU",
    "lat": "33.5056",
    "lon": "126.495",
    "name": "Cheju International Airport",
    "city": "Jeju-Si",
    "state": "Jaeju-Do",
    "country": "South Korea",
    "woeid": "12514202",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9800",
    "elev": "126",
    "icao": "RKPC",
    "direct_flights": "19",
    "carriers": "8"
  },
  {
    "code": "CKB",
    "lat": "39.2967",
    "lon": "-80.2314",
    "name": "Benedum Airport",
    "city": "Clarksburg",
    "state": "West Virginia",
    "country": "United States",
    "woeid": "12518797",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5198",
    "elev": "1203",
    "icao": "KCKB",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "CKD",
    "lat": "61.8667",
    "lon": "-158.133",
    "name": "Crooked Creek Airport",
    "city": "Aniak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524616",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1200",
    "elev": "2650",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "CKG",
    "lat": "29.5833",
    "lon": "106.5",
    "name": "Chongqing Jiangbei International",
    "city": "Chongqing",
    "state": "Chongqing",
    "country": "China",
    "woeid": "12523085",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "48",
    "carriers": "25"
  },
  {
    "code": "CKH",
    "lat": "70.6416",
    "lon": "147.933",
    "name": "Russia",
    "city": "Chokurdah",
    "state": "Sakha",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Asia\/Magadan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CKS",
    "lat": "-6.1175",
    "lon": "-50.0036",
    "name": "Carajas Airport",
    "city": "Carajas",
    "state": "Para",
    "country": "Brazil",
    "woeid": "12511077",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "2028",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CKX",
    "lat": "64.0833",
    "lon": "-141.917",
    "name": "Chicken Airport",
    "city": "Tok",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524619",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "1640",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CKY",
    "lat": "9.5764",
    "lon": "-13.6108",
    "name": "Conakry Airport",
    "city": "Conakry",
    "state": "Conakry",
    "country": "Guinea",
    "woeid": "12513342",
    "tz": "Africa\/Conakry",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10827",
    "elev": "72",
    "icao": "GUCY",
    "direct_flights": "7",
    "carriers": "14"
  },
  {
    "code": "CKZ",
    "lat": "40.1553",
    "lon": "26.4142",
    "name": "Abydus",
    "city": "Canakkale",
    "state": "\u00c7anakkale",
    "country": "Turkey",
    "woeid": "2343859",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3654",
    "elev": "28",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CLD",
    "lat": "33.1255",
    "lon": "-117.275",
    "name": "Mcclellan Palomar Airport",
    "city": "Carlsbad",
    "state": "California",
    "country": "United States",
    "woeid": "12520851",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.co.san-diego.ca.us\/dpw\/airports\/mcpal.htm",
    "runway_length": "4700",
    "elev": "328",
    "icao": "KCRQ",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "CLE",
    "lat": "41.4115",
    "lon": "-81.8339",
    "name": "Hopkins International Airport",
    "city": "Cleveland",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12520226",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8998",
    "elev": "792",
    "icao": "KCLE",
    "direct_flights": "102",
    "carriers": "44"
  },
  {
    "code": "CLJ",
    "lat": "46.7902",
    "lon": "23.6984",
    "name": "Someseni Airport",
    "city": "Clausemburgo",
    "state": "Cluj",
    "country": "Romania",
    "woeid": "12515577",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "1033",
    "icao": "LRCL",
    "direct_flights": "17",
    "carriers": "9"
  },
  {
    "code": "CLL",
    "lat": "30.5938",
    "lon": "-96.3685",
    "name": "Easterwood Field Airport",
    "city": "College Station",
    "state": "Texas",
    "country": "United States",
    "woeid": "12519579",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "319",
    "icao": "KCLL",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "CLM",
    "lat": "48.1155",
    "lon": "-123.491",
    "name": "William R Fairchild International Airport",
    "city": "Port Angeles",
    "state": "Washington",
    "country": "United States",
    "woeid": "12522452",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6349",
    "elev": "288",
    "icao": "KCLM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CLO",
    "lat": "3.5461",
    "lon": "-76.385",
    "name": "Alfonso Bonilla Aragon International Airport",
    "city": "Obando",
    "state": "Valle del Cauca",
    "country": "Colombia",
    "woeid": "12512360",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "3162",
    "icao": "SKCL",
    "direct_flights": "19",
    "carriers": "15"
  },
  {
    "code": "CLP",
    "lat": "58.8517",
    "lon": "-158.51",
    "name": "Clarks Point Airport",
    "city": "Clarks Point",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524621",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2730",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CLQ",
    "lat": "19.2733",
    "lon": "-103.577",
    "name": "Colima Airport",
    "city": "Cuauht\u00e9moc",
    "state": "Colima",
    "country": "Mexico",
    "woeid": "12514853",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4746",
    "elev": "1499",
    "icao": "MMIA",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CLT",
    "lat": "35.2226",
    "lon": "-80.946",
    "name": "Douglas International Airport",
    "city": "Charlotte",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12519519",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.charmeck.org\/Departments\/Airport\/Home.htm",
    "runway_length": "10000",
    "elev": "749",
    "icao": "KCLT",
    "direct_flights": "133",
    "carriers": "39"
  },
  {
    "code": "CLY",
    "lat": "42.5295",
    "lon": "8.79677",
    "name": "Ste Catherine Airport",
    "city": "Calenzana",
    "state": "Corsica",
    "country": "France",
    "woeid": "12512978",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7579",
    "elev": "210",
    "icao": "LFKC",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "CMA",
    "lat": "-28.0333",
    "lon": "145.617",
    "name": "Cunnamulla",
    "city": "Cunnamulla",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12708296",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5680",
    "elev": "630",
    "icao": "YCMU",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CMB",
    "lat": "7.1806",
    "lon": "79.8856",
    "name": "Katunayake International Airport",
    "city": "Gampaha",
    "state": "Western",
    "country": "Sri Lanka",
    "woeid": "12511941",
    "tz": "Asia\/Colombo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10900",
    "elev": null,
    "icao": "VCBI",
    "direct_flights": "37",
    "carriers": "26"
  },
  {
    "code": "CME",
    "lat": "18.6522",
    "lon": "-91.8011",
    "name": "Ciudad del Carmen Airport",
    "city": "Carmen Olor\u00f3n",
    "state": "Campeche",
    "country": "Mexico",
    "woeid": "12514846",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6004",
    "elev": "7",
    "icao": "MMCE",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "CMF",
    "lat": "45.6375",
    "lon": "5.88291",
    "name": "Aix les Bains Airport",
    "city": "La Motte",
    "state": "Rhone-Alpes",
    "country": "France",
    "woeid": "12512839",
    "tz": "Europe\/Paris",
    "phone": "+33 4 79 54 43 54",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.chambery.aeroport.fr\/rubrique.php3?id_rubrique=55",
    "runway_length": "5807",
    "elev": "778",
    "icao": "LFLB",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "CMG",
    "lat": "-19.0055",
    "lon": "-57.7055",
    "name": "Corumba International Airport",
    "city": "Corumba",
    "state": "Mato Grosso do Sul",
    "country": "Brazil",
    "woeid": "12511101",
    "tz": "America\/Campo_Grande",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5443",
    "elev": "461",
    "icao": "SBCR",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CMH",
    "lat": "39.9974",
    "lon": "-82.8877",
    "name": "Port Columbus International Airport",
    "city": "Columbus",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12521441",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10701",
    "elev": "816",
    "icao": "KCMH",
    "direct_flights": "41",
    "carriers": "35"
  },
  {
    "code": "CMI",
    "lat": "40.0367",
    "lon": "-88.2638",
    "name": "University of Illinois-Willard Airport",
    "city": "Savoy",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12522247",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flycmi.com",
    "runway_length": "8100",
    "elev": "754",
    "icao": "KCMI",
    "direct_flights": "3",
    "carriers": "6"
  },
  {
    "code": "CMN",
    "lat": "33.365",
    "lon": "-7.5817",
    "name": "Mohamed V Airport",
    "city": "Casablanca",
    "state": "Casablanca",
    "country": "Morocco",
    "woeid": "12514788",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12205",
    "elev": "656",
    "icao": "GMMN",
    "direct_flights": "74",
    "carriers": "31"
  },
  {
    "code": "CMU",
    "lat": "-6.0283",
    "lon": "44.9717",
    "name": "Chimbu Airport",
    "city": "Kundiawa",
    "state": null,
    "country": "Papua New Guinea",
    "woeid": "12515463",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3410",
    "elev": "4900",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CMW",
    "lat": "21.4186",
    "lon": "-77.8458",
    "name": "Ignacio Agramonte Airport",
    "city": "Camag\u00fcey",
    "state": "Camaguey",
    "country": "Cuba",
    "woeid": "12512465",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "413",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CMX",
    "lat": "47.1689",
    "lon": "-88.4942",
    "name": "Houghton County Memorial Airport",
    "city": "Hancock",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12520236",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6501",
    "elev": "1095",
    "icao": "KCMX",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "CNB",
    "lat": "-30.9742",
    "lon": "148.381",
    "name": "Coonamble Airport",
    "city": "Coonamble",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510642",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5010",
    "elev": "604",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CNC",
    "lat": "-10.0505",
    "lon": "143.069",
    "name": "Australia",
    "city": "Coconut Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "28644989",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KCNC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CND",
    "lat": "44.3581",
    "lon": "28.4896",
    "name": "Constanta Mihail Kogalniceanu Airport",
    "city": "Kustenje",
    "state": "Constanta",
    "country": "Romania",
    "woeid": "12515525",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "354",
    "icao": "LRCK",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "CNF",
    "lat": "-19.6242",
    "lon": "-43.9717",
    "name": "Tancredo Neves International Airport",
    "city": "Confins",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511339",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "SBCF",
    "direct_flights": "15",
    "carriers": "13"
  },
  {
    "code": "CNJ",
    "lat": "-20.6657",
    "lon": "140.508",
    "name": "Cloncurry Aerodrome",
    "city": "Cloncurry",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510634",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "622",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "CNM",
    "lat": "32.3458",
    "lon": "-104.251",
    "name": "Cavern City Air Terminal Airport",
    "city": "Carlsbad",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12519107",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7854",
    "elev": "3293",
    "icao": "KCNM",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CNP",
    "lat": "70.7431",
    "lon": "-22.6583",
    "name": "Neerlerit Inaat",
    "city": "Neerlerit Inaat",
    "state": "Ostgronland",
    "country": "Greenland",
    "woeid": "10644998",
    "tz": "America\/Indiana\/Tell_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KCNP",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CNQ",
    "lat": "-27.4497",
    "lon": "-58.7622",
    "name": "Corrientes Airport",
    "city": "Corrientes",
    "state": "Corrientes",
    "country": "Argentina",
    "woeid": "12510484",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "203",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "CNS",
    "lat": "-16.8831",
    "lon": "145.753",
    "name": "Cairns International Airport",
    "city": "Cairns",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510623",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10489",
    "elev": "10",
    "icao": "YBCS",
    "direct_flights": "29",
    "carriers": "20"
  },
  {
    "code": "CNX",
    "lat": "18.7714",
    "lon": "98.9628",
    "name": "Chiang Mai International Airport",
    "city": "Chiang Mai",
    "state": "Roi Et",
    "country": "Thailand",
    "woeid": "12517750",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportthai.co.th\/airportnew\/chmai\/html\/gen_info1.htm",
    "runway_length": "10330",
    "elev": "1035",
    "icao": "VTCC",
    "direct_flights": "16",
    "carriers": "21"
  },
  {
    "code": "CNY",
    "lat": "38.7609",
    "lon": "-109.742",
    "name": "Canyonlands Field Airport",
    "city": "Thompson",
    "state": "Utah",
    "country": "United States",
    "woeid": "12519050",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6901",
    "elev": "4575",
    "icao": "KCNY",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "COD",
    "lat": "44.5134",
    "lon": "-109.031",
    "name": "Yellowstone Regional Airport",
    "city": "Cody\/Yellowstone",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12522532",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8505",
    "elev": "5089",
    "icao": "KCOD",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "COK",
    "lat": "9.93997",
    "lon": "76.2748",
    "name": "Kochi Airport",
    "city": "Kochi",
    "state": "Kerala",
    "country": "India",
    "woeid": "12513568",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cochin-airport.com\/",
    "runway_length": "6380",
    "elev": "8",
    "icao": "VOCC",
    "direct_flights": "22",
    "carriers": "21"
  },
  {
    "code": "COL",
    "lat": "56.6167",
    "lon": "-6.61667",
    "name": "Coll Island Airport",
    "city": "Isle of coll",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "12523978",
    "tz": "Europe\/London",
    "phone": "0187 93367",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1642",
    "elev": "41",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "COO",
    "lat": "6.3542",
    "lon": "2.3856",
    "name": "Cotonou Cadjehon Airport",
    "city": "Cotonou",
    "state": "Atlantique",
    "country": "Benin",
    "woeid": "12510939",
    "tz": "Africa\/Porto-Novo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "16",
    "icao": "DBBB",
    "direct_flights": "16",
    "carriers": "21"
  },
  {
    "code": "COQ",
    "lat": "48.1366",
    "lon": "114.65",
    "name": "Choybalsan Northeast Airport",
    "city": "Choibalsan",
    "state": "Dornod",
    "country": "Mongolia",
    "woeid": "12514740",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KCOQ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "COR",
    "lat": "-31.3131",
    "lon": "-64.1994",
    "name": "Ingeniero Ambrosio L.V. Taravella International ",
    "city": "Cordoba",
    "state": "Cordoba",
    "country": "Argentina",
    "woeid": "12510482",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10499",
    "elev": "1604",
    "icao": "SACO",
    "direct_flights": "6",
    "carriers": "8"
  },
  {
    "code": "COS",
    "lat": "38.7829",
    "lon": "-104.697",
    "name": "City of Colorado Springs Municipal Airport",
    "city": "Colorado Springs",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12519208",
    "tz": "America\/Denver",
    "phone": "719-550-1972",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11021",
    "elev": "6172",
    "icao": "KCOS",
    "direct_flights": "16",
    "carriers": "19"
  },
  {
    "code": "COU",
    "lat": "38.8174",
    "lon": "-92.2218",
    "name": "Columbia Regional Airport",
    "city": "Columbia",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12519286",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "889",
    "icao": "KCOU",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "CPC",
    "lat": "-40.0758",
    "lon": "-71.1392",
    "name": "Chapelco Airport",
    "city": "San Martin DeLos Andes",
    "state": "Buenos Aires",
    "country": "Argentina",
    "woeid": "12510474",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "2556",
    "icao": "SAMI",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CPD",
    "lat": "-29.0378",
    "lon": "134.724",
    "name": "Coober Pedy Aerodrome",
    "city": "Coober Pedy",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "12510638",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4630",
    "elev": "734",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CPE",
    "lat": "19.8158",
    "lon": "-90.5008",
    "name": "Ignacio Alberto Acuna Ongay Airport",
    "city": "Campeche",
    "state": "Campeche",
    "country": "Mexico",
    "woeid": "12514891",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "31",
    "icao": "MMCP",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "CPH",
    "lat": "55.6205",
    "lon": "12.6495",
    "name": "Copenhagen Airport",
    "city": "Kastrup",
    "state": "Hovedstaden",
    "country": "Denmark",
    "woeid": "22851127",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cph.dk",
    "runway_length": "11811",
    "elev": "17",
    "icao": "EKCH",
    "direct_flights": "136",
    "carriers": "76"
  },
  {
    "code": "CPO",
    "lat": "-27.2933",
    "lon": "-70.4122",
    "name": "Chamonate Airport",
    "city": "Copiap\u00f3",
    "state": "Atacama",
    "country": "Chile",
    "woeid": "12512314",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "954",
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "CPQ",
    "lat": "-23",
    "lon": "-47.1333",
    "name": "Campinas International Airport",
    "city": "Campinas",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12523513",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3936",
    "elev": "2051",
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "CPR",
    "lat": "42.8966",
    "lon": "-106.465",
    "name": "Natrona County International Airport",
    "city": "Casper",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12521085",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10600",
    "elev": "5348",
    "icao": "KCPR",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "CPT",
    "lat": "-33.9647",
    "lon": "18.6022",
    "name": "D F Malan Airport",
    "city": "Cape Town",
    "state": "Western Cape",
    "country": "South Africa",
    "woeid": "12517402",
    "tz": "Africa\/Johannesburg",
    "phone": "+ 27 21 937 1275",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.acsa.co.za",
    "runway_length": "10512",
    "elev": "151",
    "icao": "FACT",
    "direct_flights": "23",
    "carriers": "27"
  },
  {
    "code": "CPV",
    "lat": "-7.2689",
    "lon": "-35.8947",
    "name": "Presidente Joao Suassuna Airport",
    "city": "Campina Grande",
    "state": "Paraiba",
    "country": "Brazil",
    "woeid": "12511202",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "1641",
    "icao": "SBKG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CPX",
    "lat": "18.3204",
    "lon": "-65.2929",
    "name": "Culebra Airport",
    "city": "Culebra",
    "state": "Puerto Rico",
    "country": "United States",
    "woeid": "12515657",
    "tz": "America\/Puerto_Rico",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "65",
    "icao": "TJCP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CQD",
    "lat": "32.2967",
    "lon": "50.8417",
    "name": "Shahre-Kord",
    "city": "Shahre-Kord",
    "state": "Chahar Mahall va Bakhtiari",
    "country": "Iran",
    "woeid": "12843511",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "CRA",
    "lat": "44.3173",
    "lon": "23.8447",
    "name": "Craiova Airport",
    "city": "Croiova",
    "state": "Dolj",
    "country": "Romania",
    "woeid": "12523964",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LRCV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CRD",
    "lat": "-45.7872",
    "lon": "-67.4628",
    "name": "General Enrique Mosconi Airport",
    "city": "Comodoro Rivadavia",
    "state": "Chubut",
    "country": "Argentina",
    "woeid": "12510499",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9025",
    "elev": "190",
    "icao": "SAVC",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "CRI",
    "lat": "22.7467",
    "lon": "-74.18",
    "name": "Colonel Hill Airport",
    "city": "Colonel Hill",
    "state": "Crooked Island",
    "country": "Bahamas",
    "woeid": "12510863",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "5",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CRK",
    "lat": "15.1806",
    "lon": "120.557",
    "name": "Clark Field Airport",
    "city": "Mabalacat",
    "state": "Central Luzon",
    "country": "Philippines",
    "woeid": "12515607",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "478",
    "icao": "RPLC",
    "direct_flights": "9",
    "carriers": "9"
  },
  {
    "code": "CRL",
    "lat": "50.4601",
    "lon": "4.45878",
    "name": "Gosselies Airport",
    "city": "Charleroi",
    "state": "Hainault",
    "country": "Belgium",
    "woeid": "22048658",
    "tz": "Europe\/Brussels",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "614",
    "icao": "EBCI",
    "direct_flights": "32",
    "carriers": "3"
  },
  {
    "code": "CRM",
    "lat": "12.5044",
    "lon": "124.635",
    "name": "Catarman Airport",
    "city": "Catarman",
    "state": "Eastern Visayas",
    "country": "Philippines",
    "woeid": "12515605",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3346",
    "elev": "6",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "CRP",
    "lat": "27.7747",
    "lon": "-97.5019",
    "name": "Corpus Christi International Airport",
    "city": "Corpus Christi",
    "state": "Texas",
    "country": "United States",
    "woeid": "12519324",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7508",
    "elev": "44",
    "icao": "KCRP",
    "direct_flights": "5",
    "carriers": "12"
  },
  {
    "code": "CRW",
    "lat": "38.3697",
    "lon": "-81.5951",
    "name": "Yeager Airport",
    "city": "Charleston",
    "state": "West Virginia",
    "country": "United States",
    "woeid": "12522530",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6302",
    "elev": "982",
    "icao": "KCRW",
    "direct_flights": "10",
    "carriers": "9"
  },
  {
    "code": "CSA",
    "lat": "56.0603",
    "lon": "-6.22418",
    "name": "Isle Of Colonsay",
    "city": "Isle Of Colonsay",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "26350303",
    "tz": "Europe\/London",
    "phone": "01951 200411",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1642",
    "elev": "35",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CSG",
    "lat": "32.5184",
    "lon": "-84.9401",
    "name": "Columbus Metropolitan Airport",
    "city": "Columbus",
    "state": "Georgia",
    "country": "United States",
    "woeid": "23418432",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KCSG",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CSH",
    "lat": "65.05",
    "lon": "35.6667",
    "name": "Solovky",
    "city": "Solovetsky",
    "state": "Arkhangelrskaya Oblast",
    "country": "Russian Federation",
    "woeid": "2030227",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CSK",
    "lat": "12.3953",
    "lon": "-16.7514",
    "name": "Cap Skiring Airport",
    "city": "Cap Skirring",
    "state": "",
    "country": "Senegal",
    "woeid": "12517504",
    "tz": "Africa\/Dakar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4757",
    "elev": "49",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CSX",
    "lat": "28.0711",
    "lon": "112.958",
    "name": "Huanghua Airport",
    "city": "Changsha",
    "state": "Hunan",
    "country": "China",
    "woeid": "12523089",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZGHA",
    "direct_flights": "45",
    "carriers": "20"
  },
  {
    "code": "CSY",
    "lat": "56.1311",
    "lon": "47.2882",
    "name": "Russia",
    "city": "Cheboksary",
    "state": "Chuvashiya",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UWKS",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "CTA",
    "lat": "37.4696",
    "lon": "15.0672",
    "name": "Catania Fontanarossa Airport",
    "city": "Catania",
    "state": "Sicily",
    "country": "Italy",
    "woeid": "22307044",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroporto.catania.it\/",
    "runway_length": "8727",
    "elev": "43",
    "icao": "LICC",
    "direct_flights": "45",
    "carriers": "40"
  },
  {
    "code": "CTC",
    "lat": "-28.5939",
    "lon": "-65.7542",
    "name": "Catamarca Airport",
    "city": "San Isidro",
    "state": "Catamarca",
    "country": "Argentina",
    "woeid": "12510470",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "1522",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CTG",
    "lat": "10.4481",
    "lon": "-75.5106",
    "name": "Rafael Nunez Airport",
    "city": "La Boquilla",
    "state": "Bolivar",
    "country": "Colombia",
    "woeid": "12512408",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "5",
    "icao": "SKCG",
    "direct_flights": "11",
    "carriers": "8"
  },
  {
    "code": "CTL",
    "lat": "-26.4135",
    "lon": "146.263",
    "name": "Charleville Aerodrome",
    "city": "Charleville",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510629",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1001",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "CTM",
    "lat": "18.5039",
    "lon": "-88.3247",
    "name": "Chetumal International Airport",
    "city": "Chetumal",
    "state": "Quintana Roo",
    "country": "Mexico",
    "woeid": "12514841",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7244",
    "elev": "39",
    "icao": "MMCM",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CTS",
    "lat": "42.7757",
    "lon": "141.691",
    "name": "New Chitose Airport",
    "city": "Chitose-shi",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "12523091",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "87",
    "icao": "RJCC",
    "direct_flights": "30",
    "carriers": "28"
  },
  {
    "code": "CTU",
    "lat": "30.5775",
    "lon": "103.941",
    "name": "Chengdushuang Liu Airport",
    "city": "Chengdu",
    "state": "Sichuan",
    "country": "China",
    "woeid": "12512010",
    "tz": "Asia\/Shanghai",
    "phone": "0086-28-85702649 \/ 0086-",
    "type": "Airports",
    "email": "chengduair@cdairport.com",
    "url": "http:\/\/www.cdairport.com\/cdairport\/en_front\/index.jsp",
    "runway_length": null,
    "elev": null,
    "icao": "ZUUU",
    "direct_flights": "62",
    "carriers": "30"
  },
  {
    "code": "CUC",
    "lat": "7.93",
    "lon": "-72.5094",
    "name": "Camilo Daza Airport",
    "city": "C\u00facuta",
    "state": "Norte de Santander",
    "country": "Colombia",
    "woeid": "12512370",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7611",
    "elev": "1073",
    "icao": "SKCC",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "CUE",
    "lat": "-2.8867",
    "lon": "-78.9833",
    "name": "Mariscal Lamar Airport",
    "city": "Cuenca",
    "state": "Azuay",
    "country": "Ecuador",
    "woeid": "12512642",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6230",
    "elev": "8302",
    "icao": "SECU",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CUF",
    "lat": "44.5466",
    "lon": "7.61998",
    "name": "Levaldigi Airport",
    "city": "Fossano",
    "state": "Piedmont",
    "country": "Italy",
    "woeid": "12513834",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "1242",
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "CUK",
    "lat": "17.4956",
    "lon": "-88.1816",
    "name": "Belize",
    "city": "Caye Caulker",
    "state": "Belize",
    "country": "Belize",
    "woeid": "23424760",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CUL",
    "lat": "24.7644",
    "lon": "-107.474",
    "name": "Culiacan Airport",
    "city": "Culiacan",
    "state": "Sinaloa",
    "country": "Mexico",
    "woeid": "12514859",
    "tz": "America\/Mazatlan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "108",
    "icao": "MMCL",
    "direct_flights": "8",
    "carriers": "7"
  },
  {
    "code": "CUM",
    "lat": "10.4539",
    "lon": "-64.1297",
    "name": "Antonio Jose de Sucre Airport",
    "city": "Cuman\u00e1",
    "state": "Sucre",
    "country": "Venezuela",
    "woeid": "12522754",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6857",
    "elev": "14",
    "icao": "SVCU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CUN",
    "lat": "21.0406",
    "lon": "-86.8744",
    "name": "Cancun Airport",
    "city": "Cancun",
    "state": "Quintana Roo",
    "country": "Mexico",
    "woeid": "23388337",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "16",
    "icao": "MMUN",
    "direct_flights": "68",
    "carriers": "47"
  },
  {
    "code": "CUP",
    "lat": "10.6589",
    "lon": "-63.2625",
    "name": "Gen Jose Francisco Bermudez Airport",
    "city": "El Pilar",
    "state": "Sucre",
    "country": "Venezuela",
    "woeid": "12522786",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6759",
    "elev": "33",
    "icao": "SVCP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CUR",
    "lat": "12.188",
    "lon": "-68.9634",
    "name": "Hato Airport",
    "city": "Curacao",
    "state": "Curacao",
    "country": "Netherlands Antilles",
    "woeid": "12515138",
    "tz": "America\/Curacao",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11188",
    "elev": "29",
    "icao": "TNCC",
    "direct_flights": "20",
    "carriers": "21"
  },
  {
    "code": "CUU",
    "lat": "28.7028",
    "lon": "-105.964",
    "name": "General R F Villalobos International Airport",
    "city": "Chihuahua",
    "state": "Chihuahua",
    "country": "Mexico",
    "woeid": "12514884",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8531",
    "elev": "4462",
    "icao": "MMCU",
    "direct_flights": "10",
    "carriers": "12"
  },
  {
    "code": "CUZ",
    "lat": "-13.5308",
    "lon": "-71.9472",
    "name": "Velazco Astete Airport",
    "city": "San Sebasti\u00e1n",
    "state": "Cusco",
    "country": "Peru",
    "woeid": "12515222",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11316",
    "elev": "10656",
    "icao": "SPZO",
    "direct_flights": "5",
    "carriers": "10"
  },
  {
    "code": "CVG",
    "lat": "39.0571",
    "lon": "-84.6625",
    "name": "Greater Cincinnati International Airport",
    "city": "Hebron",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12519978",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9501",
    "elev": "891",
    "icao": "KCVG",
    "direct_flights": "115",
    "carriers": "39"
  },
  {
    "code": "CVM",
    "lat": "23.7139",
    "lon": "-98.9653",
    "name": "Ciudad Victoria Airport",
    "city": "G\u00fc\u00e9mez",
    "state": "Tamaulipas",
    "country": "Mexico",
    "woeid": "12514851",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7216",
    "elev": "771",
    "icao": "MMCV",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "CVN",
    "lat": "34.4271",
    "lon": "-103.087",
    "name": "Clovis Municipal Airport",
    "city": "Texico",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12519252",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6200",
    "elev": "4214",
    "icao": "KCVN",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CVQ",
    "lat": "-24.8801",
    "lon": "113.669",
    "name": "Carnarvon Airport",
    "city": "Carnarvon",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510626",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5508",
    "elev": "12",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "CVT",
    "lat": "52.367",
    "lon": "-1.4895",
    "name": "Coventry Airport",
    "city": "Coventry",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518067",
    "tz": "Europe\/London",
    "phone": "+44 (0) 24 76 308 600",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.coventryairport.co.uk",
    "runway_length": "6588",
    "elev": "269",
    "icao": "EGBE",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "CVU",
    "lat": "39.7",
    "lon": "-31.1",
    "name": "Corvo Island Airport",
    "city": "Corvo",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12523960",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CWA",
    "lat": "44.7846",
    "lon": "-89.6704",
    "name": "Central Wisconsin Airport",
    "city": "Mosinee",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12519120",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7645",
    "elev": "1277",
    "icao": "KCWA",
    "direct_flights": "4",
    "carriers": "9"
  },
  {
    "code": "CWB",
    "lat": "-25.5275",
    "lon": "-49.1731",
    "name": "Afonso Pena International Airport",
    "city": "Sao Jose dos Pinhais",
    "state": "Parana",
    "country": "Brazil",
    "woeid": "12511012",
    "tz": "America\/Sao_Paulo",
    "phone": "+55(41)3381-1515",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7267",
    "elev": "2989",
    "icao": "SBCT",
    "direct_flights": "19",
    "carriers": "16"
  },
  {
    "code": "CWC",
    "lat": "48.2526",
    "lon": "25.976",
    "name": "Chernovtsy Airport",
    "city": "Chernivtsi",
    "state": "Chernivets\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518229",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CWL",
    "lat": "51.3998",
    "lon": "-3.34475",
    "name": "Cardiff International Airport",
    "city": "Barry",
    "state": "Wales",
    "country": "United Kingdom",
    "woeid": "22452963",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cwlfly.com",
    "runway_length": "7723",
    "elev": "220",
    "icao": "EGFF",
    "direct_flights": "23",
    "carriers": "16"
  },
  {
    "code": "CXB",
    "lat": "21.45",
    "lon": "91.9681",
    "name": "Coxs Bazar Airport",
    "city": "Cox's Bazar",
    "state": "Chittagong",
    "country": "Bangladesh",
    "woeid": "12510888",
    "tz": "Asia\/Dhaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "CXH",
    "lat": "49.5912",
    "lon": "-125.848",
    "name": "Coal Harbour Airport",
    "city": "Vancouver",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511619",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYHC",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "CXI",
    "lat": "1.9849",
    "lon": "-157.675",
    "name": "Christmas Island Airport",
    "city": "Christmas Island",
    "state": "Florida",
    "country": "Kiribati",
    "woeid": "12514192",
    "tz": "America\/Kentucky\/Monticello",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6895",
    "elev": "5",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "CXJ",
    "lat": "-29.1961",
    "lon": "-51.1906",
    "name": "Campo dos Bugres Airport",
    "city": "Caxias do Sul",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511068",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CXR",
    "lat": "12.2333",
    "lon": "109.2",
    "name": "Nha-Trang Airport",
    "city": "Nha Trang",
    "state": "Khanh Hoa",
    "country": "Vietnam",
    "woeid": "12523189",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CYB",
    "lat": "19.689",
    "lon": "-79.8848",
    "name": "Gerrard Smith Airport",
    "city": "Cayman Brac Is",
    "state": "Cayman Brac",
    "country": "Cayman Islands",
    "woeid": "12512344",
    "tz": "America\/Cayman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5800",
    "elev": "7",
    "icao": "MWCB",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CYF",
    "lat": "60.1558",
    "lon": "-164.273",
    "name": "Chefornak Airport",
    "city": "Chefornak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524634",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "1850",
    "elev": "40",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "CYI",
    "lat": "23.4542",
    "lon": "120.404",
    "name": "Chaiyi Airport",
    "city": "Chiayi City",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "12517927",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10006",
    "elev": "82",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CYO",
    "lat": "21.6169",
    "lon": "-81.5422",
    "name": "Cayo Largo del sur Airport",
    "city": "Cayo Largo Del Sur",
    "state": "Isla de la Juventud",
    "country": "Cuba",
    "woeid": "12512455",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10493",
    "elev": "16",
    "icao": "KCYO",
    "direct_flights": "6",
    "carriers": "5"
  },
  {
    "code": "CYP",
    "lat": "12.0722",
    "lon": "124.544",
    "name": "Calbayog Airport",
    "city": "Calbayog",
    "state": "Eastern Visayas",
    "country": "Philippines",
    "woeid": "12515603",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5415",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "CYS",
    "lat": "41.1527",
    "lon": "-104.819",
    "name": "Cheyenne Airport",
    "city": "Cheyenne",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12519176",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9200",
    "elev": "6156",
    "icao": "KCYS",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "CYU",
    "lat": "10.8762",
    "lon": "121.064",
    "name": "Cuyo",
    "city": "Cuyo",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "1199109",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "98",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "CYX",
    "lat": "68.75",
    "lon": "161.35",
    "name": "Cherskiy",
    "city": "Cherskiy",
    "state": "Sakha",
    "country": "Russian Federation",
    "woeid": "2120544",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CYZ",
    "lat": "16.93",
    "lon": "121.756",
    "name": "Cauayan Airport",
    "city": "Cauayan",
    "state": "Cagayan Valley",
    "country": "Philippines",
    "woeid": "12515606",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "200",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "CZE",
    "lat": "11.4158",
    "lon": "-69.6819",
    "name": "Jose Leonardo Chirinos Airport",
    "city": "La Vela de Coro",
    "state": "Falcon",
    "country": "Venezuela",
    "woeid": "12522796",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "52",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CZH",
    "lat": "18.3696",
    "lon": "-88.4307",
    "name": "Corozal Airport",
    "city": "Corozal",
    "state": "Corozal",
    "country": "Belize",
    "woeid": "12524033",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1970",
    "elev": "39",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CZL",
    "lat": "36.2778",
    "lon": "6.6247",
    "name": "Ain El Bey Airport",
    "city": "Constantine",
    "state": "Constantine",
    "country": "Algeria",
    "woeid": "12510312",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "2303",
    "icao": "KCZL",
    "direct_flights": "13",
    "carriers": "2"
  },
  {
    "code": "CZM",
    "lat": "20.5242",
    "lon": "-86.9264",
    "name": "Cozumel International Airport",
    "city": "San Miguel de Cozumel",
    "state": "Quintana Roo",
    "country": "Mexico",
    "woeid": "12514855",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8859",
    "elev": "16",
    "icao": "MMCZ",
    "direct_flights": "14",
    "carriers": "12"
  },
  {
    "code": "CZN",
    "lat": "62.0833",
    "lon": "-142.05",
    "name": "Chisana Airport",
    "city": "Gakona",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524639",
    "tz": "America\/Yakutat",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "3318",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CZS",
    "lat": "-7.5994",
    "lon": "-72.7697",
    "name": "Cruzeiro do Sul International Airport",
    "city": "Cruzeiro Do Sul",
    "state": "Acre",
    "country": "Brazil",
    "woeid": "12511105",
    "tz": "America\/Rio_Branco",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7877",
    "elev": "600",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "CZU",
    "lat": "9.3375",
    "lon": "-75.2828",
    "name": "Las Brujas Airport",
    "city": "Morroa",
    "state": "Sucre",
    "country": "Colombia",
    "woeid": "12512390",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4806",
    "elev": "568",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "CZX",
    "lat": "31.7833",
    "lon": "119.95",
    "name": "Changzhou Airport",
    "city": "Changzhou",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "12523092",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "DAB",
    "lat": "29.1874",
    "lon": "-81.0615",
    "name": "Daytona Beach International Airport",
    "city": "Daytona Beach",
    "state": "Florida",
    "country": "United States",
    "woeid": "12519432",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "35",
    "icao": "KDAB",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "DAC",
    "lat": "23.8379",
    "lon": "90.3948",
    "name": "Zia International Airport Dhaka",
    "city": "Dhaka",
    "state": "Dhaka",
    "country": "Bangladesh",
    "woeid": "12510895",
    "tz": "Asia\/Dhaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "26",
    "icao": "VGZR",
    "direct_flights": "32",
    "carriers": "31"
  },
  {
    "code": "DAD",
    "lat": "16.05",
    "lon": "108.2",
    "name": "Da Nang Airport",
    "city": "Tourane",
    "state": "Da Nang",
    "country": "Vietnam",
    "woeid": "12523093",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "30",
    "icao": "VVDN",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "DAL",
    "lat": "32.8439",
    "lon": "-96.8496",
    "name": "Dallas Love Field Airport",
    "city": "Dallas",
    "state": "Texas",
    "country": "United States",
    "woeid": "12519401",
    "tz": "America\/Chicago",
    "phone": "1-214-670-6073",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dallas-lovefield.com\/",
    "runway_length": "8800",
    "elev": "487",
    "icao": "KDAL",
    "direct_flights": "19",
    "carriers": "8"
  },
  {
    "code": "DAM",
    "lat": "33.4139",
    "lon": "36.5178",
    "name": "Damascus International Airport",
    "city": "Damascus",
    "state": "Rif Dimashq",
    "country": "Syria",
    "woeid": "12517698",
    "tz": "Asia\/Damascus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11811",
    "elev": "2020",
    "icao": "OSDI",
    "direct_flights": "52",
    "carriers": "39"
  },
  {
    "code": "DAR",
    "lat": "-6.8753",
    "lon": "39.2019",
    "name": "Dar Es Salaam Airport",
    "city": "Dar es Salaam",
    "state": "Dar es Salaam",
    "country": "Tanzania",
    "woeid": "12518013",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "182",
    "icao": "HTDA",
    "direct_flights": "22",
    "carriers": "24"
  },
  {
    "code": "DAT",
    "lat": "45.3666",
    "lon": "-102.682",
    "name": "",
    "city": "Datong",
    "state": "Shanxi",
    "country": "China",
    "woeid": "26198304",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "DAU",
    "lat": "-9.0878",
    "lon": "143.195",
    "name": "Daru Airport",
    "city": "Daru",
    "state": "Western",
    "country": "Papua New Guinea",
    "woeid": "12515464",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "20",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "DAX",
    "lat": "31.1336",
    "lon": "107.419",
    "name": "Daxian Airport",
    "city": "Daxian",
    "state": "Sichuan",
    "country": "China",
    "woeid": "12512033",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "DAY",
    "lat": "39.8968",
    "lon": "-84.2207",
    "name": "James M Cox Dayton International Airport",
    "city": "Dayton",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12520347",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9500",
    "elev": "1009",
    "icao": "KDAY",
    "direct_flights": "27",
    "carriers": "29"
  },
  {
    "code": "DBA",
    "lat": "30.4419",
    "lon": "69.3597",
    "name": "Pakistan",
    "city": "Dalbandin",
    "state": "",
    "country": "Pakistan",
    "woeid": "23424922",
    "tz": "Asia\/Karachi",
    "phone": "0885 -210200",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1524",
    "elev": "2800",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DBO",
    "lat": "-32.2183",
    "lon": "148.574",
    "name": "Dubbo Airport",
    "city": "Dubbo",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510657",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5604",
    "elev": "935",
    "icao": "YSDU",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "DBQ",
    "lat": "42.4047",
    "lon": "-90.7037",
    "name": "Dubuque Regional Airport",
    "city": "Dubuque",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12519540",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6498",
    "elev": "1076",
    "icao": "KDBQ",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "DBV",
    "lat": "42.5631",
    "lon": "18.2692",
    "name": "Dubrovnik Airport",
    "city": "Dubrovnik",
    "state": "Dubrova\u010dko-neretvanska",
    "country": "Croatia",
    "woeid": "12513367",
    "tz": "Europe\/Belgrade",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport-dubrovnik.hr\/m\/index.php",
    "runway_length": "10824",
    "elev": "528",
    "icao": "LDDU",
    "direct_flights": "35",
    "carriers": "21"
  },
  {
    "code": "DCA",
    "lat": "38.849",
    "lon": "-77.0438",
    "name": "Washington National Airport",
    "city": "Arlington",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12522343",
    "tz": "America\/New_York",
    "phone": "703 417 80 00",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/metwashairports.com\/reagan",
    "runway_length": "6869",
    "elev": "16",
    "icao": "KDCA",
    "direct_flights": "77",
    "carriers": "42"
  },
  {
    "code": "DCF",
    "lat": "15.5333",
    "lon": "-61.4",
    "name": "Cane Field Airport",
    "city": "Portsmouth",
    "state": "Saint John",
    "country": "Dominica",
    "woeid": "12523094",
    "tz": "America\/Dominica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "14",
    "icao": "TDCF",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "DCM",
    "lat": "43.5538",
    "lon": "2.28923",
    "name": "Mazamet Airport",
    "city": "Labrugui\u00e8re",
    "state": "Midi-Pyrenees",
    "country": "France",
    "woeid": "12523095",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "738",
    "icao": "LFCK",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "DDC",
    "lat": "37.7573",
    "lon": "-99.9697",
    "name": "Dodge City Regional Airport",
    "city": "Dodge City",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12519504",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6899",
    "elev": "2594",
    "icao": "KDDC",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "DDG",
    "lat": "40.0242",
    "lon": "124.283",
    "name": "Dandong Airport",
    "city": "Dandong",
    "state": "Liaoning",
    "country": "China",
    "woeid": "12512026",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "DEA",
    "lat": "40.2483",
    "lon": "-74.0007",
    "name": "",
    "city": "Dera Ghazi Khan",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "2210903",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "DEC",
    "lat": "39.8335",
    "lon": "-88.8773",
    "name": "Decatur Airport",
    "city": "Decatur",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12519441",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6497",
    "elev": "679",
    "icao": "KDEC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DED",
    "lat": "30.3167",
    "lon": "78.0333",
    "name": "Dehra Dun",
    "city": "Dehra Dun",
    "state": "Uttaranchal",
    "country": "India",
    "woeid": "2294972",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "VIDN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DEF",
    "lat": "32.4317",
    "lon": "48.3989",
    "name": "Dezful Airport",
    "city": "Dezful",
    "state": "Khuzestan",
    "country": "Iran",
    "woeid": "12513715",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DEL",
    "lat": "28.5603",
    "lon": "77.1027",
    "name": "Indira Gandhi International Airport",
    "city": "New Delhi",
    "state": "Madhya Pradesh",
    "country": "India",
    "woeid": "12513599",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12500",
    "elev": "776",
    "icao": "VIDP",
    "direct_flights": "95",
    "carriers": "70"
  },
  {
    "code": "DEN",
    "lat": "39.8396",
    "lon": "-104.672",
    "name": "Denver International Airport",
    "city": "Denver",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12523052",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12000",
    "elev": "5333",
    "icao": "KDEN",
    "direct_flights": "162",
    "carriers": "57"
  },
  {
    "code": "DEZ",
    "lat": "35.2822",
    "lon": "40.1744",
    "name": "Deir Zzor Airport",
    "city": "Dayr az Zawr",
    "state": "Dayr az Zawr",
    "country": "Syria",
    "woeid": "12517699",
    "tz": "Asia\/Damascus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "700",
    "icao": "OSDZ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DFW",
    "lat": "32.9222",
    "lon": "-97.0409",
    "name": "Fort Worth International Airport",
    "city": "Dallas",
    "state": "Texas",
    "country": "United States",
    "woeid": "12519786",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11388",
    "elev": "603",
    "icao": "KDFW",
    "direct_flights": "179",
    "carriers": "62"
  },
  {
    "code": "DGA",
    "lat": "16.9757",
    "lon": "-88.235",
    "name": "Dangriga Airport",
    "city": "Dangriga",
    "state": "Stann Creek",
    "country": "Belize",
    "woeid": "12524030",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "DGE",
    "lat": "-32.5613",
    "lon": "149.618",
    "name": "Mudgee Aerodrome",
    "city": "Apple Tree Flat",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510746",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5705",
    "elev": "1545",
    "icao": "YMDG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DGM",
    "lat": "27.0667",
    "lon": "105.2",
    "name": "Dongguan",
    "city": "Dongguan",
    "state": "\u8d35\u5dde\u7701",
    "country": "China",
    "woeid": "12713509",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "DGO",
    "lat": "24.1283",
    "lon": "-104.527",
    "name": "Durango Airport",
    "city": "Durango",
    "state": "",
    "country": "Mexico",
    "woeid": "12514864",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8859",
    "elev": "6093",
    "icao": "MMDO",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "DGT",
    "lat": "9.3358",
    "lon": "123.301",
    "name": "Dumaguete Airport",
    "city": "Sibulan",
    "state": "Central Visayas",
    "country": "Philippines",
    "woeid": "12515613",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5610",
    "elev": "16",
    "icao": "RPVD",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "DHM",
    "lat": "32.3",
    "lon": "76.2667",
    "name": "Gaggal Airport",
    "city": "Dharamsala",
    "state": "Himachal Pradesh",
    "country": "India",
    "woeid": "2290603",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DHN",
    "lat": "31.3144",
    "lon": "-85.4487",
    "name": "Dothan Airport",
    "city": "Dothan",
    "state": "Alabama",
    "country": "United States",
    "woeid": "12519514",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8499",
    "elev": "401",
    "icao": "KDHN",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "DIB",
    "lat": "27.4839",
    "lon": "95.0208",
    "name": "Mohanbari Airport",
    "city": "Dikhari",
    "state": "Assam",
    "country": "India",
    "woeid": "12513633",
    "tz": "Asia\/Kolkata",
    "phone": "+91 (0) 373 2382523",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "361",
    "icao": "VEMN",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "DIE",
    "lat": "-12.3461",
    "lon": "49.2925",
    "name": "Antsiranana Arrachart Airport",
    "city": "Antseranana",
    "state": "Antsiranana",
    "country": "Madagascar",
    "woeid": "12514693",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "374",
    "icao": "FMNA",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "DIG",
    "lat": "26.1256",
    "lon": "109.308",
    "name": "Diqing",
    "city": "Diqing",
    "state": "\u8d35\u5dde\u7701",
    "country": "China",
    "woeid": "12713530",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DIK",
    "lat": "46.8035",
    "lon": "-102.802",
    "name": "Dickinson Municipal Airport",
    "city": "Dickinson",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12519490",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "2589",
    "icao": "KDIK",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "DIL",
    "lat": "-8.5533",
    "lon": "125.526",
    "name": "Comoro",
    "city": "Dili",
    "state": "Dili",
    "country": "Indonesia",
    "woeid": "12513462",
    "tz": "Asia\/Dili",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "7",
    "icao": "WPDL",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "DIN",
    "lat": "38.5686",
    "lon": "-81.0596",
    "name": "Dien Bien",
    "city": "Dien Bien Phu",
    "state": "Dien Bien",
    "country": "Vietnam",
    "woeid": "1252386",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DIR",
    "lat": "9.6244",
    "lon": "41.8536",
    "name": "Aba Tenna Dejazmatch Yilma Airport",
    "city": "Dire Dawa",
    "state": "Ogaden",
    "country": "Ethiopia",
    "woeid": "12512756",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "3812",
    "icao": "HADR",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "DIS",
    "lat": "-4.21667",
    "lon": "12.5",
    "name": "Loubomo",
    "city": "Loubomo",
    "state": "Niari",
    "country": "Congo",
    "woeid": "1280840",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1079",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DIU",
    "lat": "20.7134",
    "lon": "70.9217",
    "name": "Diu Airport",
    "city": "Diu",
    "state": "Daman and Diu",
    "country": "India",
    "woeid": "29230263",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "VA1P",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DIY",
    "lat": "37.8947",
    "lon": "40.2019",
    "name": "Diyarbakir Airport",
    "city": "Diyaribakir",
    "state": "Diyarbak\u0131r",
    "country": "Turkey",
    "woeid": "12517881",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11600",
    "elev": "2251",
    "icao": "LTCC",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "DJB",
    "lat": "-1.6369",
    "lon": "103.639",
    "name": "Sultan Taha Airport",
    "city": "Jambi",
    "state": "Jambi",
    "country": "Indonesia",
    "woeid": "12513510",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5413",
    "elev": "85",
    "icao": "WIPA",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "DJE",
    "lat": "33.8764",
    "lon": "10.7792",
    "name": "Zarzis Airport",
    "city": "Hawmat as Suq",
    "state": "Madanin",
    "country": "Tunisia",
    "woeid": "12517864",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10171",
    "elev": "16",
    "icao": "DTTJ",
    "direct_flights": "24",
    "carriers": "10"
  },
  {
    "code": "DJG",
    "lat": "24.2625",
    "lon": "9.4428",
    "name": "Tiska Airport",
    "city": "Illizi",
    "state": "Illizi",
    "country": "Algeria",
    "woeid": "12510354",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "3291",
    "icao": "DAAJ",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "DJJ",
    "lat": "-2.575",
    "lon": "140.514",
    "name": "Sentani Airport",
    "city": "Jayapura",
    "state": "Irian Jaya",
    "country": "Indonesia",
    "woeid": "12513505",
    "tz": "Asia\/Jayapura",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5741",
    "elev": "292",
    "icao": "WAJJ",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "DKR",
    "lat": "14.7417",
    "lon": "-17.4931",
    "name": "Dakar Yoff Airport",
    "city": "Ngor",
    "state": "Dakar",
    "country": "Senegal",
    "woeid": "12517505",
    "tz": "Africa\/Dakar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11450",
    "elev": "89",
    "icao": "GOOY",
    "direct_flights": "38",
    "carriers": "38"
  },
  {
    "code": "DLA",
    "lat": "4.0133",
    "lon": "9.7097",
    "name": "Douala Airport",
    "city": "Douala",
    "state": "Littoral",
    "country": "Cameroon",
    "woeid": "12512348",
    "tz": "Africa\/Douala",
    "phone": "+237 42 35 26",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9350",
    "elev": "33",
    "icao": "FKKD",
    "direct_flights": "25",
    "carriers": "28"
  },
  {
    "code": "DLC",
    "lat": "38.9617",
    "lon": "121.543",
    "name": "Chou Shui Tzu Airport",
    "city": "Dalian",
    "state": "Liaoning",
    "country": "China",
    "woeid": "23388254",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dlairport.com\/English\/home-v.htm",
    "runway_length": null,
    "elev": null,
    "icao": "KDLC",
    "direct_flights": "49",
    "carriers": "31"
  },
  {
    "code": "DLG",
    "lat": "59.0445",
    "lon": "-158.513",
    "name": "Dillingham Municipal Airport",
    "city": "Dillingham",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519494",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6404",
    "elev": "85",
    "icao": "PADL",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "DLH",
    "lat": "46.838",
    "lon": "-92.1781",
    "name": "Duluth International Airport",
    "city": "Duluth",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12519545",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10152",
    "elev": "1430",
    "icao": "KDLH",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "DLI",
    "lat": "11.7501",
    "lon": "108.377",
    "name": "Lien Khuong Airport",
    "city": "Lam Dong",
    "state": "Lam Dong",
    "country": "Vietnam",
    "woeid": "12522924",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4856",
    "elev": "3156",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DLM",
    "lat": "36.7125",
    "lon": "28.7922",
    "name": "Dalaman Airport",
    "city": "Ortaca",
    "state": "Mu\u011fla",
    "country": "Turkey",
    "woeid": "12517880",
    "tz": "Europe\/Istanbul",
    "phone": "+90 252 792 55",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.atmairport.aero\/",
    "runway_length": "9842",
    "elev": "20",
    "icao": "LTBS",
    "direct_flights": "20",
    "carriers": "11"
  },
  {
    "code": "DLU",
    "lat": "25.5667",
    "lon": "100.233",
    "name": "Dali",
    "city": "Dali City",
    "state": "Yunnan",
    "country": "China",
    "woeid": "2160550",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DLY",
    "lat": "-16.6619",
    "lon": "168.38",
    "name": "Vanuatu",
    "city": "Dillons Bay",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2165",
    "elev": "538",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "DLZ",
    "lat": "43.9667",
    "lon": "104.683",
    "name": "Dalanzadgad",
    "city": "Dalanzadgad",
    "state": "Omnogov",
    "country": "Mongolia",
    "woeid": "2266117",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KDLZ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DMD",
    "lat": "-18",
    "lon": "138.833",
    "name": "Doomadgee",
    "city": "Doomadgee",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12708461",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5630",
    "elev": "153",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "DME",
    "lat": "55.4025",
    "lon": "37.9136",
    "name": "Domodedovo Airport",
    "city": "Podol'sk",
    "state": "Moskovskaya Oblast",
    "country": "Russia",
    "woeid": "12515946",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.domodedovo.ru",
    "runway_length": null,
    "elev": "512",
    "icao": "UUDD",
    "direct_flights": "165",
    "carriers": "71"
  },
  {
    "code": "DMK",
    "lat": "13.9181",
    "lon": "100.59",
    "name": "Don Mueang",
    "city": "Don Muang",
    "state": "Bangkok",
    "country": "Thailand",
    "woeid": "28341403",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "VTBD",
    "direct_flights": "12",
    "carriers": "4"
  },
  {
    "code": "DMM",
    "lat": "26.4722",
    "lon": "49.8058",
    "name": "King Fahd International Airport",
    "city": "Khuwaylidiyah",
    "state": "Ash Sharqiyah",
    "country": "Saudi Arabia",
    "woeid": "12517346",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "72",
    "icao": "OEDF",
    "direct_flights": "45",
    "carriers": "29"
  },
  {
    "code": "DMU",
    "lat": "25.8839",
    "lon": "93.7736",
    "name": "Dimapur Airport",
    "city": "Dimapur",
    "state": "Nagaland",
    "country": "India",
    "woeid": "12513581",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5600",
    "elev": "485",
    "icao": "VEMR",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "DND",
    "lat": "56.4538",
    "lon": "-3.01347",
    "name": "Dundee Airport",
    "city": "Dundee",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22463072",
    "tz": "Europe\/London",
    "phone": "01382 662200",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3608",
    "elev": "13",
    "icao": "EGPN",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "DNH",
    "lat": "40.2",
    "lon": "94.6833",
    "name": "Dunhuang",
    "city": "Dunhuang",
    "state": "Gansu",
    "country": "China",
    "woeid": "2145159",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "DNK",
    "lat": "48.368",
    "lon": "35.0942",
    "name": "Voloskoye Airport",
    "city": "Dnipropetrovs'k",
    "state": "Dnipropetrovs\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518475",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UKDD",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "DNR",
    "lat": "48.589",
    "lon": "-2.07757",
    "name": "Pleurtuit Airport",
    "city": "Pleurtuit",
    "state": "Brittany",
    "country": "France",
    "woeid": "12523809",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "217",
    "icao": "LFRD",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "DNZ",
    "lat": "37.7667",
    "lon": "29.1",
    "name": "Cardak Airport",
    "city": "Denizli",
    "state": "Denizli",
    "country": "Turkey",
    "woeid": "12523098",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "2792",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DOG",
    "lat": "19.1833",
    "lon": "30.4833",
    "name": "Dongola Airport",
    "city": "Dongola",
    "state": "Ash Shamaliyah",
    "country": "Sudan",
    "woeid": "12523099",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6726",
    "elev": "902",
    "icao": "HSDN",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DOH",
    "lat": "25.2592",
    "lon": "51.5658",
    "name": "Doha International Airport",
    "city": "Doha",
    "state": "Doha",
    "country": "Qatar",
    "woeid": "12515487",
    "tz": "Asia\/Qatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "15000",
    "elev": "35",
    "icao": "OTBD",
    "direct_flights": "87",
    "carriers": "43"
  },
  {
    "code": "DOK",
    "lat": "48.072",
    "lon": "37.7426",
    "name": "Donetsk Airport",
    "city": "Donets'k",
    "state": "Donets\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12523100",
    "tz": "Europe\/Zaporozhye",
    "phone": "(+380 622) 515322",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/kbp.kiev.ua\/airports\/search\/DONETSK.html",
    "runway_length": null,
    "elev": "234",
    "icao": "UKCC",
    "direct_flights": "17",
    "carriers": "12"
  },
  {
    "code": "DOM",
    "lat": "15.5431",
    "lon": "-61.3092",
    "name": "Melville Hall Airport",
    "city": "Marigot",
    "state": "Saint Andrew",
    "country": "Dominica",
    "woeid": "12512609",
    "tz": "America\/Dominica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "80",
    "icao": "TDPD",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "DOP",
    "lat": "29.217",
    "lon": "82.7517",
    "name": "Nepal",
    "city": "Dolpa",
    "state": "Midwest",
    "country": "Nepal",
    "woeid": "23424911",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1500",
    "elev": "8200",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DOU",
    "lat": "-22.1978",
    "lon": "-54.9225",
    "name": "Dourados Airport",
    "city": "Dourados",
    "state": "Mato Grosso do Sul",
    "country": "Brazil",
    "woeid": "12511113",
    "tz": "America\/Campo_Grande",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4321",
    "elev": "1509",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DOY",
    "lat": "40.0294",
    "lon": "-120.107",
    "name": "",
    "city": "Dongying",
    "state": "Shandong",
    "country": "China",
    "woeid": "26198082",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DPL",
    "lat": "8.6043",
    "lon": "123.348",
    "name": "Dipolog Airport",
    "city": "Polanco",
    "state": "Zamboanga Peninsula",
    "country": "Philippines",
    "woeid": "12515612",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5085",
    "elev": "12",
    "icao": "RPMG",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "DPO",
    "lat": "-41.1726",
    "lon": "146.425",
    "name": "Devonport Airport",
    "city": "Devonport",
    "state": "Tasmania",
    "country": "Australia",
    "woeid": "12510655",
    "tz": "Australia\/Hobart",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6030",
    "elev": "22",
    "icao": "YDPO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DPS",
    "lat": "-8.7486",
    "lon": "115.165",
    "name": "Bali International Airport",
    "city": "Denpasar",
    "state": "Bali",
    "country": "Indonesia",
    "woeid": "12513453",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "14",
    "icao": "WADD",
    "direct_flights": "30",
    "carriers": "31"
  },
  {
    "code": "DRG",
    "lat": "66.0833",
    "lon": "-162.767",
    "name": "Deering Airport",
    "city": "Deering",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524659",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "8",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "DRO",
    "lat": "37.1595",
    "lon": "-107.751",
    "name": "Durango la Plata County Airport",
    "city": "Durango",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12519550",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9200",
    "elev": "6685",
    "icao": "KDRO",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "DRS",
    "lat": "51.1688",
    "lon": "13.7899",
    "name": "Ottendorf Okrilla Highway Strip Airport",
    "city": "Ottendorf-Okrilla",
    "state": "Saxony",
    "country": "Germany",
    "woeid": "12513205",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dresden-airport.de",
    "runway_length": "8229",
    "elev": "755",
    "icao": "EDDC",
    "direct_flights": "26",
    "carriers": "22"
  },
  {
    "code": "DRT",
    "lat": "29.3702",
    "lon": "-100.918",
    "name": "Del Rio International Airport",
    "city": "Del Rio",
    "state": "Texas",
    "country": "United States",
    "woeid": "12519452",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5105",
    "elev": "999",
    "icao": "KDRT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DRW",
    "lat": "-12.4159",
    "lon": "130.874",
    "name": "Darwin International Airport",
    "city": "Darwin",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "12510651",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10906",
    "elev": "102",
    "icao": "YPDN",
    "direct_flights": "19",
    "carriers": "12"
  },
  {
    "code": "DSA",
    "lat": "53.3954",
    "lon": "-1.38243",
    "name": "Sheffield Airport",
    "city": "Doncaster Sheffield",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22656550",
    "tz": "Europe\/London",
    "phone": "+44 8708 33 22 10",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.robinhoodairport.com",
    "runway_length": "9485",
    "elev": null,
    "icao": "EGCN",
    "direct_flights": "20",
    "carriers": "4"
  },
  {
    "code": "DSM",
    "lat": "41.5328",
    "lon": "-93.6481",
    "name": "Des Moines International Airport",
    "city": "Des Moines",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12519473",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dsmairport.com",
    "runway_length": "9001",
    "elev": "957",
    "icao": "KDSM",
    "direct_flights": "20",
    "carriers": "24"
  },
  {
    "code": "DSN",
    "lat": "39.8295",
    "lon": "109.976",
    "name": "Dongsheng Airport",
    "city": "Dongsheng",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "12512040",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "DTM",
    "lat": "51.5123",
    "lon": "7.60451",
    "name": "Dortmund Airport",
    "city": "Dortmund",
    "state": "North Rhine-Westphalia",
    "country": "Germany",
    "woeid": "23046924",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dortmund-airport.com",
    "runway_length": "3455",
    "elev": "456",
    "icao": "EDLW",
    "direct_flights": "41",
    "carriers": "11"
  },
  {
    "code": "DTW",
    "lat": "42.2327",
    "lon": "-83.3412",
    "name": "Detroit Metropolitan Wayne County Airport",
    "city": "Detroit",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12519481",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.metroairport.com\/",
    "runway_length": "10501",
    "elev": "639",
    "icao": "KDTW",
    "direct_flights": "161",
    "carriers": "45"
  },
  {
    "code": "DUB",
    "lat": "53.4272",
    "lon": "-6.24418",
    "name": "Dublin Airport",
    "city": "Cloghran",
    "state": "",
    "country": "Ireland",
    "woeid": "23388266",
    "tz": "Europe\/Dublin",
    "phone": "+353 1 8141111",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dub.aero",
    "runway_length": "7499",
    "elev": "222",
    "icao": "EIDW",
    "direct_flights": "167",
    "carriers": "54"
  },
  {
    "code": "DUD",
    "lat": "-45.9287",
    "lon": "170.199",
    "name": "Dunedin Airport",
    "city": "Outram",
    "state": "Otago",
    "country": "New Zealand",
    "woeid": "12515153",
    "tz": "Pacific\/Auckland",
    "phone": "+64 3 486 2879",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dnairport.co.nz",
    "runway_length": "6266",
    "elev": "4",
    "icao": "NZDN",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "DUE",
    "lat": "-7.3958",
    "lon": "20.8236",
    "name": "Dundo Airport",
    "city": "Dundo",
    "state": "Lunda Norte",
    "country": "Angola",
    "woeid": "12510434",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6233",
    "elev": "2451",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DUJ",
    "lat": "41.1833",
    "lon": "-78.8953",
    "name": "Du Bois Jefferson County Airport",
    "city": "Reynoldsville",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12519536",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5504",
    "elev": "1817",
    "icao": "KDUJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DUQ",
    "lat": "48.7562",
    "lon": "-123.711",
    "name": "Duncan-Quamichan Lake Airport",
    "city": "Duncan",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524040",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "1600",
    "elev": "310",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DUR",
    "lat": "-29.9736",
    "lon": "30.9472",
    "name": "Louis Botha Airport",
    "city": "Durban",
    "state": "Kwazulu Natal",
    "country": "South Africa",
    "woeid": "12517440",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8015",
    "elev": "25",
    "icao": "FADN",
    "direct_flights": "10",
    "carriers": "16"
  },
  {
    "code": "DUS",
    "lat": "51.2794",
    "lon": "6.76481",
    "name": "Dusseldorf International Airport",
    "city": "Dusseldorf",
    "state": "North Rhine-Westphalia",
    "country": "Germany",
    "woeid": "23024911",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.duesseldorf-international.de\/",
    "runway_length": "9842",
    "elev": "147",
    "icao": "EDDL",
    "direct_flights": "167",
    "carriers": "74"
  },
  {
    "code": "DUT",
    "lat": "53.896",
    "lon": "-166.535",
    "name": "Unalaska Airport",
    "city": "Unalaska",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12522244",
    "tz": "America\/Adak",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "12",
    "icao": "PADU",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "DVL",
    "lat": "48.108",
    "lon": "-98.9037",
    "name": "Devils Lake Municipal Airport",
    "city": "Devils Lake",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12519482",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5509",
    "elev": "1455",
    "icao": "KDVL",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "DVO",
    "lat": "7.1292",
    "lon": "125.647",
    "name": "Francisco Bangoy International Airport",
    "city": "Davao City",
    "state": "Davao Region",
    "country": "Philippines",
    "woeid": "12515616",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "88",
    "icao": "KDVO",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "DWB",
    "lat": "-16.1",
    "lon": "45.3667",
    "name": "Soalala",
    "city": "Soalala",
    "state": "Mahajanga",
    "country": "Madagascar",
    "woeid": "1364336",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4101",
    "elev": "141",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "DWD",
    "lat": "23.9176",
    "lon": "45.0811",
    "name": "Saudi Arabia",
    "city": "Dawadmi",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "23424938",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "DXB",
    "lat": "25.2509",
    "lon": "55.3629",
    "name": "Dubai International Airport",
    "city": "Dubai",
    "state": "Dubai",
    "country": "United Arab Emirates",
    "woeid": "23388362",
    "tz": "Asia\/Dubai",
    "phone": "+971 4 216 2525",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.dubaiairport.com",
    "runway_length": "13123",
    "elev": "34",
    "icao": "OMDB",
    "direct_flights": "170",
    "carriers": "126"
  },
  {
    "code": "DYG",
    "lat": "29.1333",
    "lon": "110.483",
    "name": "Dayong",
    "city": "Dayong",
    "state": "Hunan",
    "country": "China",
    "woeid": "2142725",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "14",
    "carriers": "8"
  },
  {
    "code": "DYR",
    "lat": "64.7358",
    "lon": "177.739",
    "name": "Anadyr-Ugolnyye Kopi Airport",
    "city": "Anadyr'",
    "state": "Chukotskiy Avtonomnyy Okrug",
    "country": "Russia",
    "woeid": "12515705",
    "tz": "Asia\/Kamchatka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "DYU",
    "lat": "38.5785",
    "lon": "68.7773",
    "name": "Tajikistan",
    "city": "Dushanbe",
    "state": "Karategin",
    "country": "Tajikistan",
    "woeid": "23424961",
    "tz": "Asia\/Dushanbe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10170",
    "elev": "2569",
    "icao": "UTDD",
    "direct_flights": "21",
    "carriers": "16"
  },
  {
    "code": "DZA",
    "lat": "-12.8",
    "lon": "45.2833",
    "name": "Dzaoudzi Pamanzi Airport",
    "city": "Mamoudzou",
    "state": "Mamoudzou",
    "country": "Mayotte",
    "woeid": "12514730",
    "tz": "Indian\/Mayotte",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4429",
    "elev": "26",
    "icao": "FMCZ",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "DZN",
    "lat": "47.7131",
    "lon": "67.7065",
    "name": "Dzhezkazgan South Airport",
    "city": "Zhezqazghan",
    "state": "Qaraghandy",
    "country": "Kazakhstan",
    "woeid": "12514327",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UAKD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EAA",
    "lat": "64.7667",
    "lon": "-141.15",
    "name": "Eagle Airport",
    "city": "Tok",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524661",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3600",
    "elev": "880",
    "icao": "PAEA",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "EAE",
    "lat": "-17.0686",
    "lon": "168.381",
    "name": "Vanuatu",
    "city": "Emae",
    "state": "Shefa",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "7",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "EAL",
    "lat": "9.08333",
    "lon": "167.333",
    "name": "Elenak",
    "city": "Kwajalein Atoll",
    "state": "Kwajalein",
    "country": "Marshall Islands",
    "woeid": "24549846",
    "tz": "Pacific\/Kwajalein",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EAM",
    "lat": "17.6117",
    "lon": "44.4297",
    "name": "Nejran Airport",
    "city": "Najran",
    "state": "Najran",
    "country": "Saudi Arabia",
    "woeid": "12517355",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "3982",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "EAR",
    "lat": "40.7236",
    "lon": "-99.0151",
    "name": "Kearney Municipal Airport",
    "city": "Kearney",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12520429",
    "tz": "America\/Chicago",
    "phone": "308-234-2318",
    "type": "Airports",
    "email": "bjohnson@flykearney.com",
    "url": "",
    "runway_length": "7092",
    "elev": "2130",
    "icao": "KEAR",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "EAS",
    "lat": "43.3557",
    "lon": "-1.79208",
    "name": "San Sebastian Airport",
    "city": "Hondarribia",
    "state": "Basque Country",
    "country": "Spain",
    "woeid": "12517567",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5755",
    "elev": "16",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "EAT",
    "lat": "47.4048",
    "lon": "-120.21",
    "name": "Pangborn Memorial Airport",
    "city": "East Wenatchee",
    "state": "Washington",
    "country": "United States",
    "woeid": "12521295",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5499",
    "elev": "1245",
    "icao": "KEAT",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "EAU",
    "lat": "44.8625",
    "lon": "-91.4826",
    "name": "Eau Claire County Airport",
    "city": "Eau Claire",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12519585",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7300",
    "elev": "907",
    "icao": "KEAU",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "EBA",
    "lat": "42.7629",
    "lon": "10.2383",
    "name": "Marina de Campo Airport",
    "city": "Pianosa",
    "state": "Tuscany",
    "country": "Italy",
    "woeid": "12523910",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3855",
    "elev": "26",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "EBB",
    "lat": "0.0417",
    "lon": "32.4381",
    "name": "Entebbe International Airport",
    "city": "Entebbe",
    "state": "Wakiso",
    "country": "Uganda",
    "woeid": "12518024",
    "tz": "Africa\/Kampala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.caa.co.ug\/entebbe.php",
    "runway_length": "12000",
    "elev": "3782",
    "icao": "HUEN",
    "direct_flights": "17",
    "carriers": "20"
  },
  {
    "code": "EBD",
    "lat": "13.1597",
    "lon": "30.2342",
    "name": "El Obeid Airport",
    "city": "Al Ubayyid",
    "state": "Shamal Kurdufan",
    "country": "Sudan",
    "woeid": "12517598",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8390",
    "elev": "1883",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "EBJ",
    "lat": "55.5247",
    "lon": "8.55388",
    "name": "Esbjerg Airport",
    "city": "Esbjerg",
    "state": "Syddanmark",
    "country": "Denmark",
    "woeid": "12512590",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.esbjerg-lufthavn.dk",
    "runway_length": "8530",
    "elev": "97",
    "icao": "EKEB",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "EBL",
    "lat": "36.1797",
    "lon": "44.016",
    "name": "Erbil",
    "city": "Erbil",
    "state": "Arbil",
    "country": "Iraq",
    "woeid": "1979432",
    "tz": "Asia\/Baghdad",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ORER",
    "direct_flights": "9",
    "carriers": "6"
  },
  {
    "code": "ECN",
    "lat": "35.1597",
    "lon": "33.4897",
    "name": "Ercan Airport",
    "city": "Nicosia",
    "state": "Cyprus, TRNC",
    "country": "Cyprus",
    "woeid": "12512497",
    "tz": "Asia\/Nicosia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9050",
    "elev": "402",
    "icao": "LCEN",
    "direct_flights": "10",
    "carriers": "3"
  },
  {
    "code": "EDA",
    "lat": "55.9503",
    "lon": "-133.656",
    "name": "Edna Bay Seaplane Base",
    "city": "Ketchikan",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524642",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EDI",
    "lat": "55.9486",
    "lon": "-3.36431",
    "name": "Edinburgh International Airport",
    "city": "Edinburgh",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22463734",
    "tz": "Europe\/London",
    "phone": "+44 (0) 870 040 0007",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.edinburghairport.com",
    "runway_length": "8400",
    "elev": "135",
    "icao": "EGPH",
    "direct_flights": "87",
    "carriers": "49"
  },
  {
    "code": "EDL",
    "lat": "0.5375",
    "lon": "35.2783",
    "name": "Eldoret Airport",
    "city": "Nakuru",
    "state": "Rift Valley",
    "country": "Kenya",
    "woeid": "12514065",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4396",
    "elev": "7050",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "EDO",
    "lat": "39.599",
    "lon": "27.0221",
    "name": "Edremit-Korfez Airport",
    "city": "Edremit",
    "state": "Bal\u0131kesir",
    "country": "Turkey",
    "woeid": "12523443",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EDR",
    "lat": "-14.9",
    "lon": "141.617",
    "name": "Edward River",
    "city": "Edward River",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12708477",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3820",
    "elev": "50",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "EEK",
    "lat": "60.2167",
    "lon": "-162.017",
    "name": "Eek Airport",
    "city": "Eek",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524663",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1300",
    "elev": "40",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "EFL",
    "lat": "38.1181",
    "lon": "20.5003",
    "name": "Kefallinia Airport",
    "city": "Dhilianata",
    "state": "Nisia Ionioy",
    "country": "Greece",
    "woeid": "12513297",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7973",
    "elev": "60",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "EGC",
    "lat": "44.8227",
    "lon": "0.51524",
    "name": "Bergerac-Roumaniere Airport",
    "city": "Bergerac",
    "state": "Aquitaine",
    "country": "France",
    "woeid": "12512960",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "6742",
    "elev": "167",
    "icao": "LFBE",
    "direct_flights": "12",
    "carriers": "3"
  },
  {
    "code": "EGE",
    "lat": "39.6395",
    "lon": "-106.912",
    "name": "Eagle County Regional Airport",
    "city": "Gypsum",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12519558",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "6538",
    "icao": "KEGE",
    "direct_flights": "12",
    "carriers": "9"
  },
  {
    "code": "EGN",
    "lat": "13.4833",
    "lon": "22.45",
    "name": "",
    "city": "Geneina",
    "state": "Gharb Darfur",
    "country": "Sudan",
    "woeid": "1432552",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5250",
    "elev": "2650",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "EGO",
    "lat": "50.6406",
    "lon": "36.5853",
    "name": "Belgorod North Airport",
    "city": "Belgorod",
    "state": "Belgorodskaya Oblast",
    "country": "Russia",
    "woeid": "12515790",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "EGS",
    "lat": "65.2694",
    "lon": "-14.3549",
    "name": "Egilsstadir Airport",
    "city": "Egilsstadir",
    "state": "Sudur-Mulasysla",
    "country": "Iceland",
    "woeid": "12523881",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5334",
    "elev": "81",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EGV",
    "lat": "45.932",
    "lon": "-89.2599",
    "name": "Eagle River Union Airport",
    "city": "Eagle River",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12519564",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3600",
    "elev": "1642",
    "icao": "KEGV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "EGX",
    "lat": "58.2101",
    "lon": "-157.366",
    "name": "Egegik Airport",
    "city": "Egegik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524588",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2100",
    "elev": "100",
    "icao": "PAII",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "EIB",
    "lat": "50.9853",
    "lon": "10.485",
    "name": "Haina Airport",
    "city": "Eisenach",
    "state": "Rhineland-Palatinate",
    "country": "Germany",
    "woeid": "12513118",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "EIE",
    "lat": "58.4395",
    "lon": "92.1852",
    "name": "Russia",
    "city": "Eniseysk",
    "state": "Krasnoyarskiy Kray",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EIK",
    "lat": "59.298",
    "lon": "10.4319",
    "name": "",
    "city": "Tonsberg",
    "state": "Vestfold Fylke",
    "country": "Norway",
    "woeid": "22659603",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EIN",
    "lat": "51.4463",
    "lon": "5.39212",
    "name": "Eindhoven Airport",
    "city": "Eindhoven",
    "state": "North Brabant",
    "country": "Netherlands",
    "woeid": "12515087",
    "tz": "Europe\/Amsterdam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.eindhovenairport.com\/",
    "runway_length": "9846",
    "elev": "74",
    "icao": "EHEH",
    "direct_flights": "21",
    "carriers": "9"
  },
  {
    "code": "EIS",
    "lat": "18.4439",
    "lon": "-64.5428",
    "name": "Beef Island-Roadtown Airport",
    "city": "Beef Island",
    "state": "",
    "country": "British Virgin Islands",
    "woeid": "12522860",
    "tz": "America\/Tortola",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "TUPJ",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "EJA",
    "lat": "7.025",
    "lon": "-73.8092",
    "name": "Yariguies Airport",
    "city": "Barrancabermeja",
    "state": "Santander",
    "country": "Colombia",
    "woeid": "12512424",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5904",
    "elev": "412",
    "icao": "SKEJ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EJH",
    "lat": "26.2003",
    "lon": "36.4758",
    "name": "Wejh Airport",
    "city": "Wedjh",
    "state": "Tabuk",
    "country": "Saudi Arabia",
    "woeid": "12517378",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "66",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "EKO",
    "lat": "40.829",
    "lon": "-115.78",
    "name": "Elko Municipal Airport-J C Harris Field",
    "city": "Elko",
    "state": "Nevada",
    "country": "United States",
    "woeid": "12519626",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7211",
    "elev": "5135",
    "icao": "KEKO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EKS",
    "lat": "45.2681",
    "lon": "-111.65",
    "name": "Ennis Big Sky Airport",
    "city": "",
    "state": "",
    "country": "",
    "woeid": "29387875",
    "tz": "America\/Boise",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "5383",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ELC",
    "lat": "-11.9149",
    "lon": "135.803",
    "name": "Elcho Island Airport",
    "city": "Darwin",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "12510663",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4850",
    "elev": "97",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ELF",
    "lat": "35.0339",
    "lon": "-83.7405",
    "name": "",
    "city": "El Fasher",
    "state": "Shamal Darfur",
    "country": "Sudan",
    "woeid": "1430323",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6053",
    "elev": "2404",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ELG",
    "lat": "30.5686",
    "lon": "2.8653",
    "name": "El Golea Airport",
    "city": "El Golea",
    "state": "Ghardaia",
    "country": "Algeria",
    "woeid": "12510324",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1312",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ELH",
    "lat": "25.4747",
    "lon": "-76.685",
    "name": "North Eleuthera Airport",
    "city": "The Bluff",
    "state": "North Eleuthera",
    "country": "Bahamas",
    "woeid": "12510876",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "5",
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "ELI",
    "lat": "64.6167",
    "lon": "-162.267",
    "name": "Elim Airport",
    "city": "Elim",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524667",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "200",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "ELM",
    "lat": "42.1633",
    "lon": "-76.8973",
    "name": "Elmira Corning Regional Airport",
    "city": "Horseheads",
    "state": "New York",
    "country": "United States",
    "woeid": "12519635",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6999",
    "elev": "955",
    "icao": "KELM",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "ELP",
    "lat": "31.7982",
    "lon": "-106.393",
    "name": "El Paso International Airport",
    "city": "El Paso",
    "state": "Texas",
    "country": "United States",
    "woeid": "12519608",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11012",
    "elev": "3956",
    "icao": "KELP",
    "direct_flights": "20",
    "carriers": "23"
  },
  {
    "code": "ELQ",
    "lat": "26.3025",
    "lon": "43.7703",
    "name": "Gassim Airport",
    "city": "Al Qara'",
    "state": "Al Qasim",
    "country": "Saudi Arabia",
    "woeid": "12517334",
    "tz": "Asia\/Riyadh",
    "phone": "+966(6)3800001",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "2126",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "ELS",
    "lat": "-33.0361",
    "lon": "27.8239",
    "name": "Ben Schoeman Airport",
    "city": "East London",
    "state": "Eastern Cape",
    "country": "South Africa",
    "woeid": "12517396",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6350",
    "elev": "431",
    "icao": "FAEL",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "ELU",
    "lat": "33.5122",
    "lon": "6.7842",
    "name": "Guemar Airport",
    "city": "El Oued",
    "state": "El Oued",
    "country": "Algeria",
    "woeid": "12510328",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "208",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ELV",
    "lat": "58.1833",
    "lon": "-136.317",
    "name": "Elfin Cove Airport",
    "city": "Elfin Cove",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524668",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ELY",
    "lat": "39.3043",
    "lon": "-114.853",
    "name": "Yelland Field Airport",
    "city": "Ely",
    "state": "Nevada",
    "country": "United States",
    "woeid": "12519640",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5998",
    "elev": "6255",
    "icao": "KELY",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "EMA",
    "lat": "52.8258",
    "lon": "-1.33094",
    "name": "East Midlands International Airport",
    "city": "Derby",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22462097",
    "tz": "Europe\/London",
    "phone": "0871 919 9000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.nottinghamema.com",
    "runway_length": "9400",
    "elev": "310",
    "icao": "EGNX",
    "direct_flights": "67",
    "carriers": "16"
  },
  {
    "code": "EMD",
    "lat": "-23.5704",
    "lon": "148.178",
    "name": "Emerald Aerodrome",
    "city": "Emerald",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510665",
    "tz": "Australia\/Melbourne",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "624",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EME",
    "lat": "53.3893",
    "lon": "7.2284",
    "name": "Emden Airport",
    "city": "Emden",
    "state": "Lower Saxony",
    "country": "Germany",
    "woeid": "22192358",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EMK",
    "lat": "62.785",
    "lon": "-164.491",
    "name": "Emmonak Airport",
    "city": "Alakanuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519644",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "10",
    "icao": "",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "ENA",
    "lat": "60.5651",
    "lon": "-151.244",
    "name": "Kenai Municipal Airport",
    "city": "Kenai",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520437",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7575",
    "elev": "92",
    "icao": "PAEN",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "ENE",
    "lat": "-8.8",
    "lon": "121.6",
    "name": "Ende Airport",
    "city": "Ende",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12523103",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2460",
    "elev": "49",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ENF",
    "lat": "68.3606",
    "lon": "23.4205",
    "name": "Enontekio Airport",
    "city": "Lepp\u00e4j\u00e4rvi",
    "state": "Lapland",
    "country": "Finland",
    "woeid": "12512763",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "1004",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ENH",
    "lat": "30.2875",
    "lon": "109.472",
    "name": "Enshi Airport",
    "city": "Enshi",
    "state": "Hubei",
    "country": "China",
    "woeid": "12512043",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "ENI",
    "lat": "34.1167",
    "lon": "-89.9395",
    "name": "",
    "city": "El Nido",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "1199158",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1640",
    "elev": "12",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ENS",
    "lat": "52.2678",
    "lon": "6.89152",
    "name": "Enschede Twente",
    "city": "Enschede",
    "state": "Overijssel",
    "country": "Netherlands",
    "woeid": "12515094",
    "tz": "Europe\/Amsterdam",
    "phone": "+31(0)53-4862222",
    "type": "Other Airport",
    "email": "",
    "url": "http:\/\/www.enschede-airport.nl",
    "runway_length": "9801",
    "elev": "114",
    "icao": "EHTW",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ENU",
    "lat": "6.4756",
    "lon": "7.5661",
    "name": "Enugu Airport",
    "city": "Enugu",
    "state": "Enugu",
    "country": "Nigeria",
    "woeid": "12515063",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "466",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ENW",
    "lat": "42.5934",
    "lon": "-87.9299",
    "name": "Kenosha Regional Airport",
    "city": "Kenosha",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12520443",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "729",
    "icao": "KENW",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ENY",
    "lat": "36.5965",
    "lon": "109.475",
    "name": "Yan'an",
    "city": "Yan'an",
    "state": "Shaanxi",
    "country": "China",
    "woeid": "2156695",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "EOH",
    "lat": "6.2231",
    "lon": "-75.5886",
    "name": "Olaya Herrera Airport",
    "city": "Medellin",
    "state": "Antioquia",
    "country": "Colombia",
    "woeid": "12512399",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeropuertoolayaherrera.gov.co",
    "runway_length": "5900",
    "elev": "4940",
    "icao": "SKMD",
    "direct_flights": "13",
    "carriers": "3"
  },
  {
    "code": "EOZ",
    "lat": "7.0631",
    "lon": "-69.4942",
    "name": "Elorza Airport",
    "city": "Elorza",
    "state": "Apure",
    "country": "Venezuela",
    "woeid": "12522783",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "295",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EPR",
    "lat": "-33.6819",
    "lon": "121.827",
    "name": "Esperance Aerodrome",
    "city": "Gibson",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510666",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "470",
    "icao": "YESP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EQS",
    "lat": "-42.9264",
    "lon": "-71.1425",
    "name": "Esquel Airport",
    "city": "Esquel",
    "state": "Chubut",
    "country": "Argentina",
    "woeid": "12510495",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7857",
    "elev": "2582",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ERC",
    "lat": "39.7117",
    "lon": "39.5169",
    "name": "Erzincan Airport",
    "city": "Erzincan",
    "state": "Erzincan",
    "country": "Turkey",
    "woeid": "12517887",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5800",
    "elev": "3792",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ERF",
    "lat": "50.9754",
    "lon": "10.9626",
    "name": "Erfurt Airport",
    "city": "Erfurt",
    "state": "Thuringia",
    "country": "Germany",
    "woeid": "22193194",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "1033",
    "icao": "EDDE",
    "direct_flights": "15",
    "carriers": "5"
  },
  {
    "code": "ERH",
    "lat": "31.9525",
    "lon": "-4.4067",
    "name": "Er Rachidia Airport",
    "city": "Errachidia",
    "state": "Er Rachidia",
    "country": "Morocco",
    "woeid": "12514786",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "3392",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ERI",
    "lat": "42.0829",
    "lon": "-80.1824",
    "name": "Erie International Airport",
    "city": "Erie",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12519655",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "732",
    "icao": "KERI",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "ERM",
    "lat": "-27.6608",
    "lon": "-52.2756",
    "name": "Comandante Kraemer Airport",
    "city": "Erechim",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511118",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4199",
    "elev": "2498",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ERS",
    "lat": "-22.6125",
    "lon": "17.0819",
    "name": "Eros Airport",
    "city": "Windhoek",
    "state": "Khomas",
    "country": "Namibia",
    "woeid": "12522975",
    "tz": "Africa\/Windhoek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "5584",
    "icao": "FYWE",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ERZ",
    "lat": "39.9567",
    "lon": "41.1697",
    "name": "Erzurum Airport",
    "city": "Erzurum",
    "state": "Erzurum",
    "country": "Turkey",
    "woeid": "12517888",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12500",
    "elev": "5761",
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "ESB",
    "lat": "40.1289",
    "lon": "32.9961",
    "name": "Esenboga Airport",
    "city": "\u00c7ubuk",
    "state": "Ankara",
    "country": "Turkey",
    "woeid": "12517889",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12310",
    "elev": "3125",
    "icao": "LTAC",
    "direct_flights": "50",
    "carriers": "15"
  },
  {
    "code": "ESC",
    "lat": "45.718",
    "lon": "-87.0942",
    "name": "Delta County Airport",
    "city": "Escanaba",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12519461",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6498",
    "elev": "609",
    "icao": "KESC",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ESD",
    "lat": "48.7073",
    "lon": "-122.909",
    "name": "Orcas Island Airport",
    "city": "Eastsound",
    "state": "Washington",
    "country": "United States",
    "woeid": "12523452",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2900",
    "elev": "25",
    "icao": "KORS",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ESM",
    "lat": "0.9717",
    "lon": "-79.6219",
    "name": "General Rivadeneira Airport",
    "city": "Tachina",
    "state": "Esmeraldas",
    "country": "Ecuador",
    "woeid": "12512631",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7872",
    "elev": "23",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "ESR",
    "lat": "-26.3083",
    "lon": "-69.7517",
    "name": "El Salvador Bajo Airport",
    "city": "Diego de Almagro",
    "state": "Atacama",
    "country": "Chile",
    "woeid": "12512321",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "5240",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ESS",
    "lat": "51.4078",
    "lon": "6.93968",
    "name": "Essen-Mulheim Airport",
    "city": "Mulheim-on-Ruhr",
    "state": "North Rhine-Westphalia",
    "country": "Germany",
    "woeid": "22247850",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5095",
    "elev": "407",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "ESU",
    "lat": "31.5147",
    "lon": "-9.76532",
    "name": "Morocco",
    "city": "Essaouira",
    "state": "Essaouira",
    "country": "Morocco",
    "woeid": "23424893",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ETH",
    "lat": "29.5541",
    "lon": "34.9554",
    "name": "J Hozman Airport",
    "city": "Elat",
    "state": "HaDarom",
    "country": "Israel",
    "woeid": "12513782",
    "tz": "Asia\/Jerusalem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "43",
    "icao": "LLET",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "ETZ",
    "lat": "48.9788",
    "lon": "6.24722",
    "name": "Lorraine Airport",
    "city": "Verny",
    "state": "Lorraine",
    "country": "France",
    "woeid": "12523821",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.metz-nancy-lorraine.aeroport.fr",
    "runway_length": "8200",
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "EUA",
    "lat": "45.9034",
    "lon": "-116.982",
    "name": "Eua Island",
    "city": "Eua",
    "state": "Tongatapu",
    "country": "Tonga",
    "woeid": "12502093",
    "tz": "Pacific\/Tongatapu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2400",
    "elev": "325",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EUG",
    "lat": "44.1184",
    "lon": "-123.213",
    "name": "Mahlon Sweet Field Airport",
    "city": "Eugene",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12520758",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6202",
    "elev": "365",
    "icao": "KEUG",
    "direct_flights": "12",
    "carriers": "15"
  },
  {
    "code": "EUM",
    "lat": "54.1667",
    "lon": "9.98333",
    "name": "Wasbek Airport",
    "city": "Bordesholm",
    "state": "Schleswig-Holstein",
    "country": "Germany",
    "woeid": "12523863",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1968",
    "elev": "72",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EUN",
    "lat": "27.15",
    "lon": "-13.2",
    "name": "Hassan I",
    "city": "Laayoune",
    "state": "Sakia El Hamra",
    "country": "Morocco",
    "woeid": "1466769",
    "tz": "Africa\/El_Aaiun",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "207",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "EUX",
    "lat": "17.5031",
    "lon": "-62.9736",
    "name": "St. Eustatius Airport",
    "city": "Oranjestad",
    "state": "St Eustatius",
    "country": "Netherlands Antilles",
    "woeid": "12515141",
    "tz": "America\/Curacao",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "124",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "EVE",
    "lat": "68.489",
    "lon": "16.6806",
    "name": "Evenes Airport",
    "city": "Tarnstad",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12515107",
    "tz": "Europe\/Oslo",
    "phone": "(+47) 76 98 10 25",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.avinor.no\/Norsk\/Lufthavner\/Harstad+Narvik_lufthavn,_E",
    "runway_length": "8720",
    "elev": "85",
    "icao": "ENEV",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "EVG",
    "lat": "62.0466",
    "lon": "14.4187",
    "name": "Sveg Airport",
    "city": "Sveg",
    "state": "Jamtland",
    "country": "Sweden",
    "woeid": "12517684",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "1176",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EVN",
    "lat": "40.1573",
    "lon": "44.4065",
    "name": "Yerevan-Parakar Airport",
    "city": "Yerevan",
    "state": "Armavir",
    "country": "Armenia",
    "woeid": "12510427",
    "tz": "Asia\/Yerevan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.zvartnots.am\/",
    "runway_length": null,
    "elev": "2976",
    "icao": "UDYZ",
    "direct_flights": "43",
    "carriers": "29"
  },
  {
    "code": "EVV",
    "lat": "38.0464",
    "lon": "-87.5308",
    "name": "Evansville Regional Airport",
    "city": "Evansville",
    "state": "Indiana",
    "country": "United States",
    "woeid": "12519675",
    "tz": "America\/Chicago",
    "phone": "(812) 421-4401",
    "type": "Airports",
    "email": "comments@evvairport.com",
    "url": "http:\/\/www.evvairport.com",
    "runway_length": "8021",
    "elev": "418",
    "icao": "KEVV",
    "direct_flights": "7",
    "carriers": "10"
  },
  {
    "code": "EWB",
    "lat": "41.6735",
    "lon": "-70.9579",
    "name": "New Bedford Municipal Airport",
    "city": "New Bedford",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12521103",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4997",
    "elev": "80",
    "icao": "KEWB",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "EWD",
    "lat": "61.1021",
    "lon": "-149.924",
    "name": "Wildman Lake",
    "city": "Wildman Lake",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799607",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "EWN",
    "lat": "35.0786",
    "lon": "-77.0343",
    "name": "Craven County Regional Airport",
    "city": "New Bern",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12519354",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4804",
    "elev": "19",
    "icao": "KEWN",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "EWR",
    "lat": "40.6924",
    "lon": "-74.1843",
    "name": "Newark International Airport",
    "city": "Newark",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "12521127",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11000",
    "elev": "18",
    "icao": "KEWR",
    "direct_flights": "220",
    "carriers": "64"
  },
  {
    "code": "EXT",
    "lat": "50.7303",
    "lon": "-3.4166",
    "name": "Exeter Airport",
    "city": "Exeter",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22464406",
    "tz": "Europe\/London",
    "phone": "+44 (0)1392 367433",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.exeter-airport.co.uk",
    "runway_length": "6834",
    "elev": "102",
    "icao": "EGTE",
    "direct_flights": "28",
    "carriers": "3"
  },
  {
    "code": "EYP",
    "lat": "5.35",
    "lon": "-72.3833",
    "name": "El Yopal Airport",
    "city": "Yopal",
    "state": "Casanare",
    "country": "Colombia",
    "woeid": "12524476",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5085",
    "elev": "1100",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "EYW",
    "lat": "24.5536",
    "lon": "-81.7551",
    "name": "Key West International Airport",
    "city": "Key West",
    "state": "Florida",
    "country": "United States",
    "woeid": "12520456",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "4",
    "icao": "KEYW",
    "direct_flights": "8",
    "carriers": "10"
  },
  {
    "code": "EZE",
    "lat": "-34.82",
    "lon": "-58.5333",
    "name": "Ministro Pistarini International Airport",
    "city": "Ezeiza",
    "state": "Buenos Aires",
    "country": "Argentina",
    "woeid": "12510496",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10827",
    "elev": "66",
    "icao": "SAEZ",
    "direct_flights": "44",
    "carriers": "36"
  },
  {
    "code": "EZS",
    "lat": "38.6083",
    "lon": "39.2917",
    "name": "Elazig Airport",
    "city": "Elaz\u0131\u011f",
    "state": "Elaz\u0131\u011f",
    "country": "Turkey",
    "woeid": "12517883",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5610",
    "elev": "2961",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "FAB",
    "lat": "51.2783",
    "lon": "-0.7703",
    "name": "Farnborough Airport",
    "city": "Farnborough",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518087",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2440",
    "elev": "238",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FAE",
    "lat": "62.0625",
    "lon": "-7.2708",
    "name": "Vagar Airport",
    "city": "Faroe Islands",
    "state": "V\u00e1ga",
    "country": "Faroe Islands",
    "woeid": "12512818",
    "tz": "Atlantic\/Faroe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.floghavn.fo\/",
    "runway_length": "4104",
    "elev": "280",
    "icao": "EKVG",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "FAI",
    "lat": "64.8183",
    "lon": "-147.866",
    "name": "Fairbanks International Airport",
    "city": "Fairbanks",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519682",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10300",
    "elev": "434",
    "icao": "PAFA",
    "direct_flights": "32",
    "carriers": "18"
  },
  {
    "code": "FAO",
    "lat": "37.0154",
    "lon": "-7.97197",
    "name": "Faro Airport",
    "city": "Faro",
    "state": "Faro",
    "country": "Portugal",
    "woeid": "12515442",
    "tz": "Europe\/Lisbon",
    "phone": "289 800 800",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ana.pt",
    "runway_length": "8169",
    "elev": "24",
    "icao": "LPFR",
    "direct_flights": "58",
    "carriers": "33"
  },
  {
    "code": "FAR",
    "lat": "46.9195",
    "lon": "-96.8243",
    "name": "Hector International Airport",
    "city": "Fargo",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12520153",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9546",
    "elev": "900",
    "icao": "KFAR",
    "direct_flights": "7",
    "carriers": "12"
  },
  {
    "code": "FAT",
    "lat": "36.7675",
    "lon": "-119.717",
    "name": "Fresno Yosemite International Airport",
    "city": "Fresno",
    "state": "California",
    "country": "United States",
    "woeid": "12519826",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9222",
    "elev": "332",
    "icao": "KFAT",
    "direct_flights": "14",
    "carriers": "19"
  },
  {
    "code": "FAV",
    "lat": "-15.9969",
    "lon": "-145.509",
    "name": "French Polynesia",
    "city": "Fakarava",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "7",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "FAY",
    "lat": "34.9914",
    "lon": "-78.8889",
    "name": "Fayetteville Regional Airport",
    "city": "Fayetteville",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12519718",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7204",
    "elev": "190",
    "icao": "KFAY",
    "direct_flights": "2",
    "carriers": "6"
  },
  {
    "code": "FBM",
    "lat": "-11.5908",
    "lon": "27.5292",
    "name": "Lubumbashi Luano International Airport",
    "city": "Lubumbashi",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511970",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10370",
    "elev": "4295",
    "icao": "",
    "direct_flights": "8",
    "carriers": "9"
  },
  {
    "code": "FCA",
    "lat": "48.3075",
    "lon": "-114.252",
    "name": "Glacier Park International Airport",
    "city": "Kalispell",
    "state": "Montana",
    "country": "United States",
    "woeid": "12519905",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "2972",
    "icao": "KFCA",
    "direct_flights": "8",
    "carriers": "10"
  },
  {
    "code": "FCN",
    "lat": "53.7849",
    "lon": "8.59625",
    "name": "Nordholz",
    "city": "Cuxhaven",
    "state": "Lower-Saxony",
    "country": "Germany",
    "woeid": "680398",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FCO",
    "lat": "41.8026",
    "lon": "12.2551",
    "name": "Leonardo da Vinci International Airport",
    "city": "Rome",
    "state": "Lazio",
    "country": "Italy",
    "woeid": "22318195",
    "tz": "Europe\/Rome",
    "phone": "+39 06 65951",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.adr.it\/",
    "runway_length": "12795",
    "elev": "14",
    "icao": "LIRF",
    "direct_flights": "181",
    "carriers": "124"
  },
  {
    "code": "FDE",
    "lat": "61.392",
    "lon": "5.7615",
    "name": "Bringeland Airport",
    "city": "Bygstad",
    "state": "Sogn og Fjordane Fylke",
    "country": "Norway",
    "woeid": "12523930",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2215",
    "elev": "7",
    "icao": "ENBL",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "FDF",
    "lat": "14.5897",
    "lon": "-61.0092",
    "name": "Le Lamentin Airport",
    "city": "Ducos",
    "state": "Fort-de-France",
    "country": "Martinique",
    "woeid": "12514712",
    "tz": "America\/Martinique",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10827",
    "elev": "16",
    "icao": "TFFF",
    "direct_flights": "10",
    "carriers": "9"
  },
  {
    "code": "FDH",
    "lat": "47.6747",
    "lon": "9.52088",
    "name": "Friedrichshafen Airport",
    "city": "Meckenbeuren",
    "state": "Baden-Wurttemberg",
    "country": "Germany",
    "woeid": "22244457",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7726",
    "elev": "1365",
    "icao": "EDNY",
    "direct_flights": "27",
    "carriers": "8"
  },
  {
    "code": "FEG",
    "lat": "40.3833",
    "lon": "71.7667",
    "name": "Fergana",
    "city": "Fergana",
    "state": "Farghona",
    "country": "Uzbekistan",
    "woeid": "2270088",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "FEN",
    "lat": "-3.8519",
    "lon": "-32.4244",
    "name": "Fernando de Noronha Airport",
    "city": "Vila dos Rem\u00e9dios",
    "state": "Pernambuco",
    "country": "Brazil",
    "woeid": "12511145",
    "tz": "America\/Recife",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6050",
    "elev": "184",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "FEZ",
    "lat": "33.9286",
    "lon": "-4.9767",
    "name": "Saiss Airport",
    "city": "Fez",
    "state": "Fes",
    "country": "Morocco",
    "woeid": "12514790",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10499",
    "elev": "1900",
    "icao": "",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "FGU",
    "lat": "-15.9038",
    "lon": "-140.494",
    "name": "French Polynesia",
    "city": "Fangatau",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2953",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FHZ",
    "lat": "-8.64687",
    "lon": "-131.787",
    "name": "French Polynesia",
    "city": "Fakahina",
    "state": "",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2952",
    "elev": "3",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FIH",
    "lat": "-4.3847",
    "lon": "15.445",
    "name": "Kinshasa N Djili International Airport",
    "city": "Kinshasa",
    "state": "Kinshasa",
    "country": "Democratic Republic of Congo",
    "woeid": "12511963",
    "tz": "Africa\/Kinshasa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1027",
    "icao": "FZAA",
    "direct_flights": "21",
    "carriers": "15"
  },
  {
    "code": "FJR",
    "lat": "25.1106",
    "lon": "56.3267",
    "name": "Fujairah Airport",
    "city": "Al Fujayrah",
    "state": "Fujairah",
    "country": "United Arab Emirates",
    "woeid": "12517735",
    "tz": "Asia\/Dubai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "10",
    "icao": "OMFJ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FKB",
    "lat": "48.781",
    "lon": "8.08678",
    "name": "Baden-Airpark",
    "city": "Rheinm\u00fcnster",
    "state": "Baden-Wurttemberg",
    "country": "Germany",
    "woeid": "22269415",
    "tz": "Europe\/Berlin",
    "phone": "+49 (7229)  66-2309",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.badenairpark.de\/index.html",
    "runway_length": null,
    "elev": null,
    "icao": "EDSB",
    "direct_flights": "24",
    "carriers": "5"
  },
  {
    "code": "FKI",
    "lat": "0.4808",
    "lon": "25.3331",
    "name": "Kisangani Bangoka International Airport",
    "city": "Kisangani",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511965",
    "tz": "Africa\/Lubumbashi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "1289",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "FKL",
    "lat": "41.3766",
    "lon": "-79.8573",
    "name": "Chess Lamberton Airport",
    "city": "Franklin",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12519170",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "1540",
    "icao": "KFKL",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "FKQ",
    "lat": "-2.46456",
    "lon": "117.996",
    "name": "Indonesia",
    "city": "Fak Fak",
    "state": "",
    "country": "Indonesia",
    "woeid": "23424846",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3936",
    "elev": "5",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "FKS",
    "lat": "37.2314",
    "lon": "140.429",
    "name": "Fukushima Airport",
    "city": "Sukagawa-shi",
    "state": "Fukushima Prefecture",
    "country": "Japan",
    "woeid": "28360523",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KFKS",
    "direct_flights": "6",
    "carriers": "5"
  },
  {
    "code": "FLA",
    "lat": "1.5847",
    "lon": "-75.5644",
    "name": "Gustavo Artunduaga Paredes Airport",
    "city": "Florencia",
    "state": "Caqueta",
    "country": "Colombia",
    "woeid": "12512382",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4592",
    "elev": "803",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "FLG",
    "lat": "35.139",
    "lon": "-111.675",
    "name": "Flagstaff Pulliam Airport",
    "city": "Flagstaff",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12521495",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6999",
    "elev": "7011",
    "icao": "KFLG",
    "direct_flights": "3",
    "carriers": "6"
  },
  {
    "code": "FLL",
    "lat": "26.0722",
    "lon": "-80.1354",
    "name": "Fort Lauderdale Hollywood International Airport",
    "city": "Dania Beach",
    "state": "Florida",
    "country": "United States",
    "woeid": "12519836",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9001",
    "elev": "11",
    "icao": "KFLL",
    "direct_flights": "108",
    "carriers": "46"
  },
  {
    "code": "FLN",
    "lat": "-27.6697",
    "lon": "-48.5517",
    "name": "Hercilio Luz International Airport",
    "city": "Florianopolis",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12511173",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7942",
    "elev": "20",
    "icao": "",
    "direct_flights": "12",
    "carriers": "10"
  },
  {
    "code": "FLO",
    "lat": "34.1936",
    "lon": "-79.7289",
    "name": "Florence Regional Airport",
    "city": "Florence",
    "state": "South Carolina",
    "country": "United States",
    "woeid": "12519746",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6498",
    "elev": "147",
    "icao": "KFLO",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "FLR",
    "lat": "43.8097",
    "lon": "11.2003",
    "name": "Florence Airport",
    "city": "Florence",
    "state": "Tuscany",
    "country": "Italy",
    "woeid": "12513846",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": null,
    "icao": "LIRQ",
    "direct_flights": "34",
    "carriers": "23"
  },
  {
    "code": "FLW",
    "lat": "39.4569",
    "lon": "-31.1272",
    "name": "Flores Airport",
    "city": "Flores Island",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515443",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3707",
    "elev": "112",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "FMA",
    "lat": "-26.21",
    "lon": "-58.235",
    "name": "Formosa Airport",
    "city": "Formosa",
    "state": "Formosa",
    "country": "Argentina",
    "woeid": "12510497",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "194",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FMM",
    "lat": "20.499",
    "lon": "103.75",
    "name": "Memmingen-Allg\u00e4u Airport",
    "city": "Memmingen",
    "state": "Bavaria",
    "country": "Germany",
    "woeid": "20153097",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "13",
    "carriers": "1"
  },
  {
    "code": "FMN",
    "lat": "36.7387",
    "lon": "-108.227",
    "name": "Four Corners Regional Airport",
    "city": "Farmington",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12519792",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6702",
    "elev": "5503",
    "icao": "KFMN",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "FMO",
    "lat": "52.1312",
    "lon": "7.69163",
    "name": "Munster-Osnabruck International Airport",
    "city": "Greven",
    "state": "North Rhine-Westphalia",
    "country": "Germany",
    "woeid": "22206577",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flughafen-fmo.de",
    "runway_length": "7119",
    "elev": "158",
    "icao": "EDDG",
    "direct_flights": "28",
    "carriers": "20"
  },
  {
    "code": "FMY",
    "lat": "26.5815",
    "lon": "-81.862",
    "name": "Page Field Airport",
    "city": "Fort Myers",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521276",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6401",
    "elev": "18",
    "icao": "KFMY",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "FNA",
    "lat": "8.6181",
    "lon": "-13.1994",
    "name": "Freetown Lungi Airport",
    "city": "Freetown",
    "state": "Northern",
    "country": "Sierra Leone",
    "woeid": "12517517",
    "tz": "Africa\/Freetown",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "84",
    "icao": "GFLL",
    "direct_flights": "8",
    "carriers": "12"
  },
  {
    "code": "FNC",
    "lat": "32.6919",
    "lon": "-16.7768",
    "name": "Funchal Airport",
    "city": "Madeira",
    "state": "Madeira",
    "country": "Portugal",
    "woeid": "12523046",
    "tz": "Europe\/Lisbon",
    "phone": "+351 291 520 700",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.anam.pt",
    "runway_length": "5905",
    "elev": "190",
    "icao": "LPMA",
    "direct_flights": "34",
    "carriers": "23"
  },
  {
    "code": "FNI",
    "lat": "43.7618",
    "lon": "4.42092",
    "name": "Garons Airport",
    "city": "St-Gilles-du-Gard",
    "state": "Languedoc-Roussillon",
    "country": "France",
    "woeid": "12512899",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8005",
    "elev": "308",
    "icao": "LFTW",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "FNJ",
    "lat": "39.1437",
    "lon": "125.695",
    "name": "Sunan Airport",
    "city": "Sunan",
    "state": "P'yongan-namdo",
    "country": "North Korea",
    "woeid": "12514175",
    "tz": "Asia\/Pyongyang",
    "phone": "+850-2-837-917",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "94",
    "icao": "ZKPY",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "FNL",
    "lat": "40.4505",
    "lon": "-105.008",
    "name": "Fort Collins Loveland Municipal Airport",
    "city": "Loveland",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12519773",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4700",
    "elev": "4935",
    "icao": "KFNL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FNT",
    "lat": "42.9756",
    "lon": "-83.7411",
    "name": "Bishop International Airport",
    "city": "Flint",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12518841",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7848",
    "elev": "782",
    "icao": "KFNT",
    "direct_flights": "15",
    "carriers": "10"
  },
  {
    "code": "FOC",
    "lat": "26.0833",
    "lon": "119.283",
    "name": "Fuzhou Airport",
    "city": "Fuzhou",
    "state": "Fujian",
    "country": "China",
    "woeid": "12523107",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "26",
    "carriers": "14"
  },
  {
    "code": "FOD",
    "lat": "42.5523",
    "lon": "-94.1819",
    "name": "Fort Dodge Regional Airport",
    "city": "Fort Dodge",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12519775",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6547",
    "elev": "1157",
    "icao": "KFOD",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "FOG",
    "lat": "41.4341",
    "lon": "15.5438",
    "name": "Gino Lisa Airport",
    "city": "Foggia",
    "state": "Puglia",
    "country": "Italy",
    "woeid": "12513823",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "308",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "FOR",
    "lat": "-3.7758",
    "lon": "-38.5322",
    "name": "Pinto Martins International Airport",
    "city": "Fortaleza",
    "state": "Ceara",
    "country": "Brazil",
    "woeid": "12511272",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8350",
    "elev": "82",
    "icao": "SBFZ",
    "direct_flights": "18",
    "carriers": "13"
  },
  {
    "code": "FPO",
    "lat": "26.5561",
    "lon": "-78.6986",
    "name": "Freeport International Airport",
    "city": "Freeport City",
    "state": "City of Freeport",
    "country": "Bahamas",
    "woeid": "12510867",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MYGF",
    "direct_flights": "9",
    "carriers": "9"
  },
  {
    "code": "FRA",
    "lat": "50.0483",
    "lon": "8.57041",
    "name": "Frankfurt International Airport",
    "city": "Frankfurt",
    "state": "Hesse",
    "country": "Germany",
    "woeid": "22981759",
    "tz": "Europe\/Berlin",
    "phone": "+49 69 690 0",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportcity-frankfurt.de",
    "runway_length": "13123",
    "elev": "364",
    "icao": "EDDF",
    "direct_flights": "337",
    "carriers": "131"
  },
  {
    "code": "FRC",
    "lat": "-20.5897",
    "lon": "-47.3819",
    "name": "Franca Airport",
    "city": "Franca",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511149",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "3271",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FRD",
    "lat": "48.5252",
    "lon": "-123.027",
    "name": "Friday Harbor Airport",
    "city": "Friday Harbor",
    "state": "Washington",
    "country": "United States",
    "woeid": "12519828",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3400",
    "elev": "120",
    "icao": "KFHR",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "FRE",
    "lat": "-8.1",
    "lon": "159.583",
    "name": "Fera Island",
    "city": "Fera Island",
    "state": "Isabel",
    "country": "Solomon Islands",
    "woeid": "1020491",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2231",
    "elev": "8",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FRL",
    "lat": "44.1953",
    "lon": "12.0686",
    "name": "Forli Airport",
    "city": "Forli",
    "state": "Emilia Romagna",
    "country": "Italy",
    "woeid": "12513820",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7907",
    "elev": "98",
    "icao": "LIPK",
    "direct_flights": "15",
    "carriers": "3"
  },
  {
    "code": "FRO",
    "lat": "61.5863",
    "lon": "5.02484",
    "name": "Flora Airport",
    "city": "Floro",
    "state": "Sogn og Fjordane Fylke",
    "country": "Norway",
    "woeid": "12523929",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2690",
    "elev": "31",
    "icao": "ENFL",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "FRS",
    "lat": "16.9139",
    "lon": "-89.8664",
    "name": "Santa Elena Airport",
    "city": "Flores",
    "state": "Peten",
    "country": "Guatemala",
    "woeid": "12513341",
    "tz": "America\/Guatemala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "420",
    "icao": "MGTK",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "FRU",
    "lat": "43.0603",
    "lon": "74.4866",
    "name": "Vasilyevka Airport",
    "city": "Bishkek",
    "state": "Chuy",
    "country": "Kyrgyzstan",
    "woeid": "12514125",
    "tz": "Asia\/Bishkek",
    "phone": "+996 312 693109",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport.kg\/",
    "runway_length": null,
    "elev": null,
    "icao": "UAFM",
    "direct_flights": "14",
    "carriers": "17"
  },
  {
    "code": "FRW",
    "lat": "-21.16",
    "lon": "27.4825",
    "name": "Francistown Airport",
    "city": "Francistown",
    "state": "North-East",
    "country": "Botswana",
    "woeid": "12510835",
    "tz": "Africa\/Gaborone",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7088",
    "elev": "3281",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FSC",
    "lat": "41.5022",
    "lon": "9.0968",
    "name": "Sud Corse Airport",
    "city": "Figari",
    "state": "Corsica",
    "country": "France",
    "woeid": "12512979",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8136",
    "elev": "85",
    "icao": "LFKF",
    "direct_flights": "9",
    "carriers": "3"
  },
  {
    "code": "FSD",
    "lat": "43.5813",
    "lon": "-96.7317",
    "name": "Sioux Falls Regional Airport",
    "city": "Sioux Falls",
    "state": "South Dakota",
    "country": "United States",
    "woeid": "12520371",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8999",
    "elev": "1429",
    "icao": "KFSD",
    "direct_flights": "9",
    "carriers": "11"
  },
  {
    "code": "FSM",
    "lat": "36.1881",
    "lon": "-94.4944",
    "name": "Smith Field Airport",
    "city": "Fort Smith",
    "state": "Arkansas",
    "country": "United States",
    "woeid": "12521888",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "469",
    "icao": "KFSM",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "FSP",
    "lat": "46.7708",
    "lon": "-56.1667",
    "name": "St Pierre Airport",
    "city": "St Pierre",
    "state": "Miquelon-Langlade",
    "country": "Saint Pierre and Miquelon",
    "woeid": "12517380",
    "tz": "America\/Miquelon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4100",
    "elev": "23",
    "icao": "LFVP",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "FTA",
    "lat": "-19.5267",
    "lon": "170.215",
    "name": "Futuna",
    "city": "Futuna Island",
    "state": "Tafea",
    "country": "Vanuatu",
    "woeid": "12497035",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "FTE",
    "lat": "-50.3333",
    "lon": "-72.25",
    "name": "El Calafate International Airport",
    "city": "El Calafate",
    "state": "Santa Cruz",
    "country": "Argentina",
    "woeid": "24554862",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "FTU",
    "lat": "-25.0381",
    "lon": "46.9556",
    "name": "Tolagnaro Airport",
    "city": "Faradofay",
    "state": "Toliara",
    "country": "Madagascar",
    "woeid": "12514709",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "26",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "FUE",
    "lat": "28.3997",
    "lon": "-13.9998",
    "name": "Puerto del Rosario Airport",
    "city": "Antigua",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "12517563",
    "tz": "Atlantic\/Canary",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "75",
    "icao": "GCFV",
    "direct_flights": "45",
    "carriers": "27"
  },
  {
    "code": "FUG",
    "lat": "37.8882",
    "lon": "-82.9323",
    "name": "",
    "city": "Fuyang",
    "state": "Zhejiang",
    "country": "China",
    "woeid": "2132597",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FUJ",
    "lat": "32.6726",
    "lon": "128.836",
    "name": "Fukue Airport",
    "city": "Goto-shi",
    "state": "Nagasaki Prefecture",
    "country": "Japan",
    "woeid": "12513949",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5250",
    "elev": "273",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FUK",
    "lat": "33.5971",
    "lon": "130.443",
    "name": "Fukuoka Airport",
    "city": "Fukuoka-shi",
    "state": "Fukuoka Prefecture",
    "country": "Japan",
    "woeid": "23388316",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9250",
    "elev": "30",
    "icao": "RJFF",
    "direct_flights": "36",
    "carriers": "31"
  },
  {
    "code": "FUN",
    "lat": "-8.52982",
    "lon": "179.189",
    "name": "Funafuti International Airport",
    "city": "Funafuti",
    "state": "Funafuti",
    "country": "Tuvalu",
    "woeid": "12517924",
    "tz": "Pacific\/Funafuti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "9",
    "icao": "NGFU",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "FUT",
    "lat": "-14.25",
    "lon": "-178.15",
    "name": "Futuna Island",
    "city": "Futuna Island",
    "state": "Hahake",
    "country": "Wallis and Futuna Islands",
    "woeid": "22503940",
    "tz": "Pacific\/Wallis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3051",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FWA",
    "lat": "40.9883",
    "lon": "-85.1899",
    "name": "Fort Wayne Municipal Airport-Baer Field",
    "city": "Fort Wayne",
    "state": "Indiana",
    "country": "United States",
    "woeid": "12519784",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "802",
    "icao": "KFWA",
    "direct_flights": "27",
    "carriers": "16"
  },
  {
    "code": "FWM",
    "lat": "56.8179",
    "lon": "-5.1039",
    "name": "Fort William Heliport",
    "city": "Fort William",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "12523986",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "FYU",
    "lat": "66.5714",
    "lon": "-145.25",
    "name": "Fort Yukon Airport",
    "city": "Fort Yukon",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519788",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5809",
    "elev": "433",
    "icao": "PFYU",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "FZO",
    "lat": "51.5194",
    "lon": "-2.5761",
    "name": "Filton Airport",
    "city": "Bristol",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518089",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GAE",
    "lat": "33.8792",
    "lon": "10.1028",
    "name": "Gabes Airport",
    "city": "Gabes",
    "state": "Qabis",
    "country": "Tunisia",
    "woeid": "12517858",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "26",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GAF",
    "lat": "34.4222",
    "lon": "8.81944",
    "name": "Gafsa",
    "city": "Gafsa",
    "state": "Qafash",
    "country": "Tunisia",
    "woeid": "12517859",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5413",
    "elev": "1033",
    "icao": "KGAF",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "GAJ",
    "lat": "38.4109",
    "lon": "140.37",
    "name": "Yamagata Airport",
    "city": "Higashine-shi",
    "state": "Yamagata Prefecture",
    "country": "Japan",
    "woeid": "12514035",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "353",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "GAL",
    "lat": "64.7404",
    "lon": "-156.931",
    "name": "Galena Airport",
    "city": "Galena",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519851",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7254",
    "elev": "152",
    "icao": "PAGA",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "GAM",
    "lat": "63.7667",
    "lon": "-171.738",
    "name": "Gambell Airport",
    "city": "Gambell",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519858",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "28",
    "icao": "PAGM",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "GAN",
    "lat": "-0.6912",
    "lon": "73.16",
    "name": "Gan Island Airport",
    "city": "Hithadhoo",
    "state": "Seenu",
    "country": "Maldives",
    "woeid": "12514815",
    "tz": "Indian\/Maldives",
    "phone": "(+960) 313309",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airports.com.mv\/domestic\/gan.htm",
    "runway_length": "2652",
    "elev": "1",
    "icao": "VRMG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GAO",
    "lat": "20.143",
    "lon": "-75.209",
    "name": "Cuba",
    "city": "Guantanamo",
    "state": "Guantanamo",
    "country": "Cuba",
    "woeid": "23424793",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8053",
    "elev": "20",
    "icao": "KGAO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GAU",
    "lat": "26.0857",
    "lon": "91.5669",
    "name": "Borjhar",
    "city": "Gawahati",
    "state": "Assam",
    "country": "India",
    "woeid": "12513585",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "161",
    "icao": "VEGT",
    "direct_flights": "12",
    "carriers": "7"
  },
  {
    "code": "GAX",
    "lat": "-2.7639",
    "lon": "9.98581",
    "name": "Gamba",
    "city": "Gamba",
    "state": "",
    "country": "Gabon",
    "woeid": "23424822",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2461",
    "elev": "13",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GAY",
    "lat": "24.7443",
    "lon": "84.9493",
    "name": "Gaya Airport",
    "city": "Gaya",
    "state": "Bihar",
    "country": "India",
    "woeid": "12513586",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "377",
    "icao": "VEGY",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "GBD",
    "lat": "38.352",
    "lon": "-98.853",
    "name": "Great Bend Municipal Airport",
    "city": "Great Bend",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12519973",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7999",
    "elev": "1891",
    "icao": "KGBD",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "GBE",
    "lat": "-24.5558",
    "lon": "25.9189",
    "name": "Sir Seretse Khama International Airport",
    "city": "Tlokweng",
    "state": "South-East",
    "country": "Botswana",
    "woeid": "12510841",
    "tz": "Africa\/Gaborone",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9850",
    "elev": "3299",
    "icao": "FBSK",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "GBJ",
    "lat": "15.8714",
    "lon": "-61.2647",
    "name": "Marie Galante Airport",
    "city": "Grand-Bourg",
    "state": "Pointe-\u00c0-Pitre",
    "country": "Guadeloupe",
    "woeid": "12513278",
    "tz": "America\/Guadeloupe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4068",
    "elev": "16",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GBT",
    "lat": "36.8281",
    "lon": "54.4392",
    "name": "Iran",
    "city": "Gorgan",
    "state": "Golestan",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "GCC",
    "lat": "44.3465",
    "lon": "-105.533",
    "name": "Gillette Campbell County Airport",
    "city": "Gillette",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12519902",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "4363",
    "icao": "KGCC",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "GCI",
    "lat": "49.4341",
    "lon": "-2.60003",
    "name": "Guernsey Airport",
    "city": "St. Peter Port",
    "state": "Channel Islands",
    "country": "United Kingdom",
    "woeid": "12513029",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "336",
    "icao": "EGJB",
    "direct_flights": "13",
    "carriers": "2"
  },
  {
    "code": "GCK",
    "lat": "37.9267",
    "lon": "-100.719",
    "name": "Garden City Regional Airport",
    "city": "Pierceville",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12519861",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5999",
    "elev": "2888",
    "icao": "KGCK",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "GCM",
    "lat": "19.2925",
    "lon": "-81.3589",
    "name": "Owen Roberts International Airport",
    "city": "Georgetown",
    "state": "Grand Cayman",
    "country": "Cayman Islands",
    "woeid": "12512345",
    "tz": "America\/Cayman",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "8",
    "icao": "MWCR",
    "direct_flights": "22",
    "carriers": "13"
  },
  {
    "code": "GDE",
    "lat": "5.93461",
    "lon": "43.5318",
    "name": "Ididole",
    "city": "Gode\/Iddidole",
    "state": "Bale",
    "country": "Ethiopia",
    "woeid": "1317305",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7909",
    "elev": "968",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GDL",
    "lat": "20.5347",
    "lon": "-103.322",
    "name": "Don Miguel Hidalgo International Airport",
    "city": "Tlajomulco de Z\u00fa\u00f1iga",
    "state": "Jalisco",
    "country": "Mexico",
    "woeid": "12514863",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13120",
    "elev": "5012",
    "icao": "MMGL",
    "direct_flights": "43",
    "carriers": "29"
  },
  {
    "code": "GDN",
    "lat": "54.3821",
    "lon": "18.454",
    "name": "Rebiechowo Airport",
    "city": "Gdansk",
    "state": "Pomorskie",
    "country": "Poland",
    "woeid": "12515381",
    "tz": "Europe\/Warsaw",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport.gdansk.pl",
    "runway_length": "9186",
    "elev": "489",
    "icao": "EPGD",
    "direct_flights": "29",
    "carriers": "12"
  },
  {
    "code": "GDO",
    "lat": "7.2111",
    "lon": "-70.7583",
    "name": "Vare Maria Airport",
    "city": "Barinas",
    "state": "Apure",
    "country": "Venezuela",
    "woeid": "12522788",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3969",
    "elev": "426",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GDQ",
    "lat": "12.5167",
    "lon": "37.45",
    "name": "Gondar Airport",
    "city": "Azezo",
    "state": "Debub Gonder",
    "country": "Ethiopia",
    "woeid": "12523110",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4626",
    "elev": "6453",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "GDT",
    "lat": "21.4439",
    "lon": "-71.1425",
    "name": "Grand Turk International Airport",
    "city": "Grand Turk",
    "state": "Gand Turk",
    "country": "Turks And Caicos Islands",
    "woeid": "12517840",
    "tz": "America\/Grand_Turk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6335",
    "elev": "13",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "GDX",
    "lat": "59.8984",
    "lon": "150.71",
    "name": "Magadan Northwest Airport",
    "city": "Magadan",
    "state": "Magadanskaya Oblast",
    "country": "Russia",
    "woeid": "12516379",
    "tz": "Asia\/Magadan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11325",
    "elev": "574",
    "icao": "",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "GEA",
    "lat": "-22.2667",
    "lon": "166.467",
    "name": "Magenta Airport",
    "city": "Noumea",
    "state": "Sud",
    "country": "New Caledonia",
    "woeid": "12523111",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3609",
    "elev": "10",
    "icao": "",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "GEG",
    "lat": "47.625",
    "lon": "-117.538",
    "name": "Spokane International Airport",
    "city": "Spokane",
    "state": "Washington",
    "country": "United States",
    "woeid": "12521938",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "2372",
    "icao": "KGEG",
    "direct_flights": "15",
    "carriers": "20"
  },
  {
    "code": "GEL",
    "lat": "-28.2808",
    "lon": "-54.1683",
    "name": "Santo Angelo Airport",
    "city": "Santo Angelo",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511309",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "1063",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "GEO",
    "lat": "6.5003",
    "lon": "-58.2542",
    "name": "Timehri International Airport",
    "city": "Hyde Park",
    "state": "Demerara-Mahaica",
    "country": "Guyana",
    "woeid": "12513350",
    "tz": "America\/Guyana",
    "phone": "(920) 498-4800",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cjairport-gy.com\/",
    "runway_length": "7",
    "elev": "95",
    "icao": "SYCJ",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "GER",
    "lat": "21.8375",
    "lon": "-82.78",
    "name": "Nueva Gerona Airport",
    "city": "Nueva Gerona",
    "state": "Isla de la Juventud",
    "country": "Cuba",
    "woeid": "12512478",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "72",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GES",
    "lat": "6.1094",
    "lon": "125.235",
    "name": "General Santos Airport",
    "city": "General Santos City",
    "state": "Soccsksargen",
    "country": "Philippines",
    "woeid": "12515617",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ato.gov.ph\/Airports\/Tambler.htm",
    "runway_length": "10567",
    "elev": "505",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "GET",
    "lat": "-28.7975",
    "lon": "114.706",
    "name": "Geraldton Airport",
    "city": "Geraldton",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510673",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "120",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "GEV",
    "lat": "67.1345",
    "lon": "20.8126",
    "name": "Lappland Airport",
    "city": "Koskullskulle",
    "state": "Norrbotten",
    "country": "Sweden",
    "woeid": "12517628",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5610",
    "elev": "979",
    "icao": "KGEV",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "GFF",
    "lat": "-34.2454",
    "lon": "146.068",
    "name": "Griffith Airport",
    "city": "Griffith",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510681",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "446",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GFK",
    "lat": "47.9494",
    "lon": "-97.1762",
    "name": "Grand Forks Mark Andrews International Airport",
    "city": "Grand Forks",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12519951",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7349",
    "elev": "844",
    "icao": "KGFK",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "GFN",
    "lat": "-29.7562",
    "lon": "153.031",
    "name": "Grafton Airport",
    "city": "Grafton",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510680",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5600",
    "elev": "110",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GGG",
    "lat": "32.3874",
    "lon": "-94.7194",
    "name": "Gregg County Airport",
    "city": "Longview",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520015",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "365",
    "icao": "KGGG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GGT",
    "lat": "23.5611",
    "lon": "-75.8744",
    "name": "Exuma International Airport",
    "city": "George Town",
    "state": "Exuma",
    "country": "Bahamas",
    "woeid": "12510866",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "5",
    "icao": "",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "GHA",
    "lat": "32.3828",
    "lon": "3.7964",
    "name": "Noumerate Airport",
    "city": "Ghardaia",
    "state": "Ghardaia",
    "country": "Algeria",
    "woeid": "12510338",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10171",
    "elev": "1512",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "GHB",
    "lat": "25.2839",
    "lon": "-76.3308",
    "name": "Governors Harbour Airport",
    "city": "Governor Harbour",
    "state": "Central Eleuthera",
    "country": "Bahamas",
    "woeid": "12510868",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7900",
    "elev": "23",
    "icao": "",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "GHT",
    "lat": "25.1325",
    "lon": "10.1489",
    "name": "Ghat Airport",
    "city": "Ghat",
    "state": "Awbari",
    "country": "Libya",
    "woeid": "12514652",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "2175",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "GIB",
    "lat": "36.1522",
    "lon": "-5.3472",
    "name": "Gibraltar Airport",
    "city": "Gibraltar",
    "state": "",
    "country": "Gibraltar",
    "woeid": "12513027",
    "tz": "Europe\/Gibraltar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6004",
    "elev": "15",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "GIC",
    "lat": "-9.2622",
    "lon": "142.214",
    "name": "Boigu Island Airport",
    "city": "Kubin Village",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510611",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GIG",
    "lat": "-22.8094",
    "lon": "-43.25",
    "name": "Rio de Janeiro-Antonio Carlos Jobim Internationa",
    "city": "Rio de Janeiro",
    "state": "Rio de Janeiro",
    "country": "Brazil",
    "woeid": "12511292",
    "tz": "America\/Sao_Paulo",
    "phone": "+55(21)3398-5050",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13123",
    "elev": "30",
    "icao": "SBGL",
    "direct_flights": "32",
    "carriers": "25"
  },
  {
    "code": "GIL",
    "lat": "35.9192",
    "lon": "74.3358",
    "name": "Gilgit Airport",
    "city": "Gilgit",
    "state": "Northern Areas",
    "country": "Pakistan",
    "woeid": "12515235",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5400",
    "elev": "4770",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GIS",
    "lat": "-38.66",
    "lon": "177.978",
    "name": "Gisborne Airport",
    "city": "Gisborne",
    "state": "Gisborne",
    "country": "New Zealand",
    "woeid": "12515154",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "15",
    "icao": "NZGS",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GIZ",
    "lat": "16.9069",
    "lon": "42.5819",
    "name": "Gizan Airport",
    "city": "Jizan",
    "state": "Jizan",
    "country": "Saudi Arabia",
    "woeid": "12517335",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "20",
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "GJA",
    "lat": "16.4591",
    "lon": "-85.9034",
    "name": "Guanaja Airport",
    "city": "Guanaja",
    "state": "Islas de la Bah\u00eda",
    "country": "Honduras",
    "woeid": "12513359",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3600",
    "elev": "36",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "GJL",
    "lat": "36.7942",
    "lon": "5.8769",
    "name": "Taher Airport",
    "city": "Jijel",
    "state": "Jijel",
    "country": "Algeria",
    "woeid": "12510349",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "33",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GJT",
    "lat": "39.1217",
    "lon": "-108.529",
    "name": "Walker Field Airport",
    "city": "Grand Junction",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12522315",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10501",
    "elev": "4858",
    "icao": "KGJT",
    "direct_flights": "7",
    "carriers": "11"
  },
  {
    "code": "GKA",
    "lat": "-6.0828",
    "lon": "145.396",
    "name": "Goroka Airport",
    "city": "Goroka",
    "state": "Eastern Highlands",
    "country": "Papua New Guinea",
    "woeid": "12515466",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5400",
    "elev": "5208",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "GLA",
    "lat": "55.8646",
    "lon": "-4.43234",
    "name": "Glasgow International Airport",
    "city": "Paisley",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22482705",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.glasgowairport.com\/",
    "runway_length": "7625",
    "elev": "26",
    "icao": "EGPF",
    "direct_flights": "67",
    "carriers": "44"
  },
  {
    "code": "GLF",
    "lat": "8.6525",
    "lon": "-83.1828",
    "name": "Golfito Airport",
    "city": "Palmar Sur",
    "state": "Puntarenas",
    "country": "Costa Rica",
    "woeid": "12512432",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "49",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GLH",
    "lat": "33.4819",
    "lon": "-90.9903",
    "name": "Mid Delta Regional Airport",
    "city": "Greenville",
    "state": "Mississippi",
    "country": "United States",
    "woeid": "12520935",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7019",
    "elev": "131",
    "icao": "KGLH",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "GLK",
    "lat": "6.7786",
    "lon": "47.4528",
    "name": "Galcaio Airport",
    "city": "Gaalkacyo",
    "state": "Mudug",
    "country": "Somalia",
    "woeid": "12517529",
    "tz": "Africa\/Mogadishu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9660",
    "elev": "975",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GLN",
    "lat": "28.9839",
    "lon": "-10.0603",
    "name": "Guelmim",
    "city": "Goulimime",
    "state": "Guelmim",
    "country": "Morocco",
    "woeid": "1535570",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "984",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GLO",
    "lat": "51.8925",
    "lon": "-2.1633",
    "name": "Gloucestershire Airport",
    "city": "Cheltenham",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518156",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4052",
    "elev": "95",
    "icao": "EGBJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GLT",
    "lat": "-23.87",
    "lon": "151.226",
    "name": "Gladstone Airport",
    "city": "Gladstone",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510675",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5360",
    "elev": "64",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GLV",
    "lat": "64.5433",
    "lon": "-163.033",
    "name": "Golovin",
    "city": "Golovin",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2411932",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "25",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "GMA",
    "lat": "3.2347",
    "lon": "19.7719",
    "name": "Gemena Airport",
    "city": "Gemena",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511955",
    "tz": "Africa\/Kinshasa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "1378",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "GMB",
    "lat": "13.4454",
    "lon": "-15.3114",
    "name": "",
    "city": "Gambela",
    "state": "Ilubabor",
    "country": "Ethiopia",
    "woeid": "1316101",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "1768",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GMP",
    "lat": "37.5563",
    "lon": "126.793",
    "name": "Gimpo International Airport",
    "city": "Seoul",
    "state": "Seoul",
    "country": "South Korea",
    "woeid": "23388324",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "RKSS",
    "direct_flights": "10",
    "carriers": "9"
  },
  {
    "code": "GMR",
    "lat": "-23.0831",
    "lon": "-134.887",
    "name": "Gambier Is",
    "city": "Gambier Is",
    "state": "Windward Islands",
    "country": "French Polynesia",
    "woeid": "22504021",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "7",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GMZ",
    "lat": "28.0252",
    "lon": "-17.2148",
    "name": "La Gomera Airport",
    "city": "Alajer\u00f3",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "24554860",
    "tz": "Atlantic\/Canary",
    "phone": "+34 922 87 30 00",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GNB",
    "lat": "45.3614",
    "lon": "5.33214",
    "name": "St Geoirs Airport",
    "city": "Sillans",
    "state": "Rhone-Alpes",
    "country": "France",
    "woeid": "12512972",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "1302",
    "icao": "KGNB",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "GND",
    "lat": "12.0056",
    "lon": "-61.7867",
    "name": "Point Salines International Airport",
    "city": "Grenada",
    "state": "Saint George",
    "country": "Grenada",
    "woeid": "12513028",
    "tz": "America\/Grenada",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "30",
    "icao": "TGPY",
    "direct_flights": "13",
    "carriers": "9"
  },
  {
    "code": "GNU",
    "lat": "59.1218",
    "lon": "-161.588",
    "name": "Alaska",
    "city": "Goodnews Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "15",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "GNV",
    "lat": "29.6861",
    "lon": "-82.277",
    "name": "Gainesville Regional Airport",
    "city": "Gainesville",
    "state": "Florida",
    "country": "United States",
    "woeid": "12519850",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7503",
    "elev": "152",
    "icao": "KGNV",
    "direct_flights": "3",
    "carriers": "8"
  },
  {
    "code": "GOA",
    "lat": "44.4119",
    "lon": "8.84633",
    "name": "Genoa Cristoforo Colombo Airport",
    "city": "Genoa",
    "state": "Liguria",
    "country": "Italy",
    "woeid": "12513821",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroportodigenova.com\/home\/index.php",
    "runway_length": "9925",
    "elev": "10",
    "icao": "LIMJ",
    "direct_flights": "16",
    "carriers": "22"
  },
  {
    "code": "GOH",
    "lat": "64.1922",
    "lon": "-51.6825",
    "name": "Godthaab Airport",
    "city": "Nuuk",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12513030",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3100",
    "elev": "283",
    "icao": "BGGH",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "GOI",
    "lat": "15.3818",
    "lon": "73.8265",
    "name": "Dabolim Airport",
    "city": "Vasco Da Gama",
    "state": "Goa",
    "country": "India",
    "woeid": "12513587",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7850",
    "elev": "157",
    "icao": "",
    "direct_flights": "13",
    "carriers": "10"
  },
  {
    "code": "GOJ",
    "lat": "56.2153",
    "lon": "43.783",
    "name": "Strigino Airport",
    "city": "Dzerzinsk",
    "state": "Nizhegorodskaya Oblast",
    "country": "Russia",
    "woeid": "12516946",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "GOM",
    "lat": "-1.6661",
    "lon": "29.2344",
    "name": "Goma International Airport",
    "city": "Goma",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511956",
    "tz": "Africa\/Lubumbashi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "5089",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "GOP",
    "lat": "22.5552",
    "lon": "88.3685",
    "name": "Gorakhpur",
    "city": "Gorakhpur",
    "state": "Bihar",
    "country": "India",
    "woeid": "29160405",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "259",
    "icao": "KGOP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GOQ",
    "lat": "36.8135",
    "lon": "95.3498",
    "name": "Golmud Airport",
    "city": "Golmud",
    "state": "Qinghai",
    "country": "China",
    "woeid": "12512057",
    "tz": "Asia\/Urumqi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GOT",
    "lat": "57.6693",
    "lon": "12.2957",
    "name": "Gothenburg Airport",
    "city": "H\u00e4rryda",
    "state": "Vastra Gotaland",
    "country": "Sweden",
    "woeid": "12517657",
    "tz": "Europe\/Stockholm",
    "phone": "+4631941000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.landvetter.lfv.se",
    "runway_length": "10827",
    "elev": "506",
    "icao": "ESGG",
    "direct_flights": "46",
    "carriers": "43"
  },
  {
    "code": "GOU",
    "lat": "9.3361",
    "lon": "13.3756",
    "name": "Garoua Airport",
    "city": "Garoua",
    "state": "Nord",
    "country": "Cameroon",
    "woeid": "12512350",
    "tz": "Africa\/Douala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11155",
    "elev": "794",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "GOV",
    "lat": "-12.2747",
    "lon": "136.826",
    "name": "Gove Aerodrome",
    "city": "Gove",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "12510679",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6750",
    "elev": "172",
    "icao": "KGOV",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "GPA",
    "lat": "38.1519",
    "lon": "21.4272",
    "name": "Araxos Airport",
    "city": "Lakkopetra",
    "state": "Dytiki Ellada",
    "country": "Greece",
    "woeid": "12513285",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LGRX",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "GPI",
    "lat": "2.5806",
    "lon": "-77.9083",
    "name": "Guapi Airport",
    "city": "Guap\u00ed",
    "state": "Cauca",
    "country": "Colombia",
    "woeid": "12512379",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4330",
    "elev": "164",
    "icao": "KGPI",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "GPS",
    "lat": "-0.45",
    "lon": "-90.2625",
    "name": "Seymour Airport",
    "city": "Puerto Ayora",
    "state": "Galapagos",
    "country": "Ecuador",
    "woeid": "12512651",
    "tz": "Pacific\/Galapagos",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "52",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "GPT",
    "lat": "30.4133",
    "lon": "-89.072",
    "name": "Gulfport Biloxi Regional Airport",
    "city": "Gulfport",
    "state": "Mississippi",
    "country": "United States",
    "woeid": "12520037",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9002",
    "elev": "28",
    "icao": "KGPT",
    "direct_flights": "9",
    "carriers": "11"
  },
  {
    "code": "GRB",
    "lat": "44.4923",
    "lon": "-88.1274",
    "name": "Austin Straubel International Airport",
    "city": "Green Bay",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12518709",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.co.brown.wi.us\/airport\/",
    "runway_length": "7699",
    "elev": "695",
    "icao": "KGRB",
    "direct_flights": "12",
    "carriers": "16"
  },
  {
    "code": "GRJ",
    "lat": "-34.005",
    "lon": "22.3792",
    "name": "P W Botha Airport",
    "city": "George",
    "state": "Western Cape",
    "country": "South Africa",
    "woeid": "12517456",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "649",
    "icao": "FAGG",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "GRK",
    "lat": "31.0615",
    "lon": "-97.8206",
    "name": "Killeen-Fort Hood Regional Airport",
    "city": "Killeen",
    "state": "Texas",
    "country": "United States",
    "woeid": "12521608",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KGRK",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "GRO",
    "lat": "41.8992",
    "lon": "2.7646",
    "name": "Gerona Airport",
    "city": "Vilob\u00ed d'Onyar",
    "state": "Catalonia",
    "country": "Spain",
    "woeid": "23268768",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "469",
    "icao": "LEGE",
    "direct_flights": "63",
    "carriers": "6"
  },
  {
    "code": "GRQ",
    "lat": "53.1273",
    "lon": "6.58249",
    "name": "Groningen Eelde",
    "city": "Eelde",
    "state": "Drenthe",
    "country": "Netherlands",
    "woeid": "12515086",
    "tz": "Europe\/Amsterdam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "17",
    "icao": "EHGG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GRR",
    "lat": "42.8841",
    "lon": "-85.5307",
    "name": "Gerald R. Ford International Airport",
    "city": "Grand Rapids",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12520445",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "794",
    "icao": "KGRR",
    "direct_flights": "21",
    "carriers": "26"
  },
  {
    "code": "GRU",
    "lat": "-23.435",
    "lon": "-46.4728",
    "name": "Governador Andre Franco Montoro International Ai",
    "city": "Guarulhos",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511170",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12140",
    "elev": "2459",
    "icao": "SBGR",
    "direct_flights": "63",
    "carriers": "35"
  },
  {
    "code": "GRV",
    "lat": "43.3964",
    "lon": "45.6868",
    "name": "Groznyy Airport",
    "city": "Groznyy",
    "state": "Chechnya",
    "country": "Russia",
    "woeid": "12516015",
    "tz": "Europe\/Volgograd",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "GRW",
    "lat": "39.0936",
    "lon": "-28.0297",
    "name": "Graciosa Airport",
    "city": "Graciosa Island",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515444",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "86",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GRX",
    "lat": "37.1876",
    "lon": "-3.77498",
    "name": "Granada Airport",
    "city": "Chauchina",
    "state": "Andalucia",
    "country": "Spain",
    "woeid": "12517547",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9514",
    "elev": "1860",
    "icao": "",
    "direct_flights": "16",
    "carriers": "12"
  },
  {
    "code": "GRY",
    "lat": "66.5439",
    "lon": "-18.0117",
    "name": "Grimsey Airport",
    "city": "Akureyri",
    "state": "Akureyri",
    "country": "Iceland",
    "woeid": "12513443",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2559",
    "elev": "66",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GRZ",
    "lat": "46.9902",
    "lon": "15.4389",
    "name": "Graz Airport",
    "city": "Feldkirchen",
    "state": "Styria",
    "country": "Austria",
    "woeid": "22014356",
    "tz": "Europe\/Vienna",
    "phone": "+43(0)31629020",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flughafen-graz.at",
    "runway_length": "9055",
    "elev": "1115",
    "icao": "LOWG",
    "direct_flights": "29",
    "carriers": "18"
  },
  {
    "code": "GSE",
    "lat": "57.7774",
    "lon": "11.8648",
    "name": "Save Airport",
    "city": "Kyrkobyn",
    "state": "Vastra Gotaland",
    "country": "Sweden",
    "woeid": "12517675",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.goteborgcityairport.se\/",
    "runway_length": "6316",
    "elev": "66",
    "icao": "ESGP",
    "direct_flights": "14",
    "carriers": "3"
  },
  {
    "code": "GSM",
    "lat": "36.0347",
    "lon": "14.3106",
    "name": "",
    "city": "Gheshm",
    "state": "",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "GSO",
    "lat": "36.1038",
    "lon": "-79.9427",
    "name": "Triad International Airport",
    "city": "Greensboro",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12522200",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "926",
    "icao": "KGSO",
    "direct_flights": "24",
    "carriers": "26"
  },
  {
    "code": "GSP",
    "lat": "34.8907",
    "lon": "-82.2167",
    "name": "Greenville Spartanburg International Airport",
    "city": "Greer",
    "state": "South Carolina",
    "country": "United States",
    "woeid": "12520010",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7600",
    "elev": "972",
    "icao": "KGSP",
    "direct_flights": "25",
    "carriers": "25"
  },
  {
    "code": "GST",
    "lat": "58.4246",
    "lon": "-135.707",
    "name": "Gustavus Airport",
    "city": "Gustavus",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520042",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6800",
    "elev": "36",
    "icao": "PAGS",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "GSY",
    "lat": "53.4533",
    "lon": "-0.2",
    "name": "Binbrook Airport",
    "city": "Market Rasen",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518039",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "374",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GTA",
    "lat": "-8.72906",
    "lon": "162.855",
    "name": "Solomon Islands",
    "city": "Gatokae",
    "state": "",
    "country": "Solomon Islands",
    "woeid": "23424766",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GTE",
    "lat": "-13.9743",
    "lon": "136.462",
    "name": "Groote Eylandt Airport",
    "city": "Darwin",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "12510682",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6240",
    "elev": "52",
    "icao": "KGTE",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "GTF",
    "lat": "47.482",
    "lon": "-111.356",
    "name": "Great Falls International Airport",
    "city": "Great Falls",
    "state": "Montana",
    "country": "United States",
    "woeid": "12519974",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10502",
    "elev": "3674",
    "icao": "KGTF",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "GTO",
    "lat": "0.6339",
    "lon": "122.846",
    "name": "Jalaluddin Airport",
    "city": "Gorontalo",
    "state": "Sulawesi Utara",
    "country": "Indonesia",
    "woeid": "12513474",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5413",
    "elev": "60",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "GTR",
    "lat": "33.4516",
    "lon": "-88.5876",
    "name": "Golden Triangle Regional Airport",
    "city": "Columbus",
    "state": "Mississippi",
    "country": "United States",
    "woeid": "12519926",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6497",
    "elev": "264",
    "icao": "KGTR",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "GTS",
    "lat": "-31.875",
    "lon": "136.081",
    "name": "Australia",
    "city": "Granites",
    "state": "",
    "country": "Australia",
    "woeid": "23424748",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3615",
    "elev": "1106",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GUA",
    "lat": "14.5819",
    "lon": "-90.5275",
    "name": "La Aurora Airport",
    "city": "Guatemala City",
    "state": "Guatemala",
    "country": "Guatemala",
    "woeid": "12513336",
    "tz": "America\/Guatemala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9793",
    "elev": "4952",
    "icao": "MGGT",
    "direct_flights": "21",
    "carriers": "25"
  },
  {
    "code": "GUC",
    "lat": "38.5356",
    "lon": "-106.941",
    "name": "Gunnison County Airport",
    "city": "Gunnison",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12520039",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7198",
    "elev": "7668",
    "icao": "KGUC",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "GUM",
    "lat": "13.4833",
    "lon": "144.796",
    "name": "Antonio B Won Pat International Airport",
    "city": "Hag\u00e5t\u00f1a",
    "state": "Guam",
    "country": "United States",
    "woeid": "12523115",
    "tz": "Pacific\/Guam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PGUM",
    "direct_flights": "22",
    "carriers": "10"
  },
  {
    "code": "GUR",
    "lat": "-10.3108",
    "lon": "150.339",
    "name": "Gurney Airport",
    "city": "Alotau",
    "state": "Milne Bay",
    "country": "Papua New Guinea",
    "woeid": "12515467",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "65",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "GUW",
    "lat": "47.1133",
    "lon": "51.8457",
    "name": "Guryev Airport",
    "city": "Atyrau",
    "state": "Atyrau",
    "country": "Kazakhstan",
    "woeid": "12514338",
    "tz": "Asia\/Aqtobe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "6"
  },
  {
    "code": "GVA",
    "lat": "46.2329",
    "lon": "6.10682",
    "name": "Geneva Airport",
    "city": "Geneva",
    "state": "Canton of Geneva",
    "country": "Switzerland",
    "woeid": "23365569",
    "tz": "Europe\/Zurich",
    "phone": "+41 22 717 71 11",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.gva.ch",
    "runway_length": "12796",
    "elev": "1411",
    "icao": "LSGG",
    "direct_flights": "122",
    "carriers": "71"
  },
  {
    "code": "GVR",
    "lat": "-18.8958",
    "lon": "-41.9856",
    "name": "Governador Valadares Airport",
    "city": "Governador Valadares",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511160",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "561",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GWD",
    "lat": "25.2306",
    "lon": "62.3389",
    "name": "Gwadar Airport",
    "city": "Gawadar",
    "state": "Balochistan",
    "country": "Pakistan",
    "woeid": "12515236",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "96",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "GWL",
    "lat": "26.2843",
    "lon": "78.2288",
    "name": "Gwalior Airport",
    "city": "Gwalior",
    "state": "Madhya Pradesh",
    "country": "India",
    "woeid": "12513589",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "617",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "GWT",
    "lat": "54.9139",
    "lon": "8.33126",
    "name": "Westerland Airport",
    "city": "Westerland",
    "state": "Schleswig-Holstein",
    "country": "Germany",
    "woeid": "22294483",
    "tz": "Europe\/Berlin",
    "phone": "0049 4651 920612",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flughafen-sylt.de\/",
    "runway_length": "6968",
    "elev": "51",
    "icao": "",
    "direct_flights": "8",
    "carriers": "4"
  },
  {
    "code": "GWY",
    "lat": "53.3016",
    "lon": "-8.94049",
    "name": "Carnmore Airport",
    "city": "Carnmore",
    "state": "",
    "country": "Ireland",
    "woeid": "12512716",
    "tz": "Europe\/Dublin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1870",
    "elev": "90",
    "icao": "EICM",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "GXF",
    "lat": "15.9625",
    "lon": "48.7877",
    "name": "Sayun Airport",
    "city": "Seiyun",
    "state": "Hadramawt",
    "country": "Yemen",
    "woeid": "24554865",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3850",
    "elev": "2100",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "GXG",
    "lat": "-7.7547",
    "lon": "15.2919",
    "name": "Negage Airport",
    "city": "Negage",
    "state": "Uige",
    "country": "Angola",
    "woeid": "12510448",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "4105",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "GYA",
    "lat": "-10.8276",
    "lon": "-65.3993",
    "name": "Guayaramerin Airport",
    "city": "Guayaramer\u00edn",
    "state": "El Beni",
    "country": "Bolivia",
    "woeid": "12523574",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "427",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GYD",
    "lat": "40.3781",
    "lon": "49.8028",
    "name": "Azerbaijan",
    "city": "Baku",
    "state": "Baki",
    "country": "Azerbaijan",
    "woeid": "23424741",
    "tz": "Asia\/Baku",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UBBB",
    "direct_flights": "58",
    "carriers": "35"
  },
  {
    "code": "GYE",
    "lat": "-2.1533",
    "lon": "-79.8833",
    "name": "Simon Bolivar International Airport",
    "city": "Guayaquil",
    "state": "Guayas",
    "country": "Ecuador",
    "woeid": "12512652",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8005",
    "elev": "15",
    "icao": "SEGU",
    "direct_flights": "18",
    "carriers": "21"
  },
  {
    "code": "GYL",
    "lat": "-16.6364",
    "lon": "128.449",
    "name": "Argyle Airport",
    "city": "Argyle",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510589",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7540",
    "elev": "522",
    "icao": "KGYL",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "GYM",
    "lat": "27.9689",
    "lon": "-110.924",
    "name": "General Jose Maria Yanez in Airport",
    "city": "Guaymas",
    "state": "Sonora",
    "country": "Mexico",
    "woeid": "12514881",
    "tz": "America\/Hermosillo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7710",
    "elev": "88",
    "icao": "MMGM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "GYN",
    "lat": "-16.6294",
    "lon": "-49.2261",
    "name": "Santa Genoveva Airport",
    "city": "Goiania",
    "state": "Goias",
    "country": "Brazil",
    "woeid": "12511301",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7221",
    "elev": "2448",
    "icao": "",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "GZO",
    "lat": "-8.0749",
    "lon": "156.832",
    "name": "Nusatupe Airport",
    "city": "Gizo",
    "state": "Western",
    "country": "Solomon Islands",
    "woeid": "12511008",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3202",
    "elev": "13",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "GZT",
    "lat": "36.9481",
    "lon": "37.4783",
    "name": "Gaziantep Airport",
    "city": "O\u011fuzeli",
    "state": "Gaziantep",
    "country": "Turkey",
    "woeid": "12517892",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "2313",
    "icao": "",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "HAA",
    "lat": "70.4849",
    "lon": "22.1126",
    "name": "Hasvik Airport",
    "city": "Hasvik",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523933",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2362",
    "elev": "25",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HAC",
    "lat": "33.1153",
    "lon": "139.784",
    "name": "Hachijojima Airport",
    "city": "Hachijo-machi",
    "state": "Tokyo Prefecture",
    "country": "Japan",
    "woeid": "12513954",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5910",
    "elev": "303",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HAD",
    "lat": "56.6819",
    "lon": "12.8164",
    "name": "Halmstad Airport",
    "city": "Hamstad",
    "state": "Halland",
    "country": "Sweden",
    "woeid": "12517635",
    "tz": "Europe\/Stockholm",
    "phone": "+46 (0) 035-18 26 00",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.halmstadsflygplats.se\/",
    "runway_length": "7546",
    "elev": "101",
    "icao": "KHAD",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "HAE",
    "lat": "36.2306",
    "lon": "-112.669",
    "name": "Havasupai",
    "city": "Havasupai",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12794857",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KHAE",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HAH",
    "lat": "-11.5319",
    "lon": "43.2611",
    "name": "Moroni Hahaia Airport",
    "city": "Hahaia",
    "state": "Grande Comore",
    "country": "Comoros",
    "woeid": "12512357",
    "tz": "Indian\/Comoro",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9514",
    "elev": "89",
    "icao": "FMCH",
    "direct_flights": "9",
    "carriers": "6"
  },
  {
    "code": "HAJ",
    "lat": "52.4586",
    "lon": "9.69459",
    "name": "Hannover International Airport",
    "city": "Langenhagen",
    "state": "Lower Saxony",
    "country": "Germany",
    "woeid": "22235735",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "183",
    "icao": "EDDV",
    "direct_flights": "86",
    "carriers": "46"
  },
  {
    "code": "HAK",
    "lat": "20.0204",
    "lon": "110.348",
    "name": "Haikou Airport",
    "city": "Haikou",
    "state": "Hainan",
    "country": "China",
    "woeid": "12512065",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "31",
    "carriers": "17"
  },
  {
    "code": "HAM",
    "lat": "53.6321",
    "lon": "10.0042",
    "name": "Hamburg Airport",
    "city": "Hamburg",
    "state": "Hamburg",
    "country": "Germany",
    "woeid": "22211603",
    "tz": "Europe\/Berlin",
    "phone": "+49 (0)40\/5075-0",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hamburg-airport.de",
    "runway_length": "12024",
    "elev": "53",
    "icao": "EDDH",
    "direct_flights": "112",
    "carriers": "75"
  },
  {
    "code": "HAN",
    "lat": "21.2263",
    "lon": "105.815",
    "name": "Noi Bai Airport",
    "city": "Hanoi",
    "state": "Ha Noi",
    "country": "Vietnam",
    "woeid": "12522936",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "26",
    "icao": "VVNB",
    "direct_flights": "35",
    "carriers": "30"
  },
  {
    "code": "HAQ",
    "lat": "6.75785",
    "lon": "73.1472",
    "name": "Hanimadu",
    "city": "Hanimaadhoo",
    "state": "Haa Dhaalu",
    "country": "Maldives",
    "woeid": "12468497",
    "tz": "Indian\/Maldives",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "VRMH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HAS",
    "lat": "27.4433",
    "lon": "41.6872",
    "name": "Hail Airport",
    "city": "Ha'il",
    "state": "Hail",
    "country": "Saudi Arabia",
    "woeid": "12517339",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10827",
    "elev": "3331",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "HAU",
    "lat": "59.3436",
    "lon": "5.21246",
    "name": "Haugesund Karmoy Airport",
    "city": "Avaldsnes",
    "state": "Rogaland Fylke",
    "country": "Norway",
    "woeid": "12515110",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6957",
    "elev": "77",
    "icao": "ENHD",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "HAV",
    "lat": "22.9894",
    "lon": "-82.4075",
    "name": "Jose Marti International Airport",
    "city": "Wajay",
    "state": "Ciudad de la Habana",
    "country": "Cuba",
    "woeid": "12512466",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13123",
    "elev": "210",
    "icao": "MUHA",
    "direct_flights": "40",
    "carriers": "26"
  },
  {
    "code": "HBA",
    "lat": "-42.8375",
    "lon": "147.506",
    "name": "Hobart International Airport",
    "city": "Hobart",
    "state": "Tasmania",
    "country": "Australia",
    "woeid": "12510690",
    "tz": "Australia\/Hobart",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7386",
    "elev": "13",
    "icao": "YMHB",
    "direct_flights": "4",
    "carriers": "8"
  },
  {
    "code": "HBE",
    "lat": "30.9183",
    "lon": "29.6914",
    "name": "Borg El Arab International Airport",
    "city": "Alexandria",
    "state": "Al Iskandariyah",
    "country": "Egypt",
    "woeid": "12512663",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "HBT",
    "lat": "23.9176",
    "lon": "45.0811",
    "name": "Saudi Arabia",
    "city": "Hafr Albatin",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "23424938",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "1175",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HBX",
    "lat": "15.364",
    "lon": "75.1131",
    "name": "India",
    "city": "Hubli",
    "state": "Karnataka",
    "country": "India",
    "woeid": "23424848",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HCN",
    "lat": "22.0403",
    "lon": "120.735",
    "name": "Hengchun Airport",
    "city": "Hengchun",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "28760738",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HCR",
    "lat": "62.2016",
    "lon": "-159.77",
    "name": "Alaska",
    "city": "Holy Cross",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "85",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "HDB",
    "lat": "49.4167",
    "lon": "8.71667",
    "name": "Heidelberg Airport",
    "city": "Heidelberg",
    "state": "Baden-Wurttemberg",
    "country": "Germany",
    "woeid": "12523860",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "EDIU",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HDD",
    "lat": "25.3142",
    "lon": "68.3656",
    "name": "Hyderabad Airport",
    "city": "Hyderabad",
    "state": "Sindh",
    "country": "Pakistan",
    "woeid": "12515237",
    "tz": "Asia\/Karachi",
    "phone": "03012750377",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "130",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HDF",
    "lat": "53.8788",
    "lon": "14.1383",
    "name": "Heringsdorf",
    "city": "Heringsdorf",
    "state": "Mecklenburg-Vorpommern",
    "country": "Germany",
    "woeid": "12832291",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "HDM",
    "lat": "34.8692",
    "lon": "48.5522",
    "name": "Hamadan Airport",
    "city": "Hamadan",
    "state": "Hamadan",
    "country": "Iran",
    "woeid": "12513727",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7643",
    "elev": "5730",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HDN",
    "lat": "40.485",
    "lon": "-107.219",
    "name": "Yampa Valley Airport",
    "city": "Hayden",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12522528",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6999",
    "elev": "6597",
    "icao": "KHDN",
    "direct_flights": "9",
    "carriers": "8"
  },
  {
    "code": "HDS",
    "lat": "-24.3578",
    "lon": "31.0444",
    "name": "Hoedspruit Afs Airport",
    "city": "Phalaborwa",
    "state": "Limpopo",
    "country": "South Africa",
    "woeid": "12517422",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HDY",
    "lat": "6.9294",
    "lon": "100.399",
    "name": "Hat Yai International Airport",
    "city": "Hat Yai",
    "state": "Songkhla",
    "country": "Thailand",
    "woeid": "12517752",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10006",
    "elev": "91",
    "icao": "VTSS",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "HEA",
    "lat": "34.2069",
    "lon": "62.2267",
    "name": "Herat Airport",
    "city": "Herat",
    "state": "Herat",
    "country": "Afghanistan",
    "woeid": "12510304",
    "tz": "Asia\/Kabul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8525",
    "elev": "3206",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "HEH",
    "lat": "20.7439",
    "lon": "96.7944",
    "name": "Heho Airport",
    "city": "Heho",
    "state": "Shan State",
    "country": "Myanmar",
    "woeid": "12510914",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5700",
    "elev": "3858",
    "icao": "",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "HEI",
    "lat": "54.153",
    "lon": "8.89555",
    "name": "Heide-Busum Airport",
    "city": "Heide-Buesum",
    "state": "Schleswig-Holstein",
    "country": "Germany",
    "woeid": "22656458",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2362",
    "elev": "7",
    "icao": "KHEI",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HEK",
    "lat": "50.25",
    "lon": "127.3",
    "name": "Heihe Airport",
    "city": "Heihe",
    "state": "Heilongjiang",
    "country": "China",
    "woeid": "12523304",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HEL",
    "lat": "60.3243",
    "lon": "24.9688",
    "name": "Helsinki Vantaa Airport",
    "city": "Vantaa",
    "state": "Southern Finland",
    "country": "Finland",
    "woeid": "12512766",
    "tz": "Europe\/Helsinki",
    "phone": "+358 200 14636",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.helsinki-vantaa.fi\/",
    "runway_length": "11286",
    "elev": "179",
    "icao": "EFHK",
    "direct_flights": "104",
    "carriers": "53"
  },
  {
    "code": "HER",
    "lat": "35.3397",
    "lon": "25.1836",
    "name": "Iraklion Airport",
    "city": "Iraklio",
    "state": "Kriti",
    "country": "Greece",
    "woeid": "12513291",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8793",
    "elev": "115",
    "icao": "LGIR",
    "direct_flights": "50",
    "carriers": "28"
  },
  {
    "code": "HET",
    "lat": "40.8543",
    "lon": "111.821",
    "name": "Huhehaote Airport",
    "city": "Hohhot",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "12512080",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZBHH",
    "direct_flights": "29",
    "carriers": "14"
  },
  {
    "code": "HFA",
    "lat": "32.8111",
    "lon": "35.0417",
    "name": "U Michaeli Airport",
    "city": "Hefa",
    "state": "H'efa",
    "country": "Israel",
    "woeid": "12513795",
    "tz": "Asia\/Jerusalem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4183",
    "elev": "28",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HFE",
    "lat": "31.7712",
    "lon": "117.301",
    "name": "Hefei-Luogang Airport",
    "city": "Hefei",
    "state": "Anhui",
    "country": "China",
    "woeid": "12512070",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "26",
    "carriers": "10"
  },
  {
    "code": "HFS",
    "lat": "60.026",
    "lon": "13.5817",
    "name": "Hagfors Airport",
    "city": "Hagfors",
    "state": "",
    "country": "Sweden",
    "woeid": "12517632",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4954",
    "elev": "472",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HFT",
    "lat": "70.6808",
    "lon": "23.676",
    "name": "Hammerfest Airport",
    "city": "Hammerfest",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523934",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "266",
    "icao": "ENHF",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "HGA",
    "lat": "9.5186",
    "lon": "44.0903",
    "name": "Hargeisa Airport",
    "city": "Hargeysa",
    "state": "Woqooyi Galbeed",
    "country": "Somalia",
    "woeid": "12517530",
    "tz": "Africa\/Mogadishu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7480",
    "elev": "4423",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "HGD",
    "lat": "-20.8127",
    "lon": "144.225",
    "name": "Hughenden Aerodrome",
    "city": "Hughenden",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510695",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5400",
    "elev": "1043",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HGH",
    "lat": "30.3332",
    "lon": "120.234",
    "name": "Jianoiao Airport",
    "city": "Hangzhou",
    "state": "Zhejiang",
    "country": "China",
    "woeid": "12512090",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZSHC",
    "direct_flights": "46",
    "carriers": "28"
  },
  {
    "code": "HGL",
    "lat": "51.0908",
    "lon": "10.4542",
    "name": "Germany",
    "city": "Helgoland",
    "state": null,
    "country": "Germany",
    "woeid": "23424829",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1312",
    "elev": "8",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "HGN",
    "lat": "19.2989",
    "lon": "97.9764",
    "name": "Mae Hongson Airport",
    "city": "Mae Hong Son",
    "state": "Mae Hong Son",
    "country": "Thailand",
    "woeid": "12517764",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5433",
    "elev": "761",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "HGU",
    "lat": "-5.8272",
    "lon": "144.295",
    "name": "Mount Hagen Airport",
    "city": "Mount Hagen",
    "state": "Western Highlands",
    "country": "Papua New Guinea",
    "woeid": "12515475",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6180",
    "elev": "5388",
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "HHH",
    "lat": "32.2267",
    "lon": "-80.6989",
    "name": "Hilton Head Airport",
    "city": "Hilton Head Island",
    "state": "South Carolina",
    "country": "United States",
    "woeid": "12520191",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3700",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "HHN",
    "lat": "49.9454",
    "lon": "7.26851",
    "name": "Frankfurt-Hahn Airport",
    "city": "Dickenschied",
    "state": "Rheinland-Pfalz",
    "country": "Germany",
    "woeid": "22991139",
    "tz": "Europe\/Berlin",
    "phone": "+49 6543 5090",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hahn-airport.de",
    "runway_length": "3300",
    "elev": "1649",
    "icao": "EDFH",
    "direct_flights": "63",
    "carriers": "11"
  },
  {
    "code": "HHQ",
    "lat": "12.6281",
    "lon": "99.95",
    "name": "Hua Hin Airport",
    "city": "Hua Hin",
    "state": "Kalasin",
    "country": "Thailand",
    "woeid": "12517753",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HHZ",
    "lat": "-18.0363",
    "lon": "-142.616",
    "name": "French Polynesia",
    "city": "Hikueru",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "7",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HIB",
    "lat": "47.3934",
    "lon": "-92.8418",
    "name": "Chisholm Hibbing Airport",
    "city": "Hibbing",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12519189",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6750",
    "elev": "1352",
    "icao": "KHIB",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "HID",
    "lat": "-10.5858",
    "lon": "142.293",
    "name": "Horn Island",
    "city": "Horn Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12708481",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4550",
    "elev": "44",
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "HIJ",
    "lat": "34.4368",
    "lon": "132.922",
    "name": "Hiroshima Airport",
    "city": "Mihara-shi",
    "state": "Hiroshima Prefecture",
    "country": "Japan",
    "woeid": "28360532",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "15",
    "icao": "",
    "direct_flights": "12",
    "carriers": "12"
  },
  {
    "code": "HIL",
    "lat": "19.7192",
    "lon": "-155.082",
    "name": "",
    "city": "Shillavo",
    "state": "",
    "country": "Ethiopia",
    "woeid": "23424808",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HIN",
    "lat": "35.085",
    "lon": "128.072",
    "name": "Sacheon Airport",
    "city": "Sacheon-Si",
    "state": "Gyeongsangnam-Do",
    "country": "South Korea",
    "woeid": "12514226",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "25",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "HIR",
    "lat": "-9.4211",
    "lon": "160.053",
    "name": "Henderson Airport",
    "city": "Honiara",
    "state": "Guadalcanal",
    "country": "Solomon Islands",
    "woeid": "12511006",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6702",
    "elev": "32",
    "icao": "",
    "direct_flights": "23",
    "carriers": "7"
  },
  {
    "code": "HIS",
    "lat": "-20.0543",
    "lon": "148.884",
    "name": "Hayman Island Airport",
    "city": "Hayman Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510688",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "HJJ",
    "lat": "34.668",
    "lon": "104.166",
    "name": "Zhi Jiang",
    "city": "Zhi Jiang",
    "state": "Hunan",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HJR",
    "lat": "24.8496",
    "lon": "79.9367",
    "name": "Khajuraho Airport",
    "city": "Khajuraho",
    "state": "Madhya Pradesh",
    "country": "India",
    "woeid": "12513622",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "713",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "HKB",
    "lat": "63.8667",
    "lon": "-148.967",
    "name": "Healy Lake Airport",
    "city": "Healy Lake",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799776",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2640",
    "elev": "1294",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HKD",
    "lat": "41.7706",
    "lon": "140.824",
    "name": "Hakodate Airport",
    "city": "Hakodate-shi",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "12523120",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "138",
    "icao": "RJCH",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "HKG",
    "lat": "22.3124",
    "lon": "113.929",
    "name": "Hong Kong International Airport",
    "city": "Hong Kong",
    "state": "Hong Kong Island",
    "country": "Hong Kong",
    "woeid": "24875607",
    "tz": "Asia\/Hong_Kong",
    "phone": "+852 2188 7111",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hongkongairport.com\/",
    "runway_length": "12467",
    "elev": "19",
    "icao": "VHHH",
    "direct_flights": "131",
    "carriers": "87"
  },
  {
    "code": "HKK",
    "lat": "-42.7141",
    "lon": "170.989",
    "name": "Hokitika Airport",
    "city": "Hokitika",
    "state": "West Coast",
    "country": "New Zealand",
    "woeid": "12523121",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4311",
    "elev": "146",
    "icao": "NZHK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HKN",
    "lat": "-5.4628",
    "lon": "150.404",
    "name": "Hoskins Airport",
    "city": "Kimbe",
    "state": "West New Britain",
    "country": "Papua New Guinea",
    "woeid": "12515468",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "66",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "HKT",
    "lat": "8.1106",
    "lon": "98.3125",
    "name": "Phuket International Airport",
    "city": "Thalang",
    "state": "Phuket",
    "country": "Thailand",
    "woeid": "12517777",
    "tz": "Asia\/Bangkok",
    "phone": "076 327230-7",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportthai.co.th\/airportnew\/phuket\/html\/index.html",
    "runway_length": "8200",
    "elev": "69",
    "icao": "VTSP",
    "direct_flights": "19",
    "carriers": "38"
  },
  {
    "code": "HLA",
    "lat": "-25.9353",
    "lon": "27.925",
    "name": "Lanseria Airport",
    "city": "Johannesburg",
    "state": "Gauteng",
    "country": "South Africa",
    "woeid": "12517436",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "http:\/\/www.lanseria.co.za",
    "runway_length": "10000",
    "elev": "4517",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "HLD",
    "lat": "49.2",
    "lon": "119.7",
    "name": "Hailar",
    "city": "Hailar",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "2149763",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "HLH",
    "lat": "46.0935",
    "lon": "122.021",
    "name": "Ulanhot Airport",
    "city": "Ulan Hot",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "12512222",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "HLN",
    "lat": "46.6104",
    "lon": "-111.99",
    "name": "Helena Regional Airport",
    "city": "Helena",
    "state": "Montana",
    "country": "United States",
    "woeid": "12520154",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "3873",
    "icao": "KHLN",
    "direct_flights": "6",
    "carriers": "8"
  },
  {
    "code": "HLY",
    "lat": "53.3167",
    "lon": "-4.63333",
    "name": "Holyhead Airport",
    "city": "Holyhead",
    "state": "Wales",
    "country": "United Kingdom",
    "woeid": "12523988",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HLZ",
    "lat": "-37.8632",
    "lon": "175.328",
    "name": "Hamilton Airport",
    "city": "Hamilton",
    "state": "Waikato",
    "country": "New Zealand",
    "woeid": "12515155",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5600",
    "elev": "172",
    "icao": "NZHN",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "HMA",
    "lat": "61.0286",
    "lon": "69.0919",
    "name": "Khanty-Nansiysk",
    "city": "Khanty-Mansiysk",
    "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
    "country": "Russian Federation",
    "woeid": "2121314",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "17",
    "carriers": "3"
  },
  {
    "code": "HME",
    "lat": "31.6739",
    "lon": "6.1428",
    "name": "Oued Irara Airport",
    "city": "Ouargla",
    "state": "Ouargla",
    "country": "Algeria",
    "woeid": "12510340",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "463",
    "icao": "",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "HMO",
    "lat": "29.0967",
    "lon": "-111.047",
    "name": "Gen Ignacio P Garcia International Airport",
    "city": "Hermosillo",
    "state": "Sonora",
    "country": "Mexico",
    "woeid": "12514876",
    "tz": "America\/Hermosillo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "630",
    "icao": "MMHO",
    "direct_flights": "18",
    "carriers": "12"
  },
  {
    "code": "HMV",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Hemavan",
    "state": null,
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "HNA",
    "lat": "39.4315",
    "lon": "141.135",
    "name": "Hanamaki Airport",
    "city": "Hanamaki-shi",
    "state": "Iwate Prefecture",
    "country": "Japan",
    "woeid": "12513956",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "297",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "HND",
    "lat": "35.5533",
    "lon": "139.771",
    "name": "Tokyo International Airport",
    "city": "Tokyo",
    "state": "Tokyo Prefecture",
    "country": "Japan",
    "woeid": "23388311",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.tokyo-airport-bldg.co.jp\/",
    "runway_length": null,
    "elev": null,
    "icao": "KHND",
    "direct_flights": "55",
    "carriers": "19"
  },
  {
    "code": "HNH",
    "lat": "58.0966",
    "lon": "-135.41",
    "name": "Hoonah Airport",
    "city": "Hoonah",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29388566",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3100",
    "elev": "30",
    "icao": "PAOH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HNL",
    "lat": "21.3358",
    "lon": "-157.919",
    "name": "Honolulu International Airport",
    "city": "Honolulu",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520216",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12360",
    "elev": "13",
    "icao": "PHNL",
    "direct_flights": "43",
    "carriers": "36"
  },
  {
    "code": "HNM",
    "lat": "20.7944",
    "lon": "-156.015",
    "name": "Hana Airport",
    "city": "Hana",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520072",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3605",
    "elev": "77",
    "icao": "PHHN",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HNS",
    "lat": "59.2439",
    "lon": "-135.524",
    "name": "Haines Airport",
    "city": "Haines",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29388567",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4602",
    "elev": "16",
    "icao": "PAHN",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HOB",
    "lat": "32.6929",
    "lon": "-103.215",
    "name": "Lea County Regional Airport",
    "city": "Hobbs",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12520599",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7398",
    "elev": "3659",
    "icao": "KHOB",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HOD",
    "lat": "14.755",
    "lon": "42.9858",
    "name": "Hodeidah Airport",
    "city": "Al Hudaydah",
    "state": "Al Hudaydah",
    "country": "Yemen",
    "woeid": "12523003",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "49",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "HOE",
    "lat": "20.5",
    "lon": "103.75",
    "name": "Houeisay",
    "city": "Houeisay",
    "state": "Houaphan",
    "country": "Lao People's Democratic Republic",
    "woeid": "1140444",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KHOE",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HOF",
    "lat": "23.9176",
    "lon": "45.0811",
    "name": "Saudi Arabia",
    "city": "Alahsa",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "23424938",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6350",
    "elev": "517",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HOG",
    "lat": "20.7853",
    "lon": "-76.315",
    "name": "Holguin Airport",
    "city": "San Pedro de Cacocum",
    "state": "Holguin",
    "country": "Cuba",
    "woeid": "12512464",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10414",
    "elev": "348",
    "icao": "MUHG",
    "direct_flights": "8",
    "carriers": "6"
  },
  {
    "code": "HOH",
    "lat": "47.3833",
    "lon": "9.7",
    "name": "Hohenems Airport",
    "city": "Hohenems",
    "state": "Vorarlberg",
    "country": "Austria",
    "woeid": "12523771",
    "tz": "Europe\/Vienna",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2066",
    "elev": "1352",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "HOI",
    "lat": "-18.0753",
    "lon": "-140.946",
    "name": "Hao Airport",
    "city": "Papeete",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "12512822",
    "tz": "Pacific\/Gambier",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11089",
    "elev": "10",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "HOM",
    "lat": "59.6408",
    "lon": "-151.499",
    "name": "Homer Airport",
    "city": "Homer",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520209",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7401",
    "elev": "78",
    "icao": "PAHO",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "HON",
    "lat": "44.3833",
    "lon": "-98.2333",
    "name": "Howes",
    "city": "Huron",
    "state": "South Dakota",
    "country": "United States",
    "woeid": "12782876",
    "tz": "America\/Chicago",
    "phone": "605-353-8516",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/Huron Regional Airport",
    "runway_length": "7200",
    "elev": "1288",
    "icao": "KHON",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "HOQ",
    "lat": "50.2893",
    "lon": "11.866",
    "name": "Hof-Plauen Airport",
    "city": "Hof",
    "state": "Bavaria",
    "country": "Germany",
    "woeid": "22221018",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2690",
    "elev": "1926",
    "icao": "EDQM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HOR",
    "lat": "38.52",
    "lon": "-28.7172",
    "name": "Horta Airport",
    "city": "Horta",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515445",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "115",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "HOU",
    "lat": "29.6572",
    "lon": "-95.2795",
    "name": "William P Hobby Airport",
    "city": "Houston",
    "state": "Texas",
    "country": "United States",
    "woeid": "12522451",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7602",
    "elev": "47",
    "icao": "KHOU",
    "direct_flights": "33",
    "carriers": "12"
  },
  {
    "code": "HOV",
    "lat": "62.1804",
    "lon": "6.07624",
    "name": "Hovden Airport",
    "city": "Hovdebygda",
    "state": "More og Romsdal",
    "country": "Norway",
    "woeid": "12523936",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "243",
    "icao": "ENOV",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "HPA",
    "lat": "-19.8",
    "lon": "-174.35",
    "name": "Salote Pilolevu Airport",
    "city": "Pangai",
    "state": "Ha'apai",
    "country": "Tonga",
    "woeid": "12523124",
    "tz": "Pacific\/Tongatapu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "HPB",
    "lat": "61.5262",
    "lon": "-166.142",
    "name": "Hooper Bay Airport",
    "city": "Hooper Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520222",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3616",
    "elev": "18",
    "icao": "PAHP",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "HPH",
    "lat": "20.8192",
    "lon": "106.726",
    "name": "Hai Phong Cat Bi Airport",
    "city": "H\u00e4i Ph\u00f2ng",
    "state": "Hai Phong",
    "country": "Vietnam",
    "woeid": "12522913",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "HPN",
    "lat": "41.0693",
    "lon": "-73.7042",
    "name": "Westchester County Airport",
    "city": "Purchase",
    "state": "New York",
    "country": "United States",
    "woeid": "12522396",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6548",
    "elev": "439",
    "icao": "KHPN",
    "direct_flights": "16",
    "carriers": "13"
  },
  {
    "code": "HRB",
    "lat": "45.6281",
    "lon": "126.252",
    "name": "Harbin Yangjiagang Airport",
    "city": "Harbin",
    "state": "Heilongjiang",
    "country": "China",
    "woeid": "12512069",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZYHB",
    "direct_flights": "32",
    "carriers": "24"
  },
  {
    "code": "HRE",
    "lat": "-17.9228",
    "lon": "31.1014",
    "name": "Harare International Airport",
    "city": "Harare",
    "state": "Harare",
    "country": "Zimbabwe",
    "woeid": "12523026",
    "tz": "Africa\/Harare",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "15500",
    "elev": "4901",
    "icao": "FVHA",
    "direct_flights": "16",
    "carriers": "13"
  },
  {
    "code": "HRG",
    "lat": "27.1858",
    "lon": "33.7981",
    "name": "Hurghada Airport",
    "city": "Bor Safajah",
    "state": "Al Bahr al Ahmar",
    "country": "Egypt",
    "woeid": "12512692",
    "tz": "Africa\/Cairo",
    "phone": "+20 (0)65 442831",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "52",
    "icao": "HEGN",
    "direct_flights": "33",
    "carriers": "15"
  },
  {
    "code": "HRK",
    "lat": "49.9245",
    "lon": "36.2946",
    "name": "Kharkov Airport",
    "city": "Kharkiv",
    "state": "Kharkivs\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518282",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "499",
    "icao": "UKHH",
    "direct_flights": "7",
    "carriers": "10"
  },
  {
    "code": "HRL",
    "lat": "26.2216",
    "lon": "-97.663",
    "name": "Grande Valley International Airport",
    "city": "Harlingen",
    "state": "Texas",
    "country": "United States",
    "woeid": "12519957",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8300",
    "elev": "36",
    "icao": "KHRL",
    "direct_flights": "9",
    "carriers": "10"
  },
  {
    "code": "HRT",
    "lat": "54.0486",
    "lon": "-1.25333",
    "name": "Linton-On-Ouse",
    "city": "Harrogate",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "26353284",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6014",
    "elev": "53",
    "icao": "KHRT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HSG",
    "lat": "33.1508",
    "lon": "130.302",
    "name": "Saga Airport",
    "city": "Saga",
    "state": "Tokyo Prefecture",
    "country": "Japan",
    "woeid": "28360533",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6561",
    "elev": "7",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "HSL",
    "lat": "65.7024",
    "lon": "-156.387",
    "name": "Alaska",
    "city": "Huslia",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "180",
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "HSN",
    "lat": "29.935",
    "lon": "122.359",
    "name": "Zhoushan Airport",
    "city": "Shenjiamen Town",
    "state": "Zhejiang",
    "country": "China",
    "woeid": "2130999",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "HSV",
    "lat": "34.6492",
    "lon": "-86.7767",
    "name": "Huntsville International Airport",
    "city": "Huntsville",
    "state": "Alabama",
    "country": "United States",
    "woeid": "12519069",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "363",
    "icao": "KHSV",
    "direct_flights": "17",
    "carriers": "24"
  },
  {
    "code": "HTA",
    "lat": "52.1138",
    "lon": "113.498",
    "name": "Chita Airport",
    "city": "Chita",
    "state": "Chitinskaya Oblast",
    "country": "Russia",
    "woeid": "12515913",
    "tz": "Asia\/Yakutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "2274",
    "icao": "",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "HTG",
    "lat": "71.9751",
    "lon": "102.493",
    "name": "Hatanga Airport",
    "city": "Dudinka",
    "state": "Krasnoyarskiy Kray",
    "country": "Russia",
    "woeid": "12516026",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HTI",
    "lat": "-20.3563",
    "lon": "148.95",
    "name": "Hamilton Island Airport",
    "city": "Hamilton Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510686",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5984",
    "elev": "15",
    "icao": "YBHM",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "HTN",
    "lat": "40.5",
    "lon": "78.5",
    "name": "Hotan",
    "city": "Hotan",
    "state": "\u65b0\u7586\u7ef4\u543e\u5c14\u81ea\u6cbb\u533a",
    "country": "China",
    "woeid": "12714209",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "HTS",
    "lat": "38.37",
    "lon": "-82.5542",
    "name": "Tri State Walker Long Field Airport",
    "city": "Huntington",
    "state": "West Virginia",
    "country": "United States",
    "woeid": "12522198",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6509",
    "elev": "828",
    "icao": "KHTS",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "HUH",
    "lat": "-16.6867",
    "lon": "-151.019",
    "name": "Huahine Airport",
    "city": "Papeete",
    "state": "Leeward Islands",
    "country": "French Polynesia",
    "woeid": "12512823",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "7",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "HUI",
    "lat": "16.3988",
    "lon": "107.7",
    "name": "Hue-Phu Bai Airport",
    "city": "Hue",
    "state": "Thura Thien-Hue",
    "country": "Vietnam",
    "woeid": "12522918",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "49",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HUN",
    "lat": "24.0257",
    "lon": "121.613",
    "name": "Hwmlien Airport",
    "city": "Hualien City",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "12517939",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9098",
    "elev": "48",
    "icao": "RCYU",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "HUQ",
    "lat": "29.1117",
    "lon": "15.9661",
    "name": "Hon Airport",
    "city": "Houn",
    "state": "Al Jufrah",
    "country": "Libya",
    "woeid": "12514655",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5890",
    "elev": "837",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HUS",
    "lat": "66.0443",
    "lon": "-154.258",
    "name": "Hughes",
    "city": "Hughes",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2425379",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "289",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "HUV",
    "lat": "61.7683",
    "lon": "17.0855",
    "name": "Hudiksvall Airport",
    "city": "Hudiksvall",
    "state": "Gavleborg",
    "country": "Sweden",
    "woeid": "23322175",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3445",
    "elev": "91",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "HUX",
    "lat": "15.7739",
    "lon": "-96.2625",
    "name": "Bahias de Huatulco International Airport",
    "city": "Santo Domingo de Morelos",
    "state": "Oaxaca",
    "country": "Mexico",
    "woeid": "12514830",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "HUY",
    "lat": "53.5833",
    "lon": "-0.34925",
    "name": "Humberside International Airport",
    "city": "Ulceby",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22492354",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5098",
    "elev": "102",
    "icao": "",
    "direct_flights": "4",
    "carriers": "9"
  },
  {
    "code": "HUZ",
    "lat": "23.0957",
    "lon": "114.398",
    "name": "Huizhou",
    "city": "Huizhou",
    "state": "Guangdong",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "HVA",
    "lat": "-14.6289",
    "lon": "47.7667",
    "name": "Analalava Airport",
    "city": "Analalava",
    "state": "Mahajanga",
    "country": "Madagascar",
    "woeid": "12514687",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "312",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HVB",
    "lat": "-25.2839",
    "lon": "152.836",
    "name": "Hervey Bay Airport",
    "city": "Hervey Bay",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12523127",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4855",
    "elev": "60",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "HVD",
    "lat": "48.0167",
    "lon": "91.6333",
    "name": "Mongolia",
    "city": "Khovd",
    "state": "Hovd",
    "country": "Mongolia",
    "woeid": "23424887",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HVG",
    "lat": "71.0099",
    "lon": "25.9767",
    "name": "Valan Airport",
    "city": "Honningsv\u00e5g",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523938",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "46",
    "icao": "ENHV",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "HVN",
    "lat": "41.2709",
    "lon": "-72.89",
    "name": "Tweed New Haven Airport",
    "city": "East Haven",
    "state": "Connecticut",
    "country": "United States",
    "woeid": "12522229",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5600",
    "elev": "13",
    "icao": "KHVN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HVR",
    "lat": "48.5461",
    "lon": "-109.773",
    "name": "Havre City-County Airport",
    "city": "Havre",
    "state": "Montana",
    "country": "United States",
    "woeid": "12520127",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5205",
    "elev": "2588",
    "icao": "KHVR",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HYA",
    "lat": "41.6667",
    "lon": "-70.2863",
    "name": "Barnstable Boardman Polando Airport",
    "city": "Hyannis",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12518745",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5564",
    "elev": "52",
    "icao": "KHYA",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "HYD",
    "lat": "17.4522",
    "lon": "78.4629",
    "name": "Begumpet Airport",
    "city": "Hyderabad",
    "state": "Andhra Pradesh",
    "country": "India",
    "woeid": "12513597",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9082",
    "elev": "1741",
    "icao": "VOHY",
    "direct_flights": "34",
    "carriers": "28"
  },
  {
    "code": "HYG",
    "lat": "55.205",
    "lon": "-132.822",
    "name": "Alaska",
    "city": "Hydaburg",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HYL",
    "lat": "55.4833",
    "lon": "-132.65",
    "name": "SPB",
    "city": "Hollis",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799854",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "HYN",
    "lat": "60.8929",
    "lon": "15.9744",
    "name": "",
    "city": "Huangyan",
    "state": "Zhejiang",
    "country": "China",
    "woeid": "2132584",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "HYS",
    "lat": "38.8546",
    "lon": "-99.2737",
    "name": "Hays Municipal Airport",
    "city": "Hays",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12520139",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5700",
    "elev": "1998",
    "icao": "KHYS",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "HZG",
    "lat": "33.0677",
    "lon": "107.007",
    "name": "Hanzhong Airport",
    "city": "Hanzhong",
    "state": "Shaanxi",
    "country": "China",
    "woeid": "12512068",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "HZH",
    "lat": "32.7833",
    "lon": "106.667",
    "name": "Liping",
    "city": "Liping City",
    "state": "\u965d\u897f\u7701",
    "country": "China",
    "woeid": "12713981",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IAA",
    "lat": "67.4538",
    "lon": "86.4912",
    "name": "Russia",
    "city": "Igarka",
    "state": "Krasnoyarskiy Kray",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IAD",
    "lat": "38.9556",
    "lon": "-77.4484",
    "name": "Dulles International Airport",
    "city": "Washington",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12519544",
    "tz": "America\/New_York",
    "phone": "703-572-6001",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.metwashairports.com\/Dulles\/",
    "runway_length": "11501",
    "elev": "313",
    "icao": "KIAD",
    "direct_flights": "150",
    "carriers": "63"
  },
  {
    "code": "IAG",
    "lat": "43.1",
    "lon": "-78.9423",
    "name": "Niagara Falls International Airport",
    "city": "Niagara Falls",
    "state": "New York",
    "country": "United States",
    "woeid": "12521138",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "9125",
    "elev": "590",
    "icao": "KIAG",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "IAH",
    "lat": "29.9784",
    "lon": "-95.3424",
    "name": "George Bush Intercontinental Airport",
    "city": "Houston",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520245",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12001",
    "elev": "98",
    "icao": "KIAH",
    "direct_flights": "210",
    "carriers": "54"
  },
  {
    "code": "IAM",
    "lat": "28.0511",
    "lon": "9.6381",
    "name": "In Amenas Airport",
    "city": "Illizi",
    "state": "Illizi",
    "country": "Algeria",
    "woeid": "12510332",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "aeroport-iam@egsa-alger.com",
    "url": "http:\/\/www.egsa-alger.com\/index.php?id=89",
    "runway_length": "9843",
    "elev": "1847",
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "IAN",
    "lat": "66.9699",
    "lon": "-160.429",
    "name": "Kiana",
    "city": "Kiana",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2432151",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3400",
    "elev": "150",
    "icao": "PAIK",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "IAS",
    "lat": "47.1814",
    "lon": "27.617",
    "name": "Iasi North Airport",
    "city": "Ilasi",
    "state": "Iasi",
    "country": "Romania",
    "woeid": "12515544",
    "tz": "Europe\/Bucharest",
    "phone": "0232 278 510",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "394",
    "icao": "LRIA",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "IBA",
    "lat": "7.3608",
    "lon": "3.9781",
    "name": "Ibadan Airport",
    "city": "Ibadan",
    "state": "Oyo",
    "country": "Nigeria",
    "woeid": "12515065",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4537",
    "elev": "769",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IBE",
    "lat": "4.425",
    "lon": "-75.1394",
    "name": "Perales Airport",
    "city": "Ibague",
    "state": "Tolima",
    "country": "Colombia",
    "woeid": "12512402",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4987",
    "elev": "3044",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "IBZ",
    "lat": "38.8755",
    "lon": "1.36851",
    "name": "Ibiza Airport",
    "city": "San Jos\u00e9",
    "state": "Balearic Islands",
    "country": "Spain",
    "woeid": "12517548",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "23",
    "icao": "LEIB",
    "direct_flights": "57",
    "carriers": "30"
  },
  {
    "code": "ICI",
    "lat": "-17.7518",
    "lon": "-179.307",
    "name": "Cicia Airport",
    "city": "Cicia",
    "state": "Eastern",
    "country": "Fiji",
    "woeid": "12512810",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "13",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ICN",
    "lat": "37.4534",
    "lon": "126.657",
    "name": "New Incheon International Airport",
    "city": "Incheon",
    "state": "Incheon",
    "country": "South Korea",
    "woeid": "24554859",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport.or.kr\/eng\/airport\/",
    "runway_length": "12300",
    "elev": "6",
    "icao": "RKSI",
    "direct_flights": "139",
    "carriers": "76"
  },
  {
    "code": "ICT",
    "lat": "37.6531",
    "lon": "-97.43",
    "name": "Wichita Mid-Continent Airport",
    "city": "Wichita",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12522432",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7302",
    "elev": "1332",
    "icao": "KICT",
    "direct_flights": "14",
    "carriers": "22"
  },
  {
    "code": "IDA",
    "lat": "43.5147",
    "lon": "-112.068",
    "name": "Fanning Field Airport",
    "city": "Idaho Falls",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12519708",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9001",
    "elev": "4741",
    "icao": "KIDA",
    "direct_flights": "6",
    "carriers": "8"
  },
  {
    "code": "IDR",
    "lat": "22.7197",
    "lon": "75.807",
    "name": "Devi Ahilyabai Holkar International Airport",
    "city": "Indore",
    "state": "Madhya Pradesh",
    "country": "India",
    "woeid": "12513600",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7480",
    "elev": "1850",
    "icao": "VAID",
    "direct_flights": "9",
    "carriers": "4"
  },
  {
    "code": "IEG",
    "lat": "52.1282",
    "lon": "15.7975",
    "name": "Babimost Airport",
    "city": "Babimost",
    "state": "Lubusz",
    "country": "Poland",
    "woeid": "12515279",
    "tz": "Europe\/Warsaw",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "EPZG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IEV",
    "lat": "50.4069",
    "lon": "30.4456",
    "name": "Zhulyany Airport",
    "city": "Kiev",
    "state": "Kyyivs\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518501",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "574",
    "icao": "UKKK",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "IFJ",
    "lat": "66.0548",
    "lon": "-23.1301",
    "name": "Isafjordur Airport",
    "city": "Isafjordur",
    "state": "Isafjordur",
    "country": "Iceland",
    "woeid": "12513444",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IFN",
    "lat": "32.7514",
    "lon": "51.8639",
    "name": "Esfahan International Airport",
    "city": "Esfahan",
    "state": "Esfahan",
    "country": "Iran",
    "woeid": "12513720",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "5242",
    "icao": "",
    "direct_flights": "11",
    "carriers": "4"
  },
  {
    "code": "IFO",
    "lat": "48.8833",
    "lon": "24.6833",
    "name": "Ivano-Frankovsk Airport",
    "city": "Ivano-Frankivs'k",
    "state": "Ivano-Frankivs\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12524009",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "IFP",
    "lat": "35.1657",
    "lon": "-114.557",
    "name": "Laughlin-Bullhead International Airport",
    "city": "Bullhead City",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12518972",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KIFP",
    "direct_flights": "32",
    "carriers": "1"
  },
  {
    "code": "IGA",
    "lat": "20.9767",
    "lon": "-73.6656",
    "name": "Great Inagua Airport",
    "city": "Matthew Town",
    "state": "Inagua",
    "country": "Bahamas",
    "woeid": "12510870",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "8",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IGG",
    "lat": "59.3167",
    "lon": "-155.9",
    "name": "Igiugig",
    "city": "Igiugig",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520567",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2700",
    "elev": "110",
    "icao": "PAIG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IGM",
    "lat": "35.2692",
    "lon": "-113.957",
    "name": "Kingman Airport",
    "city": "Kingman",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12520468",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6831",
    "elev": "3446",
    "icao": "KIGM",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "IGR",
    "lat": "-25.7422",
    "lon": "-54.4753",
    "name": "Cataratas del Iguazu Airport",
    "city": "Puerto Esperanza",
    "state": "Misiones",
    "country": "Argentina",
    "woeid": "12510472",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10827",
    "elev": "915",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "IGS",
    "lat": "49.6622",
    "lon": "9.9275",
    "name": "Ingolstadt-Manching",
    "city": "Ingolstadt-Manching",
    "state": "Bavaria",
    "country": "Germany",
    "woeid": "663546",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IGU",
    "lat": "-25.5958",
    "lon": "-54.4864",
    "name": "Cataratas International Airport",
    "city": "Foz do Iguacu",
    "state": "Parana",
    "country": "Brazil",
    "woeid": "12511087",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "787",
    "icao": "SBFI",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "IHR",
    "lat": "32.4207",
    "lon": "53.6824",
    "name": "Iran",
    "city": "Iran Shahr",
    "state": "",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IIL",
    "lat": "32.4207",
    "lon": "53.6824",
    "name": "Iran",
    "city": "Ilaam",
    "state": "",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IJK",
    "lat": "56.8346",
    "lon": "53.2026",
    "name": "Russia",
    "city": "Izhevsk",
    "state": "Udmurtiya",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Europe\/Samara",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.izhavia.udm.ru",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "IKA",
    "lat": "35.5683",
    "lon": "51.4436",
    "name": "Imam Khomeini International Airport",
    "city": "Tehran",
    "state": "Tehran",
    "country": "Iran",
    "woeid": "2245727",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "OIIE",
    "direct_flights": "39",
    "carriers": "27"
  },
  {
    "code": "IKO",
    "lat": "52.9389",
    "lon": "-168.868",
    "name": "Nikolski",
    "city": "Nikolski",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2459970",
    "tz": "America\/Adak",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "73",
    "icao": "PAKO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IKS",
    "lat": "71.6969",
    "lon": "128.908",
    "name": "Tiksi Airport",
    "city": "Tiksi",
    "state": "",
    "country": "Russia",
    "woeid": "12517006",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IKT",
    "lat": "52.2604",
    "lon": "104.404",
    "name": "Irkutsk Southeast Airport",
    "city": "Irkutsk",
    "state": "Irkutskaya Oblast",
    "country": "Russia",
    "woeid": "12516044",
    "tz": "Asia\/Irkutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9022",
    "elev": "1641",
    "icao": "UUII",
    "direct_flights": "22",
    "carriers": "15"
  },
  {
    "code": "ILF",
    "lat": "56.0557",
    "lon": "-95.5897",
    "name": "Ilford Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524042",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "642",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ILI",
    "lat": "59.7555",
    "lon": "-154.918",
    "name": "Iliamna Airport",
    "city": "Iliamna",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29387741",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "207",
    "icao": "PAIL",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "ILM",
    "lat": "34.2668",
    "lon": "-77.912",
    "name": "Wilmington International Airport",
    "city": "Wilmington",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12521109",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flyilm.com",
    "runway_length": "7999",
    "elev": "32",
    "icao": "KILM",
    "direct_flights": "6",
    "carriers": "9"
  },
  {
    "code": "ILN",
    "lat": "39.4348",
    "lon": "-83.7982",
    "name": "Airborne Airpark",
    "city": "Wilmington",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12518539",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "1071",
    "icao": "KILN",
    "direct_flights": "71",
    "carriers": "4"
  },
  {
    "code": "ILO",
    "lat": "10.7144",
    "lon": "122.544",
    "name": "Iloilo Airport",
    "city": "Iloilo",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "12515621",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "24",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "ILP",
    "lat": "-22.609",
    "lon": "167.478",
    "name": "Moue Airport",
    "city": "Vao",
    "state": "Sud",
    "country": "New Caledonia",
    "woeid": "12515039",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3608",
    "elev": "315",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ILR",
    "lat": "8.4397",
    "lon": "4.4947",
    "name": "Ilorin Airport",
    "city": "Ilorin",
    "state": "Kwara",
    "country": "Nigeria",
    "woeid": "12515066",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10171",
    "elev": "1125",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ILY",
    "lat": "55.6835",
    "lon": "-6.24856",
    "name": "Islay Airport",
    "city": "Glenegedale",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22483897",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4458",
    "elev": "58",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "ILZ",
    "lat": "49.2333",
    "lon": "18.6179",
    "name": "Kotesovo Airport",
    "city": "Byt\u010da",
    "state": "Zilinsky",
    "country": "Slovakia",
    "woeid": "12514614",
    "tz": "Europe\/Prague",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "IMF",
    "lat": "24.7611",
    "lon": "93.8972",
    "name": "Imphal Airport",
    "city": "Lilong (Imphal West)",
    "state": "Manipur",
    "country": "India",
    "woeid": "12513598",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9022",
    "elev": "2542",
    "icao": "",
    "direct_flights": "6",
    "carriers": "5"
  },
  {
    "code": "IMK",
    "lat": "29.9667",
    "lon": "81.8333",
    "name": "Nepal",
    "city": "Simikot",
    "state": "Midwest",
    "country": "Nepal",
    "woeid": "23424911",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IMP",
    "lat": "-5.5303",
    "lon": "-47.4669",
    "name": "Prefeito Renato Moreira Airport",
    "city": "Imperatriz",
    "state": "Maranhao",
    "country": "Brazil",
    "woeid": "12511179",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5918",
    "elev": "430",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "IMT",
    "lat": "45.8153",
    "lon": "-88.1185",
    "name": "Ford Airport",
    "city": "Kingsford",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12519761",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "1182",
    "icao": "KIMT",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "INC",
    "lat": "38.4999",
    "lon": "106.146",
    "name": "Yinchuan Airport",
    "city": "Yinchuan",
    "state": "Ningxia",
    "country": "China",
    "woeid": "12512275",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "14",
    "carriers": "11"
  },
  {
    "code": "IND",
    "lat": "39.7322",
    "lon": "-86.2707",
    "name": "Indianapolis International Airport",
    "city": "Indianapolis",
    "state": "Indiana",
    "country": "United States",
    "woeid": "12520294",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10005",
    "elev": "797",
    "icao": "KIND",
    "direct_flights": "46",
    "carriers": "39"
  },
  {
    "code": "INH",
    "lat": "-23.8731",
    "lon": "35.4058",
    "name": "Inhambane Airport",
    "city": "Inhambane",
    "state": "Inhambane",
    "country": "Mozambique",
    "woeid": "12515018",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "30",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "INI",
    "lat": "43.3381",
    "lon": "21.8547",
    "name": "Nis Airport",
    "city": "Nis",
    "state": "Nishava",
    "country": "Serbia",
    "woeid": "12517587",
    "tz": "Europe\/Belgrade",
    "phone": "381 18 585858",
    "type": "Other Airport",
    "email": "",
    "url": "http:\/\/www.airportnis.co.yu",
    "runway_length": "7225",
    "elev": "648",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "INL",
    "lat": "48.5661",
    "lon": "-93.3978",
    "name": "Falls International Airport",
    "city": "International Falls",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12519705",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6508",
    "elev": "1185",
    "icao": "KINL",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "INN",
    "lat": "47.2591",
    "lon": "11.3566",
    "name": "Innsbruck Airport",
    "city": "Innsbruck",
    "state": "Tyrol",
    "country": "Austria",
    "woeid": "12510823",
    "tz": "Europe\/Vienna",
    "phone": "+43(0)512225250",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.innsbruck-airport.com",
    "runway_length": "6560",
    "elev": "1906",
    "icao": "LOWI",
    "direct_flights": "23",
    "carriers": "19"
  },
  {
    "code": "INT",
    "lat": "36.1361",
    "lon": "-80.2294",
    "name": "Smith Reynolds Airport",
    "city": "Winston-Salem",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12521891",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6655",
    "elev": "970",
    "icao": "KINT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "INU",
    "lat": "-0.54855",
    "lon": "166.921",
    "name": "Nauru International Airport",
    "city": "Yaren",
    "state": "Yaren",
    "country": "Nauru",
    "woeid": "12523130",
    "tz": "Pacific\/Nauru",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5620",
    "elev": "19",
    "icao": "ANYN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "INV",
    "lat": "57.5399",
    "lon": "-4.06352",
    "name": "Inverness Airport",
    "city": "Inverness",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22469954",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hial.co.uk\/inverness-airport.html",
    "runway_length": "6190",
    "elev": "31",
    "icao": "",
    "direct_flights": "14",
    "carriers": "6"
  },
  {
    "code": "INZ",
    "lat": "27.2453",
    "lon": "2.5164",
    "name": "In Salah Airport",
    "city": "In Salah",
    "state": "Tamanghasset",
    "country": "Algeria",
    "woeid": "12510334",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "883",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "IOA",
    "lat": "39.6967",
    "lon": "20.8258",
    "name": "Ioannina Airport",
    "city": "Ioannina",
    "state": "Ipiros",
    "country": "Greece",
    "woeid": "12513290",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "1600",
    "icao": "LGIO",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "IOM",
    "lat": "54.0819",
    "lon": "-4.6269",
    "name": "Ronaldsway Airport",
    "city": "Castletown",
    "state": "Isle of Man",
    "country": "United Kingdom",
    "woeid": "12513525",
    "tz": "Europe\/Isle_of_Man",
    "phone": "+44 1624 821600",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5751",
    "elev": "55",
    "icao": "EGNS",
    "direct_flights": "18",
    "carriers": "6"
  },
  {
    "code": "ION",
    "lat": "1.5953",
    "lon": "18.0522",
    "name": "Impfondo Airport",
    "city": "Impfondo",
    "state": "Likuoala",
    "country": "Congo",
    "woeid": "12511946",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1099",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IOS",
    "lat": "-14.8142",
    "lon": "-39.0331",
    "name": "Jorge Amado Airport",
    "city": "Ilh\u00e9us",
    "state": "Bahia",
    "country": "Brazil",
    "woeid": "12511178",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5164",
    "elev": "13",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "IPA",
    "lat": "-18.8134",
    "lon": "169.166",
    "name": "",
    "city": "Ipota",
    "state": "Tafea",
    "country": "Vanuatu",
    "woeid": "20069887",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3050",
    "elev": "36",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IPC",
    "lat": "-27.1167",
    "lon": "-109.367",
    "name": "Mataveri International Airport",
    "city": "Easter Island",
    "state": "Valparaiso",
    "country": "Chile",
    "woeid": "12523131",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9524",
    "elev": "226",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "IPH",
    "lat": "4.5669",
    "lon": "101.096",
    "name": "Ipoh Airport",
    "city": "Ipoh",
    "state": "Perak",
    "country": "Malaysia",
    "woeid": "12514993",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "128",
    "icao": "WMKI",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "IPI",
    "lat": "0.8611",
    "lon": "-77.6681",
    "name": "San Luis Airport",
    "city": "Ipiales",
    "state": "Narino",
    "country": "Colombia",
    "woeid": "12512410",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "9762",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IPL",
    "lat": "32.8332",
    "lon": "-115.57",
    "name": "Imperial County Airport",
    "city": "Imperial",
    "state": "California",
    "country": "United States",
    "woeid": "12520284",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5304",
    "elev": "-56",
    "icao": "KIPL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IPN",
    "lat": "-19.47",
    "lon": "-42.4875",
    "name": "Usiminas Airport",
    "city": "Santana do Para\u00edso",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511364",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6575",
    "elev": "784",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IPT",
    "lat": "41.2441",
    "lon": "-76.9276",
    "name": "Williamsport-Lycoming County Airport",
    "city": "Montoursville",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12522464",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6449",
    "elev": "529",
    "icao": "KIPT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IPW",
    "lat": "52.0285",
    "lon": "1.19325",
    "name": "Ipswich Airport",
    "city": "Ipswich",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "23388367",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "3445",
    "elev": "128",
    "icao": "EGSE",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "IQM",
    "lat": "38.1333",
    "lon": "85.5333",
    "name": "Qiemo",
    "city": "Qiemo",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "2143746",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IQN",
    "lat": "36.1",
    "lon": "107.783",
    "name": "",
    "city": "Qingyang",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "2137122",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IQQ",
    "lat": "-20.5356",
    "lon": "-70.1842",
    "name": "Diego Aracena International Airport",
    "city": "Alto Hospicio",
    "state": "Tarapaca",
    "country": "Chile",
    "woeid": "12512317",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8136",
    "elev": "157",
    "icao": "SCDA",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "IQT",
    "lat": "-3.7856",
    "lon": "-73.3103",
    "name": "Cnl Fap Fran Seca Vignetta Airport",
    "city": "Iquitos",
    "state": "Loreto",
    "country": "Peru",
    "woeid": "12515199",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "406",
    "icao": "SPQT",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "IRA",
    "lat": "-10.5",
    "lon": "161.833",
    "name": "Kirakira",
    "city": "Kirakira",
    "state": "Makira",
    "country": "Solomon Islands",
    "woeid": "1018638",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3969",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IRC",
    "lat": "65.8245",
    "lon": "-144.063",
    "name": "Circle",
    "city": "Circle",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2380412",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2730",
    "elev": "598",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IRJ",
    "lat": "-29.3847",
    "lon": "-66.7833",
    "name": "Capitan Vicente A Almonacid Airport",
    "city": "Chamical",
    "state": "La Rioja",
    "country": "Argentina",
    "woeid": "12510468",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9383",
    "elev": "1437",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IRM",
    "lat": "45.3517",
    "lon": "-89.6665",
    "name": "",
    "city": "Irma",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "2427520",
    "tz": "America\/Menominee",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ISA",
    "lat": "-20.6696",
    "lon": "139.488",
    "name": "Mount Isa Airport",
    "city": "Mount Isa",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510745",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8400",
    "elev": "1121",
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "ISB",
    "lat": "33.6147",
    "lon": "73.0967",
    "name": "Islamabad International Airport",
    "city": "R\u00e5walpindi",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "12515238",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.islamabadairport.com.pk\/",
    "runway_length": "9000",
    "elev": "1665",
    "icao": "KISB",
    "direct_flights": "31",
    "carriers": "18"
  },
  {
    "code": "ISC",
    "lat": "49.9167",
    "lon": "-6.3",
    "name": "Saint Mary's Airport",
    "city": "Rams Valley",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12523133",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1870",
    "elev": "116",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ISG",
    "lat": "24.3456",
    "lon": "124.186",
    "name": "Ishigaki Airport",
    "city": "Ishigaki-shi",
    "state": "Okinawa Prefecture",
    "country": "Japan",
    "woeid": "12513966",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "86",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ISN",
    "lat": "48.1767",
    "lon": "-103.631",
    "name": "Sloulin Field International Airport",
    "city": "Williston",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12521885",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6041",
    "elev": "1957",
    "icao": "KISN",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "ISO",
    "lat": "35.3243",
    "lon": "-77.6115",
    "name": "Kinston Jetport Stallings Airport",
    "city": "Kinston",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12520475",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "94",
    "icao": "KISO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ISP",
    "lat": "40.7891",
    "lon": "-73.0984",
    "name": "Long Island Macarthur Airport",
    "city": "Ronkonkoma",
    "state": "New York",
    "country": "United States",
    "woeid": "12520697",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5999",
    "elev": "99",
    "icao": "KISP",
    "direct_flights": "10",
    "carriers": "3"
  },
  {
    "code": "IST",
    "lat": "40.9857",
    "lon": "28.8163",
    "name": "Ataturk Hava Limani Airport",
    "city": "Bak\u0131rk\u00f6y",
    "state": "Istanbul",
    "country": "Turkey",
    "woeid": "23388365",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "157",
    "icao": "LTBA",
    "direct_flights": "157",
    "carriers": "79"
  },
  {
    "code": "ITH",
    "lat": "42.4898",
    "lon": "-76.4627",
    "name": "Tompkins County Airport",
    "city": "Ithaca",
    "state": "New York",
    "country": "United States",
    "woeid": "12522159",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5801",
    "elev": "1099",
    "icao": "KITH",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "ITM",
    "lat": "34.7857",
    "lon": "135.439",
    "name": "Osaka International Airport",
    "city": "Itami-shi",
    "state": "Hyogo Prefecture",
    "country": "Japan",
    "woeid": "12514009",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.osaka-airport.co.jp\/",
    "runway_length": "9840",
    "elev": "40",
    "icao": "RJOO",
    "direct_flights": "25",
    "carriers": "10"
  },
  {
    "code": "ITO",
    "lat": "19.7126",
    "lon": "-155.042",
    "name": "Hilo International Airport",
    "city": "Hilo",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520190",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9803",
    "elev": "38",
    "icao": "PHTO",
    "direct_flights": "3",
    "carriers": "9"
  },
  {
    "code": "IUE",
    "lat": "-19.0787",
    "lon": "-169.926",
    "name": "Niue",
    "city": "Niue Island",
    "state": "",
    "country": "Niue",
    "woeid": "23424904",
    "tz": "Pacific\/Niue",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5400",
    "elev": "192",
    "icao": "NIUE",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IVC",
    "lat": "-46.4124",
    "lon": "168.309",
    "name": "Invercargill Airport",
    "city": "Invercargill",
    "state": "Southland",
    "country": "New Zealand",
    "woeid": "12515156",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.invercargillairport.co.nz\/",
    "runway_length": "7250",
    "elev": "5",
    "icao": "NZNV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IVL",
    "lat": "68.6064",
    "lon": "27.4029",
    "name": "Ivalo Airport",
    "city": "Ivalo",
    "state": "Lapland",
    "country": "Finland",
    "woeid": "12512769",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "481",
    "icao": "EFIV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IVR",
    "lat": "-29.8892",
    "lon": "151.142",
    "name": "Inverell Airport",
    "city": "Inverell",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12523134",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6935",
    "elev": "2667",
    "icao": "YIVL",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IWD",
    "lat": "46.5208",
    "lon": "-90.1344",
    "name": "Gogebic-Iron County Airport",
    "city": "Ironwood",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12520317",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6501",
    "elev": "1230",
    "icao": "KIWD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IWJ",
    "lat": "-10.45",
    "lon": "150.333",
    "name": "Iwami Airport",
    "city": "Iwami",
    "state": "Tottori Prefecture",
    "country": "Japan",
    "woeid": "1117345",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "IXA",
    "lat": "23.89",
    "lon": "91.2422",
    "name": "Agartala Airport",
    "city": "Gandhigram",
    "state": "Tripura",
    "country": "India",
    "woeid": "12513527",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6152",
    "elev": "55",
    "icao": "VEAT",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "IXB",
    "lat": "26.7333",
    "lon": "88.3333",
    "name": "Bagdogra Airport",
    "city": "Bagdogra",
    "state": "West Bengal",
    "country": "India",
    "woeid": "12513539",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9270",
    "elev": "412",
    "icao": "VEBD",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "IXC",
    "lat": "30.6751",
    "lon": "76.7973",
    "name": "Chandigarh Airport",
    "city": "Bhabat",
    "state": "Chandigarh",
    "country": "India",
    "woeid": "12513566",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8950",
    "elev": "1012",
    "icao": "VICG",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "IXD",
    "lat": "25.4397",
    "lon": "81.7361",
    "name": "Bamrauli Airport",
    "city": "Allahabad",
    "state": "Uttar Pradesh",
    "country": "India",
    "woeid": "12513532",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7600",
    "elev": "322",
    "icao": "VIAL",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "IXE",
    "lat": "12.9812",
    "lon": "74.8992",
    "name": "Mangalore Airport",
    "city": "Mulur",
    "state": "Karnataka",
    "country": "India",
    "woeid": "12513631",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/mangaloreairport.com",
    "runway_length": "5800",
    "elev": "336",
    "icao": "VOML",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "IXG",
    "lat": "15.8581",
    "lon": "74.6189",
    "name": "Belgaum Airport",
    "city": "Kangrali",
    "state": "Karnataka",
    "country": "India",
    "woeid": "12513546",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5899",
    "elev": "2488",
    "icao": "VABM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IXI",
    "lat": "21.7866",
    "lon": "82.7948",
    "name": "India",
    "city": "Lilabari",
    "state": "",
    "country": "India",
    "woeid": "23424848",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5480",
    "elev": "333",
    "icao": "VELR",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "IXJ",
    "lat": "32.6917",
    "lon": "74.8375",
    "name": "Jammu Airport",
    "city": "Jammu Cantt",
    "state": "Jammu and Kashmir",
    "country": "India",
    "woeid": "12513606",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5556",
    "elev": "1029",
    "icao": "VIJU",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "IXL",
    "lat": "34.1667",
    "lon": "77.5833",
    "name": "Leh Airport",
    "city": "Leh",
    "state": "Jammu and Kashmir",
    "country": "India",
    "woeid": "12513626",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10100",
    "elev": "10682",
    "icao": "VILH",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "IXM",
    "lat": "9.83543",
    "lon": "78.0964",
    "name": "Madurai Airport",
    "city": "Harveypatti",
    "state": "Tamil Nadu",
    "country": "India",
    "woeid": "12513630",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5990",
    "elev": "459",
    "icao": "VOMD",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "IXR",
    "lat": "23.3066",
    "lon": "85.3353",
    "name": "Birsa Munda Airport",
    "city": "Ranchi",
    "state": "Jharkhand",
    "country": "India",
    "woeid": "12513662",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8900",
    "elev": "2147",
    "icao": "VERC",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "IXS",
    "lat": "24.9667",
    "lon": "92.5167",
    "name": "Kumbhirgram Airport",
    "city": "Tarapur",
    "state": "Assam",
    "country": "India",
    "woeid": "12513625",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5856",
    "elev": "353",
    "icao": "VEKU",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "IXU",
    "lat": "19.8698",
    "lon": "75.386",
    "name": "Aurangabad Airport",
    "city": "Aurangabad",
    "state": "Maharashtra",
    "country": "India",
    "woeid": "12513537",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1906",
    "icao": "VAAU",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "IXW",
    "lat": "22.8167",
    "lon": "86.1667",
    "name": "Sonari Airport",
    "city": "Jamshedpur",
    "state": "Jharkhand",
    "country": "India",
    "woeid": "12513608",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3480",
    "elev": "459",
    "icao": "VEJS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IXY",
    "lat": "23.1119",
    "lon": "70.1011",
    "name": "Kandla Airport",
    "city": "Gandhidham",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513616",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "96",
    "icao": "VAKE",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IXZ",
    "lat": "11.692",
    "lon": "92.7097",
    "name": "Vir Savarkar Airport",
    "city": "Port Blair",
    "state": "Andaman and Nicobar Islands",
    "country": "India",
    "woeid": "12513654",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "58",
    "icao": "VOPB",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "IYK",
    "lat": "35.6606",
    "lon": "-117.816",
    "name": "Inyokern Airport",
    "city": "Inyokern",
    "state": "California",
    "country": "United States",
    "woeid": "12520307",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7337",
    "elev": "2457",
    "icao": "KIYK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "IZO",
    "lat": "35.4131",
    "lon": "132.887",
    "name": "Izumo Airport",
    "city": "Hikawa-cho",
    "state": "Shimane Prefecture",
    "country": "Japan",
    "woeid": "12513969",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "7",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JAC",
    "lat": "43.6034",
    "lon": "-110.736",
    "name": "Jackson Hole Airport",
    "city": "Jackson",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12520335",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6299",
    "elev": "6444",
    "icao": "KJAC",
    "direct_flights": "7",
    "carriers": "9"
  },
  {
    "code": "JAI",
    "lat": "26.8165",
    "lon": "75.8128",
    "name": "Sanganer Airport",
    "city": "Jaipur",
    "state": "Rajasthan",
    "country": "India",
    "woeid": "12513603",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5955",
    "elev": "1278",
    "icao": "VIJP",
    "direct_flights": "14",
    "carriers": "12"
  },
  {
    "code": "JAL",
    "lat": "19.5833",
    "lon": "-96.8333",
    "name": "Jalapa Airport",
    "city": "Jalapa",
    "state": "",
    "country": "Mexico",
    "woeid": "12524377",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "2871",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "JAN",
    "lat": "32.3089",
    "lon": "-90.0733",
    "name": "Jackson International Airport",
    "city": "Pearl",
    "state": "Mississippi",
    "country": "United States",
    "woeid": "12520336",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8500",
    "elev": "346",
    "icao": "KJAN",
    "direct_flights": "16",
    "carriers": "15"
  },
  {
    "code": "JAV",
    "lat": "69.25",
    "lon": "-51",
    "name": "Ilulissat Airport",
    "city": "Ilulissat",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523844",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "JAX",
    "lat": "30.4914",
    "lon": "-81.676",
    "name": "Jacksonville International Airport",
    "city": "Jacksonville",
    "state": "Florida",
    "country": "United States",
    "woeid": "12520339",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "30",
    "icao": "KJAX",
    "direct_flights": "32",
    "carriers": "31"
  },
  {
    "code": "JCB",
    "lat": "-27.1722",
    "lon": "-51.5511",
    "name": "Joacaba Airport",
    "city": "Joa\u00e7aba",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12511200",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4134",
    "elev": "2546",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "JCH",
    "lat": "68.8333",
    "lon": "-51",
    "name": "Christianshab Airport",
    "city": "Qasigiannguit",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523838",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "JCK",
    "lat": "-20.669",
    "lon": "141.722",
    "name": "Julia Creek Aerodrome",
    "city": "Julia Creek",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510702",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "404",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JCU",
    "lat": "35.8879",
    "lon": "-5.31601",
    "name": "Ceuta Heliport",
    "city": "Ceuta",
    "state": "Ciudad Aut\u00f3noma de Ceuta",
    "country": "Spain",
    "woeid": "20089013",
    "tz": "Africa\/Ceuta",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JDF",
    "lat": "-21.7925",
    "lon": "-43.385",
    "name": "Francisco de Assis Airport",
    "city": "Juiz de Fora",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511151",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4275",
    "elev": "2989",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "JDH",
    "lat": "26.2564",
    "lon": "73.0546",
    "name": "Jodhpur Airport",
    "city": "Jodhpur",
    "state": "Rajasthan",
    "country": "India",
    "woeid": "12513611",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "717",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "JDO",
    "lat": "-7.2181",
    "lon": "-39.2706",
    "name": "Cariri Regional Airport",
    "city": "Juazeiro do Norte",
    "state": "Ceara",
    "country": "Brazil",
    "woeid": "12511080",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5965",
    "elev": "1342",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JDZ",
    "lat": "29.293",
    "lon": "117.203",
    "name": "Jingde Town",
    "city": "Jingdezhen",
    "state": "Jiangxi",
    "country": "China",
    "woeid": "2133709",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "JED",
    "lat": "21.706",
    "lon": "39.1386",
    "name": "King Abdul Aziz International Airport",
    "city": "Jeddah",
    "state": "Makka",
    "country": "Saudi Arabia",
    "woeid": "23388353",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12467",
    "elev": "48",
    "icao": "OEJN",
    "direct_flights": "106",
    "carriers": "54"
  },
  {
    "code": "JEF",
    "lat": "38.593",
    "lon": "-92.1652",
    "name": "Jefferson City Memorial Airport",
    "city": "Holts Summit",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12520356",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KJEF",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JEG",
    "lat": "68.7075",
    "lon": "-52.8069",
    "name": "Auisiait Airport",
    "city": "Aasiaat",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523840",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "JEJ",
    "lat": "7.58333",
    "lon": "168.917",
    "name": "Jeh",
    "city": "Jeh",
    "state": "Jabat",
    "country": "Marshall Islands",
    "woeid": "24549842",
    "tz": "Pacific\/Kwajalein",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JER",
    "lat": "49.205",
    "lon": "-2.19729",
    "name": "Jersey Airport",
    "city": "St. Peter",
    "state": "Channel Islands",
    "country": "United Kingdom",
    "woeid": "12514041",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.jerseyairport.com\/",
    "runway_length": "5597",
    "elev": "276",
    "icao": "KJER",
    "direct_flights": "30",
    "carriers": "16"
  },
  {
    "code": "JFK",
    "lat": "40.6437",
    "lon": "-73.79",
    "name": "John F Kennedy International Airport",
    "city": "Jamaica",
    "state": "New York",
    "country": "United States",
    "woeid": "12520380",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "14572",
    "elev": "13",
    "icao": "KJFK",
    "direct_flights": "233",
    "carriers": "100"
  },
  {
    "code": "JFR",
    "lat": "62",
    "lon": "-49.65",
    "name": "Paamiut",
    "city": "Paamiut",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "472981",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JGA",
    "lat": "22.4633",
    "lon": "70.0119",
    "name": "Jamnagar Airport",
    "city": "Jamnagar",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513607",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "69",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "JGC",
    "lat": "35.9621",
    "lon": "-112.133",
    "name": "Grand Canyon Heliport",
    "city": "Williams",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12523301",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "6580",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JGN",
    "lat": "39.8574",
    "lon": "98.3368",
    "name": "Jiayuguan Airport",
    "city": "Jiayuguan",
    "state": "Gansu",
    "country": "China",
    "woeid": "12512095",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "JGO",
    "lat": "69.25",
    "lon": "-53.55",
    "name": "Godhavn Airport",
    "city": "Qeqertarsuaq",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523841",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "JGS",
    "lat": "27.1093",
    "lon": "114.984",
    "name": "Ji An\/Jing Gang Shan",
    "city": "Ji An",
    "state": "Jiangxi",
    "country": "China",
    "woeid": "2133708",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "JHB",
    "lat": "1.6389",
    "lon": "103.671",
    "name": "Sultan Ismail Airport",
    "city": "Senai",
    "state": "Johor",
    "country": "Malaysia",
    "woeid": "12515010",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11004",
    "elev": "135",
    "icao": "",
    "direct_flights": "13",
    "carriers": "6"
  },
  {
    "code": "JHG",
    "lat": "22.0167",
    "lon": "100.8",
    "name": "Gasa",
    "city": "Jinghong",
    "state": "Yunnan",
    "country": "China",
    "woeid": "2160652",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "6"
  },
  {
    "code": "JHM",
    "lat": "20.9619",
    "lon": "-156.676",
    "name": "Kapalua West Maui Airport",
    "city": "Lahaina",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520422",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PHJH",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "JHS",
    "lat": "66.9273",
    "lon": "-53.6754",
    "name": "Holsteinsborg Airport",
    "city": "Sisimiut",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523843",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "JHW",
    "lat": "42.1528",
    "lon": "-79.2666",
    "name": "Chautauqua County-Jamestown Airport",
    "city": "Jamestown",
    "state": "New York",
    "country": "United States",
    "woeid": "12519155",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5299",
    "elev": "1724",
    "icao": "KJHW",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "JIB",
    "lat": "11.5467",
    "lon": "43.1592",
    "name": "Djibouti Ambouli Airport",
    "city": "Djibouti City",
    "state": "Djibouti",
    "country": "Djibouti",
    "woeid": "12512608",
    "tz": "Africa\/Djibouti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10302",
    "elev": "49",
    "icao": "HDAM",
    "direct_flights": "16",
    "carriers": "10"
  },
  {
    "code": "JIJ",
    "lat": "9.34587",
    "lon": "42.7955",
    "name": "Jijiga",
    "city": "Jijiga",
    "state": "Ogaden",
    "country": "Ethiopia",
    "woeid": "1317599",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "HAJJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JIK",
    "lat": "37.6176",
    "lon": "26.1614",
    "name": "Ikaria Island Airport",
    "city": "Evdilos",
    "state": "Voreio Aigaio",
    "country": "Greece",
    "woeid": "12523353",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JIM",
    "lat": "32.3834",
    "lon": "-81.8349",
    "name": "",
    "city": "Jimma",
    "state": "Kefa",
    "country": "Ethiopia",
    "woeid": "1317611",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "5500",
    "icao": "HAJM",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "JIU",
    "lat": "45.0377",
    "lon": "23.2697",
    "name": "",
    "city": "Jiujiang",
    "state": "Jiangxi",
    "country": "China",
    "woeid": "26198150",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZSJJ",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "JJN",
    "lat": "24.9",
    "lon": "118.583",
    "name": "Jinjiang",
    "city": "Jinjiang",
    "state": "Fujian",
    "country": "China",
    "woeid": "2139965",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZBDX",
    "direct_flights": "11",
    "carriers": "5"
  },
  {
    "code": "JJU",
    "lat": "60.7483",
    "lon": "-46.0317",
    "name": "Julianehab Heliport",
    "city": "Qaqortoq",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523845",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "BGQE",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "JKG",
    "lat": "57.7522",
    "lon": "14.0692",
    "name": "Jonkoping Airport",
    "city": "Jonkoping",
    "state": "Jonkoping",
    "country": "Sweden",
    "woeid": "12517643",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "741",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "JKH",
    "lat": "38.345",
    "lon": "26.1417",
    "name": "Chios Airport",
    "city": "Khios",
    "state": "Voreio Aigaio",
    "country": "Greece",
    "woeid": "12513287",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "16",
    "icao": "LGHI",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "JKL",
    "lat": "37.5917",
    "lon": "-83.315",
    "name": "Julian Carroll Airport",
    "city": "Jackson",
    "state": "Kentucky",
    "country": "United States",
    "woeid": "12520407",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KJKL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JLD",
    "lat": "55.8848",
    "lon": "12.8667",
    "name": "Landskrona Heliport",
    "city": "Landskrona",
    "state": "Skane",
    "country": "Sweden",
    "woeid": "12523971",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "180",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JLN",
    "lat": "37.147",
    "lon": "-94.5019",
    "name": "Joplin Regional Airport",
    "city": "Webb City",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12520400",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6503",
    "elev": "981",
    "icao": "KJLN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JLR",
    "lat": "23.1778",
    "lon": "80.0556",
    "name": "Jabalpur Airport",
    "city": "Bilpura",
    "state": "Madhya Pradesh",
    "country": "India",
    "woeid": "12513601",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "1621",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "JMK",
    "lat": "37.4383",
    "lon": "25.3472",
    "name": "Mikonos Airport",
    "city": "Mikonos",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513311",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "405",
    "icao": "",
    "direct_flights": "13",
    "carriers": "10"
  },
  {
    "code": "JMS",
    "lat": "46.9243",
    "lon": "-98.6788",
    "name": "Jamestown Municipal Airport",
    "city": "Jamestown",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12520348",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "1498",
    "icao": "KJMS",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "JMU",
    "lat": "46.8333",
    "lon": "130.35",
    "name": "Jiamusi",
    "city": "Jiamusi",
    "state": "Heilongjiang",
    "country": "China",
    "woeid": "2141175",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "JNB",
    "lat": "-26.1219",
    "lon": "28.2467",
    "name": "OR Tambo International Airport",
    "city": "Johannesburg",
    "state": "Gauteng",
    "country": "South Africa",
    "woeid": "12517424",
    "tz": "Africa\/Johannesburg",
    "phone": "+27 (0)11 921 6262",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airports.co.za\/home.asp?pid=1147",
    "runway_length": "14495",
    "elev": "5512",
    "icao": "FAJS",
    "direct_flights": "89",
    "carriers": "63"
  },
  {
    "code": "JNN",
    "lat": "60.153",
    "lon": "-45.2532",
    "name": "Nanortalik Airport",
    "city": "Nanortalik",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523847",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "JNS",
    "lat": "61.1427",
    "lon": "-45.4053",
    "name": "Narsaq Heliport",
    "city": "Narsaq",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523848",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JNU",
    "lat": "58.3598",
    "lon": "-134.583",
    "name": "Juneau International Airport",
    "city": "Juneau",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520409",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8456",
    "elev": "18",
    "icao": "PAJN",
    "direct_flights": "15",
    "carriers": "11"
  },
  {
    "code": "JNX",
    "lat": "37.1",
    "lon": "25.3833",
    "name": "Naxos Airport",
    "city": "Naxos",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12520392",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KJNX",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JNZ",
    "lat": "39.1",
    "lon": "121.717",
    "name": "Jinzhou",
    "city": "Jinzhou",
    "state": "Liaoning",
    "country": "China",
    "woeid": "2147999",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JOE",
    "lat": "62.6629",
    "lon": "29.6076",
    "name": "Joensuu Airport",
    "city": "Yl\u00e4mylly",
    "state": "Eastern Finland",
    "country": "Finland",
    "woeid": "12512772",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "391",
    "icao": "EFJO",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "JOG",
    "lat": "-7.7889",
    "lon": "110.43",
    "name": "Adisucipto Airport",
    "city": "Yogyakarta",
    "state": "Yogyakarta",
    "country": "Indonesia",
    "woeid": "12513451",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "350",
    "icao": "",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "JOI",
    "lat": "-26.223",
    "lon": "-48.8046",
    "name": "Lauro Carneiro de Loyola Airport",
    "city": "Joinville",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12511203",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5381",
    "elev": "12",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "JOL",
    "lat": "6.0553",
    "lon": "121.009",
    "name": "Jolo Airport",
    "city": "Jolo",
    "state": "Autonomous Region in Muslim Mind",
    "country": "Philippines",
    "woeid": "12515623",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4429",
    "elev": "79",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "JPA",
    "lat": "-7.1481",
    "lon": "-34.9506",
    "name": "Presidente Castro Pinto International Airport",
    "city": "Santa Rita",
    "state": "Paraiba",
    "country": "Brazil",
    "woeid": "12511287",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8251",
    "elev": "213",
    "icao": "SBJP",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "JPR",
    "lat": "-10.8703",
    "lon": "-61.8461",
    "name": "Ji Parana Airport",
    "city": "Ji-Paran\u00e1",
    "state": "Rondonia",
    "country": "Brazil",
    "woeid": "12511198",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "594",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "JQA",
    "lat": "71.8021",
    "lon": "-42.1772",
    "name": "Greenland",
    "city": "Qaarsut",
    "state": "Arkansas",
    "country": "Greenland",
    "woeid": "23424828",
    "tz": "America\/Indiana\/Tell_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "JRB",
    "lat": "40.7039",
    "lon": "-74.0129",
    "name": "Downtown Manhattan Heliport",
    "city": "New York",
    "state": "New York",
    "country": "United States",
    "woeid": "12523144",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KJRB",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "JRH",
    "lat": "26.7286",
    "lon": "94.1778",
    "name": "Jorhat Airport",
    "city": "Senchoa Gaon",
    "state": "Assam",
    "country": "India",
    "woeid": "12513612",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "284",
    "icao": "VEJT",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "JRO",
    "lat": "-3.4278",
    "lon": "37.0714",
    "name": "Kilimanjaro International Airport",
    "city": "Sanya",
    "state": "Kilimanjaro",
    "country": "Tanzania",
    "woeid": "12518016",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11814",
    "elev": "2932",
    "icao": "HTKJ",
    "direct_flights": "9",
    "carriers": "13"
  },
  {
    "code": "JSA",
    "lat": "26.8873",
    "lon": "70.8636",
    "name": "Jaisalmer Airport",
    "city": "Jaisalmer",
    "state": "Rajasthan",
    "country": "India",
    "woeid": "12513604",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9055",
    "elev": "887",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JSH",
    "lat": "35.2113",
    "lon": "26.1012",
    "name": "Sitia Airport",
    "city": "Seteia",
    "state": "Kriti",
    "country": "Greece",
    "woeid": "12523352",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2395",
    "elev": "402",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "JSI",
    "lat": "39.1806",
    "lon": "23.5056",
    "name": "Skiathos Airport",
    "city": "Skiathos",
    "state": "Thessalia",
    "country": "Greece",
    "woeid": "12513322",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5151",
    "elev": "53",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "JSR",
    "lat": "23.1833",
    "lon": "89.1644",
    "name": "Jessore Airport",
    "city": "Jessore",
    "state": "Khulna",
    "country": "Bangladesh",
    "woeid": "12510891",
    "tz": "Asia\/Dhaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "JST",
    "lat": "40.3208",
    "lon": "-78.8306",
    "name": "Johnstown Cambria County Airport",
    "city": "Johnstown",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12520393",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5486",
    "elev": "2284",
    "icao": "KJST",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JSU",
    "lat": "65.4177",
    "lon": "-52.9269",
    "name": "Maniitsoq Heliport",
    "city": "Maitsoq",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523850",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "JSY",
    "lat": "37.4236",
    "lon": "24.95",
    "name": "Syros Island Airport",
    "city": "Ano Siros",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12523331",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JTR",
    "lat": "36.4033",
    "lon": "25.4806",
    "name": "Santorini Airport",
    "city": "Emborion",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513321",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "130",
    "icao": "",
    "direct_flights": "13",
    "carriers": "9"
  },
  {
    "code": "JTY",
    "lat": "36.5551",
    "lon": "26.3533",
    "name": "Astypalaia Island Airport",
    "city": "Astypalaea",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12523355",
    "tz": "Europe\/Athens",
    "phone": "+30 2 436 1410",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3215",
    "elev": "154",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "JUB",
    "lat": "4.8722",
    "lon": "31.5931",
    "name": "Juba Airport",
    "city": "Juba",
    "state": "Bahr al Jabal",
    "country": "Sudan",
    "woeid": "12517599",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "1509",
    "icao": "",
    "direct_flights": "6",
    "carriers": "9"
  },
  {
    "code": "JUJ",
    "lat": "-24.3933",
    "lon": "-65.0889",
    "name": "Jujuy Airport",
    "city": "Santa Catalina",
    "state": "Jujuy",
    "country": "Argentina",
    "woeid": "12510508",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9678",
    "elev": "3022",
    "icao": "SASJ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JUL",
    "lat": "-15.4642",
    "lon": "-70.1544",
    "name": "Juliaca Airport",
    "city": "Juliaca",
    "state": "Puno",
    "country": "Peru",
    "woeid": "12515207",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13780",
    "elev": "12546",
    "icao": "SPJL",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "JUM",
    "lat": "40.6152",
    "lon": "-83.7891",
    "name": "",
    "city": "Jumla",
    "state": "Midwest",
    "country": "Nepal",
    "woeid": "2269118",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1631",
    "elev": "8500",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JUV",
    "lat": "72.8",
    "lon": "-56.15",
    "name": "Upernavik Heliport",
    "city": "Upernavik",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523851",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "BGUK",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "JUZ",
    "lat": "36.8945",
    "lon": "104.166",
    "name": "Juzhou",
    "city": "Juzhou",
    "state": "",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZUJZ",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "JVA",
    "lat": "-23.3548",
    "lon": "43.6631",
    "name": "Toliara",
    "city": "Ankavandra",
    "state": "Toliara",
    "country": "Madagascar",
    "woeid": "1364703",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "426",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JYR",
    "lat": "28.7244",
    "lon": "57.6683",
    "name": "Jiroft",
    "city": "Jiroft",
    "state": "Kerman",
    "country": "Iran",
    "woeid": "12513754",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KJYR",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "JYV",
    "lat": "62.401",
    "lon": "25.6748",
    "name": "Jyvaskyla Airport",
    "city": "Tikkakoski",
    "state": "Western Finland",
    "country": "Finland",
    "woeid": "12512773",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "459",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "JZH",
    "lat": "32.8369",
    "lon": "103.682",
    "name": "Jiu Zhai Huang Long",
    "city": "Song Pan",
    "state": "\u56db\u5ddd\u7701",
    "country": "China",
    "woeid": "12713656",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10499",
    "elev": "11311",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "KAB",
    "lat": "-16.5183",
    "lon": "28.8853",
    "name": "Kariba Airport",
    "city": "Kariba",
    "state": "Mashonaland West",
    "country": "Zimbabwe",
    "woeid": "12523030",
    "tz": "Africa\/Harare",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5446",
    "elev": "1706",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KAC",
    "lat": "37.0333",
    "lon": "41.0833",
    "name": "Kamishly Airport",
    "city": "Al Qamishli",
    "state": "Al H'asakah",
    "country": "Syria",
    "woeid": "12517703",
    "tz": "Asia\/Damascus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9020",
    "elev": "1480",
    "icao": "OSKL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KAD",
    "lat": "10.695",
    "lon": "7.3194",
    "name": "Kaduna Airport",
    "city": "Afaka",
    "state": "Kaduna",
    "country": "Nigeria",
    "woeid": "12515069",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "2000",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KAJ",
    "lat": "64.2845",
    "lon": "27.6802",
    "name": "Kajaani Airport",
    "city": "Paltaniemi",
    "state": "Oulu Province",
    "country": "Finland",
    "woeid": "12512774",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "468",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KAL",
    "lat": "64.3248",
    "lon": "-158.725",
    "name": "Kaltag",
    "city": "Kaltag",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2430534",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "120",
    "icao": "PAKV",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KAN",
    "lat": "12.0444",
    "lon": "8.5139",
    "name": "Kano Mallam Aminu International Airport",
    "city": "",
    "state": "Kano",
    "country": "Nigeria",
    "woeid": "12515072",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10827",
    "elev": "1565",
    "icao": "",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "KAO",
    "lat": "65.9941",
    "lon": "29.2214",
    "name": "Kuusamo Airport",
    "city": "Kuusamo",
    "state": "Oulu Province",
    "country": "Finland",
    "woeid": "12512780",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "866",
    "icao": "",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "KAT",
    "lat": "-35.0714",
    "lon": "173.277",
    "name": "Kaitaia Aerodrome",
    "city": "Kaitaia",
    "state": "Northland",
    "country": "New Zealand",
    "woeid": "12515157",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "270",
    "icao": "NZKT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KAW",
    "lat": "10.0472",
    "lon": "98.5408",
    "name": "Kawthaung Airport",
    "city": "Kawthaung",
    "state": "Ranong",
    "country": "Myanmar",
    "woeid": "12510916",
    "tz": "Asia\/Bangkok",
    "phone": "+955951018",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "180",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KAX",
    "lat": "-27.8667",
    "lon": "114.133",
    "name": "Kalbarri",
    "city": "Kalbarri",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12709172",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KBC",
    "lat": "66.2667",
    "lon": "-145.8",
    "name": "Birch Creek Airport",
    "city": "Birch Creek",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12518834",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "450",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KBL",
    "lat": "34.5642",
    "lon": "69.2094",
    "name": "Kabul International Airport",
    "city": "Kabul",
    "state": "Kabul",
    "country": "Afghanistan",
    "woeid": "12510306",
    "tz": "Asia\/Kabul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "5871",
    "icao": "OAKB",
    "direct_flights": "19",
    "carriers": "9"
  },
  {
    "code": "KBP",
    "lat": "50.3405",
    "lon": "30.9025",
    "name": "Borispol Airport",
    "city": "Kiev",
    "state": "Kyyivs\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518215",
    "tz": "Europe\/Kiev",
    "phone": "(+380 44) 490 47 77",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport-borispol.kiev.ua\/",
    "runway_length": "11483",
    "elev": "427",
    "icao": "UKBB",
    "direct_flights": "83",
    "carriers": "64"
  },
  {
    "code": "KBR",
    "lat": "6.1683",
    "lon": "102.293",
    "name": "Sultan Ismail Petra Airport",
    "city": "Kota Baharu",
    "state": "Kelantan",
    "country": "Malaysia",
    "woeid": "12515011",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "16",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KBV",
    "lat": "7.98237",
    "lon": "98.8406",
    "name": "Thailand",
    "city": "Krabi",
    "state": "Krabi",
    "country": "Thailand",
    "woeid": "23424960",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "8"
  },
  {
    "code": "KCA",
    "lat": "41.5667",
    "lon": "82.7333",
    "name": "Kuqa",
    "city": "Kuqa",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "2143659",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KCC",
    "lat": "56.014",
    "lon": "-132.826",
    "name": "Alaska",
    "city": "Coffman Cove",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KCF",
    "lat": "30.4419",
    "lon": "69.3597",
    "name": "Pakistan",
    "city": "Kadanwari",
    "state": "",
    "country": "Pakistan",
    "woeid": "23424922",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "239",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KCG",
    "lat": "56.3173",
    "lon": "-158.596",
    "name": "Chignik Fisheries Airport",
    "city": "Chignik Lagoon",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523152",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1280",
    "elev": "25",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KCH",
    "lat": "1.485",
    "lon": "110.342",
    "name": "Kuching Airport",
    "city": "Kuching",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12514997",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8250",
    "elev": "88",
    "icao": "",
    "direct_flights": "15",
    "carriers": "8"
  },
  {
    "code": "KCL",
    "lat": "56.3065",
    "lon": "-158.537",
    "name": "Chignik Lagoon Airport",
    "city": "Chignik Lagoon",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523153",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1700",
    "elev": "50",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KCM",
    "lat": "37.5907",
    "lon": "36.9414",
    "name": "Kahramanmaras Airport",
    "city": "Kahramanmara\u015f",
    "state": "Kahramanmara\u015f",
    "country": "Turkey",
    "woeid": "12523444",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KCQ",
    "lat": "56.3119",
    "lon": "-158.362",
    "name": "Chignik Lake Airport",
    "city": "Chignik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523154",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "50",
    "icao": "PAJC",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KCZ",
    "lat": "33.5468",
    "lon": "133.672",
    "name": "Kochi Airport",
    "city": "Nankoku-shi",
    "state": "Kochi Prefecture",
    "country": "Japan",
    "woeid": "12513978",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6658",
    "elev": "31",
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "KDH",
    "lat": "31.5069",
    "lon": "65.8475",
    "name": "Kandahar International Airport",
    "city": "Alaqadari Daman",
    "state": "Kandahar",
    "country": "Afghanistan",
    "woeid": "12510307",
    "tz": "Asia\/Kabul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "3312",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KDI",
    "lat": "-4.0811",
    "lon": "122.417",
    "name": "Wolter Monginsidi Airport",
    "city": "Kendari",
    "state": "Sulawesi Tenggara",
    "country": "Indonesia",
    "woeid": "12513524",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5413",
    "elev": "164",
    "icao": "",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "KDL",
    "lat": "59.0013",
    "lon": "22.8181",
    "name": "Kardla East Airport",
    "city": "Kerdlya",
    "state": "Hiiumaa",
    "country": "Estonia",
    "woeid": "12512735",
    "tz": "Europe\/Tallinn",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "EEKA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KDM",
    "lat": "6.56889",
    "lon": "-7.71056",
    "name": "Kaadedhdhoo",
    "city": "Kaadedhdhoo",
    "state": "Guiglo",
    "country": "Maldives",
    "woeid": "1345139",
    "tz": "Indian\/Maldives",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KDO",
    "lat": "5.22745",
    "lon": "73.0989",
    "name": "Kudadu",
    "city": "Kadhdhoo",
    "state": "Baa",
    "country": "Maldives",
    "woeid": "12509007",
    "tz": "Indian\/Maldives",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KDU",
    "lat": "35.3",
    "lon": "75.6333",
    "name": "Skardu Airport",
    "city": "Skardu",
    "state": "Jammu and Kashmir",
    "country": "Pakistan",
    "woeid": "12515269",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6600",
    "elev": "7600",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KDV",
    "lat": "-19.05",
    "lon": "178.217",
    "name": "Kandavu Airport",
    "city": "Kandavu",
    "state": "Eastern",
    "country": "Fiji",
    "woeid": "12523306",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2502",
    "elev": "6",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KEB",
    "lat": "41.0833",
    "lon": "-92.4583",
    "name": "Nanwalek",
    "city": "Nanwalek",
    "state": "Alaska",
    "country": "United States",
    "woeid": "23417038",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1800",
    "elev": "27",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KEF",
    "lat": "63.9853",
    "lon": "-22.6042",
    "name": "Keflavik International",
    "city": "Reykjavik",
    "state": "Keflavik",
    "country": "Iceland",
    "woeid": "12513445",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10058",
    "elev": "169",
    "icao": "BIKF",
    "direct_flights": "30",
    "carriers": "9"
  },
  {
    "code": "KEJ",
    "lat": "55.3227",
    "lon": "86.0229",
    "name": "Kemerovo Airport",
    "city": "Kemerovo",
    "state": "Kemerovskaya Oblast",
    "country": "Russia",
    "woeid": "12516123",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "KEK",
    "lat": "59.3516",
    "lon": "-157.48",
    "name": "Ekwok",
    "city": "Ekwok",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2397726",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2720",
    "elev": "130",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KEM",
    "lat": "65.7786",
    "lon": "24.5814",
    "name": "Kemi Airport",
    "city": "Kemi",
    "state": "Lapland",
    "country": "Finland",
    "woeid": "12512776",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "61",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KEP",
    "lat": "28",
    "lon": "81.6333",
    "name": "",
    "city": "Nepalganj",
    "state": "Midwest",
    "country": "Nepal",
    "woeid": "2269174",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3700",
    "elev": "600",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "KER",
    "lat": "30.2633",
    "lon": "56.9583",
    "name": "Kerman Airport",
    "city": "Kerman",
    "state": "Kerman",
    "country": "Iran",
    "woeid": "12513735",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12614",
    "elev": "5739",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "KET",
    "lat": "21.3014",
    "lon": "99.6378",
    "name": "Kengtung Airport",
    "city": "Keng Tung",
    "state": "Shan State",
    "country": "Myanmar",
    "woeid": "12510917",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4720",
    "elev": "2800",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KEW",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "",
    "city": "Keewaywin",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KFA",
    "lat": "16.5889",
    "lon": "-11.4053",
    "name": "Kiffa Airport",
    "city": "Kiffa",
    "state": "Assaba",
    "country": "Mauritania",
    "woeid": "12514802",
    "tz": "Africa\/Nouakchott",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "423",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KFP",
    "lat": "54.85",
    "lon": "-163.417",
    "name": "",
    "city": "False Pass",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2402200",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KGA",
    "lat": "-5.9",
    "lon": "22.4706",
    "name": "Kananga Airport",
    "city": "Kananga",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511960",
    "tz": "Africa\/Kinshasa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "2133",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KGC",
    "lat": "-35.7158",
    "lon": "137.521",
    "name": "Kingscote Airport",
    "city": "Kingscote",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "12510708",
    "tz": "Australia\/Adelaide",
    "phone": "08 8553 2015",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kangarooisland.sa.gov.au\/site\/page.cfm?u=213\/site\/pag",
    "runway_length": "4600",
    "elev": "24",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KGD",
    "lat": "54.8056",
    "lon": "21.3394",
    "name": "Kaliningradskaya Oblast",
    "city": "Kaliningrad",
    "state": "Kaliningradskaya Oblast",
    "country": "Russia",
    "woeid": "2346938",
    "tz": "Europe\/Kaliningrad",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "34",
    "carriers": "9"
  },
  {
    "code": "KGE",
    "lat": "5.88333",
    "lon": "116.75",
    "name": "Kagau",
    "city": "Kagau",
    "state": "Sabah",
    "country": "Solomon Islands",
    "woeid": "12488244",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KGF",
    "lat": "49.6671",
    "lon": "73.3303",
    "name": "Karaganda Airport",
    "city": "Qaraghandy",
    "state": "Qaraghandy",
    "country": "Kazakhstan",
    "woeid": "12514358",
    "tz": "Asia\/Almaty",
    "phone": "+7 (3212) 49-62-87",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport.karaganda.kz\/",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "KGI",
    "lat": "-30.7896",
    "lon": "121.461",
    "name": "Kalgoorlie Bolder Airport",
    "city": "Kalgoorlie",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510703",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5850",
    "elev": "1181",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KGK",
    "lat": "59.7266",
    "lon": "-157.26",
    "name": "Koliganek Airport",
    "city": "New Koliganek",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29388571",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2140",
    "elev": "240",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KGL",
    "lat": "-1.9678",
    "lon": "30.14",
    "name": "Kigali Airport",
    "city": "Kigali",
    "state": "Kigali Province",
    "country": "Rwanda",
    "woeid": "12517316",
    "tz": "Africa\/Kigali",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "4892",
    "icao": "HRYR",
    "direct_flights": "7",
    "carriers": "11"
  },
  {
    "code": "KGP",
    "lat": "62.1906",
    "lon": "74.5339",
    "name": "Kogalym International",
    "city": "Kogalym",
    "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
    "country": "Russian Federation",
    "woeid": "12516798",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8225",
    "elev": "220",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "KGS",
    "lat": "36.7956",
    "lon": "27.0917",
    "name": "Kos Airport",
    "city": "Antimacheia",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513301",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "410",
    "icao": "LGKO",
    "direct_flights": "29",
    "carriers": "10"
  },
  {
    "code": "KGX",
    "lat": "62.9053",
    "lon": "-160.067",
    "name": "Alaska",
    "city": "Grayling",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2400",
    "elev": "99",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KHG",
    "lat": "39.5415",
    "lon": "76.0176",
    "name": "Kashi Airport",
    "city": "Kashi",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "12512108",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KHH",
    "lat": "22.5698",
    "lon": "120.345",
    "name": "Kaohsiung International Airport",
    "city": "Kaohsiung City",
    "state": "Kaohsiung City",
    "country": "Taiwan",
    "woeid": "2306255",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10006",
    "elev": "30",
    "icao": "RCKH",
    "direct_flights": "14",
    "carriers": "15"
  },
  {
    "code": "KHI",
    "lat": "24.8984",
    "lon": "67.1518",
    "name": "Karachi Civil Airport",
    "city": "Karachi",
    "state": "Sindh",
    "country": "Pakistan",
    "woeid": "12515241",
    "tz": "Asia\/Karachi",
    "phone": "9248792",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.karachiairport.com",
    "runway_length": "10500",
    "elev": "100",
    "icao": "OPKC",
    "direct_flights": "44",
    "carriers": "29"
  },
  {
    "code": "KHM",
    "lat": "11.9848",
    "lon": "104.984",
    "name": "",
    "city": "Khamti",
    "state": "",
    "country": "Myanmar",
    "woeid": "23424763",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "600",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KHN",
    "lat": "28.6316",
    "lon": "115.931",
    "name": "Nanchang New Airport",
    "city": "Nanchang",
    "state": "Jiangxi",
    "country": "China",
    "woeid": "12512149",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "23",
    "carriers": "10"
  },
  {
    "code": "KHS",
    "lat": "26.1733",
    "lon": "56.2403",
    "name": "Khasab Airport",
    "city": "Khasab",
    "state": "Musandam",
    "country": "Oman",
    "woeid": "12514809",
    "tz": "Asia\/Muscat",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "100",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KHV",
    "lat": "48.5241",
    "lon": "135.171",
    "name": "Khabarovsk Northeast Airport",
    "city": "Khabarovsk",
    "state": "Khabarovskiy Kray",
    "country": "Russia",
    "woeid": "12516130",
    "tz": "Asia\/Sakhalin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13123",
    "elev": "240",
    "icao": "",
    "direct_flights": "31",
    "carriers": "16"
  },
  {
    "code": "KHY",
    "lat": "38.5556",
    "lon": "44.9958",
    "name": "Iran",
    "city": "Khoy",
    "state": "Azarbayjan-e Gharbi",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KHZ",
    "lat": "-17.6902",
    "lon": "-149.376",
    "name": "French Polynesia",
    "city": "Kauehi",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KID",
    "lat": "55.9241",
    "lon": "14.0819",
    "name": "Kristianstad Airport",
    "city": "Tollarp",
    "state": "Skane",
    "country": "Sweden",
    "woeid": "22435246",
    "tz": "Europe\/Stockholm",
    "phone": "+46 (0)44-238800",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kidairport.com",
    "runway_length": "6562",
    "elev": "76",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KIF",
    "lat": "53.0308",
    "lon": "-89.8335",
    "name": "Kingfisher Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524045",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KIH",
    "lat": "26.5267",
    "lon": "53.9817",
    "name": "Kish Island Airport",
    "city": "Bandar Abbas",
    "state": "Hormozgan",
    "country": "Iran",
    "woeid": "12513738",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12008",
    "elev": "96",
    "icao": "",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "KIJ",
    "lat": "37.9553",
    "lon": "139.113",
    "name": "Niigata Airport",
    "city": "Niigata-shi",
    "state": "Niigata Prefecture",
    "country": "Japan",
    "woeid": "12514001",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "23",
    "icao": "RJSN",
    "direct_flights": "13",
    "carriers": "12"
  },
  {
    "code": "KIK",
    "lat": "35.4681",
    "lon": "44.3536",
    "name": "Kirkuk Airport",
    "city": "Kirkuk",
    "state": "At Ta'mim",
    "country": "Iraq",
    "woeid": "12513909",
    "tz": "Asia\/Baghdad",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9250",
    "elev": "1148",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KIM",
    "lat": "-28.8014",
    "lon": "24.7639",
    "name": "B J Vorster Airport",
    "city": "Kimberley",
    "state": "Northern Cape",
    "country": "South Africa",
    "woeid": "12517394",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "3949",
    "icao": "FAKM",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KIN",
    "lat": "17.9305",
    "lon": "-76.7898",
    "name": "Norman Manley",
    "city": "Kingston",
    "state": "Kingston St John",
    "country": "Jamaica",
    "woeid": "12514045",
    "tz": "America\/Jamaica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8786",
    "elev": "10",
    "icao": "MKJP",
    "direct_flights": "16",
    "carriers": "15"
  },
  {
    "code": "KIR",
    "lat": "52.1767",
    "lon": "-9.5333",
    "name": "Kerry County Airport",
    "city": "Farranfore",
    "state": "",
    "country": "Ireland",
    "woeid": "12512722",
    "tz": "Europe\/Dublin",
    "phone": "++353 (0) 66 9764644",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kerryairport.ie\/",
    "runway_length": "2100",
    "elev": "77",
    "icao": "EIKY",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "KIS",
    "lat": "-0.0861",
    "lon": "34.7278",
    "name": "Kisumu Airport",
    "city": "Kisumu",
    "state": "Nyanza",
    "country": "Kenya",
    "woeid": "12514068",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6693",
    "elev": "3775",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "KIT",
    "lat": "36.261",
    "lon": "22.9993",
    "name": "Kithira Airport",
    "city": "Potamos Kythiron",
    "state": "Attiki",
    "country": "Greece",
    "woeid": "12513299",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2100",
    "elev": "950",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KIV",
    "lat": "46.9296",
    "lon": "28.9389",
    "name": "Kishinev Southeast Airport",
    "city": "Chisinau",
    "state": "Chisinau",
    "country": "Moldova",
    "woeid": "12514720",
    "tz": "Europe\/Chisinau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport.md",
    "runway_length": null,
    "elev": null,
    "icao": "LUKK",
    "direct_flights": "22",
    "carriers": "16"
  },
  {
    "code": "KIX",
    "lat": "34.4295",
    "lon": "135.244",
    "name": "Kansai International Airport",
    "city": "Tajiri-cho",
    "state": "Osaka Prefecture",
    "country": "Japan",
    "woeid": "12523041",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kansai-airport.or.jp",
    "runway_length": "11400",
    "elev": null,
    "icao": "RJBB",
    "direct_flights": "78",
    "carriers": "61"
  },
  {
    "code": "KJA",
    "lat": "56.1688",
    "lon": "92.4987",
    "name": "Yelovaya Airport",
    "city": "Kansk",
    "state": "Krasnoyarskiy Kray",
    "country": "Russia",
    "woeid": "12517249",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UNKL",
    "direct_flights": "45",
    "carriers": "12"
  },
  {
    "code": "KKA",
    "lat": "64.9311",
    "lon": "-161.16",
    "name": "Koyuk",
    "city": "Koyuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2433864",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "150",
    "icao": "PAKK",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "KKB",
    "lat": "58.1869",
    "lon": "-152.375",
    "name": "Kitoi Seaplane Base",
    "city": "Kitoi Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524650",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KKC",
    "lat": "16.465",
    "lon": "102.791",
    "name": "Khon Kaen Airport",
    "city": "Khon Kaen",
    "state": "Khon Kaen",
    "country": "Thailand",
    "woeid": "12517755",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6800",
    "elev": "629",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KKD",
    "lat": "-8.94828",
    "lon": "148.314",
    "name": "Northern",
    "city": "Kokoda",
    "state": "Northern",
    "country": "Papua New Guinea",
    "woeid": "2346584",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2800",
    "elev": "1269",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KKE",
    "lat": "-35.2584",
    "lon": "173.908",
    "name": "Bay of Islands Airport",
    "city": "Kerikeri",
    "state": "Northland",
    "country": "New Zealand",
    "woeid": "12515158",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3900",
    "elev": "492",
    "icao": "NZKK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KKH",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Kongiganak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "25",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "KKI",
    "lat": "60.9057",
    "lon": "-161.424",
    "name": "Akiachak",
    "city": "Akiachak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2352459",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "25",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KKJ",
    "lat": "33.839",
    "lon": "131.033",
    "name": "New Kitakyushu Airport",
    "city": "Kita Kyushu",
    "state": "Kagoshima Prefecture",
    "country": "Japan",
    "woeid": "23388303",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kitakyu-air.co.jp\/",
    "runway_length": "8202",
    "elev": "10",
    "icao": "",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "KKN",
    "lat": "69.726",
    "lon": "29.8958",
    "name": "Kirkenes Hoybuktmoen Airport",
    "city": "Hesseng",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12515111",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "299",
    "icao": "ENKR",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "KKR",
    "lat": "-17.6902",
    "lon": "-149.376",
    "name": "French Polynesia",
    "city": "Kaukura Atoll",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2300",
    "elev": "7",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KKU",
    "lat": "58.8167",
    "lon": "-158.55",
    "name": "Ekuk Airport",
    "city": "Clarks Point",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524622",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1200",
    "elev": "30",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KLG",
    "lat": "61.5382",
    "lon": "-160.314",
    "name": "Kalskag",
    "city": "Kalskag",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2430533",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "49",
    "icao": "PALG",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KLH",
    "lat": "16.6667",
    "lon": "74.3333",
    "name": "Kolhapur Airport",
    "city": "Kolhapur",
    "state": "Maharashtra",
    "country": "India",
    "woeid": "29230260",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "2001",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KLL",
    "lat": "59.1126",
    "lon": "-156.856",
    "name": "Levelock",
    "city": "Levelock",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2438614",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1915",
    "elev": "60",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KLN",
    "lat": "57.5383",
    "lon": "-153.98",
    "name": "Larsen Bay",
    "city": "Larsen Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "23510510",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "2377",
    "elev": "77",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KLO",
    "lat": "11.6828",
    "lon": "122.377",
    "name": "Kalib0 Airport",
    "city": "Kalibo",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "12515624",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "23",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "KLR",
    "lat": "56.678",
    "lon": "16.2856",
    "name": "Kalmar Airport",
    "city": "Kalmar",
    "state": "Kalmar",
    "country": "Sweden",
    "woeid": "12517646",
    "tz": "Europe\/Stockholm",
    "phone": "+46 (0)480 587 00",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kalmarairport.com",
    "runway_length": "6726",
    "elev": "21",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KLU",
    "lat": "46.6428",
    "lon": "14.3429",
    "name": "Klagenfurt Airport",
    "city": "Celovec",
    "state": "Carinthia",
    "country": "Austria",
    "woeid": "12510824",
    "tz": "Europe\/Vienna",
    "phone": "+43(0)463415000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.klagenfurt-airport.com",
    "runway_length": "8924",
    "elev": "1470",
    "icao": "LOWK",
    "direct_flights": "10",
    "carriers": "11"
  },
  {
    "code": "KLV",
    "lat": "50.2009",
    "lon": "12.9149",
    "name": "Karlovy Vary Airport",
    "city": "Carlsbad",
    "state": "Karlovarsk\u00fd",
    "country": "Czech Republic",
    "woeid": "12512532",
    "tz": "Europe\/Prague",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1552",
    "icao": "LKKV",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KLW",
    "lat": "55.5743",
    "lon": "-133.064",
    "name": "Klawock Seaplane Base",
    "city": "Klawock",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520485",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "50",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KLX",
    "lat": "37.0678",
    "lon": "22.0267",
    "name": "Kalamata Airport",
    "city": "Kalamae",
    "state": "Peloponnisos",
    "country": "Greece",
    "woeid": "12513292",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9894",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KMA",
    "lat": "-7.9636",
    "lon": "145.77",
    "name": "Kerema Airport",
    "city": "Kerema",
    "state": "Gulf",
    "country": "Papua New Guinea",
    "woeid": "12515470",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3100",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KMC",
    "lat": "57.1333",
    "lon": "-153.2",
    "name": "King Khalid Military",
    "city": "King Khalid Mil. City",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2433701",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KME",
    "lat": "-2.4614",
    "lon": "28.9092",
    "name": "Kamembe Airport",
    "city": "Kamembe",
    "state": "Province de l'Ouest",
    "country": "Rwanda",
    "woeid": "12517315",
    "tz": "Africa\/Kigali",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4944",
    "elev": "5220",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KMG",
    "lat": "24.966",
    "lon": "102.733",
    "name": "Wuchia Pa Airport",
    "city": "Kunming",
    "state": "Yunnan",
    "country": "China",
    "woeid": "12512231",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "6235",
    "icao": "ZPPP",
    "direct_flights": "62",
    "carriers": "27"
  },
  {
    "code": "KMI",
    "lat": "31.8771",
    "lon": "131.449",
    "name": "Miyazaki Airport",
    "city": "Miyazaki-shi",
    "state": "Miyazaki Prefecture",
    "country": "Japan",
    "woeid": "12513991",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6230",
    "elev": "20",
    "icao": "",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "KMJ",
    "lat": "32.8346",
    "lon": "130.849",
    "name": "Kumamoto Airport",
    "city": "Kikuyo-machi",
    "state": "Kumamoto Prefecture",
    "country": "Japan",
    "woeid": "12513981",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "642",
    "icao": "RJFT",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "KMO",
    "lat": "58.9817",
    "lon": "-159.058",
    "name": "Manokotak",
    "city": "Manokotak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2444918",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "107",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KMQ",
    "lat": "36.394",
    "lon": "136.408",
    "name": "Komatsu Airport",
    "city": "Komatsu-shi",
    "state": "Ishikawa Prefecture",
    "country": "Japan",
    "woeid": "12513980",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8860",
    "elev": "18",
    "icao": "",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "KMS",
    "lat": "6.7172",
    "lon": "-1.5911",
    "name": "Kumasi Airport",
    "city": "New Tafo",
    "state": "Ashanti",
    "country": "Ghana",
    "woeid": "12513023",
    "tz": "Africa\/Accra",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "942",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KMV",
    "lat": "23.1869",
    "lon": "94.0556",
    "name": "Kalemyo Airport",
    "city": "Kalemyo",
    "state": "Sagaing",
    "country": "Myanmar",
    "woeid": "12510915",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "499",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KMY",
    "lat": "57",
    "lon": "-154.167",
    "name": "Moser Bay",
    "city": "Moser Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799674",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KND",
    "lat": "-2.9225",
    "lon": "25.9139",
    "name": "Kindu Airport",
    "city": "Kindu",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511962",
    "tz": "Africa\/Lubumbashi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "1631",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KNF",
    "lat": "51.3667",
    "lon": "-0.283333",
    "name": "",
    "city": "Kings Lynn",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "25093",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KNG",
    "lat": "-2.29861",
    "lon": "134.059",
    "name": "West Irian Jaya",
    "city": "Kaimana",
    "state": "West Irian Jaya",
    "country": "Indonesia",
    "woeid": "28350157",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "16",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KNH",
    "lat": "24.4583",
    "lon": "118.376",
    "name": "Kinmen County",
    "city": "Kinmen",
    "state": "Fujian",
    "country": "Taiwan",
    "woeid": "28760735",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9845",
    "elev": "30",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "KNK",
    "lat": "59.4333",
    "lon": "-154.85",
    "name": "",
    "city": "Kakhonak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2430469",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "50",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KNQ",
    "lat": "-21",
    "lon": "164.675",
    "name": "",
    "city": "Kone",
    "state": "Nord",
    "country": "New Caledonia",
    "woeid": "1049645",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "23",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KNS",
    "lat": "-39.8797",
    "lon": "143.881",
    "name": "King Island Airport",
    "city": "King Island",
    "state": "Tasmania",
    "country": "Australia",
    "woeid": "12510706",
    "tz": "Australia\/Hobart",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "-10",
    "elev": "5200",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KNU",
    "lat": "26.4414",
    "lon": "80.3656",
    "name": "Kanpur Airport",
    "city": "Kanpur",
    "state": "Uttar Pradesh",
    "country": "India",
    "woeid": "12513617",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "405",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KNW",
    "lat": "59.4513",
    "lon": "-157.317",
    "name": "New Stuyahok",
    "city": "New Stuyahok",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2459005",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1800",
    "elev": "75",
    "icao": "PANW",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KNX",
    "lat": "-15.7756",
    "lon": "128.715",
    "name": "Kununurra Airport",
    "city": "Durack",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510710",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "144",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "KOA",
    "lat": "19.7334",
    "lon": "-156.039",
    "name": "Kailua-Kona International Airport",
    "city": "Kailua Kona",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520428",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "43",
    "icao": "PHKO",
    "direct_flights": "11",
    "carriers": "17"
  },
  {
    "code": "KOC",
    "lat": "32.4981",
    "lon": "-89.8925",
    "name": "",
    "city": "Koumac",
    "state": "Nord",
    "country": "New Caledonia",
    "woeid": "24551437",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4900",
    "elev": "39",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KOE",
    "lat": "-10.1739",
    "lon": "123.658",
    "name": "El Tari Airport",
    "city": "Kupang",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12513464",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "335",
    "icao": "",
    "direct_flights": "11",
    "carriers": "4"
  },
  {
    "code": "KOI",
    "lat": "58.9549",
    "lon": "-2.9026",
    "name": "Kirkwall Airport",
    "city": "Kirkwall",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22471196",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4698",
    "elev": "69",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "KOJ",
    "lat": "31.8",
    "lon": "130.718",
    "name": "Kagoshima Airport",
    "city": "Kirishima-shi",
    "state": "Kagoshima Prefecture",
    "country": "Japan",
    "woeid": "12513971",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "905",
    "icao": "RJFK",
    "direct_flights": "11",
    "carriers": "9"
  },
  {
    "code": "KOK",
    "lat": "63.7211",
    "lon": "23.1438",
    "name": "Kruunupyy Airport",
    "city": "Kruunupyy",
    "state": "Western Finland",
    "country": "Finland",
    "woeid": "12512778",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ilmailulaitos.fi\/airport_kruunupyy",
    "runway_length": "6890",
    "elev": "84",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KOP",
    "lat": "17.404",
    "lon": "104.771",
    "name": "Nakhon Phanom Airport",
    "city": "Nakhon Phanom",
    "state": "Nakhon Phanom",
    "country": "Thailand",
    "woeid": "12517766",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "579",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KOT",
    "lat": "63.0328",
    "lon": "-163.554",
    "name": "Kotlik",
    "city": "Kotlik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2433856",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2236",
    "elev": "5",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "KOU",
    "lat": "21.3602",
    "lon": "-157.919",
    "name": "",
    "city": "Koulamoutou",
    "state": "Ogooue-Lolo",
    "country": "Gabon",
    "woeid": "1324072",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6010",
    "elev": "1070",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KOW",
    "lat": "25.8224",
    "lon": "114.907",
    "name": "Ganzhou Airport",
    "city": "Ganzhou",
    "state": "Jiangxi",
    "country": "China",
    "woeid": "12512054",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "KOY",
    "lat": "47.902",
    "lon": "-112.265",
    "name": "Olga Bay",
    "city": "Olga Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KOZ",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Ouzinkie",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KPB",
    "lat": "56.3502",
    "lon": "-133.622",
    "name": "Point Baker Seaplane Base",
    "city": "Point Baker",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523156",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KPC",
    "lat": "65.2544",
    "lon": "-166.856",
    "name": "Port Clarence Coast Guard Station",
    "city": "Brevig Mission",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12521440",
    "tz": "America\/Nome",
    "phone": "907-642-3844",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "10",
    "icao": "PAPC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KPN",
    "lat": "59.9371",
    "lon": "-164.041",
    "name": "Kipnuk",
    "city": "Kipnuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2433000",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "2140",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "KPO",
    "lat": "35.9847",
    "lon": "129.419",
    "name": "Pohang Airport",
    "city": "Pohang-Si",
    "state": "Gyeongsangbuk-Do",
    "country": "South Korea",
    "woeid": "12514216",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "70",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KPR",
    "lat": "58.4833",
    "lon": "-152.583",
    "name": "Port Williams",
    "city": "Port Williams",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12485470",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KPV",
    "lat": "55.9122",
    "lon": "-159.154",
    "name": "Alaska",
    "city": "Perryville",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "25",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KPY",
    "lat": "58.743",
    "lon": "-154.96",
    "name": "Alaska",
    "city": "Port Bailey",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KQA",
    "lat": "54.1271",
    "lon": "-165.889",
    "name": "Akutan Airport",
    "city": "Akutan",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12518551",
    "tz": "America\/Adak",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KRF",
    "lat": "63.0478",
    "lon": "17.7633",
    "name": "Kramfors Airport",
    "city": "Nyland",
    "state": "Vasternorrland",
    "country": "Sweden",
    "woeid": "23322390",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "33",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KRI",
    "lat": "-6.65619",
    "lon": "145.859",
    "name": "Papua New Guinea",
    "city": "Kikori",
    "state": null,
    "country": "Papua New Guinea",
    "woeid": "23424926",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2310",
    "elev": "40",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "KRK",
    "lat": "50.0741",
    "lon": "19.8011",
    "name": "Balice Airport",
    "city": "Zabierz\u00f3w",
    "state": "Ma\u0142opolskie",
    "country": "Poland",
    "woeid": "12515280",
    "tz": "Europe\/Warsaw",
    "phone": "+48 12 2855120",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lotnisko-balice.pl\/",
    "runway_length": "7874",
    "elev": "790",
    "icao": "EPKK",
    "direct_flights": "45",
    "carriers": "29"
  },
  {
    "code": "KRL",
    "lat": "41.7333",
    "lon": "86.15",
    "name": "Korla",
    "city": "Korla",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "2143697",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KRN",
    "lat": "67.8239",
    "lon": "20.3389",
    "name": "Kiruna Airport",
    "city": "Kiruna",
    "state": "Norrbotten",
    "country": "Sweden",
    "woeid": "12517650",
    "tz": "Europe\/Stockholm",
    "phone": "+46 (0)980 121 90",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lfv.se\/templates\/LFV_AirportStartPage____2187.aspx",
    "runway_length": "6562",
    "elev": "1507",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "KRP",
    "lat": "56.3028",
    "lon": "9.1141",
    "name": "Karup Airport",
    "city": "K\u00e5rup",
    "state": "Midtjylland",
    "country": "Denmark",
    "woeid": "12512592",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.karup-airport.dk\/",
    "runway_length": "9623",
    "elev": "170",
    "icao": "EKKA",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KRR",
    "lat": "45.0441",
    "lon": "39.1442",
    "name": "Krasnodar-Pashovskiy Airport",
    "city": "Krasnodar",
    "state": "Krasnodarskiy Kray",
    "country": "Russia",
    "woeid": "12516256",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "28",
    "carriers": "20"
  },
  {
    "code": "KRS",
    "lat": "58.1988",
    "lon": "8.07792",
    "name": "Kristiansand Airport",
    "city": "Kjevic",
    "state": "Vest-Agder Fylke",
    "country": "Norway",
    "woeid": "12515113",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6135",
    "elev": "57",
    "icao": "ENCN",
    "direct_flights": "7",
    "carriers": "10"
  },
  {
    "code": "KRT",
    "lat": "15.5917",
    "lon": "32.5533",
    "name": "Khartoum Airport",
    "city": "Khartoum",
    "state": "Al Khartum",
    "country": "Sudan",
    "woeid": "12517601",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "1261",
    "icao": "",
    "direct_flights": "31",
    "carriers": "31"
  },
  {
    "code": "KRY",
    "lat": "45.5726",
    "lon": "84.8891",
    "name": "Karamay Airport",
    "city": "Karamay",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "12512107",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KSA",
    "lat": "5.3183",
    "lon": "162.97",
    "name": "Kosrae Island Airport",
    "city": "Tofol",
    "state": "Kosrae",
    "country": "Federated States of Micronesia",
    "woeid": "12512815",
    "tz": "Pacific\/Ponape",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "6",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KSC",
    "lat": "48.6548",
    "lon": "21.2487",
    "name": "Barca Airport",
    "city": "Kosice",
    "state": "Kosicky",
    "country": "Slovakia",
    "woeid": "12514604",
    "tz": "Europe\/Prague",
    "phone": "+421 (0) 55 6832-123",
    "type": "Airports",
    "email": "sekretariat@airportkosice.sk",
    "url": "http:\/\/www.airportkosice.sk\/",
    "runway_length": "3100",
    "elev": "771",
    "icao": "LZKZ",
    "direct_flights": "11",
    "carriers": "10"
  },
  {
    "code": "KSD",
    "lat": "59.444",
    "lon": "13.3435",
    "name": "Karlstad Airport",
    "city": "Karlstad",
    "state": "Varmland",
    "country": "Sweden",
    "woeid": "22656500",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lfv.se\/templates\/LFV_AirportStartPage____4490.aspx",
    "runway_length": "5184",
    "elev": "152",
    "icao": "",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "KSF",
    "lat": "51.4066",
    "lon": "9.37722",
    "name": "Kassel Calden Airport",
    "city": "Kalden",
    "state": "Hesse",
    "country": "Germany",
    "woeid": "22180427",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flughafenkassel.de",
    "runway_length": "5495",
    "elev": "907",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KSH",
    "lat": "34.3489",
    "lon": "47.1572",
    "name": "Bakhtaran Airport",
    "city": "Kermanshah",
    "state": "Kermanshah",
    "country": "Iran",
    "woeid": "12513702",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "4307",
    "icao": "OICC",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KSJ",
    "lat": "35.3903",
    "lon": "26.9371",
    "name": "Kasos Airport",
    "city": "St. Marina",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513294",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2493",
    "elev": "33",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KSL",
    "lat": "15.3833",
    "lon": "36.3236",
    "name": "Kassala Airport",
    "city": "Kassala",
    "state": "Kassala",
    "country": "Sudan",
    "woeid": "12517600",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "1667",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KSM",
    "lat": "62.0503",
    "lon": "-163.179",
    "name": "Alaska",
    "city": "Saint Marys",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "311",
    "icao": "PASM",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "KSN",
    "lat": "53.2297",
    "lon": "63.6038",
    "name": "Kustanay Airport",
    "city": "Qostanay",
    "state": "Qostanay",
    "country": "Kazakhstan",
    "woeid": "12514397",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "EKSN",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "KSO",
    "lat": "40.4508",
    "lon": "21.2733",
    "name": "Kastoria Airport",
    "city": "Argos Orestiko",
    "state": "Dytiki Makedonia",
    "country": "Greece",
    "woeid": "12513296",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5938",
    "elev": "2183",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KSQ",
    "lat": "38.8042",
    "lon": "65.7717",
    "name": "Karshi South Airport",
    "city": "Qarshi",
    "state": "Qashqadaryo",
    "country": "Uzbekistan",
    "woeid": "12522643",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KSU",
    "lat": "63.1137",
    "lon": "7.82585",
    "name": "Kristiansund Kvernberget Airport",
    "city": "Kristiansund Nord",
    "state": "More og Romsdal",
    "country": "Norway",
    "woeid": "12515114",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.avinor.no\/norsk\/lufthavner\/kristiansund_lufthavn,_kve",
    "runway_length": "5774",
    "elev": "204",
    "icao": "ENKB",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KSY",
    "lat": "40.5828",
    "lon": "43.0675",
    "name": "Kars (abandoned) Airport",
    "city": "Kars",
    "state": "Kars",
    "country": "Turkey",
    "woeid": "12517901",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "KSZ",
    "lat": "61.2165",
    "lon": "46.6936",
    "name": "Kotlas Southeast Airport",
    "city": "Vel'sk",
    "state": "Arkhangelrskaya Oblast",
    "country": "Russia",
    "woeid": "12516238",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KTA",
    "lat": "-20.7108",
    "lon": "116.776",
    "name": "Karratha Airport",
    "city": "Karratha",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510704",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "29",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "KTB",
    "lat": "55.6833",
    "lon": "-132.529",
    "name": "Alaska",
    "city": "Thorne Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KTE",
    "lat": "4.5378",
    "lon": "103.428",
    "name": "Kerteh Airport",
    "city": "Kerteh",
    "state": "Terengganu",
    "country": "Malaysia",
    "woeid": "12514994",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "18",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KTM",
    "lat": "27.6969",
    "lon": "85.3594",
    "name": "Tribhuvan International Airport",
    "city": "Kathmandu",
    "state": "Central",
    "country": "Nepal",
    "woeid": "12515135",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10121",
    "elev": "4420",
    "icao": "",
    "direct_flights": "32",
    "carriers": "26"
  },
  {
    "code": "KTN",
    "lat": "55.354",
    "lon": "-131.706",
    "name": "Ketchikan International Airport",
    "city": "Ketchikan",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520453",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7497",
    "elev": "88",
    "icao": "PAKT",
    "direct_flights": "13",
    "carriers": "11"
  },
  {
    "code": "KTS",
    "lat": "65.3333",
    "lon": "-166.483",
    "name": "",
    "city": "Teller Mission",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1920",
    "elev": "25",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KTT",
    "lat": "67.695",
    "lon": "24.8508",
    "name": "Kittila Airport",
    "city": "Kittila",
    "state": "Lapland",
    "country": "Finland",
    "woeid": "12512777",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "643",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "KTW",
    "lat": "50.4819",
    "lon": "19.0772",
    "name": "Zendek Airport",
    "city": "O\u017carowice",
    "state": "Sl\u0105skie",
    "country": "Poland",
    "woeid": "12515408",
    "tz": "Europe\/Warsaw",
    "phone": "0048 32 39 27 200",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.gtl.com.pl",
    "runway_length": "9183",
    "elev": "995",
    "icao": "EPKT",
    "direct_flights": "34",
    "carriers": "9"
  },
  {
    "code": "KUA",
    "lat": "3.7747",
    "lon": "103.21",
    "name": "Kuantan Airport",
    "city": "Gambang",
    "state": "Pahang",
    "country": "Malaysia",
    "woeid": "12514996",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9200",
    "elev": "58",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KUD",
    "lat": "33.0758",
    "lon": "75.2973",
    "name": "",
    "city": "Kudat",
    "state": "Sabah",
    "country": "Malaysia",
    "woeid": "1154804",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2411",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KUF",
    "lat": "53.5067",
    "lon": "50.166",
    "name": "Kurumoch Airport",
    "city": "Syzran'",
    "state": "Samarskaya Oblast",
    "country": "Russia",
    "woeid": "12516311",
    "tz": "Europe\/Samara",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9846",
    "elev": "479",
    "icao": "",
    "direct_flights": "34",
    "carriers": "22"
  },
  {
    "code": "KUG",
    "lat": "-31.875",
    "lon": "136.081",
    "name": "Australia",
    "city": "Kubin Island",
    "state": "",
    "country": "Australia",
    "woeid": "23424748",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KUH",
    "lat": "43.043",
    "lon": "144.194",
    "name": "Kushiro Airport",
    "city": "Kushiro",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "28360574",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "324",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "KUK",
    "lat": "60.8953",
    "lon": "-162.517",
    "name": "Kasigluk",
    "city": "Kasigluk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2430788",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2463",
    "elev": "40",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KUL",
    "lat": "2.77859",
    "lon": "101.689",
    "name": "Kuala Lumpur International Airport",
    "city": "Sepang",
    "state": "Putrajaya",
    "country": "Malaysia",
    "woeid": "28752278",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "+603 8776 4386",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.klia.com.my\/",
    "runway_length": "4000",
    "elev": null,
    "icao": "WMKK",
    "direct_flights": "101",
    "carriers": "68"
  },
  {
    "code": "KUN",
    "lat": "54.9863",
    "lon": "24.0512",
    "name": "Karmilava Airport",
    "city": "Kovno",
    "state": "Kauno apskritis",
    "country": "Lithuania",
    "woeid": "12514587",
    "tz": "Europe\/Vilnius",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "KUO",
    "lat": "63.0071",
    "lon": "27.7979",
    "name": "Kuopio Airport",
    "city": "Toivala",
    "state": "Eastern Finland",
    "country": "Finland",
    "woeid": "12512779",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "322",
    "icao": "",
    "direct_flights": "2",
    "carriers": "8"
  },
  {
    "code": "KUS",
    "lat": "65.5667",
    "lon": "-37.1167",
    "name": "Kulusuk Airport",
    "city": "Kulusuk",
    "state": "Ostgronland",
    "country": "Greenland",
    "woeid": "12523846",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3904",
    "elev": "112",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "KUT",
    "lat": "32.507",
    "lon": "45.8217",
    "name": "Kopitnari",
    "city": "Kutaisi",
    "state": "K\u00b4ut\u00b4aisi",
    "country": "Georgia",
    "woeid": "1963698",
    "tz": "Asia\/Tbilisi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "KUU",
    "lat": "31.8729",
    "lon": "77.1482",
    "name": "Bhuntar Airport",
    "city": "Bhuntar",
    "state": "Himachal Pradesh",
    "country": "India",
    "woeid": "12513555",
    "tz": "Asia\/Kolkata",
    "phone": "91-1902-65037",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "3573",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KUV",
    "lat": "35.9017",
    "lon": "126.618",
    "name": "Gunsan Airport",
    "city": "Gunsan-Si",
    "state": "Jeollabuk-Do",
    "country": "South Korea",
    "woeid": "12514209",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "29",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KVA",
    "lat": "40.915",
    "lon": "24.6203",
    "name": "Chrisoupolis Airport",
    "city": "Khrysoupolis",
    "state": "Anatoliki Makedonia",
    "country": "Greece",
    "woeid": "12513288",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "18",
    "icao": "",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "KVB",
    "lat": "58.4529",
    "lon": "13.9643",
    "name": "Skovde Airport",
    "city": "V\u00e4ring",
    "state": "Vastra Gotaland",
    "country": "Sweden",
    "woeid": "22656501",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2675",
    "elev": "397",
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "KVC",
    "lat": "55.0667",
    "lon": "-162.317",
    "name": "",
    "city": "King Cove",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2432507",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "148",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KVD",
    "lat": "40.6844",
    "lon": "46.3488",
    "name": "Elisavetpol",
    "city": "Gyandzha",
    "state": "Ganca",
    "country": "Azerbaijan",
    "woeid": "1951907",
    "tz": "Asia\/Baku",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "KVG",
    "lat": "-2.5806",
    "lon": "150.807",
    "name": "Kavieng Airport",
    "city": "Kavieng",
    "state": "New Ireland",
    "country": "Papua New Guinea",
    "woeid": "12515469",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5010",
    "elev": "12",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "KVK",
    "lat": "67.5757",
    "lon": "33.5691",
    "name": "Kirovsk Airport",
    "city": "Kirovsk",
    "state": "Murmanskaya Oblast",
    "country": "Russia",
    "woeid": "12516168",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KVL",
    "lat": "67.7333",
    "lon": "-164.667",
    "name": "Kivalina",
    "city": "Kivalina",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799789",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "10",
    "icao": "PAVL",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KVR",
    "lat": "49.1825",
    "lon": "-0.45906",
    "name": "Carpiquet Airport",
    "city": "Carpiquet",
    "state": "Basse-Normandie",
    "country": "France",
    "woeid": "12523083",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KWA",
    "lat": "8.7147",
    "lon": "167.727",
    "name": "Bucholz Army Air Field",
    "city": "Kwajalein",
    "state": "Kwajalein",
    "country": "Marshall Islands",
    "woeid": "12515490",
    "tz": "Pacific\/Majuro",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6673",
    "elev": "9",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "KWE",
    "lat": "26.9015",
    "lon": "106.585",
    "name": "Guizhou",
    "city": "Guiyang",
    "state": "Guizhou",
    "country": "China",
    "woeid": "12578007",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "29",
    "carriers": "14"
  },
  {
    "code": "KWI",
    "lat": "29.2442",
    "lon": "47.9748",
    "name": "Kuwait International Airport",
    "city": "Kuwait City",
    "state": "Al Farwaniyah",
    "country": "Kuwait",
    "woeid": "23388328",
    "tz": "Asia\/Kuwait",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kuwait-airport.com.kw\/",
    "runway_length": "11152",
    "elev": "189",
    "icao": "OKBK",
    "direct_flights": "67",
    "carriers": "52"
  },
  {
    "code": "KWJ",
    "lat": "35.125",
    "lon": "126.811",
    "name": "Gwangju Airport",
    "city": "Gwangju",
    "state": "Gwangju",
    "country": "South Korea",
    "woeid": "12514210",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9300",
    "elev": "42",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "KWK",
    "lat": "59.9077",
    "lon": "-163.026",
    "name": "Kwigillingok",
    "city": "Kwigillingok",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2434060",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2300",
    "elev": "20",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "KWL",
    "lat": "25.1921",
    "lon": "110.305",
    "name": "Li Chia Tsun Airport",
    "city": "Guilin",
    "state": "Guangxi",
    "country": "China",
    "woeid": "12512117",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "36",
    "carriers": "23"
  },
  {
    "code": "KWM",
    "lat": "-15.4878",
    "lon": "141.76",
    "name": "Kowanyama",
    "city": "Kowanyama",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12708477",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KWN",
    "lat": "59.7511",
    "lon": "-161.908",
    "name": "Quinhagak",
    "city": "Quinhagak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2477888",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2700",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "KWP",
    "lat": "57.7696",
    "lon": "-153.554",
    "name": "Village Seaplane Base-West Point",
    "city": "West Point",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523157",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KWT",
    "lat": "60.8",
    "lon": "-161.45",
    "name": "Kwethluk Airport",
    "city": "Kwethluk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2434059",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1800",
    "elev": "28",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "KWZ",
    "lat": "-10.7667",
    "lon": "25.5069",
    "name": "Kolwezi Airport",
    "city": "Kolwezi",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511968",
    "tz": "Africa\/Lubumbashi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5741",
    "elev": "5007",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KXA",
    "lat": "55.5",
    "lon": "-132.5",
    "name": "Kasaan SPB",
    "city": "Kasaan",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2430774",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KXF",
    "lat": "-17.3167",
    "lon": "179.383",
    "name": "Koro Island",
    "city": "Koro Island",
    "state": "Northern",
    "country": "Fiji",
    "woeid": "1062932",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2515",
    "elev": "358",
    "icao": "NFNO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KXK",
    "lat": "50.4029",
    "lon": "136.946",
    "name": "Komsomolsk South Airport",
    "city": "Komsomol'sk-na-Amure",
    "state": "Khabarovskiy Kray",
    "country": "Russia",
    "woeid": "12516213",
    "tz": "Asia\/Sakhalin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "KXU",
    "lat": "-16.4333",
    "lon": "-1.36667",
    "name": "Katiu",
    "city": "Katiu",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "24549702",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KYA",
    "lat": "37.9797",
    "lon": "32.5622",
    "name": "Konya Airport",
    "city": "Konya",
    "state": "Konya",
    "country": "Turkey",
    "woeid": "12517903",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10942",
    "elev": "3390",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KYK",
    "lat": "57.5645",
    "lon": "-154.454",
    "name": "Karluk Airport",
    "city": "Kodiak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523158",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "137",
    "icao": "PAKY",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KYN",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Milton Keynes",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "KYP",
    "lat": "19.4267",
    "lon": "93.5347",
    "name": "Kyaukpyu Airport",
    "city": "Kyaukpyu",
    "state": "Rakhine State",
    "country": "Myanmar",
    "woeid": "12510918",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "20",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "KYS",
    "lat": "14.4319",
    "lon": "-11.4397",
    "name": "Kayes Airport",
    "city": "Kayes",
    "state": "Kayes",
    "country": "Mali",
    "woeid": "12514775",
    "tz": "Africa\/Bamako",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3822",
    "elev": "154",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KYU",
    "lat": "64.9",
    "lon": "-157.7",
    "name": "Koyukuk",
    "city": "Koyukuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799793",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2980",
    "elev": "115",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "KYZ",
    "lat": "51.6636",
    "lon": "94.1494",
    "name": "Tyva",
    "city": "Kyzyl",
    "state": "Tyva",
    "country": "Russia",
    "woeid": "2346879",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "KZB",
    "lat": "57.55",
    "lon": "-153.75",
    "name": "Zachar Bay",
    "city": "Zachar Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799679",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KZI",
    "lat": "40.2886",
    "lon": "21.8419",
    "name": "Kozani Airport",
    "city": "Kozani",
    "state": "Dytiki Makedonia",
    "country": "Greece",
    "woeid": "12513302",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6004",
    "elev": "2080",
    "icao": "LGKZ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "KZN",
    "lat": "55.6014",
    "lon": "49.2751",
    "name": "Kirbi Airport",
    "city": "Zelenodol'sk",
    "state": "Tatarstan",
    "country": "Russia",
    "woeid": "12516165",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "210",
    "icao": "",
    "direct_flights": "20",
    "carriers": "12"
  },
  {
    "code": "KZO",
    "lat": "44.8172",
    "lon": "65.5435",
    "name": "Kzyl Orda Airport",
    "city": "Kzyl-Orda",
    "state": "Qyzylorda",
    "country": "Kazakhstan",
    "woeid": "12514405",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "KZS",
    "lat": "36.1355",
    "lon": "29.5754",
    "name": "Kastelorizo Airport",
    "city": "Kastelorizo",
    "state": "New York",
    "country": "Greece",
    "woeid": "12523354",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LAD",
    "lat": "-8.8547",
    "lon": "13.2342",
    "name": "Luanda 4 de Fevereiro Airport",
    "city": "Luanda",
    "state": "Luanda",
    "country": "Angola",
    "woeid": "12510439",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12139",
    "elev": "243",
    "icao": "",
    "direct_flights": "29",
    "carriers": "13"
  },
  {
    "code": "LAE",
    "lat": "-6.5672",
    "lon": "146.725",
    "name": "Nadzab Airport",
    "city": "Lae",
    "state": "Morobe",
    "country": "Papua New Guinea",
    "woeid": "12515476",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5823",
    "elev": "46",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "LAI",
    "lat": "48.756",
    "lon": "-3.47109",
    "name": "Servel Airport",
    "city": "Lannion",
    "state": "Brittany",
    "country": "France",
    "woeid": "12523815",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4708",
    "elev": "292",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LAJ",
    "lat": "-27.7831",
    "lon": "-50.2825",
    "name": "Lajes Airport",
    "city": "Lages",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12511208",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "3068",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LAK",
    "lat": "68.2332",
    "lon": "-134.96",
    "name": "Aklavik Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524046",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "35",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LAN",
    "lat": "42.7728",
    "lon": "-84.5888",
    "name": "Lansing Capital City Airport",
    "city": "Lansing",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12519060",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7251",
    "elev": "860",
    "icao": "KLAN",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "LAO",
    "lat": "18.1797",
    "lon": "120.529",
    "name": "Laoag International Airport",
    "city": "San Nicolas",
    "state": "Ilocos Region",
    "country": "Philippines",
    "woeid": "12515626",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7940",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LAP",
    "lat": "24.0722",
    "lon": "-110.362",
    "name": "General Manuel Marquez de Leon International Air",
    "city": "La Paz",
    "state": "Baja California Sur",
    "country": "Mexico",
    "woeid": "12514883",
    "tz": "America\/Mazatlan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "69",
    "icao": "MMLP",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "LAQ",
    "lat": "32.76",
    "lon": "21.7616",
    "name": "Al Bayda'",
    "city": "Beida",
    "state": "Al Jabal al Akhdar",
    "country": "Libya",
    "woeid": "1352570",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11981",
    "elev": "2157",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LAR",
    "lat": "37.8527",
    "lon": "-76.517",
    "name": "General Brees Field",
    "city": "Laramie",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12793533",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7700",
    "elev": "7278",
    "icao": "KLAR",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LAS",
    "lat": "36.0806",
    "lon": "-115.143",
    "name": "Mccarran International Airport",
    "city": "Las Vegas",
    "state": "Nevada",
    "country": "United States",
    "woeid": "12520849",
    "tz": "America\/Los_Angeles",
    "phone": "(702) 261-5211",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.mccarran.com",
    "runway_length": "12636",
    "elev": "2174",
    "icao": "KLAS",
    "direct_flights": "130",
    "carriers": "64"
  },
  {
    "code": "LAU",
    "lat": "-2.25",
    "lon": "40.8333",
    "name": "Lamu Airport",
    "city": "Lamu",
    "state": "Coast",
    "country": "Kenya",
    "woeid": "12523159",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LAW",
    "lat": "34.573",
    "lon": "-98.4135",
    "name": "Lawton Municipal Airport",
    "city": "Lawton",
    "state": "Oklahoma",
    "country": "United States",
    "woeid": "12520593",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8599",
    "elev": "1110",
    "icao": "KLAW",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LAX",
    "lat": "33.9456",
    "lon": "-118.391",
    "name": "Los Angeles International Airport",
    "city": "Los Angeles",
    "state": "California",
    "country": "United States",
    "woeid": "12520706",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12091",
    "elev": "126",
    "icao": "KLAX",
    "direct_flights": "200",
    "carriers": "99"
  },
  {
    "code": "LBA",
    "lat": "53.8685",
    "lon": "-1.66123",
    "name": "Leeds Bradford Airport",
    "city": "Leeds",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22471856",
    "tz": "Europe\/London",
    "phone": "+44 (0) 113 250 9696",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lbia.co.uk",
    "runway_length": "7381",
    "elev": "682",
    "icao": "EGNM",
    "direct_flights": "61",
    "carriers": "34"
  },
  {
    "code": "LBB",
    "lat": "33.6566",
    "lon": "-101.821",
    "name": "Lubbock International Airport",
    "city": "Lubbock",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520718",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flylia.com\/",
    "runway_length": "11500",
    "elev": "3281",
    "icao": "KLBB",
    "direct_flights": "10",
    "carriers": "11"
  },
  {
    "code": "LBC",
    "lat": "53.8061",
    "lon": "10.7032",
    "name": "Lubeck Airport",
    "city": "Lubeque",
    "state": "Schleswig-Holstein",
    "country": "Germany",
    "woeid": "22239875",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flughafen-luebeck.de\/1024\/english\/index.htm",
    "runway_length": "5912",
    "elev": "53",
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "LBD",
    "lat": "40.2833",
    "lon": "69.6167",
    "name": "Khujand",
    "city": "Khudzhand",
    "state": "Leninobod",
    "country": "Tajikistan",
    "woeid": "2212369",
    "tz": "Asia\/Dushanbe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "12",
    "carriers": "7"
  },
  {
    "code": "LBE",
    "lat": "40.2728",
    "lon": "-79.4056",
    "name": "Westmoreland County Airport",
    "city": "Latrobe",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12522400",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5501",
    "elev": "1161",
    "icao": "KLBE",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "LBF",
    "lat": "41.1333",
    "lon": "-100.705",
    "name": "Lee Bird Field Airport",
    "city": "North Platte",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12520609",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "2779",
    "icao": "KLBF",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "LBJ",
    "lat": "-8.51667",
    "lon": "119.883",
    "name": "Mutiara Airport",
    "city": "Ende",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12523308",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "295",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LBL",
    "lat": "37.0446",
    "lon": "-100.952",
    "name": "Liberal Municipal Airport",
    "city": "Liberal",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12520643",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7100",
    "elev": "2887",
    "icao": "KLBL",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LBP",
    "lat": "3.18333",
    "lon": "115.45",
    "name": "",
    "city": "Long Banga",
    "state": "",
    "country": "Malaysia",
    "woeid": "23424901",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LBS",
    "lat": "-16.5729",
    "lon": "179.265",
    "name": "Northern",
    "city": "Labasa",
    "state": "Northern",
    "country": "Fiji",
    "woeid": "2345337",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3356",
    "elev": "30",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "LBU",
    "lat": "5.3",
    "lon": "115.249",
    "name": "Labuan Airport",
    "city": "Victoria",
    "state": "Labuan",
    "country": "Malaysia",
    "woeid": "12514998",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6380",
    "elev": "99",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "LBV",
    "lat": "0.4592",
    "lon": "9.4153",
    "name": "Libreville Leon M Ba Airport",
    "city": "Libreville",
    "state": "Estuaire",
    "country": "Gabon",
    "woeid": "12512994",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "39",
    "icao": "FOOL",
    "direct_flights": "23",
    "carriers": "22"
  },
  {
    "code": "LCA",
    "lat": "34.8789",
    "lon": "33.6303",
    "name": "Larnaca Airport",
    "city": "Larnaca",
    "state": "Rep. Cyprus",
    "country": "Cyprus",
    "woeid": "12512501",
    "tz": "Asia\/Nicosia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "8",
    "icao": "LCLK",
    "direct_flights": "56",
    "carriers": "48"
  },
  {
    "code": "LCE",
    "lat": "15.7433",
    "lon": "-86.8528",
    "name": "Goloson International Airport",
    "city": "La Ceiba",
    "state": "Atl\u00e1ntida",
    "country": "Honduras",
    "woeid": "12513358",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9783",
    "elev": "49",
    "icao": "",
    "direct_flights": "8",
    "carriers": "4"
  },
  {
    "code": "LCG",
    "lat": "43.3026",
    "lon": "-8.37867",
    "name": "La Coruna Airport",
    "city": "S Esteban",
    "state": "Galicia",
    "country": "Spain",
    "woeid": "12517551",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5512",
    "elev": "318",
    "icao": "LECO",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "LCH",
    "lat": "30.1235",
    "lon": "-93.2198",
    "name": "Lake Charles Regional Airport",
    "city": "Lake Charles",
    "state": "Louisiana",
    "country": "United States",
    "woeid": "12520523",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "15",
    "icao": "KLCH",
    "direct_flights": "1",
    "carriers": "5"
  },
  {
    "code": "LCJ",
    "lat": "51.7217",
    "lon": "19.3989",
    "name": "Lodz Lublinek",
    "city": "Lodz",
    "state": "Woj. Lodzkie",
    "country": "Poland",
    "woeid": "505120",
    "tz": "Europe\/Warsaw",
    "phone": "+48 42 683 52 00",
    "type": "Airports",
    "email": "handling@airport.lodz.pl",
    "url": "http:\/\/www.airport.lodz.pl\/",
    "runway_length": "4701",
    "elev": "606",
    "icao": "EPLL",
    "direct_flights": "9",
    "carriers": "3"
  },
  {
    "code": "LCK",
    "lat": "39.8176",
    "lon": "-82.936",
    "name": "Rickenbacker International Airport",
    "city": "Columbus",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12521590",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12100",
    "elev": "744",
    "icao": "KLCK",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LCR",
    "lat": "-0.73333",
    "lon": "-73.0167",
    "name": "La Chorrera Airport",
    "city": "La Chorrera",
    "state": "Amazonas",
    "country": "Colombia",
    "woeid": "12524485",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LCX",
    "lat": "19.3542",
    "lon": "-99.2925",
    "name": "",
    "city": "Longyan",
    "state": "Fujian",
    "country": "China",
    "woeid": "2139971",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LCY",
    "lat": "51.5039",
    "lon": "0.04981",
    "name": "London City Airport",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22475376",
    "tz": "Europe\/London",
    "phone": "02076460000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.londoncityairport.com\/",
    "runway_length": "4327",
    "elev": "6",
    "icao": "EGLC",
    "direct_flights": "31",
    "carriers": "13"
  },
  {
    "code": "LDB",
    "lat": "-23.3297",
    "lon": "-51.1361",
    "name": "Londrina Airport",
    "city": "Londrina",
    "state": "Parana",
    "country": "Brazil",
    "woeid": "12511215",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6896",
    "elev": "1867",
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "LDE",
    "lat": "43.1857",
    "lon": "0.00346",
    "name": "Ossun Airport",
    "city": "Juillan",
    "state": "Midi-Pyrenees",
    "country": "France",
    "woeid": "12512948",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "1260",
    "icao": "LFBT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LDG",
    "lat": "64.9029",
    "lon": "45.708",
    "name": "Leshukonskoye Airport",
    "city": "Leshukonskoye",
    "state": "Arkhangelrskaya Oblast",
    "country": "Russia",
    "woeid": "12516346",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LDH",
    "lat": "-31.5392",
    "lon": "159.08",
    "name": "Lord Howe Island Airport",
    "city": "Lord Howe Island",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510722",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3298",
    "elev": "13",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "LDN",
    "lat": "27.3",
    "lon": "86.75",
    "name": "Lamidanda Airport",
    "city": "Lamidanda",
    "state": "Central",
    "country": "Nepal",
    "woeid": "22662771",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1770",
    "elev": "3500",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LDU",
    "lat": "5.0319",
    "lon": "118.324",
    "name": "Lahad Datu Airport",
    "city": "Lahad Datu",
    "state": "Sabah",
    "country": "Malaysia",
    "woeid": "12514999",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "45",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LDV",
    "lat": "48.5303",
    "lon": "-4.1508",
    "name": "Landivisiau Airport",
    "city": "Landivisiau",
    "state": "Brittany",
    "country": "France",
    "woeid": "12512911",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "LDY",
    "lat": "55.0402",
    "lon": "-7.156",
    "name": "City of Derry Airport",
    "city": "Londonderry",
    "state": "Northern Ireland",
    "country": "United Kingdom",
    "woeid": "23387862",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4695",
    "elev": "18",
    "icao": "EGAE",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "LEA",
    "lat": "-22.233",
    "lon": "114.092",
    "name": "Learmonth Airport",
    "city": "Learmonth",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510715",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10100",
    "elev": "19",
    "icao": "YPLM",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "LEB",
    "lat": "43.6249",
    "lon": "-72.3087",
    "name": "Lebanon Municipal Airport",
    "city": "West Lebanon",
    "state": "New Hampshire",
    "country": "United States",
    "woeid": "12520604",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5495",
    "elev": "589",
    "icao": "KLEB",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "LED",
    "lat": "59.9667",
    "lon": "30.3",
    "name": "Pulkuvo 2 Airport",
    "city": "St. Petersburg",
    "state": "St. Peterburg",
    "country": "Russia",
    "woeid": "12523047",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11155",
    "elev": "59",
    "icao": "ULLI",
    "direct_flights": "121",
    "carriers": "58"
  },
  {
    "code": "LEH",
    "lat": "40.3897",
    "lon": "-111.847",
    "name": "",
    "city": "Le Havre",
    "state": "Haute-Normandie",
    "country": "France",
    "woeid": "603259",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "312",
    "icao": "LFOH",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "LEI",
    "lat": "36.8459",
    "lon": "-2.37221",
    "name": "Almeria Airport",
    "city": "Almeria",
    "state": "Andalucia",
    "country": "Spain",
    "woeid": "12517536",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "50",
    "icao": "LEAM",
    "direct_flights": "13",
    "carriers": "10"
  },
  {
    "code": "LEJ",
    "lat": "51.4197",
    "lon": "12.2201",
    "name": "Leipzig-Halle Airport",
    "city": "Schkeuditz",
    "state": "Saxony",
    "country": "Germany",
    "woeid": "22275261",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "466",
    "icao": "EDDP",
    "direct_flights": "46",
    "carriers": "31"
  },
  {
    "code": "LEN",
    "lat": "34.1697",
    "lon": "77.53",
    "name": "Aeropuero de Bajio",
    "city": "Leon",
    "state": "Castille and Leon",
    "country": "Spain",
    "woeid": "765099",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "LER",
    "lat": "-27.873",
    "lon": "120.61",
    "name": "Leinster Airport",
    "city": "Sir Samuel",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12523286",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5700",
    "elev": "1631",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LET",
    "lat": "-4.19332",
    "lon": "-69.9402",
    "name": "Gen. A.V. Cobo",
    "city": "Leticia",
    "state": "Amazonas",
    "country": "Colombia",
    "woeid": "12511015",
    "tz": "America\/Rio_Branco",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6167",
    "elev": "275",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "LEV",
    "lat": "37.2127",
    "lon": "-83.6199",
    "name": "",
    "city": "Bureta",
    "state": "",
    "country": "Fiji",
    "woeid": "23424813",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2543",
    "elev": "11",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LEX",
    "lat": "38.0384",
    "lon": "-84.5989",
    "name": "Blue Grass Field",
    "city": "Lexington",
    "state": "Kentucky",
    "country": "United States",
    "woeid": "24554866",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7003",
    "elev": "980",
    "icao": "KLEX",
    "direct_flights": "14",
    "carriers": "17"
  },
  {
    "code": "LFM",
    "lat": "27.3739",
    "lon": "53.1911",
    "name": "Lamerd",
    "city": "Lamerd",
    "state": "Fars",
    "country": "Iran",
    "woeid": "12513739",
    "tz": "Asia\/Tehran",
    "phone": "+987825222595",
    "type": "Airports",
    "email": "sohrab_azizy@YAHOO.COM",
    "url": "",
    "runway_length": "3054",
    "elev": "1345",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "LFT",
    "lat": "30.2084",
    "lon": "-91.9935",
    "name": "Lafayette Regional Airport",
    "city": "Lafayette",
    "state": "Louisiana",
    "country": "United States",
    "woeid": "12520518",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7651",
    "elev": "42",
    "icao": "KLFT",
    "direct_flights": "5",
    "carriers": "9"
  },
  {
    "code": "LFW",
    "lat": "6.1625",
    "lon": "1.255",
    "name": "Lome Tokoin Airport",
    "city": "Lome",
    "state": "Lome",
    "country": "Togo",
    "woeid": "12517850",
    "tz": "Africa\/Lome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "72",
    "icao": "DXXX",
    "direct_flights": "18",
    "carriers": "15"
  },
  {
    "code": "LGA",
    "lat": "40.7731",
    "lon": "-73.8756",
    "name": "LaGuardia Airport",
    "city": "Flushing",
    "state": "New York",
    "country": "United States",
    "woeid": "12520509",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "22",
    "icao": "KLGA",
    "direct_flights": "82",
    "carriers": "30"
  },
  {
    "code": "LGB",
    "lat": "33.8186",
    "lon": "-118.144",
    "name": "Long Beach Daugherty Field Airport",
    "city": "Long Beach",
    "state": "California",
    "country": "United States",
    "woeid": "12520696",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "57",
    "icao": "",
    "direct_flights": "19",
    "carriers": "8"
  },
  {
    "code": "LGG",
    "lat": "50.64",
    "lon": "5.44035",
    "name": "Bierset Airport",
    "city": "Velroux",
    "state": "Liege",
    "country": "Belgium",
    "woeid": "12510845",
    "tz": "Europe\/Brussels",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8839",
    "elev": "660",
    "icao": "EBLG",
    "direct_flights": "99",
    "carriers": "7"
  },
  {
    "code": "LGI",
    "lat": "23.1794",
    "lon": "-75.0906",
    "name": "Deadmans Cay Airport",
    "city": "Deadmans Cay",
    "state": "Long Island",
    "country": "Bahamas",
    "woeid": "12510865",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "9",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "LGK",
    "lat": "6.3364",
    "lon": "99.7353",
    "name": "Langkawi International Airport",
    "city": "Kuah",
    "state": "Perlis",
    "country": "Malaysia",
    "woeid": "12515000",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8001",
    "elev": "29",
    "icao": "",
    "direct_flights": "4",
    "carriers": "8"
  },
  {
    "code": "LGL",
    "lat": "3.41667",
    "lon": "115.15",
    "name": "Long Lellang",
    "city": "Long Lellang",
    "state": "",
    "country": "Malaysia",
    "woeid": "23424901",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2559",
    "elev": "1400",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LGP",
    "lat": "13.1578",
    "lon": "123.731",
    "name": "Legazpi Airport",
    "city": "Daraga",
    "state": "Bicol Region",
    "country": "Philippines",
    "woeid": "12515627",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6476",
    "elev": "66",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LGQ",
    "lat": "0.1",
    "lon": "-76.8833",
    "name": "Lago Agrio Airport",
    "city": "Lago Agrio",
    "state": "Sucumbios",
    "country": "Ecuador",
    "woeid": "12523610",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6085",
    "elev": "980",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LGW",
    "lat": "51.1568",
    "lon": "-0.16988",
    "name": "London Gatwick Airport",
    "city": "Horley",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "23387567",
    "tz": "Europe\/London",
    "phone": "0870 000 2468",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.gatwickairport.com\/",
    "runway_length": "10364",
    "elev": "202",
    "icao": "",
    "direct_flights": "227",
    "carriers": "71"
  },
  {
    "code": "LHE",
    "lat": "31.521",
    "lon": "74.3933",
    "name": "Lahore Airport",
    "city": "Lahore",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "12515245",
    "tz": "Asia\/Karachi",
    "phone": "9248792",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lahore-airport.com\/",
    "runway_length": "11024",
    "elev": "700",
    "icao": "OPLA",
    "direct_flights": "35",
    "carriers": "19"
  },
  {
    "code": "LHG",
    "lat": "-29.4531",
    "lon": "147.981",
    "name": "New South Wales",
    "city": "Lightning Ridge",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "2344700",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "540",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LHR",
    "lat": "51.4703",
    "lon": "-0.45342",
    "name": "London Heathrow Airport",
    "city": "Hounslow",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "23382429",
    "tz": "Europe\/London",
    "phone": "+44 (0)8700 000698",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.heathrowairport.com",
    "runway_length": "12802",
    "elev": "80",
    "icao": "EGLL",
    "direct_flights": "227",
    "carriers": "105"
  },
  {
    "code": "LHW",
    "lat": "36.0167",
    "lon": "103.75",
    "name": "Lanzhou Airport",
    "city": "Lanzhou",
    "state": "\u7518\u8083\u7701",
    "country": "China",
    "woeid": "12714026",
    "tz": "Asia\/Shanghai",
    "phone": "+86-931-8968160",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3400",
    "elev": "1947",
    "icao": "KLHW",
    "direct_flights": "25",
    "carriers": "9"
  },
  {
    "code": "LIF",
    "lat": "-20.9314",
    "lon": "167.229",
    "name": "Loyalty Islands Airport",
    "city": "W\u00e9",
    "state": "Loyaute",
    "country": "New Caledonia",
    "woeid": "12515037",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3608",
    "elev": "92",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "LIG",
    "lat": "45.8615",
    "lon": "1.1779",
    "name": "Bellegarde Airport",
    "city": "Limoges",
    "state": "Limousin",
    "country": "France",
    "woeid": "12512853",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "1299",
    "icao": "LFBL",
    "direct_flights": "10",
    "carriers": "5"
  },
  {
    "code": "LIH",
    "lat": "21.9782",
    "lon": "-159.351",
    "name": "Lihue Airport",
    "city": "Lihue",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520648",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "149",
    "icao": "PHLI",
    "direct_flights": "7",
    "carriers": "13"
  },
  {
    "code": "LIL",
    "lat": "50.5713",
    "lon": "3.1072",
    "name": "Lesquin Airport",
    "city": "Fretin",
    "state": "Nord-Pas-de-Calais",
    "country": "France",
    "woeid": "12523162",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9268",
    "elev": "157",
    "icao": "",
    "direct_flights": "20",
    "carriers": "15"
  },
  {
    "code": "LIM",
    "lat": "-12.0228",
    "lon": "-77.1081",
    "name": "Jorge Chavez Airport",
    "city": "Ventanilla",
    "state": "Provincia Constitucional del Cal",
    "country": "Peru",
    "woeid": "23388342",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11506",
    "elev": "113",
    "icao": "SPIM",
    "direct_flights": "39",
    "carriers": "32"
  },
  {
    "code": "LIN",
    "lat": "45.4558",
    "lon": "9.27269",
    "name": "Linate Airport",
    "city": "Peschiera Borromeo",
    "state": "Lombardy",
    "country": "Italy",
    "woeid": "22314960",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sea-aeroportimilano.it\/",
    "runway_length": "8005",
    "elev": "353",
    "icao": "LIML",
    "direct_flights": "37",
    "carriers": "28"
  },
  {
    "code": "LIO",
    "lat": "9.9578",
    "lon": "-83.0291",
    "name": "Limon International Airport",
    "city": "Pto. Limon",
    "state": "Limon",
    "country": "Costa Rica",
    "woeid": "12512435",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5900",
    "elev": "7",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "LIR",
    "lat": "10.5922",
    "lon": "-85.5439",
    "name": "Tomas Guardia International Airport",
    "city": "Liberia",
    "state": "Guanacaste",
    "country": "Costa Rica",
    "woeid": "12512444",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7465",
    "elev": "230",
    "icao": "MRLB",
    "direct_flights": "13",
    "carriers": "9"
  },
  {
    "code": "LIS",
    "lat": "38.7701",
    "lon": "-9.13775",
    "name": "Lisbon Airport",
    "city": "Lisbon",
    "state": "Lisbon",
    "country": "Portugal",
    "woeid": "22405520",
    "tz": "Europe\/Lisbon",
    "phone": "+351 218 413 500",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ana.pt",
    "runway_length": "12484",
    "elev": "374",
    "icao": "LPPT",
    "direct_flights": "92",
    "carriers": "57"
  },
  {
    "code": "LIT",
    "lat": "34.7278",
    "lon": "-92.219",
    "name": "Adams Field Airport",
    "city": "Little Rock",
    "state": "Arkansas",
    "country": "United States",
    "woeid": "12518526",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7173",
    "elev": "258",
    "icao": "KLIT",
    "direct_flights": "22",
    "carriers": "20"
  },
  {
    "code": "LIW",
    "lat": "19.6886",
    "lon": "97.2169",
    "name": "Loikaw Airport",
    "city": "Loi-kaw",
    "state": "Kayan State",
    "country": "Myanmar",
    "woeid": "12510921",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "2940",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LJG",
    "lat": "37.8167",
    "lon": "118.017",
    "name": "Lijiang",
    "city": "Lijiang City",
    "state": "\u5c71\u4e1c\u7701",
    "country": "China",
    "woeid": "12712671",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2066",
    "elev": "0",
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "LJU",
    "lat": "46.2247",
    "lon": "14.4608",
    "name": "Ljubljana Airport",
    "city": "Ljubljana",
    "state": "Kranj",
    "country": "Slovenia",
    "woeid": "12517513",
    "tz": "Europe\/Belgrade",
    "phone": "+386 4 20 61 000",
    "type": "Airports",
    "email": "info@lju-airport.si",
    "url": "http:\/\/www.lju-airport.si\/",
    "runway_length": "10827",
    "elev": "1273",
    "icao": "LJLJ",
    "direct_flights": "40",
    "carriers": "28"
  },
  {
    "code": "LKA",
    "lat": "-8.34641",
    "lon": "122.979",
    "name": "Larantuka",
    "city": "Larantuka",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "1047732",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LKB",
    "lat": "-18.2",
    "lon": "178.833",
    "name": "Lakemba Island",
    "city": "Lakeba",
    "state": "Eastern",
    "country": "Fiji",
    "woeid": "12490030",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2495",
    "elev": "280",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LKE",
    "lat": "47.6333",
    "lon": "-122.333",
    "name": "Lake Union Seaplane Base",
    "city": "Seattle",
    "state": "Washington",
    "country": "United States",
    "woeid": "12523163",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "14",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "LKG",
    "lat": "4.20388",
    "lon": "34.3495",
    "name": "Lokichoggio Airport",
    "city": "Lokichoggio",
    "state": "Rift Valley",
    "country": "Kenya",
    "woeid": "1528041",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LKH",
    "lat": "3.31667",
    "lon": "114.783",
    "name": "Long Akah",
    "city": "Long Akah",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12740115",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LKL",
    "lat": "70.0661",
    "lon": "24.975",
    "name": "Banak Airport",
    "city": "Lakeselv",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12515102",
    "tz": "Europe\/Oslo",
    "phone": "47-78-46-50-01",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "25",
    "icao": "ENNA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LKN",
    "lat": "68.1564",
    "lon": "13.6121",
    "name": "Leknes Airport",
    "city": "Leknes",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523939",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "86",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LKO",
    "lat": "26.7667",
    "lon": "80.8833",
    "name": "Amausi International Airport",
    "city": "Lucknow",
    "state": "Uttar Pradesh",
    "country": "India",
    "woeid": "12513627",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7835",
    "elev": "400",
    "icao": "",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "LLA",
    "lat": "65.549",
    "lon": "22.1233",
    "name": "Kallax Airport",
    "city": "Lulea",
    "state": "Norrbotten",
    "country": "Sweden",
    "woeid": "12517645",
    "tz": "Europe\/Stockholm",
    "phone": "+46-920-244900",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lfv.se\/templates\/LFV_AirportStartPage____3595.aspx",
    "runway_length": "7218",
    "elev": "55",
    "icao": "ESPA",
    "direct_flights": "7",
    "carriers": "9"
  },
  {
    "code": "LLF",
    "lat": "26.2333",
    "lon": "111.617",
    "name": "Lingling Airport",
    "city": "LingLing",
    "state": "Hunan",
    "country": "China",
    "woeid": "12512124",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LLI",
    "lat": "12.009",
    "lon": "38.91",
    "name": "Ethiopia",
    "city": "Lalibela",
    "state": "Semen Welo",
    "country": "Ethiopia",
    "woeid": "23424808",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "6500",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "LLU",
    "lat": "60.5",
    "lon": "-45.5833",
    "name": "Alluitsup Paa Airport",
    "city": "Alluitsup Paa",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "472937",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KLLU",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "LLW",
    "lat": "-13.7917",
    "lon": "33.7792",
    "name": "Kamuzu International Airport",
    "city": "Lumbadzi",
    "state": "Dowa",
    "country": "Malawi",
    "woeid": "12514766",
    "tz": "Africa\/Blantyre",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11614",
    "elev": "4035",
    "icao": "",
    "direct_flights": "7",
    "carriers": "8"
  },
  {
    "code": "LMA",
    "lat": "63.8825",
    "lon": "-152.313",
    "name": "Lake Minchumina",
    "city": "Lake Minchumina",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2435032",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "684",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LMC",
    "lat": "3.31667",
    "lon": "-73.9",
    "name": "Lamacarena Airport",
    "city": "San Juan de Arama",
    "state": "Meta",
    "country": "Colombia",
    "woeid": "12524487",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3099",
    "elev": "1500",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LMM",
    "lat": "25.6856",
    "lon": "-109.082",
    "name": "Los Mochis Airport",
    "city": "Ahome",
    "state": "Sinaloa",
    "country": "Mexico",
    "woeid": "12514917",
    "tz": "America\/Mazatlan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "10",
    "icao": "MMLM",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "LMN",
    "lat": "2.91165",
    "lon": "112.609",
    "name": "Sarawak",
    "city": "Limbang",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "2346305",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1412",
    "elev": "70",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LMP",
    "lat": "35.4995",
    "lon": "12.6165",
    "name": "Lampedusa Airport",
    "city": "Caltabellotta",
    "state": "Sicily",
    "country": "Italy",
    "woeid": "12513831",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "72",
    "icao": "LICD",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "LMT",
    "lat": "42.1638",
    "lon": "-121.745",
    "name": "Klamath Falls International Airport",
    "city": "Klamath Falls",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12520483",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10301",
    "elev": "4092",
    "icao": "KLMT",
    "direct_flights": "2",
    "carriers": "6"
  },
  {
    "code": "LMY",
    "lat": "-7.18482",
    "lon": "142.374",
    "name": "Western",
    "city": "Lake Murray",
    "state": "Western",
    "country": "Papua New Guinea",
    "woeid": "2346586",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2495",
    "elev": "200",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LNB",
    "lat": "-16.5833",
    "lon": "168.183",
    "name": "Lamen Bay Airport",
    "city": "Lamen Bay",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2789",
    "elev": "7",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LNE",
    "lat": "-15.8667",
    "lon": "168.177",
    "name": "Lonorore Airport",
    "city": "Panngi",
    "state": "Penama",
    "country": "Vanuatu",
    "woeid": "12523164",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2953",
    "elev": "43",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "LNJ",
    "lat": "25.1902",
    "lon": "101.824",
    "name": "Yunnan",
    "city": "Lincang",
    "state": "Yunnan",
    "country": "China",
    "woeid": "12578018",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LNK",
    "lat": "40.8461",
    "lon": "-96.7543",
    "name": "Lincoln Municipal Airport",
    "city": "Lincoln",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12520655",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12901",
    "elev": "1214",
    "icao": "KLNK",
    "direct_flights": "5",
    "carriers": "9"
  },
  {
    "code": "LNO",
    "lat": "-28.8776",
    "lon": "121.315",
    "name": "Leonora Aerodrome",
    "city": "Leonora",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12523165",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4406",
    "elev": "1217",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LNV",
    "lat": "-3.06667",
    "lon": "152.617",
    "name": "Gerrit Denys Island",
    "city": "Lihir Island",
    "state": "New Ireland",
    "country": "Papua New Guinea",
    "woeid": "12498872",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "300",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LNY",
    "lat": "20.7909",
    "lon": "-156.951",
    "name": "Lanai Airport",
    "city": "Lanai City",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520557",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1308",
    "icao": "PHNY",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "LNZ",
    "lat": "48.2338",
    "lon": "14.1905",
    "name": "Linz Airport",
    "city": "Hoersching",
    "state": "Upper Austria",
    "country": "Austria",
    "woeid": "22016347",
    "tz": "Europe\/Vienna",
    "phone": "+43(0)72216000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.linz-airport.at",
    "runway_length": "9219",
    "elev": "978",
    "icao": "",
    "direct_flights": "30",
    "carriers": "17"
  },
  {
    "code": "LOD",
    "lat": "31.9587",
    "lon": "34.8864",
    "name": "",
    "city": "Longana",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2329",
    "elev": "167",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "LOH",
    "lat": "-3.96911",
    "lon": "-79.2116",
    "name": "Loja Airport",
    "city": "Loja",
    "state": "Loja",
    "country": "Ecuador",
    "woeid": "12523618",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5576",
    "elev": "4061",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LOS",
    "lat": "6.575",
    "lon": "3.3222",
    "name": "Lagos Murtala Muhammed Airport",
    "city": "Ikeja",
    "state": "Lagos",
    "country": "Nigeria",
    "woeid": "12515073",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12795",
    "elev": "135",
    "icao": "DNMM",
    "direct_flights": "42",
    "carriers": "37"
  },
  {
    "code": "LOU",
    "lat": "38.2228",
    "lon": "-85.6669",
    "name": "Bowman Field Airport",
    "city": "Louisville",
    "state": "Kentucky",
    "country": "United States",
    "woeid": "12518909",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KLOU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LOV",
    "lat": "26.9561",
    "lon": "-101.467",
    "name": "Monclova Airport",
    "city": "Frontera",
    "state": "Coahuila de Zaragoza",
    "country": "Mexico",
    "woeid": "12514922",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4784",
    "elev": "1850",
    "icao": "MMMV",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LPA",
    "lat": "27.9372",
    "lon": "-15.3828",
    "name": "Las Palmas Airport",
    "city": "Telde",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "12517553",
    "tz": "Atlantic\/Canary",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aena.es\/csee\/Satellite?pagename=Estandar%2FPage%2FAer",
    "runway_length": "10171",
    "elev": "76",
    "icao": "",
    "direct_flights": "71",
    "carriers": "43"
  },
  {
    "code": "LPB",
    "lat": "-16.5094",
    "lon": "-68.1906",
    "name": "El Alto International Airport",
    "city": "La Paz",
    "state": "La Paz",
    "country": "Bolivia",
    "woeid": "12510906",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13123",
    "elev": "13313",
    "icao": "SLLP",
    "direct_flights": "9",
    "carriers": "7"
  },
  {
    "code": "LPD",
    "lat": "-1.3",
    "lon": "-69.7167",
    "name": "La Pedrera Airport",
    "city": "La Pedrera",
    "state": "Amazonas",
    "country": "Colombia",
    "woeid": "12524489",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4750",
    "elev": "250",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LPI",
    "lat": "58.4082",
    "lon": "15.6586",
    "name": "Saab Airport",
    "city": "Linkoping",
    "state": "Ostergotland",
    "country": "Sweden",
    "woeid": "12517672",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7382",
    "elev": "306",
    "icao": "ESSL",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "LPK",
    "lat": "52.6194",
    "lon": "39.6597",
    "name": "Russia",
    "city": "Lipetsk",
    "state": "Lipetskaya Oblast",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LPL",
    "lat": "53.3371",
    "lon": "-2.85746",
    "name": "Liverpool John Lennon Airport",
    "city": "Liverpool",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22473264",
    "tz": "Europe\/London",
    "phone": "+44 (0)870 129 8484",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.liverpoolairport.com\/",
    "runway_length": "7500",
    "elev": "82",
    "icao": "EGGP",
    "direct_flights": "61",
    "carriers": "8"
  },
  {
    "code": "LPM",
    "lat": "-16.4445",
    "lon": "167.819",
    "name": "Lamap Airport",
    "city": "Lamap",
    "state": "Malampa",
    "country": "Vanuatu",
    "woeid": "12523167",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2756",
    "elev": "7",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LPP",
    "lat": "61.0465",
    "lon": "28.1536",
    "name": "Lappeenranta Airport",
    "city": "Lappeenranta",
    "state": "Southern Finland",
    "country": "Finland",
    "woeid": "12512782",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "349",
    "icao": "EFLP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LPQ",
    "lat": "19.8958",
    "lon": "102.164",
    "name": "Louangphrabang Airport",
    "city": "Louangphrabang",
    "state": "Louangphabang",
    "country": "Laos",
    "woeid": "12514546",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "978",
    "icao": "",
    "direct_flights": "8",
    "carriers": "4"
  },
  {
    "code": "LPS",
    "lat": "48.4855",
    "lon": "-122.936",
    "name": "Lopez Island Airport",
    "city": "Lopez Island",
    "state": "Washington",
    "country": "United States",
    "woeid": "29387798",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2900",
    "elev": "200",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LPT",
    "lat": "18.2742",
    "lon": "99.5072",
    "name": "Lampang Airport",
    "city": "Lampang",
    "state": "Lampang",
    "country": "Thailand",
    "woeid": "12517760",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4890",
    "elev": "794",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LPX",
    "lat": "56.501",
    "lon": "21.0897",
    "name": "Liepaja East Airport",
    "city": "Liepaya",
    "state": "Liepajas Rajons",
    "country": "Latvia",
    "woeid": "12514573",
    "tz": "Europe\/Riga",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "http:\/\/www.liepaja-airport.lv",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "LPY",
    "lat": "45.0757",
    "lon": "3.76327",
    "name": "Loudes Airport",
    "city": "Le Puy",
    "state": "Auvergne",
    "country": "France",
    "woeid": "12512927",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4757",
    "elev": "2730",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LQM",
    "lat": "-0.12003",
    "lon": "-74.8204",
    "name": "Puerto Leguizamo Airport",
    "city": "Puerto Legu\u00edzamo",
    "state": "Putumayo",
    "country": "Colombia",
    "woeid": "12524491",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3902",
    "elev": "765",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LRD",
    "lat": "27.5431",
    "lon": "-99.4555",
    "name": "Laredo International Airport",
    "city": "Laredo",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520571",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8201",
    "elev": "508",
    "icao": "KLRD",
    "direct_flights": "13",
    "carriers": "15"
  },
  {
    "code": "LRE",
    "lat": "-23.4375",
    "lon": "144.278",
    "name": "Longreach Aerodrome",
    "city": "Longreach",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510721",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "630",
    "icao": "YLRE",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "LRH",
    "lat": "46.1766",
    "lon": "-1.19372",
    "name": "Laleu Airport",
    "city": "La Rochelle",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "12512909",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7021",
    "elev": "72",
    "icao": "",
    "direct_flights": "13",
    "carriers": "6"
  },
  {
    "code": "LRM",
    "lat": "18.4103",
    "lon": "-68.9417",
    "name": "La Romana Airport",
    "city": "La Romana",
    "state": "Romana",
    "country": "Dominican Republic",
    "woeid": "12512618",
    "tz": "America\/Santo_Domingo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6578",
    "elev": "27",
    "icao": "MDLR",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "LRR",
    "lat": "27.6868",
    "lon": "54.3334",
    "name": "Lar",
    "city": "Lar",
    "state": "Fars",
    "country": "Iran",
    "woeid": "2254868",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "LRS",
    "lat": "37.1847",
    "lon": "26.8017",
    "name": "Leros Airport",
    "city": "Lero",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513306",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2198",
    "elev": "36",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "LRT",
    "lat": "47.7545",
    "lon": "-3.43763",
    "name": "Lann Bihoue Airport",
    "city": "Ploemeur",
    "state": "Brittany",
    "country": "France",
    "woeid": "12512912",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7316",
    "elev": "161",
    "icao": "LFRH",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LSA",
    "lat": "-6.65619",
    "lon": "145.859",
    "name": "Papua New Guinea",
    "city": "Losuia",
    "state": null,
    "country": "Papua New Guinea",
    "woeid": "23424926",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5593",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LSC",
    "lat": "-29.9111",
    "lon": "-71.1978",
    "name": "La Florida Airport",
    "city": "Compa\u00f1\u00eda Alta",
    "state": "Coquimbo",
    "country": "Chile",
    "woeid": "12512327",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6358",
    "elev": "479",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "LSE",
    "lat": "43.8751",
    "lon": "-91.2638",
    "name": "La Crosse Municipal Airport",
    "city": "La Crosse",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12520505",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8536",
    "elev": "654",
    "icao": "KLSE",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "LSH",
    "lat": "22.9767",
    "lon": "97.7558",
    "name": "Lashio Airport",
    "city": "Lashio",
    "state": "Shan State",
    "country": "Myanmar",
    "woeid": "12510920",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5252",
    "elev": "2450",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LSI",
    "lat": "59.877",
    "lon": "-1.29733",
    "name": "Sumburgh Airport",
    "city": "Shetland",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22472492",
    "tz": "Europe\/London",
    "phone": "+44(0) 1950 461000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hial.co.uk\/sumburgh-airport.html",
    "runway_length": "4678",
    "elev": "18",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "LSP",
    "lat": "11.7794",
    "lon": "-70.1525",
    "name": "Josefa Camejo Airport",
    "city": "Las Piedras",
    "state": "Falcon",
    "country": "Venezuela",
    "woeid": "12522797",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "75",
    "icao": "",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "LSS",
    "lat": "15.8686",
    "lon": "-61.5778",
    "name": "Terre-De-Haut Airport",
    "city": "Trois-Rivi\u00e8res",
    "state": "Basse-Terre",
    "country": "Guadeloupe",
    "woeid": "12513279",
    "tz": "America\/Guadeloupe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LST",
    "lat": "-41.5469",
    "lon": "147.213",
    "name": "Launceston Airport",
    "city": "Blessington",
    "state": "Tasmania",
    "country": "Australia",
    "woeid": "12510712",
    "tz": "Australia\/Hobart",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "-10",
    "elev": "6500",
    "icao": "YMLT",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "LSY",
    "lat": "-28.8249",
    "lon": "153.259",
    "name": "Lismore Airport",
    "city": "Lismore",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510718",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3930",
    "elev": "35",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LTD",
    "lat": "30.147",
    "lon": "9.50288",
    "name": "Ghadames",
    "city": "Ghadames",
    "state": "Ghadamis",
    "country": "Libya",
    "woeid": "1352905",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5740",
    "elev": "1070",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LTI",
    "lat": "44.9167",
    "lon": "95.4167",
    "name": "Altay",
    "city": "Altai",
    "state": "Govi-Altay",
    "country": "Mongolia",
    "woeid": "2265164",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LTK",
    "lat": "35.4028",
    "lon": "35.95",
    "name": "Latakia Airport",
    "city": "Djeble",
    "state": "Al Ladhiqiyah",
    "country": "Syria",
    "woeid": "12517705",
    "tz": "Asia\/Damascus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "157",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "LTN",
    "lat": "51.8796",
    "lon": "-0.37548",
    "name": "London Luton Airport",
    "city": "Luton",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22477104",
    "tz": "Europe\/London",
    "phone": "+44 (0) 1582 405 100",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.london-luton.co.uk\/",
    "runway_length": "7087",
    "elev": "528",
    "icao": "EGGW",
    "direct_flights": "78",
    "carriers": "11"
  },
  {
    "code": "LTO",
    "lat": "25.9858",
    "lon": "-111.353",
    "name": "Loreto Airport",
    "city": "Comond\u00fa",
    "state": "Baja California Sur",
    "country": "Mexico",
    "woeid": "12514914",
    "tz": "America\/Mazatlan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "10",
    "icao": "MMLT",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "LTT",
    "lat": "43.2037",
    "lon": "6.47477",
    "name": "La Mole Airport",
    "city": "Grimaud",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "12512904",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.st-tropez-airport.com\/",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LTX",
    "lat": "0.933333",
    "lon": "-78.6167",
    "name": "",
    "city": "Latacunga",
    "state": "Cotopaxi",
    "country": "Ecuador",
    "woeid": "375752",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LUA",
    "lat": "27.6833",
    "lon": "86.7333",
    "name": "Lukla Airport",
    "city": "Solukhumbu",
    "state": "Central",
    "country": "Nepal",
    "woeid": "12523168",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1476",
    "elev": "9100",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LUD",
    "lat": "-26.6839",
    "lon": "15.245",
    "name": "Luderitz Airport",
    "city": "Luderitz",
    "state": "Karas",
    "country": "Namibia",
    "woeid": "12522980",
    "tz": "Africa\/Windhoek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6004",
    "elev": "426",
    "icao": "KLUD",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LUF",
    "lat": "33.5355",
    "lon": "-112.372",
    "name": "Luke Air Force Base",
    "city": "Webb",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12520723",
    "tz": "America\/Phoenix",
    "phone": "623 856 6376",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "9910",
    "elev": "1085",
    "icao": "KLUF",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "LUG",
    "lat": "46.0013",
    "lon": "8.90909",
    "name": "Lugano Airport",
    "city": "Agno",
    "state": "Canton of Ticino",
    "country": "Switzerland",
    "woeid": "23354864",
    "tz": "Europe\/Zurich",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "915",
    "icao": "KLUG",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "LUM",
    "lat": "24.4833",
    "lon": "98.5167",
    "name": "Mangshi",
    "city": "Luxi",
    "state": "Yunnan",
    "country": "China",
    "woeid": "2160712",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KLUM",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LUN",
    "lat": "-15.3292",
    "lon": "28.4528",
    "name": "Lusaka International Airport",
    "city": "Lusaka",
    "state": "Lusaka",
    "country": "Zambia",
    "woeid": "12523011",
    "tz": "Africa\/Lusaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13000",
    "elev": "3779",
    "icao": "FLLS",
    "direct_flights": "15",
    "carriers": "14"
  },
  {
    "code": "LUO",
    "lat": "-11.791",
    "lon": "19.9046",
    "name": "Luena",
    "city": "Luena",
    "state": "Moxico",
    "country": "Angola",
    "woeid": "1261966",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "4360",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LUP",
    "lat": "21.2077",
    "lon": "-156.976",
    "name": "Kalaupapa Airport",
    "city": "Kalaupapa",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "29387738",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2760",
    "elev": "26",
    "icao": "PHLU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LUQ",
    "lat": "-33.2744",
    "lon": "-66.3586",
    "name": "San Luis Airport",
    "city": "Villa General Roca",
    "state": "San Luis",
    "country": "Argentina",
    "woeid": "12510554",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "2329",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LUR",
    "lat": "68.8482",
    "lon": "-166.118",
    "name": "Cape Lisburne Long-Range Radar Station",
    "city": "Point Hope",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519054",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "5009",
    "elev": "12",
    "icao": "PALU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LUV",
    "lat": "0.10974",
    "lon": "113.917",
    "name": "Indonesia",
    "city": "Langgur",
    "state": "",
    "country": "Indonesia",
    "woeid": "23424846",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4363",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LUX",
    "lat": "49.6269",
    "lon": "6.20685",
    "name": "Luxembourg Airport",
    "city": "Sandweiler",
    "state": "Luxemburg",
    "country": "Luxembourg",
    "woeid": "12514635",
    "tz": "Europe\/Luxembourg",
    "phone": "+352 2464 1",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.luxair.lu",
    "runway_length": "13123",
    "elev": "1234",
    "icao": "ELLX",
    "direct_flights": "119",
    "carriers": "29"
  },
  {
    "code": "LVI",
    "lat": "-17.8208",
    "lon": "25.8225",
    "name": "Livingstone Airport",
    "city": "Livingstone",
    "state": "Southern",
    "country": "Zambia",
    "woeid": "12523009",
    "tz": "Africa\/Lusaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7520",
    "elev": "3250",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "LVO",
    "lat": "-28.6083",
    "lon": "122.417",
    "name": "Laverton Aerodrome",
    "city": "Laverton",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510713",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4606",
    "elev": "1525",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LWB",
    "lat": "37.8571",
    "lon": "-80.4044",
    "name": "Greenbrier Valley Airport",
    "city": "Lewisburg",
    "state": "West Virginia",
    "country": "United States",
    "woeid": "12519993",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7004",
    "elev": "2303",
    "icao": "KLWB",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "LWE",
    "lat": "0.10974",
    "lon": "113.917",
    "name": "Indonesia",
    "city": "Lewoleba",
    "state": "",
    "country": "Indonesia",
    "woeid": "23424846",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LWN",
    "lat": "40.7859",
    "lon": "43.8439",
    "name": "Gyumri Airport",
    "city": "Gyumri",
    "state": "Shirak",
    "country": "Armenia",
    "woeid": "12523169",
    "tz": "Asia\/Yerevan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UDSG",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "LWO",
    "lat": "49.7977",
    "lon": "23.9695",
    "name": "Sknilov Airport",
    "city": "L'viv",
    "state": "L\u00b4vivs\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518429",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8235",
    "elev": "1063",
    "icao": "UKLL",
    "direct_flights": "13",
    "carriers": "13"
  },
  {
    "code": "LWS",
    "lat": "46.3776",
    "lon": "-117.011",
    "name": "Lewiston Nez Perce County Airport",
    "city": "Lewiston",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12520635",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6512",
    "elev": "1438",
    "icao": "KLWS",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "LWT",
    "lat": "47.0552",
    "lon": "-109.46",
    "name": "Lewistown Municipal Airport",
    "city": "Lewistown",
    "state": "Montana",
    "country": "United States",
    "woeid": "12520636",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5600",
    "elev": "4167",
    "icao": "KLWT",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "LWY",
    "lat": "4.8615",
    "lon": "115.406",
    "name": "Lawas",
    "city": "Lawas",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "1154824",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1700",
    "elev": "5",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LXA",
    "lat": "29.6355",
    "lon": "91.1646",
    "name": "Lhasa",
    "city": "Lhasa",
    "state": "\u897f\u85cf\u81ea\u6cbb\u533a",
    "country": "China",
    "woeid": "12714232",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZULS",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "LXG",
    "lat": "20.9667",
    "lon": "101.4",
    "name": "Luang Namtha",
    "city": "Luang Namtha",
    "state": "Louangnamtha",
    "country": "Lao People's Democratic Republic",
    "woeid": "1140558",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4330",
    "elev": "1968",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "LXR",
    "lat": "25.675",
    "lon": "32.7075",
    "name": "Luxor Airport",
    "city": "Luxor",
    "state": "Qina",
    "country": "Egypt",
    "woeid": "12512697",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "288",
    "icao": "",
    "direct_flights": "16",
    "carriers": "13"
  },
  {
    "code": "LXS",
    "lat": "39.9214",
    "lon": "25.2417",
    "name": "Limnos Airport",
    "city": "Moudhros",
    "state": "Voreio Aigaio",
    "country": "Greece",
    "woeid": "12513307",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "15",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "LYA",
    "lat": "34.6833",
    "lon": "112.467",
    "name": "Luoyang Airport",
    "city": "Luoyang",
    "state": "Henan",
    "country": "China",
    "woeid": "12523313",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "LYB",
    "lat": "19.6901",
    "lon": "-80.0385",
    "name": "Boddenfield Airport",
    "city": "Little Cayman",
    "state": "Little Cayman",
    "country": "Cayman Islands",
    "woeid": "12512343",
    "tz": "America\/Cayman",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3311",
    "elev": "4",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LYC",
    "lat": "64.5501",
    "lon": "18.7082",
    "name": "Lycksele Airport",
    "city": "Lyoksele",
    "state": "Vasterbotten",
    "country": "Sweden",
    "woeid": "12517660",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3609",
    "elev": "692",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "LYG",
    "lat": "34.5995",
    "lon": "119.141",
    "name": "Lianyungang",
    "city": "Lianyungang",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "2137087",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "LYH",
    "lat": "37.3309",
    "lon": "-79.1944",
    "name": "Lynchburg Regional Airport",
    "city": "Lynchburg",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12520730",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5799",
    "elev": "938",
    "icao": "KLYH",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "LYI",
    "lat": "35.0631",
    "lon": "118.343",
    "name": "Linyi",
    "city": "Linyi",
    "state": "Shandong",
    "country": "China",
    "woeid": "2168342",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "6"
  },
  {
    "code": "LYP",
    "lat": "31.3692",
    "lon": "72.9981",
    "name": "Faisalabad Airport",
    "city": "Shah Faisalabad",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "12515233",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9300",
    "elev": "607",
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "LYR",
    "lat": "78.1917",
    "lon": "15.9",
    "name": "Svalbard Longyear Airport",
    "city": "Longyearbyen",
    "state": "Svalbard",
    "country": "Norway",
    "woeid": "12517605",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7021",
    "elev": "94",
    "icao": "ENSB",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "LYS",
    "lat": "45.7198",
    "lon": "5.08245",
    "name": "Lyon Airport",
    "city": "Colombier",
    "state": "Rhone-Alpes",
    "country": "France",
    "woeid": "12512963",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lyon.aeroport.fr",
    "runway_length": "13123",
    "elev": "814",
    "icao": "LFLL",
    "direct_flights": "89",
    "carriers": "54"
  },
  {
    "code": "LZC",
    "lat": "17.9889",
    "lon": "-102.219",
    "name": "Lazaro Cardenas Airport",
    "city": "Arteaga",
    "state": "Michoacan de Ocampo",
    "country": "Mexico",
    "woeid": "12514908",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4922",
    "elev": "36",
    "icao": "MMLC",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "LZH",
    "lat": "24.2717",
    "lon": "109.372",
    "name": "Liuzhou Airport",
    "city": "Linzhou",
    "state": "Guangxi",
    "country": "China",
    "woeid": "12512131",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "LZN",
    "lat": "26.1497",
    "lon": "119.939",
    "name": "Nankan",
    "city": "Nangan",
    "state": "Fujian",
    "country": "Taiwan",
    "woeid": "28752831",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "LZO",
    "lat": "28.8516",
    "lon": "105.401",
    "name": "Luzhou Airport",
    "city": "Luzhou",
    "state": "Sichuan",
    "country": "China",
    "woeid": "12512139",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "MAA",
    "lat": "12.9849",
    "lon": "80.1634",
    "name": "Chennai International Airport",
    "city": "Kanchipuram",
    "state": "Tamil Nadu",
    "country": "India",
    "woeid": "12513629",
    "tz": "Asia\/Kolkata",
    "phone": "044-2340551",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/chennaiairport.com",
    "runway_length": "10050",
    "elev": "50",
    "icao": "VOMM",
    "direct_flights": "44",
    "carriers": "42"
  },
  {
    "code": "MAB",
    "lat": "-5.3533",
    "lon": "-49.1336",
    "name": "Maraba Airport",
    "city": "Marab\u00e1",
    "state": "Para",
    "country": "Brazil",
    "woeid": "12511224",
    "tz": "America\/Araguaina",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6575",
    "elev": "358",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MAD",
    "lat": "40.4684",
    "lon": "-3.56769",
    "name": "Barajas Airport",
    "city": "Madrid",
    "state": "Madrid",
    "country": "Spain",
    "woeid": "12517539",
    "tz": "Europe\/Madrid",
    "phone": "00 34 902 404 704",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13451",
    "elev": "1998",
    "icao": "LEMD",
    "direct_flights": "186",
    "carriers": "93"
  },
  {
    "code": "MAF",
    "lat": "31.9361",
    "lon": "-102.208",
    "name": "Midland International Airport",
    "city": "Midland",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520944",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9501",
    "elev": "2871",
    "icao": "KMAF",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "MAG",
    "lat": "-5.21",
    "lon": "145.787",
    "name": "Madang Airport",
    "city": "Madang",
    "state": "Madang",
    "country": "Papua New Guinea",
    "woeid": "12515472",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5150",
    "elev": "20",
    "icao": "",
    "direct_flights": "9",
    "carriers": "1"
  },
  {
    "code": "MAH",
    "lat": "39.8652",
    "lon": "4.22383",
    "name": "Menorca Airport",
    "city": "Mao",
    "state": "Balearic Islands",
    "country": "Spain",
    "woeid": "12517557",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7710",
    "elev": "287",
    "icao": "",
    "direct_flights": "28",
    "carriers": "18"
  },
  {
    "code": "MAJ",
    "lat": "7.0605",
    "lon": "171.275",
    "name": "Marshall Islands International Airport",
    "city": "Majuro",
    "state": "Majuro",
    "country": "Marshall Islands",
    "woeid": "12515494",
    "tz": "Pacific\/Kwajalein",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7900",
    "elev": "6",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "MAK",
    "lat": "9.5556",
    "lon": "31.6444",
    "name": "Malakal Airport",
    "city": "Malakal",
    "state": "A ali an Nil",
    "country": "Sudan",
    "woeid": "12517602",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6600",
    "elev": "1270",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MAM",
    "lat": "25.7694",
    "lon": "-97.5239",
    "name": "General Sevando Canales Airport",
    "city": "Matamoros",
    "state": "Tamaulipas",
    "country": "Mexico",
    "woeid": "12514886",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "27",
    "icao": "MMMA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MAN",
    "lat": "53.365",
    "lon": "-2.27089",
    "name": "Manchester International Airport",
    "city": "Manchester",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22478032",
    "tz": "Europe\/London",
    "phone": "+44 (0) 161 489 3000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.manchesterairport.co.uk",
    "runway_length": "10000",
    "elev": "256",
    "icao": "EGCC",
    "direct_flights": "129",
    "carriers": "79"
  },
  {
    "code": "MAO",
    "lat": "-3.0392",
    "lon": "-60.0469",
    "name": "Eduardo Gomes International Airport",
    "city": "Manaus",
    "state": "Amazonas",
    "country": "Brazil",
    "woeid": "12511115",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "279",
    "icao": "KMAO",
    "direct_flights": "15",
    "carriers": "11"
  },
  {
    "code": "MAR",
    "lat": "10.5614",
    "lon": "-71.7253",
    "name": "La Chinita International Airport",
    "city": "Maracaibo",
    "state": "Zulia",
    "country": "Venezuela",
    "woeid": "12522800",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "235",
    "icao": "SVMC",
    "direct_flights": "8",
    "carriers": "9"
  },
  {
    "code": "MAS",
    "lat": "-2.1052",
    "lon": "146.926",
    "name": "Manus Island Airport",
    "city": "Lorengau",
    "state": "Manus",
    "country": "Papua New Guinea",
    "woeid": "12515473",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7020",
    "elev": "12",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MAU",
    "lat": "-16.4311",
    "lon": "-152.28",
    "name": "Society Islands Airport",
    "city": "Papeete",
    "state": "Leeward Islands",
    "country": "French Polynesia",
    "woeid": "12512830",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2673",
    "elev": "13",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MAZ",
    "lat": "18.2531",
    "lon": "-67.1489",
    "name": "Eugenio Maria de Hostos Airport",
    "city": "Mayaguez",
    "state": "Puerto Rico",
    "country": "United States",
    "woeid": "12515660",
    "tz": "America\/Puerto_Rico",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "29",
    "icao": "TJMZ",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MBA",
    "lat": "-4.0314",
    "lon": "39.5936",
    "name": "Moi International Airport",
    "city": "Mombasa",
    "state": "Coast",
    "country": "Kenya",
    "woeid": "12514073",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kenyaairports.co.ke\/mombasa\/indexMoi.htm",
    "runway_length": "10991",
    "elev": "196",
    "icao": "HKMO",
    "direct_flights": "17",
    "carriers": "14"
  },
  {
    "code": "MBD",
    "lat": "-25.8",
    "lon": "25.545",
    "name": "Mmabatho International Airport",
    "city": "Mmabatho",
    "state": "North-west",
    "country": "South Africa",
    "woeid": "12517447",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "14928",
    "elev": "4189",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MBE",
    "lat": "44.3046",
    "lon": "143.404",
    "name": "Okhotsk-Monbetsu Airport",
    "city": "Monbetsu-shi",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "28360513",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MBH",
    "lat": "-25.515",
    "lon": "152.714",
    "name": "Maryborough Airport",
    "city": "Maryborough",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510730",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "38",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MBJ",
    "lat": "18.5042",
    "lon": "-77.9125",
    "name": "Sangster International Airport",
    "city": "Montego Bay",
    "state": "Saint James",
    "country": "Jamaica",
    "woeid": "12514046",
    "tz": "America\/Jamaica",
    "phone": "(876) 952-3124",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.mbjairport.com",
    "runway_length": "8700",
    "elev": "4",
    "icao": "MKJS",
    "direct_flights": "39",
    "carriers": "24"
  },
  {
    "code": "MBL",
    "lat": "44.2757",
    "lon": "-86.2558",
    "name": "Manistee County-Blacker Airport",
    "city": "Manistee",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12520778",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5502",
    "elev": "620",
    "icao": "KMBL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MBS",
    "lat": "43.5311",
    "lon": "-84.0933",
    "name": "MBS International Airport",
    "city": "Freeland",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12522191",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "668",
    "icao": "KMBS",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "MBT",
    "lat": "12.3703",
    "lon": "123.628",
    "name": "Masbate Airport",
    "city": "Masbate",
    "state": "Bicol Region",
    "country": "Philippines",
    "woeid": "12515634",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "33",
    "icao": "KMBT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MBU",
    "lat": "-9.73333",
    "lon": "160.75",
    "name": "Mbambanakira",
    "city": "Mbambanakira",
    "state": "Guadalcanal",
    "country": "Solomon Islands",
    "woeid": "1020499",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2217",
    "elev": "70",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MCE",
    "lat": "37.2892",
    "lon": "-120.515",
    "name": "Merced Municipal Airport-Macready Field",
    "city": "Merced",
    "state": "California",
    "country": "United States",
    "woeid": "12520900",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5903",
    "elev": "153",
    "icao": "KMCE",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MCG",
    "lat": "62.9536",
    "lon": "-155.603",
    "name": "Mcgrath Airport",
    "city": "Mcgrath",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12520858",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5481",
    "elev": "337",
    "icao": "PAMC",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "MCI",
    "lat": "39.2992",
    "lon": "-94.7171",
    "name": "Kansas City International Airport",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12520420",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flykci.com\/",
    "runway_length": "10801",
    "elev": "1025",
    "icao": "KMCI",
    "direct_flights": "64",
    "carriers": "41"
  },
  {
    "code": "MCK",
    "lat": "40.2071",
    "lon": "-100.599",
    "name": "Mccook Municipal Airport",
    "city": "Mccook",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12520873",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5998",
    "elev": "2579",
    "icao": "KMCK",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "MCM",
    "lat": "43.7278",
    "lon": "7.41875",
    "name": "Monte Carlo Heliport",
    "city": "Monaco-Ville",
    "state": "La Condamine",
    "country": "Monaco",
    "woeid": "12523920",
    "tz": "Europe\/Monaco",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MCN",
    "lat": "32.7023",
    "lon": "-83.65",
    "name": "Middle Georgia Regional Airport",
    "city": "Macon",
    "state": "Georgia",
    "country": "United States",
    "woeid": "12520939",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6501",
    "elev": "354",
    "icao": "KMCN",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MCO",
    "lat": "28.4418",
    "lon": "-81.3115",
    "name": "Orlando International Airport",
    "city": "Orlando",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521243",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12004",
    "elev": "96",
    "icao": "KMCO",
    "direct_flights": "106",
    "carriers": "63"
  },
  {
    "code": "MCP",
    "lat": "0.0525",
    "lon": "-51.0675",
    "name": "Macapa International Airport",
    "city": "Macap\u00e1",
    "state": "Amapa",
    "country": "Brazil",
    "woeid": "12511218",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6847",
    "elev": "52",
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "MCT",
    "lat": "23.5917",
    "lon": "58.2792",
    "name": "Seeb International Airport",
    "city": "Muscat",
    "state": "Masqat",
    "country": "Oman",
    "woeid": "12514813",
    "tz": "Asia\/Muscat",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11762",
    "elev": "48",
    "icao": "OOMS",
    "direct_flights": "36",
    "carriers": "33"
  },
  {
    "code": "MCV",
    "lat": "47.452",
    "lon": "-122.288",
    "name": "",
    "city": "Mcarthur River",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "55864519",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5180",
    "elev": "125",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MCW",
    "lat": "43.153",
    "lon": "-93.3361",
    "name": "Mason City Municipal Airport",
    "city": "Clear Lake",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12520832",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6501",
    "elev": "1213",
    "icao": "KMCW",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MCX",
    "lat": "42.8209",
    "lon": "47.6288",
    "name": "Makhachkala-Uytash Airport",
    "city": "Khasavyurt",
    "state": "Dagestan",
    "country": "Russia",
    "woeid": "12516386",
    "tz": "Europe\/Volgograd",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KMCX",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "MCY",
    "lat": "-26.6035",
    "lon": "153.091",
    "name": "Maroochydore Aerodrome",
    "city": "Mudjimba",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510729",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "15",
    "icao": "YBMC",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "MCZ",
    "lat": "-9.5117",
    "lon": "-35.8",
    "name": "Zumbi dos Palmares International Airport",
    "city": "Maceio",
    "state": "Alagoas",
    "country": "Brazil",
    "woeid": "12511069",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "377",
    "icao": "KMCZ",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "MDC",
    "lat": "1.5486",
    "lon": "124.926",
    "name": "Sam Ratulangi Airport",
    "city": "Manado",
    "state": "North Sulawesi",
    "country": "Indonesia",
    "woeid": "12513501",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "262",
    "icao": "",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "MDE",
    "lat": "6.1675",
    "lon": "-75.4267",
    "name": "Jose Maria Cordova Airport",
    "city": "R\u00edonegro",
    "state": "Antioquia",
    "country": "Colombia",
    "woeid": "12512386",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "7028",
    "icao": "SKRG",
    "direct_flights": "12",
    "carriers": "11"
  },
  {
    "code": "MDG",
    "lat": "44.6",
    "lon": "129.5",
    "name": "Mudanjiang",
    "city": "Mudanjiang",
    "state": "\u9ed1\u9f99\u6c5f\u7701",
    "country": "China",
    "woeid": "12712423",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "MDK",
    "lat": "0.0233",
    "lon": "18.2922",
    "name": "Mbandaka Airport",
    "city": "Mbandaka",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511971",
    "tz": "Africa\/Kinshasa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7430",
    "elev": "1040",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MDL",
    "lat": "21.9392",
    "lon": "96.0914",
    "name": "Mandalay Airport",
    "city": "Mandalay",
    "state": "Mandalay",
    "country": "Myanmar",
    "woeid": "12510922",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "14000",
    "elev": "300",
    "icao": "",
    "direct_flights": "11",
    "carriers": "4"
  },
  {
    "code": "MDQ",
    "lat": "-37.9347",
    "lon": "-57.5764",
    "name": "Mar del Plata Airport",
    "city": "Mar del Plata",
    "state": "Buenos Aires",
    "country": "Argentina",
    "woeid": "12510518",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "72",
    "icao": "KMDQ",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MDS",
    "lat": "21.7905",
    "lon": "-71.7664",
    "name": "Middle Caicos Airport",
    "city": "Lorimers",
    "state": "Middle Caicos",
    "country": "Turks And Caicos Islands",
    "woeid": "12524422",
    "tz": "America\/Grand_Turk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "10",
    "icao": "KMDS",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MDT",
    "lat": "40.1962",
    "lon": "-76.7564",
    "name": "Harrisburg International Airport",
    "city": "Middletown",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12520098",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9501",
    "elev": "310",
    "icao": "KMDT",
    "direct_flights": "18",
    "carriers": "22"
  },
  {
    "code": "MDU",
    "lat": "-6.65619",
    "lon": "145.859",
    "name": "Papua New Guinea",
    "city": "Mendi",
    "state": null,
    "country": "Papua New Guinea",
    "woeid": "23424926",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4395",
    "elev": "5500",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MDW",
    "lat": "41.7875",
    "lon": "-87.7416",
    "name": "Chicago Midway International Airport",
    "city": "Chicago",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12519178",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6519",
    "elev": "619",
    "icao": "KMDW",
    "direct_flights": "55",
    "carriers": "8"
  },
  {
    "code": "MDZ",
    "lat": "-32.8319",
    "lon": "-68.7847",
    "name": "El Plumerillo Airport",
    "city": "Mendoza",
    "state": "Mendoza",
    "country": "Argentina",
    "woeid": "12510491",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9885",
    "elev": "2312",
    "icao": "KMDZ",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "MEA",
    "lat": "-22.3422",
    "lon": "-41.7633",
    "name": "Macae Airport",
    "city": "Macae",
    "state": "Rio de Janeiro",
    "country": "Brazil",
    "woeid": "12511217",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "7",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MEC",
    "lat": "-0.95",
    "lon": "-80.6833",
    "name": "Eloy Alfaro Airport",
    "city": "Manta",
    "state": "Manabi",
    "country": "Ecuador",
    "woeid": "12512629",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "40",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "MED",
    "lat": "24.5517",
    "lon": "39.7025",
    "name": "Madinah International Airport",
    "city": "Al Madinah",
    "state": "Al Madinah",
    "country": "Saudi Arabia",
    "woeid": "12517353",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "2144",
    "icao": "",
    "direct_flights": "26",
    "carriers": "8"
  },
  {
    "code": "MEE",
    "lat": "-21.5021",
    "lon": "167.964",
    "name": "Loyalty Islands Airport",
    "city": "Tadine",
    "state": "Loyaute",
    "country": "New Caledonia",
    "woeid": "12515036",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "141",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "MEG",
    "lat": "-9.5236",
    "lon": "16.3158",
    "name": "Malanje Airport",
    "city": "Malanje",
    "state": "Malanje",
    "country": "Angola",
    "woeid": "12510442",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7267",
    "elev": "3865",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MEH",
    "lat": "71.0333",
    "lon": "27.8333",
    "name": "Mehamn Airport",
    "city": "Mehavn",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523940",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "41",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "MEI",
    "lat": "32.3334",
    "lon": "-88.7449",
    "name": "Key Field Airport",
    "city": "Meridian",
    "state": "Mississippi",
    "country": "United States",
    "woeid": "12520455",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8004",
    "elev": "297",
    "icao": "KMEI",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "MEL",
    "lat": "-37.6759",
    "lon": "144.844",
    "name": "Melbourne International Airport",
    "city": "Melbourne",
    "state": "Victoria",
    "country": "Australia",
    "woeid": "23388202",
    "tz": "Australia\/Melbourne",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12000",
    "elev": "434",
    "icao": "YMML",
    "direct_flights": "53",
    "carriers": "44"
  },
  {
    "code": "MEM",
    "lat": "35.047",
    "lon": "-89.9823",
    "name": "Memphis International Airport",
    "city": "Memphis",
    "state": "Tennessee",
    "country": "United States",
    "woeid": "12520895",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9319",
    "elev": "332",
    "icao": "KMEM",
    "direct_flights": "96",
    "carriers": "28"
  },
  {
    "code": "MES",
    "lat": "3.5606",
    "lon": "98.6708",
    "name": "Polonia Airport",
    "city": "Medan",
    "state": "Sumatera Utara",
    "country": "Indonesia",
    "woeid": "12513497",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9715",
    "elev": "89",
    "icao": "WIMM",
    "direct_flights": "10",
    "carriers": "14"
  },
  {
    "code": "MEX",
    "lat": "19.4344",
    "lon": "-99.0742",
    "name": "Lic Benito Juarez International Airport",
    "city": "Mexico City",
    "state": "Distrito Federal",
    "country": "Mexico",
    "woeid": "12514910",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aicm.com.mx\/",
    "runway_length": "12619",
    "elev": "7341",
    "icao": "MMMX",
    "direct_flights": "98",
    "carriers": "42"
  },
  {
    "code": "MEY",
    "lat": "40.1494",
    "lon": "-91.5043",
    "name": "",
    "city": "Meghauli",
    "state": "",
    "country": "Nepal",
    "woeid": "23424911",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "600",
    "icao": "KMEY",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MFE",
    "lat": "26.1813",
    "lon": "-98.2404",
    "name": "Miller International Airport",
    "city": "Mcallen",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520953",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7108",
    "elev": "107",
    "icao": "KMFE",
    "direct_flights": "4",
    "carriers": "11"
  },
  {
    "code": "MFJ",
    "lat": "-18.6045",
    "lon": "179.924",
    "name": "Moala Airport",
    "city": "Moala",
    "state": "Eastern",
    "country": "Fiji",
    "woeid": "12512811",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2510",
    "elev": "13",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MFK",
    "lat": "23.5998",
    "lon": "121.024",
    "name": "Taiwan",
    "city": "Matsu",
    "state": null,
    "country": "Taiwan",
    "woeid": "23424971",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MFM",
    "lat": "22.1845",
    "lon": "113.532",
    "name": "Macau Airport",
    "city": "Macau",
    "state": "Macau",
    "country": "Macau",
    "woeid": "12523336",
    "tz": "Asia\/Macau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3214",
    "elev": "1",
    "icao": "VMMC",
    "direct_flights": "31",
    "carriers": "20"
  },
  {
    "code": "MFR",
    "lat": "42.3691",
    "lon": "-122.874",
    "name": "Rogue Valley International-Medford Airport",
    "city": "Central Point",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12520884",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8800",
    "elev": "1331",
    "icao": "KMFR",
    "direct_flights": "10",
    "carriers": "13"
  },
  {
    "code": "MFU",
    "lat": "-13.2597",
    "lon": "31.9347",
    "name": "Mfuwe Airport",
    "city": "Mfuwe",
    "state": "Eastern",
    "country": "Zambia",
    "woeid": "12523013",
    "tz": "Africa\/Lusaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7200",
    "elev": "1880",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "MGA",
    "lat": "12.1411",
    "lon": "-86.1686",
    "name": "Augusto Cesar Sandino International Airport",
    "city": "Tipitapa",
    "state": "Managua",
    "country": "Nicaragua",
    "woeid": "12515143",
    "tz": "America\/Managua",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.eaai.com.ni\/english\/index.shtml",
    "runway_length": "7999",
    "elev": "194",
    "icao": "MNMG",
    "direct_flights": "10",
    "carriers": "15"
  },
  {
    "code": "MGB",
    "lat": "-37.7466",
    "lon": "140.787",
    "name": "Mount Gambier Airport",
    "city": "Mount Gambier",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "12510744",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "212",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MGF",
    "lat": "-23.4394",
    "lon": "-51.9061",
    "name": "Maringa Domestic Airport",
    "city": "Maringa",
    "state": "Parana",
    "country": "Brazil",
    "woeid": "12511231",
    "tz": "America\/Sao_Paulo",
    "phone": "55-44-3266-3838",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroportomaringa.com.br",
    "runway_length": "5250",
    "elev": "1821",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "MGH",
    "lat": "-30.8569",
    "lon": "30.3417",
    "name": "Margate Airport",
    "city": "Port Shepstone",
    "state": "Kwazulu Natal",
    "country": "South Africa",
    "woeid": "12517442",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4498",
    "elev": "495",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MGM",
    "lat": "32.3049",
    "lon": "-86.3909",
    "name": "Montgomery Regional Airport",
    "city": "Montgomery",
    "state": "Alabama",
    "country": "United States",
    "woeid": "12519409",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9001",
    "elev": "221",
    "icao": "KMGM",
    "direct_flights": "4",
    "carriers": "9"
  },
  {
    "code": "MGQ",
    "lat": "2.0136",
    "lon": "45.3047",
    "name": "Mogadishu Airport",
    "city": "Mogadishu",
    "state": "Banaadir",
    "country": "Somalia",
    "woeid": "12517532",
    "tz": "Africa\/Mogadishu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10335",
    "elev": "27",
    "icao": "",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "MGS",
    "lat": "-21.9103",
    "lon": "-157.921",
    "name": "Mangaia",
    "city": "Mangaia Island",
    "state": "Mangaia",
    "country": "Cook Islands",
    "woeid": "12489418",
    "tz": "Pacific\/Rarotonga",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3275",
    "elev": "45",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MGT",
    "lat": "-18.5572",
    "lon": "133.501",
    "name": "Northern Territory",
    "city": "Milingimbi",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "2344701",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4130",
    "elev": "47",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MGW",
    "lat": "39.6411",
    "lon": "-79.9241",
    "name": "Morgantown Municipal Airport-Hart Field",
    "city": "Morgantown",
    "state": "West Virginia",
    "country": "United States",
    "woeid": "12521027",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5199",
    "elev": "1248",
    "icao": "KMGW",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MGZ",
    "lat": "12.4458",
    "lon": "98.625",
    "name": "Mergui Airport",
    "city": "Mergui",
    "state": "Tenasserim",
    "country": "Myanmar",
    "woeid": "12510923",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "73",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MHD",
    "lat": "36.2358",
    "lon": "59.6392",
    "name": "Mashhad Airport",
    "city": "Mashhad",
    "state": "Khorasan",
    "country": "Iran",
    "woeid": "12513744",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12497",
    "elev": "3263",
    "icao": "",
    "direct_flights": "28",
    "carriers": "11"
  },
  {
    "code": "MHG",
    "lat": "49.4762",
    "lon": "8.52105",
    "name": "Mannheim City Airport",
    "city": "Mannheim",
    "state": "Baden-Wurttemberg",
    "country": "Germany",
    "woeid": "22243020",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "309",
    "icao": "",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "MHH",
    "lat": "26.5111",
    "lon": "-77.0847",
    "name": "Marsh Harbour Airport",
    "city": "Marsh Harbour",
    "state": "Central Abaco",
    "country": "Bahamas",
    "woeid": "12510872",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "10",
    "icao": "",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "MHK",
    "lat": "39.1368",
    "lon": "-96.6699",
    "name": "Manhattan Municipal Airport",
    "city": "Manhattan",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12520775",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "1056",
    "icao": "KMHK",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "MHP",
    "lat": "53.8713",
    "lon": "27.5352",
    "name": "Minsk International 1",
    "city": "Minsk",
    "state": "Minskaya Voblasts'",
    "country": "Belarus",
    "woeid": "12510966",
    "tz": "Europe\/Minsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KMHP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MHQ",
    "lat": "60.1249",
    "lon": "19.9075",
    "name": "Mariehamn Airport",
    "city": "Maarianhamina",
    "state": "Aland Islands",
    "country": "Finland",
    "woeid": "12512784",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "17",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MHR",
    "lat": "38.5646",
    "lon": "-121.297",
    "name": "Mather Airport",
    "city": "Mather",
    "state": "California",
    "country": "United States",
    "woeid": "12521692",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KMHR",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "MHT",
    "lat": "42.9293",
    "lon": "-71.4386",
    "name": "Manchester-Boston Regional Airport",
    "city": "Manchester",
    "state": "New Hampshire",
    "country": "United States",
    "woeid": "12520772",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7001",
    "elev": "234",
    "icao": "KMHT",
    "direct_flights": "20",
    "carriers": "16"
  },
  {
    "code": "MIA",
    "lat": "25.7953",
    "lon": "-80.2727",
    "name": "Miami International Airport",
    "city": "Miami",
    "state": "Florida",
    "country": "United States",
    "woeid": "12520923",
    "tz": "America\/New_York",
    "phone": "(305) 876-7000",
    "type": "Airports",
    "email": "customerservice@miami-airport.com",
    "url": "http:\/\/www.miami-airport.com",
    "runway_length": "13000",
    "elev": "11",
    "icao": "KMIA",
    "direct_flights": "167",
    "carriers": "91"
  },
  {
    "code": "MID",
    "lat": "20.9361",
    "lon": "-89.6575",
    "name": "Lic M Crecencio Rejon International Airport",
    "city": "M\u00e9rida",
    "state": "Yucatan",
    "country": "Mexico",
    "woeid": "12514912",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "34",
    "icao": "MMMD",
    "direct_flights": "8",
    "carriers": "21"
  },
  {
    "code": "MIG",
    "lat": "31.4833",
    "lon": "104.733",
    "name": "Mian Yang",
    "city": "Mian Yang",
    "state": "Sichuan",
    "country": "China",
    "woeid": "2158439",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "MII",
    "lat": "-22.1953",
    "lon": "-49.9261",
    "name": "Dr Gastao Vidigal Airport",
    "city": "Marilia",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511229",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "2093",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MIM",
    "lat": "-36.9081",
    "lon": "149.903",
    "name": "Merimbula Aerodrome",
    "city": "Merimbula",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12523173",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5255",
    "elev": "7",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MIR",
    "lat": "35.7611",
    "lon": "10.7556",
    "name": "Habib Bourguiba International Airport",
    "city": "Sidi al Ghudamisi",
    "state": "Al Munastir",
    "country": "Tunisia",
    "woeid": "12517860",
    "tz": "Africa\/Tunis",
    "phone": "+216 (0)73 521.300",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.oaca.nat.tn",
    "runway_length": "9678",
    "elev": "9",
    "icao": "",
    "direct_flights": "31",
    "carriers": "10"
  },
  {
    "code": "MIS",
    "lat": "46.2127",
    "lon": "11.9079",
    "name": "Saint Aignan Island",
    "city": "Misima Island",
    "state": "Milne Bay",
    "country": "Papua New Guinea",
    "woeid": "12498877",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3020",
    "elev": "20",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MJA",
    "lat": "-23.3548",
    "lon": "43.6631",
    "name": "Toliara",
    "city": "Manja",
    "state": "Toliara",
    "country": "Madagascar",
    "woeid": "1364703",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "787",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MJD",
    "lat": "27.3364",
    "lon": "68.1417",
    "name": "Moenjodaro Airport",
    "city": "Mohenjodaro",
    "state": "Sindh",
    "country": "Pakistan",
    "woeid": "12515250",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6512",
    "elev": "167",
    "icao": "KMJD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MJF",
    "lat": "65.7833",
    "lon": "13.2167",
    "name": "Kjaerstad Airport",
    "city": "Mosjoen",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523941",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "MJI",
    "lat": "26.3385",
    "lon": "17.2688",
    "name": "Libya",
    "city": "Mitiga",
    "state": "",
    "country": "Libya",
    "woeid": "23424882",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "HLLM",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MJK",
    "lat": "-25.8881",
    "lon": "113.578",
    "name": "Shark Bay Airport",
    "city": "Monkey Mia",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12523287",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "MJL",
    "lat": "-1.56087",
    "lon": "11.1955",
    "name": "Ngounie",
    "city": "Mouila",
    "state": "Ngounie",
    "country": "Gabon",
    "woeid": "2345452",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "295",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MJM",
    "lat": "-6.1214",
    "lon": "23.5697",
    "name": "Mbuji Mayi Airport",
    "city": "Mbuji Mayi",
    "state": "Democratic Republic of",
    "country": "Congo",
    "woeid": "12511972",
    "tz": "Africa\/Lubumbashi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "2221",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "MJN",
    "lat": "-15.6658",
    "lon": "46.3531",
    "name": "Mahajanga Amborovy Airport",
    "city": "Mahajanga",
    "state": "Mahajanga",
    "country": "Madagascar",
    "woeid": "12514698",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "85",
    "icao": "",
    "direct_flights": "10",
    "carriers": "3"
  },
  {
    "code": "MJT",
    "lat": "39.0606",
    "lon": "26.5981",
    "name": "Mitilini Airport",
    "city": "Mitilini",
    "state": "Voreio Aigaio",
    "country": "Greece",
    "woeid": "12513313",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6476",
    "elev": "57",
    "icao": "",
    "direct_flights": "10",
    "carriers": "4"
  },
  {
    "code": "MJV",
    "lat": "37.7697",
    "lon": "-0.82098",
    "name": "Murcia San Javier Airport",
    "city": "San Javier",
    "state": "Murcia",
    "country": "Spain",
    "woeid": "12517559",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "11",
    "icao": "LELC",
    "direct_flights": "29",
    "carriers": "9"
  },
  {
    "code": "MJZ",
    "lat": "62.5168",
    "lon": "113.973",
    "name": "Mirnyy",
    "city": "Mirnyj",
    "state": "Sakha",
    "country": "Russia",
    "woeid": "2046493",
    "tz": "Asia\/Yakutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "MKC",
    "lat": "39.1197",
    "lon": "-94.5906",
    "name": "Kansas City Downtown Airport",
    "city": "Kansas City",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12520419",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "7001",
    "elev": "758",
    "icao": "KMKC",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MKE",
    "lat": "42.9471",
    "lon": "-87.9051",
    "name": "General Mitchell International Airport",
    "city": "Milwaukee",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12519880",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9690",
    "elev": "723",
    "icao": "KMKE",
    "direct_flights": "59",
    "carriers": "30"
  },
  {
    "code": "MKG",
    "lat": "43.1646",
    "lon": "-86.2367",
    "name": "Muskegon County Airport",
    "city": "Muskegon",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12521071",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6501",
    "elev": "628",
    "icao": "KMKG",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "MKK",
    "lat": "21.1557",
    "lon": "-157.094",
    "name": "Molokai Airport",
    "city": "Hoolehua",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520984",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4494",
    "elev": "454",
    "icao": "PHMK",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "MKM",
    "lat": "2.91165",
    "lon": "112.609",
    "name": "Sarawak",
    "city": "Mukah",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "2346305",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2460",
    "elev": "5",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MKP",
    "lat": "-16.5978",
    "lon": "-143.662",
    "name": "Makemo Airport",
    "city": "Makemo",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "12512824",
    "tz": "Pacific\/Gambier",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "3",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "MKQ",
    "lat": "-8.5183",
    "lon": "140.422",
    "name": "Mopah Airport",
    "city": "Merauke",
    "state": "Irian Jaya",
    "country": "Indonesia",
    "woeid": "12513484",
    "tz": "Asia\/Jayapura",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MKR",
    "lat": "-26.6091",
    "lon": "118.551",
    "name": "Meekatharra Airport",
    "city": "Kumarina",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510731",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7150",
    "elev": "1712",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MKU",
    "lat": "0.5792",
    "lon": "12.8922",
    "name": "Makokou Airport",
    "city": "Makokou",
    "state": "Ogooue-Ivindo",
    "country": "Gabon",
    "woeid": "12512995",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1726",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MKW",
    "lat": "-0.8911",
    "lon": "134.051",
    "name": "Rendani Airport",
    "city": "Manokwari",
    "state": "West Irian Jaya",
    "country": "Indonesia",
    "woeid": "12513500",
    "tz": "Asia\/Jayapura",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "15",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MKY",
    "lat": "-21.1703",
    "lon": "149.183",
    "name": "Mackay Airport",
    "city": "Mackay",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510724",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "19",
    "icao": "YBMK",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "MLA",
    "lat": "35.8586",
    "lon": "14.4781",
    "name": "Luqa Airport",
    "city": "Curmi",
    "state": "South Eastern",
    "country": "Malta",
    "woeid": "12514808",
    "tz": "Europe\/Malta",
    "phone": "00356 2124 9600",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.maltairport.com",
    "runway_length": "11627",
    "elev": "300",
    "icao": "LMML",
    "direct_flights": "56",
    "carriers": "35"
  },
  {
    "code": "MLB",
    "lat": "28.0957",
    "lon": "-80.6282",
    "name": "Melbourne International Airport",
    "city": "Melbourne",
    "state": "Florida",
    "country": "United States",
    "woeid": "12520889",
    "tz": "America\/New_York",
    "phone": "321-723-6227",
    "type": "Airports",
    "email": "info@mlbair.com",
    "url": "http:\/\/www.mlbair.com",
    "runway_length": "10181",
    "elev": "35",
    "icao": "KMLB",
    "direct_flights": "1",
    "carriers": "5"
  },
  {
    "code": "MLE",
    "lat": "4.167",
    "lon": "73.4974",
    "name": "Male International Airport",
    "city": "Male",
    "state": "Maale",
    "country": "Maldives",
    "woeid": "12514816",
    "tz": "Indian\/Maldives",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9318",
    "elev": "4",
    "icao": "KMLE",
    "direct_flights": "27",
    "carriers": "22"
  },
  {
    "code": "MLG",
    "lat": "-7.98189",
    "lon": "112.627",
    "name": "Malang",
    "city": "Malang",
    "state": "Jawa Timur",
    "country": "Indonesia",
    "woeid": "1047846",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6398",
    "elev": "1726",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MLH",
    "lat": "47.5998",
    "lon": "7.53134",
    "name": "Bale Mulhouse Airport",
    "city": "Basel Mulhouse Freiburg",
    "state": "Alsace",
    "country": "France",
    "woeid": "12512851",
    "tz": "Europe\/Zurich",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.euroairport.com",
    "runway_length": "12795",
    "elev": "883",
    "icao": "LFSB",
    "direct_flights": "12",
    "carriers": "13"
  },
  {
    "code": "MLI",
    "lat": "41.4539",
    "lon": "-90.5058",
    "name": "Quad City Airport",
    "city": "Coal Valley",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12521505",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6507",
    "elev": "589",
    "icao": "KMLI",
    "direct_flights": "11",
    "carriers": "14"
  },
  {
    "code": "MLL",
    "lat": "61.8833",
    "lon": "-162.067",
    "name": "Marshall",
    "city": "Marshall",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799653",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1762",
    "elev": "90",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "MLM",
    "lat": "19.8464",
    "lon": "-101.029",
    "name": "General Francisco J Mujica Airport",
    "city": "\u00c1lvaro Obreg\u00f3n",
    "state": "Michoacan de Ocampo",
    "country": "Mexico",
    "woeid": "12514879",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "6234",
    "icao": "MMMM",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "MLN",
    "lat": "35.2825",
    "lon": "-2.9569",
    "name": "Melilla Airport",
    "city": "Melilla",
    "state": "Melilla Province",
    "country": "Spain",
    "woeid": "12517556",
    "tz": "Africa\/Ceuta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3543",
    "elev": "154",
    "icao": "",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "MLO",
    "lat": "36.7137",
    "lon": "24.5118",
    "name": "Milos Island Airport",
    "city": "Apollonia",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513312",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "12",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MLU",
    "lat": "32.5099",
    "lon": "-92.044",
    "name": "Monroe Regional Airport",
    "city": "Monroe",
    "state": "Louisiana",
    "country": "United States",
    "woeid": "12520996",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7507",
    "elev": "79",
    "icao": "KMLU",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "MLW",
    "lat": "6.2897",
    "lon": "-10.7581",
    "name": "Monrovia Spriggs Payne Airport",
    "city": "Monrovia",
    "state": "Montserrado",
    "country": "Liberia",
    "woeid": "12514602",
    "tz": "Africa\/Monrovia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5997",
    "elev": "25",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MLX",
    "lat": "38.435",
    "lon": "38.0911",
    "name": "Erhac Airport",
    "city": "Arga",
    "state": "Malatya",
    "country": "Turkey",
    "woeid": "12517885",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7600",
    "elev": "3016",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "MLY",
    "lat": "64.9812",
    "lon": "-150.637",
    "name": "Manley Hot Springs",
    "city": "Manley Hot Springs",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2444846",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2760",
    "elev": "270",
    "icao": "PAML",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MMB",
    "lat": "43.8814",
    "lon": "144.163",
    "name": "Memanbetsu Airport",
    "city": "Ozora-cho",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "12523175",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "122",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "MME",
    "lat": "54.5116",
    "lon": "-1.43475",
    "name": "Durham Tees Valley Airport",
    "city": "Darlington",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22461793",
    "tz": "Europe\/London",
    "phone": "+44 1325 332811",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.durhamteesvalleyairport.com\/",
    "runway_length": null,
    "elev": null,
    "icao": "EGNV",
    "direct_flights": "14",
    "carriers": "26"
  },
  {
    "code": "MMG",
    "lat": "-24.4378",
    "lon": "121.079",
    "name": "Western Australia",
    "city": "Mount Magnet",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "2344706",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5030",
    "elev": "1350",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MMH",
    "lat": "37.6288",
    "lon": "-118.844",
    "name": "Mammoth June Lakes Airport",
    "city": "Mammoth Lakes",
    "state": "California",
    "country": "United States",
    "woeid": "12520768",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "7128",
    "icao": "KMMH",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MMK",
    "lat": "68.7794",
    "lon": "32.7434",
    "name": "Murmashi Airport",
    "city": "Apatity",
    "state": "Murmanskaya Oblast",
    "country": "Russia",
    "woeid": "12516460",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KMMK",
    "direct_flights": "8",
    "carriers": "6"
  },
  {
    "code": "MMO",
    "lat": "15.2209",
    "lon": "-23.1762",
    "name": "Maio Airport",
    "city": "Vila do Maio",
    "state": "Maio",
    "country": "Cape Verde",
    "woeid": "12512492",
    "tz": "Atlantic\/Cape_Verde",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "170",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MMX",
    "lat": "55.5406",
    "lon": "13.3666",
    "name": "Sturup Airport",
    "city": "Svedala",
    "state": "Skane",
    "country": "Sweden",
    "woeid": "23318094",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lfv.se\/templates\/LFV_AirportStartPage____4125.aspx",
    "runway_length": "9186",
    "elev": "236",
    "icao": "ESMS",
    "direct_flights": "22",
    "carriers": "12"
  },
  {
    "code": "MMY",
    "lat": "24.7824",
    "lon": "125.295",
    "name": "Miyako Airport",
    "city": "Miyako Jima",
    "state": "Iwate Prefecture",
    "country": "Japan",
    "woeid": "12513990",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "149",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "MNF",
    "lat": "-17.6611",
    "lon": "177.119",
    "name": "Mana Island Airstrip",
    "city": "Mana Island",
    "state": "Western",
    "country": "Fiji",
    "woeid": "2345338",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KMNF",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MNG",
    "lat": "-12.06",
    "lon": "134.233",
    "name": "Maningrida Airport",
    "city": "Maningrida",
    "state": "Northern Territory",
    "country": "Australia",
    "woeid": "12510727",
    "tz": "Australia\/Darwin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "112",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MNJ",
    "lat": "-21.2017",
    "lon": "48.3583",
    "name": "Mananjary Airport",
    "city": "Mananjary",
    "state": "Fianarantsoa",
    "country": "Madagascar",
    "woeid": "12514701",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MNL",
    "lat": "14.5114",
    "lon": "121.016",
    "name": "Ninoy Aquino International Airport",
    "city": "Para\u00f1aque",
    "state": "National Capital Region",
    "country": "Philippines",
    "woeid": "12515637",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11004",
    "elev": "75",
    "icao": "RPLL",
    "direct_flights": "77",
    "carriers": "35"
  },
  {
    "code": "MNT",
    "lat": "65.1987",
    "lon": "-149.53",
    "name": "Minto",
    "city": "Minto",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2452225",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1800",
    "elev": "328",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MNU",
    "lat": "16.4417",
    "lon": "97.6694",
    "name": "Moulmein Airport",
    "city": "Moulmein",
    "state": "Mon State",
    "country": "Myanmar",
    "woeid": "12510926",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5300",
    "elev": "149",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MOA",
    "lat": "38.5702",
    "lon": "-109.553",
    "name": "",
    "city": "Moa",
    "state": "Holguin",
    "country": "Cuba",
    "woeid": "65544",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5580",
    "elev": "16",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MOB",
    "lat": "30.6775",
    "lon": "-88.243",
    "name": "Mobile Regional Airport",
    "city": "Mobile",
    "state": "Alabama",
    "country": "United States",
    "woeid": "12518752",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8527",
    "elev": "218",
    "icao": "KMOB",
    "direct_flights": "11",
    "carriers": "15"
  },
  {
    "code": "MOC",
    "lat": "-16.7044",
    "lon": "-43.8203",
    "name": "Montes Claros Airport",
    "city": "Montes Claros",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511238",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "2192",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "MOD",
    "lat": "37.6319",
    "lon": "-120.959",
    "name": "Modesto City County Airport-Harry Sham Field",
    "city": "Modesto",
    "state": "California",
    "country": "United States",
    "woeid": "12520978",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5911",
    "elev": "97",
    "icao": "KMOD",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MOF",
    "lat": "-8.6381",
    "lon": "122.24",
    "name": "Wai Oti Airport",
    "city": "Maumere",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12513521",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4947",
    "elev": "115",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "MOG",
    "lat": "37.3729",
    "lon": "-87.1361",
    "name": "",
    "city": "Mong Hsat",
    "state": "Rakhine State",
    "country": "Myanmar",
    "woeid": "1017865",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1875",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MOI",
    "lat": "-19.8167",
    "lon": "-157.717",
    "name": "",
    "city": "Mitiaro Island",
    "state": "Mitiaro",
    "country": "Cook Islands",
    "woeid": "24549667",
    "tz": "Pacific\/Rarotonga",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "25",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MOL",
    "lat": "62.7466",
    "lon": "7.26397",
    "name": "Aro Airport",
    "city": "Bols\u00f8ya",
    "state": "More og Romsdal",
    "country": "Norway",
    "woeid": "12515101",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "10",
    "icao": "ENML",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MOQ",
    "lat": "-20.2836",
    "lon": "44.3183",
    "name": "Morondava Airport",
    "city": "Morondava",
    "state": "Toliara",
    "country": "Madagascar",
    "woeid": "12514705",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "23",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "MOT",
    "lat": "48.2554",
    "lon": "-101.291",
    "name": "Minot International Airport",
    "city": "Minot",
    "state": "North Dakota",
    "country": "United States",
    "woeid": "12520968",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7493",
    "elev": "1715",
    "icao": "KMOT",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "MOU",
    "lat": "62.0833",
    "lon": "-163.733",
    "name": "Mountain Village",
    "city": "Mountain Village",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799686",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2214",
    "elev": "165",
    "icao": "PAMO",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "MOV",
    "lat": "-22.0574",
    "lon": "148.077",
    "name": "Moranbah Airport",
    "city": "Moranbah",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "28677683",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3200",
    "elev": "565",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MOZ",
    "lat": "-17.5328",
    "lon": "-149.839",
    "name": "Society Islands Airport",
    "city": "Papeete",
    "state": "Windward Islands",
    "country": "French Polynesia",
    "woeid": "12512831",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2887",
    "elev": "10",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "MPA",
    "lat": "-17.6342",
    "lon": "24.1806",
    "name": "Mpacha Airport",
    "city": "Mpacha",
    "state": "",
    "country": "Namibia",
    "woeid": "12522982",
    "tz": "Africa\/Windhoek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "3230",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MPH",
    "lat": "11.9",
    "lon": "121.917",
    "name": "Malay",
    "city": "Caticlan",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "23424934",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "7",
    "icao": "",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "MPL",
    "lat": "43.5807",
    "lon": "3.96039",
    "name": "Frejorgues Airport",
    "city": "Mauguio",
    "state": "Languedoc-Roussillon",
    "country": "France",
    "woeid": "12512897",
    "tz": "Europe\/Paris",
    "phone": "+33 (0) 4 67 20 85",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.montpellier.aeroport.fr\/",
    "runway_length": "8530",
    "elev": "16",
    "icao": "LFMT",
    "direct_flights": "16",
    "carriers": "15"
  },
  {
    "code": "MPM",
    "lat": "-25.9211",
    "lon": "32.5694",
    "name": "Maputo Airport",
    "city": "Maputo",
    "state": "Maputo",
    "country": "Mozambique",
    "woeid": "12515021",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11910",
    "elev": "131",
    "icao": "",
    "direct_flights": "14",
    "carriers": "7"
  },
  {
    "code": "MPN",
    "lat": "-51.8222",
    "lon": "-58.4417",
    "name": "Mount Pleasant Airport",
    "city": "Mount Pleasant",
    "state": "East Falkland",
    "country": "Falkland Islands",
    "woeid": "12512813",
    "tz": "Atlantic\/Stanley",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8497",
    "elev": "244",
    "icao": "EGYP",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "MQF",
    "lat": "53.45",
    "lon": "59.0667",
    "name": "Magnitogorsk",
    "city": "Chelyabinsk",
    "state": "Chelyabinskaya Oblast",
    "country": "Russian Federation",
    "woeid": "24553463",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "MQL",
    "lat": "-34.2308",
    "lon": "142.084",
    "name": "Mildura Airport",
    "city": "Mildura",
    "state": "Victoria",
    "country": "Australia",
    "woeid": "12510736",
    "tz": "Australia\/Melbourne",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4852",
    "elev": "163",
    "icao": "YMIA",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MQM",
    "lat": "37.2233",
    "lon": "40.6317",
    "name": "Mardin",
    "city": "Mardin",
    "state": "Mardin",
    "country": "Turkey",
    "woeid": "2329522",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MQN",
    "lat": "66.3643",
    "lon": "14.3035",
    "name": "Rossvoll Airport",
    "city": "Skonseng",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523942",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2624",
    "elev": "228",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "MQP",
    "lat": "-25.5",
    "lon": "30.9167",
    "name": "Nelspruit Airport",
    "city": "Nelspruit",
    "state": "",
    "country": "South Africa",
    "woeid": "12523191",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "MQT",
    "lat": "46.3501",
    "lon": "-87.3871",
    "name": "Sawyer International Airport",
    "city": "Marquette",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12520411",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "1419",
    "icao": "KSAW",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "MQX",
    "lat": "13.4667",
    "lon": "39.5167",
    "name": "",
    "city": "Makale",
    "state": "Tigray",
    "country": "Ethiopia",
    "woeid": "1318717",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "7320",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MRA",
    "lat": "32.3325",
    "lon": "15.0617",
    "name": "Misurata Airport",
    "city": "Misratah",
    "state": "Misratah",
    "country": "Libya",
    "woeid": "12514660",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10980",
    "elev": "60",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MRD",
    "lat": "8.5853",
    "lon": "-71.1597",
    "name": "Alberto Carnevalli Airport",
    "city": "Merida",
    "state": "M\u00e9rida",
    "country": "Venezuela",
    "woeid": "12522751",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5348",
    "elev": "5007",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MRE",
    "lat": "-1.3944",
    "lon": "35.0089",
    "name": "Mara Serena Airport",
    "city": "Mara Lodges",
    "state": "Rift Valley",
    "country": "Kenya",
    "woeid": "12514071",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2700",
    "elev": "5600",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "MRS",
    "lat": "43.4411",
    "lon": "5.22087",
    "name": "Marignane Airport",
    "city": "Marignane",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "12512931",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "69",
    "icao": "LFML",
    "direct_flights": "81",
    "carriers": "51"
  },
  {
    "code": "MRU",
    "lat": "-20.4272",
    "lon": "57.6767",
    "name": "Plaisance International Airport",
    "city": "Mahebourg",
    "state": "Grand Port",
    "country": "Mauritius",
    "woeid": "12514796",
    "tz": "Indian\/Mauritius",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8498",
    "elev": "186",
    "icao": "FIMP",
    "direct_flights": "30",
    "carriers": "18"
  },
  {
    "code": "MRV",
    "lat": "44.2026",
    "lon": "43.1352",
    "name": "Mineral'nyye Vody",
    "city": "Mineralnye Vody",
    "state": "Stavropolrskiy Kray",
    "country": "Russia",
    "woeid": "2046335",
    "tz": "Europe\/Volgograd",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "URMM",
    "direct_flights": "19",
    "carriers": "14"
  },
  {
    "code": "MRY",
    "lat": "36.5872",
    "lon": "-121.851",
    "name": "Monterey Peninsula Airport",
    "city": "Monterey",
    "state": "California",
    "country": "United States",
    "woeid": "12521001",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6597",
    "elev": "244",
    "icao": "KMRY",
    "direct_flights": "8",
    "carriers": "15"
  },
  {
    "code": "MRZ",
    "lat": "-29.494",
    "lon": "149.851",
    "name": "Moree Airport",
    "city": "Moree",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510741",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5300",
    "elev": "701",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MSA",
    "lat": "53.8333",
    "lon": "-92",
    "name": "Muskrat Dam",
    "city": "Muskrat Dam",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2646",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MSE",
    "lat": "51.3448",
    "lon": "1.35733",
    "name": "Kent International Airport",
    "city": "Manston",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22484617",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9029",
    "elev": "178",
    "icao": "GMSH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MSJ",
    "lat": "40.7053",
    "lon": "141.361",
    "name": "Misawa Airport",
    "city": "Misawa-shi",
    "state": "Aomori Prefecture",
    "country": "Japan",
    "woeid": "12523178",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "119",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MSL",
    "lat": "34.7439",
    "lon": "-87.6185",
    "name": "Muscle Shoals Regional Airport",
    "city": "Muscle Shoals",
    "state": "Alabama",
    "country": "United States",
    "woeid": "12521070",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6693",
    "elev": "550",
    "icao": "KMSL",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MSN",
    "lat": "43.1351",
    "lon": "-89.3465",
    "name": "Dane County Regional Airport-Truax Field",
    "city": "Madison",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12519407",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9005",
    "elev": "862",
    "icao": "KMSN",
    "direct_flights": "18",
    "carriers": "20"
  },
  {
    "code": "MSO",
    "lat": "46.9192",
    "lon": "-114.084",
    "name": "Missoula International Airport",
    "city": "Missoula",
    "state": "Montana",
    "country": "United States",
    "woeid": "12520974",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9499",
    "elev": "3201",
    "icao": "KMSO",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "MSP",
    "lat": "44.8793",
    "lon": "-93.1987",
    "name": "Minneapolis St Paul International Airport",
    "city": "St. Paul",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12520966",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/mspairport.com",
    "runway_length": "11006",
    "elev": "841",
    "icao": "KMSP",
    "direct_flights": "171",
    "carriers": "41"
  },
  {
    "code": "MSQ",
    "lat": "53.8873",
    "lon": "28.0344",
    "name": "Velikiydvor Airport",
    "city": "Minsk",
    "state": "Minskaya Voblasts'",
    "country": "Belarus",
    "woeid": "12510995",
    "tz": "Europe\/Minsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "742",
    "icao": "UMMS",
    "direct_flights": "28",
    "carriers": "17"
  },
  {
    "code": "MSR",
    "lat": "38.7472",
    "lon": "41.6589",
    "name": "Mus Airport",
    "city": "Mush",
    "state": "Mu\u015f",
    "country": "Turkey",
    "woeid": "12517909",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MSS",
    "lat": "44.9326",
    "lon": "-74.8535",
    "name": "Massena International Airport",
    "city": "Massena",
    "state": "New York",
    "country": "United States",
    "woeid": "12521580",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5001",
    "elev": "214",
    "icao": "KMSS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MST",
    "lat": "50.9157",
    "lon": "5.76967",
    "name": "Maastricht Airport",
    "city": "Maastricht-Airport",
    "state": "Limburg",
    "country": "Netherlands",
    "woeid": "12515090",
    "tz": "Europe\/Amsterdam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "375",
    "icao": "EHBK",
    "direct_flights": "8",
    "carriers": "10"
  },
  {
    "code": "MSU",
    "lat": "-29.4581",
    "lon": "27.5556",
    "name": "Maseru Moshoeshoe Airport",
    "city": "Maseru",
    "state": "Maseru",
    "country": "Lesotho",
    "woeid": "12514633",
    "tz": "Africa\/Maseru",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "5105",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MSW",
    "lat": "15.6",
    "lon": "39.4333",
    "name": "Massawa",
    "city": "Massawa",
    "state": "Semien-Keih-Bahri",
    "country": "Ethiopia",
    "woeid": "1312462",
    "tz": "Africa\/Asmara",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6201",
    "elev": "37",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MSY",
    "lat": "29.983",
    "lon": "-90.2569",
    "name": "New Orleans International Airport",
    "city": "Kenner",
    "state": "Louisiana",
    "country": "United States",
    "woeid": "12521116",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flymsy.com",
    "runway_length": null,
    "elev": "4",
    "icao": "KMSY",
    "direct_flights": "41",
    "carriers": "44"
  },
  {
    "code": "MSZ",
    "lat": "-15.1972",
    "lon": "12.1551",
    "name": "Namibe",
    "city": "Namibe",
    "state": "Namibe",
    "country": "Angola",
    "woeid": "1262954",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8284",
    "elev": "210",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MTJ",
    "lat": "38.5003",
    "lon": "-107.899",
    "name": "Montrose Regional Airport",
    "city": "Montrose",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12521012",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8497",
    "elev": "5759",
    "icao": "KMTJ",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "MTM",
    "lat": "55.1333",
    "lon": "-131.583",
    "name": "Metlakatla Sea Plane Base",
    "city": "Metlakatla",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2449920",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "MTR",
    "lat": "8.8306",
    "lon": "-75.8247",
    "name": "Los Garzones Airport",
    "city": "Los Garzones",
    "state": "Cordoba",
    "country": "Colombia",
    "woeid": "12512392",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6132",
    "elev": "36",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "MTS",
    "lat": "-26.5204",
    "lon": "31.3146",
    "name": "Matsapa International Airport",
    "city": "Manzini",
    "state": "Manzini",
    "country": "Swaziland",
    "woeid": "12522997",
    "tz": "Africa\/Mbabane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "2075",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MTT",
    "lat": "18.0833",
    "lon": "-94.5961",
    "name": "Minatitlan Airport",
    "city": "Minatitlan",
    "state": "",
    "country": "Mexico",
    "woeid": "12514920",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6217",
    "elev": "90",
    "icao": "MMMT",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MTV",
    "lat": "-13.8167",
    "lon": "167.7",
    "name": "Mota Lava",
    "city": "Mota Lava",
    "state": "Torba",
    "country": "Vanuatu",
    "woeid": "1050899",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2950",
    "elev": "63",
    "icao": "KMTV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MTY",
    "lat": "25.7783",
    "lon": "-100.107",
    "name": "Gen Mariano Escobedo International Airport",
    "city": "Pesquer\u00eda",
    "state": "Nuevo Leon",
    "country": "Mexico",
    "woeid": "12514877",
    "tz": "America\/Monterrey",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "1269",
    "icao": "MMMY",
    "direct_flights": "31",
    "carriers": "24"
  },
  {
    "code": "MUA",
    "lat": "-8.3272",
    "lon": "157.261",
    "name": "Munda Airport",
    "city": "Munda",
    "state": "Western",
    "country": "Solomon Islands",
    "woeid": "12511007",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "10",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MUB",
    "lat": "-19.9708",
    "lon": "23.4306",
    "name": "Maun Airport",
    "city": "Maun",
    "state": "Ngamiland",
    "country": "Botswana",
    "woeid": "12510837",
    "tz": "Africa\/Gaborone",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "3101",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "MUC",
    "lat": "48.354",
    "lon": "11.7816",
    "name": "Franz-Josef-Strauss Airport",
    "city": "Oberding",
    "state": "Bavaria",
    "country": "Germany",
    "woeid": "22923040",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.munich-airport.de\/",
    "runway_length": "13100",
    "elev": "1737",
    "icao": "EDDM",
    "direct_flights": "213",
    "carriers": "95"
  },
  {
    "code": "MUE",
    "lat": "19.9983",
    "lon": "-155.675",
    "name": "Waimea Kohala Airport",
    "city": "Kamuela",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12522305",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "2671",
    "icao": "PHMU",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "MUH",
    "lat": "31.3253",
    "lon": "27.2208",
    "name": "Mersa Matruh Airport",
    "city": "Marsa Matruh",
    "state": "Matruh",
    "country": "Egypt",
    "woeid": "12512698",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "94",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "MUK",
    "lat": "-20.1333",
    "lon": "-157.35",
    "name": "Mauke Island",
    "city": "Mauke Island",
    "state": "Atiu",
    "country": "Cook Islands",
    "woeid": "24549674",
    "tz": "Pacific\/Rarotonga",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3107",
    "elev": "90",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MUN",
    "lat": "9.7514",
    "lon": "-63.1528",
    "name": "Maturin Airport",
    "city": "Aguasay",
    "state": "Monagas",
    "country": "Venezuela",
    "woeid": "12522819",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "224",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "MUR",
    "lat": "4.175",
    "lon": "114.33",
    "name": "Marudi Airport",
    "city": "Miri",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12515002",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "103",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "MUX",
    "lat": "30.2028",
    "lon": "71.4181",
    "name": "Multan Airport",
    "city": "Multan",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "12515251",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "400",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "MUZ",
    "lat": "-1.77227",
    "lon": "33.9811",
    "name": "Mara",
    "city": "Musoma",
    "state": "Mara",
    "country": "Tanzania",
    "woeid": "2347359",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5250",
    "elev": "3778",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MVB",
    "lat": "-1.6569",
    "lon": "13.4397",
    "name": "Franceville Mvengue Airport",
    "city": "Franceville",
    "state": "Haut-Ogooue",
    "country": "Gabon",
    "woeid": "12512992",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10105",
    "elev": "1447",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "MVD",
    "lat": "-34.8291",
    "lon": "-56.0323",
    "name": "Carrasco International Airport",
    "city": "Montevideo",
    "state": "Montevideo",
    "country": "Uruguay",
    "woeid": "12522559",
    "tz": "America\/Montevideo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "105",
    "icao": "SUMU",
    "direct_flights": "13",
    "carriers": "14"
  },
  {
    "code": "MVP",
    "lat": "1.13333",
    "lon": "-70.05",
    "name": "Mitu Airport",
    "city": "Mitu",
    "state": "Vaupes",
    "country": "Colombia",
    "woeid": "12523635",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "680",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MVR",
    "lat": "10.4533",
    "lon": "14.2564",
    "name": "Maroua Salak Airport",
    "city": "Maroua",
    "state": "Extreme-Nord",
    "country": "Cameroon",
    "woeid": "12512351",
    "tz": "Africa\/Douala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "1391",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "MVS",
    "lat": "-18.0492",
    "lon": "-39.8653",
    "name": "Aeroporto Max Feffer",
    "city": "Mucuri",
    "state": "Nordeste",
    "country": "Brazil",
    "woeid": "456351",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MVT",
    "lat": "-17.6902",
    "lon": "-149.376",
    "name": "French Polynesia",
    "city": "Mataiva",
    "state": "Windward Islands",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3936",
    "elev": "3",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MVY",
    "lat": "41.3895",
    "lon": "-70.6112",
    "name": "Marthas Vineyard Airport",
    "city": "Vineyard Haven",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12520822",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KMVY",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "MWA",
    "lat": "37.7467",
    "lon": "-89.0104",
    "name": "Williamson County Regional Airport",
    "city": "Marion",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12522462",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6502",
    "elev": "471",
    "icao": "KMWA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MWF",
    "lat": "-15.1609",
    "lon": "168.145",
    "name": "Vanuatu",
    "city": "Maewo",
    "state": "Penama",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2789",
    "elev": "509",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MWN",
    "lat": "-3.59224",
    "lon": "33.5603",
    "name": "Mwadui",
    "city": "Mwadui",
    "state": "Shinyanga",
    "country": "Tanzania",
    "woeid": "1448060",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.mwadui.com",
    "runway_length": "5249",
    "elev": "3970",
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "MWQ",
    "lat": "20.1403",
    "lon": "94.928",
    "name": "Magwe",
    "city": "Magwe",
    "state": "Magway",
    "country": "Myanmar",
    "woeid": "1017567",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "275",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MWZ",
    "lat": "-2.4469",
    "lon": "32.9286",
    "name": "Mwanza Airport",
    "city": "Ilemera",
    "state": "Mwanza",
    "country": "Tanzania",
    "woeid": "12518019",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10830",
    "elev": "3763",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "MXH",
    "lat": "-6.65619",
    "lon": "145.859",
    "name": "Papua New Guinea",
    "city": "Moro",
    "state": "Southern Highlands",
    "country": "Papua New Guinea",
    "woeid": "23424926",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "2750",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MXL",
    "lat": "32.6308",
    "lon": "-115.242",
    "name": "Gen Rodolfo Sanchez T International Airport",
    "city": "Mexicali",
    "state": "Baja California",
    "country": "Mexico",
    "woeid": "12514878",
    "tz": "America\/Tijuana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "72",
    "icao": "MMML",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "MXM",
    "lat": "-21.7506",
    "lon": "43.3742",
    "name": "Morombe Airport",
    "city": "Morombe",
    "state": "Toliara",
    "country": "Madagascar",
    "woeid": "12514704",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "16",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MXN",
    "lat": "48.5987",
    "lon": "-3.81735",
    "name": "Ploujean Airport",
    "city": "Morlaix",
    "state": "Brittany",
    "country": "France",
    "woeid": "12523822",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4216",
    "elev": "282",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MXP",
    "lat": "45.6314",
    "lon": "8.72284",
    "name": "Malpensa International Airport",
    "city": "Cardano al Campo",
    "state": "Lombardy",
    "country": "Italy",
    "woeid": "22306432",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sea-aeroportimilano.it\/",
    "runway_length": "12844",
    "elev": "768",
    "icao": "LIMC",
    "direct_flights": "163",
    "carriers": "109"
  },
  {
    "code": "MXT",
    "lat": "-27.2167",
    "lon": "-65.1333",
    "name": "",
    "city": "Maintirano",
    "state": "Mahajanga",
    "country": "Madagascar",
    "woeid": "1362166",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "95",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "MXV",
    "lat": "49.663",
    "lon": "100.094",
    "name": "Moron Airport",
    "city": "M\u00f6r\u00f6n",
    "state": "Hovsgol",
    "country": "Mongolia",
    "woeid": "12514750",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MXX",
    "lat": "60.9586",
    "lon": "14.5049",
    "name": "Siljan Airport",
    "city": "Mora",
    "state": "Dalarna",
    "country": "Sweden",
    "woeid": "12517676",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5938",
    "elev": "633",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "MXZ",
    "lat": "24.35",
    "lon": "116.133",
    "name": "Meixian",
    "city": "Meixian",
    "state": "\u5e7f\u4e1c\u7701",
    "country": "China",
    "woeid": "12713337",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MYA",
    "lat": "-35.8983",
    "lon": "150.147",
    "name": "Moruya Aerodrome",
    "city": "Bingie",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510743",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4974",
    "elev": "14",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MYD",
    "lat": "-3.2261",
    "lon": "40.1006",
    "name": "Malindi Airport",
    "city": "Mombasa",
    "state": "Coast",
    "country": "Kenya",
    "woeid": "12514070",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "80",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "MYE",
    "lat": "34.0726",
    "lon": "139.56",
    "name": "Miyakejima Airport",
    "city": "Miyake-mura",
    "state": "Tokyo Prefecture",
    "country": "Japan",
    "woeid": "12513989",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "65",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MYG",
    "lat": "22.3706",
    "lon": "-72.9481",
    "name": "Miltary & Civil Airport",
    "city": "Abraham Bay",
    "state": "Mayaguana",
    "country": "Bahamas",
    "woeid": "12510873",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7297",
    "elev": "11",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "MYI",
    "lat": "-9.91682",
    "lon": "144.051",
    "name": "Murray Island",
    "city": "Murray Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "28645248",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MYJ",
    "lat": "33.8276",
    "lon": "132.7",
    "name": "Matsuyama Airport",
    "city": "Matsuyama-shi",
    "state": "Ehime Prefecture",
    "country": "Japan",
    "woeid": "12513985",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "21",
    "icao": "KMYJ",
    "direct_flights": "10",
    "carriers": "8"
  },
  {
    "code": "MYL",
    "lat": "44.8968",
    "lon": "-116.096",
    "name": "Mccall Airport",
    "city": "Mccall",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12520848",
    "tz": "America\/Boise",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5002",
    "elev": "5023",
    "icao": "KMYL",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MYR",
    "lat": "33.6821",
    "lon": "-78.9228",
    "name": "Myrtle Beach International Airport",
    "city": "Myrtle Beach",
    "state": "South Carolina",
    "country": "United States",
    "woeid": "12521074",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "9502",
    "elev": "26",
    "icao": "KMYR",
    "direct_flights": "13",
    "carriers": "12"
  },
  {
    "code": "MYT",
    "lat": "25.3844",
    "lon": "97.3553",
    "name": "Myitkyina Airport",
    "city": "Myitkyina",
    "state": "Kachin State",
    "country": "Myanmar",
    "woeid": "12510927",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8595",
    "elev": "470",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MYU",
    "lat": "60.3906",
    "lon": "-166.191",
    "name": "Mekoryuk",
    "city": "Mekoryuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2449074",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3271",
    "elev": "48",
    "icao": "PAMY",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MYW",
    "lat": "-10.3342",
    "lon": "40.1808",
    "name": "Mtwara Airport",
    "city": "Ziwani",
    "state": "Mtwara",
    "country": "Tanzania",
    "woeid": "12518018",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7400",
    "elev": "371",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "MYY",
    "lat": "4.3256",
    "lon": "113.984",
    "name": "Miri Airport",
    "city": "Miri",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12515003",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "55",
    "icao": "",
    "direct_flights": "18",
    "carriers": "3"
  },
  {
    "code": "MZG",
    "lat": "23.5631",
    "lon": "119.634",
    "name": "Magong Airport",
    "city": "Makung City",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "12517945",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "69",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "MZH",
    "lat": "40.8303",
    "lon": "35.5202",
    "name": "Merzifon",
    "city": "Merzifon",
    "state": "",
    "country": "Turkey",
    "woeid": "2343729",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "1783",
    "icao": "KMZH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MZI",
    "lat": "14.5206",
    "lon": "-4.0872",
    "name": "Mopti Barbe Airport",
    "city": "Mopti",
    "state": "Mopti",
    "country": "Mali",
    "woeid": "12514776",
    "tz": "Africa\/Bamako",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "906",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MZL",
    "lat": "5.0322",
    "lon": "-75.4708",
    "name": "La Nubia Airport",
    "city": "Villamar\u00eda",
    "state": "Caldas",
    "country": "Colombia",
    "woeid": "12512389",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "3380",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "MZO",
    "lat": "20.2861",
    "lon": "-77.0864",
    "name": "Manzanillo Airport",
    "city": "Manzanillo",
    "state": "Granma",
    "country": "Cuba",
    "woeid": "12512474",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "112",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "MZR",
    "lat": "36.7042",
    "lon": "67.2083",
    "name": "Mazar I Sharif Airport",
    "city": "Mazar-i-Sharif",
    "state": "Balkh",
    "country": "Afghanistan",
    "woeid": "12510309",
    "tz": "Asia\/Kabul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8725",
    "elev": "1284",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "MZT",
    "lat": "23.1625",
    "lon": "-106.274",
    "name": "General Rafael Buelna International Airport",
    "city": "Mazatl\u00e1n",
    "state": "Sinaloa",
    "country": "Mexico",
    "woeid": "12514885",
    "tz": "America\/Mazatlan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8859",
    "elev": "33",
    "icao": "MMMZ",
    "direct_flights": "18",
    "carriers": "15"
  },
  {
    "code": "MZV",
    "lat": "4.03333",
    "lon": "114.8",
    "name": "Mulu Airport",
    "city": "Mulu",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12523318",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NAA",
    "lat": "-30.3208",
    "lon": "149.826",
    "name": "Narrabri Airport",
    "city": "Bohena Creek",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510748",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "786",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NAC",
    "lat": "-36.9803",
    "lon": "140.728",
    "name": "Naracoorte Airport",
    "city": "Naracoorte",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "12510747",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3440",
    "elev": "169",
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "NAG",
    "lat": "21.0917",
    "lon": "79.0486",
    "name": "Sonegaon Airport",
    "city": "Nagpur",
    "state": "Maharashtra",
    "country": "India",
    "woeid": "12513637",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "1033",
    "icao": "",
    "direct_flights": "10",
    "carriers": "9"
  },
  {
    "code": "NAJ",
    "lat": "22.8799",
    "lon": "72.5687",
    "name": "",
    "city": "Nakhichevan",
    "state": "Naxcivan",
    "country": "Azerbaijan",
    "woeid": "1951979",
    "tz": "Asia\/Baku",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "NAN",
    "lat": "-17.7558",
    "lon": "177.446",
    "name": "Nadi International Airport",
    "city": "Nadi",
    "state": "Western",
    "country": "Fiji",
    "woeid": "12523183",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.afl.com.fj",
    "runway_length": "10500",
    "elev": "63",
    "icao": "NFFN",
    "direct_flights": "25",
    "carriers": "14"
  },
  {
    "code": "NAO",
    "lat": "30.7556",
    "lon": "106.057",
    "name": "Nanchong Airport",
    "city": "Nanchong",
    "state": "Sichuan",
    "country": "China",
    "woeid": "12512150",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NAP",
    "lat": "40.8837",
    "lon": "14.2815",
    "name": "Naples International Airport",
    "city": "Naples",
    "state": "Campania",
    "country": "Italy",
    "woeid": "12513810",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7201",
    "elev": "289",
    "icao": "LIRN",
    "direct_flights": "56",
    "carriers": "48"
  },
  {
    "code": "NAQ",
    "lat": "77.5",
    "lon": "-69.1667",
    "name": "Qaanaaq",
    "city": "Qaanaaq",
    "state": "Nordgronland",
    "country": "Greenland",
    "woeid": "472989",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NAS",
    "lat": "25.0406",
    "lon": "-77.4719",
    "name": "Nassau International Airport",
    "city": "Nassau",
    "state": "New Providence",
    "country": "Bahamas",
    "woeid": "12510874",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11000",
    "elev": "10",
    "icao": "MYNN",
    "direct_flights": "49",
    "carriers": "19"
  },
  {
    "code": "NAT",
    "lat": "-5.84694",
    "lon": "-35.2145",
    "name": "Augusto Severo International Airport",
    "city": "Natal",
    "state": "Rio Grande do Norte",
    "country": "Brazil",
    "woeid": "12511038",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7441",
    "elev": "171",
    "icao": "SBNT",
    "direct_flights": "11",
    "carriers": "10"
  },
  {
    "code": "NAU",
    "lat": "-14.1667",
    "lon": "-141.583",
    "name": "Napuka Island",
    "city": "Napuka Island",
    "state": "Windward Islands",
    "country": "French Polynesia",
    "woeid": "22504011",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4101",
    "elev": "7",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NAV",
    "lat": "32.5281",
    "lon": "-87.2731",
    "name": "",
    "city": "Nevsehir",
    "state": "Nev\u015fehir",
    "country": "Turkey",
    "woeid": "2347303",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NAW",
    "lat": "6.5183",
    "lon": "101.748",
    "name": "Narathiwat Airport",
    "city": "Narathiwat",
    "state": "Narathiwat",
    "country": "Thailand",
    "woeid": "12517772",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6578",
    "elev": "18",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NBC",
    "lat": "59.4615",
    "lon": "108.832",
    "name": "Russia",
    "city": "Naberevnye Chelny",
    "state": "",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KNBC",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "NBO",
    "lat": "-1.31697",
    "lon": "36.9222",
    "name": "Jomo Kenyatta International Airport",
    "city": "Nairobi",
    "state": "Nairobi Area",
    "country": "Kenya",
    "woeid": "12514067",
    "tz": "Africa\/Nairobi",
    "phone": "00254\/(0)20825400",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.kenyaairport.co.ke",
    "runway_length": "13507",
    "elev": "5327",
    "icao": "HKJK",
    "direct_flights": "55",
    "carriers": "42"
  },
  {
    "code": "NBW",
    "lat": "19.9093",
    "lon": "-75.2076",
    "name": "Guantanamo Bay Naval Air Station",
    "city": "Caimanera",
    "state": "Guantanamo",
    "country": "Cuba",
    "woeid": "12512463",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "NBX",
    "lat": "-3.3617",
    "lon": "135.494",
    "name": "Nabire Airport",
    "city": "Nabire",
    "state": "Irian Jaya",
    "country": "Indonesia",
    "woeid": "12513488",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3773",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "NCA",
    "lat": "21.9167",
    "lon": "-71.9425",
    "name": "North Caicos Airport",
    "city": "Bottle Creek Settlements",
    "state": "North Caicos",
    "country": "Turks And Caicos Islands",
    "woeid": "12517842",
    "tz": "America\/Grand_Turk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4280",
    "elev": "10",
    "icao": "KNCA",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "NCE",
    "lat": "43.6638",
    "lon": "7.21286",
    "name": "Nice-Cote d'Azur Airport",
    "city": "Nice",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "22143543",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "13",
    "icao": "LFMN",
    "direct_flights": "97",
    "carriers": "67"
  },
  {
    "code": "NCL",
    "lat": "55.0374",
    "lon": "-1.70962",
    "name": "Newcastle International Airport",
    "city": "Newcastle",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22480195",
    "tz": "Europe\/London",
    "phone": "0870 122 1488",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.newcastleairport.com\/",
    "runway_length": "7651",
    "elev": "266",
    "icao": "EGNT",
    "direct_flights": "56",
    "carriers": "33"
  },
  {
    "code": "NCN",
    "lat": "60.0784",
    "lon": "-148.017",
    "name": "Chenega",
    "city": "New Chenega",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2378864",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "NCU",
    "lat": "42.4833",
    "lon": "59.6333",
    "name": "",
    "city": "Nukus",
    "state": "Qoraqalpoghiston",
    "country": "Uzbekistan",
    "woeid": "2272619",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "NCY",
    "lat": "45.931",
    "lon": "6.10666",
    "name": "Meythet Airport",
    "city": "Pringy",
    "state": "Rhone-Alpes",
    "country": "France",
    "woeid": "12512937",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4134",
    "elev": "1512",
    "icao": "LFLP",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "NDB",
    "lat": "20.9342",
    "lon": "-17.0283",
    "name": "Nouadhibou Airport",
    "city": "Port-Etienne",
    "state": "Dakhlet Nouadhibou",
    "country": "Mauritania",
    "woeid": "12514805",
    "tz": "Africa\/Nouakchott",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7956",
    "elev": "16",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NDG",
    "lat": "47.5167",
    "lon": "112.133",
    "name": "Qiqihar",
    "city": "Qiqihar",
    "state": "Suhbaatar",
    "country": "China",
    "woeid": "2265748",
    "tz": "Asia\/Harbin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "NDJ",
    "lat": "12.1347",
    "lon": "15.035",
    "name": "Ndjamena Airport",
    "city": "N'Djamena",
    "state": "Chari-Baguirmi",
    "country": "Chad",
    "woeid": "12511933",
    "tz": "Africa\/Ndjamena",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "968",
    "icao": "FTTJ",
    "direct_flights": "13",
    "carriers": "15"
  },
  {
    "code": "NDR",
    "lat": "35.1678",
    "lon": "-2.94411",
    "name": "Nador Airport",
    "city": "Nador",
    "state": "Nador",
    "country": "Morocco",
    "woeid": "24554857",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "20",
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "NER",
    "lat": "17.611",
    "lon": "8.08095",
    "name": "",
    "city": "Neryungri",
    "state": "Sakha",
    "country": "Russia",
    "woeid": "2050745",
    "tz": "Asia\/Yakutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "NEV",
    "lat": "17.2",
    "lon": "-62.6",
    "name": "Newcastle Airport",
    "city": "Nevis",
    "state": "Saint James Windward",
    "country": "Saint Kitts and Nevis",
    "woeid": "12523186",
    "tz": "America\/St_Kitts",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "20",
    "icao": "TKPN",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "NFO",
    "lat": "-15.6022",
    "lon": "-175.614",
    "name": "Niuafo'ou Airport",
    "city": "",
    "state": "Vava'eu",
    "country": "Tonga",
    "woeid": "12517848",
    "tz": "Pacific\/Tongatapu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NGB",
    "lat": "29.8333",
    "lon": "121.533",
    "name": "Ningbo Airport",
    "city": "Jiangshan",
    "state": "Zhejiang",
    "country": "China",
    "woeid": "12523321",
    "tz": "Asia\/Shanghai",
    "phone": "+86-0574-8742-7888",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/ningboguide.com\/airport.html",
    "runway_length": "8200",
    "elev": null,
    "icao": "",
    "direct_flights": "27",
    "carriers": "11"
  },
  {
    "code": "NGE",
    "lat": "7.3564",
    "lon": "13.5614",
    "name": "Ngaoundere Airport",
    "city": "Ngaound\u00e9r\u00e9",
    "state": "Adamaoua",
    "country": "Cameroon",
    "woeid": "12512352",
    "tz": "Africa\/Douala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "3656",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NGI",
    "lat": "-18.0333",
    "lon": "179.3",
    "name": "Ngau Island",
    "city": "Ngau Island",
    "state": "Eastern",
    "country": "Fiji",
    "woeid": "12489736",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2493",
    "elev": "50",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NGO",
    "lat": "34.8624",
    "lon": "136.811",
    "name": "Chubu International Airport",
    "city": "Tokoname-shi",
    "state": "Aichi Prefecture",
    "country": "Japan",
    "woeid": "28298951",
    "tz": "Asia\/Tokyo",
    "phone": "+81-569-38-1195",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.centrair.jp\/en\/index.html",
    "runway_length": "11513",
    "elev": "12",
    "icao": "RJGG",
    "direct_flights": "55",
    "carriers": "37"
  },
  {
    "code": "NGS",
    "lat": "32.9141",
    "lon": "129.917",
    "name": "Nagasaki Airport",
    "city": "Omura-shi",
    "state": "Nagasaki Prefecture",
    "country": "Japan",
    "woeid": "12513993",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "18",
    "icao": "KNGS",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "NHA",
    "lat": "12.2333",
    "lon": "109.2",
    "name": "Nha-Trang Airport",
    "city": "Nha Trang",
    "state": "Khanh Hoa",
    "country": "Vietnam",
    "woeid": "12523189",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6166",
    "elev": "16",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "NHV",
    "lat": "-8.79383",
    "lon": "-140.223",
    "name": "Marquesas Islands Airport",
    "city": "Nuku Hiva",
    "state": "Marquesas Islands",
    "country": "French Polynesia",
    "woeid": "12512826",
    "tz": "Pacific\/Marquesas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "226",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "NIB",
    "lat": "45.9837",
    "lon": "-108.028",
    "name": "Nikolai",
    "city": "Nikolai",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2459969",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "450",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NIM",
    "lat": "13.4817",
    "lon": "2.1703",
    "name": "Niamey Airport",
    "city": "Niamey",
    "state": "Niamey",
    "country": "Niger",
    "woeid": "12515047",
    "tz": "Africa\/Niamey",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "728",
    "icao": "DRRN",
    "direct_flights": "9",
    "carriers": "13"
  },
  {
    "code": "NIP",
    "lat": "30.2342",
    "lon": "-81.6753",
    "name": "Jacksonville Naval Air Station",
    "city": "Jacksonville",
    "state": "Florida",
    "country": "United States",
    "woeid": "12520342",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KNIP",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NIU",
    "lat": "21.3358",
    "lon": "-157.919",
    "name": "Honolulu International Airport",
    "city": "Honolulu",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520216",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NJC",
    "lat": "60.9518",
    "lon": "76.4972",
    "name": "Nizhnevartovsk Northwest Airport",
    "city": "Nizhnevartovsk",
    "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
    "country": "Russia",
    "woeid": "12516511",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "17",
    "carriers": "13"
  },
  {
    "code": "NKC",
    "lat": "18.0975",
    "lon": "-15.9478",
    "name": "Nouakchott Airport",
    "city": "Nouakchott",
    "state": "Nouakchott",
    "country": "Mauritania",
    "woeid": "12514806",
    "tz": "Africa\/Nouakchott",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "7",
    "icao": "GQNN",
    "direct_flights": "10",
    "carriers": "8"
  },
  {
    "code": "NKG",
    "lat": "32.0833",
    "lon": "118.8",
    "name": "Nanjing Lukou International Airport",
    "city": "Nanjing",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "12523190",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11800",
    "elev": null,
    "icao": "ZSNG",
    "direct_flights": "51",
    "carriers": "25"
  },
  {
    "code": "NKI",
    "lat": "55.8556",
    "lon": "-133.183",
    "name": "Naukiti Airport",
    "city": "Naukiti",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NKM",
    "lat": "35.2527",
    "lon": "136.924",
    "name": "Nagoya Airport",
    "city": "Toyoyama-cho",
    "state": "Aichi Prefecture",
    "country": "Japan",
    "woeid": "23388304",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "http:\/\/www.nagoya-airport-bldg.co.jp\/",
    "runway_length": "8990",
    "elev": "46",
    "icao": "RJNA",
    "direct_flights": "9",
    "carriers": "1"
  },
  {
    "code": "NLA",
    "lat": "-12.9958",
    "lon": "28.6631",
    "name": "Ndola Airport",
    "city": "Ndola",
    "state": "Copperbelt",
    "country": "Zambia",
    "woeid": "12523017",
    "tz": "Africa\/Lusaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8250",
    "elev": "4167",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "NLD",
    "lat": "27.4422",
    "lon": "-99.5686",
    "name": "Quetzalcoatl International Airport",
    "city": "Nuevo Laredo",
    "state": "Tamaulipas",
    "country": "Mexico",
    "woeid": "12514945",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "486",
    "icao": "MMNL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NLF",
    "lat": "-9.5925",
    "lon": "143.773",
    "name": "Darnley Island Airport",
    "city": "Kubin Village",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510650",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "NLG",
    "lat": "56.0167",
    "lon": "-161.15",
    "name": "",
    "city": "Nelson Lagoon",
    "state": "Alaska",
    "country": "United States",
    "woeid": "23417017",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3300",
    "elev": "13",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NLK",
    "lat": "-29.0363",
    "lon": "168.032",
    "name": "Norfolk Island Airport",
    "city": "Kingston",
    "state": "",
    "country": "Norfolk Island",
    "woeid": "12515042",
    "tz": "Pacific\/Norfolk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6400",
    "elev": "371",
    "icao": "YNSF",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "NLV",
    "lat": "47.05",
    "lon": "31.9167",
    "name": "Nikolaev Airport",
    "city": "Mykolayiv",
    "state": "Mykolayivs\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12524013",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NMA",
    "lat": "40.9787",
    "lon": "71.5677",
    "name": "Namangan Airport",
    "city": "Namangan",
    "state": "Namangan",
    "country": "Uzbekistan",
    "woeid": "12522684",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "NME",
    "lat": "60.4723",
    "lon": "-164.699",
    "name": "Nightmute Airport",
    "city": "Nightmute",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2459959",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "6",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "NNB",
    "lat": "-10.8483",
    "lon": "162.455",
    "name": "Makira",
    "city": "Santa Ana",
    "state": "Makira",
    "country": "Solomon Islands",
    "woeid": "2344841",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NNG",
    "lat": "22.6183",
    "lon": "108.179",
    "name": "Nanning-Wuyu Airport",
    "city": "Wuxu",
    "state": "Guangxi",
    "country": "China",
    "woeid": "12512151",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "246",
    "icao": "",
    "direct_flights": "26",
    "carriers": "13"
  },
  {
    "code": "NNL",
    "lat": "59.9717",
    "lon": "-154.847",
    "name": "",
    "city": "Nondalton",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2460300",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2900",
    "elev": "300",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NNM",
    "lat": "67.6167",
    "lon": "53.15",
    "name": "",
    "city": "Naryan-Mar",
    "state": "Nenetskiy Avtonomnyy Okrug",
    "country": "Russia",
    "woeid": "12598021",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "NNT",
    "lat": "18.8097",
    "lon": "100.787",
    "name": "Nan Airport",
    "city": "Nan",
    "state": "Nan",
    "country": "Thailand",
    "woeid": "12517771",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6561",
    "elev": "699",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NNY",
    "lat": "32.9959",
    "lon": "112.527",
    "name": "Nanyang",
    "city": "Nanyang",
    "state": "Henan",
    "country": "China",
    "woeid": "2172743",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "NOB",
    "lat": "10.0053",
    "lon": "-85.6625",
    "name": "Nosara Beach Airport",
    "city": "Nosara",
    "state": "Guanacaste",
    "country": "Costa Rica",
    "woeid": "12524316",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "NOC",
    "lat": "53.9133",
    "lon": "-8.81129",
    "name": "Connaught Airport",
    "city": "Knock",
    "state": "",
    "country": "Ireland",
    "woeid": "12512719",
    "tz": "Europe\/Dublin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "665",
    "icao": "EIKN",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "NOJ",
    "lat": "63.5",
    "lon": "75",
    "name": "",
    "city": "Nojabrxsk",
    "state": "",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "NOS",
    "lat": "-13.3153",
    "lon": "48.3103",
    "name": "Nosy Be Fascene Airport",
    "city": "Hell-Ville",
    "state": "Antsiranana",
    "country": "Madagascar",
    "woeid": "12514706",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7185",
    "elev": "33",
    "icao": "",
    "direct_flights": "8",
    "carriers": "6"
  },
  {
    "code": "NOU",
    "lat": "-22.0258",
    "lon": "166.21",
    "name": "La Tontouta Airport",
    "city": "",
    "state": "Sud",
    "country": "New Caledonia",
    "woeid": "12515035",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10663",
    "elev": "52",
    "icao": "NWWW",
    "direct_flights": "10",
    "carriers": "6"
  },
  {
    "code": "NOV",
    "lat": "-12.8064",
    "lon": "15.7572",
    "name": "Huambo Airport",
    "city": "Huambo",
    "state": "Huambo",
    "country": "Angola",
    "woeid": "12510435",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8727",
    "elev": "5584",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "NOZ",
    "lat": "54.5183",
    "lon": "86.9342",
    "name": "Kemerovskaya Oblast",
    "city": "Novokuznetsk",
    "state": "Kemerovskaya Oblast",
    "country": "Russia",
    "woeid": "2346901",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "NPE",
    "lat": "-39.4689",
    "lon": "176.867",
    "name": "Hawkes Bay Airport",
    "city": "Napier",
    "state": "Hawke's Bay",
    "country": "New Zealand",
    "woeid": "12515159",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "6",
    "icao": "NZNR",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "NPL",
    "lat": "-39.0067",
    "lon": "174.171",
    "name": "New Plymouth Airport",
    "city": "New Plymouth",
    "state": "Taranaki",
    "country": "New Zealand",
    "woeid": "12515161",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "97",
    "icao": "NZNP",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "NQN",
    "lat": "-38.9489",
    "lon": "-68.1569",
    "name": "Neuquen Airport",
    "city": "Neuquen",
    "state": "Neuquen",
    "country": "Argentina",
    "woeid": "12510526",
    "tz": "America\/Argentina\/Mendoza",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8434",
    "elev": "896",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "NQU",
    "lat": "5.69889",
    "lon": "-77.2765",
    "name": "Nuqui Airport",
    "city": "Nuqu\u00ed",
    "state": "Choco",
    "country": "Colombia",
    "woeid": "12523644",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2427",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NQY",
    "lat": "50.4364",
    "lon": "-5.01373",
    "name": "St Mawgan Airport",
    "city": "Newquay",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518154",
    "tz": "Europe\/London",
    "phone": "01637 861053",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8984",
    "elev": "390",
    "icao": "EGDG",
    "direct_flights": "10",
    "carriers": "7"
  },
  {
    "code": "NRA",
    "lat": "-34.6964",
    "lon": "146.512",
    "name": "Narrandera Leeton Aerodrome",
    "city": "Narrandera",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510749",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "5282",
    "elev": "471",
    "icao": "KNRA",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "NRK",
    "lat": "58.5841",
    "lon": "16.2338",
    "name": "Kungsangen Airport",
    "city": "Norrkoping",
    "state": "Ostergotland",
    "country": "Sweden",
    "woeid": "12517656",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "17",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "NRN",
    "lat": "51.6031",
    "lon": "6.1417",
    "name": "Airport Weeze",
    "city": "Weeze",
    "state": "North Rhine-Westphalia",
    "country": "Germany",
    "woeid": "12513156",
    "tz": "Europe\/Berlin",
    "phone": "+49 (0) 28 37 \/ 66 61 11",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport-weeze.de",
    "runway_length": "2440",
    "elev": "32",
    "icao": "KNRN",
    "direct_flights": "31",
    "carriers": "1"
  },
  {
    "code": "NRT",
    "lat": "35.7491",
    "lon": "140.389",
    "name": "Narita International Airport",
    "city": "Narita-shi",
    "state": "Chiba Prefecture",
    "country": "Japan",
    "woeid": "12513999",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.narita-airport.jp",
    "runway_length": "13123",
    "elev": "141",
    "icao": "RJAA",
    "direct_flights": "97",
    "carriers": "63"
  },
  {
    "code": "NSH",
    "lat": "42.7584",
    "lon": "-71.4642",
    "name": "",
    "city": "Now Shahr",
    "state": "Mazandaran",
    "country": "Iran",
    "woeid": "2255009",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6663",
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NSI",
    "lat": "3.83168",
    "lon": "11.5233",
    "name": "Nsimalen Airport",
    "city": "Yaounde",
    "state": "Centre",
    "country": "Cameroon",
    "woeid": "12523057",
    "tz": "Africa\/Douala",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KNSI",
    "direct_flights": "6",
    "carriers": "9"
  },
  {
    "code": "NSK",
    "lat": "69.3089",
    "lon": "87.3351",
    "name": "Norilsk Alykel Airport",
    "city": "Kansk",
    "state": "Krasnoyarskiy Kray",
    "country": "Russia",
    "woeid": "12516523",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "14",
    "carriers": "9"
  },
  {
    "code": "NSN",
    "lat": "-41.2961",
    "lon": "173.22",
    "name": "Nelson Airport",
    "city": "Nelson",
    "state": "Nelson",
    "country": "New Zealand",
    "woeid": "12515160",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "17",
    "icao": "NZNS",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "NST",
    "lat": "8.4561",
    "lon": "99.96",
    "name": "Nakhon Si Thammarat Airport",
    "city": "Phra Phrom",
    "state": "Nakhon Si Thammarat",
    "country": "Thailand",
    "woeid": "12517768",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3625",
    "elev": "44",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "NTE",
    "lat": "47.1573",
    "lon": "-1.60205",
    "name": "Chateau Bougon Airport",
    "city": "Bouguenais",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "12512881",
    "tz": "Europe\/Paris",
    "phone": "+33 (0)2 40 84 81 52",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.nantes.aeroport.fr",
    "runway_length": "9514",
    "elev": "89",
    "icao": "LFRS",
    "direct_flights": "38",
    "carriers": "23"
  },
  {
    "code": "NTG",
    "lat": "32.0155",
    "lon": "120.84",
    "name": "Nantong Airport",
    "city": "Nantong",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "12523350",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "NTL",
    "lat": "-32.7985",
    "lon": "151.837",
    "name": "Williamtown Airport",
    "city": "Ferodale",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510813",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "31",
    "icao": "YWLM",
    "direct_flights": "9",
    "carriers": "8"
  },
  {
    "code": "NTN",
    "lat": "-17.7",
    "lon": "141.067",
    "name": "Normanton",
    "city": "Normanton",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12708490",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "72",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "NTQ",
    "lat": "37.2917",
    "lon": "136.957",
    "name": "Noto Airport",
    "city": "Anamizu-machi",
    "state": "Ishikawa Prefecture",
    "country": "Japan",
    "woeid": "28360528",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NTT",
    "lat": "-15.9481",
    "lon": "-173.736",
    "name": "Niuatoputapu Airport",
    "city": "Niuatoputapu",
    "state": "Vava'eu",
    "country": "Tonga",
    "woeid": "12517849",
    "tz": "Pacific\/Tongatapu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NUE",
    "lat": "49.4946",
    "lon": "11.0785",
    "name": "Nurnberg Airport",
    "city": "Nuremberg",
    "state": "Bavaria",
    "country": "Germany",
    "woeid": "22257687",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "450",
    "icao": "EDDN",
    "direct_flights": "69",
    "carriers": "34"
  },
  {
    "code": "NUI",
    "lat": "70.2167",
    "lon": "-150.917",
    "name": "Nuiqsut",
    "city": "Nuiqsut",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799827",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "50",
    "icao": "KNUI",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NUK",
    "lat": "-19.0861",
    "lon": "-139",
    "name": "Nukutavake",
    "city": "Nukutavake",
    "state": "Windward Islands",
    "country": "French Polynesia",
    "woeid": "22504012",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2788",
    "elev": "17",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "NUL",
    "lat": "64.7134",
    "lon": "-158.077",
    "name": "Nulato Airport",
    "city": "Nulato",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29388568",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2980",
    "elev": "310",
    "icao": "PANU",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "NUP",
    "lat": "60.897",
    "lon": "-162.453",
    "name": "Alaska",
    "city": "Nunapitchuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "NUS",
    "lat": "45.7396",
    "lon": "7.46666",
    "name": "",
    "city": "Norsup",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3116",
    "elev": "23",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "NUX",
    "lat": "65.9549",
    "lon": "78.3955",
    "name": "Urengoy Airport",
    "city": "Novy Urengoy",
    "state": "Yamalo-Nenetskiy Avtonomnyy Okru",
    "country": "Russia",
    "woeid": "12517089",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "9"
  },
  {
    "code": "NVA",
    "lat": "2.93333",
    "lon": "-75.2833",
    "name": "Neiva Lamarguita Airport",
    "city": "Neiva",
    "state": "Huila",
    "country": "Colombia",
    "woeid": "12524502",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "1440",
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "NVI",
    "lat": "40.1155",
    "lon": "65.19",
    "name": "Navoi Airport",
    "city": "Navoi",
    "state": "Nawoiy",
    "country": "Uzbekistan",
    "woeid": "12522688",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "NVK",
    "lat": "68.4431",
    "lon": "17.3941",
    "name": "Framnes Airport",
    "city": "Narvik",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523943",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "97",
    "icao": "ENNK",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "NVR",
    "lat": "58.5167",
    "lon": "31.3",
    "name": "",
    "city": "Novgorod",
    "state": "Novgorodskaya Oblast",
    "country": "Russia",
    "woeid": "2122471",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "NVT",
    "lat": "-26.8797",
    "lon": "-48.6481",
    "name": "Ministro Victor Konder International Airport",
    "city": "Navegantes",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12511240",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5581",
    "elev": "18",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "NWA",
    "lat": "6.47748",
    "lon": "11.0726",
    "name": "",
    "city": "Moheli",
    "state": "Moheli",
    "country": "Comoros",
    "woeid": "2345045",
    "tz": "Indian\/Comoro",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "46",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "NWI",
    "lat": "52.6704",
    "lon": "1.27648",
    "name": "Norwich Airport",
    "city": "Norwich",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22481371",
    "tz": "Europe\/London",
    "phone": "+44 (0)1603 411923",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.norwichairport.co.uk\/",
    "runway_length": "6043",
    "elev": "117",
    "icao": "EGSH",
    "direct_flights": "19",
    "carriers": "14"
  },
  {
    "code": "NYA",
    "lat": "44.7718",
    "lon": "-93.9263",
    "name": "",
    "city": "Norwood Young America",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "23417628",
    "tz": "America\/Menominee",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "NYK",
    "lat": "-0.0583",
    "lon": "37.0369",
    "name": "Nanyuki Airport",
    "city": "Nyeri",
    "state": "Rift Valley",
    "country": "Kenya",
    "woeid": "12514079",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "6250",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "NYM",
    "lat": "65.6179",
    "lon": "72.6856",
    "name": "Nadym Airport",
    "city": "Nadym",
    "state": "Yamalo-Nenetskiy Avtonomnyy Okru",
    "country": "Russia",
    "woeid": "12516467",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "NYO",
    "lat": "58.7833",
    "lon": "16.9224",
    "name": "Skavsta Airport",
    "city": "Nykoping",
    "state": "Sodermanland",
    "country": "Sweden",
    "woeid": "12517663",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.skavsta-air.se",
    "runway_length": "8202",
    "elev": "140",
    "icao": "ESKN",
    "direct_flights": "36",
    "carriers": "5"
  },
  {
    "code": "NYU",
    "lat": "21.1814",
    "lon": "94.9347",
    "name": "Nyaung U Airport",
    "city": "Nyaung-u",
    "state": "Mandalay",
    "country": "Myanmar",
    "woeid": "12510930",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "300",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "NZH",
    "lat": "49.6",
    "lon": "117.433",
    "name": "Manzhouli",
    "city": "Manzhouli",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "2149766",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OAG",
    "lat": "-32.3833",
    "lon": "149.132",
    "name": "Springhill Airport",
    "city": "Arthurville",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510756",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "3112",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OAJ",
    "lat": "34.8307",
    "lon": "-77.6053",
    "name": "Albert J Ellis Airport",
    "city": "Richlands",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12518557",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7100",
    "elev": "94",
    "icao": "KOAJ",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "OAK",
    "lat": "37.7158",
    "lon": "-122.209",
    "name": "Oakland International Airport",
    "city": "Oakland",
    "state": "California",
    "country": "United States",
    "woeid": "12520917",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "6",
    "icao": "KOAK",
    "direct_flights": "35",
    "carriers": "19"
  },
  {
    "code": "OAM",
    "lat": "-44.9679",
    "lon": "171.086",
    "name": "Oamaru Airport",
    "city": "Oamaru",
    "state": "Canterbury",
    "country": "New Zealand",
    "woeid": "12515162",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "99",
    "icao": "NZOU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OAX",
    "lat": "16.9986",
    "lon": "-96.7264",
    "name": "Xoxocotlan Airport",
    "city": "San Bernardo Mixtepec",
    "state": "Oaxaca",
    "country": "Mexico",
    "woeid": "12514986",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8036",
    "elev": "5012",
    "icao": "MMOX",
    "direct_flights": "4",
    "carriers": "10"
  },
  {
    "code": "OBN",
    "lat": "56.4638",
    "lon": "-5.3973",
    "name": "Oban Connel Airport",
    "city": "Oban",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "12518137",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.argyll-bute.gov.uk\/newsroom\/features\/obanairport?a=3",
    "runway_length": "4152",
    "elev": "20",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "OBO",
    "lat": "42.7343",
    "lon": "143.216",
    "name": "Obihiro Airport",
    "city": "Obihiro-shi",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "12523194",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "275",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "OBU",
    "lat": "66.9148",
    "lon": "-156.878",
    "name": "Kobuk Airport",
    "city": "Kobuk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524644",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "145",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "OBX",
    "lat": "-7.58333",
    "lon": "141.333",
    "name": "Obo",
    "city": "Obo",
    "state": "Western",
    "country": "Papua New Guinea",
    "woeid": "1061857",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1600",
    "elev": "69",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "OCC",
    "lat": "-0.46532",
    "lon": "-76.979",
    "name": "Coca Airport",
    "city": "Chontapunta",
    "state": "Orellano",
    "country": "Ecuador",
    "woeid": "12524524",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5107",
    "elev": "820",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "ODN",
    "lat": "2.91165",
    "lon": "112.609",
    "name": "Sarawak",
    "city": "Long Seridan",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "2346305",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1500",
    "elev": "607",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ODS",
    "lat": "46.4402",
    "lon": "30.6764",
    "name": "Odessa Central Airport",
    "city": "Odesa",
    "state": "Odes\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518374",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9186",
    "elev": "164",
    "icao": "UKOO",
    "direct_flights": "21",
    "carriers": "23"
  },
  {
    "code": "ODW",
    "lat": "48.2517",
    "lon": "-122.68",
    "name": "Oak Harbor Airpark",
    "city": "Oak Harbor",
    "state": "Washington",
    "country": "United States",
    "woeid": "12521178",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3267",
    "elev": "189",
    "icao": "KOKH",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ODY",
    "lat": "20.5833",
    "lon": "104.167",
    "name": "Oudomxay",
    "city": "Oudomxay",
    "state": "Houaphan",
    "country": "Lao People's Democratic Republic",
    "woeid": "12848841",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OER",
    "lat": "63.4113",
    "lon": "18.9916",
    "name": "Ornskoldsvik Airport",
    "city": "Husum",
    "state": "Vasternorrland",
    "country": "Sweden",
    "woeid": "12517666",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "351",
    "icao": "ESNO",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "OFI",
    "lat": "9.6",
    "lon": "-4.03333",
    "name": "Ouango Fitini",
    "city": "Ouango Fitini",
    "state": "Bouna",
    "country": "Cote D'Ivoire",
    "woeid": "1345337",
    "tz": "Africa\/Abidjan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "974",
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "OGG",
    "lat": "20.8926",
    "lon": "-156.441",
    "name": "Kahului Airport",
    "city": "Kahului",
    "state": "Hawaii",
    "country": "United States",
    "woeid": "12520412",
    "tz": "Pacific\/Honolulu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "53",
    "icao": "PHOG",
    "direct_flights": "20",
    "carriers": "19"
  },
  {
    "code": "OGN",
    "lat": "24.4674",
    "lon": "122.979",
    "name": "Yonaguni Airport",
    "city": "Yonaguni-cho",
    "state": "Okinawa Prefecture",
    "country": "Japan",
    "woeid": "12514039",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2620",
    "elev": "54",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OGS",
    "lat": "44.6782",
    "lon": "-75.4765",
    "name": "Ogdensburg International Airport",
    "city": "Ogdensburg",
    "state": "New York",
    "country": "United States",
    "woeid": "12521199",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5201",
    "elev": "297",
    "icao": "KOGS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OGX",
    "lat": "35.8764",
    "lon": "7.25695",
    "name": "Ain Beida",
    "city": "Ouargla",
    "state": "Oum el Bouaghi",
    "country": "Algeria",
    "woeid": "12805369",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "492",
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "OGZ",
    "lat": "43.2065",
    "lon": "44.6105",
    "name": "Ordzhonikidze North Airport",
    "city": "Mozdok",
    "state": "Severnaya Osetiya-Alaniya",
    "country": "Russia",
    "woeid": "12516613",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OHD",
    "lat": "41.1806",
    "lon": "20.7431",
    "name": "Ohrid Airport",
    "city": "Ohrid",
    "state": "Debarca",
    "country": "Macedonia",
    "woeid": "12514771",
    "tz": "Europe\/Skopje",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "2313",
    "icao": "LWOH",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "OHE",
    "lat": "53.6321",
    "lon": "10.0042",
    "name": "Hamburg Airport",
    "city": "Hamburg",
    "state": "Hamburg",
    "country": "Germany",
    "woeid": "22211603",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OHO",
    "lat": "59.4103",
    "lon": "143.062",
    "name": "Okhotsk Airport",
    "city": "Okhotsk",
    "state": "Khabarovskiy Kray",
    "country": "Russia",
    "woeid": "12516592",
    "tz": "Asia\/Vladivostok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OIM",
    "lat": "34.7828",
    "lon": "139.361",
    "name": "Oshima Airport",
    "city": "Oshima-machi",
    "state": "Tokyo Prefecture",
    "country": "Japan",
    "woeid": "12514010",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "128",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "OIT",
    "lat": "33.4801",
    "lon": "131.737",
    "name": "Oita Airport",
    "city": "Kunisaki-shi",
    "state": "Oita Prefecture",
    "country": "Japan",
    "woeid": "12514003",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "19",
    "icao": "RJFO",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "OKA",
    "lat": "24.8289",
    "lon": "125.146",
    "name": "Shimojishima Airport",
    "city": "Naha-shi",
    "state": "Okinawa Prefecture",
    "country": "Japan",
    "woeid": "12514018",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "15",
    "icao": "ROAH",
    "direct_flights": "28",
    "carriers": "13"
  },
  {
    "code": "OKC",
    "lat": "35.3981",
    "lon": "-97.5974",
    "name": "Will Rogers World Airport",
    "city": "Oklahoma City",
    "state": "Oklahoma",
    "country": "United States",
    "woeid": "12522444",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9802",
    "elev": "1295",
    "icao": "KOKC",
    "direct_flights": "29",
    "carriers": "28"
  },
  {
    "code": "OKD",
    "lat": "43.1162",
    "lon": "141.382",
    "name": "Okadama Airport",
    "city": "Sapporo-shi",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "23388309",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "26",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "OKJ",
    "lat": "34.7579",
    "lon": "133.855",
    "name": "Okayama Airport",
    "city": "Okayama-shi",
    "state": "Okayama Prefecture",
    "country": "Japan",
    "woeid": "12514005",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "3",
    "icao": "RJOB",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "OKR",
    "lat": "36.6159",
    "lon": "-85.1253",
    "name": "",
    "city": "Yorke Island",
    "state": "",
    "country": "Australia",
    "woeid": "23424748",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OKY",
    "lat": "-27.4086",
    "lon": "151.738",
    "name": "Oakey Aerodrome",
    "city": "Oakey",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510754",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "1334",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "OLA",
    "lat": "63.696",
    "lon": "9.60463",
    "name": "Orland Airport",
    "city": "Orland",
    "state": "Sor-Trondelag",
    "country": "Norway",
    "woeid": "12515117",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8904",
    "elev": "30",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OLB",
    "lat": "40.8994",
    "lon": "9.51616",
    "name": "Olbia Costa Smeralda Airport",
    "city": "Terranova",
    "state": "Sardinia",
    "country": "Italy",
    "woeid": "12513840",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8022",
    "elev": "37",
    "icao": "",
    "direct_flights": "49",
    "carriers": "24"
  },
  {
    "code": "OLF",
    "lat": "48.0964",
    "lon": "-105.575",
    "name": "L. M. Clayton Airport",
    "city": "Wolf Point",
    "state": "Montana",
    "country": "United States",
    "woeid": "29387871",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5089",
    "elev": "1986",
    "icao": "KOLF",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OLH",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Old Harbor",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "15",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "OLJ",
    "lat": "-16.2368",
    "lon": "167.492",
    "name": "Malampa",
    "city": "Olpoi",
    "state": "Malampa",
    "country": "Vanuatu",
    "woeid": "20069886",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OLP",
    "lat": "-30.484",
    "lon": "136.878",
    "name": "Olympic Dam Aerodrome",
    "city": "Roxby Downs",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "12510755",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OMA",
    "lat": "41.3",
    "lon": "-95.8998",
    "name": "Eppley Airfield",
    "city": "Omaha",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12519653",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8500",
    "elev": "983",
    "icao": "KOMA",
    "direct_flights": "24",
    "carriers": "32"
  },
  {
    "code": "OMB",
    "lat": "-1.5778",
    "lon": "9.25386",
    "name": "Ombou\u00e9",
    "city": "Omboue",
    "state": "Ogooue-Martime",
    "country": "Gabon",
    "woeid": "1325458",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "33",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OMC",
    "lat": "11.0611",
    "lon": "124.563",
    "name": "Ormoc Airport",
    "city": "Ormoc",
    "state": "Eastern Visayas",
    "country": "Philippines",
    "woeid": "12515639",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3609",
    "elev": "82",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "OMD",
    "lat": "-28.5956",
    "lon": "16.4436",
    "name": "Oranjemund Airport",
    "city": "Oranjemund",
    "state": "Karas",
    "country": "Namibia",
    "woeid": "12522987",
    "tz": "Africa\/Windhoek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "2",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "OME",
    "lat": "64.5125",
    "lon": "-165.445",
    "name": "Nome Airport",
    "city": "Nome",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12521145",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6001",
    "elev": "36",
    "icao": "PAOM",
    "direct_flights": "16",
    "carriers": "8"
  },
  {
    "code": "OMH",
    "lat": "37.6694",
    "lon": "45.0703",
    "name": "Uromiyeh Airport",
    "city": "Urmieh",
    "state": "Azarbayjan-e Gharbi",
    "country": "Iran",
    "woeid": "12513769",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9514",
    "elev": "4295",
    "icao": "KOMH",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "OMO",
    "lat": "43.2836",
    "lon": "17.8467",
    "name": "Mostar Airport",
    "city": "Mostar",
    "state": "Federacija Bosne I Hercegovine",
    "country": "Bosnia and Herzegovina",
    "woeid": "12510900",
    "tz": "Europe\/Belgrade",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "157",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "OMR",
    "lat": "47.0249",
    "lon": "21.9292",
    "name": "Oradea Airport",
    "city": "Oradea-Mare",
    "state": "Bihor",
    "country": "Romania",
    "woeid": "12523966",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "462",
    "icao": "LROD",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "OMS",
    "lat": "54.9634",
    "lon": "73.3068",
    "name": "Omsk Southwest Airport",
    "city": "Omsk",
    "state": "Omskaya Oblast",
    "country": "Russia",
    "woeid": "12516609",
    "tz": "Asia\/Omsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "308",
    "icao": "",
    "direct_flights": "19",
    "carriers": "14"
  },
  {
    "code": "OND",
    "lat": "-17.8783",
    "lon": "15.95",
    "name": "Ondangwa Airport",
    "city": "Ondangwa",
    "state": "Oshana",
    "country": "Namibia",
    "woeid": "12522985",
    "tz": "Africa\/Windhoek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9750",
    "elev": "3592",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ONG",
    "lat": "-16.565",
    "lon": "139.434",
    "name": "Mornington Island Airport",
    "city": "Mornington",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510742",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "15",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ONJ",
    "lat": "40.1931",
    "lon": "140.366",
    "name": "Odate-Noshiro Airport",
    "city": "Kitakita-shi",
    "state": "Akita Prefecture",
    "country": "Japan",
    "woeid": "28360521",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ONL",
    "lat": "42.4655",
    "lon": "-98.6866",
    "name": "The Oneill Municipal Airport",
    "city": "O'neill",
    "state": "Nebraska",
    "country": "United States",
    "woeid": "12522114",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4400",
    "elev": "2030",
    "icao": "KONL",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ONT",
    "lat": "34.062",
    "lon": "-117.594",
    "name": "Ontario International Airport",
    "city": "Ontario",
    "state": "California",
    "country": "United States",
    "woeid": "12521228",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12198",
    "elev": "944",
    "icao": "KONT",
    "direct_flights": "29",
    "carriers": "17"
  },
  {
    "code": "OOK",
    "lat": "60.5334",
    "lon": "-165.114",
    "name": "Toksook Bay",
    "city": "Toksook Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2506880",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PAOO",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "OOL",
    "lat": "51.188",
    "lon": "5.94811",
    "name": "Gold Coast (Coolangatta)",
    "city": "Gold Coast",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "7226269",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6700",
    "elev": "21",
    "icao": "YBCG",
    "direct_flights": "12",
    "carriers": "9"
  },
  {
    "code": "OPF",
    "lat": "25.9023",
    "lon": "-80.2677",
    "name": "Opa Locka Airport",
    "city": "Opa-Locka",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521232",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "8002",
    "elev": "9",
    "icao": "KOPF",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OPO",
    "lat": "41.2372",
    "lon": "-8.67341",
    "name": "Porto Airport",
    "city": "Maia",
    "state": "Porto",
    "country": "Portugal",
    "woeid": "12515455",
    "tz": "Europe\/Lisbon",
    "phone": "229 432 400",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ana-aeroportos.pt",
    "runway_length": "11417",
    "elev": "228",
    "icao": "LPPR",
    "direct_flights": "48",
    "carriers": "26"
  },
  {
    "code": "OPS",
    "lat": "-11.8733",
    "lon": "-55.5733",
    "name": "Sinop Airport",
    "city": "Sinop",
    "state": "Mato Grosso",
    "country": "Brazil",
    "woeid": "12511328",
    "tz": "America\/Campo_Grande",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "OPU",
    "lat": "-8",
    "lon": "142.906",
    "name": "Balimo",
    "city": "Balimo",
    "state": "Western",
    "country": "Papua New Guinea",
    "woeid": "1061889",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4270",
    "elev": "100",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ORB",
    "lat": "59.2279",
    "lon": "15.0455",
    "name": "Orebro Airport",
    "city": "Orebro",
    "state": "Orebro",
    "country": "Sweden",
    "woeid": "12517665",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3346",
    "elev": "102",
    "icao": "KORB",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "ORD",
    "lat": "41.9796",
    "lon": "-87.8825",
    "name": "Chicago O'Hare International Airport",
    "city": "Chicago",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12521200",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flychicago.com\/ohare\/home.asp",
    "runway_length": "13000",
    "elev": "668",
    "icao": "KORD",
    "direct_flights": "222",
    "carriers": "85"
  },
  {
    "code": "ORF",
    "lat": "36.8977",
    "lon": "-76.2154",
    "name": "Norfolk International Airport",
    "city": "Norfolk",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12521146",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "27",
    "icao": "KORF",
    "direct_flights": "28",
    "carriers": "27"
  },
  {
    "code": "ORH",
    "lat": "42.2699",
    "lon": "-71.8706",
    "name": "Worcester Municipal Airport",
    "city": "Worcester",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12522514",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6999",
    "elev": "1008",
    "icao": "KORH",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ORI",
    "lat": "-7.6833",
    "lon": "-39.3667",
    "name": "",
    "city": "Port Lions",
    "state": "Alaska",
    "country": "United States",
    "woeid": "23510508",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "52",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "ORJ",
    "lat": "4.71667",
    "lon": "-60.0333",
    "name": "Orinduik Airport",
    "city": "Rera",
    "state": "Roraima",
    "country": "Brazil",
    "woeid": "12524536",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2826",
    "elev": "1800",
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ORK",
    "lat": "51.8463",
    "lon": "-8.48897",
    "name": "Cork Airport",
    "city": "Fivemilebridge",
    "state": "",
    "country": "Ireland",
    "woeid": "12512720",
    "tz": "Europe\/Dublin",
    "phone": "+353 (0)21 4313131",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.corkairport.com",
    "runway_length": null,
    "elev": "502",
    "icao": "EICK",
    "direct_flights": "38",
    "carriers": "13"
  },
  {
    "code": "ORM",
    "lat": "52.3",
    "lon": "-0.78333",
    "name": "Sywell Airport",
    "city": "Northampton",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12523997",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "2982",
    "elev": "429",
    "icao": "EGBK",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ORN",
    "lat": "35.6244",
    "lon": "-0.6186",
    "name": "Es Senia Airport",
    "city": "Oran Rp",
    "state": "Oran",
    "country": "Algeria",
    "woeid": "12510326",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "295",
    "icao": "",
    "direct_flights": "23",
    "carriers": "3"
  },
  {
    "code": "ORV",
    "lat": "66.8333",
    "lon": "-161.033",
    "name": "Curtis Memorial",
    "city": "Noorvik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799802",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3200",
    "elev": "63",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "ORY",
    "lat": "48.7261",
    "lon": "2.36411",
    "name": "Paris Orly Airport",
    "city": "Paris",
    "state": "Ile-de-France",
    "country": "France",
    "woeid": "22144181",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.adp.fr",
    "runway_length": "11975",
    "elev": "292",
    "icao": "LFPO",
    "direct_flights": "138",
    "carriers": "52"
  },
  {
    "code": "OSD",
    "lat": "63.1972",
    "lon": "14.4933",
    "name": "Ostersunds Airport",
    "city": "Fr\u00f6s\u00f6",
    "state": "Jamtland",
    "country": "Sweden",
    "woeid": "12517626",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "1233",
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "OSI",
    "lat": "45.4639",
    "lon": "18.8083",
    "name": "Osijek Airport",
    "city": "Osijek",
    "state": "Osje\u010dko-baranjska",
    "country": "Croatia",
    "woeid": "12513368",
    "tz": "Europe\/Zagreb",
    "phone": "00385 31 51 44 40",
    "type": "Airports",
    "email": "zlosijek-opc@os.t-com.hr",
    "url": "http:\/\/www.osijek-airport.hr",
    "runway_length": "3990",
    "elev": "298",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OSK",
    "lat": "57.3517",
    "lon": "16.4956",
    "name": "Oskarshamn Airport",
    "city": "F\u00e5rbo",
    "state": "Kalmar",
    "country": "Sweden",
    "woeid": "12517668",
    "tz": "Europe\/Stockholm",
    "phone": "+46 (0)491-332 00",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2953",
    "elev": "96",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "OSL",
    "lat": "60.1947",
    "lon": "11.1005",
    "name": "Oslo Gardermoen Airport",
    "city": "Gardermoen",
    "state": "Akershus Fylke",
    "country": "Norway",
    "woeid": "12515119",
    "tz": "Europe\/Oslo",
    "phone": "+47 64812000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.osl.no",
    "runway_length": "11811",
    "elev": "681",
    "icao": "ENGM",
    "direct_flights": "116",
    "carriers": "59"
  },
  {
    "code": "OSM",
    "lat": "36.3086",
    "lon": "43.1458",
    "name": "Mosul Airport",
    "city": "Mosul",
    "state": "Ninawa",
    "country": "Iraq",
    "woeid": "12513911",
    "tz": "Asia\/Baghdad",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8600",
    "elev": "910",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OSR",
    "lat": "49.6888",
    "lon": "18.1178",
    "name": "Mosnov Airport",
    "city": "Mo\u0161nov",
    "state": "Moravskoslezsk\u00fd",
    "country": "Czech Republic",
    "woeid": "12512552",
    "tz": "Europe\/Prague",
    "phone": "+420 59 747 1122",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport-ostrava.cz",
    "runway_length": "11483",
    "elev": "844",
    "icao": "LKMT",
    "direct_flights": "8",
    "carriers": "10"
  },
  {
    "code": "OSS",
    "lat": "40.6155",
    "lon": "72.7853",
    "name": "Osh Airport",
    "city": "Osh",
    "state": "Osh",
    "country": "Kyrgyzstan",
    "woeid": "12514111",
    "tz": "Asia\/Bishkek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "OST",
    "lat": "51.2012",
    "lon": "2.8716",
    "name": "Oostende Airport",
    "city": "Oostende",
    "state": "West-Vlaanderen",
    "country": "Belgium",
    "woeid": "12510854",
    "tz": "Europe\/Brussels",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10499",
    "elev": "13",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "OSW",
    "lat": "51.2085",
    "lon": "58.6325",
    "name": "Orsk",
    "city": "Orsk",
    "state": "Orenburgskaya Oblast",
    "country": "Russia",
    "woeid": "2122669",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OSY",
    "lat": "64.4723",
    "lon": "11.5721",
    "name": "Namsos Airport",
    "city": "Namsos",
    "state": "Nord-Trondelag",
    "country": "Norway",
    "woeid": "12523944",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "7",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "OSZ",
    "lat": "54.25",
    "lon": "16.25",
    "name": "Koszalin Airport",
    "city": "Sian\u00f3w",
    "state": "Zachodniopomorskie",
    "country": "Poland",
    "woeid": "12523958",
    "tz": "Europe\/Warsaw",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "OTH",
    "lat": "43.4141",
    "lon": "-124.244",
    "name": "North Bend Municipal Airport",
    "city": "North Bend",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12521150",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5045",
    "elev": "14",
    "icao": "KOTH",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "OTP",
    "lat": "44.5656",
    "lon": "26.1029",
    "name": "Otopeni Airport",
    "city": "Bucharest",
    "state": "Ilfov",
    "country": "Romania",
    "woeid": "12515558",
    "tz": "Europe\/Bucharest",
    "phone": "+40 21 204 1200",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.otp-airport.ro",
    "runway_length": "11483",
    "elev": "312",
    "icao": "LROP",
    "direct_flights": "52",
    "carriers": "42"
  },
  {
    "code": "OTR",
    "lat": "8.6006",
    "lon": "-82.9694",
    "name": "Coto 47 Airport",
    "city": "Coto 47",
    "state": "Puntarenas",
    "country": "Costa Rica",
    "woeid": "12512429",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3084",
    "elev": "26",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OTZ",
    "lat": "66.8893",
    "lon": "-162.61",
    "name": "Ralph Wien Memorial Airport",
    "city": "Kotzebue",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12521522",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5900",
    "elev": "11",
    "icao": "PAOT",
    "direct_flights": "13",
    "carriers": "8"
  },
  {
    "code": "OUA",
    "lat": "12.3522",
    "lon": "-1.5133",
    "name": "Ouagadougou Airport",
    "city": "Ouagadougou",
    "state": "Kadiogo",
    "country": "Burkina Faso",
    "woeid": "12522555",
    "tz": "Africa\/Ouagadougou",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "1037",
    "icao": "DFFD",
    "direct_flights": "13",
    "carriers": "15"
  },
  {
    "code": "OUD",
    "lat": "34.7903",
    "lon": "-1.925",
    "name": "Angads Airport",
    "city": "Oujda",
    "state": "Oujda",
    "country": "Morocco",
    "woeid": "12514781",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "1535",
    "icao": "GMFO",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "OUE",
    "lat": "1.6167",
    "lon": "16.0444",
    "name": "Ouesso Airport",
    "city": "Ouesso",
    "state": "Sangha",
    "country": "Congo",
    "woeid": "12511947",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "1155",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OUL",
    "lat": "64.9294",
    "lon": "25.3574",
    "name": "Oulu Airport",
    "city": "Oulunsalo",
    "state": "Oulu Province",
    "country": "Finland",
    "woeid": "12512792",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "47",
    "icao": "EFOU",
    "direct_flights": "3",
    "carriers": "8"
  },
  {
    "code": "OUZ",
    "lat": "22.7337",
    "lon": "-12.4941",
    "name": "Mauritania",
    "city": "Zouerate",
    "state": "Tiris Zemmour",
    "country": "Mauritania",
    "woeid": "23424896",
    "tz": "Africa\/Nouakchott",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "1050",
    "icao": "GQPZ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OVB",
    "lat": "55.0078",
    "lon": "82.6508",
    "name": "Tolmachevo Airport",
    "city": "Novosibirsk",
    "state": "Novosibirskaya Oblast",
    "country": "Russia",
    "woeid": "12517016",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11808",
    "elev": "364",
    "icao": "UNNT",
    "direct_flights": "51",
    "carriers": "24"
  },
  {
    "code": "OVD",
    "lat": "43.5596",
    "lon": "-6.03102",
    "name": "Asturias Airport",
    "city": "Castrill\u00f3n",
    "state": "Asturias",
    "country": "Spain",
    "woeid": "12517538",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "415",
    "icao": "LEAS",
    "direct_flights": "18",
    "carriers": "17"
  },
  {
    "code": "OVS",
    "lat": "43.1537",
    "lon": "-90.6764",
    "name": "Boscobel Airport",
    "city": "Boscobel",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12518898",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KOVS",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "OXB",
    "lat": "11.8886",
    "lon": "-15.6575",
    "name": "Bissau Oswaldo Vieira Airport",
    "city": "Bissau",
    "state": "Biombo",
    "country": "Guinea-Bissau",
    "woeid": "12515483",
    "tz": "Africa\/Bissau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KOXB",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "OXF",
    "lat": "51.831",
    "lon": "-1.31029",
    "name": "Oxford Airport",
    "city": "Kidlington",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518107",
    "tz": "Europe\/London",
    "phone": "0044 1865 844 260",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.oxfordairport.co.uk",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "OXR",
    "lat": "34.1984",
    "lon": "-119.206",
    "name": "Oxnard Airport",
    "city": "Oxnard",
    "state": "California",
    "country": "United States",
    "woeid": "12521271",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5950",
    "elev": "43",
    "icao": "KOXR",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "OYE",
    "lat": "1.5353",
    "lon": "11.5803",
    "name": "Oyem Airport",
    "city": "Oyem",
    "state": "Woleu-Ntem",
    "country": "Gabon",
    "woeid": "12512999",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6005",
    "elev": "2158",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "OYG",
    "lat": "3.64967",
    "lon": "31.7239",
    "name": "Moyo",
    "city": "Moyo",
    "state": "Moyo",
    "country": "Uganda",
    "woeid": "1453837",
    "tz": "Africa\/Kampala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4260",
    "elev": "3100",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "OZC",
    "lat": "8.1822",
    "lon": "123.841",
    "name": "Ozamis-Mindanao Island Airport",
    "city": "Ozamis City",
    "state": "Northern Mindanao",
    "country": "Philippines",
    "woeid": "12515640",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "16",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "OZH",
    "lat": "47.86",
    "lon": "35.3212",
    "name": "Zaporozhye East Airport",
    "city": "Zaporizhzhya",
    "state": "Zaporiz\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518494",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "282",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "OZZ",
    "lat": "30.9342",
    "lon": "-6.9031",
    "name": "Ouarzazate Airport",
    "city": "Ouarzazate",
    "state": "Ouarzazate",
    "country": "Morocco",
    "woeid": "12514789",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "3737",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PAD",
    "lat": "51.6126",
    "lon": "8.61871",
    "name": "Paderborn-Lippstadt Airport",
    "city": "B\u00fcren",
    "state": "North Rhine-Westphalia",
    "country": "Germany",
    "woeid": "22179500",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "712",
    "icao": "EDLP",
    "direct_flights": "23",
    "carriers": "6"
  },
  {
    "code": "PAH",
    "lat": "37.0612",
    "lon": "-88.7672",
    "name": "Barkley Regional Airport",
    "city": "West Paducah",
    "state": "Kentucky",
    "country": "United States",
    "woeid": "12518741",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6499",
    "elev": "410",
    "icao": "KPAH",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "PAI",
    "lat": "12.85",
    "lon": "102.583",
    "name": "Pailin Airport",
    "city": "Pailin",
    "state": "Pailin",
    "country": "Cambodia",
    "woeid": "12523199",
    "tz": "Asia\/Phnom_Penh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "623",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PAP",
    "lat": "36.4215",
    "lon": "-83.3607",
    "name": "",
    "city": "Port Au Prince",
    "state": "Ouest",
    "country": "Haiti",
    "woeid": "96110",
    "tz": "America\/Port-au-Prince",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9974",
    "elev": "109",
    "icao": "MTPP",
    "direct_flights": "12",
    "carriers": "11"
  },
  {
    "code": "PAS",
    "lat": "37.0683",
    "lon": "25.1975",
    "name": "Paros Island Airport",
    "city": "Levkai",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513317",
    "tz": "Europe\/Athens",
    "phone": "0030-2284091257",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2362",
    "elev": "121",
    "icao": "LGPA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PAT",
    "lat": "25.5914",
    "lon": "85.0866",
    "name": "Lok Nayak Jaiprakash Airport",
    "city": "Patna",
    "state": "Bihar",
    "country": "India",
    "woeid": "12513651",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6545",
    "elev": "171",
    "icao": "VEPT",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "PAZ",
    "lat": "20.6086",
    "lon": "-97.4717",
    "name": "Tajin Airport",
    "city": "Castillo de Teayo",
    "state": "Veracruz-Llave",
    "country": "Mexico",
    "woeid": "12514968",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "453",
    "icao": "MMPA",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "PBC",
    "lat": "19.1433",
    "lon": "-98.3714",
    "name": "Puebla Airport",
    "city": "Tlaltenango",
    "state": "Puebla",
    "country": "Mexico",
    "woeid": "12514937",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "7118",
    "icao": "MMPB",
    "direct_flights": "5",
    "carriers": "8"
  },
  {
    "code": "PBD",
    "lat": "21.6489",
    "lon": "69.6567",
    "name": "Porbandar Airport",
    "city": "Porbandar",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513653",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "21",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PBG",
    "lat": "44.6508",
    "lon": "-73.4683",
    "name": "Plattsburgh Air Force Base",
    "city": "Plattsburgh",
    "state": "New York",
    "country": "United States",
    "woeid": "12521414",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KPBG",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "PBH",
    "lat": "27.4033",
    "lon": "89.4292",
    "name": "Paro Airport",
    "city": "Paro",
    "state": "Paro",
    "country": "Bhutan",
    "woeid": "12511377",
    "tz": "Asia\/Thimphu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.drukair.com.bt",
    "runway_length": "4935",
    "elev": "7332",
    "icao": "KPBH",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "PBI",
    "lat": "26.6909",
    "lon": "-80.0889",
    "name": "Palm Beach International Airport",
    "city": "West Palm Beach",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521288",
    "tz": "America\/New_York",
    "phone": "(561) 471-7420",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.pbia.org",
    "runway_length": "7989",
    "elev": "19",
    "icao": "KPBI",
    "direct_flights": "31",
    "carriers": "24"
  },
  {
    "code": "PBJ",
    "lat": "-16.2368",
    "lon": "167.492",
    "name": "Malampa",
    "city": "Paama",
    "state": "Malampa",
    "country": "Vanuatu",
    "woeid": "20069886",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PBM",
    "lat": "5.45",
    "lon": "-55.1867",
    "name": "Zandery Airport",
    "city": "Sabakoe",
    "state": "Para",
    "country": "Suriname",
    "woeid": "12515136",
    "tz": "America\/Paramaribo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11417",
    "elev": "54",
    "icao": "SMJP",
    "direct_flights": "6",
    "carriers": "5"
  },
  {
    "code": "PBO",
    "lat": "-23.1688",
    "lon": "117.748",
    "name": "Paraburdoo Airport",
    "city": "Paraburdoo",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510757",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "1411",
    "icao": "YPBO",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PBP",
    "lat": "9.85917",
    "lon": "-85.3692",
    "name": "Punta Islita Airport",
    "city": "Punta Islita",
    "state": "Guanacaste",
    "country": "Costa Rica",
    "woeid": "58155",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "PBU",
    "lat": "27.33",
    "lon": "97.4306",
    "name": "Putao Airport",
    "city": "Putao",
    "state": "Kachin State",
    "country": "Myanmar",
    "woeid": "12510933",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1500",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PCE",
    "lat": "57.093",
    "lon": "-157.258",
    "name": "Painter Creek",
    "city": "Painter Creek",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799701",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PCL",
    "lat": "-8.3753",
    "lon": "-74.5731",
    "name": "Pucallpa Airport",
    "city": "Callaria",
    "state": "Ucayali",
    "country": "Peru",
    "woeid": "12515216",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "515",
    "icao": "SPCL",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "PCR",
    "lat": "6.18333",
    "lon": "-67.6333",
    "name": "Puerto Carreno Airport",
    "city": "Puerto Carre\u00f1o",
    "state": "Vichada",
    "country": "Colombia",
    "woeid": "12523680",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5865",
    "elev": "180",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PDA",
    "lat": "3.95607",
    "lon": "-67.784",
    "name": "Puerto Inirida Airport",
    "city": "Guaviare",
    "state": "Guainia",
    "country": "Colombia",
    "woeid": "12523681",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5576",
    "elev": "460",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PDB",
    "lat": "59.7868",
    "lon": "-154.105",
    "name": "Pedro Bay",
    "city": "Pedro Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2469932",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1800",
    "elev": "45",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PDG",
    "lat": "-0.8794",
    "lon": "100.352",
    "name": "Tabing Airport",
    "city": "Padang",
    "state": "Sumatera Barat",
    "country": "Indonesia",
    "woeid": "12513515",
    "tz": "Asia\/Jakarta",
    "phone": "+62 (0)751 819123",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7152",
    "elev": "16",
    "icao": "WIPT",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "PDL",
    "lat": "37.7375",
    "lon": "-25.6983",
    "name": "Ponta Delgada Airport",
    "city": "Ponta Delgada",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515454",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "259",
    "icao": "LPPD",
    "direct_flights": "21",
    "carriers": "5"
  },
  {
    "code": "PDP",
    "lat": "-34.9111",
    "lon": "-54.9197",
    "name": "Maldonado Airport",
    "city": "Punta del Este",
    "state": "Maldonado",
    "country": "Uruguay",
    "woeid": "12522563",
    "tz": "America\/Montevideo",
    "phone": "042 559777 - 042 559387",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2133",
    "elev": "95",
    "icao": "SULS",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PDS",
    "lat": "28.7",
    "lon": "-100.55",
    "name": "Piedras Negras International Airport",
    "city": "Piedras Negras",
    "state": "Coahuila de Zaragoza",
    "country": "Mexico",
    "woeid": "12514933",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "980",
    "icao": "MMPG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PDT",
    "lat": "45.6893",
    "lon": "-118.839",
    "name": "Eastern Oregon Regional Airport",
    "city": "Pendleton",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12521330",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6301",
    "elev": "1494",
    "icao": "KPDT",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "PDX",
    "lat": "45.5867",
    "lon": "-122.587",
    "name": "Portland International Airport",
    "city": "Portland",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12521453",
    "tz": "America\/Los_Angeles",
    "phone": "(+1) 877 739 4636",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flypdx.com\/",
    "runway_length": "11011",
    "elev": "22",
    "icao": "KPDX",
    "direct_flights": "74",
    "carriers": "43"
  },
  {
    "code": "PEC",
    "lat": "57.95",
    "lon": "-136.233",
    "name": "Pelican Sea Plane Base",
    "city": "Pelican",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2470028",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PED",
    "lat": "50.0372",
    "lon": "15.7785",
    "name": "Pardubice",
    "city": "Pardubice",
    "state": "Pardubicky Kraj",
    "country": "Czech Republic",
    "woeid": "795793",
    "tz": "Europe\/Prague",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LKPD",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PEE",
    "lat": "57.9114",
    "lon": "56.0174",
    "name": "Bolshesavino Airport",
    "city": "Perm'",
    "state": "Permskiy Kray",
    "country": "Russia",
    "woeid": "12515842",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "12",
    "carriers": "8"
  },
  {
    "code": "PEG",
    "lat": "43.1021",
    "lon": "12.5075",
    "name": "Perugia Airport",
    "city": "Assisi",
    "state": "Umbria",
    "country": "Italy",
    "woeid": "12513847",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5020",
    "elev": "686",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "PEI",
    "lat": "4.8147",
    "lon": "-75.745",
    "name": "Matecana Airport",
    "city": "Pereira",
    "state": "Risaralda",
    "country": "Colombia",
    "woeid": "12512397",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6626",
    "elev": "4416",
    "icao": "SKPE",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "PEK",
    "lat": "40.0724",
    "lon": "116.583",
    "name": "Beijing Capital Airport",
    "city": "Shunyi",
    "state": "Beijing",
    "country": "China",
    "woeid": "12511989",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bcia.com.cn",
    "runway_length": "12467",
    "elev": "115",
    "icao": "ZBAA",
    "direct_flights": "165",
    "carriers": "84"
  },
  {
    "code": "PEM",
    "lat": "-12.6128",
    "lon": "-69.2297",
    "name": "Padre Aldamiz Airport",
    "city": "Puerto Maldonado",
    "state": "Madre de Dios",
    "country": "Peru",
    "woeid": "12515213",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11630",
    "elev": "690",
    "icao": "SPTU",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "PEN",
    "lat": "5.2975",
    "lon": "100.278",
    "name": "Penang International Airport",
    "city": "Batu Maung",
    "state": "Pulau Pinang",
    "country": "Malaysia",
    "woeid": "12515004",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "604-643 4411",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.malaysiaairports.com.my",
    "runway_length": "11000",
    "elev": "11",
    "icao": "WMKP",
    "direct_flights": "23",
    "carriers": "24"
  },
  {
    "code": "PER",
    "lat": "-31.9336",
    "lon": "115.961",
    "name": "Perth International Airport",
    "city": "Perth",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510761",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.perthairport.com",
    "runway_length": "11300",
    "elev": "67",
    "icao": "YPPH",
    "direct_flights": "46",
    "carriers": "36"
  },
  {
    "code": "PES",
    "lat": "61.8886",
    "lon": "34.1452",
    "name": "Petrozavodsk Northwest Airport",
    "city": "Petrozavodsk",
    "state": "Kareliya",
    "country": "Russia",
    "woeid": "12516688",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PET",
    "lat": "-31.7181",
    "lon": "-52.3244",
    "name": "Pelotas Airport",
    "city": "Pelotas",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511267",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6503",
    "elev": "59",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PEU",
    "lat": "15.2404",
    "lon": "-83.7794",
    "name": "Puerto Lempira Airport",
    "city": "Puerto Lempira",
    "state": "Gracias a Dios",
    "country": "Honduras",
    "woeid": "12524366",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5590",
    "elev": "33",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PEW",
    "lat": "33.9933",
    "lon": "71.515",
    "name": "Peshawar Airport",
    "city": "Peshawar",
    "state": "Federally Administered Tribal Ar",
    "country": "Pakistan",
    "woeid": "12515258",
    "tz": "Asia\/Karachi",
    "phone": "0092 303 6909744",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "1180",
    "icao": "OPPS",
    "direct_flights": "13",
    "carriers": "10"
  },
  {
    "code": "PEX",
    "lat": "65.1179",
    "lon": "57.1095",
    "name": "Pechora Southwest Airport",
    "city": "Ukhta",
    "state": "Komi",
    "country": "Russia",
    "woeid": "12516665",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KPEX",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PEZ",
    "lat": "53.2167",
    "lon": "45",
    "name": "Penza",
    "city": "Penza",
    "state": "Privolzhskiy",
    "country": "Russian Federation",
    "woeid": "2122796",
    "tz": "Europe\/Volgograd",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "1200",
    "elev": "170",
    "icao": "KPEZ",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "PFB",
    "lat": "-28.2447",
    "lon": "-52.3317",
    "name": "Lauro Kurtz Airport",
    "city": "Passo Fundo",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511209",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4281",
    "elev": "2369",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "PFN",
    "lat": "30.2071",
    "lon": "-85.6829",
    "name": "Panama City Bay County Airport",
    "city": "Panama City",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521294",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6314",
    "elev": "21",
    "icao": "KPFN",
    "direct_flights": "4",
    "carriers": "9"
  },
  {
    "code": "PFO",
    "lat": "34.7194",
    "lon": "32.4847",
    "name": "Paphos International Airport",
    "city": "Paphos",
    "state": "Rep. Cyprus",
    "country": "Cyprus",
    "woeid": "12512502",
    "tz": "Asia\/Nicosia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "41",
    "icao": "LCPH",
    "direct_flights": "28",
    "carriers": "15"
  },
  {
    "code": "PFQ",
    "lat": "39.6053",
    "lon": "47.8758",
    "name": "",
    "city": "Parsabad",
    "state": "Ardabil",
    "country": "Iran",
    "woeid": "2255028",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PGA",
    "lat": "36.9243",
    "lon": "-111.451",
    "name": "Page Municipal Airport",
    "city": "Page",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12521277",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5499",
    "elev": "4310",
    "icao": "KPGA",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PGF",
    "lat": "42.74",
    "lon": "2.8692",
    "name": "Rivesaltes Airport",
    "city": "Perpignan",
    "state": "Languedoc-Roussillon",
    "country": "France",
    "woeid": "12512957",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "144",
    "icao": "LFMP",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "PGK",
    "lat": "-2.1606",
    "lon": "106.141",
    "name": "Pangkalpinang Airport",
    "city": "Pangkalpinang",
    "state": "Bangka-Belitung",
    "country": "Indonesia",
    "woeid": "12513490",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4987",
    "elev": "109",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PGM",
    "lat": "59.35",
    "lon": "-151.833",
    "name": "Port Graham",
    "city": "Port Graham",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799662",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2245",
    "elev": "93",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "PGU",
    "lat": "36.9243",
    "lon": "-111.451",
    "name": "Page Municipal Airport",
    "city": "Asalouyeh",
    "state": "",
    "country": "Iran",
    "woeid": "12521277",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11823",
    "elev": "15",
    "icao": "OIBI",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "PGV",
    "lat": "35.6336",
    "lon": "-77.3808",
    "name": "Pitt Greenville Airport",
    "city": "Greenville",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12521400",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "25",
    "icao": "KPGV",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "PGX",
    "lat": "45.1951",
    "lon": "0.81208",
    "name": "Bassillac Airport",
    "city": "St-Pierre",
    "state": "Aquitaine",
    "country": "France",
    "woeid": "12512852",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5741",
    "elev": "328",
    "icao": "LFBX",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PHC",
    "lat": "5.0125",
    "lon": "6.9625",
    "name": "Port Harcourt International Airport",
    "city": "Port Harcourt",
    "state": "Rivers",
    "country": "Nigeria",
    "woeid": "12515076",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7005",
    "elev": "61",
    "icao": "DNPO",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "PHE",
    "lat": "-20.3772",
    "lon": "118.63",
    "name": "Port Hedland Airport",
    "city": "Port Hedland",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510764",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "28",
    "icao": "YPPD",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PHF",
    "lat": "37.1306",
    "lon": "-76.5026",
    "name": "Newport News-Williamsburg International Airport",
    "city": "Newport News",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12521311",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8003",
    "elev": "43",
    "icao": "KPHF",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "PHG",
    "lat": "4.85",
    "lon": "7.01667",
    "name": "Port Harcourt City",
    "city": "Port Harcourt",
    "state": "Rivers",
    "country": "Nigeria",
    "woeid": "1404792",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KPHG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PHL",
    "lat": "39.8768",
    "lon": "-75.2419",
    "name": "Philadelphia International Airport",
    "city": "Philadelphia",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12521360",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.phl.org",
    "runway_length": "10500",
    "elev": "21",
    "icao": "KPHL",
    "direct_flights": "133",
    "carriers": "51"
  },
  {
    "code": "PHO",
    "lat": "68.3494",
    "lon": "-166.797",
    "name": "Point Hope Airport",
    "city": "Point Hope",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12521425",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "14",
    "icao": "PAPO",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "PHS",
    "lat": "16.7861",
    "lon": "100.278",
    "name": "Phitsanulok Airport",
    "city": "Phitsanulok",
    "state": "Phitsanulok",
    "country": "Thailand",
    "woeid": "12517774",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "145",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PHW",
    "lat": "-23.9336",
    "lon": "31.1561",
    "name": "Hendrik Van Eck Airport",
    "city": "Phalaborwa",
    "state": "Limpopo",
    "country": "South Africa",
    "woeid": "12517419",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4491",
    "elev": "1427",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PHX",
    "lat": "33.4376",
    "lon": "-112.03",
    "name": "Sky Harbor International Airport",
    "city": "Phoenix",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12521872",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/phoenix.gov\/Ahttp:\/\/phoenix.gov\/aviation",
    "runway_length": "11001",
    "elev": "1132",
    "icao": "KPHX",
    "direct_flights": "108",
    "carriers": "51"
  },
  {
    "code": "PIA",
    "lat": "40.6663",
    "lon": "-89.6909",
    "name": "Greater Peoria Regional Airport",
    "city": "Peoria",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12519982",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "660",
    "icao": "KPIA",
    "direct_flights": "9",
    "carriers": "14"
  },
  {
    "code": "PIB",
    "lat": "31.4671",
    "lon": "-89.3333",
    "name": "Pine Belt Regional Airport",
    "city": "Moselle",
    "state": "Mississippi",
    "country": "United States",
    "woeid": "12521381",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6501",
    "elev": "298",
    "icao": "KPIB",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "PIE",
    "lat": "27.9065",
    "lon": "-82.6924",
    "name": "St. Petersburg-Clearwater International Airport",
    "city": "Clearwater",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521978",
    "tz": "America\/New_York",
    "phone": "+1 (1)727 535-7600",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.stpete-clwairport.com",
    "runway_length": "8800",
    "elev": "10",
    "icao": "KPIE",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "PIF",
    "lat": "22.6781",
    "lon": "120.471",
    "name": "Pingdong Airport",
    "city": "Pingtung",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "12517951",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PIH",
    "lat": "42.9056",
    "lon": "-112.588",
    "name": "Pocatello Municipal Airport",
    "city": "Pocatello",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12521423",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9056",
    "elev": "4449",
    "icao": "KPIH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PIK",
    "lat": "55.5091",
    "lon": "-4.6109",
    "name": "Glasgow Prestwick International Airport",
    "city": "Prestwick",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22484413",
    "tz": "Europe\/London",
    "phone": "0871 223 0700",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.glasgowprestwick.com",
    "runway_length": "9800",
    "elev": "66",
    "icao": "EGPK",
    "direct_flights": "40",
    "carriers": "9"
  },
  {
    "code": "PIN",
    "lat": "-2.6658",
    "lon": "-56.7822",
    "name": "Parintins Airport",
    "city": "Parintins",
    "state": "Amazonas",
    "country": "Brazil",
    "woeid": "12511260",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5249",
    "elev": "75",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PIP",
    "lat": "57.5631",
    "lon": "-157.558",
    "name": "Pilot Point Airport",
    "city": "Pilot Point",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523201",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3470",
    "elev": "75",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PIR",
    "lat": "44.3801",
    "lon": "-100.293",
    "name": "Pierre Municipal Airport",
    "city": "Pierre",
    "state": "South Dakota",
    "country": "United States",
    "woeid": "12521375",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6891",
    "elev": "1742",
    "icao": "KPIR",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "PIS",
    "lat": "46.5859",
    "lon": "0.31135",
    "name": "Biard Airport",
    "city": "Vouneuil-sous-Biard",
    "state": "Poitou-Charentes",
    "country": "France",
    "woeid": "12512855",
    "tz": "Europe\/Paris",
    "phone": "+33 (0)5 49 30 04 40",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.poitiers.cci.fr\/aeroport",
    "runway_length": "7710",
    "elev": "420",
    "icao": "LFBI",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "PIT",
    "lat": "40.4914",
    "lon": "-80.2328",
    "name": "Pittsburgh International Airport",
    "city": "Coraopolis",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12519983",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1204",
    "icao": "KPIT",
    "direct_flights": "45",
    "carriers": "50"
  },
  {
    "code": "PIU",
    "lat": "-5.2022",
    "lon": "-80.6142",
    "name": "Capitan Concha Airport",
    "city": "Piura",
    "state": "Piura",
    "country": "Peru",
    "woeid": "12515194",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6550",
    "elev": "174",
    "icao": "SPUR",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PIW",
    "lat": "55.5833",
    "lon": "-97.15",
    "name": "Pikwitonei Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524048",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "630",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PIX",
    "lat": "38.5539",
    "lon": "-28.4442",
    "name": "Pico Airport",
    "city": "Madalena",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515452",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "110",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PIZ",
    "lat": "69.7333",
    "lon": "-163.017",
    "name": "Dew Station",
    "city": "Point Lay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2474589",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3519",
    "elev": "20",
    "icao": "PPIZ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PJA",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Pajala",
    "state": "",
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PJG",
    "lat": "26.9547",
    "lon": "64.1331",
    "name": "Panjgur Airport",
    "city": "Panjgur",
    "state": "Balochistan",
    "country": "Pakistan",
    "woeid": "12515256",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "3100",
    "icao": "OPPG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PJM",
    "lat": "8.53994",
    "lon": "-83.3227",
    "name": "Puerto Jimenez Airport",
    "city": "Puerto Jim\u00e9nez",
    "state": "Puntarenas",
    "country": "Costa Rica",
    "woeid": "12524317",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "7",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "PKA",
    "lat": "60.7049",
    "lon": "-161.766",
    "name": "Alaska",
    "city": "Napaskiak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2411",
    "elev": "24",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PKB",
    "lat": "39.3462",
    "lon": "-81.4345",
    "name": "Wood County Airport-Gill Robb Wilson Field",
    "city": "Williamstown",
    "state": "West Virginia",
    "country": "United States",
    "woeid": "12522506",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6781",
    "elev": "858",
    "icao": "KPKB",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PKC",
    "lat": "53.1663",
    "lon": "158.447",
    "name": "Petropavlovsk Yelizovo Airport",
    "city": "Elizovo",
    "state": "Kamchatskaya Oblast",
    "country": "Russia",
    "woeid": "12516681",
    "tz": "Asia\/Kamchatka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11155",
    "elev": "131",
    "icao": "UHPP",
    "direct_flights": "9",
    "carriers": "9"
  },
  {
    "code": "PKE",
    "lat": "-33.1358",
    "lon": "148.235",
    "name": "Parkes Airport",
    "city": "Parkes",
    "state": "Australian Capital Territory",
    "country": "Australia",
    "woeid": "12510758",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5525",
    "elev": "1069",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PKG",
    "lat": "4.21667",
    "lon": "100.55",
    "name": "Pangkor Airport",
    "city": "Pangkor",
    "state": "Perak",
    "country": "Malaysia",
    "woeid": "12523322",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PKK",
    "lat": "21.3333",
    "lon": "95.1",
    "name": "Pakokku",
    "city": "Pakokku",
    "state": "Magway",
    "country": "Myanmar",
    "woeid": "1017758",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4019",
    "elev": "150",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PKP",
    "lat": "-8.64687",
    "lon": "-131.787",
    "name": "French Polynesia",
    "city": "Puka Puka",
    "state": "",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2952",
    "elev": "6",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PKR",
    "lat": "28.2",
    "lon": "83.9833",
    "name": "Pokhara Airport",
    "city": "Pokhara",
    "state": "West",
    "country": "Nepal",
    "woeid": "12523202",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4809",
    "elev": "2713",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "PKU",
    "lat": "0.4642",
    "lon": "101.439",
    "name": "Simpang Tiga Airport",
    "city": "Pekanbaru",
    "state": "Riau",
    "country": "Indonesia",
    "woeid": "12513507",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "102",
    "icao": "WIBB",
    "direct_flights": "6",
    "carriers": "8"
  },
  {
    "code": "PKY",
    "lat": "-2.2228",
    "lon": "113.938",
    "name": "Tjilik Riwut Airport",
    "city": "Buntok",
    "state": "Kalimantan Tangah",
    "country": "Indonesia",
    "woeid": "12513520",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5052",
    "elev": "82",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "PKZ",
    "lat": "15.1303",
    "lon": "105.785",
    "name": "Pakse Airport",
    "city": "Pakxe",
    "state": "Champasak",
    "country": "Laos",
    "woeid": "12514552",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5330",
    "elev": "330",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "PLD",
    "lat": "9.88107",
    "lon": "-85.5507",
    "name": "Playa Samara Airport",
    "city": "Nicoya",
    "state": "Guanacaste",
    "country": "Costa Rica",
    "woeid": "12524318",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KPLD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PLH",
    "lat": "50.423",
    "lon": "-4.11154",
    "name": "Roborough Airport",
    "city": "Plymouth",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22483569",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2490",
    "elev": "488",
    "icao": "EGHD",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "PLJ",
    "lat": "17.1929",
    "lon": "-88.6528",
    "name": "Belize",
    "city": "Placencia",
    "state": "",
    "country": "Belize",
    "woeid": "23424760",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "PLM",
    "lat": "-2.9003",
    "lon": "104.698",
    "name": "Sultan Mahmud Badaruddin Ii Airport",
    "city": "Palembang",
    "state": "Sumatera Selatan",
    "country": "Indonesia",
    "woeid": "12513509",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7216",
    "elev": "37",
    "icao": "WIPP",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "PLN",
    "lat": "45.571",
    "lon": "-84.7857",
    "name": "Pellston Regional Airport",
    "city": "Pellston",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12521327",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6512",
    "elev": "720",
    "icao": "KPLN",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "PLO",
    "lat": "-34.6041",
    "lon": "135.879",
    "name": "Port Lincoln Airport",
    "city": "Port Lincoln",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "12510765",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5020",
    "elev": "36",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PLQ",
    "lat": "55.9661",
    "lon": "21.0825",
    "name": "Palanga International",
    "city": "Klaipeda\/Palanga",
    "state": "Klaipedos Apskritis",
    "country": "Lithuania",
    "woeid": "477139",
    "tz": "Europe\/Vilnius",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "EYPA",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "PLS",
    "lat": "21.7728",
    "lon": "-72.2683",
    "name": "Providenciales Airport",
    "city": "The Bight Settlements",
    "state": "Providencales and West Caicos",
    "country": "Turks And Caicos Islands",
    "woeid": "12517843",
    "tz": "America\/Grand_Turk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7600",
    "elev": "25",
    "icao": "MBPV",
    "direct_flights": "20",
    "carriers": "11"
  },
  {
    "code": "PLU",
    "lat": "-19.8517",
    "lon": "-43.9508",
    "name": "Pampulha Airport",
    "city": "Belo Horizonte",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511257",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8327",
    "elev": "2587",
    "icao": "SBBH",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "PLW",
    "lat": "-0.917694",
    "lon": "119.909",
    "name": "Mutiara Airport",
    "city": "Palu",
    "state": "Sulawesi Tengah",
    "country": "Indonesia",
    "woeid": "12513487",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5331",
    "elev": "282",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "PLX",
    "lat": "50.3517",
    "lon": "80.2336",
    "name": "",
    "city": "Semipalatinsk",
    "state": "Shyghys Qazaqstan",
    "country": "Kazakhstan",
    "woeid": "2264844",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PLZ",
    "lat": "-33.9861",
    "lon": "25.6139",
    "name": "H F Verwoerd Airport",
    "city": "Port Elizabeth",
    "state": "Eastern Cape",
    "country": "South Africa",
    "woeid": "12517413",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6529",
    "elev": "225",
    "icao": "FAPE",
    "direct_flights": "6",
    "carriers": "12"
  },
  {
    "code": "PMA",
    "lat": "-5.2539",
    "lon": "39.8125",
    "name": "Pemba Airport",
    "city": "Pemba",
    "state": "Pemba South",
    "country": "Tanzania",
    "woeid": "12518021",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "80",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PMC",
    "lat": "-41.4319",
    "lon": "-73.0928",
    "name": "El Tepual International Airport",
    "city": "Los Quemas",
    "state": "Los Lagos",
    "country": "Chile",
    "woeid": "12512322",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8694",
    "elev": "296",
    "icao": "SCTE",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "PMD",
    "lat": "34.6294",
    "lon": "-118.084",
    "name": "Air Force Plant Nr 42 Palmdale",
    "city": "Palmdale",
    "state": "California",
    "country": "United States",
    "woeid": "12521290",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "12002",
    "elev": "2542",
    "icao": "KPMD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PME",
    "lat": "50.8303",
    "lon": "-1.05586",
    "name": "Portsmouth Airport",
    "city": "Portsmouth",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12523999",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3481",
    "elev": "12",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PMF",
    "lat": "44.8232",
    "lon": "10.2949",
    "name": "Parma Airport",
    "city": "Parma",
    "state": "Emilia Romagna",
    "country": "Italy",
    "woeid": "12513845",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroportoparma.it\/",
    "runway_length": "7546",
    "elev": "164",
    "icao": "LIMP",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "PMI",
    "lat": "39.5495",
    "lon": "2.73188",
    "name": "Palma de Mallorca Airport",
    "city": "Palma",
    "state": "Balearic Islands",
    "country": "Spain",
    "woeid": "23281165",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10728",
    "elev": "15",
    "icao": "LEPA",
    "direct_flights": "109",
    "carriers": "57"
  },
  {
    "code": "PML",
    "lat": "55.9833",
    "lon": "-160.533",
    "name": "",
    "city": "Port Moller",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2475435",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "27",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PMO",
    "lat": "38.1822",
    "lon": "13.1031",
    "name": "Palermo Airport",
    "city": "Cinisi",
    "state": "Sicily",
    "country": "Italy",
    "woeid": "12513842",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.gesap.it",
    "runway_length": "9842",
    "elev": "65",
    "icao": "LICJ",
    "direct_flights": "38",
    "carriers": "36"
  },
  {
    "code": "PMR",
    "lat": "-40.3227",
    "lon": "175.611",
    "name": "Palmerston North Airport",
    "city": "Palmerston North",
    "state": "Manawatu Wanganui",
    "country": "New Zealand",
    "woeid": "12515164",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.pnairport.co.nz",
    "runway_length": "1",
    "elev": "148",
    "icao": "NZPM",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "PMV",
    "lat": "10.9167",
    "lon": "-63.98",
    "name": "Del Caribe International Airport",
    "city": "Pampatar",
    "state": "Nueva Esparta",
    "country": "Venezuela",
    "woeid": "12522772",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10433",
    "elev": "89",
    "icao": "KPMV",
    "direct_flights": "8",
    "carriers": "7"
  },
  {
    "code": "PMW",
    "lat": "-10.2417",
    "lon": "-48.3528",
    "name": "Palmas Airport",
    "city": "Palmas",
    "state": "Tocantins",
    "country": "Brazil",
    "woeid": "12523650",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "788",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "PMY",
    "lat": "-42.7597",
    "lon": "-65.11",
    "name": "El Tehuelche Airport",
    "city": "Puerto Madryn",
    "state": "Chubut",
    "country": "Argentina",
    "woeid": "12510492",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "446",
    "icao": "SAVY",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PMZ",
    "lat": "8.9531",
    "lon": "-83.4703",
    "name": "Palmar Sur Airport",
    "city": "Palmar Sur",
    "state": "Puntarenas",
    "country": "Costa Rica",
    "woeid": "12512438",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3183",
    "elev": "49",
    "icao": "KPMZ",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PNA",
    "lat": "42.7672",
    "lon": "-1.64017",
    "name": "Pamplona Airport",
    "city": "No\u00e1in",
    "state": "Navarre",
    "country": "Spain",
    "woeid": "12517562",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7350",
    "elev": "1506",
    "icao": "KPNA",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "PND",
    "lat": "16.0977",
    "lon": "-88.8303",
    "name": "Punta Gorda Airport",
    "city": "Punta Gorda",
    "state": "Toledo",
    "country": "Belize",
    "woeid": "12524035",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2430",
    "elev": "60",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PNH",
    "lat": "11.5458",
    "lon": "104.849",
    "name": "Pochentong Airport",
    "city": "Phnom Penh",
    "state": "Phnum Penh",
    "country": "Cambodia",
    "woeid": "12511928",
    "tz": "Asia\/Phnom_Penh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cambodia-airports.com",
    "runway_length": "9842",
    "elev": "39",
    "icao": "VDPP",
    "direct_flights": "12",
    "carriers": "22"
  },
  {
    "code": "PNI",
    "lat": "6.9847",
    "lon": "158.215",
    "name": "Pohnpei International Airport",
    "city": "Palikir",
    "state": "Pohnpei",
    "country": "Federated States of Micronesia",
    "woeid": "12512816",
    "tz": "Pacific\/Ponape",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PNK",
    "lat": "-0.1481",
    "lon": "109.403",
    "name": "Supadio Airport",
    "city": "Pontianak",
    "state": "Kalimantan Barat",
    "country": "Indonesia",
    "woeid": "12513512",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5430",
    "elev": "10",
    "icao": "WIOO",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "PNL",
    "lat": "36.8185",
    "lon": "11.963",
    "name": "Pantelleria Airport",
    "city": "Pantelleria",
    "state": "Sicily",
    "country": "Italy",
    "woeid": "12513844",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.pantelleriairport.it\/",
    "runway_length": "4045",
    "elev": "632",
    "icao": "",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "PNP",
    "lat": "-8.8056",
    "lon": "148.309",
    "name": "Girua Airport",
    "city": "Popondetta",
    "state": "Northern",
    "country": "Papua New Guinea",
    "woeid": "12515465",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3400",
    "elev": "400",
    "icao": "PAPN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PNQ",
    "lat": "18.5817",
    "lon": "73.9206",
    "name": "Pune Airport",
    "city": "Pune",
    "state": "Maharashtra",
    "country": "India",
    "woeid": "12513655",
    "tz": "Asia\/Kolkata",
    "phone": "91-020- 26691495",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aai.aero\/aai\/main.htm",
    "runway_length": "8700",
    "elev": "1934",
    "icao": "",
    "direct_flights": "11",
    "carriers": "8"
  },
  {
    "code": "PNR",
    "lat": "-4.8128",
    "lon": "11.8856",
    "name": "Pointe Noire Airport",
    "city": "Pointe-Noire",
    "state": "Kouilou",
    "country": "Congo",
    "woeid": "12511949",
    "tz": "Africa\/Brazzaville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "56",
    "icao": "FCPP",
    "direct_flights": "9",
    "carriers": "12"
  },
  {
    "code": "PNS",
    "lat": "30.4761",
    "lon": "-87.1941",
    "name": "Pensacola Regional Airport",
    "city": "Pensacola",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521335",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7002",
    "elev": "121",
    "icao": "KPNS",
    "direct_flights": "11",
    "carriers": "15"
  },
  {
    "code": "PNZ",
    "lat": "-9.3647",
    "lon": "-40.5658",
    "name": "Senador Nilo Coelho Airport",
    "city": "Petrolina",
    "state": "Pernambuco",
    "country": "Brazil",
    "woeid": "12511269",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "1234",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "POA",
    "lat": "-29.9933",
    "lon": "-51.1708",
    "name": "Salgado Filho International Airport",
    "city": "Porto Alegre",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511298",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7480",
    "elev": "10",
    "icao": "SBPA",
    "direct_flights": "19",
    "carriers": "15"
  },
  {
    "code": "POG",
    "lat": "-0.7197",
    "lon": "8.7514",
    "name": "Port Gentil Airport",
    "city": "Port-Gentil",
    "state": "Ogooue-Martime",
    "country": "Gabon",
    "woeid": "12513000",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "13",
    "icao": "FOOG",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "POL",
    "lat": "-12.9883",
    "lon": "40.5225",
    "name": "Pemba Airport",
    "city": "Pemba",
    "state": "Cabo Delgado",
    "country": "Mozambique",
    "woeid": "12515028",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "338",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "POM",
    "lat": "-9.4425",
    "lon": "147.219",
    "name": "Port Moresby International Airport",
    "city": "Port Moresby",
    "state": "National Capital",
    "country": "Papua New Guinea",
    "woeid": "12515477",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9022",
    "elev": "148",
    "icao": "AYPY",
    "direct_flights": "33",
    "carriers": "3"
  },
  {
    "code": "POP",
    "lat": "19.7575",
    "lon": "-70.5692",
    "name": "Puerto Plata International Airport",
    "city": "San Felipe de Puerto Plata",
    "state": "Puerto Plata",
    "country": "Dominican Republic",
    "woeid": "12512619",
    "tz": "America\/Santo_Domingo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10105",
    "elev": "16",
    "icao": "MDPP",
    "direct_flights": "16",
    "carriers": "17"
  },
  {
    "code": "POR",
    "lat": "61.462",
    "lon": "21.7988",
    "name": "Pori Airport",
    "city": "Pori",
    "state": "Western Finland",
    "country": "Finland",
    "woeid": "12523800",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "44",
    "icao": "EFPO",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "POS",
    "lat": "10.5936",
    "lon": "-61.3386",
    "name": "Piarco Airport",
    "city": "Trinidad",
    "state": "Port of Spain",
    "country": "Trinidad and Tobago",
    "woeid": "12517744",
    "tz": "America\/Port_of_Spain",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.TrinidadAirport.com",
    "runway_length": "10508",
    "elev": "57",
    "icao": "TTPP",
    "direct_flights": "21",
    "carriers": "16"
  },
  {
    "code": "POZ",
    "lat": "52.4284",
    "lon": "16.8208",
    "name": "Lawica Airport",
    "city": "Poznan",
    "state": "Wielkopolskie",
    "country": "Poland",
    "woeid": "12515333",
    "tz": "Europe\/Warsaw",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "308",
    "icao": "EPPO",
    "direct_flights": "26",
    "carriers": "10"
  },
  {
    "code": "PPB",
    "lat": "-22.1778",
    "lon": "-51.4208",
    "name": "Presidente Prudente Airport",
    "city": "Presidente Prudente",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511289",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6900",
    "elev": "1477",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "PPE",
    "lat": "31.35",
    "lon": "-113.526",
    "name": "Punta Penasco Airport",
    "city": "Pto. Penasco",
    "state": "Sonora",
    "country": "Mexico",
    "woeid": "12514942",
    "tz": "America\/Hermosillo",
    "phone": "(638)383-6097",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "46",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PPG",
    "lat": "-14.3242",
    "lon": "-170.706",
    "name": "Pago Pago International Airport",
    "city": "Pago Pago",
    "state": "American Samoa",
    "country": "United States",
    "woeid": "12523054",
    "tz": "Pacific\/Pago_Pago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "30",
    "icao": "NSTU",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PPK",
    "lat": "54.7833",
    "lon": "69.1833",
    "name": "Petropavlovsk",
    "city": "Petropavlovsk",
    "state": "Soltustik Qazaqstan",
    "country": "Kazakhstan",
    "woeid": "20070174",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PPL",
    "lat": "28.3948",
    "lon": "84.1278",
    "name": "Nepal",
    "city": "Phaplu",
    "state": "",
    "country": "Nepal",
    "woeid": "23424911",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "2378",
    "elev": "9000",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PPN",
    "lat": "2.45",
    "lon": "-76.6164",
    "name": "Guillermo Leon Valencia Airport",
    "city": "Popay\u00e1n",
    "state": "Cauca",
    "country": "Colombia",
    "woeid": "12512381",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6100",
    "elev": "5677",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PPP",
    "lat": "-20.4939",
    "lon": "148.554",
    "name": "Proserpine Aerodrome",
    "city": "Brandy Creek",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510768",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6801",
    "elev": "83",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PPS",
    "lat": "9.7433",
    "lon": "118.756",
    "name": "Puerto Princesa International Airport",
    "city": "Puerto Princesa",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "12515642",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "20",
    "icao": "RPVP",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "PPT",
    "lat": "-17.5578",
    "lon": "-149.611",
    "name": "Tahiti Faaa Airport",
    "city": "Papeete",
    "state": "Windward Islands",
    "country": "French Polynesia",
    "woeid": "12512832",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11204",
    "elev": "7",
    "icao": "NTAA",
    "direct_flights": "38",
    "carriers": "12"
  },
  {
    "code": "PPV",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Port Protection",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PQC",
    "lat": "10.2233",
    "lon": "103.958",
    "name": "Duong Dong Airport",
    "city": "Kien Giang",
    "state": "Kien Giang",
    "country": "Vietnam",
    "woeid": "12522907",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "23",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PQI",
    "lat": "46.6921",
    "lon": "-68.0447",
    "name": "Northern Maine Regional Airport",
    "city": "Presque Isle",
    "state": "Maine",
    "country": "United States",
    "woeid": "12521164",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7440",
    "elev": "534",
    "icao": "KPQI",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PQQ",
    "lat": "-31.4309",
    "lon": "152.866",
    "name": "Port Macquarie Airport",
    "city": "Port Macquarie",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510766",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "12",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "PQS",
    "lat": "61.9362",
    "lon": "-162.882",
    "name": "Pilot Station",
    "city": "Pilot Station",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2471844",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2982",
    "elev": "275",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "PRC",
    "lat": "34.6492",
    "lon": "-112.428",
    "name": "Ernest A Love Field Airport",
    "city": "Prescott",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12519656",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7616",
    "elev": "5042",
    "icao": "KPRC",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "PRG",
    "lat": "50.1079",
    "lon": "14.2675",
    "name": "Prague Ruzyne Airport",
    "city": "Prague 6",
    "state": "Hlavni mesto Praha",
    "country": "Czech Republic",
    "woeid": "12512558",
    "tz": "Europe\/Prague",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.prg.aero",
    "runway_length": "10663",
    "elev": "1247",
    "icao": "KPRG",
    "direct_flights": "131",
    "carriers": "66"
  },
  {
    "code": "PRI",
    "lat": "-4.3158",
    "lon": "55.6958",
    "name": "Praslin Airport",
    "city": "Praslin Island",
    "state": "Grand Anse Praslin",
    "country": "Seychelles",
    "woeid": "12517388",
    "tz": "Indian\/Mahe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2493",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PRN",
    "lat": "42.5753",
    "lon": "21.0364",
    "name": "Pristina Airport",
    "city": "Prishtina",
    "state": "Kosovo",
    "country": "Serbia",
    "woeid": "12517589",
    "tz": "Europe\/Belgrade",
    "phone": "+381 0 38 5958159",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportpristina.com\/",
    "runway_length": "8202",
    "elev": "1788",
    "icao": "KPRN",
    "direct_flights": "16",
    "carriers": "14"
  },
  {
    "code": "PSA",
    "lat": "43.6959",
    "lon": "10.3976",
    "name": "Pisa Airport",
    "city": "Pisa",
    "state": "Tuscany",
    "country": "Italy",
    "woeid": "12513850",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.pisa-airport.com\/",
    "runway_length": "9800",
    "elev": "9",
    "icao": "LIRP",
    "direct_flights": "57",
    "carriers": "26"
  },
  {
    "code": "PSC",
    "lat": "46.2592",
    "lon": "-119.117",
    "name": "Tri Cities Airport",
    "city": "Pasco",
    "state": "Washington",
    "country": "United States",
    "woeid": "12522188",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7700",
    "elev": "407",
    "icao": "KPSC",
    "direct_flights": "7",
    "carriers": "12"
  },
  {
    "code": "PSE",
    "lat": "18.0116",
    "lon": "-66.5664",
    "name": "Mercedita Airport",
    "city": "Coto Laurel",
    "state": "Puerto Rico",
    "country": "United States",
    "woeid": "12515663",
    "tz": "America\/Puerto_Rico",
    "phone": "787 557 7753",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5529",
    "elev": "27",
    "icao": "TJPS",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "PSG",
    "lat": "56.8067",
    "lon": "-132.933",
    "name": "Petersburg James A Johnson Airport",
    "city": "Petersburg",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12521355",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6004",
    "elev": "107",
    "icao": "PAPG",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PSO",
    "lat": "1.4108",
    "lon": "-77.2922",
    "name": "Antonio Narino Airport",
    "city": "Chachag\u00fc\u00ed",
    "state": "Narino",
    "country": "Colombia",
    "woeid": "12512363",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7132",
    "elev": "5952",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PSP",
    "lat": "33.8227",
    "lon": "-116.509",
    "name": "Palm Springs International Airport",
    "city": "Palm Springs",
    "state": "California",
    "country": "United States",
    "woeid": "12521289",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8500",
    "elev": "462",
    "icao": "KPSP",
    "direct_flights": "17",
    "carriers": "16"
  },
  {
    "code": "PSR",
    "lat": "42.4337",
    "lon": "14.1846",
    "name": "Pescara Airport",
    "city": "Pescara",
    "state": "Abruzzi",
    "country": "Italy",
    "woeid": "12513848",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7808",
    "elev": "48",
    "icao": "LIBP",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "PSS",
    "lat": "-27.3839",
    "lon": "-55.9675",
    "name": "Posadas Airport",
    "city": "Posadas",
    "state": "Misiones",
    "country": "Argentina",
    "woeid": "12510533",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7394",
    "elev": "430",
    "icao": "SARP",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "PSZ",
    "lat": "-18.9747",
    "lon": "-57.8194",
    "name": "Salvador Ogaya Gutierrez Airport",
    "city": "Puerto Su\u00e1rez",
    "state": "Santa Cruz",
    "country": "Bolivia",
    "woeid": "12510910",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "440",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PTA",
    "lat": "60.3956",
    "lon": "-154.494",
    "name": "Port Alsworth",
    "city": "Port Alsworth",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2475311",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "280",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PTF",
    "lat": "-17.7818",
    "lon": "177.2",
    "name": "Malololailai Airport",
    "city": "Malololailai",
    "state": "Western",
    "country": "Fiji",
    "woeid": "12523205",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2100",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PTG",
    "lat": "-23.9236",
    "lon": "29.4833",
    "name": "Pietersburg Municipal Airport",
    "city": "Pietersburg",
    "state": "Limpopo",
    "country": "South Africa",
    "woeid": "12517461",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10400",
    "elev": "4075",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PTH",
    "lat": "56.9667",
    "lon": "-158.633",
    "name": "Port Heiden Airport",
    "city": "Port Heiden",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2475388",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6240",
    "elev": "86",
    "icao": "PAPH",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PTP",
    "lat": "16.2675",
    "lon": "-61.5281",
    "name": "Le Raizet Airport",
    "city": "Les Abymes",
    "state": "Pointe-\u00c0-Pitre",
    "country": "Guadeloupe",
    "woeid": "12513277",
    "tz": "America\/Guadeloupe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11499",
    "elev": "36",
    "icao": "TFFR",
    "direct_flights": "20",
    "carriers": "9"
  },
  {
    "code": "PTU",
    "lat": "58.9216",
    "lon": "-161.727",
    "name": "Platinum",
    "city": "Platinum",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2473568",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3800",
    "elev": "9",
    "icao": "PAPM",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "PTY",
    "lat": "9.07",
    "lon": "-79.3836",
    "name": "Tocumen International Airport",
    "city": "Tocumen",
    "state": "Panama",
    "country": "Panama",
    "woeid": "12515432",
    "tz": "America\/Panama",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.tocumenpanama.aero\/",
    "runway_length": "10006",
    "elev": "135",
    "icao": "MPTO",
    "direct_flights": "53",
    "carriers": "23"
  },
  {
    "code": "PUB",
    "lat": "38.2836",
    "lon": "-104.496",
    "name": "Pueblo Memorial Airport",
    "city": "Pueblo",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12521494",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10496",
    "elev": "4726",
    "icao": "KPUB",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PUF",
    "lat": "43.3823",
    "lon": "-0.4166",
    "name": "Pont Long Uzein Airport",
    "city": "Lescar",
    "state": "Aquitaine",
    "country": "France",
    "woeid": "12512953",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "617",
    "icao": "LFBP",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "PUJ",
    "lat": "18.5675",
    "lon": "-68.3469",
    "name": "Punta Cana Airport",
    "city": "Salvale\u00f3n de Hig\u00fcey",
    "state": "La Altagracia",
    "country": "Dominican Republic",
    "woeid": "12512620",
    "tz": "America\/Santo_Domingo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5043",
    "elev": "30",
    "icao": "MDPC",
    "direct_flights": "40",
    "carriers": "27"
  },
  {
    "code": "PUK",
    "lat": "21.0754",
    "lon": "-156.793",
    "name": "",
    "city": "Pukarua",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2952",
    "elev": "3",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PUQ",
    "lat": "-53.0033",
    "lon": "-70.8558",
    "name": "Carlos Ibanez de Campo International Airport",
    "city": "Punta Arenas",
    "state": "Magallanes y Antartica Chilena",
    "country": "Chile",
    "woeid": "12512310",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9153",
    "elev": "144",
    "icao": "SCCI",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "PUS",
    "lat": "35.1777",
    "lon": "128.937",
    "name": "Kimhae International Airport",
    "city": "Busan",
    "state": "Busan",
    "country": "South Korea",
    "woeid": "23388327",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "13",
    "icao": "RKPP",
    "direct_flights": "31",
    "carriers": "26"
  },
  {
    "code": "PUU",
    "lat": "0.5061",
    "lon": "-76.5022",
    "name": "Puerto Asis Airport",
    "city": "Puerto As\u00eds",
    "state": "Putumayo",
    "country": "Colombia",
    "woeid": "12512405",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5248",
    "elev": "834",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "PUW",
    "lat": "46.743",
    "lon": "-117.119",
    "name": "Pullman-Moscow Regional Airport",
    "city": "Pullman",
    "state": "Washington",
    "country": "United States",
    "woeid": "12521496",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6731",
    "elev": "2551",
    "icao": "KPUW",
    "direct_flights": "3",
    "carriers": "5"
  },
  {
    "code": "PUY",
    "lat": "44.8939",
    "lon": "13.9272",
    "name": "Pula Airport",
    "city": "Pluj",
    "state": "Istarska",
    "country": "Croatia",
    "woeid": "12513369",
    "tz": "Europe\/Belgrade",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9678",
    "elev": "276",
    "icao": "LDPL",
    "direct_flights": "13",
    "carriers": "8"
  },
  {
    "code": "PVA",
    "lat": "13.3577",
    "lon": "-81.3535",
    "name": "Providencia Island Airport",
    "city": "San Andr\u00e9s",
    "state": "San Andres y Providencia",
    "country": "Colombia",
    "woeid": "12512404",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PVC",
    "lat": "42.0771",
    "lon": "-70.2169",
    "name": "Provincetown Municipal Airport",
    "city": "Provincetown",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12521490",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3498",
    "elev": "8",
    "icao": "KPVC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PVD",
    "lat": "41.723",
    "lon": "-71.4399",
    "name": "Theodore Francis Green State Airport",
    "city": "Warwick",
    "state": "Rhode Island",
    "country": "United States",
    "woeid": "12522119",
    "tz": "America\/New_York",
    "phone": "(401) 737-8222",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.pvdairport.com\/",
    "runway_length": "7166",
    "elev": "56",
    "icao": "KPVD",
    "direct_flights": "25",
    "carriers": "24"
  },
  {
    "code": "PVG",
    "lat": "31.1156",
    "lon": "121.803",
    "name": "Pudong International Airport",
    "city": "Huinan",
    "state": "Shanghai",
    "country": "China",
    "woeid": "23428057",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KPVG",
    "direct_flights": "131",
    "carriers": "76"
  },
  {
    "code": "PVH",
    "lat": "-8.7133",
    "lon": "-63.9022",
    "name": "Governador Jorge Teixeira de Oliveira Internatio",
    "city": "P\u00f4rto Velho",
    "state": "Rondonia",
    "country": "Brazil",
    "woeid": "12511285",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "289",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "PVK",
    "lat": "38.9261",
    "lon": "20.7667",
    "name": "Preveza Airport",
    "city": "Paliambela",
    "state": "Dytiki Ellada",
    "country": "Greece",
    "woeid": "12513319",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9810",
    "elev": "13",
    "icao": "LGPZ",
    "direct_flights": "10",
    "carriers": "4"
  },
  {
    "code": "PVR",
    "lat": "20.6819",
    "lon": "-105.248",
    "name": "Lic Gustavo Diaz Ordaz International Airport",
    "city": "Puerto Vallarta",
    "state": "Jalisco",
    "country": "Mexico",
    "woeid": "12514911",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10171",
    "elev": "19",
    "icao": "MMPR",
    "direct_flights": "26",
    "carriers": "22"
  },
  {
    "code": "PWE",
    "lat": "69.7862",
    "lon": "170.642",
    "name": "Under Construction Pevek Airport",
    "city": "Anadyr'",
    "state": "Chukotskiy Avtonomnyy Okrug",
    "country": "Russia",
    "woeid": "12517084",
    "tz": "Asia\/Kamchatka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PWK",
    "lat": "42.1162",
    "lon": "-87.8987",
    "name": "Pal Waukee Airport",
    "city": "Wheeling",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12521282",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "647",
    "icao": "KPWK",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PWM",
    "lat": "43.6475",
    "lon": "-70.3103",
    "name": "Jetport International Airport",
    "city": "Portland",
    "state": "Maine",
    "country": "United States",
    "woeid": "12520368",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6800",
    "elev": "74",
    "icao": "KPWM",
    "direct_flights": "17",
    "carriers": "18"
  },
  {
    "code": "PWQ",
    "lat": "52.1859",
    "lon": "77.0858",
    "name": "Pavlodar South Airport",
    "city": "Pavlodar",
    "state": "Pavlodar",
    "country": "Kazakhstan",
    "woeid": "12514433",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "PXM",
    "lat": "15.8725",
    "lon": "-97.0861",
    "name": "Puerto Escondido Airport",
    "city": "San Pedro Juchatengo",
    "state": "Oaxaca",
    "country": "Mexico",
    "woeid": "12514938",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7544",
    "elev": "289",
    "icao": "MMPS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PXO",
    "lat": "33.0667",
    "lon": "-16.35",
    "name": "Porto Santo Airport",
    "city": "Porto Santo",
    "state": "Madeira",
    "country": "Portugal",
    "woeid": "12523962",
    "tz": "Europe\/Lisbon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8005",
    "elev": "318",
    "icao": "LPPS",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "PXU",
    "lat": "13.9747",
    "lon": "108.041",
    "name": "Pleiku Area Airport",
    "city": "Gia Lai",
    "state": "Gia Lai",
    "country": "Vietnam",
    "woeid": "12522947",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "2438",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "PYH",
    "lat": "5.5833",
    "lon": "-67.6167",
    "name": "Puerto Ayacucho Airport",
    "city": "Maroa",
    "state": "Amazonas",
    "country": "Venezuela",
    "woeid": "12522832",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6726",
    "elev": "105",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PYJ",
    "lat": "69.1966",
    "lon": "33.4842",
    "name": "Russia",
    "city": "Polyarnyj",
    "state": "Murmanskaya Oblast",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "PZB",
    "lat": "-29.6514",
    "lon": "30.3994",
    "name": "Pietermaritzburg Airport",
    "city": "Pietermaritzburg",
    "state": "Kwazulu Natal",
    "country": "South Africa",
    "woeid": "12517459",
    "tz": "Africa\/Johannesburg",
    "phone": "033 386 5252",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "2425",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "PZE",
    "lat": "50.1282",
    "lon": "-5.51945",
    "name": "Penzance Heliport",
    "city": "Penzance",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22482999",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "PZI",
    "lat": "21.652",
    "lon": "110.623",
    "name": "Pan Zhi Hua Bao AnYing",
    "city": "Pan Zhi Hua",
    "state": "Guangdong",
    "country": "China",
    "woeid": "2161880",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "PZO",
    "lat": "8.3",
    "lon": "-62.7333",
    "name": "Puerto Ordaz Airport",
    "city": "Ciudad Guayana",
    "state": "Bolivar",
    "country": "Venezuela",
    "woeid": "12523683",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "472",
    "icao": "SVPR",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "PZU",
    "lat": "19.5761",
    "lon": "37.2167",
    "name": "Port Sudan International Airport",
    "city": "Port Sudan",
    "state": "Al Bahr al Ahmar",
    "country": "Sudan",
    "woeid": "12517603",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "10",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "QBC",
    "lat": "52.3843",
    "lon": "-126.585",
    "name": "Bella Coola Airport",
    "city": "Masset",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511577",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "120",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "QDH",
    "lat": "51.08",
    "lon": "0.53",
    "name": "Ashford International Rail Station",
    "city": "Ashford",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "26352342",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "QDU",
    "lat": "51.0908",
    "lon": "10.4542",
    "name": "Germany",
    "city": "Dusseldorf",
    "state": "North-Rhine-Westphalia",
    "country": "Germany",
    "woeid": "23424829",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "QFB",
    "lat": "47.9986",
    "lon": "7.84964",
    "name": "Freiburg Hauptbahnhof",
    "city": "Freiburg",
    "state": "Baden-W\u00fcrttemberg",
    "country": "Germany",
    "woeid": "650437",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "QFZ",
    "lat": "49.2315",
    "lon": "6.99827",
    "name": "Saarbruecken Rail Station",
    "city": "Sarrebruck",
    "state": "Saar",
    "country": "Germany",
    "woeid": "690631",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "QJY",
    "lat": "33.5056",
    "lon": "126.495",
    "name": "Cheju International Airport",
    "city": "Jeju-Si",
    "state": "Jaeju-Do",
    "country": "South Korea",
    "woeid": "12514202",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "QJZ",
    "lat": "46.7124",
    "lon": "1.71832",
    "name": "France",
    "city": "Nantes",
    "state": null,
    "country": "France",
    "woeid": "23424819",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "10"
  },
  {
    "code": "QKL",
    "lat": "50.944",
    "lon": "6.95806",
    "name": "Koeln Hauptbahnhof",
    "city": "Cologne",
    "state": "North-Rhine-Westphalia",
    "country": "Germany",
    "woeid": "20066498",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "7"
  },
  {
    "code": "QOW",
    "lat": "5.49341",
    "lon": "7.0261",
    "name": "Owerri",
    "city": "Owerri",
    "state": "Imo",
    "country": "Nigeria",
    "woeid": "1510193",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "DNIM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "QQD",
    "lat": "51.07",
    "lon": "1.19",
    "name": "Dover Rail Station",
    "city": "Dover",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "26345882",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "QQH",
    "lat": "51.9389",
    "lon": "1.28162",
    "name": "Harwich Rail Station",
    "city": "Harwich",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "26345764",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "QQK",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "QQM",
    "lat": "53.365",
    "lon": "-2.27089",
    "name": "Manchester International Airport",
    "city": "Manchester",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22478032",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "QQN",
    "lat": "52.4531",
    "lon": "-1.73847",
    "name": "Birmingham International Airport",
    "city": "Birmingham",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22454274",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "1"
  },
  {
    "code": "QQP",
    "lat": "51.5158",
    "lon": "-0.17539",
    "name": "Paddington Station",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22475708",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "QQS",
    "lat": "51.5299",
    "lon": "-0.12549",
    "name": "St Pancras International",
    "city": "Britrail Rail Zone S",
    "state": "",
    "country": "United Kingdom",
    "woeid": "22476283",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "QQU",
    "lat": "52.4531",
    "lon": "-1.73847",
    "name": "Birmingham International Airport",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22454274",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "1"
  },
  {
    "code": "QQW",
    "lat": "51.5035",
    "lon": "-0.11297",
    "name": "Waterloo Railway Station",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "23380781",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "QQX",
    "lat": "51.3851",
    "lon": "-2.36116",
    "name": "Bath Rail Service",
    "city": "Bath",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12056",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "QQY",
    "lat": "52.4531",
    "lon": "-1.73847",
    "name": "Birmingham International Airport",
    "city": "York",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22454274",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "12",
    "carriers": "1"
  },
  {
    "code": "QRH",
    "lat": "51.9525",
    "lon": "4.4392",
    "name": "Rotterdam Airport",
    "city": "Rotterdam",
    "state": "",
    "country": "Netherlands",
    "woeid": "23182361",
    "tz": "Europe\/Amsterdam",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "QRO",
    "lat": "20.6228",
    "lon": "-100.369",
    "name": "Queretaro Airport",
    "city": "Queretaro",
    "state": "Queretaro de Arteaga",
    "country": "Mexico",
    "woeid": "12514944",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "6461",
    "icao": "",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "QRW",
    "lat": "5.75242",
    "lon": "5.86487",
    "name": "Delta",
    "city": "Warri",
    "state": "Delta",
    "country": "Nigeria",
    "woeid": "2346364",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "QSF",
    "lat": "36.1914",
    "lon": "5.40944",
    "name": "Setif",
    "city": "Setif",
    "state": "Setif",
    "country": "Algeria",
    "woeid": "1257253",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "QUL",
    "lat": "36.5922",
    "lon": "-90.2465",
    "name": "",
    "city": "Qulin",
    "state": "Missouri",
    "country": "United States",
    "woeid": "2477964",
    "tz": "America\/Indiana\/Tell_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "QWB",
    "lat": "-25.5275",
    "lon": "-49.1731",
    "name": "Afonso Pena International Airport",
    "city": "Sao Jose dos Pinhais",
    "state": "Parana",
    "country": "Brazil",
    "woeid": "12511012",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "QXB",
    "lat": "43.5048",
    "lon": "5.36794",
    "name": "Aix Les Milles Airport",
    "city": "Aix-les-milles",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "12512840",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LFMA",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "QXG",
    "lat": "47.4174",
    "lon": "-0.82135",
    "name": "Pays de la Loire",
    "city": "Angers",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "7153325",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "9"
  },
  {
    "code": "QYU",
    "lat": "61.2661",
    "lon": "15.9858",
    "name": "Gavleborg",
    "city": "Gefle",
    "state": "Gavleborg",
    "country": "Sweden",
    "woeid": "2347046",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "QYX",
    "lat": "59.8585",
    "lon": "17.6441",
    "name": "Uppsala Station",
    "city": "Uppsala",
    "state": "",
    "country": "Sweden",
    "woeid": "908572",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "RAB",
    "lat": "-4.2272",
    "lon": "152.184",
    "name": "Rabaul Airport",
    "city": "Rabaul",
    "state": "East New Britain",
    "country": "Papua New Guinea",
    "woeid": "12515478",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5210",
    "elev": "29",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "RAE",
    "lat": "30.9086",
    "lon": "41.1367",
    "name": "Arar Airport",
    "city": "Arar",
    "state": "Al Hudud ash Shamaliyah",
    "country": "Saudi Arabia",
    "woeid": "12517330",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "1821",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "RAH",
    "lat": "29.6278",
    "lon": "43.4944",
    "name": "Rafha Airport",
    "city": "Rafha",
    "state": "Al Hudud ash Shamaliyah",
    "country": "Saudi Arabia",
    "woeid": "12517363",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "1473",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RAI",
    "lat": "14.9253",
    "lon": "-23.5031",
    "name": "Francisco Mendes Airport",
    "city": "Praia",
    "state": "Praia",
    "country": "Cape Verde",
    "woeid": "12512491",
    "tz": "Atlantic\/Cape_Verde",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5828",
    "elev": "229",
    "icao": "",
    "direct_flights": "13",
    "carriers": "4"
  },
  {
    "code": "RAJ",
    "lat": "22.3169",
    "lon": "70.769",
    "name": "Rajkot Airport",
    "city": "Rajkot",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513661",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5395",
    "elev": "440",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RAK",
    "lat": "31.6167",
    "lon": "-8.05",
    "name": "Menara Airport",
    "city": "Marrakesh",
    "state": "Marrakech",
    "country": "Morocco",
    "woeid": "12523044",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10171",
    "elev": "1535",
    "icao": "GMMX",
    "direct_flights": "30",
    "carriers": "20"
  },
  {
    "code": "RAO",
    "lat": "-21.1322",
    "lon": "-47.7744",
    "name": "Leite Lopes Airport",
    "city": "Ribeir\u00e3o Pr\u00eato",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511210",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "1801",
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "RAP",
    "lat": "44.037",
    "lon": "-103.06",
    "name": "Rapid City Regional Airport",
    "city": "Rapid City",
    "state": "South Dakota",
    "country": "United States",
    "woeid": "12521530",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8701",
    "elev": "3202",
    "icao": "KRAP",
    "direct_flights": "9",
    "carriers": "11"
  },
  {
    "code": "RAR",
    "lat": "-21.2",
    "lon": "-159.8",
    "name": "Rarotonga International Airport",
    "city": "Avarua",
    "state": "Rarotonga",
    "country": "Cook Islands",
    "woeid": "12523208",
    "tz": "Pacific\/Rarotonga",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7638",
    "elev": "22",
    "icao": "NCRG",
    "direct_flights": "9",
    "carriers": "6"
  },
  {
    "code": "RAS",
    "lat": "37.3256",
    "lon": "49.6092",
    "name": "Rasht Airport",
    "city": "Rasht",
    "state": "Gilan",
    "country": "Iran",
    "woeid": "12513752",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7545",
    "elev": null,
    "icao": "KRAS",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "RBA",
    "lat": "34.0475",
    "lon": "-6.7578",
    "name": "Sale Airport",
    "city": "Rabat",
    "state": "Rabat-Sale",
    "country": "Morocco",
    "woeid": "12514791",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "276",
    "icao": "GMME",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "RBH",
    "lat": "64.7381",
    "lon": "-155.489",
    "name": "Brooks Lodge",
    "city": "Brooks Lodge",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RBQ",
    "lat": "-14.45",
    "lon": "-67.55",
    "name": "Rurrenabaque Airport",
    "city": "Rurrenabaque",
    "state": "El Beni",
    "country": "Bolivia",
    "woeid": "12523701",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "899",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RBR",
    "lat": "-9.9981",
    "lon": "-67.7997",
    "name": "Presidente Medici International Airport",
    "city": "Rio Branco",
    "state": "Norte",
    "country": "Brazil",
    "woeid": "12511288",
    "tz": "America\/Cuiaba",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "466",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "RBV",
    "lat": "58.05",
    "lon": "25.1",
    "name": "Ramata",
    "city": "Ramata",
    "state": "Valmieras Rajons",
    "country": "Solomon Islands",
    "woeid": "55844616",
    "tz": "Europe\/Riga",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RBX",
    "lat": "46.4717",
    "lon": "-108.55",
    "name": "Roundup Airport",
    "city": "Roundup",
    "state": "Montana",
    "country": "United States",
    "woeid": "12521664",
    "tz": "America\/Boise",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RBY",
    "lat": "64.7381",
    "lon": "-155.489",
    "name": "Ruby Airport",
    "city": "Ruby",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2485593",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2900",
    "elev": "635",
    "icao": "PARY",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "RCB",
    "lat": "-28.7386",
    "lon": "32.0928",
    "name": "Richards Bay Airport",
    "city": "Empangeni",
    "state": "Kwazulu Natal",
    "country": "South Africa",
    "woeid": "12517470",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "108",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RCE",
    "lat": "48.6126",
    "lon": "-123.14",
    "name": "Roche Harbor Airport",
    "city": "Friday Harbor",
    "state": "Washington",
    "country": "United States",
    "woeid": "12521623",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "100",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RCH",
    "lat": "11.5292",
    "lon": "-72.9244",
    "name": "Almirante Padilla Airport",
    "city": "R\u00edohacha",
    "state": "La Guajira",
    "country": "Colombia",
    "woeid": "12512362",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5413",
    "elev": "48",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RCL",
    "lat": "-15.4802",
    "lon": "167.834",
    "name": "",
    "city": "Redcliffe",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2230",
    "elev": "36",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RCM",
    "lat": "-20.7021",
    "lon": "143.115",
    "name": "Richmond Aerodrome",
    "city": "Bellfield",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510771",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "675",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RCP",
    "lat": "51.5257",
    "lon": "-0.14499",
    "name": "",
    "city": "Cinder River",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RDB",
    "lat": "67.75",
    "lon": "-163.667",
    "name": "Red Dog",
    "city": "Red Dog",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799789",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PADG",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "RDD",
    "lat": "40.5056",
    "lon": "-122.302",
    "name": "Redding Municipal Airport",
    "city": "Redding",
    "state": "California",
    "country": "United States",
    "woeid": "12521548",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ci.redding.ca.us\/adminsv\/airports\/index.htm",
    "runway_length": "7003",
    "elev": "502",
    "icao": "KRDD",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "RDM",
    "lat": "44.2533",
    "lon": "-121.162",
    "name": "Roberts Field Airport",
    "city": "Redmond",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12521617",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7040",
    "elev": "3077",
    "icao": "KRDM",
    "direct_flights": "6",
    "carriers": "11"
  },
  {
    "code": "RDN",
    "lat": "3.94515",
    "lon": "114.402",
    "name": "Malaysia",
    "city": "Redang",
    "state": "",
    "country": "Malaysia",
    "woeid": "23424901",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "940",
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RDU",
    "lat": "35.8729",
    "lon": "-78.7923",
    "name": "Durham International Airport",
    "city": "Raleigh\/Durham",
    "state": "North Carolina",
    "country": "United States",
    "woeid": "12519551",
    "tz": "America\/New_York",
    "phone": "919 840 2123",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/rdu.com",
    "runway_length": "10000",
    "elev": "437",
    "icao": "KRDU",
    "direct_flights": "47",
    "carriers": "44"
  },
  {
    "code": "RDV",
    "lat": "61.7645",
    "lon": "-157.312",
    "name": "Red Devil",
    "city": "Red Devil",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2479276",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "210",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "RDZ",
    "lat": "44.41",
    "lon": "2.48254",
    "name": "Marcillac Airport",
    "city": "Marcillac",
    "state": "Midi-Pyrenees",
    "country": "France",
    "woeid": "12512930",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "1906",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "REA",
    "lat": "-19",
    "lon": "-135.1",
    "name": "Reao",
    "city": "Reao",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "28743702",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2953",
    "elev": "7",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "REC",
    "lat": "-8.1261",
    "lon": "-34.9225",
    "name": "Gilberto Freyre International Airport",
    "city": "Recife",
    "state": "Pernambuco",
    "country": "Brazil",
    "woeid": "12511167",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9875",
    "elev": "36",
    "icao": "SBRF",
    "direct_flights": "18",
    "carriers": "13"
  },
  {
    "code": "REG",
    "lat": "38.0731",
    "lon": "15.6498",
    "name": "Reggio Calabria Airport",
    "city": "Reggio di Calabria",
    "state": "Calabria",
    "country": "Italy",
    "woeid": "22315780",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sogas.it",
    "runway_length": "6050",
    "elev": "85",
    "icao": "",
    "direct_flights": "8",
    "carriers": "8"
  },
  {
    "code": "REL",
    "lat": "-43.2111",
    "lon": "-65.2681",
    "name": "Trelew Almirante Zar Airport",
    "city": "Trelew",
    "state": "Chubut",
    "country": "Argentina",
    "woeid": "12510569",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "REN",
    "lat": "51.7953",
    "lon": "55.4498",
    "name": "Orenburg East Airport",
    "city": "Orenburg",
    "state": "Orenburgskaya Oblast",
    "country": "Russia",
    "woeid": "12516620",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "12",
    "carriers": "1"
  },
  {
    "code": "REP",
    "lat": "13.4092",
    "lon": "103.816",
    "name": "Siem Reap Airport",
    "city": "Siemrap",
    "state": "Siem Reab",
    "country": "Cambodia",
    "woeid": "12511929",
    "tz": "Asia\/Phnom_Penh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cambodia-airports.com",
    "runway_length": "8366",
    "elev": "75",
    "icao": "VDSR",
    "direct_flights": "14",
    "carriers": "16"
  },
  {
    "code": "RES",
    "lat": "-27.4514",
    "lon": "-59.0508",
    "name": "Resistencia Airport",
    "city": "Makall\u00e9",
    "state": "Chaco",
    "country": "Argentina",
    "woeid": "12510540",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9084",
    "elev": "174",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "REU",
    "lat": "41.1444",
    "lon": "1.15631",
    "name": "Reus Airport",
    "city": "Reus",
    "state": "Catalonia",
    "country": "Spain",
    "woeid": "12517564",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9513",
    "elev": "233",
    "icao": "LERS",
    "direct_flights": "13",
    "carriers": "2"
  },
  {
    "code": "REX",
    "lat": "26.0133",
    "lon": "-98.2319",
    "name": "Reynosa International Airport",
    "city": "Reynosa",
    "state": "Tamaulipas",
    "country": "Mexico",
    "woeid": "12514948",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "128",
    "icao": "MMRX",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "RFD",
    "lat": "42.2035",
    "lon": "-89.0955",
    "name": "Greater Rockford Airport",
    "city": "Rockford",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12519986",
    "tz": "America\/Chicago",
    "phone": "815-969-4000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flyrfd.com",
    "runway_length": "10",
    "elev": "736",
    "icao": "KRFD",
    "direct_flights": "7",
    "carriers": "4"
  },
  {
    "code": "RFP",
    "lat": "-16.7167",
    "lon": "-151.467",
    "name": "Raiatea Island Airport",
    "city": "Papeete",
    "state": "Leeward Islands",
    "country": "French Polynesia",
    "woeid": "12523210",
    "tz": "Pacific\/Tahiti",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "3",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "RGA",
    "lat": "-53.7792",
    "lon": "-67.7667",
    "name": "Rio Grande Airport",
    "city": "R\u00edo Grande",
    "state": "Tierra del Fuego",
    "country": "Argentina",
    "woeid": "12510546",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6561",
    "elev": "66",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RGI",
    "lat": "-14.9516",
    "lon": "-147.649",
    "name": "Rangiroa Airport",
    "city": "Papeete",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "12512828",
    "tz": "Pacific\/Gambier",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "10",
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "RGL",
    "lat": "-51.6092",
    "lon": "-69.3131",
    "name": "Rio Gallegos Airport",
    "city": "R\u00edo Gallegos",
    "state": "Santa Cruz",
    "country": "Argentina",
    "woeid": "12510544",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11647",
    "elev": "59",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "RGN",
    "lat": "16.9064",
    "lon": "96.1389",
    "name": "Mingaladon Airport",
    "city": "Insein",
    "state": "Yangon",
    "country": "Myanmar",
    "woeid": "12510924",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8100",
    "elev": "109",
    "icao": "VYYY",
    "direct_flights": "21",
    "carriers": "17"
  },
  {
    "code": "RHI",
    "lat": "45.626",
    "lon": "-89.4626",
    "name": "Rhinelander-Oneida County Airport",
    "city": "Rhinelander",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12521570",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6800",
    "elev": "1624",
    "icao": "KRHI",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "RHO",
    "lat": "36.4044",
    "lon": "28.0867",
    "name": "Paradisi Airport",
    "city": "Rodhos",
    "state": "Notio Aigaio",
    "country": "Greece",
    "woeid": "12513316",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10696",
    "elev": "14",
    "icao": "LGRP",
    "direct_flights": "48",
    "carriers": "21"
  },
  {
    "code": "RIA",
    "lat": "-29.7106",
    "lon": "-53.6875",
    "name": "Santa Maria Airport",
    "city": "Santa Maria",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511304",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7087",
    "elev": "289",
    "icao": "KRIA",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "RIB",
    "lat": "-11.0167",
    "lon": "-66.0833",
    "name": "Gen. Buech Airport",
    "city": "Riberalta",
    "state": "El Beni",
    "country": "Bolivia",
    "woeid": "12523692",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "463",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RIC",
    "lat": "37.5108",
    "lon": "-77.3332",
    "name": "Richmond International Airport",
    "city": "Richmond",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12518996",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8999",
    "elev": "168",
    "icao": "KRIC",
    "direct_flights": "26",
    "carriers": "34"
  },
  {
    "code": "RIG",
    "lat": "-32.0819",
    "lon": "-52.1664",
    "name": "Rio Grande Airport",
    "city": "Rio Grande",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511293",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "16",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RIS",
    "lat": "45.2419",
    "lon": "141.186",
    "name": "Rishiri Airport",
    "city": "Rishirifuji-cho",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "28360519",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "98",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RIV",
    "lat": "33.8806",
    "lon": "-117.259",
    "name": "March Air Force Base",
    "city": "Alessandro",
    "state": "California",
    "country": "United States",
    "woeid": "12520791",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KRIV",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "RIW",
    "lat": "43.0611",
    "lon": "-108.458",
    "name": "Riverton Regional Airport",
    "city": "Riverton",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12521603",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "5525",
    "icao": "KRIW",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "RIX",
    "lat": "56.9231",
    "lon": "23.9717",
    "name": "Riga Airport",
    "city": "Marupe",
    "state": "Rigas Rajons",
    "country": "Latvia",
    "woeid": "12514576",
    "tz": "Europe\/Riga",
    "phone": "+371 720-70-09",
    "type": "Airports",
    "email": "office@riga-airport.com",
    "url": "http:\/\/www.riga-airport.com",
    "runway_length": "8366",
    "elev": "34",
    "icao": "EVRA",
    "direct_flights": "76",
    "carriers": "36"
  },
  {
    "code": "RIY",
    "lat": "14.6611",
    "lon": "49.3761",
    "name": "Riyan Airport",
    "city": "Shuhayr",
    "state": "Hadramawt",
    "country": "Yemen",
    "woeid": "12523004",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "54",
    "icao": "",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "RJA",
    "lat": "17.1081",
    "lon": "81.82",
    "name": "Rajahmundry Airport",
    "city": "Kapavaram",
    "state": "Andhra Pradesh",
    "country": "India",
    "woeid": "12513659",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "151",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "RJK",
    "lat": "45.2167",
    "lon": "14.5683",
    "name": "Rijeka Krk Airport",
    "city": "Rijeka",
    "state": "Primorsko-Goranska",
    "country": "Croatia",
    "woeid": "12513371",
    "tz": "Europe\/Zagreb",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.rijeka-airport.hr\/",
    "runway_length": "8202",
    "elev": "246",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "RJL",
    "lat": "42.4563",
    "lon": "-2.31476",
    "name": "Agoncillo",
    "city": "Logrono",
    "state": "La Rioja",
    "country": "Spain",
    "woeid": "12578023",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "RJN",
    "lat": "30.2803",
    "lon": "57.067",
    "name": "Kerman",
    "city": "Rafsanjan",
    "state": "Kerman",
    "country": "Iran",
    "woeid": "2254796",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RKD",
    "lat": "44.0612",
    "lon": "-69.0943",
    "name": "Knox County Regional Airport",
    "city": "Owls Head",
    "state": "Maine",
    "country": "United States",
    "woeid": "12520489",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4502",
    "elev": "55",
    "icao": "KRKD",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "RKS",
    "lat": "41.5988",
    "lon": "-109.072",
    "name": "Rock Springs-Sweetwater County Airport",
    "city": "Rock Springs",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12521630",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "6760",
    "icao": "KRKS",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "RKT",
    "lat": "25.6111",
    "lon": "55.9378",
    "name": "Ras Al Khaimah International Airport",
    "city": "",
    "state": "Ras Al Khaimah",
    "country": "United Arab Emirates",
    "woeid": "12517738",
    "tz": "Asia\/Dubai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12336",
    "elev": "102",
    "icao": "OMRK",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "RKV",
    "lat": "64.1311",
    "lon": "-21.9389",
    "name": "Reykjavik Airport",
    "city": "Reykjavik",
    "state": "Reykjavik",
    "country": "Iceland",
    "woeid": "12513446",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "BIRK",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "RLG",
    "lat": "46.7427",
    "lon": "-96.2383",
    "name": "",
    "city": "Rostock-laage",
    "state": "Mecklenburg-Vorpommern",
    "country": "Germany",
    "woeid": "655984",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ETNL",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "RMA",
    "lat": "-26.5457",
    "lon": "148.773",
    "name": "Roma Aerodrome",
    "city": "Blythdale",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510774",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4212",
    "elev": "1032",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "RMF",
    "lat": "25.07",
    "lon": "34.9",
    "name": "Marsa Alam International",
    "city": "Marsa Alam",
    "state": "Al Bahr al Ahmar",
    "country": "Egypt",
    "woeid": "1525119",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/marsa-alam-airport.com",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "RMI",
    "lat": "44.0225",
    "lon": "12.618",
    "name": "Rimini Airport",
    "city": "Rimini",
    "state": "Emilia Romagna",
    "country": "Italy",
    "woeid": "12513855",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9810",
    "elev": "39",
    "icao": "LIPR",
    "direct_flights": "15",
    "carriers": "11"
  },
  {
    "code": "RMP",
    "lat": "65.5063",
    "lon": "-150.169",
    "name": "Rampart",
    "city": "Rampart",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2478448",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "275",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "RMQ",
    "lat": "23.5998",
    "lon": "121.024",
    "name": "Taiwan",
    "city": "Taichung",
    "state": null,
    "country": "Taiwan",
    "woeid": "23424971",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "RMT",
    "lat": "32.3103",
    "lon": "10.3856",
    "name": "Remada Airport",
    "city": "Tatawin",
    "state": "Tatawin",
    "country": "Tunisia",
    "woeid": "12517862",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RNA",
    "lat": "-19.3",
    "lon": "44.8167",
    "name": "Ulawa Airport",
    "city": "Arona",
    "state": "Toliara",
    "country": "Solomon Islands",
    "woeid": "1355953",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RNB",
    "lat": "56.2542",
    "lon": "15.2672",
    "name": "Ronneby Airport",
    "city": "Kallinge",
    "state": "Blekinge",
    "country": "Sweden",
    "woeid": "12517671",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7743",
    "elev": "189",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RNL",
    "lat": "-11.6667",
    "lon": "160.3",
    "name": "Rennell",
    "city": "Rennell",
    "state": "Central",
    "country": "Solomon Islands",
    "woeid": "2344838",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "94",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RNN",
    "lat": "55.0681",
    "lon": "14.7472",
    "name": "Bornholm Airport",
    "city": "Ronne",
    "state": "Hovedstaden",
    "country": "Denmark",
    "woeid": "12512596",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.bornholms-lufthavn.dk\/en\/",
    "runway_length": "6562",
    "elev": "52",
    "icao": "EKRN",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "RNO",
    "lat": "39.5064",
    "lon": "-119.776",
    "name": "Reno-Tahoe International Airport",
    "city": "Reno",
    "state": "Nevada",
    "country": "United States",
    "woeid": "12519047",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.renoairport.com",
    "runway_length": null,
    "elev": null,
    "icao": "KRNO",
    "direct_flights": "21",
    "carriers": "22"
  },
  {
    "code": "RNP",
    "lat": "11.15",
    "lon": "166.883",
    "name": "Rongelap Island",
    "city": "Rongelap Island",
    "state": "Rongelap",
    "country": "Marshall Islands",
    "woeid": "24549862",
    "tz": "Pacific\/Majuro",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KRNP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RNS",
    "lat": "48.0686",
    "lon": "-1.72536",
    "name": "St Jacques Airport",
    "city": "St-Jacques",
    "state": "Brittany",
    "country": "France",
    "woeid": "12512973",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "121",
    "icao": "LFRN",
    "direct_flights": "18",
    "carriers": "11"
  },
  {
    "code": "ROA",
    "lat": "37.3203",
    "lon": "-79.9688",
    "name": "Roanoke Regional Airport-Woodrum Field",
    "city": "Roanoke",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12521606",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6800",
    "elev": "1175",
    "icao": "KROA",
    "direct_flights": "13",
    "carriers": "18"
  },
  {
    "code": "ROB",
    "lat": "6.2328",
    "lon": "-10.365",
    "name": "Roberts International Airport",
    "city": "Harbelville",
    "state": "Grand Bassa",
    "country": "Liberia",
    "woeid": "12514603",
    "tz": "Africa\/Monrovia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11000",
    "elev": "31",
    "icao": "",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "ROC",
    "lat": "43.1309",
    "lon": "-77.6697",
    "name": "Greater Rochester International Airport",
    "city": "Rochester",
    "state": "New York",
    "country": "United States",
    "woeid": "12519985",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8001",
    "elev": "559",
    "icao": "KROC",
    "direct_flights": "22",
    "carriers": "31"
  },
  {
    "code": "ROI",
    "lat": "16.0142",
    "lon": "103.592",
    "name": "Mueang Poi Et",
    "city": "Roi Et",
    "state": "Roi Et",
    "country": "Thailand",
    "woeid": "28341194",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ROK",
    "lat": "-23.3768",
    "lon": "150.474",
    "name": "Rockhampton Airport",
    "city": "Rockhampton",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510773",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "34",
    "icao": "KBRK",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "ROO",
    "lat": "-16.4458",
    "lon": "-54.6639",
    "name": "Rondonopolis Airport",
    "city": "Rondon\u00f3polis",
    "state": "Mato Grosso",
    "country": "Brazil",
    "woeid": "12511294",
    "tz": "America\/Campo_Grande",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "984",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ROP",
    "lat": "14.1667",
    "lon": "145.25",
    "name": "Rota International Airport",
    "city": "Rota",
    "state": "Northern Mariana Islands",
    "country": "United States",
    "woeid": "12523212",
    "tz": "Pacific\/Saipan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "606",
    "icao": "PGRO",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "ROR",
    "lat": "7.35743",
    "lon": "134.547",
    "name": "Koror Airport",
    "city": "Koror",
    "state": "Koror",
    "country": "Palau",
    "woeid": "12515481",
    "tz": "Pacific\/Palau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7200",
    "elev": "176",
    "icao": "PTRO",
    "direct_flights": "8",
    "carriers": "6"
  },
  {
    "code": "ROS",
    "lat": "-32.9033",
    "lon": "-60.7856",
    "name": "Rosario Airport",
    "city": "Rosario",
    "state": "Santa Fe",
    "country": "Argentina",
    "woeid": "12510547",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "82",
    "icao": "KROS",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "ROT",
    "lat": "-38.1047",
    "lon": "176.314",
    "name": "Rotorua Airport",
    "city": "Rotorua",
    "state": "Bay Of Plenty",
    "country": "New Zealand",
    "woeid": "12515167",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "936",
    "icao": "NZRO",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "ROV",
    "lat": "47.2556",
    "lon": "39.8169",
    "name": "Rostov East Airport",
    "city": "Taganrog",
    "state": "Rostovskaya Oblast",
    "country": "Russia",
    "woeid": "12516787",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "253",
    "icao": "",
    "direct_flights": "36",
    "carriers": "21"
  },
  {
    "code": "ROW",
    "lat": "33.3071",
    "lon": "-104.519",
    "name": "Roswell Industrial Air Center",
    "city": "Roswell",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12521662",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13000",
    "elev": "3669",
    "icao": "KROW",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RPR",
    "lat": "21.1671",
    "lon": "81.7473",
    "name": "Raipur Airport",
    "city": "Banarsi",
    "state": "Chhattisgarh",
    "country": "India",
    "woeid": "12513658",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6107",
    "elev": "1037",
    "icao": "VARP",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "RRG",
    "lat": "-20.2554",
    "lon": "57.5517",
    "name": "Mauritius",
    "city": "Rodrigues Is",
    "state": "Rodrigues",
    "country": "Mauritius",
    "woeid": "23424894",
    "tz": "Indian\/Mauritius",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3346",
    "elev": "131",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RRS",
    "lat": "62.5792",
    "lon": "11.3458",
    "name": "Roeros Airport",
    "city": "Roros",
    "state": "Sor-Trondelag",
    "country": "Norway",
    "woeid": "12515120",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5610",
    "elev": "2054",
    "icao": "ENRO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RSA",
    "lat": "-36.5881",
    "lon": "-64.2756",
    "name": "Santa Rosa Airport",
    "city": "Santa Rosa",
    "state": "La Pampa",
    "country": "Argentina",
    "woeid": "12510559",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7545",
    "elev": "623",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RSD",
    "lat": "24.8917",
    "lon": "-76.1781",
    "name": "Rock Sound Airport",
    "city": "Rock Sound",
    "state": "South Eleuthera",
    "country": "Bahamas",
    "woeid": "12510877",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7200",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "RSH",
    "lat": "61.7833",
    "lon": "-161.333",
    "name": "Russian",
    "city": "Russian Mission",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799709",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "70",
    "icao": "PARS",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "RSJ",
    "lat": "48.6339",
    "lon": "-122.81",
    "name": "Rosario Seaplane Base",
    "city": "Olga",
    "state": "Washington",
    "country": "United States",
    "woeid": "12524597",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2850",
    "elev": "2170",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RST",
    "lat": "43.9101",
    "lon": "-92.4884",
    "name": "Rochester International Airport",
    "city": "Rochester",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12521625",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7533",
    "elev": "1317",
    "icao": "KRST",
    "direct_flights": "6",
    "carriers": "9"
  },
  {
    "code": "RSU",
    "lat": "34.8369",
    "lon": "127.619",
    "name": "Yeosu Airport",
    "city": "Yeosu-Si",
    "state": "Jeollanam-Do",
    "country": "South Korea",
    "woeid": "12514236",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "68",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "RSW",
    "lat": "26.5228",
    "lon": "-81.7531",
    "name": "Southwest Florida International Airport",
    "city": "Fort Myers",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521921",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8400",
    "elev": "31",
    "icao": "KRSW",
    "direct_flights": "39",
    "carriers": "25"
  },
  {
    "code": "RTA",
    "lat": "-12.5017",
    "lon": "177.066",
    "name": "Rotuma",
    "city": "Rotuma Island",
    "state": "Rotuma",
    "country": "Fiji",
    "woeid": "20069921",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4901",
    "elev": "25",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RTB",
    "lat": "16.3242",
    "lon": "-86.5323",
    "name": "Roatan Island Airport",
    "city": "Roat\u00e1n",
    "state": "Islas de la Bah\u00eda",
    "country": "Honduras",
    "woeid": "12513362",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3400",
    "elev": "7",
    "icao": "MHRO",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "RTG",
    "lat": "-8.5878",
    "lon": "120.483",
    "name": "Satartacik Airport",
    "city": "Ruteng",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12513503",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "3440",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RTM",
    "lat": "51.9525",
    "lon": "4.4392",
    "name": "Rotterdam Airport",
    "city": "Rotterdam",
    "state": "South Holland",
    "country": "Netherlands",
    "woeid": "23182361",
    "tz": "Europe\/Amsterdam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.rotterdam-airport.nl",
    "runway_length": "7218",
    "elev": "-15",
    "icao": "EHRD",
    "direct_flights": "22",
    "carriers": "10"
  },
  {
    "code": "RTW",
    "lat": "51.5617",
    "lon": "46.0419",
    "name": "Saratov Airport",
    "city": "Saratov",
    "state": "Saratovskaya Oblast",
    "country": "Russia",
    "woeid": "12516819",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "RUA",
    "lat": "37.5534",
    "lon": "-76.3539",
    "name": "",
    "city": "Arua",
    "state": "Arua",
    "country": "Uganda",
    "woeid": "1450394",
    "tz": "Africa\/Kampala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5600",
    "elev": "3951",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RUD",
    "lat": "59.9448",
    "lon": "10.6382",
    "name": "",
    "city": "Oslo",
    "state": "Oslo Fylke",
    "country": "Norway",
    "woeid": "863011",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RUH",
    "lat": "24.9625",
    "lon": "46.7078",
    "name": "King Khalid International Airport",
    "city": "Riyadh",
    "state": "Ar Riyad",
    "country": "Saudi Arabia",
    "woeid": "12517349",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13287",
    "elev": "2082",
    "icao": "OERK",
    "direct_flights": "71",
    "carriers": "35"
  },
  {
    "code": "RUK",
    "lat": "28.3948",
    "lon": "84.1278",
    "name": "Nepal",
    "city": "Rukumkot",
    "state": "Midwest",
    "country": "Nepal",
    "woeid": "23424911",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RUM",
    "lat": "27.3333",
    "lon": "86.55",
    "name": "Rumjatar Airport",
    "city": "Rumjatar",
    "state": "Central",
    "country": "Nepal",
    "woeid": "2269044",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1525",
    "elev": "4500",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RUN",
    "lat": "-20.8772",
    "lon": "55.5214",
    "name": "Saint Denis Gillot Airport",
    "city": "Sainte-Marie",
    "state": "St-Denis",
    "country": "Reunion",
    "woeid": "12515488",
    "tz": "Indian\/Reunion",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8760",
    "elev": "66",
    "icao": "FMEE",
    "direct_flights": "18",
    "carriers": "7"
  },
  {
    "code": "RUR",
    "lat": "51.0804",
    "lon": "6.14209",
    "name": "",
    "city": "Rurutu",
    "state": "Austral Islands",
    "country": "French Polynesia",
    "woeid": "28743711",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "17",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "RUT",
    "lat": "43.5291",
    "lon": "-72.945",
    "name": "Rutland State Airport",
    "city": "North Clarendon",
    "state": "Vermont",
    "country": "United States",
    "woeid": "12521687",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "787",
    "icao": "KRUT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RVA",
    "lat": "-22.8053",
    "lon": "47.8206",
    "name": "",
    "city": "Farafangana",
    "state": "Fianarantsoa",
    "country": "Madagascar",
    "woeid": "1360922",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4347",
    "elev": "26",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RVE",
    "lat": "6.9581",
    "lon": "-71.855",
    "name": "Saravena El Eden Airport",
    "city": "Saravena",
    "state": "Arauca",
    "country": "Colombia",
    "woeid": "12512416",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3677",
    "elev": "212",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RVK",
    "lat": "64.8402",
    "lon": "11.1352",
    "name": "Ryumsjoen Airport",
    "city": "Rorvik",
    "state": "Nord-Trondelag",
    "country": "Norway",
    "woeid": "12523946",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1969",
    "elev": "11",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "RVN",
    "lat": "66.5628",
    "lon": "25.8266",
    "name": "Rovaniemi Airport",
    "city": "Saarenkyl\u00e4",
    "state": "Lapland",
    "country": "Finland",
    "woeid": "12512798",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "645",
    "icao": "EFRO",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "RVT",
    "lat": "-24.4378",
    "lon": "121.079",
    "name": "Western Australia",
    "city": "Ravensthorpe",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "2344706",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RVV",
    "lat": "-23.8862",
    "lon": "-147.665",
    "name": "Raivavae Airport",
    "city": "Rairua",
    "state": "Austral Islands",
    "country": "French Polynesia",
    "woeid": "28752897",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RXS",
    "lat": "11.6",
    "lon": "122.751",
    "name": "Roxas Airport",
    "city": "Ivisan",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "12515644",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5807",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "RYG",
    "lat": "59.3789",
    "lon": "10.7856",
    "name": "Moss Airport",
    "city": "Rygge",
    "state": "",
    "country": "Norway",
    "woeid": "863279",
    "tz": "Europe\/Oslo",
    "phone": "(+47) 69 23 58 40",
    "type": "Airports",
    "email": "post@ryg.no",
    "url": "http:\/\/www.ryg.no\/",
    "runway_length": "8",
    "elev": "174",
    "icao": "ENRY",
    "direct_flights": "15",
    "carriers": "1"
  },
  {
    "code": "RYK",
    "lat": "28.4",
    "lon": "70.2833",
    "name": "Rahim Yar Khan",
    "city": "Rahim Yar Khan",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "2211116",
    "tz": "Asia\/Karachi",
    "phone": "+926 85562301",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "270",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "RZE",
    "lat": "50.1062",
    "lon": "22.0243",
    "name": "Jasionka Airport",
    "city": "Trzebownisko",
    "state": "Subcarpathia",
    "country": "Poland",
    "woeid": "12515314",
    "tz": "Europe\/Warsaw",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8209",
    "elev": "692",
    "icao": "EPRZ",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "RZP",
    "lat": "15.5931",
    "lon": "120.739",
    "name": "Philippines",
    "city": "Taytay Sandoval",
    "state": "Calabarzon",
    "country": "Philippines",
    "woeid": "23424934",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RZR",
    "lat": "36.9108",
    "lon": "50.6806",
    "name": "Ramsar Airport",
    "city": "Ramsar",
    "state": "Mazandaran",
    "country": "Iran",
    "woeid": "12513751",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "-75",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "RZS",
    "lat": "33.6403",
    "lon": "73.8569",
    "name": "",
    "city": "Sawan",
    "state": "",
    "country": "Pakistan",
    "woeid": "23424922",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SAB",
    "lat": "17.6394",
    "lon": "-63.2316",
    "name": "Yrausquin Airport",
    "city": "",
    "state": "Saba",
    "country": "Netherlands Antilles",
    "woeid": "12515142",
    "tz": "America\/Curacao",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1300",
    "elev": "60",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SAH",
    "lat": "15.48",
    "lon": "44.2208",
    "name": "Sanaa International Airport",
    "city": "Ar Raudha",
    "state": "San`a\u00b4",
    "country": "Yemen",
    "woeid": "12523005",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "7216",
    "icao": "OYSN",
    "direct_flights": "29",
    "carriers": "18"
  },
  {
    "code": "SAL",
    "lat": "13.44",
    "lon": "-89.0558",
    "name": "El Salvador International Airport",
    "city": "San Luis",
    "state": "La Paz",
    "country": "El Salvador",
    "woeid": "12512751",
    "tz": "America\/El_Salvador",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeropuertoelsalvador.gob.sv\/",
    "runway_length": "10500",
    "elev": "101",
    "icao": "MSLP",
    "direct_flights": "20",
    "carriers": "16"
  },
  {
    "code": "SAN",
    "lat": "32.7299",
    "lon": "-117.195",
    "name": "San Diego International Airport",
    "city": "San Diego",
    "state": "California",
    "country": "United States",
    "woeid": "12520660",
    "tz": "America\/Los_Angeles",
    "phone": "(619) 231 2100",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.san.org\/",
    "runway_length": "9400",
    "elev": "15",
    "icao": "KSAN",
    "direct_flights": "51",
    "carriers": "57"
  },
  {
    "code": "SAP",
    "lat": "15.4514",
    "lon": "-87.9242",
    "name": "La Mesa International Airport",
    "city": "San Pedro Sula",
    "state": "Cort\u00e9s",
    "country": "Honduras",
    "woeid": "12513361",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9613",
    "elev": "90",
    "icao": "MHLM",
    "direct_flights": "13",
    "carriers": "18"
  },
  {
    "code": "SAQ",
    "lat": "25.05",
    "lon": "-78.0333",
    "name": "San Andros Municipal Airport",
    "city": "San Andros",
    "state": "North Andros",
    "country": "Bahamas",
    "woeid": "12524025",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4974",
    "elev": "5",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SAT",
    "lat": "29.5252",
    "lon": "-98.4729",
    "name": "San Antonio International Airport",
    "city": "San Antonio",
    "state": "Texas",
    "country": "United States",
    "woeid": "12521716",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8502",
    "elev": "809",
    "icao": "KSAT",
    "direct_flights": "44",
    "carriers": "34"
  },
  {
    "code": "SAV",
    "lat": "32.1338",
    "lon": "-81.2133",
    "name": "Savannah International Airport",
    "city": "Savannah",
    "state": "Georgia",
    "country": "United States",
    "woeid": "12521756",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9003",
    "elev": "51",
    "icao": "KSAV",
    "direct_flights": "18",
    "carriers": "21"
  },
  {
    "code": "SAW",
    "lat": "40.9928",
    "lon": "29.034",
    "name": "Istanbul Sabiha Gokcen Airport",
    "city": "Umraniye",
    "state": "Istanbul",
    "country": "Turkey",
    "woeid": "23416958",
    "tz": "Europe\/Istanbul",
    "phone": "+90 216 585 50 00",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sgairport.com\/",
    "runway_length": "9843",
    "elev": "90",
    "icao": "LTFJ",
    "direct_flights": "34",
    "carriers": "18"
  },
  {
    "code": "SBA",
    "lat": "34.4255",
    "lon": "-119.835",
    "name": "Santa Barbara Municipal Airport",
    "city": "Goleta",
    "state": "California",
    "country": "United States",
    "woeid": "12521741",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6049",
    "elev": "10",
    "icao": "KSBA",
    "direct_flights": "12",
    "carriers": "17"
  },
  {
    "code": "SBH",
    "lat": "17.8667",
    "lon": "-62.8333",
    "name": "Gustavia Airport",
    "city": "Gustavia",
    "state": "Saint-Martin et Saint-Barth\u00e9l\u00e9",
    "country": "Guadeloupe",
    "woeid": "12523214",
    "tz": "America\/Guadeloupe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2100",
    "elev": "49",
    "icao": "",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "SBL",
    "lat": "-13.7667",
    "lon": "-65.5833",
    "name": "Santa Ana de Yacuma Airport",
    "city": "Santa Ana",
    "state": "El Beni",
    "country": "Bolivia",
    "woeid": "12524440",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4823",
    "elev": "726",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SBN",
    "lat": "41.7006",
    "lon": "-86.311",
    "name": "South Bend Regional Airport",
    "city": "South Bend",
    "state": "Indiana",
    "country": "United States",
    "woeid": "12520929",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7099",
    "elev": "790",
    "icao": "KSBN",
    "direct_flights": "16",
    "carriers": "14"
  },
  {
    "code": "SBP",
    "lat": "35.239",
    "lon": "-120.641",
    "name": "San Luis Obispo County Airport",
    "city": "San Luis Obispo",
    "state": "California",
    "country": "United States",
    "woeid": "12521725",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5300",
    "elev": "212",
    "icao": "KSBP",
    "direct_flights": "3",
    "carriers": "8"
  },
  {
    "code": "SBR",
    "lat": "-9.4",
    "lon": "142.667",
    "name": "Saibai Island",
    "city": "Saibai Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "1105453",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SBW",
    "lat": "2.3417",
    "lon": "111.838",
    "name": "Sibu Airport",
    "city": "Sibu",
    "state": "Sarawak",
    "country": "Malaysia",
    "woeid": "12515006",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "28",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "SBY",
    "lat": "38.3431",
    "lon": "-75.517",
    "name": "Salisbury-Wicomico County Regional Airport",
    "city": "Salisbury",
    "state": "Maryland",
    "country": "United States",
    "woeid": "12521706",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "52",
    "icao": "KSBY",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SBZ",
    "lat": "45.7882",
    "lon": "24.0943",
    "name": "Turnisor Airport",
    "city": "Sibiu",
    "state": "Sibiu",
    "country": "Romania",
    "woeid": "12515589",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "1474",
    "icao": "LRSB",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "SCC",
    "lat": "70.1975",
    "lon": "-148.458",
    "name": "Deadhorse Airport",
    "city": "Prudhoe Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12519437",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "45",
    "icao": "PASC",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "SCE",
    "lat": "40.8515",
    "lon": "-77.851",
    "name": "University Park Airport",
    "city": "State College",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "12522252",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4065",
    "elev": "1228",
    "icao": "KUNV",
    "direct_flights": "4",
    "carriers": "8"
  },
  {
    "code": "SCK",
    "lat": "37.8973",
    "lon": "-121.253",
    "name": "Stockton Metropolitan Airport",
    "city": "Stockton",
    "state": "California",
    "country": "United States",
    "woeid": "12522018",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8650",
    "elev": "30",
    "icao": "KSCK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SCL",
    "lat": "-33.39",
    "lon": "-70.785",
    "name": "Arturo Merino Benitez International Airport",
    "city": "Lo Amor",
    "state": "Santiago",
    "country": "Chile",
    "woeid": "12512304",
    "tz": "America\/Santiago",
    "phone": "(56 2) 690 17 52",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeropuertosantiago.cl\/ingles\/index.php",
    "runway_length": "10499",
    "elev": "1554",
    "icao": "SCEL",
    "direct_flights": "43",
    "carriers": "36"
  },
  {
    "code": "SCM",
    "lat": "61.8425",
    "lon": "-165.58",
    "name": "Scammon Bay",
    "city": "Scammon Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2489516",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "2777",
    "elev": "22",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "SCN",
    "lat": "49.2188",
    "lon": "7.11263",
    "name": "Saarbrucken Airport",
    "city": "Sarrebruck",
    "state": "Saar",
    "country": "Germany",
    "woeid": "22273157",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "1057",
    "icao": "EDDR",
    "direct_flights": "13",
    "carriers": "6"
  },
  {
    "code": "SCO",
    "lat": "43.8667",
    "lon": "51.1",
    "name": "Aktau",
    "city": "Aktau",
    "state": "Mangghystau",
    "country": "Kazakhstan",
    "woeid": "2261655",
    "tz": "Asia\/Aqtau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "13",
    "carriers": "6"
  },
  {
    "code": "SCQ",
    "lat": "42.8991",
    "lon": "-8.41646",
    "name": "Santiago Airport",
    "city": "Santiago",
    "state": "Galicia",
    "country": "Spain",
    "woeid": "12517569",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "1221",
    "icao": "LEST",
    "direct_flights": "23",
    "carriers": "17"
  },
  {
    "code": "SCT",
    "lat": "25.6472",
    "lon": "-100.172",
    "name": "",
    "city": "Socotra",
    "state": "",
    "country": "Yemen",
    "woeid": "23425002",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9510",
    "elev": "150",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SCU",
    "lat": "19.9708",
    "lon": "-75.8364",
    "name": "Antonio Maceo Airport",
    "city": "",
    "state": "Santiago de Cuba",
    "country": "Cuba",
    "woeid": "12512450",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8465",
    "elev": "207",
    "icao": "MUCU",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SCV",
    "lat": "47.6943",
    "lon": "26.363",
    "name": "Suceava Salcea Airport",
    "city": "",
    "state": "Suceava",
    "country": "Romania",
    "woeid": "12515580",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "1371",
    "icao": "LRSV",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SCW",
    "lat": "61.6726",
    "lon": "50.822",
    "name": "Russia",
    "city": "Syktyvkar",
    "state": "Komi",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "13",
    "carriers": "5"
  },
  {
    "code": "SCX",
    "lat": "16.2104",
    "lon": "-95.1989",
    "name": "Salina Cruz",
    "city": "Salina Cruz",
    "state": "",
    "country": "Mexico",
    "woeid": "24552823",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5248",
    "elev": "75",
    "icao": "KSCX",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SCY",
    "lat": "-0.91",
    "lon": "-89.6067",
    "name": "San Cristobal Airport",
    "city": "Puerto Baquerizo Moreno",
    "state": "Galapagos",
    "country": "Ecuador",
    "woeid": "12512650",
    "tz": "Pacific\/Galapagos",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SCZ",
    "lat": "-11.6167",
    "lon": "166.85",
    "name": "Santa Cruz Is",
    "city": "Santa Cruz Is",
    "state": "",
    "country": "Solomon Islands",
    "woeid": "23424766",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "18",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SDD",
    "lat": "-14.9244",
    "lon": "13.5767",
    "name": "Lubango Airport",
    "city": "Lubango",
    "state": "Huila",
    "country": "Angola",
    "woeid": "12510440",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9200",
    "elev": "5740",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "SDE",
    "lat": "-27.7675",
    "lon": "-64.3106",
    "name": "Santiago del Estero Airport",
    "city": "Fr\u00edas",
    "state": "Santiago del Estero",
    "country": "Argentina",
    "woeid": "12510562",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9430",
    "elev": "650",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SDF",
    "lat": "38.1865",
    "lon": "-85.7462",
    "name": "Louisville International Airport",
    "city": "Louisville",
    "state": "Kentucky",
    "country": "United States",
    "woeid": "12521983",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "496",
    "icao": "KSDF",
    "direct_flights": "32",
    "carriers": "26"
  },
  {
    "code": "SDG",
    "lat": "35.25",
    "lon": "47.0092",
    "name": "Sanandaj Airport",
    "city": "Sanandaj",
    "state": "Kordestan",
    "country": "Iran",
    "woeid": "12513755",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6725",
    "elev": "4505",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SDJ",
    "lat": "38.1401",
    "lon": "140.918",
    "name": "Sendai Airport",
    "city": "Natori-shi",
    "state": "Miyagi Prefecture",
    "country": "Japan",
    "woeid": "12514015",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.pref.miyagi.jp\/KUTAI\/ENGLISH\/top-english.htm",
    "runway_length": "9342",
    "elev": "6",
    "icao": "RJSS",
    "direct_flights": "16",
    "carriers": "14"
  },
  {
    "code": "SDK",
    "lat": "5.9017",
    "lon": "118.053",
    "name": "Sandakan Airport",
    "city": "Sandakan",
    "state": "Sabah",
    "country": "Malaysia",
    "woeid": "12515005",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "+60 (0)89 660405",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "46",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "SDL",
    "lat": "62.5249",
    "lon": "17.4407",
    "name": "Sundsvall Harnosand Airport",
    "city": "Bergeforsen",
    "state": "Vasternorrland",
    "country": "Sweden",
    "woeid": "23322231",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "13",
    "icao": "KSDL",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "SDN",
    "lat": "61.8296",
    "lon": "6.10628",
    "name": "Sandane Airport",
    "city": "Sandene",
    "state": "Sogn og Fjordane Fylke",
    "country": "Norway",
    "woeid": "12523947",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "196",
    "icao": "ENSD",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "SDP",
    "lat": "55.3184",
    "lon": "-160.523",
    "name": "Sand Point Airport",
    "city": "Sand Point",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29387742",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3800",
    "elev": "22",
    "icao": "PASD",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "SDQ",
    "lat": "18.4292",
    "lon": "-69.6692",
    "name": "De Las Americas International Airport",
    "city": "Santo Domingo",
    "state": "Santo Domingo",
    "country": "Dominican Republic",
    "woeid": "12512616",
    "tz": "America\/Santo_Domingo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11002",
    "elev": "58",
    "icao": "MDSD",
    "direct_flights": "27",
    "carriers": "26"
  },
  {
    "code": "SDR",
    "lat": "43.4232",
    "lon": "-3.82375",
    "name": "Santander Airport",
    "city": "Santander",
    "state": "Cantabria",
    "country": "Spain",
    "woeid": "12517568",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "17",
    "icao": "",
    "direct_flights": "15",
    "carriers": "2"
  },
  {
    "code": "SDT",
    "lat": "34.8125",
    "lon": "72.3528",
    "name": "Saidu Sharif Airport",
    "city": "Saidu",
    "state": "North-West Frontier",
    "country": "Pakistan",
    "woeid": "12515266",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6053",
    "elev": "3000",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SDU",
    "lat": "-22.9107",
    "lon": "-43.1707",
    "name": "Santos Dumont Airport",
    "city": "Rio de Janeiro",
    "state": "Rio de Janeiro",
    "country": "Brazil",
    "woeid": "12511312",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4341",
    "elev": "11",
    "icao": "SBRJ",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "SDV",
    "lat": "32.1104",
    "lon": "34.7823",
    "name": "Sde Dov Airport",
    "city": "Tel Aviv Yafo",
    "state": "Tel Aviv",
    "country": "Israel",
    "woeid": "23388293",
    "tz": "Asia\/Jerusalem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SDY",
    "lat": "47.7113",
    "lon": "-104.184",
    "name": "Sidney Richland Municipal Airport",
    "city": "Sidney",
    "state": "Montana",
    "country": "United States",
    "woeid": "12521850",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5705",
    "elev": "1984",
    "icao": "KSDY",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "SEA",
    "lat": "47.4405",
    "lon": "-122.296",
    "name": "Tacoma International Airport",
    "city": "Seattle",
    "state": "Washington",
    "country": "United States",
    "woeid": "12522066",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.portseattle.org\/seatac\/",
    "runway_length": "11900",
    "elev": "429",
    "icao": "KSEA",
    "direct_flights": "109",
    "carriers": "59"
  },
  {
    "code": "SEB",
    "lat": "26.9933",
    "lon": "14.4669",
    "name": "Sebha Airport",
    "city": "Sabha",
    "state": "Sabha",
    "country": "Libya",
    "woeid": "12514670",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11877",
    "elev": "1427",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "SEN",
    "lat": "51.5678",
    "lon": "0.69928",
    "name": "Southend Airport",
    "city": "Southend on Sea",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518151",
    "tz": "Europe\/London",
    "phone": "+44 (0)1702 608100",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.southendairport.net",
    "runway_length": "5265",
    "elev": "49",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SEW",
    "lat": "40.6438",
    "lon": "-93.2598",
    "name": "",
    "city": "Siwa",
    "state": "Matruh",
    "country": "Egypt",
    "woeid": "1526392",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5820",
    "elev": "330",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SEZ",
    "lat": "-4.6751",
    "lon": "55.5177",
    "name": "Seychelles International Airport",
    "city": "Victoria",
    "state": "English River",
    "country": "Seychelles",
    "woeid": "12517389",
    "tz": "Indian\/Mahe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9800",
    "elev": "10",
    "icao": "FSIA",
    "direct_flights": "15",
    "carriers": "8"
  },
  {
    "code": "SFA",
    "lat": "34.7181",
    "lon": "10.6917",
    "name": "El Maou Airport",
    "city": "Safaqis",
    "state": "Safaqis",
    "country": "Tunisia",
    "woeid": "12517857",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "82",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "SFB",
    "lat": "28.773",
    "lon": "-81.2403",
    "name": "Orlando Sanford International Airport",
    "city": "Sanford",
    "state": "Florida",
    "country": "United States",
    "woeid": "23418431",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.orlandosanfordairport.com",
    "runway_length": "9600",
    "elev": "55",
    "icao": "KSFB",
    "direct_flights": "18",
    "carriers": "3"
  },
  {
    "code": "SFD",
    "lat": "7.8856",
    "lon": "-67.4406",
    "name": "San Fernando de Apure Airport",
    "city": "San Fernando de Apure",
    "state": "Apure",
    "country": "Venezuela",
    "woeid": "12522842",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "239",
    "icao": "KSFD",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SFE",
    "lat": "16.5958",
    "lon": "120.302",
    "name": "San Fernando Airport",
    "city": "San Fernando",
    "state": "Ilocos Region",
    "country": "Philippines",
    "woeid": "12515645",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "13",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SFG",
    "lat": "18.0421",
    "lon": "-63.1144",
    "name": "Grand Case-Esperance Airport",
    "city": "",
    "state": "Saint-Martin et Saint-Barth\u00e9l\u00e9",
    "country": "Guadeloupe",
    "woeid": "12513276",
    "tz": "America\/Guadeloupe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "SFJ",
    "lat": "67.0169",
    "lon": "-50.6892",
    "name": "Kangerlussuaq",
    "city": "Kangerlussuaq",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12513032",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9235",
    "elev": "165",
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "SFL",
    "lat": "14.9",
    "lon": "-24.5167",
    "name": "",
    "city": "Sao Filipe",
    "state": "Fogo",
    "country": "Cape Verde",
    "woeid": "1310891",
    "tz": "Atlantic\/Cape_Verde",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1804",
    "elev": "617",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SFN",
    "lat": "-31.7108",
    "lon": "-60.8117",
    "name": "Sauce Viejo Airport",
    "city": "Santa Fe",
    "state": "Santa Fe",
    "country": "Argentina",
    "woeid": "12510563",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7170",
    "elev": "56",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SFO",
    "lat": "37.6148",
    "lon": "-122.392",
    "name": "San Francisco International Airport",
    "city": "San Francisco",
    "state": "California",
    "country": "United States",
    "woeid": "12521721",
    "tz": "America\/Los_Angeles",
    "phone": "(650) 821-8211",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flysfo.com",
    "runway_length": "11870",
    "elev": "12",
    "icao": "KSFO",
    "direct_flights": "147",
    "carriers": "76"
  },
  {
    "code": "SFT",
    "lat": "64.6236",
    "lon": "21.0675",
    "name": "Skelleftea Airport",
    "city": "Skelleftea",
    "state": "Vasterbotten",
    "country": "Sweden",
    "woeid": "12517678",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "153",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SGC",
    "lat": "61.3468",
    "lon": "73.4182",
    "name": "Surgut North Airport",
    "city": "Khanty-Mansiysk",
    "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
    "country": "Russia",
    "woeid": "12516957",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "29",
    "carriers": "8"
  },
  {
    "code": "SGD",
    "lat": "54.963",
    "lon": "9.78978",
    "name": "Sonderborg Airport",
    "city": "Sonderborg",
    "state": "Syddanmark",
    "country": "Denmark",
    "woeid": "12512601",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.eksb.dk",
    "runway_length": "4921",
    "elev": "24",
    "icao": "EKSB",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SGF",
    "lat": "37.243",
    "lon": "-93.3817",
    "name": "Springfield Regional Airport",
    "city": "Springfield",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12521949",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7003",
    "elev": "1267",
    "icao": "KSGF",
    "direct_flights": "15",
    "carriers": "16"
  },
  {
    "code": "SGN",
    "lat": "10.8191",
    "lon": "106.658",
    "name": "Tan Son Nhut Airport",
    "city": "Ho Chi Minh City",
    "state": "Ho Chi Minh",
    "country": "Vietnam",
    "woeid": "12522956",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.saigonairport.com",
    "runway_length": "3320",
    "elev": "9",
    "icao": "VVTS",
    "direct_flights": "45",
    "carriers": "48"
  },
  {
    "code": "SGO",
    "lat": "-28.0833",
    "lon": "148.75",
    "name": "St George",
    "city": "St George",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12708293",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "656",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SGU",
    "lat": "37.0973",
    "lon": "-113.591",
    "name": "St George Municipal Airport",
    "city": "St. George",
    "state": "Utah",
    "country": "United States",
    "woeid": "12521964",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6100",
    "elev": "2938",
    "icao": "KSGU",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SGX",
    "lat": "-10.6668",
    "lon": "36.3287",
    "name": "Ruvuma",
    "city": "Songea",
    "state": "Ruvuma",
    "country": "Tanzania",
    "woeid": "2347365",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5743",
    "elev": "3445",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SGY",
    "lat": "59.4594",
    "lon": "-135.314",
    "name": "Skagway Airport",
    "city": "Skagway",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12521869",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3301",
    "elev": "44",
    "icao": "PAGY",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SHA",
    "lat": "31.1865",
    "lon": "121.34",
    "name": "Hongqiao Airport",
    "city": "Shanghai",
    "state": "Shanghai",
    "country": "China",
    "woeid": "23388248",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "15",
    "icao": "ZSSS",
    "direct_flights": "60",
    "carriers": "21"
  },
  {
    "code": "SHB",
    "lat": "43.5767",
    "lon": "144.958",
    "name": "Nakashibetsu Airport",
    "city": "Nakashibetsu-cho",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "12523220",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.nakashibetsu-airport.jp\/",
    "runway_length": "6561",
    "elev": "218",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SHC",
    "lat": "14.0833",
    "lon": "38.2833",
    "name": "",
    "city": "Indaselassie",
    "state": "Tigray",
    "country": "Ethiopia",
    "woeid": "1482295",
    "tz": "Africa\/Addis_Ababa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SHD",
    "lat": "38.2637",
    "lon": "-78.9013",
    "name": "Shenandoah Valley Regional Airport",
    "city": "Weyers Cave",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12521830",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6002",
    "elev": "1201",
    "icao": "KSHD",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SHE",
    "lat": "41.7857",
    "lon": "123.529",
    "name": "Dongta Airport",
    "city": "Shenyang",
    "state": "Liaoning",
    "country": "China",
    "woeid": "12512041",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6626",
    "elev": "157",
    "icao": "ZYTX",
    "direct_flights": "44",
    "carriers": "29"
  },
  {
    "code": "SHG",
    "lat": "66.8801",
    "lon": "-157.067",
    "name": "Shungnak Airport",
    "city": "Shungnak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524646",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2800",
    "elev": "200",
    "icao": "PAHG",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "SHH",
    "lat": "66.2568",
    "lon": "-166.067",
    "name": "Shishmaref",
    "city": "Shishmaref",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2492966",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "8",
    "icao": "PASH",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SHJ",
    "lat": "25.3297",
    "lon": "55.5178",
    "name": "Sharjah International Airport",
    "city": "Ajman",
    "state": "Ajman",
    "country": "United Arab Emirates",
    "woeid": "12517739",
    "tz": "Asia\/Dubai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12336",
    "elev": "111",
    "icao": "",
    "direct_flights": "66",
    "carriers": "25"
  },
  {
    "code": "SHL",
    "lat": "25.5667",
    "lon": "91.8833",
    "name": "",
    "city": "Shillong",
    "state": "Assam",
    "country": "India",
    "woeid": "2277394",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "2950",
    "icao": "KSHL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SHM",
    "lat": "33.664",
    "lon": "135.362",
    "name": "Nanki-Shirahama Airport",
    "city": "Shirahama-cho",
    "state": "Wakayama Prefecture",
    "country": "Japan",
    "woeid": "12513996",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3936",
    "elev": "345",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SHP",
    "lat": "45.0167",
    "lon": "119.75",
    "name": "Qinhuangdao",
    "city": "Qinhuangdao",
    "state": "\u5185\u8499\u53e4\u81ea\u6cbb\u533a",
    "country": "China",
    "woeid": "12711986",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SHR",
    "lat": "44.7739",
    "lon": "-106.97",
    "name": "Sheridan County Airport",
    "city": "Sheridan",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12521834",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6649",
    "elev": "4021",
    "icao": "KSHR",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SHV",
    "lat": "32.4549",
    "lon": "-93.8285",
    "name": "Shreveport Regional Airport",
    "city": "Shreveport",
    "state": "Louisiana",
    "country": "United States",
    "woeid": "12521845",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8351",
    "elev": "258",
    "icao": "KSHV",
    "direct_flights": "10",
    "carriers": "13"
  },
  {
    "code": "SHW",
    "lat": "17.4686",
    "lon": "47.1242",
    "name": "Sharurah Airport",
    "city": "As-Saraura",
    "state": "Najran",
    "country": "Saudi Arabia",
    "woeid": "12517369",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11975",
    "elev": "2363",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "SHX",
    "lat": "62.6333",
    "lon": "-159.583",
    "name": "Shageluk",
    "city": "Shageluk",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799717",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "70",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "SHY",
    "lat": "-3.66487",
    "lon": "33.4212",
    "name": "Shinyanga",
    "city": "Shinyanga",
    "state": "Shinyanga",
    "country": "Tanzania",
    "woeid": "1449377",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4560",
    "elev": "3800",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SIA",
    "lat": "34.3667",
    "lon": "108.7",
    "name": "Xiguan Airport",
    "city": "Xi'an",
    "state": "Shaanxi",
    "country": "China",
    "woeid": "12512238",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "1572",
    "icao": "",
    "direct_flights": "15",
    "carriers": "1"
  },
  {
    "code": "SIC",
    "lat": "42.0189",
    "lon": "35.0792",
    "name": "",
    "city": "Sinop",
    "state": "Sinop",
    "country": "Turkey",
    "woeid": "2344394",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "21",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SID",
    "lat": "16.745",
    "lon": "-22.9517",
    "name": "Amilcar Cabral International Airport",
    "city": "Santa Maria",
    "state": "Sal",
    "country": "Cape Verde",
    "woeid": "12512490",
    "tz": "Atlantic\/Cape_Verde",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10728",
    "elev": "177",
    "icao": "GVAC",
    "direct_flights": "15",
    "carriers": "5"
  },
  {
    "code": "SIF",
    "lat": "27",
    "lon": "85.5333",
    "name": "Simara Airport",
    "city": "Simara",
    "state": "Central",
    "country": "Nepal",
    "woeid": "12742210",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3740",
    "elev": "450",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SIG",
    "lat": "18.4558",
    "lon": "-66.0981",
    "name": "Isla Grande Airport",
    "city": "San Juan",
    "state": "Puerto Rico",
    "country": "United States",
    "woeid": "12515661",
    "tz": "America\/Puerto_Rico",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5317",
    "elev": "10",
    "icao": "TJIG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SIN",
    "lat": "1.3578",
    "lon": "103.991",
    "name": "Singapore Changi Airport",
    "city": "Singapore",
    "state": "South East",
    "country": "Singapore",
    "woeid": "12517525",
    "tz": "Asia\/Singapore",
    "phone": "(65) 6542 1122",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.changiairport.com.sg\/",
    "runway_length": "13200",
    "elev": "65",
    "icao": "WSSS",
    "direct_flights": "108",
    "carriers": "80"
  },
  {
    "code": "SIP",
    "lat": "45.0153",
    "lon": "33.9941",
    "name": "Simferopol North Airport",
    "city": "Simferopol'",
    "state": "Krym, Avtonomna Respublika",
    "country": "Ukraine",
    "woeid": "12518424",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12158",
    "elev": "637",
    "icao": "",
    "direct_flights": "29",
    "carriers": "26"
  },
  {
    "code": "SIT",
    "lat": "57.0535",
    "lon": "-135.366",
    "name": "Sitka Airport",
    "city": "Sitka",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12521866",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "21",
    "icao": "PASI",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "SJC",
    "lat": "37.3679",
    "lon": "-121.926",
    "name": "Norman Y Mineta San Jose International Airport",
    "city": "San Jose",
    "state": "California",
    "country": "United States",
    "woeid": "12521722",
    "tz": "America\/Los_Angeles",
    "phone": "(408) 501-7600",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sjc.org",
    "runway_length": "11000",
    "elev": "56",
    "icao": "KSJC",
    "direct_flights": "34",
    "carriers": "33"
  },
  {
    "code": "SJD",
    "lat": "23.1564",
    "lon": "-109.723",
    "name": "Los Cabos International Airport",
    "city": "S. Jose del Cabo",
    "state": "Baja California Sur",
    "country": "Mexico",
    "woeid": "12514915",
    "tz": "America\/Mazatlan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "358",
    "icao": "MMSD",
    "direct_flights": "26",
    "carriers": "21"
  },
  {
    "code": "SJE",
    "lat": "2.59328",
    "lon": "-72.6081",
    "name": "San Jose del Guaviaro Airport",
    "city": "San Jose del Guavuare",
    "state": "Guavaire",
    "country": "Colombia",
    "woeid": "12524511",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6479",
    "elev": "520",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SJI",
    "lat": "12.3611",
    "lon": "121.047",
    "name": "San Jose Airport",
    "city": "San Jose",
    "state": "Mimaropa",
    "country": "Philippines",
    "woeid": "12515646",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "SJJ",
    "lat": "43.8247",
    "lon": "18.3361",
    "name": "Sarajevo Airport",
    "city": "Ilid\u017ea",
    "state": "Federacija Bosne I Hercegovine",
    "country": "Bosnia and Herzegovina",
    "woeid": "12510901",
    "tz": "Europe\/Belgrade",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "1708",
    "icao": "LQSA",
    "direct_flights": "18",
    "carriers": "13"
  },
  {
    "code": "SJK",
    "lat": "-23.2283",
    "lon": "-45.8706",
    "name": "Sao Jose dos Campos Airport",
    "city": "Sao Jose dos Campos",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511318",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "2129",
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "SJO",
    "lat": "9.9919",
    "lon": "-84.2114",
    "name": "Juan Santamaria International Airport",
    "city": "Heredia",
    "state": "Alajuela",
    "country": "Costa Rica",
    "woeid": "12512434",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9882",
    "elev": "3046",
    "icao": "MROC",
    "direct_flights": "42",
    "carriers": "28"
  },
  {
    "code": "SJP",
    "lat": "-20.8156",
    "lon": "-49.4042",
    "name": "Sao Jose do Rio Preto Airport",
    "city": "S\u00e3o Jos\u00e9 do Rio Pr\u00eato",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511317",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "1778",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SJT",
    "lat": "31.3616",
    "lon": "-100.507",
    "name": "Mathis Field Airport",
    "city": "San Angelo",
    "state": "Texas",
    "country": "United States",
    "woeid": "12520840",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6920",
    "elev": "1916",
    "icao": "KSJT",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "SJU",
    "lat": "18.4367",
    "lon": "-66.0095",
    "name": "Luis Munoz Marin Airport",
    "city": "Carolina",
    "state": "Puerto Rico",
    "country": "United States",
    "woeid": "23388351",
    "tz": "America\/Puerto_Rico",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10002",
    "elev": "10",
    "icao": "TJSJ",
    "direct_flights": "60",
    "carriers": "37"
  },
  {
    "code": "SJW",
    "lat": "38.0492",
    "lon": "114.489",
    "name": "Shijiazhuang",
    "city": "Shijiazhuang",
    "state": "Hebei",
    "country": "China",
    "woeid": "2171287",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "19",
    "carriers": "8"
  },
  {
    "code": "SJY",
    "lat": "62.6937",
    "lon": "22.8289",
    "name": "Ilmajoki Airport",
    "city": "Seinajoki",
    "state": "Western Finland",
    "country": "Finland",
    "woeid": "12512768",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5052",
    "elev": "302",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SJZ",
    "lat": "38.6653",
    "lon": "-28.175",
    "name": "Sao Jorge Airport",
    "city": "Velas",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515458",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SKB",
    "lat": "17.3128",
    "lon": "-62.7178",
    "name": "Golden Rock Airport",
    "city": "Basseterre",
    "state": "Saint George Basseterre",
    "country": "Saint Kitts and Nevis",
    "woeid": "12517381",
    "tz": "America\/St_Kitts",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8002",
    "elev": "170",
    "icao": "TKPK",
    "direct_flights": "10",
    "carriers": "6"
  },
  {
    "code": "SKC",
    "lat": "-6.65619",
    "lon": "145.859",
    "name": "Papua New Guinea",
    "city": "Suki",
    "state": "",
    "country": "Papua New Guinea",
    "woeid": "23424926",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2130",
    "elev": "63",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SKD",
    "lat": "39.6667",
    "lon": "66.9667",
    "name": "Samarqand",
    "city": "Samarkand",
    "state": "Samarqand",
    "country": "Uzbekistan",
    "woeid": "2272628",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "2201",
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "SKE",
    "lat": "59.1844",
    "lon": "9.56938",
    "name": "Geiterygen Airport",
    "city": "Skien",
    "state": "Telemark Fylke",
    "country": "Norway",
    "woeid": "12515122",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4213",
    "elev": "463",
    "icao": "ENSN",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SKG",
    "lat": "40.5208",
    "lon": "22.9722",
    "name": "Thessaloniki Airport",
    "city": "Thessaloniki",
    "state": "Kentriki Makedonia",
    "country": "Greece",
    "woeid": "12513329",
    "tz": "Europe\/Athens",
    "phone": "+30 2310 473700 \/ 2310 4",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hcaa-eleng.gr\/thesdat.htm",
    "runway_length": "10",
    "elev": "22",
    "icao": "LGTS",
    "direct_flights": "57",
    "carriers": "34"
  },
  {
    "code": "SKH",
    "lat": "28.4908",
    "lon": "81.7689",
    "name": "Surkhet",
    "city": "Surkhet",
    "state": "Midwest",
    "country": "Nepal",
    "woeid": "23706779",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3480",
    "elev": "2400",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SKK",
    "lat": "64.3549",
    "lon": "-161.193",
    "name": "Shaktoolik",
    "city": "Shaktoolik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2491522",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2400",
    "elev": "22",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "SKN",
    "lat": "68.5802",
    "lon": "15.0325",
    "name": "Stokmarknes Airport",
    "city": "Stokkmarknes",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12515125",
    "tz": "Europe\/Oslo",
    "phone": "76 16 11 75",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "11",
    "icao": "ENSK",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "SKO",
    "lat": "12.9164",
    "lon": "5.2075",
    "name": "Sadiq Abubakar Iii Airport",
    "city": "Shunni",
    "state": "Sokoto",
    "country": "Nigeria",
    "woeid": "12515077",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "1006",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SKP",
    "lat": "41.9606",
    "lon": "21.6217",
    "name": "Petrovec",
    "city": "Skopje",
    "state": "Ilinden",
    "country": "Macedonia",
    "woeid": "12514772",
    "tz": "Europe\/Belgrade",
    "phone": "++ (389 2) 3148 300",
    "type": "Airports",
    "email": "skpap@airports.com.mk",
    "url": "http:\/\/www.airports.com.mk",
    "runway_length": "8038",
    "elev": "781",
    "icao": "LWSK",
    "direct_flights": "16",
    "carriers": "17"
  },
  {
    "code": "SKT",
    "lat": "32.5194",
    "lon": "74.5555",
    "name": "Sialkot",
    "city": "Sialkot",
    "state": "Punjab",
    "country": "Pakistan",
    "woeid": "2211467",
    "tz": "Asia\/Karachi",
    "phone": "0092 52 3555333",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3",
    "elev": "45",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SKU",
    "lat": "38.9683",
    "lon": "24.4881",
    "name": "Skiros Airport",
    "city": "Skiros",
    "state": "Sterea Ellada",
    "country": "Greece",
    "woeid": "12513323",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9840",
    "elev": "48",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SKZ",
    "lat": "27.7236",
    "lon": "68.7903",
    "name": "Sukkur Airport",
    "city": "Sukkur",
    "state": "Sindh",
    "country": "Pakistan",
    "woeid": "12515270",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "191",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SLA",
    "lat": "-24.8589",
    "lon": "-65.4756",
    "name": "Salta Airport",
    "city": "La Caldera",
    "state": "Salta",
    "country": "Argentina",
    "woeid": "12510549",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "4078",
    "icao": "SASA",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "SLC",
    "lat": "40.7862",
    "lon": "-111.982",
    "name": "Salt Lake City International Airport",
    "city": "Salt Lake City",
    "state": "Utah",
    "country": "United States",
    "woeid": "12521709",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12003",
    "elev": "4226",
    "icao": "KSLC",
    "direct_flights": "104",
    "carriers": "38"
  },
  {
    "code": "SLE",
    "lat": "44.9104",
    "lon": "-123.008",
    "name": "Mcnary Field Airport",
    "city": "Salem",
    "state": "Oregon",
    "country": "United States",
    "woeid": "12520877",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5811",
    "elev": "210",
    "icao": "KSLE",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SLH",
    "lat": "-14.2459",
    "lon": "167.509",
    "name": "Torba",
    "city": "Sola",
    "state": "Torba",
    "country": "Vanuatu",
    "woeid": "20069883",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2296",
    "elev": "7",
    "icao": "KSLH",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "SLI",
    "lat": "-12.1722",
    "lon": "26.3667",
    "name": "",
    "city": "Solwezi",
    "state": "North-Western",
    "country": "Zambia",
    "woeid": "1575642",
    "tz": "Africa\/Lusaka",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "4399",
    "elev": "4547",
    "icao": "KSLI",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SLK",
    "lat": "44.3804",
    "lon": "-74.2038",
    "name": "Adirondack Regional Airport",
    "city": "Saranac Lake",
    "state": "New York",
    "country": "United States",
    "woeid": "12518529",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6573",
    "elev": "1663",
    "icao": "KSLK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SLL",
    "lat": "17.0411",
    "lon": "54.0944",
    "name": "Salalah Airport",
    "city": "Salalah",
    "state": "Zufar",
    "country": "Oman",
    "woeid": "12514812",
    "tz": "Asia\/Muscat",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "15154",
    "elev": "73",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SLM",
    "lat": "40.9465",
    "lon": "-5.50744",
    "name": "Salamanca Airport",
    "city": "Villagonzalo de Tormes",
    "state": "Castille and Leon",
    "country": "Spain",
    "woeid": "12517566",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "2602",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "SLN",
    "lat": "38.7786",
    "lon": "-97.6397",
    "name": "Salina Municipal Airport",
    "city": "Salina",
    "state": "Kansas",
    "country": "United States",
    "woeid": "12521703",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13332",
    "elev": "1272",
    "icao": "KSLN",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "SLP",
    "lat": "22.2539",
    "lon": "-100.927",
    "name": "San Luis Potosi Airport",
    "city": "S. Luis Potosi",
    "state": "San Luis Potosi",
    "country": "Mexico",
    "woeid": "12514959",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "6070",
    "icao": "",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "SLQ",
    "lat": "61.7",
    "lon": "-157.117",
    "name": "Sleetmute Airport",
    "city": "Sleetmute",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2494667",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "225",
    "icao": "PASL",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SLU",
    "lat": "14.0192",
    "lon": "-60.9928",
    "name": "Vigie Airport",
    "city": "St Lucia",
    "state": "Castries",
    "country": "St. Lucia",
    "woeid": "12517596",
    "tz": "America\/St_Lucia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5700",
    "elev": "8",
    "icao": "TLPC",
    "direct_flights": "9",
    "carriers": "3"
  },
  {
    "code": "SLV",
    "lat": "31.0778",
    "lon": "77.0728",
    "name": "Simla Airport",
    "city": "Jutogh",
    "state": "Himachal Pradesh",
    "country": "India",
    "woeid": "12513671",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SLW",
    "lat": "25.5467",
    "lon": "-100.929",
    "name": "Plan de Guadalupe Airport",
    "city": "General Cepeda",
    "state": "Coahuila de Zaragoza",
    "country": "Mexico",
    "woeid": "12514935",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8251",
    "elev": "4600",
    "icao": "MMIO",
    "direct_flights": "3",
    "carriers": "6"
  },
  {
    "code": "SLX",
    "lat": "21.3223",
    "lon": "-71.1983",
    "name": "Salt Cay Airport",
    "city": "Salt Cay",
    "state": "Salt Cay",
    "country": "Turks And Caicos Islands",
    "woeid": "12517844",
    "tz": "America\/Grand_Turk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2900",
    "elev": "5",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SLY",
    "lat": "59.4615",
    "lon": "108.832",
    "name": "Russia",
    "city": "Salekhard",
    "state": "Yamalo-Nenetskiy Avtonomnyy Okru",
    "country": "Russia",
    "woeid": "23424936",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "2"
  },
  {
    "code": "SLZ",
    "lat": "-2.5867",
    "lon": "-44.2358",
    "name": "Marechal Cunha Machado International Airport",
    "city": "Salvador",
    "state": "Nordeste",
    "country": "Brazil",
    "woeid": "12511227",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7710",
    "elev": "177",
    "icao": "",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "SMA",
    "lat": "36.9658",
    "lon": "-25.1714",
    "name": "Santa Maria Airport",
    "city": "Vila do Porto",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515457",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "305",
    "icao": "LPAZ",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SMF",
    "lat": "38.683",
    "lon": "-121.591",
    "name": "Sacramento International Airport",
    "city": "Sacramento",
    "state": "California",
    "country": "United States",
    "woeid": "12521693",
    "tz": "America\/Los_Angeles",
    "phone": "916 929 5151",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sacairports.org",
    "runway_length": "8600",
    "elev": "23",
    "icao": "KSMF",
    "direct_flights": "37",
    "carriers": "29"
  },
  {
    "code": "SMI",
    "lat": "37.6903",
    "lon": "26.9128",
    "name": "Samos Airport",
    "city": "Pithagorion",
    "state": "Voreio Aigaio",
    "country": "Greece",
    "woeid": "12513320",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6660",
    "elev": "20",
    "icao": "",
    "direct_flights": "19",
    "carriers": "7"
  },
  {
    "code": "SMK",
    "lat": "63.4735",
    "lon": "-162.052",
    "name": "Alaska",
    "city": "St Michael",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2700",
    "elev": "25",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "SML",
    "lat": "23.5833",
    "lon": "-75.2681",
    "name": "Stella Maris Airport",
    "city": "Simms",
    "state": "Long Island",
    "country": "Bahamas",
    "woeid": "12510883",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "10",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SMN",
    "lat": "45.1148",
    "lon": "-113.888",
    "name": "Lemhi County Airport",
    "city": "Salmon",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12520625",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5150",
    "elev": "4045",
    "icao": "KSMN",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SMR",
    "lat": "11.1221",
    "lon": "-74.2262",
    "name": "Simon Bolivar Airport",
    "city": "",
    "state": "Magdalena",
    "country": "Colombia",
    "woeid": "12512418",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5576",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SMS",
    "lat": "-17.0939",
    "lon": "49.8158",
    "name": "Sainte Marie Airport",
    "city": "Toamasina",
    "state": "Toamasina",
    "country": "Madagascar",
    "woeid": "12514707",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3363",
    "elev": "7",
    "icao": "KSMS",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SMX",
    "lat": "34.8888",
    "lon": "-120.437",
    "name": "Santa Maria Public Airport",
    "city": "Santa Maria",
    "state": "California",
    "country": "United States",
    "woeid": "12521743",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6300",
    "elev": "259",
    "icao": "KSMX",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SNA",
    "lat": "33.6813",
    "lon": "-117.859",
    "name": "John Wayne Airport",
    "city": "Santa Ana",
    "state": "California",
    "country": "United States",
    "woeid": "12520383",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5700",
    "elev": "54",
    "icao": "KSNA",
    "direct_flights": "19",
    "carriers": "21"
  },
  {
    "code": "SNC",
    "lat": "-2.2",
    "lon": "-80.985",
    "name": "General Ulpiano Paez Airport",
    "city": "Salinas",
    "state": "Guayas",
    "country": "Ecuador",
    "woeid": "12512632",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7996",
    "elev": "14",
    "icao": "KSNC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SNE",
    "lat": "16.5869",
    "lon": "-24.2886",
    "name": "Preguica Airport",
    "city": "Ribeira Brava",
    "state": "Sao Nicolau",
    "country": "Cape Verde",
    "woeid": "12512493",
    "tz": "Atlantic\/Cape_Verde",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "500",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SNN",
    "lat": "52.6931",
    "lon": "-8.92047",
    "name": "Shannon Airport",
    "city": "Shannon Airport",
    "state": "",
    "country": "Ireland",
    "woeid": "12512726",
    "tz": "Europe\/Dublin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.shannonairport.com\/",
    "runway_length": "10499",
    "elev": "47",
    "icao": "EINN",
    "direct_flights": "41",
    "carriers": "15"
  },
  {
    "code": "SNO",
    "lat": "17.2847",
    "lon": "104.107",
    "name": "Sakon Nakhon Airport",
    "city": "Sakon Nakhon",
    "state": "Sakon Nakhon",
    "country": "Thailand",
    "woeid": "12517781",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "554",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SNP",
    "lat": "57.1667",
    "lon": "-170.217",
    "name": "Saint Paul Island",
    "city": "Saint Paul Island",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799712",
    "tz": "America\/Adak",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5175",
    "elev": "44",
    "icao": "PASN",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SNR",
    "lat": "47.3121",
    "lon": "-2.15411",
    "name": "Montoir Airport",
    "city": "Montoir-de-Bretagne",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "12512944",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SNU",
    "lat": "22.4922",
    "lon": "-79.9408",
    "name": "Santa Clara Airport",
    "city": "Esperanza",
    "state": "Villa Clara",
    "country": "Cuba",
    "woeid": "12512488",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "328",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SNW",
    "lat": "18.4587",
    "lon": "94.3696",
    "name": "Myanmar",
    "city": "Thandwe",
    "state": "Rakhine State",
    "country": "Myanmar",
    "woeid": "23424763",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "SOB",
    "lat": "46.6998",
    "lon": "17.1494",
    "name": "Sarmellek Airport",
    "city": "Zalav\u00e1r",
    "state": "Zala",
    "country": "Hungary",
    "woeid": "12513427",
    "tz": "Europe\/Budapest",
    "phone": "+36 83 355 500",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "407",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "SOC",
    "lat": "-7.5181",
    "lon": "110.751",
    "name": "Adi Sumarmo Wiryokusumo Airport",
    "city": "Surakarta",
    "state": "Jawa Tengah",
    "country": "Indonesia",
    "woeid": "12513450",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "348",
    "icao": "",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "SOF",
    "lat": "42.6895",
    "lon": "23.4024",
    "name": "Vrazhdebna Airport",
    "city": "Sofia",
    "state": "Sofiya-Grad",
    "country": "Bulgaria",
    "woeid": "12511552",
    "tz": "Europe\/Sofia",
    "phone": "+35 (92) 937-2211",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sofia-airport.bg",
    "runway_length": "9186",
    "elev": "1742",
    "icao": "LBSF",
    "direct_flights": "49",
    "carriers": "39"
  },
  {
    "code": "SOG",
    "lat": "61.1566",
    "lon": "7.13623",
    "name": "Haukasen Airport",
    "city": "Kaupanger",
    "state": "Sogn og Fjordane Fylke",
    "country": "Norway",
    "woeid": "12523948",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "1632",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "SOJ",
    "lat": "69.7833",
    "lon": "20.9333",
    "name": "Sorkjosen Airport",
    "city": "S\u00f8rkjosen",
    "state": "Troms Fylke",
    "country": "Norway",
    "woeid": "12523949",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "19",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SOM",
    "lat": "8.9489",
    "lon": "-64.1478",
    "name": "San Tome Airport",
    "city": "Cantaura",
    "state": "Anzoategui",
    "country": "Venezuela",
    "woeid": "12522844",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "846",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SON",
    "lat": "-15.5094",
    "lon": "167.214",
    "name": "Santo Pekoa International Airport",
    "city": "",
    "state": "Sanma",
    "country": "Vanuatu",
    "woeid": "12515053",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "184",
    "icao": "",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "SOO",
    "lat": "61.2698",
    "lon": "17.0906",
    "name": "Soderhamn Airport",
    "city": "Soderhamn",
    "state": "Gavleborg",
    "country": "Sweden",
    "woeid": "12517680",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "85",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SOQ",
    "lat": "-0.9326",
    "lon": "131.12",
    "name": "Jefman Airport",
    "city": "Sorong",
    "state": "West Irian Jaya",
    "country": "Indonesia",
    "woeid": "12513476",
    "tz": "Asia\/Jayapura",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5413",
    "elev": "10",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "SOU",
    "lat": "50.9512",
    "lon": "-1.36139",
    "name": "Southampton International Airport",
    "city": "Southampton",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22488616",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5653",
    "elev": "44",
    "icao": "EGHI",
    "direct_flights": "44",
    "carriers": "9"
  },
  {
    "code": "SOV",
    "lat": "59.4428",
    "lon": "-151.705",
    "name": "",
    "city": "Seldovia",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2490645",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "29",
    "icao": "PASO",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SOW",
    "lat": "34.2595",
    "lon": "-110.005",
    "name": "Show Low Municipal Airport",
    "city": "Show Low",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12521843",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "6010",
    "elev": "6411",
    "icao": "KSOW",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SPB",
    "lat": "18.3409",
    "lon": "-64.9698",
    "name": "St Thomas Seaplane Base",
    "city": "Charlotte Amalie",
    "state": "US Virgin Islands",
    "country": "United States",
    "woeid": "12523060",
    "tz": "America\/St_Thomas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KSPB",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SPC",
    "lat": "28.6676",
    "lon": "-17.7708",
    "name": "Santa Cruz de la Palma Airport",
    "city": "Bre\u00f1a Alta",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "12523225",
    "tz": "Atlantic\/Canary",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "95",
    "icao": "GCLA",
    "direct_flights": "17",
    "carriers": "10"
  },
  {
    "code": "SPD",
    "lat": "25.7625",
    "lon": "88.91",
    "name": "Saidpur Airport",
    "city": "Dinajpur",
    "state": "Rajshahi",
    "country": "Bangladesh",
    "woeid": "12510894",
    "tz": "Asia\/Dhaka",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "125",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SPI",
    "lat": "39.8445",
    "lon": "-89.672",
    "name": "Capital Airport",
    "city": "Springfield",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12519058",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7999",
    "elev": "597",
    "icao": "KSPI",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "SPL",
    "lat": "36.9658",
    "lon": "-25.1714",
    "name": "Santa Maria Airport",
    "city": "Vila do Porto",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12524514",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SPN",
    "lat": "15.1333",
    "lon": "145.717",
    "name": "Saipan International Airport",
    "city": "Saipan",
    "state": "Northern Mariana Islands",
    "country": "United States",
    "woeid": "12523226",
    "tz": "Pacific\/Saipan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8700",
    "elev": "215",
    "icao": "PGSN",
    "direct_flights": "10",
    "carriers": "6"
  },
  {
    "code": "SPP",
    "lat": "-14.6542",
    "lon": "17.7247",
    "name": "Menongue East Airport",
    "city": "Menongue",
    "state": "Cuando Cubango",
    "country": "Angola",
    "woeid": "12510445",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "4616",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "SPR",
    "lat": "17.9167",
    "lon": "-87.9667",
    "name": "San Pedro Airport",
    "city": "San Pedro",
    "state": "Belize",
    "country": "Belize",
    "woeid": "12524036",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2575",
    "elev": "2",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "SPS",
    "lat": "33.9862",
    "lon": "-98.4984",
    "name": "Sheppard Air Force Base",
    "city": "Wichita Falls",
    "state": "Texas",
    "country": "United States",
    "woeid": "12521831",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "13100",
    "elev": "1015",
    "icao": "KSPS",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SPU",
    "lat": "43.5406",
    "lon": "16.3",
    "name": "Split Airport",
    "city": "Split",
    "state": "Splitsko-Dalmatinska",
    "country": "Croatia",
    "woeid": "12513372",
    "tz": "Europe\/Belgrade",
    "phone": "+385 21 203506",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.split-airport.hrhttp:\/\/www.split-airport.hr",
    "runway_length": "8366",
    "elev": "79",
    "icao": "LDSP",
    "direct_flights": "37",
    "carriers": "20"
  },
  {
    "code": "SQO",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Storuman",
    "state": null,
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SRA",
    "lat": "-27.9083",
    "lon": "-54.5217",
    "name": "Santa Rosa Airport",
    "city": "Santa Rosa",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511306",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "935",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SRE",
    "lat": "-19.0008",
    "lon": "-65.2878",
    "name": "Juana Azurduy de Padilla Airport",
    "city": "Sucre",
    "state": "Chuquisaca",
    "country": "Bolivia",
    "woeid": "12510909",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9300",
    "elev": "9527",
    "icao": "KSRE",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SRG",
    "lat": "-6.9758",
    "lon": "110.38",
    "name": "Achmad Yani Airport",
    "city": "Semarang",
    "state": "Jawa Tengah",
    "country": "Indonesia",
    "woeid": "12513449",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5414",
    "elev": "10",
    "icao": "WARS",
    "direct_flights": "4",
    "carriers": "6"
  },
  {
    "code": "SRJ",
    "lat": "-14.8573",
    "lon": "-66.7383",
    "name": "Capitan G Q Guardia",
    "city": "San Borja",
    "state": "Santa Cruz",
    "country": "Bolivia",
    "woeid": "12524445",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "636",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SRP",
    "lat": "59.7928",
    "lon": "5.33972",
    "name": "Sorstukken Airport",
    "city": "Skjold",
    "state": "Rogaland Fylke",
    "country": "Norway",
    "woeid": "12523950",
    "tz": "Europe\/Oslo",
    "phone": "+ 47 53 40 37 10",
    "type": "Airports",
    "email": "admin@stordlufthavn.no",
    "url": "http:\/\/www.stordlufthavn.no",
    "runway_length": "3936",
    "elev": "160",
    "icao": "ENSA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SRQ",
    "lat": "27.3868",
    "lon": "-82.5516",
    "name": "Sarasota Bradenton Airport",
    "city": "Sarasota",
    "state": "Florida",
    "country": "United States",
    "woeid": "12521750",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7003",
    "elev": "28",
    "icao": "KSRQ",
    "direct_flights": "14",
    "carriers": "14"
  },
  {
    "code": "SRV",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Stony River",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2950",
    "elev": "230",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "SRX",
    "lat": "31.206",
    "lon": "16.5924",
    "name": "Surt",
    "city": "Sert",
    "state": "Surt",
    "country": "Libya",
    "woeid": "1353260",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9250",
    "elev": "40",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SRY",
    "lat": "36.635",
    "lon": "53.1933",
    "name": "",
    "city": "Sary",
    "state": "",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7550",
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SRZ",
    "lat": "-17.8072",
    "lon": "-63.1708",
    "name": "El Trompillo Airport",
    "city": "Santa Cruz de la Sierra",
    "state": "Santa Cruz",
    "country": "Bolivia",
    "woeid": "12510905",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "1224",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SSA",
    "lat": "-12.9081",
    "lon": "-38.3222",
    "name": "Deputado Luis Eduardo Magalhaes International Ai",
    "city": "Salvador",
    "state": "Nordeste",
    "country": "Brazil",
    "woeid": "12511112",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9865",
    "elev": "60",
    "icao": "SBSV",
    "direct_flights": "24",
    "carriers": "14"
  },
  {
    "code": "SSB",
    "lat": "17.75",
    "lon": "-64.7",
    "name": "Christiansted Harbor Seaplane Base",
    "city": "Christiansted",
    "state": "US Virgin Islands",
    "country": "United States",
    "woeid": "12523231",
    "tz": "America\/St_Thomas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "1",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SSG",
    "lat": "3.7472",
    "lon": "8.7083",
    "name": "Malabo Airport",
    "city": "Malabo",
    "state": "Bioko Norte",
    "country": "Equatorial Guinea",
    "woeid": "12512730",
    "tz": "Africa\/Malabo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9646",
    "elev": "79",
    "icao": "FGSL",
    "direct_flights": "11",
    "carriers": "11"
  },
  {
    "code": "SSH",
    "lat": "27.975",
    "lon": "34.3897",
    "name": "Ras Nasrani Airport",
    "city": "Al Arish",
    "state": "Janub Sina'",
    "country": "Egypt",
    "woeid": "12512706",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9596",
    "elev": "150",
    "icao": "HESH",
    "direct_flights": "42",
    "carriers": "23"
  },
  {
    "code": "SSJ",
    "lat": "65.9612",
    "lon": "12.474",
    "name": "Stokka Airport",
    "city": "Sandnessjoen",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523951",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2516",
    "elev": "54",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "SSR",
    "lat": "-15.1468",
    "lon": "167.038",
    "name": "Vanuatu",
    "city": "Sara",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1575",
    "elev": "371",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "SSY",
    "lat": "-6.2739",
    "lon": "14.2503",
    "name": "Mbanza Congo Airport",
    "city": "M'banza Congo",
    "state": "Zaire",
    "country": "Angola",
    "woeid": "12510444",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "STC",
    "lat": "45.5463",
    "lon": "-94.066",
    "name": "St Cloud Regional Airport",
    "city": "St. Cloud",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12521961",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "1024",
    "icao": "KSTC",
    "direct_flights": "1",
    "carriers": "4"
  },
  {
    "code": "STD",
    "lat": "7.57033",
    "lon": "-72.0443",
    "name": "Mayor Buenaventura Vivas Airport",
    "city": "Santa Ana del Tachira",
    "state": "Tachira",
    "country": "Venezuela",
    "woeid": "12522820",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5052",
    "elev": "1118",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "STG",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "St George Island",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Adak",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3050",
    "elev": "90",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "STI",
    "lat": "19.4692",
    "lon": "-70.7003",
    "name": "Cibao International Airport",
    "city": "La Lomota",
    "state": "Santiago",
    "country": "Dominican Republic",
    "woeid": "12512614",
    "tz": "America\/Santo_Domingo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5200",
    "elev": "597",
    "icao": "MDST",
    "direct_flights": "8",
    "carriers": "9"
  },
  {
    "code": "STL",
    "lat": "38.7414",
    "lon": "-90.3647",
    "name": "Lambert St Louis International Airport",
    "city": "St. Louis",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12520553",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11019",
    "elev": "605",
    "icao": "KSTL",
    "direct_flights": "78",
    "carriers": "46"
  },
  {
    "code": "STM",
    "lat": "-2.4244",
    "lon": "-54.7853",
    "name": "Santarem International Airport",
    "city": "Santar\u00e9m",
    "state": "Para",
    "country": "Brazil",
    "woeid": "12511308",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7881",
    "elev": "197",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "STN",
    "lat": "51.8894",
    "lon": "0.26151",
    "name": "London Stansted International Airport",
    "city": "Stansted Mountfitchet",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22489156",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.stanstedairport.com\/",
    "runway_length": "10000",
    "elev": "347",
    "icao": "EGSS",
    "direct_flights": "168",
    "carriers": "25"
  },
  {
    "code": "STR",
    "lat": "48.6895",
    "lon": "9.19298",
    "name": "Stuttgart Airport",
    "city": "Stuttgart",
    "state": "Baden-Wurttemberg",
    "country": "Germany",
    "woeid": "22236827",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "1300",
    "icao": "EDDS",
    "direct_flights": "116",
    "carriers": "51"
  },
  {
    "code": "STS",
    "lat": "38.511",
    "lon": "-122.805",
    "name": "Sonoma County Airport",
    "city": "Windsor",
    "state": "California",
    "country": "United States",
    "woeid": "12521904",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5115",
    "elev": "125",
    "icao": "KSTS",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "STT",
    "lat": "18.3333",
    "lon": "-64.9667",
    "name": "Cyril E King International Airport",
    "city": "Charlotte Amalie",
    "state": "US Virgin Islands",
    "country": "United States",
    "woeid": "12522972",
    "tz": "America\/St_Thomas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5348",
    "elev": "15",
    "icao": "TIST",
    "direct_flights": "17",
    "carriers": "12"
  },
  {
    "code": "STV",
    "lat": "21.1667",
    "lon": "72.8333",
    "name": "Surat Airport",
    "city": "Un",
    "state": "Gujarat",
    "country": "India",
    "woeid": "12513677",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "50",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "STW",
    "lat": "45.1104",
    "lon": "42.1089",
    "name": "Mikhaylovskoye Airport",
    "city": "Stavropol'",
    "state": "Stavropolrskiy Kray",
    "country": "Russia",
    "woeid": "12516431",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "STX",
    "lat": "17.6988",
    "lon": "-64.7977",
    "name": "Henry E Rohlson International Airport",
    "city": "Frederiksted",
    "state": "US Virgin Islands",
    "country": "United States",
    "woeid": "12522971",
    "tz": "America\/St_Thomas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7612",
    "elev": "61",
    "icao": "TISX",
    "direct_flights": "9",
    "carriers": "10"
  },
  {
    "code": "SUB",
    "lat": "-7.38",
    "lon": "112.785",
    "name": "Juanda Airport",
    "city": "Sidoarjo",
    "state": "Jawa Timur",
    "country": "Indonesia",
    "woeid": "12513477",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "9",
    "icao": "WARR",
    "direct_flights": "17",
    "carriers": "18"
  },
  {
    "code": "SUF",
    "lat": "38.9087",
    "lon": "16.2443",
    "name": "Lamezia Terme Airport",
    "city": "Case Cervi",
    "state": "Calabria",
    "country": "Italy",
    "woeid": "12513830",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lameziatermeairport.it",
    "runway_length": "7874",
    "elev": "41",
    "icao": "LICA",
    "direct_flights": "28",
    "carriers": "28"
  },
  {
    "code": "SUG",
    "lat": "9.7589",
    "lon": "125.479",
    "name": "Surigao Airport",
    "city": "Surigao City",
    "state": "Caraga",
    "country": "Philippines",
    "woeid": "12515648",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SUJ",
    "lat": "47.7134",
    "lon": "22.8887",
    "name": "Satu Mare Airport",
    "city": "Satu Mare",
    "state": "Satu Mare",
    "country": "Romania",
    "woeid": "12515569",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "400",
    "icao": "LRSM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SUN",
    "lat": "43.5065",
    "lon": "-114.301",
    "name": "Friedman Memorial Airport",
    "city": "Hailey",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12519829",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6600",
    "elev": "5313",
    "icao": "KSUN",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "SUR",
    "lat": "52.7086",
    "lon": "-88.5419",
    "name": "Summer Beaver",
    "city": "Summer Beaver",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "23399236",
    "tz": "America\/Nipigon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SUV",
    "lat": "-18.05",
    "lon": "178.567",
    "name": "Nausori International Airport",
    "city": "Nausori",
    "state": "Central",
    "country": "Fiji",
    "woeid": "12523233",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6053",
    "elev": "16",
    "icao": "",
    "direct_flights": "15",
    "carriers": "2"
  },
  {
    "code": "SUX",
    "lat": "42.4012",
    "lon": "-96.3784",
    "name": "Sioux Gateway Airport",
    "city": "Sioux City",
    "state": "Iowa",
    "country": "United States",
    "woeid": "12521862",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8999",
    "elev": "1098",
    "icao": "KSUX",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "SVA",
    "lat": "63.6951",
    "lon": "-170.465",
    "name": "Alaska",
    "city": "Savoonga",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "53",
    "icao": "PASA",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "SVB",
    "lat": "-14.2505",
    "lon": "50.1539",
    "name": "Sambava",
    "city": "Sambava",
    "state": "Antsiranana",
    "country": "Madagascar",
    "woeid": "1364128",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "16",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "SVC",
    "lat": "32.6301",
    "lon": "-108.164",
    "name": "Silver City-Grant County Airport",
    "city": "Silver City",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12521856",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6800",
    "elev": "5443",
    "icao": "KSVC",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "SVD",
    "lat": "13.1422",
    "lon": "-61.2111",
    "name": "E T Joshua Airport",
    "city": "Kingstown",
    "state": "Saint George",
    "country": "Saint Vincent and the Grenadines",
    "woeid": "12522749",
    "tz": "America\/St_Vincent",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "66",
    "icao": "TVSV",
    "direct_flights": "9",
    "carriers": "3"
  },
  {
    "code": "SVG",
    "lat": "58.8806",
    "lon": "5.63019",
    "name": "Stavanger Sola Airport",
    "city": "Rage",
    "state": "Rogaland Fylke",
    "country": "Norway",
    "woeid": "12515124",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "29",
    "icao": "ENZV",
    "direct_flights": "28",
    "carriers": "26"
  },
  {
    "code": "SVI",
    "lat": "2.1464",
    "lon": "-74.7622",
    "name": "San Vicente del Caguan Airport",
    "city": "San Vicente del Cagu\u00e1n",
    "state": "Caqueta",
    "country": "Colombia",
    "woeid": "12512411",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "920",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SVJ",
    "lat": "68.2441",
    "lon": "14.6669",
    "name": "Helle Airport",
    "city": "Svolvar",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523952",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2559",
    "elev": "30",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SVL",
    "lat": "61.9464",
    "lon": "28.9355",
    "name": "Savonlinna Airport",
    "city": "Savonlinna",
    "state": "Eastern Finland",
    "country": "Finland",
    "woeid": "12512788",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "312",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SVO",
    "lat": "55.9621",
    "lon": "37.4189",
    "name": "Sheremtyevo Airport",
    "city": "Zelenograd",
    "state": "Moskovskaya Oblast",
    "country": "Russia",
    "woeid": "12516860",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.sheremetyevo-airport.ru",
    "runway_length": "12139",
    "elev": "623",
    "icao": "UUEE",
    "direct_flights": "140",
    "carriers": "48"
  },
  {
    "code": "SVQ",
    "lat": "37.4224",
    "lon": "-5.89796",
    "name": "Sevilla Airport",
    "city": "Seville",
    "state": "Andalucia",
    "country": "Spain",
    "woeid": "12517571",
    "tz": "Europe\/Madrid",
    "phone": "+34 954 44 90 00",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11024",
    "elev": "112",
    "icao": "LEZL",
    "direct_flights": "40",
    "carriers": "20"
  },
  {
    "code": "SVS",
    "lat": "66.0044",
    "lon": "-149.091",
    "name": "Stevens Village",
    "city": "Stevens Village",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2499756",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "310",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SVU",
    "lat": "-16.8",
    "lon": "179.35",
    "name": "Savusavu Airport",
    "city": "Labasa",
    "state": "Northern",
    "country": "Fiji",
    "woeid": "12523234",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3300",
    "elev": "14",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "SVX",
    "lat": "56.7435",
    "lon": "60.7911",
    "name": "Koltsovo Airport",
    "city": "Yekaterinburg",
    "state": "Sverdlovskaya Oblast",
    "country": "Russia",
    "woeid": "12516207",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "USSS",
    "direct_flights": "49",
    "carriers": "30"
  },
  {
    "code": "SVZ",
    "lat": "7.8431",
    "lon": "-72.4381",
    "name": "San Antonio del Tachira Airport",
    "city": "T\u00e1riba",
    "state": "Tachira",
    "country": "Venezuela",
    "woeid": "12522839",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6070",
    "elev": "1312",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SWA",
    "lat": "23.425",
    "lon": "116.759",
    "name": "Shantou Northeast Airport",
    "city": "Chenghai",
    "state": "Guangdong",
    "country": "China",
    "woeid": "12512184",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "17",
    "carriers": "6"
  },
  {
    "code": "SWF",
    "lat": "41.4869",
    "lon": "-74.0974",
    "name": "Stewart International Airport",
    "city": "New Windsor",
    "state": "New York",
    "country": "United States",
    "woeid": "12522012",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11818",
    "elev": "491",
    "icao": "KSWF",
    "direct_flights": "9",
    "carriers": "8"
  },
  {
    "code": "SWJ",
    "lat": "-16.4917",
    "lon": "167.439",
    "name": "South West Bay Airport",
    "city": "South West Bay",
    "state": "Malampa",
    "country": "Vanuatu",
    "woeid": "12523327",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2887",
    "elev": "10",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SWK",
    "lat": "45.4738",
    "lon": "9.2986",
    "name": "Segrate Airport",
    "city": "Trezzano sul Naviglio",
    "state": "Lombardy",
    "country": "Italy",
    "woeid": "12523358",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SWO",
    "lat": "36.1618",
    "lon": "-97.0751",
    "name": "Stillwater Municipal Airport",
    "city": "Stillwater",
    "state": "Oklahoma",
    "country": "United States",
    "woeid": "12522014",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5002",
    "elev": "984",
    "icao": "KSWO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SWQ",
    "lat": "-8.4933",
    "lon": "117.421",
    "name": "Brang Bidji Airport",
    "city": "Sumbawa Besar",
    "state": "Nusa Tenggara Barat",
    "country": "Indonesia",
    "woeid": "12513511",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4754",
    "elev": "16",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SWS",
    "lat": "51.6006",
    "lon": "-4.07",
    "name": "Swansea Airport",
    "city": "Swansea",
    "state": "Wales",
    "country": "United Kingdom",
    "woeid": "12518159",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4829",
    "elev": "300",
    "icao": "EGFH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SXB",
    "lat": "48.5427",
    "lon": "7.63466",
    "name": "Entzheim Airport",
    "city": "Entzheim",
    "state": "Alsace",
    "country": "France",
    "woeid": "12512892",
    "tz": "Europe\/Paris",
    "phone": "+ 33 3 88 64 67 67",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.strasbourg.aeroport.fr",
    "runway_length": "7874",
    "elev": "502",
    "icao": "LFST",
    "direct_flights": "30",
    "carriers": "20"
  },
  {
    "code": "SXF",
    "lat": "52.3886",
    "lon": "13.5188",
    "name": "Berlin-Schonefeld International Airport",
    "city": "Sch\u00f6nefeld",
    "state": "Bundesland Brandenburg",
    "country": "Germany",
    "woeid": "22276234",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "154",
    "icao": "EDDB",
    "direct_flights": "85",
    "carriers": "21"
  },
  {
    "code": "SXL",
    "lat": "54.275",
    "lon": "-8.6",
    "name": "Sligo Airport",
    "city": "Larass",
    "state": "",
    "country": "Ireland",
    "woeid": "12512727",
    "tz": "Europe\/Dublin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "20",
    "icao": "EISG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SXM",
    "lat": "18.0421",
    "lon": "-63.1107",
    "name": "Prinses Juliana International Airport",
    "city": "",
    "state": "St Maarten",
    "country": "Netherlands Antilles",
    "woeid": "12515140",
    "tz": "America\/Curacao",
    "phone": "+599 545 2060",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.pjiae.com\/",
    "runway_length": "7054",
    "elev": "13",
    "icao": "TNCM",
    "direct_flights": "32",
    "carriers": "23"
  },
  {
    "code": "SXP",
    "lat": "62.5333",
    "lon": "-164.833",
    "name": "Sheldon SPB",
    "city": "Sheldon Point",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2492171",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "80",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "SXR",
    "lat": "33.9833",
    "lon": "74.7833",
    "name": "Srinagar Airport",
    "city": "Srinagar",
    "state": "Jammu and Kashmir",
    "country": "India",
    "woeid": "12513675",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12040",
    "elev": "5458",
    "icao": "",
    "direct_flights": "3",
    "carriers": "7"
  },
  {
    "code": "SYB",
    "lat": "58.1667",
    "lon": "-152.5",
    "name": "Seal Bay Airport",
    "city": "Kodiak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524652",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SYD",
    "lat": "-33.9344",
    "lon": "151.168",
    "name": "Kingsford Smith Airport",
    "city": "Sydney",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "23388205",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13000",
    "elev": "21",
    "icao": "YSSY",
    "direct_flights": "84",
    "carriers": "55"
  },
  {
    "code": "SYJ",
    "lat": "29.5504",
    "lon": "55.6708",
    "name": "",
    "city": "Sirjan",
    "state": "Kerman",
    "country": "Iran",
    "woeid": "2255214",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SYM",
    "lat": "22.8333",
    "lon": "101",
    "name": "Simao",
    "city": "Simao",
    "state": "Yunnan",
    "country": "China",
    "woeid": "2160700",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "SYO",
    "lat": "38.7061",
    "lon": "140.018",
    "name": "Shonai",
    "city": "Shonai",
    "state": "Yamagata Prefecture",
    "country": "Japan",
    "woeid": "28379163",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "SYR",
    "lat": "43.1141",
    "lon": "-76.1121",
    "name": "Hancock International Airport",
    "city": "Syracuse",
    "state": "New York",
    "country": "United States",
    "woeid": "12520075",
    "tz": "America\/New_York",
    "phone": "(315) 454-4330",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.syrairport.org",
    "runway_length": "9003",
    "elev": "421",
    "icao": "KSYR",
    "direct_flights": "21",
    "carriers": "26"
  },
  {
    "code": "SYU",
    "lat": "-31.875",
    "lon": "136.081",
    "name": "Australia",
    "city": "Sue Island",
    "state": "",
    "country": "Australia",
    "woeid": "23424748",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "SYX",
    "lat": "18.281",
    "lon": "109.498",
    "name": "Sanya",
    "city": "Sanya",
    "state": "Hainan",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "23",
    "carriers": "21"
  },
  {
    "code": "SYY",
    "lat": "58.2119",
    "lon": "-6.32319",
    "name": "Stornoway Airport",
    "city": "Isle of lewis",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22472653",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hial.co.uk\/stornoway-airport.html",
    "runway_length": "7214",
    "elev": "30",
    "icao": "EGPO",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "SYZ",
    "lat": "29.54",
    "lon": "52.5897",
    "name": "Shiraz International Airport",
    "city": "Shiraz",
    "state": "Fars",
    "country": "Iran",
    "woeid": "12513764",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "14009",
    "elev": "4912",
    "icao": "OISS",
    "direct_flights": "17",
    "carriers": "6"
  },
  {
    "code": "SZA",
    "lat": "-6.1389",
    "lon": "12.3764",
    "name": "Soyo Airport",
    "city": "Santo Ant\u00f3nio do Zaire",
    "state": "Zaire",
    "country": "Angola",
    "woeid": "12510454",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6857",
    "elev": "15",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "SZB",
    "lat": "3.1303",
    "lon": "101.551",
    "name": "Sultan Abdul Aziz Shah Airport",
    "city": "Kampong Baru Subang",
    "state": "Selangor",
    "country": "Malaysia",
    "woeid": "12515008",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12402",
    "elev": "89",
    "icao": "WMSA",
    "direct_flights": "15",
    "carriers": "3"
  },
  {
    "code": "SZD",
    "lat": "53.3957",
    "lon": "-1.38232",
    "name": "Sheffield City Airport",
    "city": "Sheffield",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22656550",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "EGSY",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "SZF",
    "lat": "41.1959",
    "lon": "36.7248",
    "name": "\u00c7ar\u015famba",
    "city": "Samsun",
    "state": "Samsun",
    "country": "Turkey",
    "woeid": "2343864",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "SZG",
    "lat": "47.7937",
    "lon": "13.0043",
    "name": "Salzburg Airport",
    "city": "Salzburg",
    "state": "Salzburg",
    "country": "Austria",
    "woeid": "12510826",
    "tz": "Europe\/Vienna",
    "phone": "+43(0)66285800",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.salzburg-airport.com",
    "runway_length": "8366",
    "elev": "1411",
    "icao": "",
    "direct_flights": "39",
    "carriers": "26"
  },
  {
    "code": "SZV",
    "lat": "31.3092",
    "lon": "120.613",
    "name": "China",
    "city": "Suzhou",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "SZX",
    "lat": "22.5333",
    "lon": "113.967",
    "name": "Shenzhen Airport",
    "city": "Shenzhen",
    "state": "Guangdong",
    "country": "China",
    "woeid": "12523239",
    "tz": "Asia\/Shanghai",
    "phone": "0086-755-27776047",
    "type": "Airports",
    "email": "sacabd@public.szptt.net.cn",
    "url": "http:\/\/www.szairport.com\/index_e.asp",
    "runway_length": "3400",
    "elev": "3",
    "icao": "",
    "direct_flights": "81",
    "carriers": "31"
  },
  {
    "code": "SZZ",
    "lat": "53.5686",
    "lon": "14.8676",
    "name": "Golenow Airport",
    "city": "Goleni\u00f3w",
    "state": "Zachodniopomorskie",
    "country": "Poland",
    "woeid": "12515303",
    "tz": "Europe\/Warsaw",
    "phone": "+48 91 4817400",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport.szczecin.pl",
    "runway_length": "8202",
    "elev": "154",
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "TAB",
    "lat": "11.25",
    "lon": "-60.6667",
    "name": "Crown Point Airport",
    "city": "Plymouth",
    "state": "Tobago",
    "country": "Trinidad and Tobago",
    "woeid": "12517743",
    "tz": "America\/Port_of_Spain",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.TobagoAirport.com",
    "runway_length": "9002",
    "elev": "38",
    "icao": "TTCP",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "TAC",
    "lat": "11.2283",
    "lon": "125.023",
    "name": "Daniel Z Romualdez Airport",
    "city": "Tacloban City",
    "state": "Eastern Visayas",
    "country": "Philippines",
    "woeid": "12515611",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "TAE",
    "lat": "35.9007",
    "lon": "128.641",
    "name": "Daegu International Airport",
    "city": "Daegu",
    "state": "Daegu",
    "country": "South Korea",
    "woeid": "12514232",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "7"
  },
  {
    "code": "TAG",
    "lat": "9.6644",
    "lon": "123.852",
    "name": "Tagbilaran Airport",
    "city": "Tagbilaran City",
    "state": "Central Visayas",
    "country": "Philippines",
    "woeid": "12515649",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TAH",
    "lat": "-19.4867",
    "lon": "169.362",
    "name": "Tanna Airport",
    "city": "Isangel",
    "state": "Tafea",
    "country": "Vanuatu",
    "woeid": "12515054",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "19",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "TAI",
    "lat": "13.6889",
    "lon": "44.1375",
    "name": "Taiz Ganed Airport",
    "city": "Al-Ganad",
    "state": "Ta`izz",
    "country": "Yemen",
    "woeid": "12523006",
    "tz": "Asia\/Aden",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "TAK",
    "lat": "34.2557",
    "lon": "134.046",
    "name": "Japan",
    "city": "Takamatsu",
    "state": "Kagawa Prefecture",
    "country": "Japan",
    "woeid": "23424856",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "TAL",
    "lat": "47.7184",
    "lon": "-115.431",
    "name": "Ralph Calhoun",
    "city": "Tanana",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2503916",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PATA",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "TAM",
    "lat": "22.2881",
    "lon": "-97.8633",
    "name": "Gen Francisco J Mina International Airport",
    "city": "Tampico",
    "state": "Tamaulipas",
    "country": "Mexico",
    "woeid": "12514875",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MMTM",
    "direct_flights": "8",
    "carriers": "12"
  },
  {
    "code": "TAO",
    "lat": "36.2311",
    "lon": "120.389",
    "name": "Liuting Airport",
    "city": "Wanggezhuang",
    "state": "Shandong",
    "country": "China",
    "woeid": "12512130",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZSQD",
    "direct_flights": "38",
    "carriers": "23"
  },
  {
    "code": "TAP",
    "lat": "14.7931",
    "lon": "-92.37",
    "name": "Tapachula International Airport",
    "city": "Tapachula",
    "state": "Chiapas",
    "country": "Mexico",
    "woeid": "12514971",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MMTP",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "TAS",
    "lat": "41.2552",
    "lon": "69.2846",
    "name": "Tashkent South Airport",
    "city": "Tashkent",
    "state": "Toshkent",
    "country": "Uzbekistan",
    "woeid": "12522725",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13123",
    "elev": "1417",
    "icao": "UTTT",
    "direct_flights": "61",
    "carriers": "25"
  },
  {
    "code": "TAT",
    "lat": "49.0663",
    "lon": "20.2445",
    "name": "Poprad Tatry Airport",
    "city": "Poprad",
    "state": "Presovsky",
    "country": "Slovakia",
    "woeid": "12514622",
    "tz": "Europe\/Bratislava",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LZTT",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TBB",
    "lat": "13.0494",
    "lon": "109.334",
    "name": "Tuy Hoa Airport",
    "city": "Tuy Hoa",
    "state": "Phu Yen",
    "country": "Vietnam",
    "woeid": "1252649",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3048",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TBG",
    "lat": "-7.18482",
    "lon": "142.374",
    "name": "Western",
    "city": "Tabubil",
    "state": "Western",
    "country": "Papua New Guinea",
    "woeid": "2346586",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "1500",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "TBH",
    "lat": "12.3133",
    "lon": "122.079",
    "name": "Romblon Airport",
    "city": "Alcantara",
    "state": "Mimaropa",
    "country": "Philippines",
    "woeid": "12515643",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TBI",
    "lat": "24.315",
    "lon": "-75.4536",
    "name": "The Bight Airport",
    "city": "New Bight",
    "state": "Cat Island",
    "country": "Bahamas",
    "woeid": "12510875",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "TBJ",
    "lat": "36.9777",
    "lon": "8.87678",
    "name": "Tabarka Airport",
    "city": "Jundobah",
    "state": "Jundubah",
    "country": "Tunisia",
    "woeid": "24554858",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2870",
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TBN",
    "lat": "37.7327",
    "lon": "-92.1361",
    "name": "Waynesville Regional Airport At Forney Field",
    "city": "Fort Leonard Wood",
    "state": "Missouri",
    "country": "United States",
    "woeid": "12519767",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6038",
    "elev": "1159",
    "icao": "KTBN",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TBO",
    "lat": "5.08333",
    "lon": "32.8333",
    "name": "",
    "city": "Tabora",
    "state": "Tabora",
    "country": "Tanzania",
    "woeid": "1449526",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13",
    "elev": "3920",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TBP",
    "lat": "-3.5494",
    "lon": "-80.3781",
    "name": "Pedro Canga Airport",
    "city": "Tumbes",
    "state": "Tumbes",
    "country": "Peru",
    "woeid": "12515214",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TBS",
    "lat": "41.6716",
    "lon": "44.9556",
    "name": "Tbilisi-Noyo Alekseyevka Airport",
    "city": "Tbilisi",
    "state": "Gardabanis Raioni",
    "country": "Georgia",
    "woeid": "12513015",
    "tz": "Asia\/Tbilisi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UGTB",
    "direct_flights": "34",
    "carriers": "25"
  },
  {
    "code": "TBU",
    "lat": "-21.2486",
    "lon": "-175.136",
    "name": "Fua'amotu International Airport",
    "city": "Nuku'alofa",
    "state": "Tongatapu",
    "country": "Tonga",
    "woeid": "12517847",
    "tz": "Pacific\/Tongatapu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "NFTF",
    "direct_flights": "8",
    "carriers": "6"
  },
  {
    "code": "TBW",
    "lat": "52.7056",
    "lon": "41.3686",
    "name": "",
    "city": "Tambov",
    "state": "Tambovskaya Oblast",
    "country": "Russia",
    "woeid": "2123828",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TBZ",
    "lat": "38.1322",
    "lon": "46.2431",
    "name": "Tabriz Airport",
    "city": "Tabriz",
    "state": "Azarbayjan-e Sharqi",
    "country": "Iran",
    "woeid": "12513767",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "OITT",
    "direct_flights": "7",
    "carriers": "7"
  },
  {
    "code": "TCB",
    "lat": "26.7461",
    "lon": "-77.3931",
    "name": "Treasure Cay Airport",
    "city": "Green Turtle Cay",
    "state": "North Abaco",
    "country": "Bahamas",
    "woeid": "12510884",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "TCE",
    "lat": "45.0653",
    "lon": "28.7232",
    "name": "Cataloi Airport",
    "city": "Tulcea",
    "state": "Tulcea",
    "country": "Romania",
    "woeid": "12515516",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TCH",
    "lat": "47.4463",
    "lon": "-52.9283",
    "name": "",
    "city": "Tchibanga",
    "state": "Nyanga",
    "country": "Gabon",
    "woeid": "1325711",
    "tz": "Africa\/Libreville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TCO",
    "lat": "1.8208",
    "lon": "-78.7528",
    "name": "La Florida Airport",
    "city": "Tumaco",
    "state": "Narino",
    "country": "Colombia",
    "woeid": "12512387",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TCP",
    "lat": "29.4945",
    "lon": "34.8918",
    "name": "Taba",
    "city": "Taba",
    "state": "Janub Sina'",
    "country": "Egypt",
    "woeid": "1526415",
    "tz": "Africa\/Cairo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TCQ",
    "lat": "-18.0508",
    "lon": "-70.2739",
    "name": "Cor Fap Carlos C Santa Rosa Airport",
    "city": "Tacna",
    "state": "Tacna",
    "country": "Peru",
    "woeid": "12515202",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "TCR",
    "lat": "8.78333",
    "lon": "78.1333",
    "name": "",
    "city": "Tuticorin",
    "state": "Tamil Nadu",
    "country": "India",
    "woeid": "2295369",
    "tz": "Asia\/Kolkata",
    "phone": "00-91-461-71565",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportsindia.org.in",
    "runway_length": "1350",
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TCT",
    "lat": "62.9845",
    "lon": "-156.07",
    "name": "Takotna",
    "city": "Takotna",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2503638",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TDD",
    "lat": "-14.8156",
    "lon": "-64.9161",
    "name": "Jorge Henrich Arauz Airport",
    "city": "Trinidad",
    "state": "El Beni",
    "country": "Bolivia",
    "woeid": "12510907",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "1"
  },
  {
    "code": "TDX",
    "lat": "12.2229",
    "lon": "102.593",
    "name": "Mueang Trat",
    "city": "Trat",
    "state": "Trat",
    "country": "Thailand",
    "woeid": "28341379",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TEB",
    "lat": "40.8541",
    "lon": "-74.0662",
    "name": "Teterboro Airport",
    "city": "Teterboro",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "12522105",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTEB",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TEE",
    "lat": "35.4308",
    "lon": "8.1244",
    "name": "Tebessa Airport",
    "city": "Tebessa",
    "state": "Tebessa",
    "country": "Algeria",
    "woeid": "12510351",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TEK",
    "lat": "60.8644",
    "lon": "-146.693",
    "name": "Tatitlek Seaplane Base",
    "city": "Valdez",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12524670",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TEN",
    "lat": "35.5089",
    "lon": "102.011",
    "name": "Tongren",
    "city": "Tongren",
    "state": "Qinghai",
    "country": "China",
    "woeid": "2138839",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "TER",
    "lat": "38.765",
    "lon": "-27.0958",
    "name": "Lajes Airport",
    "city": "Praia da Vit\u00f3ria",
    "state": "Azores",
    "country": "Portugal",
    "woeid": "12515446",
    "tz": "Atlantic\/Azores",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LPLA",
    "direct_flights": "11",
    "carriers": "3"
  },
  {
    "code": "TET",
    "lat": "-16.1033",
    "lon": "33.6389",
    "name": "Tete Chingozi Airport",
    "city": "Tete",
    "state": "Tete",
    "country": "Mozambique",
    "woeid": "12515030",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "TEX",
    "lat": "37.9544",
    "lon": "-107.901",
    "name": "Telluride Regional Airport",
    "city": "Telluride",
    "state": "Colorado",
    "country": "United States",
    "woeid": "12522097",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTEX",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "TFI",
    "lat": "-9.08333",
    "lon": "149.317",
    "name": "",
    "city": "Tufi",
    "state": "Northern",
    "country": "Papua New Guinea",
    "woeid": "1061985",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TFN",
    "lat": "28.4978",
    "lon": "-16.3419",
    "name": "Norte-Los Rodeos Airport",
    "city": "Tegueste",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "12517560",
    "tz": "Atlantic\/Canary",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "GCXO",
    "direct_flights": "22",
    "carriers": "20"
  },
  {
    "code": "TFS",
    "lat": "28.0474",
    "lon": "-16.5705",
    "name": "Sur-Reina Sofia Airport",
    "city": "Granadilla",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "12517573",
    "tz": "Atlantic\/Canary",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aena.es",
    "runway_length": null,
    "elev": null,
    "icao": "GCTS",
    "direct_flights": "76",
    "carriers": "33"
  },
  {
    "code": "TGC",
    "lat": "35.9349",
    "lon": "-88.8506",
    "name": "Gibson County Airport",
    "city": "Milan",
    "state": "Tennessee",
    "country": "United States",
    "woeid": "12519895",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTGC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TGD",
    "lat": "42.3675",
    "lon": "19.275",
    "name": "Titograd Airport",
    "city": "Podgorica",
    "state": "Montenegro",
    "country": "Montenegro",
    "woeid": "12517592",
    "tz": "Europe\/Podgorica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "121",
    "icao": "LYPG",
    "direct_flights": "13",
    "carriers": "8"
  },
  {
    "code": "TGG",
    "lat": "5.3811",
    "lon": "103.11",
    "name": "Sultan Mahmud Airport",
    "city": "Kuala Terengganu",
    "state": "Terengganu",
    "country": "Malaysia",
    "woeid": "12515012",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6801",
    "elev": "21",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "TGH",
    "lat": "-16.9027",
    "lon": "168.56",
    "name": "Tongoa Airport",
    "city": "",
    "state": "Shefa",
    "country": "Vanuatu",
    "woeid": "12515055",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TGJ",
    "lat": "-20.9314",
    "lon": "167.229",
    "name": "Loyaute",
    "city": "Tiga",
    "state": "Loyaute",
    "country": "New Caledonia",
    "woeid": "24549805",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "TGM",
    "lat": "46.4676",
    "lon": "24.4203",
    "name": "Vidrasau Airport",
    "city": "",
    "state": "Mures",
    "country": "Romania",
    "woeid": "12515593",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LRTM",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "TGO",
    "lat": "43.6125",
    "lon": "122.265",
    "name": "Tongliao",
    "city": "Tongliao",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "2149767",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TGR",
    "lat": "33.0675",
    "lon": "6.0883",
    "name": "Touggourt Airport",
    "city": "Ouargla",
    "state": "Ouargla",
    "country": "Algeria",
    "woeid": "12510356",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TGU",
    "lat": "14.0603",
    "lon": "-87.2172",
    "name": "Toncontin International Airport",
    "city": "Tegucigalpa",
    "state": "Francisco Moraz\u00e1n",
    "country": "Honduras",
    "woeid": "12513364",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6132",
    "elev": "3297",
    "icao": "MHTG",
    "direct_flights": "8",
    "carriers": "13"
  },
  {
    "code": "TGZ",
    "lat": "16.7683",
    "lon": "-93.3422",
    "name": "Tuxtla Gutierrez Airport",
    "city": "San Fernando",
    "state": "Chiapas",
    "country": "Mexico",
    "woeid": "12514981",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MMTG",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "THE",
    "lat": "-5.0603",
    "lon": "-42.8239",
    "name": "Senador Petronio Portella Airport",
    "city": "Teresina",
    "state": "Maranhao",
    "country": "Brazil",
    "woeid": "12511344",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "THF",
    "lat": "52.4825",
    "lon": "13.3892",
    "name": "Tempelhof Central Airport",
    "city": "Berlin",
    "state": "Berlin",
    "country": "Germany",
    "woeid": "22164089",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6942",
    "elev": "164",
    "icao": "EDDI",
    "direct_flights": "5",
    "carriers": "7"
  },
  {
    "code": "THL",
    "lat": "20.4842",
    "lon": "99.9322",
    "name": "Tachilek Airport",
    "city": "Tachilek",
    "state": "Shan State",
    "country": "Myanmar",
    "woeid": "12510937",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "THN",
    "lat": "58.3161",
    "lon": "12.3507",
    "name": "Trollhattan Vanersborg Airport",
    "city": "Trollhatan",
    "state": "Vastra Gotaland",
    "country": "Sweden",
    "woeid": "12517685",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.fyrstadsflyget.se\/",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "THO",
    "lat": "66.2",
    "lon": "-15.3333",
    "name": "Thorshofn Airport",
    "city": "Thorshofn",
    "state": "Nordur-Tingeyjarsysla",
    "country": "Iceland",
    "woeid": "12523905",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "THR",
    "lat": "35.6908",
    "lon": "51.3144",
    "name": "Mehrabad International Airport",
    "city": "Tehran",
    "state": "Tehran",
    "country": "Iran",
    "woeid": "12513746",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "OIII",
    "direct_flights": "55",
    "carriers": "11"
  },
  {
    "code": "THS",
    "lat": "17.2167",
    "lon": "99.8167",
    "name": "Sukhothai Airport",
    "city": "Sawankhalok",
    "state": "Sukhothai",
    "country": "Thailand",
    "woeid": "12523370",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "THU",
    "lat": "76.53",
    "lon": "-68.7058",
    "name": "Pituffik",
    "city": "Pituffik",
    "state": "Nordgronland",
    "country": "Greenland",
    "woeid": "12513033",
    "tz": "America\/Thule",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "BGTL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TIA",
    "lat": "41.4247",
    "lon": "19.7147",
    "name": "Tirane Rinas Airport",
    "city": "Krna",
    "state": "Durr\u00ebs",
    "country": "Albania",
    "woeid": "12510418",
    "tz": "Europe\/Tirane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "www.tirana-airport.com",
    "runway_length": null,
    "elev": null,
    "icao": "LATI",
    "direct_flights": "33",
    "carriers": "17"
  },
  {
    "code": "TIF",
    "lat": "21.4831",
    "lon": "40.5439",
    "name": "Taif Airport",
    "city": "",
    "state": "Makka",
    "country": "Saudi Arabia",
    "woeid": "12517372",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTIF",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "TIH",
    "lat": "-17.6902",
    "lon": "-149.376",
    "name": "French Polynesia",
    "city": "Tikehau Atoll",
    "state": "",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TIJ",
    "lat": "32.5411",
    "lon": "-116.972",
    "name": "General Abelardo L Rodriguez International Airpo",
    "city": "Tijuana",
    "state": "Baja California",
    "country": "Mexico",
    "woeid": "12514874",
    "tz": "America\/Tijuana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9711",
    "elev": "489",
    "icao": "MMTJ",
    "direct_flights": "25",
    "carriers": "12"
  },
  {
    "code": "TIM",
    "lat": "-4.5347",
    "lon": "136.888",
    "name": "Tembagapura Airport",
    "city": "Nabire",
    "state": "Irian Jaya",
    "country": "Indonesia",
    "woeid": "12513519",
    "tz": "Asia\/Jayapura",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "TIN",
    "lat": "39.5354",
    "lon": "-93.4399",
    "name": "",
    "city": "Tindouf",
    "state": "Tindouf",
    "country": "Algeria",
    "woeid": "1257822",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TIP",
    "lat": "31",
    "lon": "15",
    "name": "International",
    "city": "Tripoli",
    "state": "Sawfajjin",
    "country": "Libyan Arab Jamahiriya",
    "woeid": "1352663",
    "tz": "Africa\/Tripoli",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "HLLT",
    "direct_flights": "53",
    "carriers": "27"
  },
  {
    "code": "TIQ",
    "lat": "45.0039",
    "lon": "12.2686",
    "name": "Tinian",
    "city": "Tinian",
    "state": "Veneto",
    "country": "Northern Mariana Islands",
    "woeid": "12846096",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TIR",
    "lat": "13.6319",
    "lon": "79.5444",
    "name": "Tirupathi Airport",
    "city": "Renigunta",
    "state": "Andhra Pradesh",
    "country": "India",
    "woeid": "12513685",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airportsindia.org",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "TIS",
    "lat": "-10.586",
    "lon": "142.293",
    "name": "Thursday Island Airport",
    "city": "Kubin Village",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510795",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TIU",
    "lat": "-44.3015",
    "lon": "171.223",
    "name": "Richard Pearse Airport",
    "city": "Timaru",
    "state": "Canterbury",
    "country": "New Zealand",
    "woeid": "12515170",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "NZTU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TIV",
    "lat": "42.4047",
    "lon": "18.7233",
    "name": "Tivat Airport",
    "city": "Teodo",
    "state": "Tivat",
    "country": "Montenegro",
    "woeid": "12517593",
    "tz": "Europe\/Belgrade",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aptivat.com\/",
    "runway_length": "8208",
    "elev": "20",
    "icao": "LYTV",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "TIZ",
    "lat": "-5.83333",
    "lon": "142.95",
    "name": "Tari Airport",
    "city": "Mendi",
    "state": "Southern Highlands",
    "country": "Papua New Guinea",
    "woeid": "12523242",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TJA",
    "lat": "-21.5503",
    "lon": "-64.7044",
    "name": "Capitan Oriel Lea Plaza Airport",
    "city": "Tarija",
    "state": "Tarija",
    "country": "Bolivia",
    "woeid": "12510904",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "SLTJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TJM",
    "lat": "57.1704",
    "lon": "65.3561",
    "name": "Tyumen Northwest Airport",
    "city": "Tyumen'",
    "state": "Tyumenskaya Oblast",
    "country": "Russia",
    "woeid": "12517062",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "29",
    "carriers": "10"
  },
  {
    "code": "TJN",
    "lat": "-17.6902",
    "lon": "-149.376",
    "name": "French Polynesia",
    "city": "Takume",
    "state": "",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TJQ",
    "lat": "-2.7494",
    "lon": "107.763",
    "name": "Bulutumbang Airport",
    "city": "Tanjungpandan",
    "state": "Bangka-Belitung",
    "country": "Indonesia",
    "woeid": "12513459",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TKE",
    "lat": "57.7773",
    "lon": "-135.208",
    "name": "Tenakee Springs",
    "city": "Tenakee Springs",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2504726",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TKG",
    "lat": "-5.2403",
    "lon": "105.178",
    "name": "Branti Airport",
    "city": "Tanjungkarang",
    "state": "Lampung",
    "country": "Indonesia",
    "woeid": "12513457",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4986",
    "elev": "282",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TKJ",
    "lat": "48.1205",
    "lon": "21.4125",
    "name": "",
    "city": "Tok",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2506862",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PATJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TKK",
    "lat": "7.4569",
    "lon": "151.84",
    "name": "Truk International Airport",
    "city": "Weno",
    "state": "Chuuk",
    "country": "Federated States of Micronesia",
    "woeid": "12512817",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TKN",
    "lat": "27.834",
    "lon": "128.882",
    "name": "Tokunoshima Airport",
    "city": "Amagi-cho",
    "state": "Kagoshima Prefecture",
    "country": "Japan",
    "woeid": "12514026",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TKP",
    "lat": "-17.6902",
    "lon": "-149.376",
    "name": "French Polynesia",
    "city": "Takapoto",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TKQ",
    "lat": "-4.88005",
    "lon": "29.628",
    "name": "Tanzania",
    "city": "Kigoma",
    "state": "Kigoma",
    "country": "Tanzania",
    "woeid": "23424973",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5797",
    "elev": "2700",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TKS",
    "lat": "34.1339",
    "lon": "134.603",
    "name": "Tokushima Airport",
    "city": "Matsushige-cho",
    "state": "Tokushima Prefecture",
    "country": "Japan",
    "woeid": "12514027",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "26",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TKU",
    "lat": "60.5138",
    "lon": "22.272",
    "name": "Turku Airport",
    "city": "Turku",
    "state": "Western Finland",
    "country": "Finland",
    "woeid": "12523801",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "161",
    "icao": "EFTU",
    "direct_flights": "4",
    "carriers": "10"
  },
  {
    "code": "TKV",
    "lat": "-17.3371",
    "lon": "-138.408",
    "name": "French Polynesia",
    "city": "Tatakoto",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTKV",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TKX",
    "lat": "-14.4667",
    "lon": "-1.96667",
    "name": "Takaroa",
    "city": "Takaroa",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "28743703",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTKX",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TLA",
    "lat": "65.2413",
    "lon": "-166.331",
    "name": "Teller",
    "city": "Teller",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2504609",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PATE",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "TLC",
    "lat": "19.3356",
    "lon": "-99.565",
    "name": "Jose Maria Morelos Y Pavon Airport",
    "city": "Toluca de Lerdo",
    "state": "Mexico",
    "country": "Mexico",
    "woeid": "12514900",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13780",
    "elev": "8448",
    "icao": "MMTO",
    "direct_flights": "26",
    "carriers": "6"
  },
  {
    "code": "TLE",
    "lat": "-23.3864",
    "lon": "43.7264",
    "name": "Toliara Airport",
    "city": "Toliara",
    "state": "Toliara",
    "country": "Madagascar",
    "woeid": "12514710",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "TLH",
    "lat": "30.3964",
    "lon": "-84.3503",
    "name": "Tallahassee Regional Airport",
    "city": "Tallahassee",
    "state": "Florida",
    "country": "United States",
    "woeid": "12522075",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.talgov.com\/citytlh\/aviation\/",
    "runway_length": "8000",
    "elev": "81",
    "icao": "KTLH",
    "direct_flights": "10",
    "carriers": "10"
  },
  {
    "code": "TLJ",
    "lat": "44.2044",
    "lon": "23.5396",
    "name": "",
    "city": "Tatalina",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PATL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TLL",
    "lat": "59.4121",
    "lon": "24.8297",
    "name": "Ulemiste Airport",
    "city": "Tallinn",
    "state": "Harjumaa",
    "country": "Estonia",
    "woeid": "12512744",
    "tz": "Europe\/Tallinn",
    "phone": "+372 605 8701",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.tallinn-airport.ee\/",
    "runway_length": "3070",
    "elev": "131",
    "icao": "EETN",
    "direct_flights": "31",
    "carriers": "25"
  },
  {
    "code": "TLM",
    "lat": "35.0139",
    "lon": "-1.4572",
    "name": "Zenata Airport",
    "city": "Tlemcen",
    "state": "Tlemcen",
    "country": "Algeria",
    "woeid": "12510357",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "TLN",
    "lat": "43.093",
    "lon": "6.15712",
    "name": "Le Palyvestre Airport",
    "city": "Hyeres",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "12512920",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6955",
    "elev": "7",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "TLS",
    "lat": "43.6294",
    "lon": "1.3747",
    "name": "Blagnac Airport",
    "city": "Blagnac",
    "state": "Midi-Pyrenees",
    "country": "France",
    "woeid": "12512856",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LFBO",
    "direct_flights": "47",
    "carriers": "43"
  },
  {
    "code": "TLT",
    "lat": "61.0959",
    "lon": "-160.968",
    "name": "Tuluksak",
    "city": "Tuluksak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2508578",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TLV",
    "lat": "32.0117",
    "lon": "34.8861",
    "name": "Ben Gurion Airport",
    "city": "Petah\u0331 Tiqwa",
    "state": "HaMerkaz",
    "country": "Israel",
    "woeid": "12513775",
    "tz": "Asia\/Jerusalem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.iaa.gov.il\/Rashat\/en-US\/Airports\/BenGurion",
    "runway_length": "11998",
    "elev": "135",
    "icao": "LLBG",
    "direct_flights": "87",
    "carriers": "56"
  },
  {
    "code": "TMC",
    "lat": "-9.4117",
    "lon": "119.25",
    "name": "Tambolaka Airport",
    "city": "Tambolaka",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12513516",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "TME",
    "lat": "6.4536",
    "lon": "-71.7614",
    "name": "Tame Airport",
    "city": "Tame",
    "state": "Arauca",
    "country": "Colombia",
    "woeid": "12512419",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TMJ",
    "lat": "37.2808",
    "lon": "67.3064",
    "name": "Termez Airport",
    "city": "Termiz",
    "state": "Surkhondaryo",
    "country": "Uzbekistan",
    "woeid": "12522727",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TML",
    "lat": "9.5636",
    "lon": "-0.8625",
    "name": "Tamale Airport",
    "city": "Savelugu",
    "state": "Northern",
    "country": "Ghana",
    "woeid": "12513026",
    "tz": "Africa\/Accra",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TMM",
    "lat": "-18.1064",
    "lon": "49.3919",
    "name": "Toamasina Airport",
    "city": "Toamasina",
    "state": "Toamasina",
    "country": "Madagascar",
    "woeid": "12514708",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "TMP",
    "lat": "61.4166",
    "lon": "23.615",
    "name": "Tampere Pirkkala Airport",
    "city": "Pitk\u00e4niemi",
    "state": "Western Finland",
    "country": "Finland",
    "woeid": "12512800",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2700",
    "elev": "120",
    "icao": "EFTP",
    "direct_flights": "9",
    "carriers": "8"
  },
  {
    "code": "TMR",
    "lat": "22.8147",
    "lon": "5.4592",
    "name": "Tamanrasset Airport",
    "city": "Tamanrasset",
    "state": "Tamanghasset",
    "country": "Algeria",
    "woeid": "12510350",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "TMS",
    "lat": "0.3783",
    "lon": "6.725",
    "name": "Sao Tome Salazar Airport",
    "city": "Sao Tome",
    "state": "Sao Tome",
    "country": "Sao Tome and Principe",
    "woeid": "12517853",
    "tz": "Africa\/Sao_Tome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7284",
    "elev": "33",
    "icao": "",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "TMT",
    "lat": "-1.48667",
    "lon": "-56.3961",
    "name": "Trombetas",
    "city": "Trombetas",
    "state": "Para",
    "country": "Brazil",
    "woeid": "12511352",
    "tz": "America\/Manaus",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "TMU",
    "lat": "9.73167",
    "lon": "-85.0251",
    "name": "Tambor Airport",
    "city": "Lepanto",
    "state": "Puntarenas",
    "country": "Costa Rica",
    "woeid": "12524321",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TMW",
    "lat": "-31.0839",
    "lon": "150.849",
    "name": "Tamworth Airport",
    "city": "Barry",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510789",
    "tz": "Australia\/Sydney",
    "phone": "(02) 6760 7611",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.tamworth.nsw.gov.au\/asp\/index.asp?pgid=9279&cid=24735",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TMX",
    "lat": "27.8676",
    "lon": "-0.28984",
    "name": "Adrar",
    "city": "Timimoun",
    "state": "Adrar",
    "country": "Algeria",
    "woeid": "1252933",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "TNA",
    "lat": "36.3306",
    "lon": "118.761",
    "name": "Shandong",
    "city": "Jinan",
    "state": "Shandong",
    "country": "China",
    "woeid": "12578014",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "32",
    "carriers": "14"
  },
  {
    "code": "TNC",
    "lat": "65.5639",
    "lon": "-167.919",
    "name": "Tin City AFS",
    "city": "Tin City",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2506552",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PATC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TNG",
    "lat": "35.7283",
    "lon": "-5.9175",
    "name": "Boukhalf Airport",
    "city": "Tangiers",
    "state": "Tanger",
    "country": "Morocco",
    "woeid": "12514783",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "GMTT",
    "direct_flights": "13",
    "carriers": "8"
  },
  {
    "code": "TNJ",
    "lat": "0.9192",
    "lon": "104.536",
    "name": "Kijang Airport",
    "city": "Tanjungpinang",
    "state": "Riau",
    "country": "Indonesia",
    "woeid": "12513478",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TNK",
    "lat": "60.5813",
    "lon": "-165.253",
    "name": "Tununak Airport",
    "city": "Tununak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29388569",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "TNN",
    "lat": "22.9634",
    "lon": "120.216",
    "name": "Tainan Airport",
    "city": "Tainan City",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "12517957",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "RCNN",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TNO",
    "lat": "10.9333",
    "lon": "-85.4667",
    "name": "Tamarindo Airport",
    "city": "Liberia",
    "state": "Guanacaste",
    "country": "Costa Rica",
    "woeid": "12523332",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "TNR",
    "lat": "-18.7953",
    "lon": "47.4861",
    "name": "Antananarivo Ivato Airport",
    "city": "Ambohidratrimo",
    "state": "Antananarivo",
    "country": "Madagascar",
    "woeid": "12514691",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10171",
    "elev": "4196",
    "icao": "FMMI",
    "direct_flights": "26",
    "carriers": "11"
  },
  {
    "code": "TNZ",
    "lat": "50.2394",
    "lon": "99.8604",
    "name": "Hovsgol",
    "city": "Tosontsengel",
    "state": "Hovsgol",
    "country": "Mongolia",
    "woeid": "2346167",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "TOD",
    "lat": "2.8",
    "lon": "104.167",
    "name": "Tioman Airport",
    "city": "Kampung Genting",
    "state": "Pahang",
    "country": "Malaysia",
    "woeid": "12523243",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3255",
    "elev": "15",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TOE",
    "lat": "33.9308",
    "lon": "8.1042",
    "name": "Nefta Airport",
    "city": "Tawzar",
    "state": "Tawzar",
    "country": "Tunisia",
    "woeid": "12517861",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "4"
  },
  {
    "code": "TOF",
    "lat": "56.3833",
    "lon": "85.2",
    "name": "",
    "city": "Tomsk",
    "state": "Tomskaya Oblast",
    "country": "Russia",
    "woeid": "2123927",
    "tz": "Asia\/Krasnoyarsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "182",
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "TOG",
    "lat": "37.1198",
    "lon": "-89.8786",
    "name": "Togiak Village",
    "city": "Togiak Village",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2512308",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "PATG",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "TOH",
    "lat": "-15.1468",
    "lon": "167.038",
    "name": "Vanuatu",
    "city": "Torres",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TOL",
    "lat": "41.5923",
    "lon": "-83.8072",
    "name": "Toledo Express Airport",
    "city": "Swanton",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12522152",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTOL",
    "direct_flights": "56",
    "carriers": "17"
  },
  {
    "code": "TOM",
    "lat": "16.7314",
    "lon": "-3.0053",
    "name": "Tombouctou Airport",
    "city": "",
    "state": "Tombouctou",
    "country": "Mali",
    "woeid": "12514779",
    "tz": "Africa\/Bamako",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TOS",
    "lat": "69.6787",
    "lon": "18.9096",
    "name": "Tromso Langnes Airport",
    "city": "Tromso",
    "state": "Troms Fylke",
    "country": "Norway",
    "woeid": "12515127",
    "tz": "Europe\/Oslo",
    "phone": "+ 47 77 64 84 00",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.avinor.no\/English\/Airports\/Tromso_Airport\/",
    "runway_length": "2392",
    "elev": "31",
    "icao": "ENTC",
    "direct_flights": "20",
    "carriers": "4"
  },
  {
    "code": "TOU",
    "lat": "41.1258",
    "lon": "-96.832",
    "name": "",
    "city": "Touho",
    "state": "Nord",
    "country": "New Caledonia",
    "woeid": "24551429",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TOY",
    "lat": "36.6495",
    "lon": "137.187",
    "name": "Toyama Airport",
    "city": "Toyama-shi",
    "state": "Toyama Prefecture",
    "country": "Japan",
    "woeid": "2345891",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "5"
  },
  {
    "code": "TPA",
    "lat": "27.9744",
    "lon": "-82.5356",
    "name": "Tampa International Airport",
    "city": "Tampa",
    "state": "Florida",
    "country": "United States",
    "woeid": "12522077",
    "tz": "America\/New_York",
    "phone": "1(813)870-8770",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.tampaairport.com\/",
    "runway_length": "11002",
    "elev": "26",
    "icao": "KTPA",
    "direct_flights": "74",
    "carriers": "48"
  },
  {
    "code": "TPE",
    "lat": "25.081",
    "lon": "121.237",
    "name": "Taiwan Taoyuan International Airport",
    "city": "Taoyuan City",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "2306254",
    "tz": "Asia\/Taipei",
    "phone": "+886 (03) 398-2036",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cksairport.gov.tw\/",
    "runway_length": "3660",
    "elev": "33",
    "icao": "RCTP",
    "direct_flights": "52",
    "carriers": "43"
  },
  {
    "code": "TPJ",
    "lat": "27.2999",
    "lon": "87.7011",
    "name": "Taplejung Suketar",
    "city": "Taplejung",
    "state": "Central",
    "country": "Nepal",
    "woeid": "2268915",
    "tz": "Asia\/Katmandu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TPP",
    "lat": "-6.5058",
    "lon": "-76.3714",
    "name": "Tarapoto Airport",
    "city": "Tarapoto",
    "state": "San Martin",
    "country": "Peru",
    "woeid": "12515220",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "TPQ",
    "lat": "21.4758",
    "lon": "-104.881",
    "name": "Tepic Airport",
    "city": "Tepic",
    "state": "Nayarit",
    "country": "Mexico",
    "woeid": "12514975",
    "tz": "America\/Mazatlan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "MMEP",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "TPS",
    "lat": "37.9021",
    "lon": "12.4994",
    "name": "Trapani Birgi Airport",
    "city": "Trapani",
    "state": "Sicily",
    "country": "Italy",
    "woeid": "12513863",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LICT",
    "direct_flights": "11",
    "carriers": "2"
  },
  {
    "code": "TQR",
    "lat": "43.2667",
    "lon": "10.9333",
    "name": "",
    "city": "San Domino Island",
    "state": "Trentino-Alto Adige",
    "country": "Italy",
    "woeid": "20142146",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TRC",
    "lat": "25.5683",
    "lon": "-103.411",
    "name": "Torreon International Airport",
    "city": "Torre\u00f3n",
    "state": "Coahuila de Zaragoza",
    "country": "Mexico",
    "woeid": "12514978",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9022",
    "elev": "3688",
    "icao": "MMTC",
    "direct_flights": "11",
    "carriers": "10"
  },
  {
    "code": "TRD",
    "lat": "63.4578",
    "lon": "10.9229",
    "name": "Trondheim Vaernes Airport",
    "city": "Stjordal",
    "state": "Nord-Trondelag",
    "country": "Norway",
    "woeid": "12515128",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.avinor.no\/Norsk\/Lufthavner\/Trondheim_lufthavn,_Varnes",
    "runway_length": "2900",
    "elev": null,
    "icao": "ENVA",
    "direct_flights": "26",
    "carriers": "11"
  },
  {
    "code": "TRE",
    "lat": "56.4998",
    "lon": "-6.87066",
    "name": "Tiree Aerodrome",
    "city": "Crossapol",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22486802",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "TRF",
    "lat": "59.182",
    "lon": "10.2509",
    "name": "Torp Airport",
    "city": "Sandefjord",
    "state": "Vestfold Fylke",
    "country": "Norway",
    "woeid": "12515126",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9809",
    "elev": "285",
    "icao": "ENTO",
    "direct_flights": "23",
    "carriers": "11"
  },
  {
    "code": "TRG",
    "lat": "-37.6738",
    "lon": "176.19",
    "name": "Tauranga Airport",
    "city": "Tauranga",
    "state": "Bay Of Plenty",
    "country": "New Zealand",
    "woeid": "12515169",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "NZTG",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "TRI",
    "lat": "36.4805",
    "lon": "-82.4087",
    "name": "Tri-Cities Regional Airport",
    "city": "Blountville",
    "state": "Tennessee",
    "country": "United States",
    "woeid": "12522192",
    "tz": "America\/New_York",
    "phone": "423-325-6000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.triflight.com\/",
    "runway_length": null,
    "elev": null,
    "icao": "KTRI",
    "direct_flights": "9",
    "carriers": "12"
  },
  {
    "code": "TRK",
    "lat": "3.3258",
    "lon": "117.567",
    "name": "Tarakan Airport",
    "city": "Tarakan",
    "state": "Kalimantan Timur",
    "country": "Indonesia",
    "woeid": "12513517",
    "tz": "Asia\/Jakarta",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTRK",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "TRN",
    "lat": "45.1975",
    "lon": "7.64937",
    "name": "Turin International Airport",
    "city": "Caselle",
    "state": "Piedmont",
    "country": "Italy",
    "woeid": "12513862",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroportoditorino.it\/",
    "runway_length": null,
    "elev": null,
    "icao": "LIMF",
    "direct_flights": "37",
    "carriers": "42"
  },
  {
    "code": "TRO",
    "lat": "-31.8887",
    "lon": "152.518",
    "name": "Taree Airport",
    "city": "Taree",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510790",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TRS",
    "lat": "45.8234",
    "lon": "13.4841",
    "name": "Ronchi Dei Legionari Airport",
    "city": "Ronchi",
    "state": "Friuli-Venezia Giulia",
    "country": "Italy",
    "woeid": "12513859",
    "tz": "Europe\/Rome",
    "phone": "0039 0481 773224",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroporto.fvg.it",
    "runway_length": "3",
    "elev": null,
    "icao": "LIPQ",
    "direct_flights": "17",
    "carriers": "23"
  },
  {
    "code": "TRU",
    "lat": "-8.09",
    "lon": "-79.115",
    "name": "Cap C Martinez de Pinillos Airport",
    "city": "Huanchaco",
    "state": "La Libertad",
    "country": "Peru",
    "woeid": "12515191",
    "tz": "America\/Lima",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "SPRU",
    "direct_flights": "2",
    "carriers": "5"
  },
  {
    "code": "TRV",
    "lat": "8.47824",
    "lon": "76.9215",
    "name": "Thiruvananthapuram Airport",
    "city": "Thiruvananthapuram",
    "state": "Kerala",
    "country": "India",
    "woeid": "12513686",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "VOTV",
    "direct_flights": "17",
    "carriers": "18"
  },
  {
    "code": "TRW",
    "lat": "1.38",
    "lon": "173.151",
    "name": "Bonriki International Airport",
    "city": "Tarawa",
    "state": "",
    "country": "Kiribati",
    "woeid": "12514189",
    "tz": "Pacific\/Tarawa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TRZ",
    "lat": "10.7645",
    "lon": "78.7396",
    "name": "",
    "city": "Tiruchirapally",
    "state": "",
    "country": "India",
    "woeid": "12513684",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "TSA",
    "lat": "25.0637",
    "lon": "121.552",
    "name": "Taipei Songshan Airport",
    "city": "Taipei City",
    "state": "Taipei City",
    "country": "Taiwan",
    "woeid": "12517954",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "RCSS",
    "direct_flights": "13",
    "carriers": "9"
  },
  {
    "code": "TSE",
    "lat": "51.0266",
    "lon": "71.4782",
    "name": "Tselinograd South Airport",
    "city": "Aqmola",
    "state": "Aqmola",
    "country": "Kazakhstan",
    "woeid": "12514494",
    "tz": "Asia\/Almaty",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "30",
    "carriers": "18"
  },
  {
    "code": "TSF",
    "lat": "45.6513",
    "lon": "12.2022",
    "name": "Treviso Airport",
    "city": "Treviso",
    "state": "Veneto",
    "country": "Italy",
    "woeid": "22321438",
    "tz": "Europe\/Rome",
    "phone": "(0039)0422315111",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.trevisoairport.it",
    "runway_length": "7940",
    "elev": "59",
    "icao": "LIPH",
    "direct_flights": "20",
    "carriers": "6"
  },
  {
    "code": "TSJ",
    "lat": "34.2856",
    "lon": "129.33",
    "name": "Tsushima Airport",
    "city": "Tsushima-shi",
    "state": "Nagasaki Prefecture",
    "country": "Japan",
    "woeid": "12514032",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TSL",
    "lat": "22.0458",
    "lon": "-98.8042",
    "name": "Tamuin Airport",
    "city": "Ciudad Valles",
    "state": "San Luis Potosi",
    "country": "Mexico",
    "woeid": "12514969",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TSN",
    "lat": "39.1333",
    "lon": "117.35",
    "name": "Zhangguizhuang Airport",
    "city": "Tanggu",
    "state": "Tianjin",
    "country": "China",
    "woeid": "12523246",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "39",
    "carriers": "26"
  },
  {
    "code": "TSO",
    "lat": "49.9502",
    "lon": "-6.32874",
    "name": "",
    "city": "Isles Of Scilly",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12695899",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTSO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TSR",
    "lat": "45.8047",
    "lon": "21.3377",
    "name": "Timisoara Northeast Airport",
    "city": "",
    "state": "Timis",
    "country": "Romania",
    "woeid": "12515586",
    "tz": "Europe\/Bucharest",
    "phone": "+40 256 493 123",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aerotim.ro",
    "runway_length": "3500",
    "elev": "106",
    "icao": "LRTR",
    "direct_flights": "30",
    "carriers": "9"
  },
  {
    "code": "TSS",
    "lat": "40.7474",
    "lon": "-73.9912",
    "name": "East 34th Street Heliport",
    "city": "New York",
    "state": "New York",
    "country": "United States",
    "woeid": "12523248",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Heliport2",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "TST",
    "lat": "7.5081",
    "lon": "99.625",
    "name": "Trang Airport",
    "city": "Muang Trang",
    "state": "Trang",
    "country": "Thailand",
    "woeid": "12517789",
    "tz": "Asia\/Bangkok",
    "phone": "075-218-224, 211-150",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TSV",
    "lat": "-19.2542",
    "lon": "146.764",
    "name": "Townsville Airport",
    "city": "Townsville",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510800",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.townsvilleairport.com.au",
    "runway_length": "2436",
    "elev": "18",
    "icao": "YBTL",
    "direct_flights": "10",
    "carriers": "5"
  },
  {
    "code": "TTA",
    "lat": "28.4294",
    "lon": "-11.098",
    "name": "Tan-Tan",
    "city": "Tan Tan",
    "state": "Tan-Tan",
    "country": "Morocco",
    "woeid": "1540902",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTTA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TTE",
    "lat": "0.8308",
    "lon": "127.377",
    "name": "Babullah Airport",
    "city": "Ternate",
    "state": "Maluku Utara",
    "country": "Indonesia",
    "woeid": "12513452",
    "tz": "Asia\/Jayapura",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "TTJ",
    "lat": "35.5298",
    "lon": "134.166",
    "name": "Tottori Airport",
    "city": "Tottori-shi",
    "state": "Tottori Prefecture",
    "country": "Japan",
    "woeid": "12514029",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TTQ",
    "lat": "10.5667",
    "lon": "-83.5167",
    "name": "Tortuquero Airport",
    "city": "Colorado",
    "state": "Limon",
    "country": "Costa Rica",
    "woeid": "12524322",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "TTT",
    "lat": "22.7561",
    "lon": "121.107",
    "name": "Taitung Airport",
    "city": "Taitung City",
    "state": "Taiwan Province",
    "country": "Taiwan",
    "woeid": "12517928",
    "tz": "Asia\/Taipei",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TTU",
    "lat": "35.5953",
    "lon": "-5.3261",
    "name": "Sania Ramel Airport",
    "city": "Tetouan",
    "state": "Tetouan",
    "country": "Morocco",
    "woeid": "12514792",
    "tz": "Africa\/Casablanca",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "TUA",
    "lat": "0.8111",
    "lon": "-77.7069",
    "name": "El Rosal Teniente Guerrero Airport",
    "city": "Urbina",
    "state": "Carchi",
    "country": "Ecuador",
    "woeid": "12512628",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TUB",
    "lat": "31.6123",
    "lon": "-111.052",
    "name": "",
    "city": "Tubuai",
    "state": "Austral Islands",
    "country": "French Polynesia",
    "woeid": "28743674",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "TUC",
    "lat": "-26.8397",
    "lon": "-65.1019",
    "name": "Teniente Benjamin Matienzo Airport",
    "city": "Banda del R\u00edo Sal\u00ed",
    "state": "Tucuman",
    "country": "Argentina",
    "woeid": "12510566",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TUD",
    "lat": "13.7347",
    "lon": "-13.6561",
    "name": "Tambacounda Airport",
    "city": "Tambacounda",
    "state": "Tambacounda",
    "country": "Senegal",
    "woeid": "12517510",
    "tz": "Africa\/Dakar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TUF",
    "lat": "47.428",
    "lon": "0.72414",
    "name": "St Symphorien Airport",
    "city": "Tours",
    "state": "Centre",
    "country": "France",
    "woeid": "12512976",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LFOT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TUG",
    "lat": "17.6417",
    "lon": "121.731",
    "name": "Tuguegarao Airport",
    "city": "Tuguegarao",
    "state": "Cagayan Valley",
    "country": "Philippines",
    "woeid": "12515652",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "TUI",
    "lat": "31.6917",
    "lon": "38.7289",
    "name": "Turaif Airport",
    "city": "Turayf",
    "state": "Al Hudud ash Shamaliyah",
    "country": "Saudi Arabia",
    "woeid": "12517375",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "TUK",
    "lat": "25.9833",
    "lon": "63.0319",
    "name": "Turbat Airport",
    "city": "Turbat",
    "state": "Balochistan",
    "country": "Pakistan",
    "woeid": "12515273",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "TUL",
    "lat": "36.1877",
    "lon": "-95.8892",
    "name": "Tulsa International Airport",
    "city": "Tulsa",
    "state": "Oklahoma",
    "country": "United States",
    "woeid": "12522219",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTUL",
    "direct_flights": "26",
    "carriers": "21"
  },
  {
    "code": "TUN",
    "lat": "36.8435",
    "lon": "10.2348",
    "name": "Aeroport Tunis",
    "city": "Tunis",
    "state": "Tunis",
    "country": "Tunisia",
    "woeid": "23388364",
    "tz": "Africa\/Tunis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "DTTA",
    "direct_flights": "62",
    "carriers": "26"
  },
  {
    "code": "TUO",
    "lat": "-38.7383",
    "lon": "176.088",
    "name": "Taupo Aerodrome",
    "city": "Taupo",
    "state": "Waikato",
    "country": "New Zealand",
    "woeid": "12523249",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "NZAP",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "TUP",
    "lat": "34.2662",
    "lon": "-88.766",
    "name": "Tupelo Municipal-C D Lemons Airport",
    "city": "Tupelo",
    "state": "Mississippi",
    "country": "United States",
    "woeid": "12522222",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTUP",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "TUR",
    "lat": "-3.7767",
    "lon": "-49.7192",
    "name": "Tucurui Airport",
    "city": "Tucuru\u00ed",
    "state": "Para",
    "country": "Brazil",
    "woeid": "12511353",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TUS",
    "lat": "32.1204",
    "lon": "-110.936",
    "name": "Tucson International Airport",
    "city": "Tucson",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12522214",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2643",
    "icao": "KTUS",
    "direct_flights": "25",
    "carriers": "26"
  },
  {
    "code": "TUU",
    "lat": "28.3722",
    "lon": "36.6253",
    "name": "Tabuk Airport",
    "city": "Tabuk",
    "state": "Tabuk",
    "country": "Saudi Arabia",
    "woeid": "12517371",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTUU",
    "direct_flights": "10",
    "carriers": "1"
  },
  {
    "code": "TVC",
    "lat": "44.7365",
    "lon": "-85.5796",
    "name": "Cherry Capital Airport",
    "city": "Traverse City",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12519167",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.tvcairport.com\/",
    "runway_length": "6501",
    "elev": "624",
    "icao": "KTVC",
    "direct_flights": "6",
    "carriers": "9"
  },
  {
    "code": "TVF",
    "lat": "48.0655",
    "lon": "-96.1791",
    "name": "Thief River Falls Regional Airport",
    "city": "Thief River Falls",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12522121",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTVF",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TVU",
    "lat": "-16.8494",
    "lon": "-179.97",
    "name": "Taveuni Airport",
    "city": "Taveuni",
    "state": "Northern",
    "country": "Fiji",
    "woeid": "12512812",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "TVY",
    "lat": "14.1017",
    "lon": "98.2067",
    "name": "",
    "city": "Dawe",
    "state": "",
    "country": "Myanmar",
    "woeid": "23424763",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4593",
    "elev": "84",
    "icao": "KTVY",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "TWA",
    "lat": "36.8259",
    "lon": "-83.3212",
    "name": "Twin Hills",
    "city": "Twin Hills",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2509121",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TWB",
    "lat": "-27.5444",
    "lon": "151.914",
    "name": "Toowoomba Airport",
    "city": "Toowoomba",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510799",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/tcc.toowoomba.qld.gov.au\/index.php?option=com_content&tas",
    "runway_length": "1121",
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TWF",
    "lat": "42.4838",
    "lon": "-114.485",
    "name": "Twin Falls-Sun Valley Regional Airport",
    "city": "Twin Falls",
    "state": "Idaho",
    "country": "United States",
    "woeid": "12522233",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTWF",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TWT",
    "lat": "7.1",
    "lon": "124.65",
    "name": "Tawitawi",
    "city": "Tawitawi",
    "state": "Soccsksargen",
    "country": "Philippines",
    "woeid": "12747940",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "TWU",
    "lat": "4.2642",
    "lon": "117.885",
    "name": "Tawau Airport",
    "city": "Tawau",
    "state": "Sabah",
    "country": "Malaysia",
    "woeid": "12515013",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "TXK",
    "lat": "33.457",
    "lon": "-93.9943",
    "name": "Texarkana Regional Airport",
    "city": "Texarkana",
    "state": "Arkansas",
    "country": "United States",
    "woeid": "12522107",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KTXK",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "TXL",
    "lat": "52.5548",
    "lon": "13.289",
    "name": "Berlin-Tegel International Airport",
    "city": "Berlin",
    "state": "Berlin",
    "country": "Germany",
    "woeid": "22164086",
    "tz": "Europe\/Berlin",
    "phone": "0180 5000 186 (0,12 Euro",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.berlin-airport.de\/PubEnglish\/PubTegel\/index.php?",
    "runway_length": "9918",
    "elev": "121",
    "icao": "EDDT",
    "direct_flights": "101",
    "carriers": "70"
  },
  {
    "code": "TXN",
    "lat": "29.7354",
    "lon": "118.262",
    "name": "Tunxi Airport",
    "city": "Haiyang",
    "state": "Anhui",
    "country": "China",
    "woeid": "12512221",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "TYF",
    "lat": "60.154",
    "lon": "12.9958",
    "name": "Fryklanda Airport",
    "city": "Torsby",
    "state": "Varmland",
    "country": "Sweden",
    "woeid": "12517627",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TYN",
    "lat": "37.774",
    "lon": "112.614",
    "name": "Taiyuan Wusu Airport",
    "city": "Taiyuan",
    "state": "Shanxi",
    "country": "China",
    "woeid": "12512207",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "31",
    "carriers": "14"
  },
  {
    "code": "TYR",
    "lat": "32.3506",
    "lon": "-95.4118",
    "name": "Tyler Pounds Field Airport",
    "city": "Tyler",
    "state": "Texas",
    "country": "United States",
    "woeid": "12522238",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7200",
    "elev": "544",
    "icao": "KTYR",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "TYS",
    "lat": "35.8064",
    "lon": "-83.9889",
    "name": "Mcghee Tyson Airport",
    "city": "Knoxville",
    "state": "Tennessee",
    "country": "United States",
    "woeid": "12520857",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9008",
    "elev": "981",
    "icao": "KTYS",
    "direct_flights": "25",
    "carriers": "25"
  },
  {
    "code": "TZA",
    "lat": "17.5347",
    "lon": "-88.3028",
    "name": "Belize City Municipal Airport",
    "city": "Hattieville",
    "state": "Belize",
    "country": "Belize",
    "woeid": "12523291",
    "tz": "America\/Belize",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1740",
    "elev": "5",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "TZN",
    "lat": "24.1543",
    "lon": "-77.5885",
    "name": "South Andros Airport",
    "city": "Mangrove Cay",
    "state": "Mangrove Cay",
    "country": "Bahamas",
    "woeid": "12524026",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "15",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "TZX",
    "lat": "41",
    "lon": "39.7167",
    "name": "Trabzon Air Base",
    "city": "Trabzon",
    "state": "Trabzon",
    "country": "Turkey",
    "woeid": "12523250",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2640",
    "elev": "104",
    "icao": "LTCD",
    "direct_flights": "12",
    "carriers": "4"
  },
  {
    "code": "UAH",
    "lat": "-8.91445",
    "lon": "-139.548",
    "name": "French Polynesia",
    "city": "Ua Huka",
    "state": "Marquesas Islands",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2477",
    "elev": "151",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "UAK",
    "lat": "61.1611",
    "lon": "-45.4278",
    "name": "Narsarsuaq Airport",
    "city": "Narsarssurk",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12513031",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/iserit.greennet.gl\/bgbw",
    "runway_length": "6004",
    "elev": "112",
    "icao": "",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "UAP",
    "lat": "-9.41207",
    "lon": "-140.068",
    "name": "French Polynesia",
    "city": "Ua Pou",
    "state": "Marquesas Islands",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2723",
    "elev": "112",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "UAQ",
    "lat": "-31.6024",
    "lon": "-68.5472",
    "name": "San Juan Airport",
    "city": "9 de Julio",
    "state": "San Juan",
    "country": "Argentina",
    "woeid": "12510552",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8070",
    "elev": "1959",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "UAS",
    "lat": "0.466667",
    "lon": "37.5667",
    "name": "Samburu Airport",
    "city": "Samburu",
    "state": "Eastern",
    "country": "Kenya",
    "woeid": "12732896",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3280",
    "elev": "3300",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "UBA",
    "lat": "-19.75",
    "lon": "-47.95",
    "name": "Uberaba",
    "city": "Uberaba",
    "state": "Sudeste",
    "country": "Brazil",
    "woeid": "455916",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "2648",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "UBJ",
    "lat": "33.931",
    "lon": "131.276",
    "name": "Yamaguchi-Ube Airport",
    "city": "Ube-shi",
    "state": "Yamaguchi Prefecture",
    "country": "Japan",
    "woeid": "12514036",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6560",
    "elev": "23",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "UBP",
    "lat": "15.2489",
    "lon": "104.873",
    "name": "Ubon Airport",
    "city": "Don Mot Daeng",
    "state": "Ubon Ratchathani",
    "country": "Thailand",
    "woeid": "12517791",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9848",
    "elev": "406",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "UCT",
    "lat": "63.5577",
    "lon": "53.7894",
    "name": "Ust Ukhta Airport",
    "city": "Ukhta",
    "state": "Komi",
    "country": "Russia",
    "woeid": "12517120",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "UDI",
    "lat": "-18.8833",
    "lon": "-48.2247",
    "name": "Coronel Aviador Cesar Bombonato Airport",
    "city": "Uberlandia",
    "state": "Minas Gerais",
    "country": "Brazil",
    "woeid": "12511358",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6365",
    "elev": "3094",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "UDJ",
    "lat": "48.636",
    "lon": "22.2738",
    "name": "Uzhgorod Airport",
    "city": "Uzhhorod",
    "state": "Zakarpats\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12518458",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "UDR",
    "lat": "24.6041",
    "lon": "73.7726",
    "name": "Udaipur Airport",
    "city": "Debari",
    "state": "Rajasthan",
    "country": "India",
    "woeid": "12513688",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1670",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "UEL",
    "lat": "-17.8569",
    "lon": "36.8556",
    "name": "Quelimane Airport",
    "city": "Quelimane",
    "state": "Zambezia",
    "country": "Mozambique",
    "woeid": "12515029",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5905",
    "elev": "36",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "UEO",
    "lat": "26.3677",
    "lon": "126.716",
    "name": "Kumejima Airport",
    "city": "Kumejima-cho",
    "state": "Okinawa Prefecture",
    "country": "Japan",
    "woeid": "12513982",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "22",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UET",
    "lat": "30.2536",
    "lon": "66.9389",
    "name": "Quetta Airport",
    "city": "",
    "state": "Balochistan",
    "country": "Pakistan",
    "woeid": "12515260",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12000",
    "elev": "5250",
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "UFA",
    "lat": "54.5569",
    "lon": "55.8893",
    "name": "Ufa South Airport",
    "city": "Oufa",
    "state": "Bashkortostan",
    "country": "Russia",
    "woeid": "12517068",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UWUU",
    "direct_flights": "21",
    "carriers": "15"
  },
  {
    "code": "UGB",
    "lat": "57.4253",
    "lon": "-157.74",
    "name": "Ugashik Bay Airport",
    "city": "Pilot Point",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12523252",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1245",
    "elev": "25",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UGC",
    "lat": "41.5434",
    "lon": "60.6368",
    "name": "Urganch",
    "city": "Urgench",
    "state": "Qoraqalpoghiston",
    "country": "Uzbekistan",
    "woeid": "2272233",
    "tz": "Asia\/Samarkand",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "UGI",
    "lat": "57.8838",
    "lon": "-153.369",
    "name": "Uganik Airport",
    "city": "Kodiak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12522240",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UIB",
    "lat": "5.6903",
    "lon": "-76.6464",
    "name": "El Carano Airport",
    "city": "Quibd\u00f3",
    "state": "Choco",
    "country": "Colombia",
    "woeid": "12512373",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3870",
    "elev": "220",
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "UIH",
    "lat": "13.7673",
    "lon": "109.256",
    "name": "Vietnam",
    "city": "Qui Nhon",
    "state": "Binh Dinh",
    "country": "Vietnam",
    "woeid": "23424984",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5081",
    "elev": "25",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "UII",
    "lat": "16.0969",
    "lon": "-86.9337",
    "name": "Utila Airport",
    "city": "Utila",
    "state": "Islas de la Bah\u00eda",
    "country": "Honduras",
    "woeid": "12513366",
    "tz": "America\/Tegucigalpa",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "16",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UIN",
    "lat": "39.9348",
    "lon": "-91.196",
    "name": "Quincy Municipal Airport-Baldwin Field",
    "city": "Quincy",
    "state": "Illinois",
    "country": "United States",
    "woeid": "12521511",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7097",
    "elev": "769",
    "icao": "KUIN",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "UIO",
    "lat": "-0.1381",
    "lon": "-78.4833",
    "name": "Mariscal Sucre International Airport",
    "city": "Quito",
    "state": "Pichincha",
    "country": "Ecuador",
    "woeid": "12512643",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.quitoairport.com\/",
    "runway_length": "10240",
    "elev": "9223",
    "icao": "SEQU",
    "direct_flights": "20",
    "carriers": "22"
  },
  {
    "code": "UIP",
    "lat": "47.9751",
    "lon": "-4.17416",
    "name": "Pluguffan Airport",
    "city": "Plomelin",
    "state": "Brittany",
    "country": "France",
    "woeid": "12512952",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6234",
    "elev": "302",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "UKB",
    "lat": "34.6356",
    "lon": "135.22",
    "name": "Kobe Airport",
    "city": "Kobe-shi",
    "state": "Hyogo Prefecture",
    "country": "Japan",
    "woeid": "12523307",
    "tz": "Asia\/Tokyo",
    "phone": "078-306-4195",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.city.kobe.jp",
    "runway_length": "8202",
    "elev": "22",
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "UKK",
    "lat": "47.0436",
    "lon": "17.2142",
    "name": "",
    "city": "Ust-kamenogorsk",
    "state": "",
    "country": "Kazakhstan",
    "woeid": "23424871",
    "tz": "Asia\/Qyzylorda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "ULB",
    "lat": "-16.3333",
    "lon": "168.294",
    "name": "Ulei Airport",
    "city": "Port-Vato",
    "state": "Malampa",
    "country": "Vanuatu",
    "woeid": "12523334",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2789",
    "elev": "126",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ULN",
    "lat": "47.8458",
    "lon": "106.759",
    "name": "Ulaanbaatar Southwest Airport",
    "city": "Ulan Bator",
    "state": "Tov",
    "country": "Mongolia",
    "woeid": "12514761",
    "tz": "Asia\/Ulaanbaatar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZMUB",
    "direct_flights": "16",
    "carriers": "7"
  },
  {
    "code": "ULO",
    "lat": "49.9674",
    "lon": "92.0767",
    "name": "Ulaangom",
    "city": "Ulaangom",
    "state": "Uvs",
    "country": "Mongolia",
    "woeid": "12514762",
    "tz": "Asia\/Hovd",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ULP",
    "lat": "-26.6087",
    "lon": "144.255",
    "name": "Quilpie Aerodrome",
    "city": "Quilpie",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510769",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4900",
    "elev": "655",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ULU",
    "lat": "2.81",
    "lon": "32.2683",
    "name": "Gulu Airport",
    "city": "Gulu",
    "state": "Gulu",
    "country": "Uganda",
    "woeid": "12518025",
    "tz": "Africa\/Kampala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10203",
    "elev": "3510",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ULY",
    "lat": "54.4145",
    "lon": "48.8063",
    "name": "Ulyanovsk Northeast Airport",
    "city": "Dimitrovgrad",
    "state": "Ulryanovskaya Oblast",
    "country": "Russia",
    "woeid": "12517077",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UMD",
    "lat": "70.6801",
    "lon": "-52.1221",
    "name": "Uummannaq Airport",
    "city": "Uummannaq",
    "state": "Vestgronland",
    "country": "Greenland",
    "woeid": "12523852",
    "tz": "America\/Godthab",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "2",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UME",
    "lat": "63.7932",
    "lon": "20.2848",
    "name": "Umea Airport",
    "city": "Umea",
    "state": "Vasterbotten",
    "country": "Sweden",
    "woeid": "12517687",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "22",
    "icao": "ESNU",
    "direct_flights": "6",
    "carriers": "7"
  },
  {
    "code": "UNG",
    "lat": "-23.4941",
    "lon": "-46.5488",
    "name": "",
    "city": "Kiunga",
    "state": "Western",
    "country": "Papua New Guinea",
    "woeid": "1061752",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3690",
    "elev": "102",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "UNK",
    "lat": "63.8833",
    "lon": "-160.783",
    "name": "Unalakleet",
    "city": "Unalakleet",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799735",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6004",
    "elev": "21",
    "icao": "PAUN",
    "direct_flights": "4",
    "carriers": "8"
  },
  {
    "code": "UNN",
    "lat": "9.85278",
    "lon": "98.6292",
    "name": "Ranong Airport",
    "city": "Ranong",
    "state": "Ranong",
    "country": "Thailand",
    "woeid": "12523324",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3529",
    "elev": "115",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UPB",
    "lat": "23.0805",
    "lon": "-82.517",
    "name": "Playa Baracoa",
    "city": "Havana",
    "state": "Ciudad de la Habana",
    "country": "Cuba",
    "woeid": "66179",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "UPG",
    "lat": "-5.0694",
    "lon": "119.55",
    "name": "Hasanuddin Airport",
    "city": "Maros",
    "state": "Sulawesi Selatan",
    "country": "Indonesia",
    "woeid": "12513470",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "47",
    "icao": "",
    "direct_flights": "17",
    "carriers": "8"
  },
  {
    "code": "URA",
    "lat": "51.1526",
    "lon": "51.5336",
    "name": "Podstepnyy Airport",
    "city": "Oral",
    "state": "Batys Qazaqstan",
    "country": "Kazakhstan",
    "woeid": "12514438",
    "tz": "Asia\/Aqtobe",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "URC",
    "lat": "43.9333",
    "lon": "87.4667",
    "name": "Diwopu Airport",
    "city": "Urumqi",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "12523254",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10496",
    "elev": "2129",
    "icao": "ZWWW",
    "direct_flights": "47",
    "carriers": "18"
  },
  {
    "code": "URE",
    "lat": "58.2432",
    "lon": "22.5041",
    "name": "Kuressarre Airport",
    "city": "Kingissepa",
    "state": "Saaremaa",
    "country": "Estonia",
    "woeid": "12512737",
    "tz": "Europe\/Tallinn",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.eeke.ee",
    "runway_length": null,
    "elev": null,
    "icao": "EEKE",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "URG",
    "lat": "-29.7817",
    "lon": "-57.0383",
    "name": "Rubem Berta International Airport",
    "city": "Uruguaiana",
    "state": "Rio Grande do Sul",
    "country": "Brazil",
    "woeid": "12511295",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "243",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "URJ",
    "lat": "60.1167",
    "lon": "64.8333",
    "name": "Uraj",
    "city": "Uraj",
    "state": "Khanty-Mansiyskiy Avtonomnyy Okr",
    "country": "Russian Federation",
    "woeid": "2124077",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "URO",
    "lat": "49.3827",
    "lon": "1.26106",
    "name": "Boos Airport",
    "city": "St-Pierre",
    "state": "Haute-Normandie",
    "country": "France",
    "woeid": "12523827",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4101",
    "elev": "515",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "URS",
    "lat": "42.7756",
    "lon": "1.73213",
    "name": "",
    "city": "Kursk",
    "state": "Kurskaya Oblast",
    "country": "Russia",
    "woeid": "2121788",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "URT",
    "lat": "9.1367",
    "lon": "99.1417",
    "name": "Surat Thani Airport",
    "city": "Phun Phin",
    "state": "Surat Thani",
    "country": "Thailand",
    "woeid": "12517786",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "19",
    "icao": "VTSB",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "URY",
    "lat": "31.415",
    "lon": "37.2744",
    "name": "Guriat Airport",
    "city": "Gurayat",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "12517336",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "1671",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "USH",
    "lat": "-54.8176",
    "lon": "-68.326",
    "name": "Ushuaia Airport",
    "city": "Ushuaia",
    "state": "Tierra del Fuego",
    "country": "Argentina",
    "woeid": "12510571",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4763",
    "elev": "33",
    "icao": "SAWH",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "USK",
    "lat": "48.3136",
    "lon": "-117.281",
    "name": "",
    "city": "Usinsk",
    "state": "Komi",
    "country": "Russia",
    "woeid": "2101023",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "USM",
    "lat": "9.55",
    "lon": "100.067",
    "name": "Koh Samui Airport",
    "city": "Ko Samui",
    "state": "Surat Thani",
    "country": "Thailand",
    "woeid": "12517782",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4724",
    "elev": "44",
    "icao": "",
    "direct_flights": "9",
    "carriers": "4"
  },
  {
    "code": "USN",
    "lat": "35.5906",
    "lon": "129.357",
    "name": "Ulsan Airport",
    "city": "Ulsan",
    "state": "Ulsan",
    "country": "South Korea",
    "woeid": "12514234",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "30",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "USQ",
    "lat": "38.681",
    "lon": "29.4716",
    "name": "",
    "city": "Usak",
    "state": "U\u015fak",
    "country": "Turkey",
    "woeid": "2347315",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "USU",
    "lat": "12.1",
    "lon": "120.167",
    "name": "Busuanga Airport",
    "city": "Coron",
    "state": "Western Visayas",
    "country": "Philippines",
    "woeid": "12523295",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "148",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "UTH",
    "lat": "17.3831",
    "lon": "102.794",
    "name": "Udon Airport",
    "city": "Changwat Udon Thani; Udon Thani",
    "state": "Udon Thani",
    "country": "Thailand",
    "woeid": "12517792",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "579",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "UTN",
    "lat": "-28.3986",
    "lon": "21.2528",
    "name": "Pierre Van Ryneveld Airport",
    "city": "Upington",
    "state": "Northern Cape",
    "country": "South Africa",
    "woeid": "12517458",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "16076",
    "elev": "2791",
    "icao": "FAUP",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "UTP",
    "lat": "12.6778",
    "lon": "101.009",
    "name": "Rayong Airport",
    "city": "Ban Chang",
    "state": "Rayong",
    "country": "Thailand",
    "woeid": "12517790",
    "tz": "Asia\/Bangkok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11500",
    "elev": "59",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "UTT",
    "lat": "-31.5472",
    "lon": "28.6694",
    "name": "K D Matanzima Airport",
    "city": "Umtata",
    "state": "Eastern Cape",
    "country": "South Africa",
    "woeid": "12517426",
    "tz": "Africa\/Johannesburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6650",
    "elev": "2400",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UUD",
    "lat": "51.8063",
    "lon": "107.443",
    "name": "Ulan Ude-Mukhino Airport",
    "city": "Kabansk",
    "state": "Buryatiya",
    "country": "Russia",
    "woeid": "12517073",
    "tz": "Asia\/Irkutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8943",
    "elev": "1690",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "UUN",
    "lat": "46.663",
    "lon": "113.287",
    "name": "Baruun Urt Airport",
    "city": "Baruun-Urt",
    "state": "Suhbaatar",
    "country": "Mongolia",
    "woeid": "12514734",
    "tz": "Asia\/Choibalsan",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "UUS",
    "lat": "46.8885",
    "lon": "142.724",
    "name": "Yuzhno Sakhalinsk South Airport",
    "city": "Kholmsk",
    "state": "Sakhalinskaya Oblast",
    "country": "Russia",
    "woeid": "12517272",
    "tz": "Asia\/Vladivostok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8858",
    "elev": "56",
    "icao": "UHSS",
    "direct_flights": "14",
    "carriers": "9"
  },
  {
    "code": "UVE",
    "lat": "-20.5542",
    "lon": "166.571",
    "name": "Ouloup Airport",
    "city": "Fayaou\u00e9",
    "state": "Loyaute",
    "country": "New Caledonia",
    "woeid": "12515040",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3608",
    "elev": "23",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "UVF",
    "lat": "13.7336",
    "lon": "-60.9542",
    "name": "Hewanorra International Airport",
    "city": "Vieux Fort",
    "state": "Vieux Fort",
    "country": "St. Lucia",
    "woeid": "12517595",
    "tz": "America\/St_Lucia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "11",
    "icao": "TLPL",
    "direct_flights": "15",
    "carriers": "11"
  },
  {
    "code": "UWE",
    "lat": "51.4997",
    "lon": "-2.54595",
    "name": "Metropolitan Area",
    "city": "Wiesbaden",
    "state": "Hesse",
    "country": "Germany",
    "woeid": "706329",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "UYL",
    "lat": "10.9342",
    "lon": "25.3411",
    "name": "Janub Darfur",
    "city": "Nyala",
    "state": "Janub Darfur",
    "country": "Sudan",
    "woeid": "20069893",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5300",
    "elev": "2149",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "UYN",
    "lat": "38.2768",
    "lon": "109.769",
    "name": "Yulin Airport",
    "city": "Yulin",
    "state": "Shaanxi",
    "country": "China",
    "woeid": "12512286",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "VAA",
    "lat": "63.0396",
    "lon": "21.7691",
    "name": "Vaasa Airport",
    "city": "Vaasa",
    "state": "Western Finland",
    "country": "Finland",
    "woeid": "12512804",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "15",
    "icao": "EFVA",
    "direct_flights": "2",
    "carriers": "7"
  },
  {
    "code": "VAI",
    "lat": "35.253",
    "lon": "26.2538",
    "name": "",
    "city": "Vanimo",
    "state": "Sandaun",
    "country": "Papua New Guinea",
    "woeid": "1060345",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5250",
    "elev": "10",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "VAK",
    "lat": "61.5338",
    "lon": "-165.584",
    "name": "Chevak Airport",
    "city": "Hooper Bay",
    "state": "Alaska",
    "country": "United States",
    "woeid": "29388570",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2689",
    "elev": "75",
    "icao": "PAVA",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "VAN",
    "lat": "38.4681",
    "lon": "43.3308",
    "name": "Van Airport",
    "city": "Van",
    "state": "Van",
    "country": "Turkey",
    "woeid": "12517920",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8990",
    "elev": "5474",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "VAO",
    "lat": "7.56667",
    "lon": "154.667",
    "name": "Suavanao Airstrip",
    "city": "Suavanao",
    "state": "",
    "country": "Solomon Islands",
    "woeid": "23424766",
    "tz": "Pacific\/Noumea",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VAR",
    "lat": "43.2416",
    "lon": "27.8137",
    "name": "Topoli Airport",
    "city": "Varna",
    "state": "Varna",
    "country": "Bulgaria",
    "woeid": "12511529",
    "tz": "Europe\/Sofia",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.varna-airport.bg\/",
    "runway_length": "8202",
    "elev": "230",
    "icao": "LBWN",
    "direct_flights": "20",
    "carriers": "19"
  },
  {
    "code": "VAS",
    "lat": "39.8147",
    "lon": "36.9039",
    "name": "Sivas Airport",
    "city": "Sivas",
    "state": "Sivas",
    "country": "Turkey",
    "woeid": "12517914",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12500",
    "elev": "5222",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "VAV",
    "lat": "-18.65",
    "lon": "-173.967",
    "name": "Lupepau'u Airport",
    "city": "Neiafu",
    "state": "Vava'eu",
    "country": "Tonga",
    "woeid": "12523256",
    "tz": "Pacific\/Tongatapu",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "VAW",
    "lat": "70.3569",
    "lon": "31.0471",
    "name": "Vardoe Airport",
    "city": "Vardo",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523953",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3281",
    "elev": "20",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "VBM",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Blue Mountain",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VBS",
    "lat": "45.4343",
    "lon": "10.3214",
    "name": "Brescia Montichiari",
    "city": "Verona",
    "state": "Lombardy",
    "country": "Italy",
    "woeid": "711410",
    "tz": "Europe\/Rome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "LIPO",
    "direct_flights": "13",
    "carriers": "7"
  },
  {
    "code": "VBV",
    "lat": "16.4843",
    "lon": "73.746",
    "name": "",
    "city": "Vanuabalavu",
    "state": "",
    "country": "Fiji",
    "woeid": "23424813",
    "tz": "Pacific\/Fiji",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3018",
    "elev": "156",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VBY",
    "lat": "57.6603",
    "lon": "18.3397",
    "name": "Visby Airport",
    "city": "Visby",
    "state": "Gotland",
    "country": "Sweden",
    "woeid": "12517692",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lfv.se\/visby",
    "runway_length": "6562",
    "elev": "167",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "VCE",
    "lat": "45.505",
    "lon": "12.3433",
    "name": "Marco Polo International Airport",
    "city": "Venice",
    "state": "Veneto",
    "country": "Italy",
    "woeid": "23095516",
    "tz": "Europe\/Rome",
    "phone": "+39 041 2606111",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.veniceairport.it",
    "runway_length": "10827",
    "elev": "7",
    "icao": "LIPZ",
    "direct_flights": "70",
    "carriers": "51"
  },
  {
    "code": "VCL",
    "lat": "15.9742",
    "lon": "107.868",
    "name": "Chulai",
    "city": "Tamky",
    "state": "",
    "country": "Vietnam",
    "woeid": "23424984",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "VCP",
    "lat": "-23.0067",
    "lon": "-47.1344",
    "name": "Viracopos International Airport",
    "city": "Campinas",
    "state": "Sao Paulo",
    "country": "Brazil",
    "woeid": "12511374",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10630",
    "elev": "2169",
    "icao": "SBKP",
    "direct_flights": "14",
    "carriers": "11"
  },
  {
    "code": "VCS",
    "lat": "15.9742",
    "lon": "107.868",
    "name": "Vietnam",
    "city": "Con Dao",
    "state": "Ca Mau",
    "country": "Vietnam",
    "woeid": "23424984",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "VCT",
    "lat": "28.8449",
    "lon": "-96.9201",
    "name": "Victoria Regional Airport",
    "city": "Victoria",
    "state": "Texas",
    "country": "United States",
    "woeid": "12522283",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9102",
    "elev": "115",
    "icao": "KVCT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VCV",
    "lat": "34.588",
    "lon": "-117.373",
    "name": "Southern California Logistics Airport",
    "city": "Victorville",
    "state": "California",
    "country": "United States",
    "woeid": "12519884",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "15050",
    "elev": "2885",
    "icao": "KVCV",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VDA",
    "lat": "29.9528",
    "lon": "34.9583",
    "name": "Ovda Airport",
    "city": "Ovda",
    "state": "HaDarom",
    "country": "Israel",
    "woeid": "12513788",
    "tz": "Asia\/Jerusalem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9843",
    "elev": "1490",
    "icao": "LLOV",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VDB",
    "lat": "61.0105",
    "lon": "9.29374",
    "name": "Fagernes Leirin Airport",
    "city": "Fagernes",
    "state": "Oppland Fylke",
    "country": "Norway",
    "woeid": "12515108",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VDC",
    "lat": "-14.8633",
    "lon": "-40.8628",
    "name": "Vitoria da Conquista Airport",
    "city": "Vit\u00f3ria da Conquista",
    "state": "Bahia",
    "country": "Brazil",
    "woeid": "12511375",
    "tz": "America\/Belem",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5827",
    "elev": "2998",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VDE",
    "lat": "27.8145",
    "lon": "-17.885",
    "name": "Valverde Airport",
    "city": "Valverde",
    "state": "Canary Islands",
    "country": "Spain",
    "woeid": "12523969",
    "tz": "Atlantic\/Canary",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3609",
    "elev": "105",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VDM",
    "lat": "-40.8697",
    "lon": "-62.9994",
    "name": "Gobernador Castello Airport",
    "city": "Viedma",
    "state": "Rio Negro",
    "country": "Argentina",
    "woeid": "12510503",
    "tz": "America\/Buenos_Aires",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8431",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VDS",
    "lat": "70.0653",
    "lon": "29.8416",
    "name": "Vadso Airport",
    "city": "Vadso",
    "state": "Finnmark Fylke",
    "country": "Norway",
    "woeid": "12523954",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "127",
    "icao": "ENVD",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "VDZ",
    "lat": "61.1294",
    "lon": "-146.256",
    "name": "Valdez Airport",
    "city": "Valdez",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12522264",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "120",
    "icao": "PAVD",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "VEE",
    "lat": "67.009",
    "lon": "-146.406",
    "name": "Venetie",
    "city": "Venetie",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2511598",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4100",
    "elev": "550",
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "VEL",
    "lat": "40.4485",
    "lon": "-109.514",
    "name": "Vernal Airport",
    "city": "Vernal",
    "state": "Utah",
    "country": "United States",
    "woeid": "12522278",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6601",
    "elev": "5274",
    "icao": "KVEL",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "VER",
    "lat": "19.145",
    "lon": "-96.1878",
    "name": "General Heriberto Jara International Airport",
    "city": "Teocelo",
    "state": "Veracruz-Llave",
    "country": "Mexico",
    "woeid": "12514880",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7874",
    "elev": "95",
    "icao": "KVER",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "VEY",
    "lat": "63.4273",
    "lon": "-20.2682",
    "name": "Vestmannaeyjar Airport",
    "city": "Vestmannaeyjar",
    "state": "Vestmannaeyjar",
    "country": "Iceland",
    "woeid": "12513447",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3904",
    "elev": "328",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VFA",
    "lat": "-18.0931",
    "lon": "25.8403",
    "name": "Victoria Falls Airport",
    "city": "",
    "state": "Matabeleland North",
    "country": "Zimbabwe",
    "woeid": "12523039",
    "tz": "Africa\/Harare",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "3490",
    "icao": "FVFA",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "VGA",
    "lat": "16.5167",
    "lon": "80.8",
    "name": "Vijaywada Airport",
    "city": "Manthena",
    "state": "Andhra Pradesh",
    "country": "India",
    "woeid": "12513694",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "82",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VGO",
    "lat": "42.2258",
    "lon": "-8.63269",
    "name": "Vigo Airport",
    "city": "Vigo",
    "state": "Galicia",
    "country": "Spain",
    "woeid": "12517579",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "866",
    "icao": "LEVX",
    "direct_flights": "10",
    "carriers": "17"
  },
  {
    "code": "VGZ",
    "lat": "1.06667",
    "lon": "-76.7167",
    "name": "Villagarzon Airport",
    "city": "Villagarzon",
    "state": "Putumayo",
    "country": "Colombia",
    "woeid": "12523760",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3412",
    "elev": "2622",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VHC",
    "lat": "-9.6889",
    "lon": "20.43",
    "name": "Saurimo Airport",
    "city": "Saurimo",
    "state": "Lunda Sul",
    "country": "Angola",
    "woeid": "12510452",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11154",
    "elev": "3576",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VHM",
    "lat": "64.5797",
    "lon": "16.8374",
    "name": "Vilhelmina Airport",
    "city": "Vilhelmina",
    "state": "Vasterbotten",
    "country": "Sweden",
    "woeid": "12517691",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3330",
    "elev": "1138",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "VHZ",
    "lat": "-17.6902",
    "lon": "-149.376",
    "name": "French Polynesia",
    "city": "Vahitahi",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VIE",
    "lat": "48.1036",
    "lon": "16.5804",
    "name": "Vienna Schwechat International Airport",
    "city": "Klein-Neusiedl",
    "state": "Lower Austria",
    "country": "Austria",
    "woeid": "22022012",
    "tz": "Europe\/Vienna",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.viennaairport.com",
    "runway_length": "11811",
    "elev": "600",
    "icao": "LOWW",
    "direct_flights": "183",
    "carriers": "96"
  },
  {
    "code": "VIG",
    "lat": "39.2505",
    "lon": "-82.7916",
    "name": "",
    "city": "El Vigia",
    "state": "M\u00e9rida",
    "country": "Venezuela",
    "woeid": "468413",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10630",
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VII",
    "lat": "18.6688",
    "lon": "105.672",
    "name": "Vinh",
    "city": "Vinh City",
    "state": "Nghe An",
    "country": "Vietnam",
    "woeid": "1252662",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "VIJ",
    "lat": "18.4728",
    "lon": "-64.418",
    "name": "Virgin Gorda Airport",
    "city": "Virgin Gorda",
    "state": "",
    "country": "British Virgin Islands",
    "woeid": "12522861",
    "tz": "America\/Tortola",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3160",
    "elev": "12",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "VIL",
    "lat": "23.7333",
    "lon": "-15.9167",
    "name": "Dakhla Airport",
    "city": "Ad Dakhla",
    "state": "Oued Ed-Dahab",
    "country": "Western Sahara",
    "woeid": "12514785",
    "tz": "Africa\/El_Aaiun",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9842",
    "elev": "33",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "VIS",
    "lat": "36.3253",
    "lon": "-119.394",
    "name": "Visalia Municipal Airport",
    "city": "Visalia",
    "state": "California",
    "country": "United States",
    "woeid": "12522291",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6556",
    "elev": "292",
    "icao": "KVIS",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VIT",
    "lat": "42.8844",
    "lon": "-2.73051",
    "name": "Vitoria Airport",
    "city": "Vitoria",
    "state": "Basque Country",
    "country": "Spain",
    "woeid": "12517580",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "1683",
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "VIX",
    "lat": "-20.2578",
    "lon": "-40.2864",
    "name": "Goiabeiras Airport",
    "city": "Vitoria",
    "state": "Espirito Santo",
    "country": "Brazil",
    "woeid": "12511159",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6138",
    "elev": "11",
    "icao": "SBVT",
    "direct_flights": "6",
    "carriers": "7"
  },
  {
    "code": "VKG",
    "lat": "9.945",
    "lon": "105.135",
    "name": "Kien Giang Airport",
    "city": "Kien Giang",
    "state": "Kien Giang",
    "country": "Vietnam",
    "woeid": "12522921",
    "tz": "Asia\/Ho_Chi_Minh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3767",
    "elev": "13",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VKO",
    "lat": "55.5978",
    "lon": "37.285",
    "name": "Ynukovo Airport",
    "city": "Podol'sk",
    "state": "Moskovskaya Oblast",
    "country": "Russia",
    "woeid": "12517260",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10007",
    "elev": "669",
    "icao": "UUWW",
    "direct_flights": "79",
    "carriers": "24"
  },
  {
    "code": "VKT",
    "lat": "67.491",
    "lon": "63.995",
    "name": "Vorkuta Airport",
    "city": "Ukhta",
    "state": "Komi",
    "country": "Russia",
    "woeid": "12517192",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "VLC",
    "lat": "39.4929",
    "lon": "-0.47617",
    "name": "Valencia Airport",
    "city": "Manises",
    "state": "Valencia",
    "country": "Spain",
    "woeid": "12517577",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aena.es",
    "runway_length": "8858",
    "elev": "226",
    "icao": "LEVC",
    "direct_flights": "75",
    "carriers": "36"
  },
  {
    "code": "VLD",
    "lat": "30.7892",
    "lon": "-83.2787",
    "name": "Valdosta Regional Airport",
    "city": "Valdosta",
    "state": "Georgia",
    "country": "United States",
    "woeid": "12522265",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6302",
    "elev": "204",
    "icao": "KVLD",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "VLI",
    "lat": "-17.7039",
    "lon": "168.312",
    "name": "Port Vila Bauerfield Airport",
    "city": "Port-Vila",
    "state": "Shefa",
    "country": "Vanuatu",
    "woeid": "12515052",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8530",
    "elev": "66",
    "icao": "NVVV",
    "direct_flights": "22",
    "carriers": "7"
  },
  {
    "code": "VLL",
    "lat": "41.7077",
    "lon": "-4.84504",
    "name": "Valladolid Airport",
    "city": "Valladolid",
    "state": "Castille and Leon",
    "country": "Spain",
    "woeid": "12517578",
    "tz": "Europe\/Madrid",
    "phone": "00 34 983 415 500",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aena.es",
    "runway_length": "9843",
    "elev": "2785",
    "icao": "KVLL",
    "direct_flights": "13",
    "carriers": "5"
  },
  {
    "code": "VLN",
    "lat": "10.1556",
    "lon": "-67.9319",
    "name": "Zim Valencia Airport",
    "city": "Valencia",
    "state": "Carabobo",
    "country": "Venezuela",
    "woeid": "12522858",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "1410",
    "icao": "",
    "direct_flights": "8",
    "carriers": "10"
  },
  {
    "code": "VLS",
    "lat": "-15.1468",
    "lon": "167.038",
    "name": "Vanuatu",
    "city": "Valesdir",
    "state": "Shefa",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1968",
    "elev": "36",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VLV",
    "lat": "9.3414",
    "lon": "-70.5844",
    "name": "Dr Antonio Nicolas Briceno Airport",
    "city": "Siquisique",
    "state": "Trujillo",
    "country": "Venezuela",
    "woeid": "12522773",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6726",
    "elev": "2060",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VLY",
    "lat": "52.3685",
    "lon": "-3.98509",
    "name": "Wales",
    "city": "Anglesey",
    "state": "Wales",
    "country": "United Kingdom",
    "woeid": "12578049",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VNO",
    "lat": "54.64",
    "lon": "25.2803",
    "name": "Vilnius Airport",
    "city": "Vilnius",
    "state": "Vilniaus apskritis",
    "country": "Lithuania",
    "woeid": "12523042",
    "tz": "Europe\/Vilnius",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.vilnius-airport.lt\/",
    "runway_length": "8202",
    "elev": "623",
    "icao": "EYVI",
    "direct_flights": "39",
    "carriers": "27"
  },
  {
    "code": "VNS",
    "lat": "25.4541",
    "lon": "82.8642",
    "name": "Varanasi Airport",
    "city": "Baragaon",
    "state": "Uttar Pradesh",
    "country": "India",
    "woeid": "12513693",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5880",
    "elev": "262",
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "VNX",
    "lat": "-22.0142",
    "lon": "35.3119",
    "name": "Vilanculos Airport",
    "city": "Vilanculos",
    "state": "Inhambane",
    "country": "Mozambique",
    "woeid": "12515033",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4823",
    "elev": "46",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "VOG",
    "lat": "48.7788",
    "lon": "44.3382",
    "name": "Gumrak Airport",
    "city": "Kamyshin",
    "state": "Volgogradskaya Oblast",
    "country": "Russia",
    "woeid": "12516023",
    "tz": "Asia\/Yekaterinburg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "476",
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "VOL",
    "lat": "39.2203",
    "lon": "22.7947",
    "name": "Nea Anchialos Airport",
    "city": "Almiros",
    "state": "Thessalia",
    "country": "Greece",
    "woeid": "12513314",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "90",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VOZ",
    "lat": "51.8089",
    "lon": "39.2242",
    "name": "Voronezh-Chertovitskoye Airport",
    "city": "Semiluki",
    "state": "Voronezhskaya Oblast",
    "country": "Russia",
    "woeid": "12517197",
    "tz": "Europe\/Moscow",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "3"
  },
  {
    "code": "VPE",
    "lat": "-17.0704",
    "lon": "15.7258",
    "name": "Ondjiva",
    "city": "Ongiva",
    "state": "Cunene",
    "country": "Angola",
    "woeid": "1263141",
    "tz": "Africa\/Luanda",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8120",
    "elev": "3641",
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "VPN",
    "lat": "65.7177",
    "lon": "-14.8348",
    "name": "Vopnafjordur Airport",
    "city": "Egilsstadir",
    "state": "Nordur-Mulasysla",
    "country": "Iceland",
    "woeid": "12523906",
    "tz": "Atlantic\/Reykjavik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3268",
    "elev": "31",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VPS",
    "lat": "30.496",
    "lon": "-86.55",
    "name": "Eglin Air Force Base",
    "city": "Eglin Village",
    "state": "Florida",
    "country": "United States",
    "woeid": "12519601",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12000",
    "elev": "85",
    "icao": "KVPS",
    "direct_flights": "8",
    "carriers": "12"
  },
  {
    "code": "VPY",
    "lat": "-19.1506",
    "lon": "33.4311",
    "name": "Chimoio Airport",
    "city": "Chimoio",
    "state": "Manica",
    "country": "Mozambique",
    "woeid": "12515015",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VQS",
    "lat": "18.1355",
    "lon": "-65.487",
    "name": "Aeropuerto Antonio Rivera Rodr\u00edguez",
    "city": "Puerto Ferro",
    "state": "Puerto Rico",
    "country": "United States",
    "woeid": "12524420",
    "tz": "America\/Puerto_Rico",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "46",
    "icao": "TJVQ",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "VRA",
    "lat": "23.0306",
    "lon": "-81.4367",
    "name": "Varadero Airport",
    "city": "Varadero",
    "state": "Matanzas",
    "country": "Cuba",
    "woeid": "12512489",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7382",
    "elev": "39",
    "icao": "MUVR",
    "direct_flights": "17",
    "carriers": "10"
  },
  {
    "code": "VRC",
    "lat": "13.5792",
    "lon": "124.204",
    "name": "Virac Airport",
    "city": "Virac",
    "state": "Bicol Region",
    "country": "Philippines",
    "woeid": "12515654",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5118",
    "elev": "121",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "VRK",
    "lat": "62.1742",
    "lon": "27.8658",
    "name": "Varkaus Airport",
    "city": "Varkaus",
    "state": "Eastern Finland",
    "country": "Finland",
    "woeid": "12512805",
    "tz": "Europe\/Helsinki",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ilmailulaitos.fi\/airport_varkaus",
    "runway_length": "6562",
    "elev": "286",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VRN",
    "lat": "45.4033",
    "lon": "10.9053",
    "name": "Verona Airport",
    "city": "Sommacampagna",
    "state": "Veneto",
    "country": "Italy",
    "woeid": "22592277",
    "tz": "Europe\/Rome",
    "phone": "+39 045 8095666",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.aeroportoverona.it",
    "runway_length": "9794",
    "elev": "238",
    "icao": "",
    "direct_flights": "38",
    "carriers": "28"
  },
  {
    "code": "VRY",
    "lat": "67.6667",
    "lon": "12.6833",
    "name": "Vaeroy Airport",
    "city": "Varoy",
    "state": "Nordland Fylke",
    "country": "Norway",
    "woeid": "12523955",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2625",
    "elev": "36",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VSA",
    "lat": "17.9958",
    "lon": "-92.8189",
    "name": "Villahermosa Airport",
    "city": "Villahermosa",
    "state": "Tabasco",
    "country": "Mexico",
    "woeid": "12514984",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "43",
    "icao": "MMVA",
    "direct_flights": "7",
    "carriers": "15"
  },
  {
    "code": "VSG",
    "lat": "48.5667",
    "lon": "39.3333",
    "name": "Lugansk Airport",
    "city": "Luhans'k",
    "state": "Luhans\u00b4ka Oblast\u00b4",
    "country": "Ukraine",
    "woeid": "12524015",
    "tz": "Europe\/Kiev",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "UKCW",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "VST",
    "lat": "59.6007",
    "lon": "16.6367",
    "name": "Hasslo Airport",
    "city": "Vasteras",
    "state": "Vastmanland",
    "country": "Sweden",
    "woeid": "12517636",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "20",
    "icao": "",
    "direct_flights": "8",
    "carriers": "3"
  },
  {
    "code": "VTE",
    "lat": "17.9833",
    "lon": "102.565",
    "name": "Vientiane Airport",
    "city": "Vientiane",
    "state": "Viangchan",
    "country": "Laos",
    "woeid": "12514560",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10499",
    "elev": "559",
    "icao": "VLVT",
    "direct_flights": "12",
    "carriers": "6"
  },
  {
    "code": "VTU",
    "lat": "20.9894",
    "lon": "-76.9361",
    "name": "Las Tunas Airport",
    "city": "Becerra",
    "state": "Las Tunas",
    "country": "Cuba",
    "woeid": "12512470",
    "tz": "America\/Havana",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5906",
    "elev": "312",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VTZ",
    "lat": "17.7214",
    "lon": "83.2201",
    "name": "Vishakapatnam Airport",
    "city": "Visakhapatnam",
    "state": "Andhra Pradesh",
    "country": "India",
    "woeid": "12513695",
    "tz": "Asia\/Kolkata",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "14",
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "VUP",
    "lat": "10.4433",
    "lon": "-73.245",
    "name": "Alfonso Lopez Airport",
    "city": "Valledupar",
    "state": "Cesar",
    "country": "Colombia",
    "woeid": "12512361",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "453",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "VVC",
    "lat": "4.1708",
    "lon": "-73.6117",
    "name": "Vanguardia Airport",
    "city": "Villavicencio",
    "state": "Meta",
    "country": "Colombia",
    "woeid": "12512423",
    "tz": "America\/Bogota",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5576",
    "elev": "1388",
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "VVI",
    "lat": "-17.6411",
    "lon": "-63.1339",
    "name": "Viru Viru International Airport",
    "city": "Santa Cruz",
    "state": "Santa Cruz",
    "country": "Bolivia",
    "woeid": "12510911",
    "tz": "America\/La_Paz",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11483",
    "elev": "1224",
    "icao": "SLVR",
    "direct_flights": "14",
    "carriers": "9"
  },
  {
    "code": "VVO",
    "lat": "43.3972",
    "lon": "132.156",
    "name": "Artem North Airport",
    "city": "Nakhodka",
    "state": "Primorskiy Kray",
    "country": "Russia",
    "woeid": "12515734",
    "tz": "Asia\/Vladivostok",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.vladavia.ru\/avia_eng\/company_airport.php3",
    "runway_length": null,
    "elev": null,
    "icao": "UHWW",
    "direct_flights": "29",
    "carriers": "14"
  },
  {
    "code": "VVZ",
    "lat": "26.5001",
    "lon": "8.47094",
    "name": "Illizi",
    "city": "Illizi",
    "state": "Illizi",
    "country": "Algeria",
    "woeid": "1255066",
    "tz": "Africa\/Algiers",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5889",
    "elev": "1778",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "VXC",
    "lat": "-13.2761",
    "lon": "35.2533",
    "name": "Lichinga Airport",
    "city": "Lichinga",
    "state": "Niassa",
    "country": "Mozambique",
    "woeid": "12515019",
    "tz": "Africa\/Maputo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "4505",
    "icao": "FQLC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "VXE",
    "lat": "16.8306",
    "lon": "-25.0636",
    "name": "San Pedro Airport",
    "city": "Mindelo",
    "state": "Sao Vicente",
    "country": "Cape Verde",
    "woeid": "12512495",
    "tz": "Atlantic\/Cape_Verde",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "66",
    "icao": "GVSV",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "VXO",
    "lat": "56.9242",
    "lon": "14.7318",
    "name": "Kronoberg Airport",
    "city": "Vaxjo",
    "state": "Kronoberg",
    "country": "Sweden",
    "woeid": "12517654",
    "tz": "Europe\/Stockholm",
    "phone": "+46(0)470758500",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.smalandairport.se",
    "runway_length": "7546",
    "elev": "610",
    "icao": "ESMX",
    "direct_flights": "9",
    "carriers": "5"
  },
  {
    "code": "WAA",
    "lat": "65.6112",
    "lon": "-168.098",
    "name": "Wales",
    "city": "Wales",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2513417",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "25",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "WAE",
    "lat": "23.9175",
    "lon": "45.1202",
    "name": "Saudi Arabia",
    "city": "Wadi Ad Dawasir",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "23424938",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WAG",
    "lat": "-39.9635",
    "lon": "175.029",
    "name": "Wanganui Airport",
    "city": "Wanganui",
    "state": "Manawatu Wanganui",
    "country": "New Zealand",
    "woeid": "12515174",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "28",
    "icao": "NZWU",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WAQ",
    "lat": "-15.7137",
    "lon": "46.3155",
    "name": "Mahajanga",
    "city": "Antsalova",
    "state": "Mahajanga",
    "country": "Madagascar",
    "woeid": "1361960",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3018",
    "elev": "551",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WAT",
    "lat": "52.1972",
    "lon": "-7.0889",
    "name": "Waterford Airport",
    "city": "Waterford",
    "state": "",
    "country": "Ireland",
    "woeid": "12512728",
    "tz": "Europe\/Dublin",
    "phone": "+35351875589",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flywaterford.com",
    "runway_length": "4701",
    "elev": "122",
    "icao": "EIWF",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "WAW",
    "lat": "52.17",
    "lon": "20.9725",
    "name": "Okecie International Airport",
    "city": "Warsaw",
    "state": "Mazowieckie",
    "country": "Poland",
    "woeid": "12515363",
    "tz": "Europe\/Warsaw",
    "phone": "+48 (22) 650 42 20",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lotnisko-chopina.pl\/",
    "runway_length": "9852",
    "elev": "354",
    "icao": "EPWA",
    "direct_flights": "102",
    "carriers": "60"
  },
  {
    "code": "WBB",
    "lat": "63.52",
    "lon": "-162.279",
    "name": "Stebbins",
    "city": "Stebbins",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2499397",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2800",
    "elev": "26",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "WBQ",
    "lat": "66.359",
    "lon": "-147.396",
    "name": "Alaska",
    "city": "Beaver",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3800",
    "elev": "365",
    "icao": "PAWB",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "WDH",
    "lat": "-22.4792",
    "lon": "17.4722",
    "name": "Windhoek Airport",
    "city": "",
    "state": "Khomas",
    "country": "Namibia",
    "woeid": "12522994",
    "tz": "Africa\/Windhoek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "15010",
    "elev": "5640",
    "icao": "FYWH",
    "direct_flights": "9",
    "carriers": "7"
  },
  {
    "code": "WEF",
    "lat": "36.3306",
    "lon": "118.761",
    "name": "Shandong",
    "city": "Weifang",
    "state": "Shandong",
    "country": "China",
    "woeid": "12578014",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "WEH",
    "lat": "36.3306",
    "lon": "118.761",
    "name": "Shandong",
    "city": "Weihai",
    "state": "Shandong",
    "country": "China",
    "woeid": "12578014",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "7"
  },
  {
    "code": "WEI",
    "lat": "-12.6773",
    "lon": "141.923",
    "name": "Weipa Aerodrome",
    "city": "Weipa",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510809",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5400",
    "elev": "59",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WGA",
    "lat": "-35.159",
    "lon": "147.467",
    "name": "Wagga Wagga Airport",
    "city": "Forest Hill",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510802",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5800",
    "elev": "726",
    "icao": "YSWG",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "WGE",
    "lat": "-30.0331",
    "lon": "148.118",
    "name": "Walgett Airport",
    "city": "Walgett",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "12510803",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5335",
    "elev": "439",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "WGP",
    "lat": "-9.6722",
    "lon": "120.308",
    "name": "Mau Hau Airport",
    "city": "Waingapu",
    "state": "Nusa Tengarra Timur",
    "country": "Indonesia",
    "woeid": "12513482",
    "tz": "Asia\/Makassar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "39",
    "icao": "WRRW",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "WHF",
    "lat": "21.7929",
    "lon": "31.3713",
    "name": "Wadi Halfa'",
    "city": "Wadi Halfa",
    "state": "Ash Shamaliyah",
    "country": "Sudan",
    "woeid": "1437351",
    "tz": "Africa\/Khartoum",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WHK",
    "lat": "-37.9213",
    "lon": "176.919",
    "name": "Whakatane Airport",
    "city": "Whakatane",
    "state": "Bay Of Plenty",
    "country": "New Zealand",
    "woeid": "12515177",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "20",
    "icao": "NZWK",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WIC",
    "lat": "58.4538",
    "lon": "-3.08767",
    "name": "Wick Airport",
    "city": "Wick",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "22494239",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5279",
    "elev": "127",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "WIL",
    "lat": "-1.31888",
    "lon": "36.8157",
    "name": "Nairobi Wilson Airport",
    "city": "Nairobi",
    "state": "Nairobi Area",
    "country": "Kenya",
    "woeid": "12514078",
    "tz": "Africa\/Nairobi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4800",
    "elev": "5536",
    "icao": "HKNW",
    "direct_flights": "9",
    "carriers": "2"
  },
  {
    "code": "WIN",
    "lat": "-22.3635",
    "lon": "143.082",
    "name": "Winton Aerodrome",
    "city": "Corfield",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510815",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "638",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WJA",
    "lat": "7.46667",
    "lon": "168.567",
    "name": "Woja",
    "city": "Woja",
    "state": "Ailinglaplap",
    "country": "Marshall Islands",
    "woeid": "22503997",
    "tz": "Pacific\/Kwajalein",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WJU",
    "lat": "37.4904",
    "lon": "127.989",
    "name": "Wonju Airport",
    "city": "WonJu",
    "state": "Kangwon-Do",
    "country": "South Korea",
    "woeid": "28808960",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WKA",
    "lat": "-44.725",
    "lon": "169.243",
    "name": "Wanaka Airport",
    "city": "Wanaka",
    "state": "Otago",
    "country": "New Zealand",
    "woeid": "12515173",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2545",
    "elev": "1143",
    "icao": "NZWF",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WKJ",
    "lat": "45.4041",
    "lon": "141.801",
    "name": "Wakkanai Airport",
    "city": "Wakkanai-shi",
    "state": "Hokkaido Prefecture",
    "country": "Japan",
    "woeid": "28360512",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "24",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "WKK",
    "lat": "59.2667",
    "lon": "-158.633",
    "name": "Aleknagik",
    "city": "Aleknagik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12799627",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1800",
    "elev": "75",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WLG",
    "lat": "-41.3295",
    "lon": "174.806",
    "name": "Wellington International Airport",
    "city": "Wellington",
    "state": "Wellington",
    "country": "New Zealand",
    "woeid": "12515175",
    "tz": "Pacific\/Auckland",
    "phone": "+64 4 385 5100",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.wellington-airport.co.nz\/",
    "runway_length": "6350",
    "elev": "40",
    "icao": "NZWN",
    "direct_flights": "26",
    "carriers": "14"
  },
  {
    "code": "WLH",
    "lat": "-15.1468",
    "lon": "167.038",
    "name": "Vanuatu",
    "city": "Walaha",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2165",
    "elev": "151",
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "WLK",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Selawik",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "25",
    "icao": "PASK",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "WLS",
    "lat": "-13.3",
    "lon": "-176.167",
    "name": "Wallis Island",
    "city": "Wallis Island",
    "state": "Hahake",
    "country": "Wallis and Futuna Islands",
    "woeid": "22503938",
    "tz": "Pacific\/Wallis",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6561",
    "elev": "75",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "WMK",
    "lat": "55.7389",
    "lon": "-132.258",
    "name": "Meyers Chuck",
    "city": "Meyers Chuck",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2449996",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WMN",
    "lat": "-15.4367",
    "lon": "49.6883",
    "name": "Maroantsetra Airport",
    "city": "Maroantsetra",
    "state": "Toamasina",
    "country": "Madagascar",
    "woeid": "12514702",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4265",
    "elev": "13",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "WMO",
    "lat": "64.6811",
    "lon": "-163.408",
    "name": "White Mountain",
    "city": "White Mountain",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2519364",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "262",
    "icao": "",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "WMR",
    "lat": "-16.167",
    "lon": "49.7556",
    "name": "Mananara",
    "city": "Mananara",
    "state": "Toamasina",
    "country": "Madagascar",
    "woeid": "1362331",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2543",
    "elev": "10",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WNA",
    "lat": "60.7049",
    "lon": "-161.766",
    "name": "Napaskiak",
    "city": "Napakiak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2456992",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2142",
    "elev": "20",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "WNN",
    "lat": "52.9228",
    "lon": "-89.1934",
    "name": "Wunnummin Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524051",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "WNP",
    "lat": "13.5872",
    "lon": "123.27",
    "name": "Naga Airport",
    "city": "Pili",
    "state": "Bicol Region",
    "country": "Philippines",
    "woeid": "12515636",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4205",
    "elev": "141",
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "WNR",
    "lat": "-25.4104",
    "lon": "142.67",
    "name": "Windorah Airport",
    "city": "Tanbar",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510814",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4508",
    "elev": "452",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WNS",
    "lat": "26.2194",
    "lon": "68.3922",
    "name": "Nawabshah Airport",
    "city": "Nawabshah",
    "state": "Sindh",
    "country": "Pakistan",
    "woeid": "12515254",
    "tz": "Asia\/Karachi",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "9022",
    "elev": "93",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WNZ",
    "lat": "28.0025",
    "lon": "120.648",
    "name": "Wenzhou Airport",
    "city": "Wenzhou",
    "state": "Zhejiang",
    "country": "China",
    "woeid": "12523455",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "27",
    "carriers": "14"
  },
  {
    "code": "WPM",
    "lat": "-8.79133",
    "lon": "142.869",
    "name": "Wipim",
    "city": "Wipim",
    "state": "Western",
    "country": "Papua New Guinea",
    "woeid": "1061923",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1640",
    "elev": "100",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WRE",
    "lat": "-35.7705",
    "lon": "174.36",
    "name": "Whangarei Airport",
    "city": "Whangarei",
    "state": "Northland",
    "country": "New Zealand",
    "woeid": "12515178",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3600",
    "elev": "133",
    "icao": "NZWR",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WRG",
    "lat": "56.4876",
    "lon": "-132.378",
    "name": "Wrangell Airport",
    "city": "Wrangell",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12522517",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Sea Plane Base",
    "email": "",
    "url": "",
    "runway_length": "6003",
    "elev": "44",
    "icao": "PAWG",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "WRL",
    "lat": "43.9747",
    "lon": "-107.953",
    "name": "Worland Municipal Airport",
    "city": "Worland",
    "state": "Wyoming",
    "country": "United States",
    "woeid": "12522515",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7004",
    "elev": "4245",
    "icao": "KWRL",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "WRO",
    "lat": "51.1039",
    "lon": "16.8985",
    "name": "Strachowice Airport",
    "city": "Wroclaw",
    "state": "Dolnosl\u0105skie",
    "country": "Poland",
    "woeid": "12515390",
    "tz": "Europe\/Warsaw",
    "phone": "+48 713581100",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.airport.wroclaw.pl",
    "runway_length": null,
    "elev": null,
    "icao": "EPWR",
    "direct_flights": "26",
    "carriers": "12"
  },
  {
    "code": "WSN",
    "lat": "58.7285",
    "lon": "-157.014",
    "name": "Naknek",
    "city": "South Naknek",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2456884",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "130",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WST",
    "lat": "41.3492",
    "lon": "-71.8115",
    "name": "Westerly State Airport",
    "city": "Westerly",
    "state": "Rhode Island",
    "country": "United States",
    "woeid": "12522397",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "81",
    "icao": "KWST",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WSX",
    "lat": "48.8907",
    "lon": "-116.982",
    "name": "United States",
    "city": "Westsound",
    "state": "Washington",
    "country": "United States",
    "woeid": "23424977",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "WSZ",
    "lat": "-41.7383",
    "lon": "171.58",
    "name": "Westport Airport",
    "city": "Westport",
    "state": "West Coast",
    "country": "New Zealand",
    "woeid": "12515176",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "13",
    "icao": "NZWS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WTK",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Noatak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "99",
    "icao": "PAWN",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "WTL",
    "lat": "60.3432",
    "lon": "-162.664",
    "name": "Tuntutuliak",
    "city": "Tuntutuliak",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2508647",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "4"
  },
  {
    "code": "WTS",
    "lat": "-18.9076",
    "lon": "47.5277",
    "name": "Antananarivo",
    "city": "Tsiroanomandidy",
    "state": "Antananarivo",
    "country": "Madagascar",
    "woeid": "1358594",
    "tz": "Indian\/Antananarivo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "WUA",
    "lat": "34.668",
    "lon": "104.166",
    "name": "Wu Hai",
    "city": "Wu Hai",
    "state": "",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WUH",
    "lat": "30.5069",
    "lon": "114.31",
    "name": "Wuchang Nanhu Airport",
    "city": "Wuhan",
    "state": "Hubei",
    "country": "China",
    "woeid": "12512230",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZHHH",
    "direct_flights": "42",
    "carriers": "19"
  },
  {
    "code": "WUN",
    "lat": "20.0564",
    "lon": "78.9567",
    "name": "",
    "city": "Wiluna",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "1106702",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5308",
    "elev": "1635",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "WUS",
    "lat": "34.668",
    "lon": "104.166",
    "name": "Wuyishan",
    "city": "Wuyishan",
    "state": "",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "5"
  },
  {
    "code": "WUX",
    "lat": "31.574",
    "lon": "120.288",
    "name": "Wuxi",
    "city": "Wuxi",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "2137083",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "13",
    "carriers": "5"
  },
  {
    "code": "WVB",
    "lat": "22.9667",
    "lon": "14.4833",
    "name": "Rooikop",
    "city": "Walvis Bay",
    "state": "Murzuq",
    "country": "South Africa",
    "woeid": "1353302",
    "tz": "Africa\/Windhoek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "290",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "WWK",
    "lat": "-3.5858",
    "lon": "143.667",
    "name": "Wewak International Airport",
    "city": "Wewak",
    "state": "East Sepik",
    "country": "Papua New Guinea",
    "woeid": "12515480",
    "tz": "Pacific\/Port_Moresby",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "19",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "WWP",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Whale Pass",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Juneau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WWT",
    "lat": "62.8903",
    "lon": "-149.054",
    "name": "Alaska",
    "city": "Newtok",
    "state": "Alaska",
    "country": "United States",
    "woeid": "2347560",
    "tz": "America\/Nome",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2153",
    "elev": "25",
    "icao": "",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "WXN",
    "lat": "30.811",
    "lon": "108.375",
    "name": "Wanxian",
    "city": "Wanxian",
    "state": "Chongqing",
    "country": "China",
    "woeid": "2158443",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "WYA",
    "lat": "-33.0608",
    "lon": "137.516",
    "name": "Whyalla Airport",
    "city": "Whyalla",
    "state": "South Australia",
    "country": "Australia",
    "woeid": "12510812",
    "tz": "Australia\/Adelaide",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5530",
    "elev": "41",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "WYS",
    "lat": "44.6876",
    "lon": "-111.114",
    "name": "Yellowstone Airport",
    "city": "West Yellowstone",
    "state": "Montana",
    "country": "United States",
    "woeid": "12522531",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8399",
    "elev": "6644",
    "icao": "KWYS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XAD",
    "lat": "44.4547",
    "lon": "-79.5139",
    "name": "Churchill Rail Station",
    "city": "Oro-Medonte",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "23397643",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XAP",
    "lat": "-27.1325",
    "lon": "-52.6611",
    "name": "Chapeco Airport",
    "city": "Chapeco",
    "state": "Santa Catarina",
    "country": "Brazil",
    "woeid": "12511090",
    "tz": "America\/Sao_Paulo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4921",
    "elev": "2133",
    "icao": "SBCH",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "XAW",
    "lat": "46.7052",
    "lon": "-80.9219",
    "name": "Capreol Rail Service",
    "city": "Capreol",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "232",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XAX",
    "lat": "45.4389",
    "lon": "-73.7302",
    "name": "Dorval Rail Station",
    "city": "Dorval",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "417",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XAZ",
    "lat": "47.9955",
    "lon": "-66.6672",
    "name": "Cambellton",
    "city": "Cambellton",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "4392",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XBE",
    "lat": "53.9214",
    "lon": "-90.985",
    "name": "Bearskin Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524054",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3600",
    "elev": "826",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XBJ",
    "lat": "32.8983",
    "lon": "59.2631",
    "name": "Birjand Airport",
    "city": "Birjand",
    "state": "Khorasan",
    "country": "Iran",
    "woeid": "12513708",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6890",
    "elev": "4823",
    "icao": "OIMB",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "XBR",
    "lat": "44.6353",
    "lon": "-75.7502",
    "name": "Brockville Airport",
    "city": "Brockville",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511588",
    "tz": "",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3516",
    "elev": "398",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XCH",
    "lat": "-10.4503",
    "lon": "105.689",
    "name": "Christmas Island Airport",
    "city": "The Settlement",
    "state": "",
    "country": "Christmas Island",
    "woeid": "12514237",
    "tz": "Indian\/Christmas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XCI",
    "lat": "49.3771",
    "lon": "-84.7493",
    "name": "Ontario",
    "city": "Oro-Medonte",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2344922",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XCM",
    "lat": "42.4",
    "lon": "-82.1833",
    "name": "Chatham Airport",
    "city": "Chatham",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524057",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3838",
    "elev": "650",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XCR",
    "lat": "48.7761",
    "lon": "4.1917",
    "name": "Europort Vatry",
    "city": "Coole",
    "state": "Champagne-Ardenne",
    "country": "France",
    "woeid": "12512984",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XDB",
    "lat": "50.6333",
    "lon": "3.06667",
    "name": "Lille-Europe Railway Station",
    "city": "Lille",
    "state": "Nord-Pas-de-Calais",
    "country": "France",
    "woeid": "12725933",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "11"
  },
  {
    "code": "XDD",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "",
    "city": "",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XDG",
    "lat": "44.6462",
    "lon": "-63.5739",
    "name": "Halifax Rail Service",
    "city": "Halifax",
    "state": "Nova Scotia",
    "country": "Canada",
    "woeid": "4177",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XDH",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "",
    "city": "",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XDM",
    "lat": "45.8527",
    "lon": "-72.3912",
    "name": "Drummondville Airport",
    "city": "Drummondville",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511642",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XDP",
    "lat": "46.0884",
    "lon": "-64.7783",
    "name": "Moncton Rail Service",
    "city": "Moncton",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "3796",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XDQ",
    "lat": "42.9869",
    "lon": "-81.2462",
    "name": "London Ontario Rail Service",
    "city": "London",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4063",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "XDS",
    "lat": "49.3771",
    "lon": "-84.7493",
    "name": "Ontario",
    "city": "Oro-Medonte",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2344922",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "XDU",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "",
    "city": "",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XDV",
    "lat": "53.9167",
    "lon": "-1.76667",
    "name": "",
    "city": "Prince George",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "9537",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XDW",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "Canada",
    "city": "Prince Rupert",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XDX",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "Sarina Rail Station",
    "city": "Sarina",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XDY",
    "lat": "46.5",
    "lon": "-80.9667",
    "name": "",
    "city": "Sudbury",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "3198",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XDZ",
    "lat": "53.8134",
    "lon": "-101.25",
    "name": "The Pas Rail Service",
    "city": "The Pas",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "2331",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XEA",
    "lat": "49.2604",
    "lon": "-123.114",
    "name": "Vancouver Rail Service",
    "city": "Vancouver",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "9807",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XEC",
    "lat": "42.3178",
    "lon": "-83.0339",
    "name": "Windsor Ontario Rail Service",
    "city": "Windsor",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4079",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XED",
    "lat": "48.8669",
    "lon": "2.78194",
    "name": "Disneyland Railway Station",
    "city": "Paris",
    "state": "\u00cele-de-France",
    "country": "France",
    "woeid": "615702",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XEE",
    "lat": "47.6602",
    "lon": "-72.2718",
    "name": "Lac Edouard Rail Service",
    "city": "Lac Edouard",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "788",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XEF",
    "lat": "49.8994",
    "lon": "-97.1407",
    "name": "Winnipeg Rail Service",
    "city": "Winnipeg",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "4079",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XEG",
    "lat": "44.2315",
    "lon": "-76.4793",
    "name": "Kingston Rail Service",
    "city": "Kingston",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4145",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "XEH",
    "lat": "48.9974",
    "lon": "-123.821",
    "name": "Ladysmith Rail Service",
    "city": "Ladysmith",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "9830",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XEK",
    "lat": "54.4994",
    "lon": "-105.684",
    "name": "Saskatchewan",
    "city": "Melville",
    "state": "Saskatchewan",
    "country": "Canada",
    "woeid": "2344925",
    "tz": "America\/Regina",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XEL",
    "lat": "53.8901",
    "lon": "-68.4293",
    "name": "Quebec",
    "city": "New Carlisle",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "2344924",
    "tz": "America\/Montreal",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XER",
    "lat": "48.5851",
    "lon": "7.73643",
    "name": "Strasbourg Bus Station",
    "city": "Strasbourg",
    "state": "Alsace",
    "country": "France",
    "woeid": "627791",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Bus Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XET",
    "lat": "51.5039",
    "lon": "0.04981",
    "name": "London City Airport",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22475376",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XEV",
    "lat": "59.3322",
    "lon": "18.0624",
    "name": "Stockholm Central Station",
    "city": "Stockholm",
    "state": null,
    "country": "Sweden",
    "woeid": "906057",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "2"
  },
  {
    "code": "XEZ",
    "lat": "59.1972",
    "lon": "17.624",
    "name": "Sodertalje",
    "city": "Sodertalje",
    "state": "Stockholm",
    "country": "Sweden",
    "woeid": "905000",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "1"
  },
  {
    "code": "XFD",
    "lat": "43.3701",
    "lon": "-80.9819",
    "name": "Stratford",
    "city": "Stratford",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4054",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XFE",
    "lat": "47.9204",
    "lon": "-74.6246",
    "name": "Parent Rail Service",
    "city": "Parent",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "3418",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XFG",
    "lat": "48.5247",
    "lon": "-64.2139",
    "name": "Perce Rail Service",
    "city": "Perce",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "3729",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XFJ",
    "lat": "59.3723",
    "lon": "16.5165",
    "name": "Eskilstuna Station",
    "city": "Eskilstuna",
    "state": null,
    "country": "Sweden",
    "woeid": "889077",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XFK",
    "lat": "48.3907",
    "lon": "-77.2423",
    "name": "Senneterre Rail Service",
    "city": "Senneterre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "3259",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XFL",
    "lat": "46.5657",
    "lon": "-72.7254",
    "name": "Shawinigan Rail Station",
    "city": "Shawinigan",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12697118",
    "tz": "America\/Montreal",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XFM",
    "lat": "48.6537",
    "lon": "-123.615",
    "name": "Shawnigan Rail Service",
    "city": "Cowichan Valley",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "23405345",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XFN",
    "lat": "32.05",
    "lon": "112.017",
    "name": "Xiangfan",
    "city": "Xiangfan",
    "state": "\u6e56\u5317\u7701",
    "country": "China",
    "woeid": "12713146",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "XFP",
    "lat": "55.5966",
    "lon": "13.0016",
    "name": "Malm\u00f6 Station",
    "city": "Malmo",
    "state": null,
    "country": "Sweden",
    "woeid": "898091",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XFQ",
    "lat": "47.9",
    "lon": "-73.75",
    "name": "Weymont Rail Service",
    "city": "Weymont",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "23395517",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XFR",
    "lat": "55.6034",
    "lon": "13.0008",
    "name": "Malmo South Railway",
    "city": "Malmo",
    "state": "",
    "country": "Sweden",
    "woeid": "898091",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XFS",
    "lat": "45.3116",
    "lon": "-74.6366",
    "name": "Alexandria",
    "city": "Alexandria",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "3557",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "XFU",
    "lat": "60.3432",
    "lon": "17.5143",
    "name": "Tierp Station",
    "city": "Tierp",
    "state": null,
    "country": "Sweden",
    "woeid": "907416",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XFV",
    "lat": "43.1414",
    "lon": "-80.2617",
    "name": "Brantford",
    "city": "Brantford",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4059",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XFW",
    "lat": "53.5356",
    "lon": "9.8342",
    "name": "Finkenwerder Airport",
    "city": "Hamburg",
    "state": "Hamburg",
    "country": "Germany",
    "woeid": "12513120",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "EDHI",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XFY",
    "lat": "46.7869",
    "lon": "-71.3708",
    "name": "Sainte Foy Rail Service",
    "city": "Quebec",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "4384",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XFZ",
    "lat": "46.7137",
    "lon": "-71.2715",
    "name": "Charny",
    "city": "Charny",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "268",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XGC",
    "lat": "55.7062",
    "lon": "13.1876",
    "name": "Lund",
    "city": "Lund",
    "state": null,
    "country": "Sweden",
    "woeid": "897819",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XGJ",
    "lat": "39.8753",
    "lon": "20.005",
    "name": "Cobourg Rail Station",
    "city": "Cobourg",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4367",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XGK",
    "lat": "47.8382",
    "lon": "-64.5545",
    "name": "Coteau Rail Station",
    "city": "Coteau",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "23392633",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XGM",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Grantham",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XGR",
    "lat": "58.5344",
    "lon": "-65.8892",
    "name": "Kangiqsualujjuaq Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524059",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XGY",
    "lat": "49.3771",
    "lon": "-84.7493",
    "name": "Ontario",
    "city": "Grimsby",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2344922",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XHJ",
    "lat": "50.7781",
    "lon": "6.08849",
    "name": "Railway Station",
    "city": "Aix La Chapelle",
    "state": "North-Rhine-Westphalia",
    "country": "Germany",
    "woeid": "633175",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XHK",
    "lat": "44.9913",
    "lon": "4.97828",
    "name": "Valence Station",
    "city": "Valence",
    "state": "Midi-Pyr\u00e9n\u00e9es",
    "country": "France",
    "woeid": "22885996",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "8"
  },
  {
    "code": "XHM",
    "lat": "46.1847",
    "lon": "-62.5339",
    "name": "Georgetown Rail Station",
    "city": "Georgetown",
    "state": "Prince Edward Island",
    "country": "Canada",
    "woeid": "3793",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XHN",
    "lat": "50.501",
    "lon": "4.47677",
    "name": "Belgium",
    "city": "Liege",
    "state": "Wallonie",
    "country": "Belgium",
    "woeid": "23424757",
    "tz": "Europe\/Brussels",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XHS",
    "lat": "54.4857",
    "lon": "-126.556",
    "name": "British Columbia",
    "city": "Chemainus",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "2344916",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XIA",
    "lat": "43.5661",
    "lon": "-80.1989",
    "name": "Guelph Airport",
    "city": "Guelph",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511904",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XIB",
    "lat": "49.3771",
    "lon": "-84.7493",
    "name": "Ontario",
    "city": "Ingersoll",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2344922",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XIC",
    "lat": "27.9935",
    "lon": "102.179",
    "name": "Xichang North Airport",
    "city": "Xichang",
    "state": "Sichuan",
    "country": "China",
    "woeid": "12512245",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZUXC",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "XID",
    "lat": "45.2906",
    "lon": "-74.8516",
    "name": "Maxville Rail Station",
    "city": "Maxville",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "3559",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XIF",
    "lat": "49.3771",
    "lon": "-84.7493",
    "name": "Ontario",
    "city": "Napanee",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2344922",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XIL",
    "lat": "43.9667",
    "lon": "116.067",
    "name": "Xilinhot Airport",
    "city": "Xilin Hot",
    "state": "Nei Mongol",
    "country": "China",
    "woeid": "12512248",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XIM",
    "lat": "53.8901",
    "lon": "-68.4293",
    "name": "Quebec",
    "city": "Saint Hyacinthe",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "2344924",
    "tz": "America\/Montreal",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XIO",
    "lat": "46.1445",
    "lon": "-66.6164",
    "name": "St Marys Rail Station",
    "city": "St Marys",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "29374756",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XIP",
    "lat": "43.1301",
    "lon": "-80.7566",
    "name": "Woodstock Rail Service",
    "city": "Woodstock",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4060",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XIT",
    "lat": "51.5039",
    "lon": "0.04981",
    "name": "London City Airport",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22475376",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "XIY",
    "lat": "34.3667",
    "lon": "108.7",
    "name": "Hsien Yang Airport",
    "city": "Xianyang",
    "state": "Shaanxi",
    "country": "China",
    "woeid": "12512075",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "ZLXY",
    "direct_flights": "44",
    "carriers": "23"
  },
  {
    "code": "XJL",
    "lat": "53.8901",
    "lon": "-68.4293",
    "name": "Quebec",
    "city": "Joliette",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "2344924",
    "tz": "America\/Montreal",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XJQ",
    "lat": "48.4339",
    "lon": "-71.3432",
    "name": "Jonquiere Rail Station",
    "city": "Jonquiere",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "3404",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XKH",
    "lat": "19.5",
    "lon": "103",
    "name": "Xieng Khouang",
    "city": "Xieng Khouang",
    "state": "Xiangkhoang",
    "country": "Lao People's Democratic Republic",
    "woeid": "12848854",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "3500",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XKL",
    "lat": "3.13636",
    "lon": "101.689",
    "name": "Kuala Lumpur Central Station",
    "city": "Kuala Lumpur",
    "state": "Wilayah Persekutuan",
    "country": "Malaysia",
    "woeid": "22342065",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XKS",
    "lat": "53.513",
    "lon": "-88.6475",
    "name": "Kasabonika Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524061",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "630",
    "icao": "CYAQ",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "XKV",
    "lat": "45.8997",
    "lon": "-64.3746",
    "name": "Sackville Rail Station",
    "city": "Sackville",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "3798",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XLB",
    "lat": "58.65",
    "lon": "-97.3667",
    "name": "Lac Brochet",
    "city": "Lac Brochet",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524062",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3480",
    "elev": "1200",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XLJ",
    "lat": "46.8",
    "lon": "-71.25",
    "name": "Quebec Stn. Rail Svce.",
    "city": "Quebec",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12697028",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XLM",
    "lat": "45.5",
    "lon": "-73.5833",
    "name": "St Lambert Rail Svce.",
    "city": "Montreal",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12697167",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XLQ",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "",
    "city": "Guildwood",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XLS",
    "lat": "16.0503",
    "lon": "-16.4603",
    "name": "Saint Louis Airport",
    "city": "",
    "state": "St-Louis",
    "country": "Senegal",
    "woeid": "12517509",
    "tz": "Africa\/Dakar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6372",
    "elev": "13",
    "icao": "GOSS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XLV",
    "lat": "49.3771",
    "lon": "-84.7493",
    "name": "Ontario",
    "city": "Niagara Falls",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2344922",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XLY",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "Aldershot Rail Station",
    "city": "Aldershot",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XLZ",
    "lat": "44.7274",
    "lon": "-63.5876",
    "name": "Nova Scotia",
    "city": "Truro",
    "state": "Nova Scotia",
    "country": "Canada",
    "woeid": "2344921",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XMH",
    "lat": "-14.4252",
    "lon": "-146.068",
    "name": "Manihi Airport",
    "city": "Manihi",
    "state": "Tuamotu-Gambier",
    "country": "French Polynesia",
    "woeid": "12512825",
    "tz": "Pacific\/Gambier",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3937",
    "elev": "10",
    "icao": "NTGI",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XMN",
    "lat": "24.5333",
    "lon": "118.117",
    "name": "Xiamen Airport",
    "city": "Xiamen",
    "state": "Fujian",
    "country": "China",
    "woeid": "12523266",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7054",
    "elev": "59",
    "icao": "ZSAM",
    "direct_flights": "55",
    "carriers": "33"
  },
  {
    "code": "XMS",
    "lat": "-2.335",
    "lon": "-78.1669",
    "name": "Macas Airport",
    "city": "Z\u00fa\u00f1ac",
    "state": "Morona Santiago",
    "country": "Ecuador",
    "woeid": "12512640",
    "tz": "America\/Guayaquil",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8366",
    "elev": "3281",
    "icao": "SEMC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XMY",
    "lat": "-10.4833",
    "lon": "142.45",
    "name": "Yam Island",
    "city": "Yam Island",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "28645136",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XNA",
    "lat": "36.2756",
    "lon": "-94.2955",
    "name": "Northwest Arkansas Regional Airport",
    "city": "Bentonville",
    "state": "Arkansas",
    "country": "United States",
    "woeid": "29388192",
    "tz": "America\/Chicago",
    "phone": "479-205-1000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.nwara.com\/",
    "runway_length": "8800",
    "elev": "1286",
    "icao": "KXNA",
    "direct_flights": "15",
    "carriers": "13"
  },
  {
    "code": "XNB",
    "lat": "-3.08333",
    "lon": "-67.9333",
    "name": "Sinop Airport",
    "city": "Santo Ant\u00f4nio do I\u00e7\u00e1",
    "state": "Amazonas",
    "country": "Brazil",
    "woeid": "12523221",
    "tz": "America\/Porto_Velho",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XNM",
    "lat": "52.9206",
    "lon": "-1.075",
    "name": "Nottingham Airport",
    "city": "Nottingham",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12518136",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "XNN",
    "lat": "36.5678",
    "lon": "101.862",
    "name": "Xining Airport",
    "city": "Xining",
    "state": "Qinghai",
    "country": "China",
    "woeid": "12512251",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "8"
  },
  {
    "code": "XNO",
    "lat": "54.2",
    "lon": "-1.26",
    "name": "",
    "city": "Northallerton",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "30597",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KXNO",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XNV",
    "lat": "52.5214",
    "lon": "-1.46445",
    "name": "Nuneaton Rail Station",
    "city": "Nuneaton",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "30733",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "XNY",
    "lat": "42.8767",
    "lon": "-77.8826",
    "name": "York",
    "city": "New York",
    "state": "New York",
    "country": "United States",
    "woeid": "2524848",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XOK",
    "lat": "49.3771",
    "lon": "-84.7493",
    "name": "Ontario",
    "city": "Oakville",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "2344922",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XOP",
    "lat": "46.1325",
    "lon": "-0.17616",
    "name": "Poitou-Charentes",
    "city": "Poitiers",
    "state": "Poitou-Charentes",
    "country": "France",
    "woeid": "7153327",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "10"
  },
  {
    "code": "XPB",
    "lat": "49.3203",
    "lon": "-124.322",
    "name": "Parksville Rail Service",
    "city": "Parksville",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12523349",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XPF",
    "lat": "54.6643",
    "lon": "-2.75747",
    "name": "Penrith Rail Station",
    "city": "Penrith",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "31772",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XPG",
    "lat": "48.8805",
    "lon": "2.35459",
    "name": "Gare du Nord Rail Stn",
    "city": "Paris",
    "state": "\u00cele-de-France",
    "country": "France",
    "woeid": "20068147",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "XPJ",
    "lat": "45.6333",
    "lon": "-0.75",
    "name": "Montpellier Railway Station",
    "city": "Montpellier",
    "state": "Poitou-Charentes",
    "country": "France",
    "woeid": "12723386",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "8"
  },
  {
    "code": "XPN",
    "lat": "43.6843",
    "lon": "-79.7588",
    "name": "",
    "city": "Brampton",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4431",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XPQ",
    "lat": "3",
    "lon": "101.4",
    "name": "",
    "city": "Port Klang",
    "state": "Selangor",
    "country": "Malaysia",
    "woeid": "1152513",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XPT",
    "lat": "53.758",
    "lon": "-2.70573",
    "name": "Preston Rail Station",
    "city": "Preston",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "32566",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "1"
  },
  {
    "code": "XPX",
    "lat": "45.6585",
    "lon": "-73.5484",
    "name": "Pointe-aux-Trembles Rail Station",
    "city": "Pointe-aux-Trembles",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "1217",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XQE",
    "lat": "54.3139",
    "lon": "-2.23218",
    "name": "United Kingdom",
    "city": "London",
    "state": null,
    "country": "United Kingdom",
    "woeid": "23424975",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XQG",
    "lat": "55.7714",
    "lon": "-2.00702",
    "name": "Berwick Station",
    "city": "Berwick",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "12444",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XQH",
    "lat": "54.3139",
    "lon": "-2.23218",
    "name": "United Kingdom",
    "city": "Nottingham",
    "state": null,
    "country": "United Kingdom",
    "woeid": "23424975",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "XQL",
    "lat": "54.0445",
    "lon": "-2.79903",
    "name": "Lancaster Rail Station",
    "city": "Lancaster",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "25701",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XQP",
    "lat": "9.4442",
    "lon": "-84.1311",
    "name": "Quepos Managua Airport",
    "city": "Quepos",
    "state": "Puntarenas",
    "country": "Costa Rica",
    "woeid": "12512440",
    "tz": "America\/Costa_Rica",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3600",
    "elev": "105",
    "icao": "MRQP",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "XQU",
    "lat": "49.3384",
    "lon": "-124.392",
    "name": "Qualicum Beach Airport",
    "city": "Qualicum Beach",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524067",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2900",
    "elev": "200",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "XRC",
    "lat": "53.3304",
    "lon": "-2.70902",
    "name": "Runcorn Rail Station",
    "city": "Runcorn",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "33759",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XRF",
    "lat": "43.4844",
    "lon": "5.37588",
    "name": "Marseille Railway",
    "city": "Marseille",
    "state": "Centre",
    "country": "France",
    "woeid": "610264",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "9"
  },
  {
    "code": "XRP",
    "lat": "46.4864",
    "lon": "-65.0255",
    "name": "Pine Ridge Rail Station",
    "city": "Pine Ridge",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "23393083",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XRU",
    "lat": "52.3703",
    "lon": "-1.26071",
    "name": "Rugby Rail Station",
    "city": "Rugby",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "33744",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "1"
  },
  {
    "code": "XRY",
    "lat": "36.7497",
    "lon": "-6.06054",
    "name": "Jerez Airport",
    "city": "Jerez",
    "state": "Andalucia",
    "country": "Spain",
    "woeid": "12517549",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7546",
    "elev": "85",
    "icao": "LEJR",
    "direct_flights": "16",
    "carriers": "14"
  },
  {
    "code": "XSC",
    "lat": "21.5158",
    "lon": "-71.5297",
    "name": "South Caicos Airport",
    "city": "Cockburn Harbour",
    "state": "South and East Caicos",
    "country": "Turks And Caicos Islands",
    "woeid": "12517846",
    "tz": "America\/Grand_Turk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "5",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "XSH",
    "lat": "47.6441",
    "lon": "1.59046",
    "name": "Centre",
    "city": "Tours",
    "state": "Centre",
    "country": "France",
    "woeid": "7153314",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "10"
  },
  {
    "code": "XSI",
    "lat": "56.7819",
    "lon": "-98.9558",
    "name": "South Indian Lake Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524069",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2600",
    "elev": "948",
    "icao": "",
    "direct_flights": "1",
    "carriers": "2"
  },
  {
    "code": "XSP",
    "lat": "1.4167",
    "lon": "103.869",
    "name": "Seletar Airport",
    "city": "Singapore",
    "state": "Central Singapore",
    "country": "Singapore",
    "woeid": "12517523",
    "tz": "Asia\/Singapore",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5354",
    "elev": "35",
    "icao": "WSSL",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XSR",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Salisbury",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XTG",
    "lat": "-27.9829",
    "lon": "143.812",
    "name": "Thargomindah Aerodrome",
    "city": "Thargomindah",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "12510794",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "YTGM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XTK",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Thirsk",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XTL",
    "lat": "58.5999",
    "lon": "-98.349",
    "name": "Tadoule Lake Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524070",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYBQ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XTY",
    "lat": "42.9432",
    "lon": "-81.6198",
    "name": "Strathroy Rail Station",
    "city": "Strathroy",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12697816",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XUZ",
    "lat": "32.9444",
    "lon": "119.119",
    "name": "Jiangsu",
    "city": "Xuzhou",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "12577994",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "13",
    "carriers": "7"
  },
  {
    "code": "XVA",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Stockport",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XVB",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Stafford",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "1"
  },
  {
    "code": "XVC",
    "lat": "54.3139",
    "lon": "-2.23218",
    "name": "United Kingdom",
    "city": "Crewe",
    "state": null,
    "country": "United Kingdom",
    "woeid": "23424975",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "15",
    "carriers": "1"
  },
  {
    "code": "XVG",
    "lat": "46.9929",
    "lon": "-94.2075",
    "name": "Longville Municipal Airport",
    "city": "Longville",
    "state": "Minnesota",
    "country": "United States",
    "woeid": "12520699",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KXVG",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XVH",
    "lat": "54.3139",
    "lon": "-2.23218",
    "name": "United Kingdom",
    "city": "Peterborough",
    "state": null,
    "country": "United Kingdom",
    "woeid": "23424975",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "11",
    "carriers": "1"
  },
  {
    "code": "XVJ",
    "lat": "51.9056",
    "lon": "-0.18868",
    "name": "Stevenage Rail Station",
    "city": "Stevenage",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "36094",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XVU",
    "lat": "54.7775",
    "lon": "-1.57461",
    "name": "Durham Rail Station",
    "city": "Durham",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "18760",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XVV",
    "lat": "44.161",
    "lon": "-77.3819",
    "name": "Belleville Rail Service",
    "city": "Belleville",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4107",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XVW",
    "lat": "57.0988",
    "lon": "-3.99274",
    "name": "",
    "city": "Belleville",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "11627",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XWD",
    "lat": "54.7447",
    "lon": "-2.14264",
    "name": "Wakefield Westgate Rail Station",
    "city": "Wakefield Westgate",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "39929",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XWH",
    "lat": "53.0294",
    "lon": "-2.17507",
    "name": "Stroke on Trent Rail Station",
    "city": "Stroke on Trent",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "36240",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "XWK",
    "lat": "56.2667",
    "lon": "15.2667",
    "name": "Karlskrona Rail Svc.",
    "city": "Karlskrona",
    "state": null,
    "country": "Sweden",
    "woeid": "902730",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XWL",
    "lat": "57.7013",
    "lon": "11.9669",
    "name": "Gothenburg",
    "city": "Gothenburg",
    "state": "",
    "country": "Sweden",
    "woeid": "890869",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "XWM",
    "lat": "59.0667",
    "lon": "15.1126",
    "name": "Hallsberg Rail Station",
    "city": "Hallsberg",
    "state": "",
    "country": "Sweden",
    "woeid": "892152",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "1"
  },
  {
    "code": "XWN",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Warrington",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XWP",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Hassleholm",
    "state": null,
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "XWQ",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Enkoping",
    "state": null,
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XWR",
    "lat": "59.2707",
    "lon": "15.2175",
    "name": "Orebro Railway Station",
    "city": "Orebro",
    "state": "Orebro",
    "country": "Sweden",
    "woeid": "900751",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XWS",
    "lat": "54.3139",
    "lon": "-2.23218",
    "name": "United Kingdom",
    "city": "Swindon",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "23424975",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XWV",
    "lat": "57.1079",
    "lon": "12.249",
    "name": "Varberg Rail Station",
    "city": "Varberg",
    "state": "",
    "country": "Sweden",
    "woeid": "909053",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XWY",
    "lat": "42.9414",
    "lon": "-82.1204",
    "name": "Wyoming Rail Station",
    "city": "Wyoming",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "1964",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XWZ",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Nykoping",
    "state": null,
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XXA",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Alvesta",
    "state": "",
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "XXD",
    "lat": "59.2359",
    "lon": "14.4305",
    "name": "Degerfors Rail Station",
    "city": "Degerfors",
    "state": "",
    "country": "Sweden",
    "woeid": "888282",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XXK",
    "lat": "59.0167",
    "lon": "16.2167",
    "name": "Katrineholm",
    "city": "Katrineholm",
    "state": "",
    "country": "Sweden",
    "woeid": "895148",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XXM",
    "lat": "58.3259",
    "lon": "15.1313",
    "name": "",
    "city": "Mjolby",
    "state": "Ostergotland",
    "country": "Sweden",
    "woeid": "898538",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XXN",
    "lat": "46.4931",
    "lon": "-90.4134",
    "name": "Riyadh Air Base",
    "city": "Riyadh",
    "state": "",
    "country": "Saudi Arabia",
    "woeid": "1939753",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "OERY",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XXO",
    "lat": "60.7313",
    "lon": "14.9974",
    "name": "Leksand Rail Station",
    "city": "Leksand",
    "state": "",
    "country": "Sweden",
    "woeid": "896880",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XXT",
    "lat": "43.6167",
    "lon": "7.05",
    "name": "Sophia Antipolis Heliport",
    "city": "Valbonne",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "12523829",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XXU",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Hedemora",
    "state": "",
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XXZ",
    "lat": "62.3889",
    "lon": "17.3069",
    "name": "Sundsvall Railway Station",
    "city": "Sundsvall",
    "state": "",
    "country": "Sweden",
    "woeid": "906742",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XYA",
    "lat": "-9.06667",
    "lon": "159.233",
    "name": "Yandina",
    "city": "Yandina",
    "state": "Guadalcanal",
    "country": "Solomon Islands",
    "woeid": "1020497",
    "tz": "Pacific\/Guadalcanal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5921",
    "elev": "20",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XYB",
    "lat": "60.4838",
    "lon": "15.4344",
    "name": "Borlange",
    "city": "Borl\u00e4nge",
    "state": null,
    "country": "Sweden",
    "woeid": "887274",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XYC",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Herrljunga",
    "state": "",
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "900",
    "elev": "480",
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XYD",
    "lat": "45.75",
    "lon": "4.85",
    "name": "Lyon Part-Dieu Railway Station",
    "city": "Lyon",
    "state": "Rhone-Alpes",
    "country": "France",
    "woeid": "55863207",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "10"
  },
  {
    "code": "XYF",
    "lat": "58.1621",
    "lon": "13.5548",
    "name": "Falkoping Rail Station",
    "city": "Falkoping",
    "state": "Vastra Gotaland",
    "country": "Sweden",
    "woeid": "889227",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "XYH",
    "lat": "56.0439",
    "lon": "12.6959",
    "name": "Helsingborg Railway",
    "city": "Helsingborg",
    "state": "",
    "country": "Sweden",
    "woeid": "892828",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XYI",
    "lat": "62.1984",
    "lon": "17.5514",
    "name": "Sweden",
    "city": "Flen",
    "state": "",
    "country": "Sweden",
    "woeid": "23424954",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XYK",
    "lat": "58.5844",
    "lon": "16.1925",
    "name": "Norrkoping Railway Service",
    "city": "Norrkoping",
    "state": "Ostergotland",
    "country": "Sweden",
    "woeid": "899981",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "XYN",
    "lat": "59.3091",
    "lon": "14.1061",
    "name": "Kristinehamn",
    "city": "Kristinehamn",
    "state": "",
    "country": "Sweden",
    "woeid": "895938",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XYP",
    "lat": "60.1269",
    "lon": "16.2161",
    "name": "Kyrlbo",
    "city": "Avesta Krylbo",
    "state": "Dalarna",
    "country": "Sweden",
    "woeid": "896048",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XYQ",
    "lat": "59.6833",
    "lon": "15.8167",
    "name": "Angelholm Railway Svc.",
    "city": "Angelholm\/Helsingborg",
    "state": null,
    "country": "Sweden",
    "woeid": "892828",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XYX",
    "lat": "59.92",
    "lon": "16.606",
    "name": "Sala",
    "city": "Sala",
    "state": "",
    "country": "Sweden",
    "woeid": "892828",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "XYY",
    "lat": "59.6767",
    "lon": "12.6425",
    "name": "Arvika Airport",
    "city": "Arvika",
    "state": "",
    "country": "Sweden",
    "woeid": "12517611",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XYZ",
    "lat": "62.6335",
    "lon": "17.9377",
    "name": "Harnosand Rail Station",
    "city": "Harnosand",
    "state": "Vasternorrland",
    "country": "Sweden",
    "woeid": "892478",
    "tz": "Europe\/Stockholm",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XZB",
    "lat": "45.3135",
    "lon": "-75.089",
    "name": "Casselman Rail Station",
    "city": "Casselman",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "3372",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XZC",
    "lat": "42.7425",
    "lon": "-81.7128",
    "name": "",
    "city": "Glencoe",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "4073",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XZJ",
    "lat": "37.7167",
    "lon": "28.9667",
    "name": "",
    "city": "Rail (Generic)",
    "state": "",
    "country": "Japan",
    "woeid": "23424856",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XZL",
    "lat": "53.3078",
    "lon": "-113.584",
    "name": "Edmonton International Airport",
    "city": "Edmonton",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511646",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XZM",
    "lat": "-7.45",
    "lon": "-70.55",
    "name": "Macau Ferry",
    "city": "Macau",
    "state": "Amazonas",
    "country": "Macao",
    "woeid": "12824753",
    "tz": "America\/Rio_Branco",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "XZN",
    "lat": "43.9487",
    "lon": "4.80601",
    "name": "Avignon",
    "city": "Avignon",
    "state": "Provence-alpes-cote d'Azur",
    "country": "France",
    "woeid": "577605",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "8"
  },
  {
    "code": "XZO",
    "lat": "59.9123",
    "lon": "10.75",
    "name": "Oslo Central Station",
    "city": "Oslo",
    "state": "",
    "country": "Norway",
    "woeid": "862592",
    "tz": "Europe\/Oslo",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XZU",
    "lat": "37.2997",
    "lon": "-92.3047",
    "name": "Off line Point",
    "city": "Rail (Generic)",
    "state": "Missouri",
    "country": "United States",
    "woeid": "2478178",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Off-line Point",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "XZV",
    "lat": "46.4833",
    "lon": "-0.633333",
    "name": "TGV Station",
    "city": "Toulon",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "624561",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "YAA",
    "lat": "52.4539",
    "lon": "-125.304",
    "name": "Anahim Lake Airport",
    "city": "Alexis Creek",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511568",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3346",
    "elev": "3700",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YAC",
    "lat": "51.6667",
    "lon": "-91.8333",
    "name": "Cat Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524072",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "1343",
    "icao": "CYAC",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YAG",
    "lat": "48.6498",
    "lon": "-93.4279",
    "name": "Fort Frances Municipal Airport",
    "city": "Fort Frances",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511663",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "1122",
    "icao": "CYAG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YAK",
    "lat": "59.5103",
    "lon": "-139.668",
    "name": "Yakutat Airport",
    "city": "Yakutat",
    "state": "Alaska",
    "country": "United States",
    "woeid": "12522527",
    "tz": "America\/Anchorage",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7813",
    "elev": "33",
    "icao": "PAYA",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "YAM",
    "lat": "46.4853",
    "lon": "-84.5002",
    "name": "Sault Ste Marie Airport",
    "city": "Sault Ste. Marie",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511827",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "630",
    "icao": "CYAM",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YAO",
    "lat": "3.8336",
    "lon": "11.5236",
    "name": "Yaounde Airport",
    "city": "Yaounde",
    "state": "Centre",
    "country": "Cameroon",
    "woeid": "12512354",
    "tz": "Africa\/Douala",
    "phone": "+2372.2233602",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "2464",
    "icao": "FKKY",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YAP",
    "lat": "9.48333",
    "lon": "138.083",
    "name": "Yap International Airport",
    "city": "Colonia",
    "state": "Yap",
    "country": "Federated States of Micronesia",
    "woeid": "12523267",
    "tz": "Pacific\/Ponape",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4820",
    "elev": "51",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YAT",
    "lat": "52.9338",
    "lon": "-82.392",
    "name": "Attawapiskat Airport",
    "city": "Winisk",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524078",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "30",
    "icao": "CYAT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YAX",
    "lat": "53.2519",
    "lon": "-89.565",
    "name": "Angling Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524079",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYAX",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YAY",
    "lat": "51.3883",
    "lon": "-56.0901",
    "name": "St Anthony Airport",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511843",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "15",
    "icao": "CYAY",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YAZ",
    "lat": "49.0797",
    "lon": "-125.769",
    "name": "Tofino Airport",
    "city": "Lake Cowichan",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511879",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYAZ",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "YBB",
    "lat": "68.5333",
    "lon": "-89.8167",
    "name": "Pelly Bay Townsite Airport",
    "city": "Cambridge Bay",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12523269",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4590",
    "elev": "50",
    "icao": "KGRK",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YBC",
    "lat": "49.1368",
    "lon": "-68.1995",
    "name": "Baie Comeau Airport",
    "city": "Betsiamites",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511572",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "71",
    "icao": "CYBC",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "YBG",
    "lat": "48.3368",
    "lon": "-70.9998",
    "name": "Bagotville Airport",
    "city": "Saguenay",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511571",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "521",
    "icao": "CYBG",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YBI",
    "lat": "53.4552",
    "lon": "-55.7653",
    "name": "Black Tickle Airport",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524082",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "67",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YBK",
    "lat": "64.3003",
    "lon": "-96.0846",
    "name": "Baker Lake Airport",
    "city": "Chesterfield Inlet",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524243",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4200",
    "elev": "59",
    "icao": "CYBK",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "YBL",
    "lat": "49.954",
    "lon": "-125.27",
    "name": "Campbell River Airport",
    "city": "Gold River",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511597",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "346",
    "icao": "CYBL",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "YBP",
    "lat": "28.766",
    "lon": "104.623",
    "name": "Yibin",
    "city": "Yibin",
    "state": "Sichuan",
    "country": "China",
    "woeid": "2158444",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "YBR",
    "lat": "49.9036",
    "lon": "-99.943",
    "name": "Brandon Airport",
    "city": "Killarney",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511586",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "http:\/\/www.brandon.ca\/welcome.nsf\/0be477a354605b4d86256b6500798d",
    "runway_length": "6500",
    "elev": "1343",
    "icao": "CYBR",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YBT",
    "lat": "57.8833",
    "lon": "-101.683",
    "name": "Brochet",
    "city": "Brochet",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "169",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYBT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YBV",
    "lat": "52.4333",
    "lon": "-97.0333",
    "name": "Berens River Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524086",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "728",
    "icao": "CYBV",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YBW",
    "lat": "48.7512",
    "lon": "-123.225",
    "name": "",
    "city": "Bedwell Harbor",
    "state": "",
    "country": "Canada",
    "woeid": "12524103",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Harbours",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YBX",
    "lat": "51.4436",
    "lon": "-57.1861",
    "name": "Lourdes-De-Blanc-Sablon Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511736",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "117",
    "icao": "CYBX",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YBZ",
    "lat": "43.6382",
    "lon": "-79.3466",
    "name": "Toronto Downtown Airport",
    "city": "Toronto",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12523339",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "YCA",
    "lat": "49.6833",
    "lon": "-125",
    "name": "Courtenay Airport",
    "city": "Courtenay",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12523298",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "1974",
    "elev": "9",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YCB",
    "lat": "69.0981",
    "lon": "-105.134",
    "name": "Cambridge Bay Airport",
    "city": "Cambridge Bay",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12523271",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "90",
    "icao": "CYCB",
    "direct_flights": "6",
    "carriers": "3"
  },
  {
    "code": "YCC",
    "lat": "45.092",
    "lon": "-74.56",
    "name": "Cornwall Regional Airport",
    "city": "Moose Creek",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511626",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "175",
    "icao": "CYCC",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YCD",
    "lat": "49.0521",
    "lon": "-123.874",
    "name": "Nanaimo Airport",
    "city": "Nanaimo",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511768",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "99",
    "icao": "CYCD",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YCG",
    "lat": "49.3002",
    "lon": "-117.637",
    "name": "Castlegar Airport",
    "city": "Castlegar",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511602",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5300",
    "elev": "1624",
    "icao": "CYCG",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YCK",
    "lat": "67.0454",
    "lon": "-125.853",
    "name": "Colville Lake Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524090",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2400",
    "elev": "900",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YCM",
    "lat": "43.1893",
    "lon": "-79.1709",
    "name": "St Catharines Airport",
    "city": "Fonthill",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511845",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "321",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YCO",
    "lat": "67.8182",
    "lon": "-115.147",
    "name": "Coppermine Airport",
    "city": "Cambridge Bay",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524091",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "74",
    "icao": "CYCO",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YCR",
    "lat": "54.6042",
    "lon": "-97.7607",
    "name": "Cross Lake Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524092",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "709",
    "icao": "CYCR",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YCS",
    "lat": "63.3358",
    "lon": "-90.734",
    "name": "Chesterfield Inlet Airport",
    "city": "Chesterfield Inlet",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524093",
    "tz": "America\/Winnipeg",
    "phone": "867-898-9940",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "35",
    "icao": "CYCS",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YCU",
    "lat": "61.3167",
    "lon": "-98.5",
    "name": "Cullaton Lake Airport",
    "city": "Chesterfield Inlet",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524094",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "YCY",
    "lat": "70.4833",
    "lon": "-68.5167",
    "name": "Clyde River Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524096",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "100",
    "icao": "CYCY",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YDA",
    "lat": "64.0448",
    "lon": "-139.126",
    "name": "Dawson Airport",
    "city": "Dawson",
    "state": "Yukon Territory",
    "country": "Canada",
    "woeid": "12524097",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1211",
    "icao": "CYDA",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YDF",
    "lat": "49.2085",
    "lon": "-57.3999",
    "name": "Deer Lake Airport",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511635",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "72",
    "icao": "CYDF",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "YDN",
    "lat": "51.1025",
    "lon": "-100.06",
    "name": "Dauphin Airport",
    "city": "Dauphin",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511630",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "999",
    "icao": "CYDN",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YDP",
    "lat": "56.5333",
    "lon": "-61.6667",
    "name": "Nain Airport",
    "city": "Happy Valley-Goose Bay",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524102",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "500",
    "icao": "CYDP",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "YDQ",
    "lat": "55.739",
    "lon": "-120.187",
    "name": "Dawson Creek Airport",
    "city": "Dawson Creek",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511631",
    "tz": "America\/Dawson_Creek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "2148",
    "icao": "CYDQ",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YEG",
    "lat": "53.3078",
    "lon": "-113.584",
    "name": "Edmonton International Airport",
    "city": "Leduc",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511646",
    "tz": "America\/Edmonton",
    "phone": "(780) 890-8900",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.edmontonairports.com",
    "runway_length": "11000",
    "elev": "2373",
    "icao": "CYEG",
    "direct_flights": "44",
    "carriers": "27"
  },
  {
    "code": "YEI",
    "lat": "40.2546",
    "lon": "29.5629",
    "name": "",
    "city": "Bursa",
    "state": "Bursa",
    "country": "Turkey",
    "woeid": "2347273",
    "tz": "Europe\/Istanbul",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "9818",
    "elev": "764",
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "YEK",
    "lat": "61.1019",
    "lon": "-94.0622",
    "name": "Arviat Airport",
    "city": "Chesterfield Inlet",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524110",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "32",
    "icao": "CYEK",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YER",
    "lat": "56",
    "lon": "-87.6333",
    "name": "Fort Severn Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524113",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "40",
    "icao": "CYER",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YES",
    "lat": "34.4382",
    "lon": "-104.611",
    "name": "",
    "city": "Yasouj",
    "state": "",
    "country": "Iran",
    "woeid": "23424851",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YEV",
    "lat": "68.3063",
    "lon": "-133.497",
    "name": "Inuvik Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12511705",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "224",
    "icao": "CYEV",
    "direct_flights": "11",
    "carriers": "5"
  },
  {
    "code": "YFA",
    "lat": "52.2417",
    "lon": "-81.5917",
    "name": "Fort Albany Airport",
    "city": "Winisk",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524115",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "10",
    "icao": "CYFA",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YFB",
    "lat": "63.7514",
    "lon": "-68.5353",
    "name": "Iqaluit Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12511707",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8200",
    "elev": "110",
    "icao": "CYFB",
    "direct_flights": "12",
    "carriers": "3"
  },
  {
    "code": "YFC",
    "lat": "45.873",
    "lon": "-66.5296",
    "name": "Fredericton Airport",
    "city": "Fredericton",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "12511671",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "67",
    "icao": "CYFC",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YFH",
    "lat": "51.5466",
    "lon": "-87.9859",
    "name": "Fort Hope Airport",
    "city": "Marathon",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524118",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "905",
    "icao": "CYFH",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YFJ",
    "lat": "64.1904",
    "lon": "-114.183",
    "name": "Snare Lake",
    "city": "Snare Lake",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "29374024",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YFO",
    "lat": "54.7483",
    "lon": "-101.83",
    "name": "Flin Flon Airport",
    "city": "Flin Flon",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511657",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "997",
    "icao": "CYFO",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YFS",
    "lat": "61.7556",
    "lon": "-121.238",
    "name": "Fort Simpson Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12511667",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "554",
    "icao": "CYFS",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YFX",
    "lat": "52.3598",
    "lon": "-55.6684",
    "name": "Fox Harbour Aerodrome",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524121",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Harbours",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "77",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YGB",
    "lat": "49.6942",
    "lon": "-124.516",
    "name": "Gillies Bay Airport",
    "city": "Gibsons",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511870",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "300",
    "icao": "CYGB",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YGG",
    "lat": "48.85",
    "lon": "-123.5",
    "name": "Ganges Harbor Airport",
    "city": "Saltspring Island",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524124",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Harbours",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YGH",
    "lat": "66.2653",
    "lon": "-128.617",
    "name": "Fort Good Hope Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524125",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "215",
    "icao": "CYGH",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YGJ",
    "lat": "35.4943",
    "lon": "133.237",
    "name": "Yonago Airport",
    "city": "Sakaiminato-shi",
    "state": "Tottori Prefecture",
    "country": "Japan",
    "woeid": "12513987",
    "tz": "Asia\/Tokyo",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "20",
    "icao": "",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "YGK",
    "lat": "44.2333",
    "lon": "-76.5833",
    "name": "Kingston Airport",
    "city": "Kingston",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511717",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.cityofkingston.ca\/residents\/transportation\/airport\/in",
    "runway_length": "5000",
    "elev": "305",
    "icao": "CYGK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YGL",
    "lat": "53.6256",
    "lon": "-77.705",
    "name": "La Grande Riviere Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511720",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "638",
    "icao": "CYGL",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YGO",
    "lat": "54.55",
    "lon": "-94.4833",
    "name": "Gods Lake Narrows Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524127",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2280",
    "elev": "620",
    "icao": "CYGO",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YGP",
    "lat": "48.7782",
    "lon": "-64.4738",
    "name": "Gaspe Airport",
    "city": "Gasp\u00e9",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511674",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYGP",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YGR",
    "lat": "47.42",
    "lon": "-61.7743",
    "name": "Iles de la Madeleine Airport",
    "city": "Fatima",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511701",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3725",
    "elev": "34",
    "icao": "CYGR",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "YGT",
    "lat": "69.3674",
    "lon": "-81.8254",
    "name": "Igloolik Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524129",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3700",
    "elev": "174",
    "icao": "CYGT",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YGV",
    "lat": "50.2811",
    "lon": "-63.6111",
    "name": "Harve-St-Pierre Airport",
    "city": "Havre St Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511691",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "108",
    "icao": "CYGV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YGW",
    "lat": "55.2825",
    "lon": "-77.7583",
    "name": "Kuujjuarapik Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524130",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "60",
    "icao": "CYGW",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "YGX",
    "lat": "56.35",
    "lon": "-94.7167",
    "name": "Gillam Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524131",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "475",
    "icao": "CYGX",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YGZ",
    "lat": "76.425",
    "lon": "-82.9458",
    "name": "Grise Fiord Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524133",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "146",
    "icao": "CYGZ",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YHA",
    "lat": "52.5333",
    "lon": "-56.3",
    "name": "Port Hope Simpson Aerodrome",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524134",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "353",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YHB",
    "lat": "52.8208",
    "lon": "-102.324",
    "name": "Hudson Bay Airport",
    "city": "Melfort",
    "state": "Saskatchewan",
    "country": "Canada",
    "woeid": "12511698",
    "tz": "America\/Regina",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1175",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YHD",
    "lat": "49.8284",
    "lon": "-92.7465",
    "name": "Dryden Regional Airport",
    "city": "Dryden",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511643",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1354",
    "icao": "CYHD",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YHG",
    "lat": "52.7667",
    "lon": "-56.1",
    "name": "Charlottetown Airport",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12523297",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "165",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YHI",
    "lat": "70.7766",
    "lon": "-117.831",
    "name": "Holman Airport",
    "city": "Holman",
    "state": "",
    "country": "Canada",
    "woeid": "12524137",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "117",
    "icao": "CYHI",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YHK",
    "lat": "68.6337",
    "lon": "-95.8561",
    "name": "Gjoa Haven Airport",
    "city": "Cambridge Bay",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524138",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4400",
    "elev": "150",
    "icao": "CYHK",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YHM",
    "lat": "43.1632",
    "lon": "-79.9266",
    "name": "Hamilton Airport",
    "city": "Beamsville",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511688",
    "tz": "America\/Toronto",
    "phone": "(905) 679-1999",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flyhi.ca",
    "runway_length": "8000",
    "elev": "780",
    "icao": "CYHM",
    "direct_flights": "15",
    "carriers": "4"
  },
  {
    "code": "YHO",
    "lat": "55.4667",
    "lon": "-60.2167",
    "name": "Hopedale Airport",
    "city": "Happy Valley-Goose Bay",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524139",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYHO",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YHP",
    "lat": "52.0801",
    "lon": "-94.3094",
    "name": "Poplar Hill Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524140",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YHR",
    "lat": "50.5013",
    "lon": "-59.4799",
    "name": "Harrington Harbour Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524141",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "30",
    "icao": "CYHR",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "YHS",
    "lat": "49.4735",
    "lon": "-123.747",
    "name": "Sechelt Water Aerodrome",
    "city": "Gibsons",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524142",
    "tz": "America\/Vancouver",
    "phone": "604-885-9017",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2400",
    "elev": "250",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YHU",
    "lat": "45.5087",
    "lon": "-73.4302",
    "name": "Montreal St Hubert Airport",
    "city": "St-Hubert",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511759",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "7840",
    "elev": "90",
    "icao": "CYHU",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YHY",
    "lat": "60.8392",
    "lon": "-115.781",
    "name": "Hay River Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12511692",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "543",
    "icao": "CYHY",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YHZ",
    "lat": "44.8868",
    "lon": "-63.5154",
    "name": "Halifax International Airport",
    "city": "Fall River",
    "state": "Nova Scotia",
    "country": "Canada",
    "woeid": "12511686",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.hiaa.ca",
    "runway_length": "8800",
    "elev": "477",
    "icao": "CYHZ",
    "direct_flights": "41",
    "carriers": "23"
  },
  {
    "code": "YIF",
    "lat": "51.2117",
    "lon": "-58.6592",
    "name": "Pakuashipi Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511844",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3900",
    "elev": "15",
    "icao": "CYIF",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YIH",
    "lat": "30.9007",
    "lon": "111.06",
    "name": "China",
    "city": "Yichang",
    "state": "Hubei",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "8"
  },
  {
    "code": "YIK",
    "lat": "62.4137",
    "lon": "-77.9323",
    "name": "Ivujivik Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524146",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": "139",
    "icao": "CYIK",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YIN",
    "lat": "43.9375",
    "lon": "81.3029",
    "name": "Yining",
    "city": "Yining",
    "state": "Xinjiang",
    "country": "China",
    "woeid": "23424781",
    "tz": "Asia\/Urumqi",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YIO",
    "lat": "72.6806",
    "lon": "-77.9981",
    "name": "Pond Inlet Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524147",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "188",
    "icao": "CYIO",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YIP",
    "lat": "42.2385",
    "lon": "-83.5467",
    "name": "Willow Run Airport",
    "city": "Ypsilanti",
    "state": "Michigan",
    "country": "United States",
    "woeid": "12522472",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "7522",
    "elev": "716",
    "icao": "KYIP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YIV",
    "lat": "53.859",
    "lon": "-94.6296",
    "name": "Island Lake-Garden Hill Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524148",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "776",
    "icao": "CYIV",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YIW",
    "lat": "0.688365",
    "lon": "129.154",
    "name": "",
    "city": "Yiwu",
    "state": "Zhejiang",
    "country": "China",
    "woeid": "2132647",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "YJT",
    "lat": "48.5456",
    "lon": "-58.5626",
    "name": "Stephenville International Airport",
    "city": "Stephenville",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511856",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "84",
    "icao": "CYJT",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YKA",
    "lat": "50.7054",
    "lon": "-120.442",
    "name": "Kamloops Airport",
    "city": "Kamloops",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511710",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1134",
    "icao": "CYKA",
    "direct_flights": "6",
    "carriers": "5"
  },
  {
    "code": "YKF",
    "lat": "43.45",
    "lon": "-80.4833",
    "name": "Kitchener Airport",
    "city": "Kitchener",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524154",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.waterlooairport.ca\/",
    "runway_length": "5200",
    "elev": "1040",
    "icao": "CYKF",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "YKG",
    "lat": "60.0199",
    "lon": "-69.9964",
    "name": "Kangirsuk Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524155",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YKL",
    "lat": "54.8056",
    "lon": "-66.8067",
    "name": "Schefferville Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511828",
    "tz": "America\/Iqaluit",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1712",
    "icao": "CYKL",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YKM",
    "lat": "46.5703",
    "lon": "-120.54",
    "name": "Yakima Air Terminal",
    "city": "Yakima",
    "state": "Washington",
    "country": "United States",
    "woeid": "12522526",
    "tz": "America\/Los_Angeles",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7603",
    "elev": "1095",
    "icao": "KYKM",
    "direct_flights": "2",
    "carriers": "7"
  },
  {
    "code": "YKQ",
    "lat": "51.485",
    "lon": "-78.7483",
    "name": "Waskaganish Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524158",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": null,
    "icao": "CYKQ",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "YKS",
    "lat": "62.0953",
    "lon": "129.767",
    "name": "Yakutsk Airport",
    "city": "Yakutsk",
    "state": "Sakha",
    "country": "Russia",
    "woeid": "12517227",
    "tz": "Asia\/Yakutsk",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1155",
    "elev": "322",
    "icao": "",
    "direct_flights": "21",
    "carriers": "7"
  },
  {
    "code": "YKT",
    "lat": "52.5995",
    "lon": "-128.529",
    "name": "Klemtu Water Aerodrome",
    "city": "Masset",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524159",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YKU",
    "lat": "53.8167",
    "lon": "-79",
    "name": "Chisasibi Aerodrome",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524160",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "22",
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YLC",
    "lat": "62.85",
    "lon": "-69.8833",
    "name": "Lake Harbour Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524162",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1700",
    "elev": "200",
    "icao": "CYLC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YLE",
    "lat": "63.15",
    "lon": "-117.267",
    "name": "Lac la Martre Aerodrome",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524163",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2200",
    "elev": "890",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YLH",
    "lat": "52.2069",
    "lon": "-87.9102",
    "name": "Lansdowne House Airport",
    "city": "Marathon",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524165",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "822",
    "icao": "CYLH",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YLL",
    "lat": "53.3122",
    "lon": "-110.073",
    "name": "Lloydminster Airport",
    "city": "Vegreville",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511734",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "2194",
    "icao": "CYLL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YLQ",
    "lat": "47.4149",
    "lon": "-72.7872",
    "name": "La Tuque Airport",
    "city": "La Tuque",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511724",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "548",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YLW",
    "lat": "49.9509",
    "lon": "-119.382",
    "name": "Kelowna International Airport",
    "city": "Kelowna",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511712",
    "tz": "America\/Vancouver",
    "phone": "(250) 765-5125",
    "type": "Airports",
    "email": "",
    "url": "www.ylw.aero",
    "runway_length": "5350",
    "elev": "1128",
    "icao": "CYLW",
    "direct_flights": "9",
    "carriers": "7"
  },
  {
    "code": "YMH",
    "lat": "52.3",
    "lon": "-55.8333",
    "name": "Mary's Harbour Airport",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524173",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYMH",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YMM",
    "lat": "56.6512",
    "lon": "-111.246",
    "name": "Fort Mcmurray Airport",
    "city": "Fort Chipewyan",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511665",
    "tz": "America\/Edmonton",
    "phone": "780 791-2448",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.fortmcmurrayairport.com\/",
    "runway_length": "6000",
    "elev": "1211",
    "icao": "CYMM",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YMN",
    "lat": "55.0872",
    "lon": "-59.1714",
    "name": "Makkovik Airport",
    "city": "Happy Valley-Goose Bay",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524175",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "234",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YMO",
    "lat": "51.2855",
    "lon": "-80.6131",
    "name": "Moosonee Airport",
    "city": "Winisk",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511762",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "26",
    "icao": "CYMO",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YMT",
    "lat": "49.7793",
    "lon": "-74.5295",
    "name": "A\u00e9roport de Chapais-Chibougamau",
    "city": "M\u00e9tabetchouan",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511612",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3850",
    "elev": "1318",
    "icao": "CYMT",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YMX",
    "lat": "45.6667",
    "lon": "-74.03",
    "name": "Mirabel International Airport",
    "city": "Mirabel",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511753",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12000",
    "elev": "270",
    "icao": "CYMX",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YMY",
    "lat": "45.4825",
    "lon": "-73.5486",
    "name": "Downtown Rail Station",
    "city": "Montreal",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524177",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YNA",
    "lat": "50.1903",
    "lon": "-61.79",
    "name": "Natashquan Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511769",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "35",
    "icao": "CYNA",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YNB",
    "lat": "24.145",
    "lon": "38.0636",
    "name": "Yenbo Airport",
    "city": "Yanbu al Bahr",
    "state": "Al Madinah",
    "country": "Saudi Arabia",
    "woeid": "12517379",
    "tz": "Asia\/Riyadh",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10531",
    "elev": "26",
    "icao": "OEYN",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YNC",
    "lat": "52.9972",
    "lon": "-78.8333",
    "name": "Wemindji Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524178",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2300",
    "elev": "30",
    "icao": "CYNC",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YND",
    "lat": "45.5179",
    "lon": "-75.5618",
    "name": "Ottawa Gatineau Airport",
    "city": "Gatineau",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511778",
    "tz": "America\/Toronto",
    "phone": "(819)-663-0737",
    "type": "Other Airport",
    "email": "infos@ego-airport.ca",
    "url": "http:\/\/www.ego-airport.ca",
    "runway_length": "6000",
    "elev": "192",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YNE",
    "lat": "53.9833",
    "lon": "-97.8",
    "name": "Norway House Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524179",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3940",
    "elev": "730",
    "icao": "CYNE",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YNG",
    "lat": "41.2576",
    "lon": "-80.6648",
    "name": "Youngstown-Warren Regional Airport",
    "city": "Vienna",
    "state": "Ohio",
    "country": "United States",
    "woeid": "12522541",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7492",
    "elev": "1196",
    "icao": "KYNG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YNJ",
    "lat": "42.65",
    "lon": "129.5",
    "name": "Yanji",
    "city": "Yanji",
    "state": "\u5409\u6797\u7701",
    "country": "China",
    "woeid": "12712349",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "7"
  },
  {
    "code": "YNO",
    "lat": "52.4953",
    "lon": "-92.8807",
    "name": "North Spirit Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524184",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YNP",
    "lat": "62.3587",
    "lon": "-96.5821",
    "name": "",
    "city": "Natuashish",
    "state": "",
    "country": "Canada",
    "woeid": "23424775",
    "tz": "America\/Resolute",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "26",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YNS",
    "lat": "51.6896",
    "lon": "-76.1421",
    "name": "Nemiscau Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524186",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YNT",
    "lat": "37.55",
    "lon": "121.333",
    "name": "Yantai Airport",
    "city": "Yantai",
    "state": "Shandong",
    "country": "China",
    "woeid": "12523272",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "20",
    "carriers": "9"
  },
  {
    "code": "YNY",
    "lat": "38.0481",
    "lon": "128.656",
    "name": "Yang Yang International Airport",
    "city": "Sonyang-Myeon",
    "state": "Kangwon-Do",
    "country": "South Korea",
    "woeid": "28808959",
    "tz": "Asia\/Seoul",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YNZ",
    "lat": "33.3856",
    "lon": "120.125",
    "name": "",
    "city": "Yancheng",
    "state": "Jiangsu",
    "country": "China",
    "woeid": "2137091",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YOC",
    "lat": "67.5734",
    "lon": "-139.849",
    "name": "Old Crow Airport",
    "city": "Dawson",
    "state": "Yukon Territory",
    "country": "Canada",
    "woeid": "12524187",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "824",
    "icao": "CYOC",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YOG",
    "lat": "51.6291",
    "lon": "-85.954",
    "name": "Ogoki Aerodrome",
    "city": "Marathon",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524189",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "602",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YOH",
    "lat": "54.9357",
    "lon": "-95.2757",
    "name": "Oxford House Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524190",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "672",
    "icao": "CYOH",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YOJ",
    "lat": "58.6137",
    "lon": "-117.163",
    "name": "High Level Airport",
    "city": "Brownvale",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511694",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1110",
    "icao": "CYOJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YOO",
    "lat": "43.9263",
    "lon": "-78.8959",
    "name": "Oshawa Airport",
    "city": "Oshawa",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511777",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3476",
    "elev": "458",
    "icao": "CYOO",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "YOP",
    "lat": "58.4906",
    "lon": "-119.402",
    "name": "Rainbow Lake Airport",
    "city": "Brownvale",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511807",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "1759",
    "icao": "CYOP",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YOW",
    "lat": "45.3248",
    "lon": "-75.6666",
    "name": "Ottawa International Airport",
    "city": "Ottawa",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511779",
    "tz": "America\/Toronto",
    "phone": "(613) 248-2000",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.ottawa-airport.ca",
    "runway_length": "10000",
    "elev": "374",
    "icao": "CYOW",
    "direct_flights": "43",
    "carriers": "32"
  },
  {
    "code": "YPB",
    "lat": "49.2618",
    "lon": "-124.836",
    "name": "Port Alberni Airport",
    "city": "Lake Cowichan",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524192",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2100",
    "elev": "8",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YPC",
    "lat": "69.3499",
    "lon": "-124.085",
    "name": "Paulatuk Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524250",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3200",
    "elev": "50",
    "icao": "CYPC",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YPE",
    "lat": "56.2314",
    "lon": "-117.448",
    "name": "Peace River Airport",
    "city": "Brownvale",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511783",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1873",
    "icao": "CYPE",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YPH",
    "lat": "58.4575",
    "lon": "-78.1327",
    "name": "Inukjuak Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524196",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "9",
    "icao": "CYPH",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YPJ",
    "lat": "58.8566",
    "lon": "-69.3528",
    "name": "Aupaluk Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524198",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YPL",
    "lat": "51.4503",
    "lon": "-90.2155",
    "name": "Pickle Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511790",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "1267",
    "icao": "CYPL",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "YPM",
    "lat": "51.8117",
    "lon": "-93.9886",
    "name": "Pikangikum Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524199",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "1114",
    "icao": "CYPM",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "YPO",
    "lat": "54.9906",
    "lon": "-85.4393",
    "name": "Peawanuck Airport",
    "city": "Winisk",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524200",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "174",
    "icao": "CYPO",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YPR",
    "lat": "54.292",
    "lon": "-130.446",
    "name": "Prince Rupert Airport",
    "city": "Prince Rupert",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511802",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "111",
    "icao": "CYPR",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YPW",
    "lat": "49.8369",
    "lon": "-124.496",
    "name": "Powell River Airport",
    "city": "Powell River",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511799",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3760",
    "elev": "425",
    "icao": "CYPW",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YPX",
    "lat": "59.8141",
    "lon": "-77.2512",
    "name": "Povungnituk Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524203",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2800",
    "elev": "48",
    "icao": "CYPX",
    "direct_flights": "6",
    "carriers": "1"
  },
  {
    "code": "YPZ",
    "lat": "54.2526",
    "lon": "-125.773",
    "name": "Burns Lake Airport",
    "city": "Smithers",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511591",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "2340",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YQB",
    "lat": "46.791",
    "lon": "-71.3837",
    "name": "Quebec Airport",
    "city": "Ste-Foy",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511805",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9000",
    "elev": "243",
    "icao": "CYQB",
    "direct_flights": "22",
    "carriers": "16"
  },
  {
    "code": "YQC",
    "lat": "61.0495",
    "lon": "-69.6207",
    "name": "Quaqtaq Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524204",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YQD",
    "lat": "53.8234",
    "lon": "-101.205",
    "name": "The Pas Airport",
    "city": "The Pas",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511871",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYQD",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YQF",
    "lat": "52.1786",
    "lon": "-113.883",
    "name": "Red Deer Regional Airport",
    "city": "Sylvan Lake",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511809",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "5528",
    "elev": "2968",
    "icao": "CYQF",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YQG",
    "lat": "42.2657",
    "lon": "-82.9602",
    "name": "Windsor Airport",
    "city": "Windsor",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511916",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7900",
    "elev": "622",
    "icao": "CYQG",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YQK",
    "lat": "49.7893",
    "lon": "-94.3676",
    "name": "Kenora Airport",
    "city": "Kenora",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511713",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1342",
    "icao": "CYQK",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YQL",
    "lat": "49.6344",
    "lon": "-112.787",
    "name": "Lethbridge Airport",
    "city": "Pincher",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511730",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.lethbridgecountyairport.com\/",
    "runway_length": "6500",
    "elev": "3047",
    "icao": "CYQL",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YQM",
    "lat": "46.1166",
    "lon": "-64.6883",
    "name": "Greater Moncton International Airport",
    "city": "Moncton",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "12511754",
    "tz": "America\/Halifax",
    "phone": "(506) 856-5455",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.gmia.ca",
    "runway_length": "8000",
    "elev": "232",
    "icao": "CYQM",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "YQN",
    "lat": "50.1667",
    "lon": "-86.7",
    "name": "Nakina Airport",
    "city": "Marathon",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524206",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "3800",
    "elev": "1050",
    "icao": "CYQN",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YQQ",
    "lat": "49.7083",
    "lon": "-124.897",
    "name": "Comox Airport",
    "city": "Comox",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511624",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "80",
    "icao": "CYQQ",
    "direct_flights": "5",
    "carriers": "5"
  },
  {
    "code": "YQR",
    "lat": "50.4345",
    "lon": "-104.655",
    "name": "Regina Airport",
    "city": "Regina",
    "state": "Saskatchewan",
    "country": "Canada",
    "woeid": "23388227",
    "tz": "America\/Regina",
    "phone": "+1 306 761 7555",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.yqr.ca",
    "runway_length": "7900",
    "elev": "1894",
    "icao": "CYQR",
    "direct_flights": "8",
    "carriers": "4"
  },
  {
    "code": "YQT",
    "lat": "48.3718",
    "lon": "-89.3118",
    "name": "Thunder Bay International Airport",
    "city": "Thunder Bay",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511875",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYQT",
    "direct_flights": "11",
    "carriers": "8"
  },
  {
    "code": "YQU",
    "lat": "55.1787",
    "lon": "-118.872",
    "name": "Grande Prairie Airport",
    "city": "Brownvale",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511684",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "2195",
    "icao": "CYQU",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YQX",
    "lat": "48.9465",
    "lon": "-54.5802",
    "name": "Gander International Airport",
    "city": "Gander",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511673",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "496",
    "icao": "CYQX",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YQY",
    "lat": "46.1675",
    "lon": "-60.0473",
    "name": "Sydney Airport",
    "city": "Sydney",
    "state": "Nova Scotia",
    "country": "Canada",
    "woeid": "12511865",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7070",
    "elev": "203",
    "icao": "CYQY",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YQZ",
    "lat": "53.0275",
    "lon": "-122.507",
    "name": "Quesnel Airport",
    "city": "Quesnel",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511806",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "1789",
    "icao": "CYQZ",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YRA",
    "lat": "64.1111",
    "lon": "-117.353",
    "name": "Rae Lakes Aerodrome",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524207",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2800",
    "elev": "700",
    "icao": "CYRA",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YRB",
    "lat": "74.7167",
    "lon": "-94.9667",
    "name": "Resolute Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12523273",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6500",
    "elev": "221",
    "icao": "CYRB",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YRF",
    "lat": "53.7084",
    "lon": "-57.0014",
    "name": "Cartwright Airport",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524210",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "153",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YRG",
    "lat": "54.2102",
    "lon": "-58.4141",
    "name": "Rigolet Aerodrome",
    "city": "Happy Valley-Goose Bay",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524211",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "180",
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YRJ",
    "lat": "48.52",
    "lon": "-72.2661",
    "name": "Roberval Airport",
    "city": "Roberval",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511816",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "586",
    "icao": "CYRJ",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YRL",
    "lat": "51.0683",
    "lon": "-93.8015",
    "name": "Red Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511811",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "1257",
    "icao": "CYRL",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "YRS",
    "lat": "54.1623",
    "lon": "-93.5887",
    "name": "Red Sucker Lake Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524214",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "729",
    "icao": "CYRS",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YRT",
    "lat": "62.8042",
    "lon": "-92.1026",
    "name": "Rankin Inlet Airport",
    "city": "Chesterfield Inlet",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524215",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "106",
    "icao": "CYRT",
    "direct_flights": "11",
    "carriers": "5"
  },
  {
    "code": "YSB",
    "lat": "46.6229",
    "lon": "-80.7953",
    "name": "Sudbury Airport",
    "city": "Garson",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511859",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6600",
    "elev": "1140",
    "icao": "CYSB",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "YSG",
    "lat": "62.4009",
    "lon": "-110.693",
    "name": "Snowdrift Aerodrome",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524218",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2240",
    "elev": "575",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YSH",
    "lat": "44.9289",
    "lon": "-75.9706",
    "name": "Smiths Falls Montague Township Russ Beach Airpor",
    "city": "Smiths Falls",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511834",
    "tz": "America\/Montreal",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "416",
    "icao": "CYSH",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YSJ",
    "lat": "45.3294",
    "lon": "-65.8903",
    "name": "Saint John Airport",
    "city": "St. John",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "12511822",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "356",
    "icao": "CYSJ",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YSK",
    "lat": "56.2385",
    "lon": "-78.8122",
    "name": "Sanikiluaq Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524220",
    "tz": "America\/Iqaluit",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3800",
    "elev": "107",
    "icao": "CYSK",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YSM",
    "lat": "60.0208",
    "lon": "-111.963",
    "name": "Fort Smith Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12511668",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "666",
    "icao": "CYSM",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YSO",
    "lat": "54.9119",
    "lon": "-59.7708",
    "name": "Postville Aerodrome",
    "city": "Happy Valley-Goose Bay",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524221",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "193",
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YSR",
    "lat": "72.9833",
    "lon": "-84.6333",
    "name": "Nanisivik Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12523274",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6400",
    "elev": "2098",
    "icao": "CYSR",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YST",
    "lat": "20.9417",
    "lon": "95.9181",
    "name": "Shante Airport",
    "city": "Meiktila",
    "state": "Mandalay",
    "country": "Myanmar",
    "woeid": "12510935",
    "tz": "Asia\/Rangoon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "773",
    "icao": "CYST",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YSY",
    "lat": "72",
    "lon": "-125.267",
    "name": "Sachs Harbour Airport",
    "city": "Hay River",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12523262",
    "tz": "America\/Inuvik",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "281",
    "icao": "CYSY",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YTD",
    "lat": "55.3167",
    "lon": "-97.7",
    "name": "Thicket Portage Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524225",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CZLQ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YTE",
    "lat": "64.2228",
    "lon": "-76.5337",
    "name": "Cape Dorset Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524226",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "158",
    "icao": "CYTE",
    "direct_flights": "3",
    "carriers": "4"
  },
  {
    "code": "YTF",
    "lat": "48.55",
    "lon": "-71.65",
    "name": "Alma",
    "city": "Alma",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511566",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4300",
    "elev": "445",
    "icao": "CYTF",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YTH",
    "lat": "55.7999",
    "lon": "-97.8598",
    "name": "Thompson Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511873",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYTH",
    "direct_flights": "16",
    "carriers": "4"
  },
  {
    "code": "YTL",
    "lat": "53.7767",
    "lon": "-89.9697",
    "name": "Big Trout Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524229",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3300",
    "elev": "740",
    "icao": "CYTL",
    "direct_flights": "7",
    "carriers": "1"
  },
  {
    "code": "YTM",
    "lat": "46.25",
    "lon": "-74.5833",
    "name": "La Macaza",
    "city": "Mont Tremblant",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "23707674",
    "tz": "America\/Montreal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YTQ",
    "lat": "53.6833",
    "lon": "-69.9333",
    "name": "Tasiujuaq Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524231",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYTQ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YTS",
    "lat": "48.5655",
    "lon": "-81.3718",
    "name": "Timmins Airport",
    "city": "South Porcupine",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511877",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYTS",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YTZ",
    "lat": "43.6321",
    "lon": "-79.3962",
    "name": "Toronto City Centre Airport",
    "city": "Toronto",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511882",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYTZ",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "YUB",
    "lat": "69.4335",
    "lon": "-133.02",
    "name": "Tuktoyaktuk Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524233",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYUB",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YUD",
    "lat": "56.4835",
    "lon": "-76.4426",
    "name": "Umiujaq Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524234",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YUL",
    "lat": "45.4562",
    "lon": "-73.7473",
    "name": "A\u00e9roport International Pierre-Elliott-Trudeau d",
    "city": "Dorval",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511638",
    "tz": "America\/Toronto",
    "phone": "514 394 7377",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.admtl.com",
    "runway_length": "11000",
    "elev": "117",
    "icao": "CYUL",
    "direct_flights": "104",
    "carriers": "57"
  },
  {
    "code": "YUM",
    "lat": "32.6685",
    "lon": "-114.599",
    "name": "Yuma International Airport",
    "city": "Yuma",
    "state": "Arizona",
    "country": "United States",
    "woeid": "12522544",
    "tz": "America\/Phoenix",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "13299",
    "elev": "213",
    "icao": "KYUM",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YUT",
    "lat": "66.5333",
    "lon": "-86.25",
    "name": "Repulse Bay Airport",
    "city": "Chesterfield Inlet",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524241",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3400",
    "elev": "80",
    "icao": "CYUT",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YUX",
    "lat": "68.7804",
    "lon": "-81.2429",
    "name": "Hall Beach Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524245",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5400",
    "elev": "27",
    "icao": "CYUX",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YUY",
    "lat": "48.2135",
    "lon": "-78.8287",
    "name": "Rouyn Noranda Airport",
    "city": "\u00c9vain",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511819",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "988",
    "icao": "CYUY",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "YVA",
    "lat": "-11.7075",
    "lon": "43.2342",
    "name": "Moroni Iconi Airport",
    "city": "Moroni",
    "state": "Grande Comore",
    "country": "Comoros",
    "woeid": "12512358",
    "tz": "Indian\/Comoro",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4445",
    "elev": "33",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YVB",
    "lat": "48.0717",
    "lon": "-65.4614",
    "name": "Bonaventure Airport",
    "city": "Perc\u00e9",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511580",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "120",
    "icao": "CYVB",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YVM",
    "lat": "67.5412",
    "lon": "-63.9855",
    "name": "Broughton Island Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524254",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3475",
    "elev": "15",
    "icao": "CYVM",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "YVO",
    "lat": "48.0623",
    "lon": "-77.7896",
    "name": "Val d'or Airport",
    "city": "Val-d'or",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511891",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYVO",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YVP",
    "lat": "58.102",
    "lon": "-68.4333",
    "name": "Kuujjuaq Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511719",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "122",
    "icao": "CYVP",
    "direct_flights": "8",
    "carriers": "2"
  },
  {
    "code": "YVQ",
    "lat": "65.2825",
    "lon": "-126.798",
    "name": "Norman Wells Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12511772",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "241",
    "icao": "CYVQ",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "YVR",
    "lat": "49.1931",
    "lon": "-123.172",
    "name": "Vancouver International Airport",
    "city": "Richmond",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511894",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.yvr.ca\/",
    "runway_length": "11500",
    "elev": "9",
    "icao": "CYVR",
    "direct_flights": "92",
    "carriers": "54"
  },
  {
    "code": "YVZ",
    "lat": "52.6528",
    "lon": "-94.1569",
    "name": "Deer Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524256",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYVZ",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YWB",
    "lat": "61.6013",
    "lon": "-71.9503",
    "name": "Kangiqsujuaq Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524258",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YWG",
    "lat": "49.9045",
    "lon": "-97.2253",
    "name": "Winnipeg International Airport",
    "city": "Winnipeg",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511918",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "11000",
    "elev": "783",
    "icao": "CYWG",
    "direct_flights": "38",
    "carriers": "17"
  },
  {
    "code": "YWH",
    "lat": "70.9166",
    "lon": "-109.985",
    "name": "Inner Harbour Airport",
    "city": "Hay River",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12511702",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYWH",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "YWJ",
    "lat": "65.1994",
    "lon": "-123.431",
    "name": "Fort Franklin Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524259",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "550",
    "icao": "CYWJ",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YWK",
    "lat": "52.9261",
    "lon": "-66.873",
    "name": "Wabush Airport",
    "city": "Wabush",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511902",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1806",
    "icao": "CYWK",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "YWL",
    "lat": "52.1866",
    "lon": "-122.068",
    "name": "Williams Lake Airport",
    "city": "Williams Lake",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511915",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7000",
    "elev": "3085",
    "icao": "CYWL",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "YWM",
    "lat": "52.3333",
    "lon": "-56",
    "name": "Williams Harbour Airport",
    "city": "St. Anthony",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12524260",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Harbours",
    "email": "",
    "url": "",
    "runway_length": "2000",
    "elev": "70",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YWP",
    "lat": "52.978",
    "lon": "-87.3578",
    "name": "Webequie Airport",
    "city": "Marathon",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524263",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "650",
    "icao": "CYWP",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "YWS",
    "lat": "50.1333",
    "lon": "-122.95",
    "name": "Green Lake Water Aerodrome",
    "city": "Gibsons",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12523277",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YXC",
    "lat": "49.6148",
    "lon": "-115.786",
    "name": "Cranbrook Airport",
    "city": "Cranbrook",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511628",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "3082",
    "icao": "CYXC",
    "direct_flights": "3",
    "carriers": "3"
  },
  {
    "code": "YXE",
    "lat": "52.1703",
    "lon": "-106.689",
    "name": "John G Diefenbaker International Airport",
    "city": "Saskatoon",
    "state": "Saskatchewan",
    "country": "Canada",
    "woeid": "12511826",
    "tz": "America\/Regina",
    "phone": "+1 306 9758900",
    "type": "Airports",
    "email": "comments@saskatoonairport.ca",
    "url": "http:\/\/www.yxe.ca",
    "runway_length": "8300",
    "elev": "1650",
    "icao": "CYXE",
    "direct_flights": "12",
    "carriers": "8"
  },
  {
    "code": "YXH",
    "lat": "50.0219",
    "lon": "-110.722",
    "name": "Medicine Hat Airport",
    "city": "Medicine Hat",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511748",
    "tz": "America\/Edmonton",
    "phone": "403-526-4664",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.medicinehat.ca\/cityservices\/airport",
    "runway_length": "5000",
    "elev": "2352",
    "icao": "CYXH",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YXJ",
    "lat": "56.2464",
    "lon": "-120.737",
    "name": "North Peace Airport",
    "city": "Fort St. John",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511669",
    "tz": "America\/Dawson_Creek",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6900",
    "elev": "2280",
    "icao": "CYXJ",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "YXK",
    "lat": "48.4727",
    "lon": "-68.5044",
    "name": "Rimouski Airport",
    "city": "Rimouski",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511814",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": "4600",
    "elev": "82",
    "icao": "CYXK",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YXL",
    "lat": "50.1153",
    "lon": "-91.9056",
    "name": "Sioux Lookout Airport",
    "city": "Sioux Lookout",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511831",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4400",
    "elev": "1280",
    "icao": "CYXL",
    "direct_flights": "22",
    "carriers": "3"
  },
  {
    "code": "YXN",
    "lat": "62.2273",
    "lon": "-92.5948",
    "name": "Whale Cove Airport",
    "city": "Chesterfield Inlet",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524268",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3405",
    "elev": "66",
    "icao": "CYXN",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YXP",
    "lat": "66.1349",
    "lon": "-65.7142",
    "name": "Pangnirtung Airport",
    "city": "Iqaluit",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524269",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "2500",
    "elev": "82",
    "icao": "CYXP",
    "direct_flights": "2",
    "carriers": "3"
  },
  {
    "code": "YXS",
    "lat": "53.8805",
    "lon": "-122.676",
    "name": "Prince George Airport",
    "city": "Prince George",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511801",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7400",
    "elev": "2268",
    "icao": "CYXS",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "YXT",
    "lat": "54.4727",
    "lon": "-128.577",
    "name": "Terrace Airport",
    "city": "Terrace",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511868",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYXT",
    "direct_flights": "5",
    "carriers": "4"
  },
  {
    "code": "YXU",
    "lat": "43.0285",
    "lon": "-81.1497",
    "name": "London International Airport",
    "city": "London",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511735",
    "tz": "America\/Toronto",
    "phone": "(519) 452-4015",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.londonairport.on.ca",
    "runway_length": "8800",
    "elev": "912",
    "icao": "CYXU",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "YXX",
    "lat": "49.0221",
    "lon": "-122.382",
    "name": "Abbotsford International Airport",
    "city": "Abbotsford",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511565",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8000",
    "elev": "190",
    "icao": "CYXX",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "YXY",
    "lat": "60.7141",
    "lon": "-135.076",
    "name": "Whitehorse International Airport",
    "city": "Whitehorse",
    "state": "Yukon Territory",
    "country": "Canada",
    "woeid": "12511913",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9497",
    "elev": "2317",
    "icao": "CYXY",
    "direct_flights": "6",
    "carriers": "5"
  },
  {
    "code": "YYB",
    "lat": "46.3564",
    "lon": "-79.4285",
    "name": "North Bay Airport",
    "city": "North Bay",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511774",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10000",
    "elev": "1215",
    "icao": "CYYB",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YYC",
    "lat": "51.1343",
    "lon": "-114.007",
    "name": "Calgary International Airport",
    "city": "Calgary",
    "state": "Alberta",
    "country": "Canada",
    "woeid": "12511594",
    "tz": "America\/Edmonton",
    "phone": "403-735-1200",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.yyc.com",
    "runway_length": "12675",
    "elev": "3557",
    "icao": "CYYC",
    "direct_flights": "64",
    "carriers": "33"
  },
  {
    "code": "YYD",
    "lat": "54.8247",
    "lon": "-127.181",
    "name": "Smithers Airport",
    "city": "Smithers",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511833",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1717",
    "icao": "CYYD",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "YYE",
    "lat": "58.8429",
    "lon": "-122.579",
    "name": "Fort Nelson Airport",
    "city": "Fort Nelson",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511666",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6400",
    "elev": "1253",
    "icao": "CYYE",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YYF",
    "lat": "49.4614",
    "lon": "-119.606",
    "name": "Penticton Airport",
    "city": "Penticton",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511788",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "1129",
    "icao": "CYYF",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YYG",
    "lat": "46.2833",
    "lon": "-63.1333",
    "name": "Charlottetown Airport",
    "city": "Charlottetown",
    "state": "Prince Edward Island",
    "country": "Canada",
    "woeid": "12511608",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.flypei.com",
    "runway_length": "7000",
    "elev": "178",
    "icao": "CYYG",
    "direct_flights": "7",
    "carriers": "6"
  },
  {
    "code": "YYH",
    "lat": "69.5466",
    "lon": "-93.5751",
    "name": "Taloyoak Airport",
    "city": "Cambridge Bay",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524271",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYYH",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YYJ",
    "lat": "48.6402",
    "lon": "-123.43",
    "name": "Victoria International Airport",
    "city": "Sidney",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511899",
    "tz": "America\/Vancouver",
    "phone": "(250) 953-7500",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.victoriaairport.com",
    "runway_length": "7000",
    "elev": "63",
    "icao": "CYYJ",
    "direct_flights": "9",
    "carriers": "11"
  },
  {
    "code": "YYL",
    "lat": "56.8617",
    "lon": "-101.071",
    "name": "Lynn Lake Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511737",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "1170",
    "icao": "CYYL",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "YYQ",
    "lat": "58.7557",
    "lon": "-94.0678",
    "name": "Churchill Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12511615",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYYQ",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "YYR",
    "lat": "53.3263",
    "lon": "-60.4106",
    "name": "Goose Bay Airport",
    "city": "Happy Valley-Goose Bay",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511679",
    "tz": "America\/Goose_Bay",
    "phone": "709-896-5445",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.goosebayairport.com\/index1.htm",
    "runway_length": "11050",
    "elev": "160",
    "icao": "CYYR",
    "direct_flights": "11",
    "carriers": "3"
  },
  {
    "code": "YYT",
    "lat": "47.6124",
    "lon": "-52.7425",
    "name": "St John's International Airport",
    "city": "St. John's",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511848",
    "tz": "America\/St_Johns",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.stjohnsairport.com\/",
    "runway_length": null,
    "elev": "462",
    "icao": "CYYT",
    "direct_flights": "11",
    "carriers": "9"
  },
  {
    "code": "YYU",
    "lat": "49.4098",
    "lon": "-82.4618",
    "name": "Kapuskasing Airport",
    "city": "Kapuskasing",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511711",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "743",
    "icao": "CYYU",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YYY",
    "lat": "48.6069",
    "lon": "-68.2111",
    "name": "Mont Joli Airport",
    "city": "Mont-Joli",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511755",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6000",
    "elev": "172",
    "icao": "CYYY",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "YYZ",
    "lat": "43.685",
    "lon": "-79.6142",
    "name": "Toronto Lester B Pearson International Airport",
    "city": "Mississauga",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511883",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.gtaa.com\/",
    "runway_length": "11120",
    "elev": "569",
    "icao": "CYYZ",
    "direct_flights": "176",
    "carriers": "66"
  },
  {
    "code": "YZF",
    "lat": "62.4699",
    "lon": "-114.433",
    "name": "Yellowknife Airport",
    "city": "Yellowknife",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12511922",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7500",
    "elev": "674",
    "icao": "CYZF",
    "direct_flights": "18",
    "carriers": "6"
  },
  {
    "code": "YZG",
    "lat": "62.1853",
    "lon": "-75.6685",
    "name": "Salluit Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524276",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CYZG",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "YZP",
    "lat": "53.2528",
    "lon": "-131.814",
    "name": "Sandspit Airport",
    "city": "Masset",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511824",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5120",
    "elev": "21",
    "icao": "CYZP",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YZR",
    "lat": "42.9965",
    "lon": "-82.3078",
    "name": "Sarnia Airport",
    "city": "Sarnia",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12511825",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5100",
    "elev": "594",
    "icao": "CYZR",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "YZS",
    "lat": "64.2",
    "lon": "-83.3667",
    "name": "Coral Harbour Airport",
    "city": "Chesterfield Inlet",
    "state": "Nunavut",
    "country": "Canada",
    "woeid": "12524279",
    "tz": "America\/Coral_Harbour",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "210",
    "icao": "CYZS",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "YZT",
    "lat": "50.6843",
    "lon": "-127.377",
    "name": "Port Hardy Airport",
    "city": "Gibsons",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12511795",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": "71",
    "icao": "CYZT",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "YZV",
    "lat": "50.2169",
    "lon": "-66.2654",
    "name": "Sept Iles Airport",
    "city": "Sept-\u00celes",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12511829",
    "tz": "America\/Montreal",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6572",
    "elev": "180",
    "icao": "CYZV",
    "direct_flights": "8",
    "carriers": "5"
  },
  {
    "code": "YZZ",
    "lat": "37.0625",
    "lon": "-95.6771",
    "name": "Trail Airport",
    "city": "Trail",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "9923",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZAC",
    "lat": "56.0763",
    "lon": "-96.09",
    "name": "York Landing Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524282",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "609",
    "icao": "CZAC",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZAD",
    "lat": "44.0978",
    "lon": "15.3567",
    "name": "Zadar Airport",
    "city": "Zadar",
    "state": "Zadarsko-Kninska",
    "country": "Croatia",
    "woeid": "12513375",
    "tz": "Europe\/Zagreb",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.zadar-airport.hr\/eng\/",
    "runway_length": "8202",
    "elev": "269",
    "icao": "LDZD",
    "direct_flights": "10",
    "carriers": "5"
  },
  {
    "code": "ZAG",
    "lat": "45.7402",
    "lon": "16.0704",
    "name": "Zagreb Airport",
    "city": "Nagygoricza",
    "state": "Zagreba\u010dka",
    "country": "Croatia",
    "woeid": "12513376",
    "tz": "Europe\/Belgrade",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.zagreb-airport.hr\/",
    "runway_length": "10663",
    "elev": "351",
    "icao": "LDZA",
    "direct_flights": "37",
    "carriers": "25"
  },
  {
    "code": "ZAH",
    "lat": "29.4764",
    "lon": "60.9044",
    "name": "Zahedan International Airport",
    "city": "Dowzdab",
    "state": "Sistan va Baluchestan",
    "country": "Iran",
    "woeid": "12513773",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.Iranairports.com",
    "runway_length": "14000",
    "elev": "4517",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "ZAL",
    "lat": "-39.6458",
    "lon": "-73.0847",
    "name": "Pichoy Airport",
    "city": "Pelchuquin",
    "state": "Los Lagos",
    "country": "Chile",
    "woeid": "12512336",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4199",
    "elev": "13",
    "icao": "SCVL",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ZAM",
    "lat": "6.9244",
    "lon": "122.061",
    "name": "Zamboanga International Airport",
    "city": "Zamboanga",
    "state": "Zamboanga Peninsula",
    "country": "Philippines",
    "woeid": "12515655",
    "tz": "Asia\/Manila",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8560",
    "elev": "30",
    "icao": "",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "ZAQ",
    "lat": "48.9174",
    "lon": "11.408",
    "name": "Bavaria",
    "city": "Nuremberg",
    "state": "Bavaria",
    "country": "Germany",
    "woeid": "2345482",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "ZAT",
    "lat": "27.3167",
    "lon": "103.8",
    "name": "Zhaotong",
    "city": "Zhaotong",
    "state": "Yunnan",
    "country": "China",
    "woeid": "2160703",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZAZ",
    "lat": "41.6607",
    "lon": "-1.0078",
    "name": "Zaragoza Air Base",
    "city": "Zaragoza",
    "state": "Aragon",
    "country": "Spain",
    "woeid": "12517581",
    "tz": "Europe\/Madrid",
    "phone": "",
    "type": "Military Airport",
    "email": "",
    "url": "",
    "runway_length": "12197",
    "elev": "863",
    "icao": "LEZG",
    "direct_flights": "20",
    "carriers": "6"
  },
  {
    "code": "ZBA",
    "lat": "47.5487",
    "lon": "7.58768",
    "name": "Switzerland",
    "city": "Basel",
    "state": "Canton of Basel-City",
    "country": "Switzerland",
    "woeid": "23424957",
    "tz": "Europe\/Zurich",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ZBF",
    "lat": "47.6325",
    "lon": "-65.74",
    "name": "Bathurst Airport",
    "city": "Bathurst",
    "state": "New Brunswick",
    "country": "Canada",
    "woeid": "12511575",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "194",
    "icao": "CZBF",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ZBL",
    "lat": "-24.3903",
    "lon": "150.519",
    "name": "Australia",
    "city": "Biloela",
    "state": "Queensland",
    "country": "Australia",
    "woeid": "23424748",
    "tz": "Australia\/Brisbane",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZBP",
    "lat": "39.3117",
    "lon": "-76.6183",
    "name": "",
    "city": "Baltimore",
    "state": "Maryland",
    "country": "United States",
    "woeid": "2358820",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZBR",
    "lat": "25.445",
    "lon": "60.3819",
    "name": "Chah Bahar Airport",
    "city": "Chabahar",
    "state": "Sistan va Baluchestan",
    "country": "Iran",
    "woeid": "12513711",
    "tz": "Asia\/Tehran",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "12630",
    "elev": "23",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "ZCB",
    "lat": "41.9619",
    "lon": "21.6181",
    "name": "Skopje Airport",
    "city": "",
    "state": "Ilinden",
    "country": "Macedonia",
    "woeid": "12514772",
    "tz": "Europe\/Skopje",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZCL",
    "lat": "22.8972",
    "lon": "-102.685",
    "name": "Zacatecas Airport",
    "city": "P\u00e1nuco",
    "state": "Zacatecas",
    "country": "Mexico",
    "woeid": "12514987",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "9640",
    "elev": "7021",
    "icao": "MMZC",
    "direct_flights": "7",
    "carriers": "3"
  },
  {
    "code": "ZCO",
    "lat": "-38.7639",
    "lon": "-72.6378",
    "name": "Maquehue Airport",
    "city": "Padre Las Casas",
    "state": "Araucania",
    "country": "Chile",
    "woeid": "12512332",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "SCTC",
    "direct_flights": "4",
    "carriers": "5"
  },
  {
    "code": "ZDH",
    "lat": "47.5487",
    "lon": "7.58768",
    "name": "Basel SBB station",
    "city": "Basel",
    "state": "Basel-Stadt",
    "country": "Switzerland",
    "woeid": "781739",
    "tz": "Europe\/Zurich",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZDN",
    "lat": "-33.9344",
    "lon": "151.168",
    "name": "Kingsford Smith Airport",
    "city": "Sydney",
    "state": "New South Wales",
    "country": "Australia",
    "woeid": "23388205",
    "tz": "Australia\/Sydney",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ZDU",
    "lat": "51.5039",
    "lon": "0.04981",
    "name": "London City Airport",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "22475376",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZEE",
    "lat": "30.754",
    "lon": "-91.3415",
    "name": "Kelsey Airport",
    "city": "Kelsey",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524044",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CZEE",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZEI",
    "lat": "12.9794",
    "lon": "11.8559",
    "name": "",
    "city": "Zei",
    "state": "Yobe",
    "country": "Nigeria",
    "woeid": "1511177",
    "tz": "Africa\/Lagos",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZEJ",
    "lat": "47.7134",
    "lon": "22.8887",
    "name": "Satu Mare Airport",
    "city": "",
    "state": "Satu Mare",
    "country": "Romania",
    "woeid": "12515569",
    "tz": "Europe\/Bucharest",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ZEL",
    "lat": "52.152",
    "lon": "-128.155",
    "name": "Bella Bella Airport",
    "city": "Masset",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524283",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1500",
    "elev": "100",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZEM",
    "lat": "52.2399",
    "lon": "-78.5077",
    "name": "East Main Airport",
    "city": "Chisasibi",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524284",
    "tz": "America\/Nipigon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "1900",
    "elev": "20",
    "icao": "CZEM",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZEP",
    "lat": "39.0804",
    "lon": "-78.4233",
    "name": "",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "44418",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ZEU",
    "lat": "2.51769",
    "lon": "30.7978",
    "name": "",
    "city": "Zeu",
    "state": "Nebbi",
    "country": "Uganda",
    "woeid": "1455541",
    "tz": "Africa\/Kampala",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ZFI",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "Chesterfield",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Bus Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ZFJ",
    "lat": "46.7124",
    "lon": "1.71832",
    "name": "France",
    "city": "Rennes",
    "state": null,
    "country": "France",
    "woeid": "23424819",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "8"
  },
  {
    "code": "ZFM",
    "lat": "67.4024",
    "lon": "-134.849",
    "name": "Fort Mcpherson Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524289",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "142",
    "icao": "CZFM",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZFN",
    "lat": "64.9",
    "lon": "-125.583",
    "name": "Fort Norman Airport",
    "city": "Hay River",
    "state": "Northwest Territories",
    "country": "Canada",
    "woeid": "12524290",
    "tz": "America\/Edmonton",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3000",
    "elev": "320",
    "icao": "CZFN",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZFQ",
    "lat": "46.7124",
    "lon": "1.71832",
    "name": "France",
    "city": "Bordeaux",
    "state": null,
    "country": "France",
    "woeid": "23424819",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "9"
  },
  {
    "code": "ZFV",
    "lat": "40.9946",
    "lon": "-77.6045",
    "name": "Philadelphia Rail",
    "city": "Philadelphia",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "2347597",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "ZFZ",
    "lat": "40.7145",
    "lon": "-74.0071",
    "name": "New York",
    "city": "Buffalo",
    "state": "New York",
    "country": "United States",
    "woeid": "2459115",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZGD",
    "lat": "41.3564",
    "lon": "-72.0965",
    "name": "New London",
    "city": "Groton",
    "state": "Connecticut",
    "country": "United States",
    "woeid": "2458689",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZGG",
    "lat": "54.3139",
    "lon": "-2.23218",
    "name": "United Kingdom",
    "city": "Glasgow",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "23424975",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZGH",
    "lat": "55.6205",
    "lon": "12.6495",
    "name": "Copenhagen Main Station",
    "city": "Copenhagen",
    "state": "",
    "country": "Denmark",
    "woeid": "22851127",
    "tz": "Europe\/Copenhagen",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZGI",
    "lat": "54.6768",
    "lon": "-94.1459",
    "name": "Gods River Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524291",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4000",
    "elev": "600",
    "icao": "CZGI",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ZGN",
    "lat": "22.5167",
    "lon": "113.367",
    "name": "Zhongshan Ferry Port",
    "city": "Zhongshan",
    "state": "Guangdong",
    "country": "China",
    "woeid": "2161664",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZGO",
    "lat": "50.9496",
    "lon": "10.7087",
    "name": "Gotha",
    "city": "Gotha",
    "state": "Thuringia",
    "country": "Germany",
    "woeid": "653514",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZGS",
    "lat": "50.3016",
    "lon": "-60.6776",
    "name": "Gethsemani Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524293",
    "tz": "America\/Blanc-Sablon",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZGU",
    "lat": "-14.25",
    "lon": "167.5",
    "name": "Gaua Airport",
    "city": "Gaua",
    "state": "",
    "country": "Vanuatu",
    "woeid": "23424907",
    "tz": "Pacific\/Efate",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZHA",
    "lat": "21.2151",
    "lon": "110.362",
    "name": "Zhanjiang Airport",
    "city": "Zhanjiang",
    "state": "Guangdong",
    "country": "China",
    "woeid": "12512295",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "5"
  },
  {
    "code": "ZHO",
    "lat": "29.7605",
    "lon": "-95.3698",
    "name": "Bus Station",
    "city": "Houston",
    "state": "Texas",
    "country": "United States",
    "woeid": "2424766",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Bus Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZIF",
    "lat": "45.4167",
    "lon": "-75.7",
    "name": "Fallowfield Railway",
    "city": "Ottawa",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12697427",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZIG",
    "lat": "12.5561",
    "lon": "-16.2758",
    "name": "Ziguinchor Airport",
    "city": "Ziguinchor",
    "state": "Ziguinchor",
    "country": "Senegal",
    "woeid": "12517511",
    "tz": "Africa\/Dakar",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4413",
    "elev": "82",
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZIH",
    "lat": "17.6025",
    "lon": "-101.458",
    "name": "Ixtapa Zihuatanejo International Airport",
    "city": "Petatl\u00e1n",
    "state": "Guerrero",
    "country": "Mexico",
    "woeid": "12514897",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "8202",
    "elev": "10",
    "icao": "MMZH",
    "direct_flights": "12",
    "carriers": "15"
  },
  {
    "code": "ZIV",
    "lat": "57.48",
    "lon": "-4.22347",
    "name": "Inverness Rail Station",
    "city": "Inverness",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "24502",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZJT",
    "lat": "1.58333",
    "lon": "110.483",
    "name": "",
    "city": "Tanjung Pelepas",
    "state": "",
    "country": "Malaysia",
    "woeid": "23424901",
    "tz": "Asia\/Kuala_Lumpur",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZKE",
    "lat": "52.3117",
    "lon": "-81.6078",
    "name": "Kaschechewan Airport",
    "city": "Winisk",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524116",
    "tz": "America\/Toronto",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "35",
    "icao": "CZKE",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZKG",
    "lat": "50.1937",
    "lon": "-61.2817",
    "name": "Kegaska Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524295",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZLN",
    "lat": "48.0081",
    "lon": "0.19784",
    "name": "Le Mans",
    "city": "Le Mans",
    "state": "Pays de la Loire",
    "country": "France",
    "woeid": "603413",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "10"
  },
  {
    "code": "ZLO",
    "lat": "19.1478",
    "lon": "-104.56",
    "name": "Playa de Oro International Airport",
    "city": "Manzanillo",
    "state": "Jalisco",
    "country": "Mexico",
    "woeid": "12514936",
    "tz": "America\/Mexico_City",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "7218",
    "elev": "26",
    "icao": "MMZO",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "ZLS",
    "lat": "52.8833",
    "lon": "-1.97685",
    "name": "England",
    "city": "London",
    "state": "England",
    "country": "United Kingdom",
    "woeid": "24554868",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ZLT",
    "lat": "50.833",
    "lon": "-58.9902",
    "name": "La Tabatiere Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524296",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Other Airport",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZLY",
    "lat": "42.7426",
    "lon": "-73.809",
    "name": "Albany International Airport",
    "city": "Albany",
    "state": "New York",
    "country": "United States",
    "woeid": "12518554",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZMB",
    "lat": "-13.1519",
    "lon": "27.8525",
    "name": "Hamburg Hauptbahnhof",
    "city": "Hamburg",
    "state": "Hamburg",
    "country": "Germany",
    "woeid": "656958",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "4"
  },
  {
    "code": "ZME",
    "lat": "40.1428",
    "lon": "-74.7267",
    "name": "New Jersey",
    "city": "Newark",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "2347589",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "2"
  },
  {
    "code": "ZML",
    "lat": "42.9471",
    "lon": "-87.9051",
    "name": "General Mitchell International Airport",
    "city": "Milwaukee",
    "state": "Wisconsin",
    "country": "United States",
    "woeid": "12519880",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZMT",
    "lat": "54.012",
    "lon": "-132.134",
    "name": "Masset Airport",
    "city": "Masset",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12524297",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5000",
    "elev": null,
    "icao": "CZMT",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZMU",
    "lat": "48.9174",
    "lon": "11.408",
    "name": "Bavaria",
    "city": "Munich",
    "state": "Bavaria",
    "country": "Germany",
    "woeid": "2345482",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "6",
    "carriers": "4"
  },
  {
    "code": "ZMY",
    "lat": "43.8321",
    "lon": "4.36677",
    "name": "Huangpu Harbour",
    "city": "Huangpu",
    "state": "Shanghai",
    "country": "China",
    "woeid": "22726048",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Harbours",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ZNA",
    "lat": "49.1617",
    "lon": "-123.893",
    "name": "Nanaimo Harbour Airport",
    "city": "Nanaimo",
    "state": "British Columbia",
    "country": "Canada",
    "woeid": "12523278",
    "tz": "America\/Vancouver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZNB",
    "lat": "-3.08333",
    "lon": "-67.9333",
    "name": "Sinop Airport",
    "city": "Santo Ant\u00f4nio do I\u00e7\u00e1",
    "state": "Amazonas",
    "country": "Brazil",
    "woeid": "12523221",
    "tz": "America\/Porto_Velho",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZNE",
    "lat": "-23.4169",
    "lon": "119.803",
    "name": "Newman Airport",
    "city": "Newman",
    "state": "Western Australia",
    "country": "Australia",
    "woeid": "12510751",
    "tz": "Australia\/Perth",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6800",
    "elev": "1724",
    "icao": "YNWN",
    "direct_flights": "1",
    "carriers": "3"
  },
  {
    "code": "ZNV",
    "lat": "4.6",
    "lon": "-61.1",
    "name": "Santa Elena Airport",
    "city": "Santa Elena de Uair\u00e9n",
    "state": "Bolivar",
    "country": "Venezuela",
    "woeid": "12524549",
    "tz": "America\/Caracas",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZNZ",
    "lat": "-6.2203",
    "lon": "39.2228",
    "name": "Zanzibar Airport",
    "city": "Stone Town",
    "state": "Zanzibar Urban\/West",
    "country": "Tanzania",
    "woeid": "12518023",
    "tz": "Africa\/Dar_es_Salaam",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "6562",
    "elev": "54",
    "icao": "HTZA",
    "direct_flights": "11",
    "carriers": "13"
  },
  {
    "code": "ZOS",
    "lat": "-40.6072",
    "lon": "-73.0567",
    "name": "Canal Bajo Carlos H Siebert Airport",
    "city": "Osorno",
    "state": "Los Lagos",
    "country": "Chile",
    "woeid": "12512307",
    "tz": "America\/Santiago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5577",
    "elev": "190",
    "icao": "SCJO",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ZPB",
    "lat": "53.8648",
    "lon": "-92.1915",
    "name": "Sachigo Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524301",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "885",
    "icao": "CZPB",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZQN",
    "lat": "-45.0217",
    "lon": "168.737",
    "name": "Frankton Airport",
    "city": "Queenstown",
    "state": "Otago",
    "country": "New Zealand",
    "woeid": "12515166",
    "tz": "Pacific\/Auckland",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4400",
    "elev": "1173",
    "icao": "NZQN",
    "direct_flights": "6",
    "carriers": "6"
  },
  {
    "code": "ZQW",
    "lat": "49.9545",
    "lon": "7.31024",
    "name": "Rheinland-Pfalz",
    "city": "Zweibrucken",
    "state": "Rheinland-Pfalz",
    "country": "Germany",
    "woeid": "2345488",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "13",
    "carriers": "2"
  },
  {
    "code": "ZRB",
    "lat": "50.0483",
    "lon": "8.57041",
    "name": "Frankfurt International Airport",
    "city": "Frankfurt",
    "state": "Hesse",
    "country": "Germany",
    "woeid": "22981759",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZRD",
    "lat": "37.5406",
    "lon": "-77.4334",
    "name": "Richmond",
    "city": "Richmond",
    "state": "Virginia",
    "country": "United States",
    "woeid": "2480894",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZRH",
    "lat": "47.454",
    "lon": "8.56137",
    "name": "Zurich International Airport",
    "city": "Kloten",
    "state": "Canton of Zurich",
    "country": "Switzerland",
    "woeid": "22442771",
    "tz": "Europe\/Zurich",
    "phone": "+41 43 816 22 11",
    "type": "Airports",
    "email": "",
    "url": "http:\/\/www.zurich-airport.com",
    "runway_length": "12139",
    "elev": "1416",
    "icao": "LSZH",
    "direct_flights": "161",
    "carriers": "79"
  },
  {
    "code": "ZRJ",
    "lat": "52.95",
    "lon": "-91.3167",
    "name": "Round Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524304",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "974",
    "icao": "CZRJ",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "ZRL",
    "lat": "40.038",
    "lon": "-76.3013",
    "name": "Lancaster",
    "city": "Lancaster",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "2436076",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZRP",
    "lat": "40.7333",
    "lon": "-74.1667",
    "name": "Pennsylvania Station",
    "city": "Newark",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "12760686",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "7",
    "carriers": "2"
  },
  {
    "code": "ZRT",
    "lat": "41.7633",
    "lon": "-72.674",
    "name": "Hartford",
    "city": "Hartford",
    "state": "Connecticut",
    "country": "United States",
    "woeid": "2418244",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZRU",
    "lat": "42.322",
    "lon": "-71.0918",
    "name": "",
    "city": "Boston",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "2367105",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZRV",
    "lat": "41.8238",
    "lon": "-71.412",
    "name": "Providence",
    "city": "Providence",
    "state": "Rhode Island",
    "country": "United States",
    "woeid": "2477058",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ZSA",
    "lat": "24.06",
    "lon": "-74.5331",
    "name": "San Salvador Airport",
    "city": "Cockburn Town",
    "state": "San Salvador",
    "country": "Bahamas",
    "woeid": "12510879",
    "tz": "America\/Nassau",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4500",
    "elev": "10",
    "icao": "",
    "direct_flights": "4",
    "carriers": "3"
  },
  {
    "code": "ZSE",
    "lat": "-20.8833",
    "lon": "55.5333",
    "name": "R\u00e9union",
    "city": "St Pierre dela Reunion",
    "state": "St-Denis",
    "country": "Reunion",
    "woeid": "1511237",
    "tz": "Indian\/Reunion",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4920",
    "elev": "52",
    "icao": "FMEP",
    "direct_flights": "5",
    "carriers": "3"
  },
  {
    "code": "ZSF",
    "lat": "42.1039",
    "lon": "-72.592",
    "name": "Springfield MA RR",
    "city": "Springfield",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "12758361",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZSJ",
    "lat": "53.0513",
    "lon": "-93.2491",
    "name": "Sandy Lake Airport",
    "city": "Casummit Lake",
    "state": "Ontario",
    "country": "Canada",
    "woeid": "12524306",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "3500",
    "elev": "937",
    "icao": "CZSJ",
    "direct_flights": "4",
    "carriers": "1"
  },
  {
    "code": "ZSN",
    "lat": "56.7819",
    "lon": "-98.9558",
    "name": "South Indian Lake Airport",
    "city": "South Indian Lake",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524069",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "CZSN",
    "direct_flights": "0",
    "carriers": "0"
  },
  {
    "code": "ZTA",
    "lat": "41.3",
    "lon": "-6.46667",
    "name": "",
    "city": "Tureira",
    "state": "",
    "country": "French Polynesia",
    "woeid": "23424817",
    "tz": "Pacific\/Midway",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "NTGY",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZTB",
    "lat": "50.6876",
    "lon": "-59.3545",
    "name": "Tete-a-la-Baleine Airport",
    "city": "Havre-St-Pierre",
    "state": "Quebec",
    "country": "Canada",
    "woeid": "12524309",
    "tz": "America\/Halifax",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZTD",
    "lat": "42.8161",
    "lon": "-73.9442",
    "name": "",
    "city": "Schenectady",
    "state": "New York",
    "country": "United States",
    "woeid": "2489634",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZTE",
    "lat": "43.1531",
    "lon": "-77.6177",
    "name": "",
    "city": "Rochester",
    "state": "New York",
    "country": "United States",
    "woeid": "2482949",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZTF",
    "lat": "41.0464",
    "lon": "-73.5429",
    "name": "",
    "city": "Stamford",
    "state": "Connecticut",
    "country": "United States",
    "woeid": "2498846",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "2"
  },
  {
    "code": "ZTH",
    "lat": "37.7522",
    "lon": "20.8847",
    "name": "Zakinthos Airport",
    "city": "Zante",
    "state": "Nisia Ionioy",
    "country": "Greece",
    "woeid": "12513332",
    "tz": "Europe\/Athens",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5971",
    "elev": "10",
    "icao": "LGZA",
    "direct_flights": "15",
    "carriers": "7"
  },
  {
    "code": "ZTI",
    "lat": "22.8267",
    "lon": "113.672",
    "name": "Humen Port",
    "city": "Humen",
    "state": "Guangdong",
    "country": "China",
    "woeid": "2161939",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZTJ",
    "lat": "40.1428",
    "lon": "-74.7267",
    "name": "New Jersey",
    "city": "Princeton",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "2347589",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZTM",
    "lat": "55.8667",
    "lon": "-92.0833",
    "name": "Shamattawa Airport",
    "city": "Nelson House",
    "state": "Manitoba",
    "country": "Canada",
    "woeid": "12524310",
    "tz": "America\/Winnipeg",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "4170",
    "elev": "295",
    "icao": "CZTM",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ZTN",
    "lat": "40.217",
    "lon": "-74.7551",
    "name": "",
    "city": "Philadelphia",
    "state": "New Jersey",
    "country": "United States",
    "woeid": "2347589",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "ZTO",
    "lat": "42.3374",
    "lon": "-71.0591",
    "name": "",
    "city": "Boston",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "2367105",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZTV",
    "lat": "38.1865",
    "lon": "-85.7462",
    "name": "Louisville International Airport",
    "city": "Louisville",
    "state": "Kentucky",
    "country": "United States",
    "woeid": "12521983",
    "tz": "America\/Kentucky\/Louisville",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZTY",
    "lat": "42.3433",
    "lon": "-71.0506",
    "name": "",
    "city": "Boston",
    "state": "Massachusetts",
    "country": "United States",
    "woeid": "2367105",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZUA",
    "lat": "43.0958",
    "lon": "-75.2428",
    "name": "Utica",
    "city": "Utica",
    "state": "New York",
    "country": "United States",
    "woeid": "2510530",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZUG",
    "lat": "47.1715",
    "lon": "8.51622",
    "name": "",
    "city": "Harrisburg",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "2418046",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZUH",
    "lat": "22.277",
    "lon": "113.557",
    "name": "Zhuhai Airport",
    "city": "Zhuhai",
    "state": "Guangdong",
    "country": "China",
    "woeid": "2161856",
    "tz": "Asia\/Chongqing",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "7"
  },
  {
    "code": "ZUM",
    "lat": "53.5619",
    "lon": "-64.1072",
    "name": "Churchill Falls Airport",
    "city": "Wabush",
    "state": "Newfoundland and Labrador",
    "country": "Canada",
    "woeid": "12511616",
    "tz": "America\/Goose_Bay",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5500",
    "elev": "1442",
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZUN",
    "lat": "35.0843",
    "lon": "-108.789",
    "name": "Black Rock Airport",
    "city": "Zuni",
    "state": "New Mexico",
    "country": "United States",
    "woeid": "12518848",
    "tz": "America\/Denver",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "KZUN",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZVE",
    "lat": "41.3071",
    "lon": "-72.9249",
    "name": "New Haven",
    "city": "New Haven",
    "state": "Connecticut",
    "country": "United States",
    "woeid": "2458410",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "2"
  },
  {
    "code": "ZVK",
    "lat": "16.5536",
    "lon": "104.763",
    "name": "Savannakhet Airport",
    "city": "Savannakhet",
    "state": "Savannahkhet",
    "country": "Laos",
    "woeid": "12514556",
    "tz": "Asia\/Vientiane",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "5350",
    "elev": "509",
    "icao": "VLSK",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZVR",
    "lat": "52.3778",
    "lon": "9.74213",
    "name": "Hannover Hauptbahnhof",
    "city": "Hanover",
    "state": "Lower-Saxony",
    "country": "Germany",
    "woeid": "20065987",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "4"
  },
  {
    "code": "ZWB",
    "lat": "37.0294",
    "lon": "-76.3467",
    "name": "Williamsburg Rail",
    "city": "Hampton",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12767461",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZWE",
    "lat": "-19.0133",
    "lon": "29.1467",
    "name": "",
    "city": "",
    "state": "",
    "country": "Zimbabwe",
    "woeid": "23425004",
    "tz": "Africa\/Harare",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "2"
  },
  {
    "code": "ZWI",
    "lat": "41.1418",
    "lon": "-80.3211",
    "name": "Wilmington Rail",
    "city": "Wilmington",
    "state": "Pennsylvania",
    "country": "United States",
    "woeid": "28744866",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ZWS",
    "lat": "48.7831",
    "lon": "9.18139",
    "name": "Stuttgart Hauptbahnhof",
    "city": "Stuttgart",
    "state": "Baden-W\u00fcrttemberg",
    "country": "Germany",
    "woeid": "698064",
    "tz": "Europe\/Berlin",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "6"
  },
  {
    "code": "ZWU",
    "lat": "38.9",
    "lon": "-77.25",
    "name": "Union Station",
    "city": "Washington",
    "state": "Virginia",
    "country": "United States",
    "woeid": "12766844",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "3",
    "carriers": "1"
  },
  {
    "code": "ZWV",
    "lat": "39.7393",
    "lon": "-89.5041",
    "name": "Illinois",
    "city": "Glenview",
    "state": "Illinois",
    "country": "United States",
    "woeid": "2347572",
    "tz": "America\/Chicago",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZWW",
    "lat": "36.9805",
    "lon": "-76.4295",
    "name": "Newport News",
    "city": "Hampton",
    "state": "Virginia",
    "country": "United States",
    "woeid": "2459618",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZXA",
    "lat": "57.1538",
    "lon": "-2.10679",
    "name": "Aberdeen railway station",
    "city": "Aberdeen",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "10243",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZXE",
    "lat": "55.9521",
    "lon": "-3.189",
    "name": "Waverley station",
    "city": "Edinburgh",
    "state": "Scotland",
    "country": "United Kingdom",
    "woeid": "19344",
    "tz": "Europe\/London",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "10",
    "carriers": "1"
  },
  {
    "code": "ZYA",
    "lat": "52.3784",
    "lon": "4.90016",
    "name": "Amsterdam Central Station",
    "city": "Amsterdam",
    "state": "",
    "country": "Netherlands",
    "woeid": "15003814",
    "tz": "Europe\/Amsterdam",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "2"
  },
  {
    "code": "ZYK",
    "lat": "30.7",
    "lon": "114.35",
    "name": "Shekou Port",
    "city": "Shekou",
    "state": "\u6e56\u5317\u7701",
    "country": "China",
    "woeid": "12713102",
    "tz": "Asia\/Shanghai",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "ZYL",
    "lat": "24.9589",
    "lon": "91.8744",
    "name": "Osmany Sylhet Airport",
    "city": "Sylhet",
    "state": "Sylhet",
    "country": "Bangladesh",
    "woeid": "12510892",
    "tz": "Asia\/Dhaka",
    "phone": "0821-714243, 718459",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": "10500",
    "elev": "50",
    "icao": "VGSY",
    "direct_flights": "1",
    "carriers": "8"
  },
  {
    "code": "ZYN",
    "lat": "43.8321",
    "lon": "4.36677",
    "name": "Nimes Rail Station",
    "city": "Nimes",
    "state": "Languedoc-Roussillon",
    "country": "France",
    "woeid": "614326",
    "tz": "Europe\/Paris",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "4",
    "carriers": "7"
  },
  {
    "code": "ZYP",
    "lat": "40.7501",
    "lon": "40.7501",
    "name": "Penn Station",
    "city": "New York",
    "state": "New York",
    "country": "United States",
    "woeid": "23682829",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "5",
    "carriers": "1"
  },
  {
    "code": "ZYQ",
    "lat": "43.05",
    "lon": "-76.1474",
    "name": "Syracuse",
    "city": "Syracuse",
    "state": "New York",
    "country": "United States",
    "woeid": "2503418",
    "tz": "America\/New_York",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "2",
    "carriers": "1"
  },
  {
    "code": "ZYR",
    "lat": "50.8346",
    "lon": "4.33628",
    "name": "Brussels Midi Railway Station",
    "city": "Brussels",
    "state": "Vlaams Brabant",
    "country": "Belgium",
    "woeid": "968019",
    "tz": "Europe\/Brussels",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "9",
    "carriers": "7"
  },
  {
    "code": "ZYZ",
    "lat": "51.1992",
    "lon": "4.43246",
    "name": "Berchem Railway Stn.",
    "city": "Antwerp",
    "state": "Flanders",
    "country": "Belgium",
    "woeid": "12817574",
    "tz": "Europe\/Brussels",
    "phone": "",
    "type": "Railway Stations",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "1",
    "carriers": "1"
  },
  {
    "code": "TNM",
    "lat": "-81.5",
    "lon": "0",
    "name": "Teniente R. Marsh Airport",
    "city": "Teniente R. Marsh",
    "state": "",
    "country": "Antarctica",
    "woeid": "28289409",
    "tz": "Antarctica\/South_Pole",
    "phone": "",
    "type": "Airports",
    "email": "",
    "url": "",
    "runway_length": null,
    "elev": null,
    "icao": "",
    "direct_flights": "0",
    "carriers": "0"
  }
]

 export default airportsIATA