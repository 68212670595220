import React from 'react';


const MotorbikeHeader = () => {
  return <>
        <div className='global-header-container' >
          <p>You can enter details for up to 1 motorbike</p>
        </div>
  </>;
};

export default MotorbikeHeader;
