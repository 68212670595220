import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./_flight-page.scss";
import { Button } from "@carbon/react";
import { Link } from "react-router-dom";
import IconsNavigation from "../IconsNavigation/IconsNavigation";
import {
  NextOutline,
  PreviousOutline,
  TrashCan,
  Add,
} from "@carbon/react/icons";
import FlightHeader from "./Flight_Header";
import FlightHeaderResults from "./Flight_HeaderResults";
import FlightHeaderVisualization from "./Flight_HeaderVisualization";
import FlightOptions from "./Flight_Options";
import FlightFrom from "./Flight_From";
import FlightTo from "./Flight_To";
//import FlightClass from './Flight_Class'
//import FlightTrips from './Flight_Trips'
//import FlightRadiative from './Flight_Radiative'
//import FlightLuggage from './Flight_Luggage';
import FlightTotalResults from "./Flight_TotalResults";
import { ToastNotification } from "carbon-components-react";
import FlightFootprintsButton from "./Flight_FootprintsButton";
import FlightIndividualResults from "./Flight_IndividualResults";
import FlightDataVisualization from "./Flight_DataVisualization";
import FlightOffsetButton from "./Flight_OffsetButton";
import Waves from "../Waves";
import {
  setSelectedAirportFrom,
  setSelectedAirportTo,
  setShowFlightResult,
  resetFlightFootprint,
} from "../../redux/flights";

const FlightsPage = () => {
  const dispatch = useDispatch();

  const { flights } = useSelector((state) => state.flights);
  const [formFields, setFormFields] = useState([{ from: "", to: "" }]);
  const [formIndex, setFormIndex] = useState(flights.length);
  const [apiMessage, setApiMessage] = useState("");
  const [notification, setNotification] = useState(false);

  const handleAddFields = () => {
    let oldIndex = formIndex - 1 > 0 ? formIndex - 1 : 0;
    if (!flights[oldIndex] || !flights[oldIndex].selectedAirportFrom.name) {
      setApiMessage(" Please first select any Airport..");
      setNotification(true);
      setTimeout(() => {
        handleCloseNotify();
      }, 5000);
    } else {
      setFormFields([...formFields, { from: "", to: "" }]);
      setFormIndex(formIndex + 1);
    }
  };
  const resetFootprintFlight = (index) => {
    let showFlight = false;
    let footerPrint = 0;
    dispatch(setShowFlightResult({ showFlight, index }));
    dispatch(resetFlightFootprint({ footerPrint, index }));
    setFormIndex(formIndex - 1);
  };

  const removeFormFields = (index) => {
    let newFormValues = [...formFields];
    if (index > 0) {
      newFormValues.splice(index, 1);
      setFormFields(newFormValues);
    }
    resetFootprintFlight(index);
  };
  const handleCloseNotify = () => {
    setNotification(false);
  };
  return (
    <>
      {notification && (
        <>
          <ToastNotification
            kind={"error"}
            title="Alert"
            subtitle={apiMessage}
            caption=""
            onCloseButtonClick={handleCloseNotify}
            hideCloseButton={false}
            lowContrast={false}
            open={notification}
            className="custom-toast-notification" // Add custom CSS class name
          />
        </>
      )}
      <IconsNavigation />
      <section className="global-frame-calculator">
        <div className="global-block-top">
          <h4>Flights</h4>
        </div>

        <section className="flight-main-container">
          <div className="flight-inputs-block">
            <FlightHeader />
            <br />
            <div className="flight-inputs-container">
              <FlightOptions />

              {formFields.map((field, index) => (
                <div className="sectionOuter">
                  <div className="sectionInner">
                    <FlightFrom index={index} />
                    <FlightTo index={index} />
                  </div>
                  {index ? (
                    <Button
                      className="flight-result-trashcan-icon-container removeBtn "
                      onClick={() => removeFormFields(index)}
                    >
                      <TrashCan />
                    </Button>
                  ) : (
                    <Button
                      className="addBtn"
                      disabled={formFields.length > 4 ? true : false}
                      onClick={handleAddFields}
                    >
                      <Add />
                    </Button>
                  )}
                </div>
              ))}
            </div>
            <div className="flight-button-container">
              <FlightFootprintsButton />
            </div>
          </div>

          <div className="flight-results-block">
            <FlightHeaderResults />
            <div className="flight-results-container">
              <FlightIndividualResults removeFormFields={removeFormFields} />
            </div>
            <FlightTotalResults />
          </div>

          <div className="flight-visualization-block">
            <FlightHeaderVisualization />
            <div className="flight-datavisual-container">
              <FlightDataVisualization />
            </div>
            <FlightOffsetButton />
          </div>
        </section>
        {/* ------------------------------------------------------------------- */}
      </section>

      <div className="global-block-bottom">
        <Link className="global-buttons-link-back-and-forth" to="/house">
          <Button
            className="global-re-styled-button-back-and-forth"
            renderIcon={PreviousOutline}
            kind="tertiary"
          >
            House
          </Button>
        </Link>
        <Link className="global-buttons-link-back-and-forth" to="/car">
          <Button
            renderIcon={NextOutline}
            className="global-re-styled-button-back-and-forth"
            kind="tertiary"
          >
            Car
          </Button>
        </Link>
      </div>
      <Waves />
    </>
  );
};

export default FlightsPage;
